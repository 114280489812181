import React, {useContext, useEffect, useState} from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import data from '../mock_server/user_themes.json' // temi varianti orange
import {AuthContext, ThemeContext} from "../contexts";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',

    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
    },
});

function StyledRadio(props) {

    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

const Settings = ({theme, setTheme}) => {
    const authContext = useContext(AuthContext);
    const [themes, setThemes] = useState([])
    const [themeId, setValueTheme] = useState(useContext(ThemeContext).id)

    const { t } = useTranslation();

    useEffect(
        () => {
            setThemes(data.themes)
        }, [])

    function onRadioChange(e){
        setValueTheme(e.target.value)
        themes.forEach( (t, index) => {
            /** con === non funziona, non ho capito perchè */
            if(t.id === Number.parseInt(e.target.value)) {
                localStorage.setItem(`${authContext.userName}_${authContext.companyPrefix}_theme`, JSON.stringify(t))
                setTheme(t)
            }
        })
    }

    return (
        <Paper style={{ margin:'auto'}}>
            <Box mt={2} p={3}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("profile.selectTheme")}</FormLabel>
                    <RadioGroup defaultValue="theme1" aria-label="Theme" name="customized-radios">
                        {
                            themes.map( (t, index) => {return <div key={index}>
                            <FormControlLabel value={t.id}
                                              checked={themeId === t.id /** con === non funziona, non ho capito perchè */}
                                              onChange={onRadioChange}  control={<StyledRadio />} label={t.name} />
                            <svg width={'80px'} height={'20px'}>
                                <rect fill={t.primaryColor} width="20" height="20"/>
                                <rect fill={t.accentColor} x="20" width="20" height="20"/>
                                <rect fill={t.secondAccentColor} x="60" width="20" height="20"/>
                                <rect fill={t.secondaryColor} x="40" width="20" height="20"/>
                            </svg>
                            </div>})
                        }
                    </RadioGroup>
                </FormControl>
                <svg x="0px" y="0px" width="350px" height="200px" viewBox="0 0 65 65">
                    <circle fill={theme.primaryColor} cx="32.5" cy="32.5" r="32.5"/>
                    <circle fill={theme.accentColor} cx="26" cy="24" r="17.5"/>
                    <circle fill={theme.secondAccentColor} cx="52.5" cy="38.5" r="7.5"/>
                    <circle fill={theme.secondaryColor} cx="37.5" cy="48.5" r="2.5"/>
                </svg>
            </Box>
        </Paper>
    )
}

export default Settings;
