import React, {useContext, useMemo} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import {makeStyles} from "@material-ui/core/styles";
import {ThemeContext} from "../../contexts";
import EditTag from "./EditTag";
import chroma from 'chroma-js'
import _ from "lodash";
import {Chip} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export const CustomTagField = ({numberToShow = 5, tags, selected, onTagIconClick}) => {
    const { t } = useTranslation();
    const {accentColor} = useContext(ThemeContext)
    const useStyle = makeStyles(() => ({
        tagChip: {
            backgroundColor: chroma(accentColor).alpha(0.9).hex(),
            color: 'white',
            fontWeight: 'bold',
            marginRight: '2%',
            marginBottom: '2%'
        },
        selectedTagChip: {
            backgroundColor: 'white',
            color: 'gray',
            fontWeight: 'bold',
            marginRight: '2%',
            marginBottom: '2%'
        },
        leftTagsCountChip: {
            marginRight: '1%',
            marginBottom: '1%',
            border: '1px solid '+ accentColor,
            color: accentColor,
            fontWeight: 'bold',
        },
        selectedChip: {
            marginRight: '1%',
            marginBottom: '1%',
            color: 'white',
            fontWeight: 'bold',
            border: '1px solid rgb(255 255 255)'
        },
    }))

    const classes = useStyle()

    const [firstTags, leftTagsCount] = useMemo(() => {
        if(numberToShow === -1)
            return [tags, 0]
        const _leftTagsCount = tags.length > numberToShow ? tags.length - numberToShow : 0
        return [_.slice(tags, 0, numberToShow), _leftTagsCount]
    }, [tags])

    return(
        <div>
            {/*firstTags.map( (tag) => <span key={tag} className={selected ? classes.selectedTagChip : classes.tagChip}>{tag}</span>)*/}
            {
                firstTags.map( (tag, index) => <Chip size={'small'}
                    //variant={'outlined'}
                                                     label={tag}
                                                     key={index}
                                                     className={selected ? classes.selectedTagChip : classes.tagChip}/>)
            }
            {
                leftTagsCount > 0 || numberToShow === -1 ?
                    <Chip size={'small'}
                          variant={'outlined'}
                          label={numberToShow === -1 ? t('actions.showLess') : '+' + leftTagsCount}
                          className={selected ? classes.selectedChip : classes.leftTagsCountChip}
                          onClick={onTagIconClick ? onTagIconClick : null}
                    /> : null
            }
        </div>
    )
}

/** TAG CONTROLLER GENERICO -> sia per tag degli item che tag delle relationship per le chain */
const TagController = ({selected, product, relation, refetch, editable}) => {
    const { t } = useTranslation();

    /** per tag controller --------------------- */
    const [openTagProductDialog, setOpenTagProductDialog] = React.useState({open: false, data: null});

    const onTagIconClick = () => {
        setOpenTagProductDialog({open: true, product: product, relation: relation});
    }

    const handleClose = () => {
        if(refetch && relation && editable)
            refetch()
        setOpenTagProductDialog({open: false, product: null, relation: null});
    };
    /** ----------------------------------------- */

    const tags = product ?
        _.filter(product.tags, function(o) { return _.lowerCase(o) !== 'other'; })
        : relation.chain

    return (
        <div>
            <CustomTagField selected={selected} tags={product || relation? tags : []} onTagIconClick={onTagIconClick}/>
            <Tooltip
                title={editable ? t('actions.edit', {what: 'tags'}) : t('actions.view', {what: 'tags'})}
                arrow>
                <IconButton size={'small'} onClick={onTagIconClick}>
                    <LocalOfferIcon style={selected ? {color: 'white'} : {color: "lightgray"}}/>
                </IconButton>
            </Tooltip>
            {
                openTagProductDialog.product || openTagProductDialog.relation ?
                    <EditTag id="scroll-dialog-description" object={openTagProductDialog} handleClose={handleClose} editable={editable}/>
                    : null
            }
        </div>
    )
}

export default TagController
