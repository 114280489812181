import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from './locales/en/translation.json'
import translation_it from './locales/it/translation.json'
import translation_zh from './locales/zh/translation.json'
import formatters from "./locales/en/formatters.json"
// don't want to use this?
// have a look at the Quick start guide for passing in lng and translations on init

export const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb'
    },
    {
        code: 'it',
        name: 'Italiano',
        country_code: 'it',
    },
    {
        code: 'zh',
        name: '中文',
        country_code: 'cn',
    },
]

//console.log("translationEN:",translationEN)
//console.log("translationIT:",translationIT)
//console.log("LANG:",localStorage.getItem(`iChain_lang`))

export const langResources = {
    en: {
        translation: translation_en,
        formatters: formatters
    },
    it: {
        translation: translation_it
    },
    zh: {
        translation: translation_zh
    }
}

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        supportedLngs: ['en', 'it', 'zh'],
        lng: localStorage.getItem(`iChain_lang`),//'en',//document.querySelector('html').lang,
        fallbackLng: 'en',
        /*detection: {
            order: ['querystring', 'cookie'],
            cache: ['cookie']
        },*/
        debug: true,

        resources: langResources,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, lng) => {
                if (format === 'intlDateTime') {
                    return new Intl.DateTimeFormat(lng, {
                        hour: "2-digit",
                        minute: "2-digit",
                        //second: "2-digit"
                    }).format(value).concat(", ").concat(new Intl.DateTimeFormat(lng, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    }).format(value));
                }
                if (format === 'intlDateTimeLong') {
                    return new Intl.DateTimeFormat(lng, {
                        hour: "2-digit",
                        minute: "2-digit",
                        //second: "2-digit",
                        //timeZone: 'UTC'
                    }).format(value).concat(", ").concat(new Intl.DateTimeFormat(lng, {
                        weekday: "long",
                        day: "2-digit",
                        month: "long",
                        year: "numeric"
                    }).format(value));
                }
                if (format === 'intlDate') {
                    return new Intl.DateTimeFormat(lng, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    }).format(value);
                }
                if (format === 'intlTime') {
                    return new Intl.DateTimeFormat(lng, {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit"
                    }).format(value);
                }
                if (format === 'intlDateLong') {
                    return new Intl.DateTimeFormat(lng, {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                    }).format(value);
                }
                if (format === 'intlNumber2Digit') {
                    return new Intl.NumberFormat(lng, {
                        maximumFractionDigits: 2
                    }).format(value);
                }
                return value;
            }
        }
    });

export default i18n;