import React, {useContext, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {AuthContext, CreateEventContext, ThemeContext} from "../../contexts";
import useAxios from "axios-hooks";
import _ from "lodash";
import CustomLoader from "../../components/CustomLoader";
import CreateEventForm from  "./CreateEventForm";
import {availableProductCodeTypes, findCodeTypeAndValue} from "../../utils/CodesUtils";
import {useTranslation} from "react-i18next";

const CreateEvent = (props) => {
    const { t } = useTranslation();

    const {primaryColor, primaryColorAlpha, accentColor} = useContext(ThemeContext)
    const {companyPrefix, activeMainDomain, activeBrandName} = useContext(AuthContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            "& .MuiStepIcon-root.MuiStepIcon-active": {
                color: primaryColor,
            },
            "& .MuiStepIcon-root.MuiStepIcon-completed": {
                color: primaryColorAlpha,
            }
        }
    }));
    const classes = useStyles()

    const [formData, setFormData] = useState({})

    const [{data: products, loading: loadinProd, error: errorProd},] = useAxios({
            url: "/api/items/?domain="+activeMainDomain
        },
        {useCache: false}
    )

    const [{data: products_other, loading: loadingOther, error: errorOther},] = useAxios({
            url: "/api/items/?domain="+activeMainDomain+"&not_producer=true&producer=false"
        },
        {useCache: false}
    )
    const [{data: companies, loading: loadingCompanies, error: errorCompanies},] = useAxios({
            url: "/api/companies/"
        },
        {useCache: false}
    )

    const [{data: cbvValues, loading: loadinCbvValues, error: errorCbvValues},] = useAxios({
            url: "/api/cbv_values/"
        },
        {useCache: false}
    )

    const [{data: availableEventsName}] = useAxios({
            url: "api/capture/event_templates/latest_versions/names/"
        },
        {useCache: false}
    );

    const allProducts = useMemo(() => {
        if(!products || !products_other || !companies)
            return null

        let products_all = {
            count: 0,
            data: []
        }

        if (products && products_other && companies) {
            const mine = _.map(products.data, (product) => ({
                ...product,
                mine: true,
                companyPrefix: companyPrefix,
                brandName: activeBrandName || activeMainDomain || ''
            }))
            const others = _.map(products_other.data, product => {
                let brand = null
                const company = _.find(companies.data, function (o) {
                    const brandMy = _.find(o.brands, ['domain', product.producer]);
                    const brandOth = _.find(o.brands, ['other_domain', product.producer]);
                    brand = brandMy || brandOth
                    return !!brand
                })
                return {
                    ...product,
                    mine: false,
                    brandName: brand?.name || company?.name || null,
                    companyPrefix: brand && findCodeTypeAndValue(product.key)[0] === availableProductCodeTypes.GTIN14 ?
                        brand.company_prefix : null
                }
            });
            products_all.data = mine.concat(others)
            products_all.count = products_all.data.length

            return products_all
        }
    }, [products, products_other, companies])

    let form = props.location.state ? props.location.state : {}
    let initialState = _.cloneDeep(props.location.state)

    let validation = {
        eventName: {},
        what: {},
        why: {},
        where: {},
        when: {},
        ilmd: {},
        docids: {}
    }

    const setForm = (_form) => {
        form = _form
    }

    const getForm = () => {
        return form
    }

    const setFormField = (w, label, value) => {
        if(!w) {
            form = {...form, [label]: value}
        } else {
            const fieldIndex = _.findIndex(form[w], ['name', label])
            form[w][fieldIndex].value = value
        }
        //console.log("UPDATED EVENT FORM ---> form:", form)
    }

    const getFormField = (w, label) => {
        if(form) {
            const fieldIndex = _.findIndex(form[w], ['name', label])
            return fieldIndex !== -1 ? form[w][fieldIndex] : null
        }
        return null
    }

    const setValidationField = (w, label, value) => {
        if(!validation[w][label])
            validation[w][label] = {}
        validation[w] = {...validation[w], [label]:value}
        //console.log("setValidationField ---> validation:",validation)
    }

    const resetForm = () => {
        setForm(initialState)
        resetValidation()
    }

    const resetValidation = () => {
        validation = {
            eventName: {},
            what: {},
            why: {},
            where: {},
            when: {},
            ilmd: {},
            docids: {}
        }
    }

    const getSectionValidation = (section) => {
        return _.every(_.values(validation[section]), function (o) {return o})
    }

    const getValidation = () => {
        /** Se tutti i campi di ogni "sezione" di validation (what, why, where...) sono stati settati a TRUE
         * ---> allora validation = TRUE
         * */
        return _.every(validation, function (o) {return _.every(_.values(o), function (o) {return o})})
    }

    const getValidationSchema = () => {
        return validation
    }

    const onSubmit = () => {
        const valide = getValidation()
        if(valide) {
            setFormData(form)
            return true
        }
        else {
            return false
        }
    }

    const context = useMemo(() => {
        if(!allProducts || !cbvValues || !availableEventsName)
            return

        return {
            cbvValues: cbvValues,
            companies: companies.data,
            products: allProducts,

            companyPrefix: companyPrefix,
            activeMainDomain: activeMainDomain,
            modelsName: availableEventsName,

            /** se ho cliccato la row di tableEventModels per generare l'evento HO GIA' I DATI DEL MODELLO */
            fromEventModelsTableData: props.location.state,

            setForm: setForm,
            getForm: getForm,
            setFormField: setFormField,
            getFormField: getFormField,
            getSectionValidation: getSectionValidation,
            getValidation: getValidation,
            getValidationSchema: getValidationSchema,
            setValidationField: setValidationField,
            resetValidation: resetValidation,
            resetForm: resetForm,
            onSubmit: onSubmit
        }

    }, [allProducts, cbvValues, availableEventsName])

    return (
        <div className={classes.root}>
            {
                context ?
                    <CreateEventContext.Provider value={context}>
                        <CreateEventForm formData={formData}/>
                    </CreateEventContext.Provider> :
                    loadinProd || loadinCbvValues || loadingCompanies || loadingOther || !allProducts ? <CustomLoader text={t('actions.loading')} size={80}/> :
                        errorProd || errorCbvValues || errorCompanies || errorOther ? "Server Error" : null
            }

        </div>
    )
}

export default CreateEvent
