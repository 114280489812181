import React, {useContext} from 'react';
import MaterialTable from 'material-table';
import {ThemeContext} from "../../contexts";
import {parseName} from "../../utils/Utils";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {langResources} from "../../i18n/i18next";
import {Check, CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const TableReports = ({reports, state, setState, onClickDownload}) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        dateChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        }
    }));
    const classes = useStyles()

    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: accentColor,
            },
        },
    });

    let columns = [
        {
            title: _.startCase(t('entities.date.label')),
            field: 'creation_date',
            sorting: true,
            align: 'right',
            render: rowData => <Chip
                size={'small'}
                className={classes.dateChip}
                icon={<Check style={{color: accentColor}}/>}
                label={t("formatters:date", {date: new Date(rowData.creation_date)})}
            />
        },
        {
            title: _.startCase(t('entities.name')),
            field: 'name',
            sorting: true,
            render: rowData => <p>{parseName(rowData.name)}</p>
        },
        {
            title: _.startCase(t('entities.format')),
            field: 'format',
            sorting: true,
            render: rowData => <p>{parseName(rowData.format)}</p>
        },
        {
            title: '',
            field: '',
            align: 'right',
            sorting: false,
            render: () => <></>
        },
    ]

    const onRowClick = (event, rowData) => {
        //console.log("TABLE REPORT -> rowData: ", rowData)
    }

    const tab = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable

    function onSelectionChange(data) {
        /** data: dati selezionati, rowData: riga de/selezionata */
        setState({...state, selectedReports: data})
    }

    return (
        <Box className={classes.root}>
            <ThemeProvider theme={theme}>
                <Button startIcon={state.multipleSelection ? <CheckBox/> : <CheckBoxOutlineBlank/>}
                        onClick={() => {
                            if(state.multipleSelection === true)
                                setState({...state, multipleSelection: !state.multipleSelection, selectedReports: reports})
                            setState({...state, multipleSelection: !state.multipleSelection})
                        }}>
                    {t("otherCompanies.multipleSelection")}
                </Button>
                <MaterialTable
                    columns={columns}
                    localization={tab}
                    actions={[
                        /** TODO ? : view report before download
                         * !state.multipleSelection ? {
                            icon: () => <Visibility/>,
                            tooltip: t('actions.view'),
                            onClick: (event, rowData) => {
                                console.log("view clicked rowData:", rowData)
                            },
                            //disabled: rowData.[something]
                        } : null,*/
                        {
                            icon: 'download',
                            tooltip: t('actions.download'),
                            onClick: (event, rowData) => {
                                if(!state.multipleSelection) {
                                    onClickDownload(rowData)
                                } else if(rowData.length === 1) {
                                    onClickDownload(rowData[0])
                                } else onClickDownload(rowData)
                            },
                            //disabled: rowData.[something]
                        },
                    ]}
                    data={_.orderBy(reports, 'creation_date', 'desc')}
                    onRowClick={onRowClick}
                    onSelectionChange={onSelectionChange}
                    options={{
                        search: true,
                        sorting: true,
                        selection: state.multipleSelection,
                        showSelectAllCheckbox: state.multipleSelection,
                        showTextRowsSelected: state.multipleSelection,
                        draggable: true,
                        showTitle: false,
                        tableLayout: "auto",
                        //actionsColumnIndex: -1,
                        padding: 'dense',
                        pageSize: 10,
                        actionsCellStyle: {
                            paddingInline: '30px',
                        }
                        //paging: true,
                        // pageSize: limit,
                        //pageSizeOptions: [5, 10, 20]
                    }}
                    //totalCount={companies.count}
                    //onChangePage={handleChangePage}
                    //onRowsPerPageChange={handleChangeRowsPerPage}
                    //page={skip}
                />
            </ThemeProvider>
        </Box>
    );
}

export default TableReports
