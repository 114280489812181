import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Summarylabel from "../../components/SummaryLabel";
import Divider from '@material-ui/core/Divider';
import Header from "../../components/Header";

const ConfigurationDetail = (props) =>  {

    const data = props.location.state;

    return (
        <Box>
            <Paper>
                <Header title=''/>
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <Box p={4} pt={1}>
                            <Box pl={2} pr={2}>
                                <Summarylabel title='Name' value={data.name}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title='Version' value={data.version}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title='Last Update' value={data.last_update.replace("T", " ")
                                    .slice(0,19)}/>
                                <Divider light variant="middle"/>
                            </Box>
                            <Box p={2}>
                                <Box pb={2}>
                                    <Typography>Configuration</Typography>
                                </Box>
                                <Box>
                                    <textarea style={{resize: "none"}} value={JSON.stringify(data.configuration, undefined, 2)} readOnly={true} cols={64} rows={20} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default ConfigurationDetail