import React, {useContext, useEffect, useMemo, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Box, Button, Divider, List, ListItem, MenuItem, Paper, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import WRow from "./WRow";
import Moment from "moment";
import ReactJson from "react-json-view";
import WhatIcon from '@material-ui/icons/LocalOfferOutlined';
import WhenIcon from '@material-ui/icons/DateRangeOutlined';
import WhereIcon from '@material-ui/icons/ExploreOutlined';
import WhyIcon from '@material-ui/icons/MyLocationOutlined';
import HowIcon from '@material-ui/icons/Timeline';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ThemeContext} from "../../contexts";
import useAxios from "axios-hooks";
import QrCodeIconButton from "../QrCodeIconButton";
import {QrCodeIcon} from "../CustomIcons";
import _ from 'lodash'
import Chip from "@material-ui/core/Chip";
import chroma from "chroma-js";
import FieldInfo from "../../pages/generate_new_event/FieldInfo";
import ListItemText from "@material-ui/core/ListItemText";
import * as PropTypes from "prop-types";
import {
    extractLocationClassCode,
    extractProductClassCode,
    findCodeTypeAndValue,
    findLocationCodeTypeValue
} from "../../utils/CodesUtils";
import {bizTransactionTypes, sourceDestinationListInputTypes, splitCbvValues} from "../../utils/CbvUtils";
import {fetchDocumentId, parseName} from "../../utils/Utils";
import CustomLoader from "../CustomLoader";
import DocumentIconButton from "../DocumentIconButton";
import {useTranslation} from "react-i18next";
import i18next from "../../i18n/i18next";
import * as sensorMockData from "../eventDetails_sensorMockData.json"
import SensorReportTable from "./SensorReportTable";
import CustomReportTable from "./CustomReportTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderRadius: '10px',
        marginBottom: '5%',
        verticalAlign: 'middle',
        alignItems: "center",
    },
    wTitle: {
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '30%',
        color: 'white',
        textAlign: 'center',
        fontSize: 'large',
        verticalAlign: 'middle'
    },
    wDetails: {
        padding: '2%'
    },
    tag: {
        display: 'inline-block',
        backgroundColor: '#f6f6f6',
        padding: '1.5%',
        borderRadius: '6px',
    },
    quantity: {
        display: 'inline-block',
        padding: '1.5%',
        borderRadius: '5px',
        marginRight: '2%',
        marginTop: '2%'
    },
    grid: {
        padding: '1.5%',
        borderRadius: '5px',
        backgroundColor: '#f6f6f6',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    expansionDetail: {
        paddingTop: '0px',
        paddingBottom: '8px'
    },
    tableBorder: {
        borderRightWidth: 1, borderRightColor: 'lightgray', borderRightStyle: 'solid',
        borderBottomWidth: 1, borderBottomColor: 'lightgray', borderBottomStyle: 'solid'
    },
    stickyCell: {
        width: '185px', minWidth: '185px', maxWidth: '185px',
        position: 'sticky', left: 0,
        background: 'whitesmoke',
        filter: 'drop-shadow(1px 1px 5px lightgray)',
        overflowWrap: 'break-word'
    }
}));

const EpcList = ({list, title, gtinNameLut}) => {
    const {primaryColor, primaryColorAlpha, accentColor} = useContext(ThemeContext)

    const classes = useStyles(accentColor);
    const mapEpc = useMemo(() => {
        if(!list)
            return null

        const map_epc = {}
        list.forEach((item) => {
            let gtin = extractProductClassCode(item.epc)

            let sgtin = []
            if (map_epc[gtin] && map_epc[gtin].sgtin) {
                sgtin = map_epc[gtin].sgtin;
                if (!sgtin.includes(item.epc)) {
                    sgtin.push(item.epc)
                }
            } else {
                sgtin.push(item.epc)
            }

            map_epc[gtin] = {
                n_items: map_epc[gtin] ? map_epc[gtin].n_items ? map_epc[gtin].n_items + 1 : 1 : 1,
                sgtin: sgtin
            }
        })
        return map_epc
    }, [list])

    return (
        <ListItem>
            <Grid container>
                <Grid item xs={3}>
                    <Typography><strong>{title}</strong></Typography>
                </Grid>
                <Grid item xs={9} style={{textAlign: "start"}}>
                    <Box>
                        {
                            mapEpc && Object.entries(mapEpc).map(([key, value], index) => {
                                    const classCode = findCodeTypeAndValue(key)
                                    return <Accordion key={index}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}>
                                            <Typography
                                                className={classes.heading}>{(gtinNameLut[key]||i18next.t('entities.noNameFound')) + " - " + (classCode[2] ? classCode[2] + ':' : '') + classCode[1] + " (" + value.n_items + ") "}</Typography>
                                            <QrCodeIcon style={{color: primaryColorAlpha, marginLeft: 'auto'}}/>
                                        </AccordionSummary>
                                        <List style={{maxHeight: '150px', overflow: 'auto'}}>
                                            {
                                                value.sgtin.map(
                                                    (sgtin) => (
                                                        <AccordionDetails key={sgtin} className={classes.expansionDetail}>
                                                            <Grid container alignItems={'center'}>
                                                                <Grid item xs={1}>
                                                                    <QrCodeIconButton sgtin={sgtin} color={primaryColor}/>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.tag}>{sgtin}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    )
                                                )
                                            }
                                        </List>
                                    </Accordion>
                                }
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </ListItem>
    )
}

const QuantityList = ({list, title, gtinNameLut}) => {
    const {primaryColor, primaryColorAlpha} = useContext(ThemeContext)

    const classes = useStyles();
    const map_quantity = {}

    list.forEach((item) => {
        let gtin = extractProductClassCode(item.quantityElement.epcClass)
        let sgtin_quantity = []
        if (map_quantity[gtin] && map_quantity[gtin].sgtin) {
            sgtin_quantity = map_quantity[gtin].sgtin;
            if (!sgtin_quantity.includes(item.quantityElement.epcClass)) {
                sgtin_quantity.push({epcClass: item.quantityElement.epcClass, quantity: item.quantityElement.quantity})
            }
        } else {
            sgtin_quantity.push({epcClass: item.quantityElement.epcClass, quantity: item.quantityElement.quantity})
        }

        map_quantity[gtin] = {
            n_items: map_quantity[gtin] ? map_quantity[gtin].n_items ? map_quantity[gtin].n_items + 1 : 1 : 1,
            sgtin: sgtin_quantity,
            uom: item.quantityElement.uom ? item.quantityElement.uom : null
        }
    })

    return (
        <ListItem>
            <Grid container>
                <Grid item xs={3}>
                    <Typography><strong>{title}</strong></Typography>
                </Grid>
                <Grid item xs={9} style={{textAlign: "start"}}>
                    <Box>
                        {
                            Object.entries(map_quantity).map(([key, value], index) => {
                                    const classCode = findCodeTypeAndValue(key)
                                    return <Accordion key={index}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                        >
                                            <Typography
                                                className={classes.heading}>{(gtinNameLut[key] || i18next.t('entities.noNameFound')) + " - " + (classCode[2] ? classCode[2] + ':' : '') + classCode[1] + (value.n_items ? " (" + value?.n_items + ") " : "")}</Typography>
                                            <QrCodeIcon style={{color: primaryColorAlpha, marginLeft: 'auto'}}/>
                                        </AccordionSummary>
                                        <List style={{maxHeight: '150px', overflow: 'auto'}}>
                                            {
                                                value.sgtin.map(
                                                    (sgtin, index) => (
                                                        <AccordionDetails key={index}
                                                                          className={classes.expansionDetail}>
                                                            <Grid container alignItems={'center'}>
                                                                <Grid item xs={1}
                                                                      style={{
                                                                          textAlign: "start",
                                                                          marginLeft: '-2%',
                                                                          marginRight: '1%'
                                                                      }}>
                                                                    <QrCodeIconButton sgtin={sgtin.epcClass}
                                                                                      color={primaryColor}/>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography
                                                                        className={classes.tag}>{sgtin.epcClass}</Typography> (Q: {sgtin.quantity} {value.uom ? value.uom : ''})
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    )
                                                )
                                            }
                                        </List>
                                    </Accordion>
                                }
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </ListItem>
    )
}

const BizTransactionList = ({list, title, dryRun, docids}) => {
    const {primaryColor, accentColor} = useContext(ThemeContext)

    let _bizTransLut = {}
    if (dryRun && docids && docids.length !== 0)
        _.forEach(docids, (association) => {
            _bizTransLut[association.doc_public_id] = {documentId: association.doc_db_id}
        })
    const [bizTransLut, setBizTranLut] = useState(dryRun && docids && docids.length !== 0 ? _bizTransLut : null)

    useEffect(() => {
        (async () => {
            if (!dryRun) {
                for (let transaction of list) {
                    if (transaction.bizTransaction) {
                        const r = await fetchDocumentId(transaction.bizTransaction)
                        if (r)
                            _bizTransLut[transaction.bizTransaction] = {documentId: r.doc_db_id, _id: r._id}
                    }
                }
                if (!_.isEmpty(_bizTransLut))
                    setBizTranLut(_bizTransLut)
            }
        })();
        return () => {
            //console.log("unmount")
        }//TODO: cancel axios
    }, [])

    return (
        <ListItem style={{flexWrap: 'wrap'}}>
            <Grid container>
                <Grid item xs={3}>
                    <Typography><strong>{title}</strong></Typography>
                </Grid>
                <Grid item xs={9} style={{textAlign: "start"}}>
                    <List dense disablePadding>
                        {
                            list.map((data, index) => (<div key={index}>
                                        <ListItem style={{flexWrap: 'wrap'}}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                {
                                                    data.bizTransaction ?
                                                        <DocumentIconButton editable={false}
                                                                            bizTransaction={data.bizTransaction}
                                                                            bizTransLut={bizTransLut} dryRun={dryRun}
                                                                            color={primaryColor}/> : null
                                                }
                                                <Chip
                                                    size={"small"}
                                                    style={{
                                                        color: chroma(accentColor).hex(),
                                                        backgroundColor: 'white',
                                                        border: `2px solid ${accentColor}`,
                                                        fontWeight: "bold",
                                                        fontSize: "inherit",
                                                        marginLeft: '3%'
                                                    }}
                                                    label={_.find(bizTransactionTypes, ['value', data.type]).name}
                                                />
                                                <FieldInfo
                                                    description={_.find(bizTransactionTypes, ['value', data.type]).description}/>
                                            </Box>
                                            <Typography style={{overflowWrap: 'anywhere'}} noWrap={false}
                                                        variant={"body2"}>{data.bizTransaction}</Typography>
                                        </ListItem>
                                        {
                                            index < list.length - 1 ?
                                                <Divider/> : null
                                        }
                                    </div>
                                )
                            )
                        }
                    </List>
                </Grid>
            </Grid>
        </ListItem>
    )
}

const SourceList = ({list, title, placeLut}) => {
    const {accentColor} = useContext(ThemeContext)

    return (
        <ListItem>
            <Grid container>
                <Grid item xs={3}>
                    <Typography><strong>{title}</strong></Typography>
                </Grid>
                <Grid item xs={9} style={{textAlign: "start"}}>
                    <List dense disablePadding>
                        {
                            list.map((data, index) => (<div key={index}>
                                        <ListItem style={{flexWrap: 'wrap'}}>
                                            <Chip
                                                size={"small"}
                                                style={{
                                                    color: chroma(accentColor).hex(),
                                                    backgroundColor: 'white',
                                                    border: `2px solid ${accentColor}`,
                                                    fontWeight: "bold",
                                                    fontSize: "inherit"
                                                }}
                                                label={_.find(sourceDestinationListInputTypes, ['value', data.type]).name}
                                            />
                                            <FieldInfo
                                                description={_.find(sourceDestinationListInputTypes, ['value', data.type]).description}/>
                                            <LocationField data={data.source} placeLut={placeLut}/>
                                        </ListItem>
                                        {
                                            index < list.length - 1 ?
                                                <Divider/> : null
                                        }
                                    </div>
                                )
                            )
                        }
                    </List>
                </Grid>
            </Grid>
        </ListItem>
    )
}

function LocationField(props) {
    /** props.data => instance code
     * placeLut => {gln: {gln, name, address...}}
     * TODO: non ho lut per SUBplaces */
    const classCode = extractLocationClassCode(props.data)
    return <ListItemText
        primary={(props.data && props.placeLut[classCode] ?
            props.placeLut[classCode].name : classCode)
        + (props.data && props.placeLut[classCode] && props.placeLut[classCode].address ?
            (', ' + props.placeLut[classCode].address) : "")
        }
        secondary={props.data ? i18next.t('entities.location.code') + ": " + findLocationCodeTypeValue(props.data)[1] : ""}
    />;
}

LocationField.propTypes = {
    data: PropTypes.any,
    placeLut: PropTypes.any
};
const DestinationList = ({list, title, placeLut}) => {
    const {accentColor} = useContext(ThemeContext)
    //console.log("list:",list)
    return (
        <ListItem>
            <Grid container>
                <Grid item xs={3}>
                    <Typography><strong>{title}</strong></Typography>
                </Grid>
                <Grid item xs={9} style={{textAlign: "start"}}>
                    <List dense disablePadding>
                        {
                            list.map((data, index) => (<div key={index}>
                                    <ListItem style={{flexWrap: 'wrap'}}>
                                        <Chip
                                            size={"small"}
                                            style={{
                                                color: chroma(accentColor).hex(),
                                                backgroundColor: 'white',
                                                border: `2px solid ${accentColor}`,
                                                fontWeight: "bold",
                                                fontSize: "inherit"
                                            }}
                                            label={_.find(sourceDestinationListInputTypes, ['value', data.type]).name}
                                        />
                                        <FieldInfo
                                            description={_.find(sourceDestinationListInputTypes, ['value', data.type]).description}/>
                                        <LocationField data={data.destination} placeLut={placeLut}/>
                                    </ListItem>
                                    {
                                        index < list.length - 1 ?
                                            <Divider/> : null
                                    }
                                </div>
                            ))
                        }
                    </List>
                </Grid>
            </Grid>
        </ListItem>
    )
}

const IlmdList = ({list, title}) => {

    const classes = useStyles();

    return (
        <ListItem>
            <Grid container>
                <Grid item xs={3}>
                    <Typography><strong>{title}</strong></Typography>
                </Grid>
                <Grid item xs={9} style={{textAlign: "start"}}>
                    <Box>
                        {
                            list.map(
                                (data, index) => (
                                    <Box key={index} pb={1}>
                                        <Grid container className={classes.grid}>
                                            {/*<Grid item xs={1}>{data.namespace}</Grid>*/}
                                            <Grid item xs={6}>{data.name}</Grid>
                                            <Grid item xs={6}>{data.value}</Grid>
                                        </Grid>
                                    </Box>
                                )
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </ListItem>
    )
}

const WhatEventDetails = ({data, dryRun, docids, gtinNameLut}) => {

    const classes = useStyles();
    const classCode = extractProductClassCode(data.parentID)
    const code = findCodeTypeAndValue(classCode)

    return (
        <div>
            <List>
                {
                    data.eventName ? (
                        <Box key={"name"}>
                            <WRow label={"Name"}
                                  value={parseName(data.eventName[0].toUpperCase() + data.eventName.slice(1))}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                <WRow label={"Event Type"} value={data.type}/>
                <Divider variant="middle"/>
                {
                    !dryRun ?
                        <Box key={"eventID"}>
                            <WRow label={"Event ID"} value={data.eventID}/>
                            <Divider variant="middle"/>
                        </Box> : null
                }
                {
                    data.parentID != null ?
                        <Box key={"parentID"}>
                            <WRow label={"Parent ID"}
                                  value={gtinNameLut[classCode] ? (gtinNameLut[classCode] + " - " + (code[2] ? code[2] + ':' : '') + code[1]) : data.parentID}
                                  tooltipText={gtinNameLut[classCode] && ('Complete code: ' + data.parentID)}
                                  classes={classes}
                                  qrCode={data.parentID}
                            />
                            <Divider variant="middle"/>
                        </Box>
                        : null
                }
                {
                    data.epcList.length && data.epcList.length > 0 ? (
                        <Box key={"epcList"}>
                            <EpcList list={data.epcList} title={"Epc List"} gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.inputEPCList.length && data.inputEPCList.length > 0 ? (
                        <Box key={"inputEPCList"}>
                            <EpcList list={data.inputEPCList} title={"Input EPC List"} gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.outputEPCList.length && data.outputEPCList.length > 0 ? (
                        <Box key={"outputEPCList"}>
                            <EpcList list={data.outputEPCList} title={"Output EPC List"} gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.inputQuantityList.length && data.inputQuantityList.length > 0 ? (
                        <Box key={"inputQuantityList"}>
                            <QuantityList list={data.inputQuantityList} title={"Input Quantity List"}
                                          gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.outputQuantityList.length && data.outputQuantityList.length > 0 ? (
                        <Box key={"outputQuantityList"}>
                            <QuantityList list={data.outputQuantityList} title={"Output Quantity List"}
                                          gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.extension.quantityList.length && data.extension.quantityList.length > 0 ? (
                        <Box key={"quantityList"}>
                            <QuantityList list={data.extension.quantityList} title={"Quantity List"}
                                          gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.childEPCs.length && data.childEPCs.length > 0 ? (
                        <Box key={"childEPCs"}>
                            <EpcList list={data.childEPCs} title={"Child EPC List"}
                                     gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.extension.childQuantityList.length && data.extension.childQuantityList.length > 0 ? (
                        <Box key={"childQuantityList"}>
                            <QuantityList list={data.extension.childQuantityList} title={"Child Quantity List"}
                                          gtinNameLut={gtinNameLut}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.bizTransactionList.length && data.bizTransactionList.length > 0 ? (
                        <Box key={"bizTransactionList"}>
                            <BizTransactionList list={data.bizTransactionList} title={"Business Transaction List"}
                                                dryRun={dryRun} docids={docids}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
                {
                    data.extension.ilmd.length && data.extension.ilmd.length > 0 ? (
                        <Box key={"ilmd"}>
                            <IlmdList list={data.extension.ilmd} title={"Instance/Lot Master Data"}/>
                            <Divider variant="middle"/>
                        </Box>
                    ) : null
                }
            </List>
        </div>
    )
}

const WhenEventDetails = ({data}) => {
    const setData = (value) => {
        const formattedDate = i18next.t("formatters:dateTimeLong", {date: Moment(value)})
        return formattedDate //Moment(value).format('MMM Do \'YY, h:mm a')
    }

    return (
        <div>
            <List>
                <WRow label={"Event Time"} value={setData(data.eventTime)}/>
                <Divider variant="middle"/>
                <WRow label={"Record Time"} value={setData(data.recordTime)}/>
                <Divider variant="middle"/>
                <WRow label={"Event Timezone Offset"} value={data.eventTimeZoneOffset}/>
                <Divider variant="middle"/>
            </List>
        </div>
    )
}

const WhereEventDetails = ({data}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [{data: places, loading: loadingPlaces, error: errorPlaces}, postExecutePlaces] = useAxios({
            url: "/api/places/_by_codes/",
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    const [{data: subplaces, loading: loadingSubPlaces, error: errorSubPlaces}, postExecuteSubPlaces] = useAxios({
            url: "/api/subplaces/_by_codes/",// + mysglns.toString(),
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    useEffect(() => {
        var locationClassCodes = []
        var locationInstanceCodes = []
        if (data.bizLocation) locationClassCodes.push(extractLocationClassCode(data.bizLocation))
        if (data.readPoint) locationInstanceCodes.push(data.readPoint)
        if (data.extension.sourceList && data.extension.destinationList.length > 0)
            data.extension.sourceList.forEach((item) => locationClassCodes.push(extractLocationClassCode(item.source)))
        if (data.extension.destinationList && data.extension.destinationList.length > 0)
            data.extension.destinationList.forEach((item) => locationClassCodes.push(extractLocationClassCode(item.destination)))

        locationClassCodes.length > 0 && postExecutePlaces({data: {codes: _.uniq(locationClassCodes)}})
        locationInstanceCodes.length > 0 && postExecuteSubPlaces({data: {codes: _.uniq(locationInstanceCodes)}})
        //return [locationClassCodes, locationInstanceCodes]
    }, [data, postExecutePlaces, postExecuteSubPlaces])

    const placeLut = useMemo(() => {
        if (!places)
            return {}

        const lut = {};
        places.data.forEach((place) => {
            lut[place.gln] = place
        })

        return lut
    }, [places])

    const subplaceLut = useMemo(() => {
        if (!subplaces)
            return {}
        const lut = {};
        subplaces.data.forEach((subplace) => {
            lut[subplace.place_id] = subplace
        })
        return lut
    }, [subplaces])

    const locationsLut = useMemo(() => {
        //console.log("placeLut:",placeLut)
        //console.log("subplaceLut:",subplaceLut)
        return Object.assign({}, placeLut, subplaceLut)
    }, [placeLut, subplaceLut])

    return (
        <div>
            {loadingPlaces || loadingSubPlaces ? <CustomLoader size={40}/> : null}
            {errorPlaces || errorSubPlaces ?
                <Typography style={{color: 'darkgrey'}}>{t('errors.genericServerError')}</Typography> : null}
            {locationsLut ?// && Object.keys(locationsLut).length > 0 ?
                <List>
                    {
                        data.bizLocation ? (
                            <Box>
                                <WRow label={"Business Location"}
                                      value={<LocationField data={data.bizLocation} placeLut={placeLut}/>}
                                      classes={classes}/>
                                <Divider variant="middle"/>
                            </Box>) : null
                    }
                    {
                        data.readPoint ? (
                            <Box>
                                <WRow label={"Read Point"}
                                      value={<LocationField data={data.readPoint} placeLut={subplaceLut}/>}
                                      classes={classes}/>
                                <Divider variant="middle"/>
                            </Box>
                        ) : null
                    }
                    {
                        data.extension.sourceList && data.extension.sourceList.length > 0 ? (
                            <Box>
                                <SourceList placeLut={placeLut} list={data.extension.sourceList}
                                            title={"Source List"}/>
                                <Divider variant="middle"/>
                            </Box>
                        ) : null
                    }
                    {
                        data.extension.destinationList && data.extension.destinationList.length > 0 ? (
                            <Box>
                                <DestinationList placeLut={placeLut} list={data.extension.destinationList}
                                                 title={"Destination List"}/>
                                <Divider variant="middle"/>
                            </Box>
                        ) : null
                    }
                </List> : null
            }
        </div>
    )
}

const WhyEventDetails = ({data}) => {

    const classes = useStyles();

    return (
        <div>
            <List>
                <WRow label={"Business Step"} value={splitCbvValues(data.bizStep)} classes={classes}
                      bizStep={data.bizStep}/>
                <Divider variant="middle"/>
                {
                    data.disposition ?
                        <WRow label={"Disposition"} value={splitCbvValues(data.disposition)}
                              disposition={data.disposition} classes={classes}/> : null
                }
                <Divider variant="middle"/>
                {
                    data.action ?
                        <WRow label={"Action"} value={data.action} classes={classes}/> : null
                }
                <Divider variant="middle"/>
            </List>
        </div>
    )
}

const HowEventDetails = ({data}) => {
    //console.log("HowEventDetails --> report:", report)
    const classes = useStyles();

    /** TODO: collegare ai sensorElementList reale appena servirà */
    const sensorElementList = sensorMockData.default

    const [rawView, setRawView] = useState(false)
    const [selectedExample, setSelectedExample] = useState({key: 'sensorElementList1', value: sensorElementList['sensorElementList1']});

    const handleChange = (event) => {
        setSelectedExample({key: event.target.value, value: sensorElementList[event.target.value]});
    };

    return (
        <div>
            {process.env.REACT_APP_SENSOR_ELEMENT_SHOW === 1 && <>
            <Select
                value={selectedExample.key}
                onChange={handleChange}>
                {_.orderBy(Object.keys(sensorElementList)).map((exampleKey) => (
                    <MenuItem key={exampleKey} value={exampleKey}>{exampleKey}</MenuItem>
                ))}
            </Select>
            <Button variant={'outlined'} size={'small'} onClick={() => setRawView(!rawView)}>{rawView ? i18next.t('entities.viewMode.parsed') : i18next.t('entities.viewMode.raw') }</Button>
                <Typography align={'center'} gutterBottom>Sensors Report</Typography>
                {selectedExample.value ?
                    rawView ?
                        <ReactJson src={selectedExample.value} style={{marginLeft: '5%'}}/>
                        : <SensorReportTable data={selectedExample.value} classes={classes}/>
                    : <Typography>{i18next.t('errors.noDataToDisplay')}</Typography>}
                </>}
            {data?.ld_extensions && !_.isEmpty(data?.ld_extensions) &&
                <>
                    <Typography align={'center'} gutterBottom style={{marginTop: '1%'}}>{i18next.t('entities.customReport')}</Typography>
                    <CustomReportTable data={data.ld_extensions} classes={classes}/>
                </>}
        </div>
    )
}

const WTableDetail = ({title, bgColor, wData, dryRun, docids, gtinNameLut}) => {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.root}>
                <Grid container justifyContent={"center"} alignContent={"center"}>
                    <Grid item xs={2}>
                        <Paper style={{backgroundColor: bgColor}} className={classes.wTitle}>
                            <Typography variant={'h6'}><strong>{title}</strong> </Typography>
                            {title === "What" ?
                                <Box pt={2}>
                                    <WhatIcon/>
                                </Box>
                                : title === "When" ?
                                    <Box pt={2}>
                                        <WhenIcon/>
                                    </Box>
                                    : title === "Where" ?
                                        <Box pt={2}>
                                            <WhereIcon/>
                                        </Box>
                                        : title === "Why" ?
                                            <Box pt={2}>
                                                <WhyIcon/>
                                            </Box>
                                            : title === "How" ?
                                                <Box pt={2}>
                                                    <HowIcon/>
                                                </Box>
                                                : null
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={10} className={classes.wDetails}>
                        {title === "What" ?
                            <WhatEventDetails data={wData} dryRun={dryRun} docids={docids} gtinNameLut={gtinNameLut}/>
                            : title === "When" ?
                                <WhenEventDetails data={wData}/>
                                : title === "Where" ?
                                    <WhereEventDetails data={wData}/>
                                    : title === "Why" ?
                                        <WhyEventDetails data={wData}/>
                                        : title === "How" ?
                                            <HowEventDetails data={wData}/>
                                            : null
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

const EventDetail = ({data, dryRun, docids, gtinNameLut}) => {
    const {t} = useTranslation();
    console.log("EventDetail ---> data:", data)

    const classes = useStyles();
    const [rawView, setRawViewMode] = useState(false);
    const {primaryColor} = useContext(ThemeContext)

    const [{data: names, loading: loadingName, error: errorName}, postExecute] = useAxios(
        {
            url: "api/items/_by_keys/",
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    useEffect(() => {
        if (!gtinNameLut) {
            var gtins = []
            const _parentID = extractProductClassCode(data.parentID)
            const _parentID2 = data.parentID
            if (data.parentID !== null) gtins.push(_parentID || _parentID2)
            if (data.epcList.length && data.epcList.length > 0) data.epcList.forEach((item) => gtins.push(extractProductClassCode(item.epc)))
            if (data.childEPCs.length && data.childEPCs.length > 0) data.childEPCs.forEach((item) => gtins.push(extractProductClassCode(item.epc)))
            if (data.inputEPCList.length && data.inputEPCList.length > 0) data.inputEPCList.forEach((item) => gtins.push(extractProductClassCode(item.epc)))
            if (data.outputEPCList.length && data.outputEPCList.length > 0) data.outputEPCList.forEach((item) => gtins.push(extractProductClassCode(item.epc)))
            if (data.inputQuantityList.length && data.inputQuantityList.length > 0) data.inputQuantityList.forEach((item) => gtins.push(extractProductClassCode(item.quantityElement.epcClass)))
            if (data.outputQuantityList.length && data.outputQuantityList.length > 0) data.outputQuantityList.forEach((item) => gtins.push(extractProductClassCode(item.quantityElement.epcClass)))
            if (data.extension.quantityList.length && data.extension.quantityList.length > 0) data.extension.quantityList.forEach((item) => gtins.push(extractProductClassCode(item.quantityElement.epcClass)))
            if (data.extension.childQuantityList.length && data.extension.childQuantityList.length > 0) data.extension.childQuantityList.forEach((item) => gtins.push(extractProductClassCode(item.quantityElement.epcClass)))
            gtins = gtins.filter((item, index) => gtins.indexOf(item) === index)
            //console.log("useEffect ---> mygtins:",gtins)

            postExecute({data: {keys: gtins}})
        }
    }, [gtinNameLut, data, postExecute]);

    /*const [{data: names, loadingName, errorName}, post] = useAxios({
            url: "/api/items/_by_keys/" + mygtins.toString(),
        },
        {useCache: false}
    )*/

    var namesLut = useMemo(() => {
        if (!gtinNameLut) {
            if (!names)
                return null

            var nameLut = {};
            if (names && names.data.length > 0) {
                //console.log("gtinNameLut useMemo ---> names:",names)
                names.data.forEach((item) => {
                    //nameLut[String(item.key).replace("urn:gs1:gtin14:", "")] = item.name
                    nameLut[item.key] = item.name
                })
            }
            //console.log('creo LUT NAME --->', nameLut)
            return nameLut
        } else return gtinNameLut
    }, [names, gtinNameLut])

    const onButtonClick = () => {
        setRawViewMode(!rawView);
    }

    return (
        namesLut ?
            <Box>
                <Box pb={2}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                                //startIcon={rawView ? <ListIcon/> : <MapIcon/>}
                                onClick={onButtonClick}
                            >
                                {rawView ? t('entities.viewMode.parsed') : t('entities.viewMode.raw')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {
                    rawView ? <ReactJson src={data}/> :
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <WTableDetail title={"What"} bgColor={primaryColor} wData={data}
                                              dryRun={dryRun} docids={docids}
                                              gtinNameLut={namesLut}/>
                                <WTableDetail title={"When"} bgColor={primaryColor} wData={data}/>
                                <WTableDetail title={"Where"} bgColor={primaryColor} wData={data}/>
                                <WTableDetail title={"Why"} bgColor={primaryColor} wData={data}/>
                                {(data.ld_extensions?.length > 0
                                    || data.sensorElementList?.length > 0)
                                && <WTableDetail title={"How"} bgColor={primaryColor} wData={data}/>}
                            </Grid>
                        </Grid>
                }
            </Box> :
            !gtinNameLut && loadingName ?
                <CustomLoader text={t('actions.loading')} size={80}/> :
                !gtinNameLut && errorName ? <p>{t('errors.genericServerError')}</p> :
                    <CustomLoader text={t('actions.loading')} size={80}/>

    )
}


export default EventDetail
