import React from "react";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import {Controller} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    error: {
        color: 'red',
        margin: '0px'
    },
    preview: {
        width: "160px",
        height: "170px"
    },
    grid: {
        [theme.breakpoints.down('sm')]: {
            //minHeight: '300px'
        },
    }
}));

const ConfInfo = ({control, errors, edit}) => {

    const classes = useStyles();

    const ConfigurationNameInput = ({value, onChange}) => {
        return (
            <TextField disabled={edit} fullWidth label="Name" value={value || ''} onChange={onChange}/>
        )
    };

    const VersionInput = ({value, onChange}) => {
        return (
            <TextField fullWidth label="Version" value={value || ''} onChange={onChange}/>
        )
    };

    const ConfJsonTextArea = ({value, onChange}) => {
        return (
            <TextField multiline={true} variant={"outlined"} placeholder={"Enter here your configuration"} style={{resize: "none"}} rows={15} value={value || ''} onChange={onChange}/>
        )
    }

    return (
        <Box pl={2} pr={2}>
            <Grid container spacing={3} style={{height: '100%'}}>
                <Grid container item xs={2} md={6}>
                    <Grid container direction="column">
                        <Controller
                            as={ConfigurationNameInput}
                            name="name"
                            control={control}
                        />
                        {errors.name &&
                        <p className={classes.error} >{errors.name.message}</p>}
                    </Grid>
                    <Grid container direction="column">
                        <Box mt={1}>
                            <Controller
                                as={VersionInput}
                                name="version"
                                control={control}
                            />
                            {errors.version &&
                            <p className={classes.error} >{errors.version.message}</p>}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={6} className={classes.grid}>
                    <Grid item container pr={20} xs={12} direction="column"
                          alignItems="stretch" style={{marginLeft: 50}}>
                        <Controller
                            as={<ConfJsonTextArea/>}
                            name="configuration"
                            control={control}
                        />
                        {errors.configuration &&
                        <p className={classes.error} >{errors.configuration.message}</p>}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ConfInfo
