import React from "react";
import {Typography, Paper, Box} from "@material-ui/core";
import CustomMap from "../../components/CustomMap";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";

const MapViewPlaces = ({places}) => {
    const {t} = useTranslation()
    //console.log("MAP --> PLACES: ", places.data)
    return (
        <Paper>
            <Grid container alignItems={"center"} style={{paddingLeft:'24px', paddingRight:'8px', minHeight:'64px'}}>
                <Grid item xs={12} >
                    <Typography variant="h6">{t('entities.viewMode.map')}</Typography>
                </Grid>
            </Grid>
            <Box p={3} pt={0}>
                {<CustomMap places={places}/>}
            </Box>
        </Paper>
    );
}

export default MapViewPlaces;
