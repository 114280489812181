import React, {useState} from "react";
import axios from "axios";
import {Divider, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const DevicesMonitoring = () => {

    const useStyles = makeStyles(() => ({
        select: {
            marginBottom: 16,
        },
        panel: {
            marginBottom: 8,
            marginTop: 16
        },
    }));

    const classes = useStyles();

    const [dashboardUrl, setDashboardUrl] = useState("")

    const [timeRange, setTimeRange] = useState("15m");

    const handleChange = (event) => {
        setTimeRange(event.target.value);
    };

    axios.get('/api/grafana_data/get_data/')
            .then((res) => {
                setDashboardUrl(res.data.dashboard_url);
            })

    return (
        <div>
            <Select
                inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                }}
                className={classes.select}
                value={timeRange}
                onChange={handleChange}
            >
                <MenuItem value={"5m"}>Last 5 minutes</MenuItem>
                <MenuItem value={"15m"}>Last 15 minutes</MenuItem>
                <MenuItem value={"30m"}>Last 30 minutes</MenuItem>
                <MenuItem value={"1h"}>Last 1 hour</MenuItem>
                <MenuItem value={"3h"}>Last 3 hours</MenuItem>
                <MenuItem value={"6h"}>Last 6 hours</MenuItem>
                <MenuItem value={"12h"}>Last 12 hours</MenuItem>
                <MenuItem value={"24h"}>Last 24 hours</MenuItem>
                <MenuItem value={"2d"}>Last 2 days</MenuItem>
                <MenuItem value={"7d"}>Last 7 days</MenuItem>
                <MenuItem value={"30d"}>Last 30 days</MenuItem>
                <MenuItem value={"90d"}>Last 90 days</MenuItem>
                <MenuItem value={"6M"}>Last 6 months</MenuItem>
                <MenuItem value={"1y"}>Last 1 year</MenuItem>
                <MenuItem value={"2y"}>Last 2 years</MenuItem>
                <MenuItem value={"5y"}>Last 5 years</MenuItem>
            </Select>
            <iframe
                title={"1"}
                className={classes.panel}
                src={dashboardUrl + "1&from=now-" + timeRange + "&to=now"}
                    width="100%" height="300" frameBorder="0"/>
            <Divider/>
            <iframe
                title={"2"}
                className={classes.panel}
                src={dashboardUrl + "2&from=now-" + timeRange + "&to=now"}
                width="100%" height="300" frameBorder="0"/>
            <Divider/>
            <iframe
                title={"3"}
                className={classes.panel}
                src={dashboardUrl + "3&from=now-" + timeRange + "&to=now"}
                width="100%" height="300" frameBorder="0"/>
            <Divider/>
            <iframe
                title={"4"}
                className={classes.panel}
                src={dashboardUrl + "4&from=now-" + timeRange + "&to=now"}
                width="100%" height="300" frameBorder="0"/>
        </div>
    )

}

export default DevicesMonitoring;
