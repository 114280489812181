import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import {AuthContext, CreateEventContext, ThemeContext} from "../../../contexts";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import {makeStyles} from "@material-ui/core/styles";
import {WhiteListComponent} from "./WhiteListComponent";
import SelectInputTypeComponent from "./SelectInputTypeComponent";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useWindowSize} from "../../../utils/Utils";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import _ from "lodash";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import PrecedenceComponent from "./PrecedenceComponent";
import {eventFieldNameTypes, eventTypes, inputTypes, splitCbvValues} from "../../../utils/CbvUtils";
import i18next from "../../../i18n/i18next";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import useAxios from "axios-hooks";

const WhatComponent = ({handleChange, formData, setStepValidation}) => {
    const size = useWindowSize();

    const {primaryColor, backgroundColor, iconHoverBg} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        icon: {
            color: 'darkgray',
            '&:hover': {
                background: iconHoverBg,
            },
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        dottedDiv: {
            border: '2px solid lightGray',
            borderRadius: '15px',
            borderStyle: 'dotted',
            marginBottom: '3%',
            flex: 1
        }
    }));
    const classes = useStyles()
    const eventContext = useContext(CreateEventContext);
    const [bizTransactionDescription, setBizTransactionDescription] = useState({})

    const okayData = useMemo(() => {
        let isPartiallyFixed = formData.bizTransactionListInputType === inputTypes.PARTIALLY_FIXED.value
        return isPartiallyFixed ? (formData.bizTransactionList && formData.bizTransactionList.length > 0) : true
    }, [formData])

    useEffect(() => {
        setStepValidation(okayData)
    }, [okayData, setStepValidation])

    useEffect(() => {
        if (formData.bizTransactionList && Array.isArray(formData.bizTransactionList)) {
            let descriptions = {}
            for (let bizType of formData.bizTransactionList) {
                descriptions[bizType] = i18next.t(`cbvValues.bizTransTypes.${_.camelCase(splitCbvValues(bizType))}.description`)
            }
            setBizTransactionDescription(descriptions)
        }
    }, [formData.bizTransactionList])

    const ListComponent = ({formDataFieldName, formDataFieldValue, formLabel, formSubLabel, outputList}) => {
        const [value, setValue] = useState(formDataFieldValue);
        const [helperText, setHelperText] = useState(_.find(eventFieldNameTypes, ['type', formDataFieldValue]).description);
        const [isStrict, setStrict] = useState(outputList ? formData.strictedWhitelistOut : formData.strictedWhitelist)

        const GrayCheckbox = withStyles({
            root: {
                '&$checked': {
                    color: primaryColor,
                },
            },
            checked: {},
        })((props) =>
            <Checkbox color="default" {...props} />);

        const handleRadioChange = (event) => {
            setValue(event.target.value)
            handleChange(formDataFieldName, event.target.value)
            setHelperText(_.find(eventFieldNameTypes, ['type', event.target.value]).description)
        };

        const onStrictChange = (event, outputList) => {
            setStrict(event.target.checked);
            handleChange(outputList ? 'strictedWhitelistOut' : 'strictedWhitelist', event.target.checked)
        };

        const {isAdmin, activeMainDomain} = useContext(AuthContext);

        const [state, setState] = useState({
            chosenDomain: eventContext.chosenDomain || null,
        })

        const [{data: items, loading: loadingItems, error: errorItems},] = useAxios({
                url: !state.chosenDomain?.other ?
                ("/api/items/?domain=" + state.chosenDomain?.domain + "&producer=true&not_producer=false")
                : "/api/items/?domain=" + activeMainDomain + "&producer=false&not_producer=true",
            },
            {useCache: false}
        )

        return (
            <Box p={2}>
                <div>
                    <FormLabel component="legend">{formLabel}</FormLabel>
                    {formSubLabel ?
                        <FormLabel className={classes.subLabel}>{formSubLabel}</FormLabel> : null}
                    <RadioGroup name={formDataFieldName + "RadioGroup"} value={value}
                                onChange={handleRadioChange}>
                        <FormControlLabel value={eventFieldNameTypes.EPCLIST.type} control={<Radio color={"default"}/>}
                                          label={i18next.t('cbvValues.epcList.label', "EPC List")}/>
                        <FormControlLabel value={eventFieldNameTypes.QUANTITYLIST.type}
                                          control={<Radio color={"default"}/>}
                                          label={i18next.t('cbvValues.quantityList.label', "Quantity List")}/>
                        <FormControlLabel value={eventFieldNameTypes.EPC_QUANTITY_LIST.type}
                                          control={<Radio color={"default"}/>}
                                          label={i18next.t('cbvValues.bothListType.label', 'Both List Type')}/>
                    </RadioGroup>
                    <FormHelperText>{helperText}</FormHelperText>
                </div>
                <Box mt={2} display={'flex'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                    <Autocomplete
                        value={state.chosenDomain}
                        style={{flex: 2}}
                        disableClearable
                        options={!isAdmin ? [...eventContext.activeDomains, {name: "Partners' Domains", other: true}] : eventContext.activeDomains}
                        getOptionLabel={(option) => !option.other ? option.name + ' - ' + option.domain : option.name}
                        onChange={(event, newValue) => {
                            eventContext.chosenDomain = newValue
                            setState({...state, chosenDomain: newValue})
                        }}
                        renderInput={(params) => <TextField {...params}
                                                            size={'small'}
                                                            label={i18next.t('entities.company.companyDomain')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    !state.chosenDomain?.other && (state.chosenDomain?.image || state.chosenDomain?.img)
                                                                    && <Box p={1}><Avatar
                                                                        src={(state.chosenDomain.image || state.chosenDomain?.img) ? ("api/uploads/uploads/" + (state.chosenDomain.image || state.chosenDomain?.img)) : null}>
                                                                    </Avatar></Box>
                                                                ),
                                                            }}
                        />}
                        renderOption={(option, {selected}) => {
                            return (<React.Fragment>
                                    {(!option.other && (option.image || option.img)) && <ListItemAvatar>
                                        <Avatar
                                            src={(option.image || option.img) ? ("api/uploads/uploads/" + (option.image || option.img)) : null}>
                                        </Avatar>
                                    </ListItemAvatar>}
                                    <ListItemText
                                        primary={`${option.name}`}
                                        secondary={!option.other && option.domain}
                                        style={{color: primaryColor}}/>
                                </React.Fragment>
                            )
                        }}
                    />
                    <Box flex={4} ml={2} display={'flex'} flexDirection={'column'}>
                        <WhiteListComponent
                            options={items?.data||[]}
                            disabled={loadingItems}
                            multiple={true}
                            formDataFieldName={outputList ? 'whiteListedGtinsOut' : 'whiteListedGtins'}
                            formDataFieldValue={outputList ? formData.whiteListedGtinsOut : formData.whiteListedGtins}
                            formLabel={i18next.t('createEventModel.enterItemsYouWantToObserve', 'Enter the items you want to observe')}
                            handleChange={handleChange}/>
                        <Box ml={'auto'}><FormControlLabel
                            labelPlacement={'start'}
                            control={
                                <GrayCheckbox
                                    checked={isStrict}
                                    onChange={e => onStrictChange(e, outputList)}
                                />}
                            label={i18next.t('createEventModel.allowOnlyObservedItems', "Allow only observed products")}
                        /></Box>
                        {errorItems && <Typography align={'center'} style={{color: 'darkgrey'}}>{i18next.t('errors.genericServerError')}</Typography>}
                    </Box>
                </Box>
            </Box>
        )
    }

    const AutoCompleteComponent = ({
                                       options,
                                       multiple,
                                       formDataFieldName,
                                       formDataFieldValue,
                                       handleChange,
                                       formLabel
                                   }) => {
        const [value, setValue] = useState(formDataFieldValue ? formDataFieldValue : []);
        //const [helperText, setHelperText] = useState();
        const {primaryColorAlpha} = useContext(ThemeContext)

        return (
            <Box p={2} style={{flex: '2'}}>
                <Autocomplete
                    multiple={multiple}
                    clearOnEscape={true}
                    options={options}
                    filterSelectedOptions
                    getOptionLabel={(option) => option}
                    value={value}
                    getOptionSelected={(option, value) => false}
                    onChange={(event, newValue) => {
                        setValue(newValue)
                        handleChange(formDataFieldName, newValue)
                    }}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={splitCbvValues(option)}
                                style={{backgroundColor: primaryColorAlpha, opacity: '0.8'}}
                                {...getTagProps({index})}
                            />
                        ))
                    }
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className={classes.name}>{splitCbvValues(option)}</Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                variant={"outlined"}
                                label={formLabel}
                                error={!okayData}/>
                        );
                    }}
                />
                {
                    !okayData ?
                        <FormHelperText
                            style={{color: 'red'}}>{i18next.t('entities.requiredField', "This field is required")}</FormHelperText> : null
                }
            </Box>
        )
    }

    const getWhatComponent = () => {
        if (formData.eventType === eventTypes.OBJECT.value)
            return <div>
                <ListComponent
                    formDataFieldName={'itemListType'}
                    formDataFieldValue={formData.itemListType}
                    formLabel={i18next.t('createEventModel.what.kindOfItemToTrace', 'What kind of item do you want to trace?')}
                />
            </div>
        if (formData.eventType === eventTypes.TRANSACTION.value)
            return <div>
                <div className={classes.dottedDiv}>
                    <ListComponent
                        formDataFieldName={'itemListType'}
                        formDataFieldValue={formData.itemListType}
                        formLabel={i18next.t('createEventModel.what.kindOfItemToTrace')}
                    />
                </div>
                <SelectInputTypeComponent options={[inputTypes.EXTERNAL.value, inputTypes.PARTIALLY_FIXED.value]}
                                          formLabel={i18next.t('createEventModel.what.chooseBizTransactionListInputType', "Choose the Business Transaction List Input Type")}
                                          formDataFieldName={'bizTransactionListInputType'}
                                          formDataFieldValue={formData.bizTransactionListInputType}
                                          handleChange={handleChange}
                                          disabled={false}
                />
                {
                    formData.bizTransactionListInputType === inputTypes.PARTIALLY_FIXED.value ?
                        <Box>
                            <AutoCompleteComponent
                                options={eventContext.cbvValues.biz_transaction_types}
                                multiple={true}
                                formLabel={i18next.t('createEventModel.what.chooseBizTransactionTypes', "Choose the Business Transaction types")}
                                formDataFieldName={'bizTransactionList'}
                                formDataFieldValue={formData.bizTransactionList}
                                handleChange={handleChange}
                            />
                            <Box p={2} pt={0}>
                                {
                                    !_.isEmpty(bizTransactionDescription) ?
                                        _.map(bizTransactionDescription, (val, key) => {
                                            if (val) {
                                                return (
                                                    <Accordion
                                                        style={{backgroundColor: backgroundColor, color: primaryColor}}
                                                        key={key}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon style={{color: primaryColor}}/>}
                                                        >
                                                            <Typography
                                                                className={classes.heading}>{i18next.t('entities.moreInfo', 'More Information')} - {splitCbvValues(key)}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                {val}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            }
                                        })
                                        : null
                                }
                            </Box>
                        </Box>
                        : null
                }
            </div>
        if (formData.eventType === eventTypes.TRANSFORMATION.value)
            return <div style={{display: size.width > 900 ? 'flex' : 'grid', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <div className={classes.dottedDiv}>
                    <ListComponent
                        formDataFieldName={'inputItemListType'}
                        formDataFieldValue={formData.inputItemListType}
                        formLabel={i18next.t('createEventModel.what.kindOfInputItemToTrace', 'Choose the kind of INPUT item you want to trace')}
                        outputList={false}
                    />
                </div>
                <DoubleArrowIcon fontSize={"large"}
                                 style={{
                                     marginRight: '3%',
                                     marginLeft: '3%',
                                     marginTop: 'auto',
                                     marginBottom: 'auto',
                                     color: primaryColor
                                 }}/>
                <div className={classes.dottedDiv}>
                    <ListComponent
                        formDataFieldName={'outputItemListType'}
                        formDataFieldValue={formData.outputItemListType}
                        formLabel={i18next.t('createEventModel.what.kindOfOutputItemToTrace', 'Choose the kind of OUTPUT item you want to trace')}
                        outputList={true}
                    />
                </div>
            </div>
        if (formData.eventType === eventTypes.AGGREGATION.value || formData.eventType === eventTypes.ASSOCIATION.value) {
            const label = formData.eventType === eventTypes.AGGREGATION.value ?
                i18next.t('createEventModel.what.kindOfItemToAggregate')
                : i18next.t('createEventModel.what.kindOfItemToAssociate')
            const subLabel = i18next.t('createEventModel.what.directionGivenBybizStep')
            return <div>
                <ListComponent
                    formDataFieldName={'childListType'}
                    formDataFieldValue={formData.childListType}
                    formLabel={label}
                    formSubLabel={subLabel}
                />
            </div>
        }
    }

    return (
        <div>
            {getWhatComponent()}
            <PrecedenceComponent
                handleChange={handleChange}
                formDataFieldName={'precedence'}
                formDataFieldValue={formData.precedence}
                formLabel={i18next.t('createEventModel.what.precedence', {eventName: formData.eventName})}/>
        </div>
    )
}

export default WhatComponent
