const SETDATA = "setData"

function doSetData(whatAggregate, whenAggregate, whereAggregate, whyAggregate) {
    return {type:SETDATA,
        whatAggregate:whatAggregate,
        whenAggregate:whenAggregate,
        whereAggregate:whereAggregate,
        whyAggregate:whyAggregate
    }
}

export {doSetData, SETDATA}