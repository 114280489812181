import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import {CreateEventContext} from "../../../contexts";
import {useTranslation} from "react-i18next";
import _ from 'lodash'
import {Controller, useForm} from "react-hook-form";
import {eventTypes} from "../../../utils/CbvUtils";

const TypologyComponent = ({_eventTypes, handleChange, formData, setStepValidation}) => {
    const {t} = useTranslation()

    const [value, setValue] = useState(formData.eventType ? formData.eventType : eventTypes.OBJECT.value);
    const [helperText, setHelperText] = useState(formData.eventType ?
        (_.find(eventTypes, ['value',formData.eventType])?.description)
        : eventTypes.OBJECT.description);
    const eventContext = useContext(CreateEventContext);

    const handleRadioChange = (event) => {
        setValue(event.target.value)
        setHelperText(_.find(eventTypes, ['value',event.target.value])?.description||'')
        handleChange('eventType', event.target.value)
    };

    const handleNameChange = (event) => {
        handleChange('eventName', event.target.value.trim());
    };

    const okayData = useMemo(() => {
        let okValue = false
        let found = false
        if(formData.eventName && formData.eventName !== '' && !_.includes(formData.eventName, '/') && !(/\s/g.test(formData.eventName))){
            if(!eventContext.modelsName.includes(formData.eventName)) {
                okValue = true
            } else {
                if(formData.eventName !== formData.initialName) {
                    found = true
                } else {
                    okValue = true
                }
            }
        }

        return [okValue, found]
    }, [formData])

    useEffect(() => {
        setStepValidation(okayData[0])
    }, [okayData, setStepValidation])

    const {control, watch} = useForm({
        defaultValues: {name:formData.eventName||'',description:formData.description||eventContext.description||''}
    })

    const watchDescription = watch('description')

    useEffect(() => {
        eventContext.description = watchDescription
    }, [watchDescription])

    return (
        <div>
            <Box p={2}>
                <TextField fullWidth size={'small'}
                           label={_.startCase(t('entities.name'))}
                           name="name"
                           error={formData.eventName === '' || _.includes(formData.eventName, '/')}
                           onChange={handleNameChange}
                           defaultValue={formData.eventName}
                           placeholder={formData.initialName}
                           disabled={!eventContext.is_draft}

                />
                {
                    formData.eventName === '' ?
                        <FormHelperText style={{color:'red'}}>{t('entities.requiredField', 'This field is required')}</FormHelperText>
                        : (_.includes(formData.eventName, '/') || /\s/g.test(formData.eventName)) ?
                        <FormHelperText style={{color:'red'}}>{t('createEventModel.cannotContainSpecialChar', {specialChar: '/ or spaces'})}</FormHelperText>
                            : null
                }
                <Controller
                    as={<TextField
                        style={{marginTop: '16px'}}
                        fullWidth
                        multiline
                        rows={2}
                        rowsMax={4}
                        variant={'outlined'}
                        size={'small'}
                        placeholder={t('entities.description')}
                    />}
                    name="description"
                    control={control}
                />
            </Box>
            <Box p={2}>
                <div>
                    <FormLabel component="legend">{t('entities.eventTypology')}</FormLabel>
                    <RadioGroup name="typeRadioGroup" value={value} onChange={handleRadioChange}>
                        {
                            Object.values(eventTypes).map((type, index) => (
                                <FormControlLabel key={index} value={type.value} control={<Radio color={"default"}/>}
                                                  label={type.value}/>
                            ))
                        }
                    </RadioGroup>
                    <FormHelperText>{helperText}</FormHelperText>
                </div>
            </Box>
            {
                okayData[0] ? null :
                    okayData[1] ?
                        <Box pl={2}>
                            <FormHelperText style={{color: 'red'}}>{t('createEventModel.eventNameBusy', 'There is already a template with the same provided name in the same domain')}</FormHelperText>
                        </Box> : null
            }
        </div>
    )
}

export default TypologyComponent
