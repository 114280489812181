import {Box, Collapse, FormLabel, TextField, Typography} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import React, {useContext, useState} from "react";
import * as yup from "yup";
import {ThemeContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/Lock";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {Link} from "react-router-dom";
import {KeyboardArrowLeft, Visibility, VisibilityOff} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {severityTypes} from "../../utils/Utils";

const ResetPassword = (props) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        new_pswd: yup.string().required(t('errors.newPasswordRequired')),
        repeat_new_pswd: yup.string()
            .oneOf([yup.ref('new_pswd')], t('errors.passwordsMustMatch'))
    });
    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '70%',
            justifyContent: "center",
            display: "block",
            margin: 'auto',
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: _theme.accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: _theme.accentColor,
                },
            },
        },
        paper: {
            padding: '6%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '15px'
        },
        button: {
            backgroundColor: _theme.accentColor,
            color: "white",
            fontWeight: 'bold',
            borderRadius: '20px',
            marginLeft: '2%'
        },
        sponsored: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        lockAvatar: {
            color: 'white',
            backgroundColor: _theme.primaryColor,
            //marginBottom: '0.5em'
        },
        form: {
            width: '70%',
        },
    }));
    const classes = useStyles();

    const token = props.match.params.id
    //console.log("ResetPassword --> token:",token)

    const { control, handleSubmit, errors } = useForm({
        validationSchema:schema,
        defaultValues: {
            new_pswd: '',
            repeat_new_pswd: '',
        }
    })

    const [openAlertMessage, setOpenAlertMessage] = useState({
        open: false,
        message: '',
        severity: severityTypes.WARNING
    });

    const onSubmit = async (_data) => {
        let url = "/auth/me/password_reset/?password="+_data.new_pswd//+"&token="+token
        await axios.patch(url,null, {
            headers: {'Authorization': `Bearer ${token}`},
        })
            .then((res) => {
                setTimeout(function(){
                    setOpenAlertMessage({
                        open: true,
                        message: t('profile.changePasswordForm.passwordResetSuccess'),
                        severity: severityTypes.SUCCESS})
                    }, 300)
            })
            .catch((err) => {
                const errMsg = err.response.data?.detail[0]?.msg || err.response.data.detail
                console.log('error resetting password', errMsg)
                setOpenAlertMessage({
                    open: true,
                    message: errMsg || t('errors.genericServerError'),
                    severity: severityTypes.ERROR
                })
            })
    };

    const [showPassword, setShowPassword] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Avatar className={classes.lockAvatar}>
                    <LockIcon/>
                </Avatar>
                <img alt={"logo_iChain"} src="/assets/logo_iChain.png" style={{width: '35%'}}/>
                <FormLabel><Typography align={'center'} variant={'h6'} gutterBottom>
                    {t('profile.changePasswordForm.passwordRecovery')}
                </Typography></FormLabel>
                <Collapse in={!!openAlertMessage.open}>
                    <Grid item xs={12} style={{marginBottom: '4%', marginTop: '4%'}}>
                        <Alert severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
                    </Grid>
                </Collapse>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        as={<TextField
                            fullWidth
                            size={'small'}
                            style={{marginBottom:'3%'}}
                            error={!!errors.new_pswd}
                            helperText={errors.new_pswd?.message}
                            label={t('profile.changePasswordForm.newPassword')}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {setShowPassword(!showPassword)}}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                //
                            }}
                        />}
                        name="new_pswd"
                        control={control}
                    />
                    <Controller
                        as={<TextField
                            fullWidth
                            size={'small'}
                            type={showPassword ? 'text' : 'password'}
                            error={!!errors.repeat_new_pswd}
                            helperText={errors.repeat_new_pswd?.message}
                            label={t('profile.changePasswordForm.confirmNewPassword')}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {setShowPassword(!showPassword)}}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                //
                            }}
                        />}
                        name="repeat_new_pswd"
                        control={control}
                    />
                    <Box pt={3} display={'flex'} justifyContent={'center'}>
                        <Button
                            style={{textTransform: 'none', paddingLeft: '16px', paddingRight: '16px'}}
                            component={Link} to="/Login"
                            startIcon={<KeyboardArrowLeft/>}>
                            {t('actions.login')}
                        </Button>
                        <Button type="submit"
                                variant="contained"
                                fullWidth
                                className={classes.button}>{t('profile.changePasswordForm.resetPassword')}</Button>
                    </Box>
                </form>
            </Paper>
            <Box className={classes.sponsored} p={1}>
                <Typography style={{color: 'darkgrey'}}>{t('poweredBy')}</Typography>
                <img alt={"logo_wise_side"} src="/assets/ws_logo_20_ago_2021" style={{width: '23%'}}/>
            </Box>
        </div>
    )
}

export default ResetPassword