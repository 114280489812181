import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import AuthRequestDialog from "../AuthRequestDialog";

const MaterialTableDemo = ({authRequests, limit, setLimit, skip, setSkip, updateList}) =>  {

    let columns = [
        { title: 'IP Address', field: 'ip_address'},
        { title: 'Device Name', field: 'device_name'},
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            updateList()
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const [open, setOpen] = useState(false);

    /*
        0 = pending
        1 = authorized
        2 = unauthorized
    */
    const [operation, setOperation] = useState(0)

    const [id, setAuthRequestId] = useState("")

    return (
        <div style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                data={authRequests.data}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: 'done',
                        tooltip: 'Accept Request',
                        onClick: (event, rowData) => {
                            setAuthRequestId(rowData._id)
                            setOperation(1)
                            setOpen(true)
                        }
                    },
                    {
                        icon: 'clear',
                        tooltip: 'Reject Request',
                        onClick: (event, rowData) => {
                            setAuthRequestId(rowData._id)
                            setOperation(2)
                            setOpen(true)
                        },
                    }
                ]}
                totalCount={authRequests.count}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={skip}
            />
            <AuthRequestDialog authRequestId={id} operation={operation} open={open} onClose={() => {setOpen(false)}} updateList={updateList}/>
        </div>
    );
}

export default MaterialTableDemo
