import React, {useContext, useEffect, useState} from "react";
import {CreateEventContext, ThemeContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import _ from 'lodash'
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import {eventFieldNameTypes, inputTypes} from "../../utils/CbvUtils";
import FieldInfo from "./FieldInfo";
import chroma from "chroma-js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from '@material-ui/icons/Edit';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const EventIlmdComponent = ({templateData}) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        ilmdArea: {
            borderRadius: '12px',
            backgroundColor: 'rgba(245,245,245,0.7)',
        },
        nameSpace: {
            backgroundColor: chroma(accentColor).alpha(0.9).hex(),
            color: 'white',
            fontWeight: "bold",
            fontSize: "inherit",
        },
    }));
    const classes = useStyles()

    const eventContext = useContext(CreateEventContext)

    const {handleSubmit, register, watch} = useForm();

    const [state, setState] = useState({
        ilmd: templateData.ilmd || [],
        isSetIcon: true
    });

    useEffect(() => {
        _.forEach(state.ilmd, (feature) => {
            eventContext.setValidationField(
                'ilmd',
                feature.name,
                feature.optional)
        })
    }, [])

    const onSetClick = (data) => {
        if(state.isSetIcon) {
            _.forEach(state.ilmd, (feature) => {
                const newValue = data[feature.name]

                eventContext.setValidationField('ilmd', feature.name, !feature.optional ? !!newValue : true)
                eventContext.setFormField('ilmd', feature.name, newValue)

                return ({...feature, value: data[feature.name]})
            })
        }

        setState({...state, isSetIcon: !state.isSetIcon})
    }

    return (
        <div>
            {
                state.ilmd && state.ilmd.length > 0 ?
                <Box mb={2} pb={2}>
                    <FieldInfo
                        name={eventFieldNameTypes.ILMD.label}
                        description={eventFieldNameTypes.ILMD.description}/>
                    <Box p={2} className={classes.ilmdArea}>
                        {
                            _.map(state.ilmd, (feature, index) => (
                                <Grid spacing={2} container key={index} justify={'flex-start'} alignItems={'center'}>
                                    {/*<Grid item xs={1}>
                                        <Chip className={classes.nameSpace}
                                              label={"namespace: " + feature.ns}/>
                                    </Grid>*/}
                                    <Grid item xs={4}>
                                        <Typography align={"right"}>{_.startCase(feature.name)}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth
                                                   size={'small'}
                                                   variant={'outlined'}
                                                   label={feature.default ? t('cbvValues.ilmd.defaultValue')+": "+feature.default : feature.optional ? t('entities.optional') : t('entities.required')}
                                                   name={feature.name}
                                                   defaultValue={feature.default}
                                                   //placeholder={feature.default}
                                                   inputRef={register}
                                                   error={!watch(feature.name) && !feature.optional}
                                                   disabled={!state.isSetIcon}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FieldInfo optional={feature.optional} external={feature.input === inputTypes.EXTERNAL.value}/>
                                    </Grid>
                                </Grid>
                            ))
                        }
                        <Box pt={2} style={{display: 'flex', justifyContent:'center'}}>
                            <Divider/>
                            <Button aria-label="set"
                                    onClick={handleSubmit(onSetClick)}
                                    endIcon={state.isSetIcon ? <CheckCircleIcon style={{color: accentColor}}/>
                                    : <EditIcon style={{color: accentColor}}/>}
                            >
                                {state.isSetIcon ? t('actions.confirm') : t('actions.edit')}
                            </Button>
                        </Box>
                    </Box>
                </Box> : null
            }
        </div>
    )
}

export default EventIlmdComponent