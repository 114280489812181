import React, {useContext} from "react";
import TableEventModels from "./TableEventModels";
import MaterialTable from 'material-table';
import useAxios from "axios-hooks";
import AddIcon from "@material-ui/icons/Add";
import {Button} from "@material-ui/core";
import {RoutingContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const EventModels = () => {
    const { t } = useTranslation()
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        button: {
            marginBottom: "1%",
            color: theme.secondary,
            textTransform: "none"
        },
    }));
    const classes = useStyles()

    const [{data: templates, loading, error},] = useAxios({
            url:  "/api/capture/event_templates/?latest=true"
        },
        {useCache: false}
    )

    const myContext = useContext(RoutingContext);

    const onItemClick = () => {
        myContext.setRoute('CreateEventTemplate', {is_draft: true}); // inizialmente il template è settato a "draft");
    }

    return (
        <div>
            <Button
                className={classes.button}
                onClick={onItemClick}
                startIcon={<AddIcon/>}>
                {t('eventModels.addNewTemplate')}
            </Button>
            {templates ?
                <TableEventModels templates={templates} />:
                loading ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error ? <p>{t('errors.genericServerError')}</p> : null}
        </div>
    )
}

export default EventModels;
