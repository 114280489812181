import React, {useContext, useEffect, useState} from "react";
import {Box, Divider} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import {CreateEventContext, ThemeContext} from "../../contexts";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {eventFieldNameTypes, eventTypes, inputTypes} from "../../utils/CbvUtils";
import FieldInfo from "./FieldInfo";
import ItemCodeBuilder from "./ItemCodeBuilder";
import BizTransactionComponent from "./what/BizTransactionComponent";
import EventCodeValue from "./what/EventCodeValue";
import {useWindowSize} from "../../utils/Utils";
import i18next from "../../i18n/i18next";
import AssociationParentCodeBuilder from "./AssociationParentCodeBuilder";
import {ToggleButtonGroup} from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {useTranslation} from "react-i18next";

function FormatBoldIcon() {
    return null;
}

function FormatItalicIcon() {
    return null;
}

const EventWhatComponent = ({templateData}) => {
    const size = useWindowSize();
    const {t} = useTranslation()

    const eventContext = useContext(CreateEventContext)
    const {primaryColor, accentColor, iconHoverBg} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        icon: {
            color: 'darkgray',
            '&:hover': {
                background: iconHoverBg,
            },
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        dottedDiv: {
            border: '2px solid lightGray',
            borderRadius: '12px',
            borderStyle: 'dotted',
            //flex: 1
        },
        builderArea: {
            //marginTop: '3%',
            borderRadius: '12px',
            backgroundColor: 'rgba(245,245,245,0.7)',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid lightgray',
            borderStyle: 'dotted',
        },
        codeBuilderArea: {
            display: size.width > 700 ? 'flex' : 'contents',
            //marginTop: '2%',
            alignItems: 'flex-start',
            width: '100%',
            //flexDirection: size.width > 700 ? 'row' : 'column'
        },
        warningIcon: {
            color: accentColor,
            marginRight: '1%'
        },
        labelChip: {
            margin: '0.3%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }))
    const classes = useStyles()

    const EpcQuantityListComponent = ({field}) => {
        const [state, setState] = useState({
            parentIdType: 'location',
            parentID: field && field.name === eventFieldNameTypes.PARENT_ID.name ? eventContext.getFormField('what', field.name)?.value || null : null,
            codes: field && eventContext.getFormField('what', field.name) ? eventContext.getFormField('what', field.name)?.value || [] : [],
            codeType: null,
            productClass: null,
            serialNumber: '',
            generateCodeCheck: false,
            quantity: '',
            uom: ''
        })

        useEffect(() => {
            if(field) {
                if (field.name === eventFieldNameTypes.PARENT_ID.name)
                    eventContext.setValidationField('what', field.name, !!state.parentID)
                else eventContext.setValidationField('what', field.name, state.codes.length !== 0)
            }
        }, [])

        const onClearParentClick = () => {
            eventContext.setValidationField('what', field.name, false)
            eventContext.setFormField('what', field.name, null)
            setState({...state, parentID: null})
        }

        const onClearClick = (code) => {
            const new_codes = state.codes.filter(val => val.value !== code.value)
            eventContext.setValidationField('what',field.name, new_codes.length !== 0)
            eventContext.setFormField('what', field.name, new_codes)
            setState({...state, codes: new_codes})
        }

        const handleParentIdType = (event, type) => {
                setState({...state, parentIdType: type});
        }

        return (
            <Box mb={2} pb={2}>
                <FieldInfo name={_.find(eventFieldNameTypes, ['name', field.name]).label}
                   description={_.find(eventFieldNameTypes, ['name', field.name]).description}
                   optional={field.optional}
                   external={field.input === inputTypes.EXTERNAL.value}/>
                {
                    field.name !== eventFieldNameTypes.PARENT_ID.name ?
                        !state.codes || state.codes.length !== 0 ?
                            state.codes.map((code, index) => (
                                <EventCodeValue key={index} code={code} remove={onClearClick}/>
                            )) : <FormHelperText error>{i18next.t('createEvent.fieldCannotBeEmpty')}</FormHelperText>
                        : state.parentID ? <EventCodeValue code={state.parentID} remove={onClearParentClick}/>
                        : <FormHelperText error>{i18next.t('createEvent.fieldCannotBeEmpty')}</FormHelperText>
                }
                {field.name === eventFieldNameTypes.PARENT_ID.name ?
                    templateData.type === eventTypes.ASSOCIATION.value ?
                        <>
                            <Box display={'flex'} justifyContent={'center'}>
                                <ToggleButtonGroup exclusive value={state.parentIdType} onChange={handleParentIdType} size={'small'}>
                                <ToggleButton value="location">
                                    <FormatBoldIcon/>
                                    {t('entities.location.label')}
                                </ToggleButton>
                                <ToggleButton value="item">
                                    <FormatItalicIcon/>
                                    {t('entities.product.label')}
                                </ToggleButton>
                            </ToggleButtonGroup></Box>
                            {state.parentIdType === 'item' ? <ItemCodeBuilder
                                    eventType={templateData.type}
                                    state={state}
                                    setState={setState}
                                    field={field}
                                    classes={classes}/>
                                :
                                <AssociationParentCodeBuilder upperState={state} setUpperState={setState} field={field}
                                                              classes={classes}/>
                            }
                        </>
                        : <ItemCodeBuilder
                            eventType={templateData.type}
                            state={state}
                            setState={setState}
                            field={field}
                            classes={classes}/>
                    : <ItemCodeBuilder
                            eventType={templateData.type}
                            state={state}
                            setState={setState}
                            field={field}
                            classes={classes}/>
                }
            </Box>
        )
    }

    const getWhatComponent = () => {
        if (templateData.type === eventTypes.OBJECT.value)
            return _.filter(templateData.what, ['input', inputTypes.EXTERNAL.value]).map((field, index) => (
                <div key={index}>
                    <EpcQuantityListComponent field={field}/>
                    <Divider/>
                </div>))
        if (templateData.type === eventTypes.TRANSACTION.value)
            return <div>
                {_.filter(templateData.what, function (o) {return o.input !== inputTypes.COMPUTED.value})
                    .map((field, index) => (
                    <div key={index}>
                        {
                            field.name !== eventFieldNameTypes.BIZTRANSACTIONLIST.name ?
                                <EpcQuantityListComponent field={field}/>
                                : <BizTransactionComponent field={field}/>
                        }
                        <Divider/>
                    </div>))}
            </div>
        if (templateData.type === eventTypes.TRANSFORMATION.value)
            return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Box p={2} className={classes.dottedDiv}>
                    <EpcQuantityListComponent field={templateData.what[1]}
                                              outputList={false}/>
                </Box>
                <DoubleArrowIcon fontSize={"large"}
                                 style={{
                                     marginLeft: '2%',
                                     marginTop: '1%',
                                     marginBottom: '1%',
                                     color: primaryColor
                                 }}/>
                <Box p={2} className={classes.dottedDiv}>
                    <EpcQuantityListComponent field={templateData.what[2]}
                                              outputList={true}/>
                </Box>
            </div>
        if (templateData.type === eventTypes.AGGREGATION.value || templateData.type === eventTypes.ASSOCIATION.value)
            return <div>
                {_.filter(templateData.what, ['input', inputTypes.EXTERNAL.value]).map((field, index) => (
                    <div key={index}>
                        <EpcQuantityListComponent field={field}/>
                        <Divider/>
                    </div>
                ))}
            </div>
    }

    return (
        <div>
            {
                eventContext.products ? getWhatComponent() : null
            }
        </div>
    )
}

export default EventWhatComponent
