import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Controller, useForm} from "react-hook-form";
import {Box, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import LocationValue from "./LocationValue";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import {useTranslation} from "react-i18next";
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    box: {
        backgroundColor: "#f6f6f6",
    },
    boxStyle: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const OtherLocation = ({value, onChange, editMode, initValues, editableSubplaces, setRender, editSub, setEditSub}) => {
    const {t} = useTranslation()
    const classes = useStyles();

    const {handleSubmit, reset, control, watch} = useForm({
        defaultValues: {
            name: '',
            description: '',
        }
    });

    const locations = value || [];
    const [putError, setPutError] = useState(false)

    const onAddClick = (data) => {
        const new_locations = [...locations, {name: data.name, description: data.description}];
        onChange && onChange(new_locations);
        reset()
    }

    const onClearClick = (location) => {
        const new_locations = locations.filter(val => val.description !== location.description)
        onChange && onChange(new_locations);
    }

    function onEditSublocation(sublocation) {
        const sub = initValues.locations_extended.filter(x => x.sgln === sublocation.sgln);
        setEditSub({
            edit: true,
            objSub: sub[0],
        })
    }

    const EditSublocationComponent = () => {

        const {control, watch} = useForm({
            defaultValues: {
                sgln_sub: editSub.objSub.sgln,
                name_sub: editSub.objSub.name,
                description_sub: editSub.objSub.small_description,
            }
        });

        function closeEditSubplace() {
            if (editSub.objSub.name !== watch('name_sub') || editSub.objSub.small_description !== watch('description_sub')) {
                let dataToSend = {
                    sgln: editSub.objSub.sgln,
                    name: watch('name_sub'),
                    place_id: editSub.objSub.place_id,
                    small_description: watch('description_sub'),
                    data_origin: editSub.objSub.data_origin
                }
                //console.log('INVIO OGGETTO --> ', dataToSend)
                const url = 'api/subplaces/' + editSub.objSub._id
                axios({
                    method: 'put',
                    url: url,
                    data: {...dataToSend, id: editSub.objSub._id},
                    config: {headers: {'Media-Type': 'application/json'}}
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            setEditSub({
                                edit: false,
                                objSub: {},
                            })
                            setRender(Math.random())
                        }
                    })
                    .catch(function (response) {
                        console.log('error editing subplace ---> ', response);
                        setPutError(true)
                    });
            } else {
                setEditSub({
                    edit: false,
                    objSub: {},
                })
            }
        }

        return (
            <Box>
                <Grid container className={classes.grid} justify="space-between" alignItems="center">
                    <Grid item xs={8}>
                        <Typography>{t('actions.edit', {what: t('entities.subLocation.label')})}</Typography>
                    </Grid>
                    <IconButton aria-label="close" onClick={() => {
                        setEditSub({
                            edit: false,
                            objSub: {},
                        })
                    }}>
                        <CloseIcon/>
                    </IconButton>
                </Grid>
                <Box pt={2} pb={2}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Grid item xs={12}>
                            <Box>
                                <Controller
                                    as={<TextField disabled={true} fullWidth label={_.startCase(t('entities.code'))}/>}
                                    name="sgln_sub"
                                    control={control}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Controller
                                    as={<TextField fullWidth label={_.startCase(t('entities.name'))}/>}
                                    name="name_sub"
                                    control={control}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Controller
                                    as={<TextField fullWidth label={t('entities.description')}/>}
                                    name="description_sub"
                                    control={control}
                                />
                            </Box>
                        </Grid>
                        {
                            putError ?
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <p style={{color: 'red'}}>{t('errors.genericServerError')}</p>
                                    </Box>
                                </Grid>
                                : null
                        }
                    </Grid>
                </Box>
                <Box pt={2} pr={2} pb={2} style={{display: "flex"}}>
                    <Button
                        disabled={
                            !(editSub.objSub.name !== watch('name_sub') ||
                                editSub.objSub.small_description !== watch('description_sub'))}
                        onClick={closeEditSubplace}
                        variant="outlined"
                        color="default"
                        size="medium"
                        style={{marginLeft: 'auto', textTransform: "none"}}
                        startIcon={<SaveIcon/>}
                    >
                        {t('actions.save', {what: t('entities.subLocation.label')})}
                    </Button>
                </Box>
            </Box>
        )
    }

    return (
        <Box className={classes.box} p={2} borderRadius={2}>
            {
                !editSub.edit ?
                    <Box>
                        <Typography>{t('entities.subLocation.label_plural')}</Typography>
                        <Box pt={1}>
                            {
                                locations.map((location) => <LocationValue key={location.name} remove={onClearClick}
                                                                           location={location} editMode={editMode}
                                                                           editableSubplaces={editableSubplaces}
                                                                           onEditClick={onEditSublocation}/>)
                            }
                        </Box>
                        <Box pt={2}>
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                <Grid item xs={12} md={4}>
                                    <Box>
                                        <Controller
                                            as={<TextField fullWidth label={_.startCase(t('entities.name'))}/>}
                                            name="name"
                                            control={control}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Box pl={3}>
                                        <Controller
                                            as={<TextField fullWidth label={t('entities.description')}/>}
                                            name="description"
                                            control={control}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box pt={0.5}>
                                <IconButton aria-label="add" onClick={handleSubmit(onAddClick)}
                                            disabled={!watch('name')}>
                                    <AddCircleIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box> : <EditSublocationComponent/>
            }
        </Box>
    )
}

export default OtherLocation