import React, {useContext, useMemo, useState} from "react";
import {Backdrop, Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import useAxios from "axios-hooks";
import {AuthContext, ThemeContext} from "../../contexts";
import MaterialTable from 'material-table';
import TableReports from "./TableReports";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import _ from 'lodash'
import FilterListIcon from '@material-ui/icons/FilterList';
import WhenRuleComponent from "../create_new_relationship/rules_components/WhenRuleComponent";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import CustomLoader from "../../components/CustomLoader";

const Reports = () => {
    const { t } = useTranslation()
    const { activeMainDomain } = useContext(AuthContext)
    const { accentColor } = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        chip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            fontWeight: 'bold',
            marginLeft: '2%',
            borderRadius: '20px',
        },
    }));
    const classes = useStyles()

    const [{data: _reports, loadingReports, errorReports},] = useAxios({
            url:  `/api/dataservices/reports/?domain=${activeMainDomain}`,
            useCache: false
        },
    )

    const [{loading: loading}, downloadRequest] = useAxios(
        {method: "GET",},
        {useCache: false, manual: true}
    )

    const [state, setState] = useState({
        selectedTypeOfReports: [],
        selectedDates: [{
            startDate: null,
            endDate: null,
            color: 'darkgrey',
            key: 'selection'
        }],
        multipleSelection: false,
        selectedReports: [],
    });

    const filteredReports = useMemo(() => {
        if(!_reports)
            return null

        return state.selectedTypeOfReports.length !== 0 ?
            _.filter(_reports, (report) => _.some(state.selectedTypeOfReports, ['name', report.name]))
            : _reports
    },[_reports, state.selectedTypeOfReports])

    const filteredDatesReports = useMemo(() => {
        if(!filteredReports)
            return null
        return !state.selectedDates[0]?.startDate && !state.selectedDates[0]?.end ?
            filteredReports
            : _.filter(filteredReports, (report) => {
                return Moment(report.creation_date).isBetween(state.selectedDates[0].startDate, state.selectedDates[0].endDate, 'day', "[]")
            })
    }, [filteredReports, state.selectedDates])

    const onClickDownload = (reports) => {
        let ids, fileName, responseType, mimeType
        if(Array.isArray(reports) && reports.length > 1) {
            /** Download zip file */
            ids = _.map(reports, (el) => (el._id)).join(',')
            fileName = 'filteredDatesReports.zip'
            responseType = "arraybuffer"
            mimeType = "application/zip"
        } else {
            /** Download singolo file */
            fileName = (_.split(reports.path, "/")[3]) || (`${reports.name}.xlsx`)
            ids = reports._id
            responseType = "blob"
            mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }

        downloadRequest({
            url:  `/api/dataservices/reports/${ids}/download?domain=${activeMainDomain}`,
            responseType: responseType,
            //responseType: "blob",
            headers: {'Content-Type': mimeType,}
        }).then(response => {
            let blob = new Blob([response.data], { type: 'application/zip' })
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        }).catch(error => {
            console.log("onClickDownload --> error:", error)
        })
    }

    return (
        <div className={classes.root}>
            {loading &&
            <Backdrop style={{zIndex: 1000}} open={loading}><CustomLoader size={80}/></Backdrop>}
            {filteredDatesReports &&
            <Box mb={3} display={'flex'} alignItems={'end'}>
                <FilterListIcon style={{color: accentColor, marginRight: 16}}/>
                <Autocomplete
                    multiple
                    style={{width: '30%', marginRight: 16}}
                    filterSelectedOptions
                    limitTags={3}
                    id="tags-standard"
                    options={_.uniqBy(_reports, 'name')}
                    getOptionLabel={(option) => option.name}
                    value={state.selectedTypeOfReports}
                    onChange={(event, newSelection) => {
                        setState({...state, selectedTypeOfReports: newSelection});
                    }}
                    renderInput={params => {
                        const {InputProps, ...restParams} = params;
                        const {startAdornment, ...restInputProps} = InputProps;
                        return (
                            <TextField
                                {...restParams}
                                size={'small'}
                                variant={"standard"}
                                placeholder={t('actions.filterBy', {by: 'tipo di report'})}
                                InputProps={{
                                    ...restInputProps,
                                    startAdornment: (
                                        <div style={{
                                            maxHeight: '100px',
                                            overflowY: 'auto',
                                        }}
                                        >
                                            {startAdornment}
                                        </div>
                                    ),
                                }}
                            />
                        );
                    }}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip label={option.name} size='small'{...getTagProps({index})}/>
                        ))
                    }
                />
                <WhenRuleComponent state={state} setState={setState} width={'30%'} outlined={false}/>
                <Button className={classes.button} variant="contained"
                        onClick={() => {
                            const reportsToDownload = state.multipleSelection ? state.selectedReports : filteredDatesReports
                            if(reportsToDownload.length > 1) {
                                onClickDownload(reportsToDownload)
                            }
                        }}
                        disabled={state.selectedReports.length === 0 && state.multipleSelection}>
                    {t('actions.download')}
                </Button>
            </Box>}
            {filteredDatesReports && <TableReports reports={filteredDatesReports} state={state} setState={setState} onClickDownload={onClickDownload}/>}
            {loadingReports && <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/>}
            {errorReports && <p>{t('errors.genericServerError')}</p>}
        </div>
    )
}

export default Reports
