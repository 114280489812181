import React from "react";

import {useReducer, useContext, createContext} from "react";

export const RoutingContext = createContext(null); // ROUTE CONTEXT usato in ResponsiveDrawer.js

export const StateContext = createContext(null) // Per le aggregazioni

export const MarbleContext = createContext(null)

export const AuthContext = createContext(null)

export const ProductHistoryContext = createContext(null)

export const RelationshipContext = createContext(null)

export const RulesConfiguratorContext = createContext(null)

export const CreateEventContext = createContext(null)

export const ThemeContext = createContext(null) // Contesto per il tema colori

export const StateProvider = ({reducer, initialState, children}) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
)

export const MarbleProvider = ({reducer, initialState, init, children}) => (
    <MarbleContext.Provider value={useReducer(reducer, initialState, init)}>
        {children}
    </MarbleContext.Provider>
)

export const RulesConfiguratorProvider = ({reducer, initialState, children}) => (
    <RulesConfiguratorContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </RulesConfiguratorContext.Provider>
)

export const ProductHistoryProvider = ({reducer, initialState, init, children}) => (
    <ProductHistoryContext.Provider value={useReducer(reducer, initialState, init)}>
        {children}
    </ProductHistoryContext.Provider>
)

export const useStateValue = () => useContext(StateContext)
