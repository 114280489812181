import React, {useContext} from 'react';
import MaterialTable from 'material-table';
import {RoutingContext, ThemeContext} from "../../contexts";
import {parseName} from "../../utils/Utils";
import {makeStyles} from "@material-ui/core/styles";
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import _ from "lodash";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import {useTranslation} from "react-i18next";
import {langResources} from "../../i18n/i18next";
import {Check, Receipt} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";

const MaterialTableDemo = ({templates}) =>  {
    const { t } = useTranslation()

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        statusChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        }
    }));

    const classes = useStyles()
    const myContext = useContext(RoutingContext);

    let columns = [
        {
            title: t('entities.status', 'Status'),
            field:'is_draft',
            sorting: true,
            align: 'right',
            render: rowData => <Chip
                size={'small'}
                className={classes.statusChip}
                icon={rowData.is_draft ? <Receipt style={{color: accentColor}}/> : <Check style={{color: accentColor}}/>}
                label={rowData.is_draft ? t('entities.draft', 'Draft') : t('entities.active', 'Active')}
            />
        },
        {
            title:'',
            field:'bizStepIcon',
            align: 'right',
            sorting: false,
            render: rowData => <BizStepIcon bizstep={(rowData.why[1]?.name === 'bizStep' ? rowData.why[1]?.value : rowData.why[0]?.value)||""} color={primaryColor}/>
        },
        { title: _.startCase(t('entities.name')), field: 'name', sorting: true, render: rowData => <p>{parseName(rowData.name)}</p>},
        { title: _.startCase(t('entities.type')), field: 'type', sorting: true, render: rowData => <p>{rowData.type||"-"}</p>},
        { title: _.startCase(t('entities.description')), field: 'description', sorting: true},
        { title: _.startCase(t('entities.version')), field: 'version', sorting: true},
    ]

    const onRowClick = (event, rowData) => {
        myContext.setRoute('EventModelDetail', rowData);
    }

    /**
     * fromRulesConf: props.fromRulesConf,
     domain: props.domain,
     //per fill in di un draft model
    _id: props._id,
        modelName: props.modelName
    */

    const modelLabel = t('entities.model.label')
    const tab = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable
    return (
        <div className={classes.root}>
            <MaterialTable
                columns={columns}
                localization={tab}
                actions={[
                    rowData => ({
                        icon: 'edit',
                        tooltip: t('actions.edit', {what: rowData.is_draft ? t('entities.draft') : modelLabel}),
                        onClick: (event, rowData) => {
                            const data = !rowData.is_draft ? {...rowData, editMode: true} : {
                                ...rowData,
                                fromRulesConf: true, // le Bozze sono legate al configuratore grafico delle regole //TODO: fix
                                editMode: true,
                                //per fill in di un draft model
                                _id: rowData.mongoid||rowData._id,
                            }
                            myContext.setRoute('CreateEventTemplate', data)
                        },
                    }),
                    rowData => ({
                        icon: () => rowData.is_draft ? <AddAlertIcon color={'disabled'}/> : <AddAlertIcon/>,
                        tooltip: rowData.is_draft ?
                            t('eventModels.youNeedToFulfillFirst', "You need to fill in this model before you can generate an event of this type")
                            : t('eventModels.generateNewEventOfThisType'),
                        onClick: (event, rowData) => myContext.setRoute('CreateEvent', rowData),
                        disabled: rowData.is_draft
                    })
                ]}
                data={_.orderBy(templates.data, 'is_draft', 'desc')}
                onRowClick={onRowClick}
                options={{
                    search: true,
                    sorting: true,
                    draggable: true,
                    showTitle: false,
                    tableLayout: "auto",
                    actionsColumnIndex: -1,
                    padding: 'dense',
                    pageSize: 10,
                }}
            />
        </div>
    );
}

export default MaterialTableDemo
