import React, {useContext, useState, useEffect} from "react";
import {Backdrop, Box, Tabs, Tab} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import useAxios from "axios-hooks";
import {AuthContext, ThemeContext} from "../../contexts";
import i18next from "../../i18n/i18next";
import { 
    Table, TableHead, TableRow, TableBody, TableCell,
    TextField,
    Chip,
    Snackbar,
    Typography
} from '@material-ui/core'
import TableReports from "./TableReports";
import {Autocomplete, Alert, AlertTitle} from "@material-ui/lab";
import _ from 'lodash'
import FilterListIcon from '@material-ui/icons/FilterList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import WhenRuleComponent from "../create_new_relationship/rules_components/WhenRuleComponent";
import Moment from "moment";
import CustomLoader from "../../components/CustomLoader";
import ContainerDimensions from "react-container-dimensions";
import { ResponsiveBoxPlot } from '@nivo/boxplot';
import { ResponsiveBullet, BulletRectsItemProps } from '@nivo/bullet';
import { ResponsiveRadar } from '@nivo/radar';
import { colorSchemes } from '@nivo/colors'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { FileCopy } from "@material-ui/icons";


const tabs_conf = [
    {
        index: 0,        
        field: "I.C.",
        format: (x)=>{return (Math.round(x * 1000)/ 1000).toFixed(3)}
    },
    {
        index: 1,
        field: "Indice di performance",
        format: (x)=>{return (Math.round(x * 10)/ 10).toFixed(1)}
    },
    {
        index: 2,
        field: "Kg / mq",
        format: (x)=>{return (Math.round(x * 100)/ 100).toFixed(2)}
    },
    {
        index: 3,
        field: "Peso Medio",
        format: (x)=>{return (Math.round(x * 1000)/ 1000).toFixed(3)}
    },
    {
        index: 4,
        field: "PresenzaMedia",
        format: (x)=>{return (Math.round(x * 100)/ 100).toFixed(2)}
    },
    {
        index: 5,
        field: "Spesa Med €/kg",
        format: (x)=>{return (Math.round(x * 1000)/ 1000).toFixed(4)}
    }

]

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (<div id="graph" style={{ height: "50vh" }}>{children}</div>)}
        </div>

    )
}

const PoultryChart =  () => {
    const { activeMainDomain } = useContext(AuthContext)
    const { accentColorAlpha, accentColor } = useContext(ThemeContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
            '& ..MuiAutocomplete-inputRoot': {
                maxHeigth: '30px'
            }
        },
        chip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            fontWeight: 'bold',
            marginLeft: '2%',
            borderRadius: '20px',
        },
        paper: {
            paddingTop: '1%',
            paddingLeft:'2%',
            paddingBottom: '5%',
            paddingRight: '2%',
            fontSize: 'small'
        },
        graph_tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            fontWeight: 'thin',
            borderRadius: '5px',
            border: '1px solid ',
            padding: '0.25em',
            //minWidth: '310px'            
        },
        graph_tooltip_inner: {
            marginLeft: '1em',
            marginRight: '1em',
            whiteSpace: 'nowrap'
        },
        dot: {
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            display: 'inline-block',
        }
    }));

    const classes = useStyles()

    const { t } = useTranslation();
    const responseType = "blob"
    const mimeType = "application/json"

    const CustomRangeComponent = ({
        x,
        y,
        width,
        height,
        color,
        onMouseEnter,
        onMouseMove,
        onMouseLeave,
    }) => (
        <rect
            x={x + 3}
            y={y + 3}
            rx={3}
            ry={3}
            width={width - 3 > 0 ? width - 3 : 0}
            height={height - 3 > 0 ? height - 3 : 0 }
            fill={color}
            onMouseEnter={onMouseEnter}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
        />
    )
    const customRangeColors = [colorSchemes.set1[0], colorSchemes.set1[0], colorSchemes.set1[1]]

    const CustomMeasureComponent = ({
        x,
        y,
        width,
        height,
        color,
        onMouseEnter,
        onMouseMove,
        onMouseLeave,
    }) => (
        <rect
            x={x + 2}
            y={y + 2}
            rx={height / 2}
            ry={height / 2}
            width={width - 2 > 0 ? width - 2 : 0 }
            height={height - 2 > 0 ? height - 2 : 0 }
            fill={color}
            onMouseEnter={onMouseEnter}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
        />
    )

    const [{ data: pezzatura, loadingPezzatura, errorReports },] = useAxios({
        url: `/api/dataservices/records/_keys/?key_type=group_key&domain=${activeMainDomain}`,
        useCache: false
    },
    )

    const [filter, setFilter] = useState({
        pezz: { _id: "group_key:POLLO GROSSO" },
        selectedDates: [{
            startDate: Moment().subtract(6, 'months').toDate(),
            endDate: Moment().toDate(),
            color: 'darkgrey',
            key: 'selection'
        }
        ],
        selected_tab_index: 0,
        selected_allevamenti: []
    })

    const [dataMap, setDataMap] = useState(new Map())
    const [graphData, setGraphData] = useState([])
    const [allevamenti, setAllevamenti] = useState([])
    const [measureStats, setMeasureStats] = useState({})
    const [{bulletData, allevamento, detailData}, setAdditionalData] = useState({bulletData:[], allevamento:'', detailData:[]})
    const [radarData, setRadarData] = useState([])
    const [{copyDone, copyMessage}, setCopy] = useState({copyDone: false, copyMessage: ''})

    const [{ data, loading, error, response }, reloadData] = useAxios({
        url: `/api/dataservices/chemio_reports_data/`,
        params: {
            pezzatura: filter.pezz._id.replace("group_key:", ""),
            type_of_report: 'Analisi_cicli',
            start: Moment(filter.selectedDates[0].startDate).format('YYYY-MM-DD HH:mm:ss'),
            end: Moment(filter.selectedDates[0].endDate).format('YYYY-MM-DD HH:mm:ss'),
            domain: activeMainDomain
        },
    })

    const changeTab = (event, newValue) => {
        setFilter((prev) => ({ ...prev, selected_tab_index: newValue }))
    };

    useEffect(()=>{
        if (data?.records)
        {
            let dataMap_tmp = new Map()
            data.records.forEach((record) => {
                if ((!dataMap_tmp.get(record['Desc Centro Allevamento']))) //&& dataMap.size < maxAllev) 
                {
                    dataMap_tmp.set(record['Desc Centro Allevamento'], { records: [] })
                }
                dataMap_tmp.get(record['Desc Centro Allevamento']).records.push(record)
                
            })            
            const all_tmp = dataMap_tmp.keys().toArray()
            setDataMap(dataMap_tmp)
            setFilter(prev => ({ ...prev, selected_allevamenti: all_tmp.slice(0) }))
            setAllevamenti(all_tmp)            
        }
    }, [data])

    useEffect(() => { reloadData() }, [filter.pezz, filter.selectedDates])

    useEffect(() => {
        const new_graph_data = [];
        const measure_stats = new Map();
        dataMap.keys().forEach(allev => {
            if (filter.selected_allevamenti.find( v => v == allev)) {
                dataMap.get(allev).records.forEach((r, i) => {                    
                    let xy = {
                        "group": allev,
                        "subgroup": tabs_conf[filter.selected_tab_index].label, 
                        "value": r[tabs_conf[filter.selected_tab_index].field],
                        "ciclo": r["Rif.Ciclo"]?r["Rif.Ciclo"]:r["Rif. Ciclo"],
                    }
                    new_graph_data.push(xy)

                    tabs_conf.forEach(measure=>{
                        if (!measure_stats.get(measure.index)){
                            measure_stats.set(measure.index, {max: { y: Number.MIN_SAFE_INTEGER }, min: {y: Number.MAX_SAFE_INTEGER }, mean: 0, std_dvt: 0, values: []})
                        }
                        const measure_value= parseFloat(r[measure.field])
                        if (measure_value > measure_stats.get(measure.index).max.y)
                        {
                            measure_stats.get(measure.index).max = { 
                                y: measure_value, 
                                ciclo: r["Rif.Ciclo"]?r["Rif.Ciclo"]:r["Rif. Ciclo"], 
                                allevamento: r["Desc Centro Allevamento"],
                                fine_ciclo: r["Data Fine Ciclo"]
                            }
                        }
                        if (measure_value < measure_stats.get(measure.index).min.y)
                        {
                            measure_stats.get(measure.index).min = {
                                y: measure_value,
                                ciclo: r["Rif.Ciclo"]?r["Rif.Ciclo"]:r["Rif. Ciclo"],
                                allevamento: r["Desc Centro Allevamento"],
                                fine_ciclo: r["Data Fine Ciclo"]
                            }
                        }
                        measure_stats.get(measure.index).mean = measure_stats.get(measure.index).mean + measure_value
                        measure_stats.get(measure.index).values.push(measure_value)
                    })
                })
            }
        })

        measure_stats.forEach(measure_stat=>{
            measure_stat.mean = measure_stat.mean / measure_stat.values.length
            measure_stat.values.forEach(measure_value=>{
                measure_stat.std_dvt = measure_stat.std_dvt + Math.pow(measure_value - measure_stat.mean,2)
            })
            measure_stat.std_dvt = Math.sqrt(measure_stat.std_dvt /measure_stat.values.length)
        })
        setMeasureStats(measure_stats)
        setGraphData(new_graph_data)
        setAdditionalData({bulletData:[], allevamento:'', detailData:[]})
    }, [dataMap, filter.selected_allevamenti, filter.selected_tab_index])

    useEffect(() => {
        const radarDataTmp=[]
        tabs_conf.forEach(measureItem=>{
            const data = {measure: t('poultryChart.measures.'+measureItem.field)}
            detailData.forEach((row)=>{
                data[row.Ciclo] = row[t('poultryChart.measures.'+measureItem.field)] * 100 / measureStats.get(measureItem.index).max.y
            })
            radarDataTmp.push(data)
        })
        setRadarData(radarDataTmp)
    }, [detailData])


    function SummaryPanel({summaryData}) {
        return (
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <Typography variant={"h6"}>{t('poultryChart.summary.max.title')}</Typography>
                        <div style={{paddingTop: '1em'}}>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.max.value', 'Value')}</span>:&nbsp; {summaryData?.max.y !== Number.MIN_SAFE_INTEGER ? tabs_conf[filter.selected_tab_index].format(summaryData?.max.y): '--'}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.max.cycle', 'Breeding Cycle')}</span>:&nbsp; {summaryData?.max.ciclo}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.max.farm', 'Farm')}</span>:&nbsp; {summaryData?.max.allevamento}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.max.endDate', 'Cycle End Date')}</span>:&nbsp; {i18next.t("formatters:date", {date: Moment(summaryData?.max.fine_ciclo)})}</Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}> 
                        <Typography variant={"h6"}>{t('poultryChart.summary.min.title')}</Typography>
                        <div style={{paddingTop: '1em'}}>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.min.value', 'Value')}</span>:&nbsp; {summaryData?.min.y !== Number.MAX_SAFE_INTEGER ? tabs_conf[filter.selected_tab_index].format(summaryData.min.y): '--'}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.min.cycle', 'Breeding Cycle')}</span>:&nbsp; {summaryData?.min.ciclo}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.min.farm', 'Farm')}</span>:&nbsp; {summaryData?.min.allevamento}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.min.endDate', 'Cycle End Date')}</span>:&nbsp; {i18next.t("formatters:date", {date: Moment(summaryData?.min.fine_ciclo)})}</Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <Typography variant={"h6"}>{t('poultryChart.summary.mean.title')}</Typography>
                        <div style={{paddingTop: '1em'}}>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.mean.mean', 'Mean Value')}</span>:&nbsp; {tabs_conf[filter.selected_tab_index].format(summaryData?.mean)}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('poultryChart.summary.mean.std_dvt', 'Standard Deviation')}</span>:&nbsp; {tabs_conf[filter.selected_tab_index].format(summaryData?.std_dvt)}<br/></Typography>
                            <Typography><br/></Typography>
                            <Typography>&nbsp;</Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>


        )
    }

    const showTooltip = (event) => {
        return (
            <Grid style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', fontWeight: 'thin', borderRadius: '5px', border: '1px solid', padding: '0.25em', minWidth: '310px'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{marginLeft: '1em', marginRight: '1em'}}>
                        <div>{t('poultryChart.boxPlot.tooltip.title', 'Farm')}: <strong>{event.group}</strong></div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs style={{marginLeft: '1em', marginRight: '1em'}}>
                        <div><strong>{t('poultryChart.boxPlot.tooltip.summary', 'Summary')}</strong></div>                        
                        <div>{t('poultryChart.boxPlot.tooltip.nOfCycles', 'N. of slaughters')}: <strong>{event.formatted.n}</strong></div>                        
                        <div>{t('poultryChart.boxPlot.tooltip.max', 'Max')}: <strong>{event.formatted.extrema[1]}</strong></div>
                        <div>{t('poultryChart.boxPlot.tooltip.min', 'Min')}: <strong>{event.formatted.extrema[0]}</strong></div>
                        <div>{t('poultryChart.boxPlot.tooltip.mean', 'Mean')}: <strong>{event.formatted.mean}</strong></div>
                    </Grid>                
                    <Grid item xs style={{marginLeft: '1em', marginRight: '1em'}}>
                        <div><strong>{t('poultryChart.boxPlot.tooltip.quantiles', 'Quantiles')}</strong></div> 
                        <div>{event.formatted.quantiles[0]}%: <strong>{event.formatted.values[0]}</strong></div>
                        <div>{event.formatted.quantiles[1]}%: <strong>{event.formatted.values[1]}</strong></div>
                        <div>{event.formatted.quantiles[2]}%: <strong>{event.formatted.values[2]}</strong></div>
                        <div>{event.formatted.quantiles[3]}%: <strong>{event.formatted.values[3]}</strong></div>
                        <div>{event.formatted.quantiles[4]}%: <strong>{event.formatted.values[4]}</strong></div>

                    </Grid>
                </Grid>                
            </Grid>
        )
    }

    function handleBoxClick(event){        
        const bulletTempData = []
        tabs_conf.forEach(measure=>{
            const ranges_data= measureStats.get(measure.index)
            const measureData={
                id: measure.index,
                title: t('poultryChart.measures.'+measure.field),
                ranges: [
                    measure.format(ranges_data.min.y), 
                    measure.format(ranges_data.mean-ranges_data.std_dvt),
                    measure.format(ranges_data.mean + ranges_data.std_dvt),
                    measure.format(ranges_data.max.y)
                ],
                measures: dataMap.get(event.group).records.map(record => measure.format(Number.parseFloat(record[measure.field]))),
                markers:[measure.format(ranges_data.mean)]
            }
            bulletTempData.push(measureData)
        })
        const detailTempData = dataMap.get(event.group).records.map(record => {
            const detailData = {Ciclo: record["Rif.Ciclo"]?record["Rif.Ciclo"]:record["Rif. Ciclo"], 'epc': record['what_ids'][0]}
            tabs_conf.forEach(measure => detailData[t('poultryChart.measures.'+measure.field)]=record[measure.field])
            return detailData
        })
        setAdditionalData({bulletData: bulletTempData, allevamento: event.group, detailData: detailTempData})
    }

    function showBulletTooltip(event){
        console.log(event)
        return (<div></div>)

    }

    function copyToClipboard(epc){
        navigator.clipboard.writeText(epc).then(()=>{setCopy({copyDone:true, copyMessage: t('poultryChart.messages.copied', {epc: epc})})})
    }

    function rangeColor(range){
        console.log(range)
        return accentColor
    }
    function pickNivoColor(i){
        const cSchema = colorSchemes.set2        
        return cSchema[i%cSchema.length]
    }

    const showRadarTooltip = (event) => {
        return (
            <div className={classes.graph_tooltip}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.graph_tooltip_inner}><span style={{fontWeight: 'bold'}}>{event.index}</span></Grid>
                </Grid>
                <Grid container direction="column" spacing={1}>
                    {event.data.map((radarData, i)=> {
                        const tabConf = tabs_conf.findLast( tab=> t('poultryChart.measures.'+tab.field)===event.index)
                        return (
                            <Grid key={i} item xs className={classes.graph_tooltip_inner}>
                                
                                    <span className={classes.dot} style={{backgroundColor:radarData.color}}>&nbsp;</span>
                                    &nbsp;{radarData.id}:&nbsp;
                                    <span style={{fontWeight: 'bold'}}>{tabConf.format(measureStats.get(tabConf.index).max.y * radarData.value / 100)}</span>
                                
                            </Grid>)
                    })
                    }
                    
                </Grid>
            </div>
        )
    }

    function pickAnotherNivoColor(){
        console.log("Colore")
        //console.log(props)
        return colorSchemes.nivo[0]
    }

    return (
        <div className={classes.root} id="poultry_charts_page" style={{ height: "100%" }}>
            <ContainerDimensions>
                {({ width, height }) =>
                    <div style={{ height: height * 0.9, width: width}}>
                        <Box mb={'5em'} display={'flex'} alignItems={'end'}>
                                <FilterListIcon style={{ color: accentColor, marginRight: 16 }} />
                                <Autocomplete
                                    style={{ width: '30%', marginRight: 16 }}
                                    limitTags={3}
                                    id="pezzaturaAC"
                                    options={_.uniqBy(pezzatura, '_id')}
                                    getOptionLabel={(option) => option._id.replace('group_key:', '')}
                                    getOptionSelected={(option, value) => option._id == value._id}
                                    value={filter.pezz}
                                    //defaultValue={{"name": "Pollo Piccolo", "id":"pollo_piccolo"}}
                                    onChange={(event, newSelection) => {
                                        setFilter(prev => ({ ...prev, pezz: newSelection }));

                                    }}
                                    renderInput={params => {
                                        const { InputProps, ...restParams } = params;
                                        const { startAdornment, ...restInputProps } = InputProps;
                                        return (
                                            <TextField
                                                {...restParams}
                                                size={'small'}
                                                variant={"standard"}
                                                placeholder={t('actions.filterBy', { by: 'pezzatura' })}
                                                //defaultValue="Pollo Piccolo"
                                                InputProps={{
                                                    ...restInputProps,
                                                    startAdornment: (
                                                        <div style={{
                                                            maxHeight: '100px',
                                                            overflowY: 'auto',
                                                        }}
                                                        >
                                                            {startAdornment}
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} size='small' {...getTagProps({ index })} />
                                        ))
                                    }
                                />
                                <WhenRuleComponent state={filter} setState={setFilter} width={'30%'} outlined={false} />
                                <Autocomplete
                                    style={{ width: '30%', marginRight: 16, marginLeft: 16, maxHeight:'30px' }}
                                    multiple={true}
                                    limitTags={3}
                                    id="allevamentiAC"
                                    options={allevamenti}
                                    filterSelectedOptions={false}
                                    disableCloseOnSelect
                                    //getOptionLabel={(option) => option.name}
                                    //getOptionSelected={(option, value) => value.id == option.id }
                                    value={filter.selected_allevamenti}
                                    onChange={(event, newSelection) => {
                                        setFilter(prev => ({ ...prev, selected_allevamenti: newSelection }));

                                    }}
                                    renderInput={params => {
                                        const { InputProps, ...restParams } = params;
                                        const { startAdornment, ...restInputProps } = InputProps;
                                        return (
                                            <TextField
                                                {...restParams}
                                                size={'small'}
                                                variant={"standard"}
                                                placeholder={t('actions.filterBy', { by: 'Allevamento' })}
                                                InputProps={{
                                                    ...restInputProps,
                                                    startAdornment: (
                                                        <div style={{
                                                            maxHeight: '100px',
                                                            overflowY: 'auto',
                                                        }}
                                                        >
                                                            {startAdornment}
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option} size='small' {...getTagProps({ index })} />
                                        ))
                                    }
                                />
                        </Box>
                        <Tabs value={filter.selected_tab_index} onChange={changeTab}>
                            {tabs_conf.map((tabConf) => {
                                return <Tab key={tabConf.index} label={t('poultryChart.measures.'+tabConf.field)} {...a11yProps(tabConf.index)} />
                            })
                            }
                        </Tabs>
                        {loading &&
                            <Backdrop style={{ zIndex: 1000 }} open={loading}><CustomLoader size={80} /></Backdrop>}
                        {!loading && (error || graphData.length == 0 )&&
                            <div style={{display: 'flex', padding: '2em', alignItems: 'center', justifyContent: 'center', fontStyle: 'italic', fontSize: 'medium'}}>
                                <div><Typography>{t('poultryChart.messages.noData', 'No data for active filters')}</Typography></div>
                            </div>
                        }
                        {!loading && !error && graphData.length > 0 &&
                            <div>
                                {tabs_conf.map((tabConf) => {
                                    return (<TabPanel key={tabConf.index} value={filter.selected_tab_index} index={tabConf.index} >
                                        <br />
                                        <SummaryPanel summaryData={measureStats.get(tabConf.index)} />
                                        <br/>
                                        <Paper className={classes.paper} style={{ height: '70vh', margin: '1em', padding: '1em' }}>
                                            <ResponsiveBoxPlot                                                
                                                data={graphData}
                                                margin={{ top: 60, right: 140, bottom: 140, left: 60 }}
                                                padding={0.5}
                                                subGroupBy="subgroup"
                                                enableGridX={true}
                                                quantiles={[0.0, 0.1, 0.5, 0.9, 1.0]}
                                                axisTop={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 90,
                                                    legend: '',
                                                    legendOffset: 36,
                                                    truncateTickAt: 11
                                                }}
                                                axisRight={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendOffset: 0,
                                                    truncateTickAt: 0
                                                }}
                                                axisBottom={{
                                                    tickSize: 7,
                                                    tickPadding: 10,
                                                    tickRotation: 90,
                                                    legend: 'Allevamento',
                                                    legendPosition: 'middle',
                                                    legendOffset: 110,
                                                    truncateTickAt: 11
                                                }}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: tabConf.label,
                                                    legendPosition: 'middle',
                                                    legendOffset: -50,
                                                    truncateTickAt: 0
                                                }}
                                                colors={ ({group})=> ( group === allevamento? accentColor : accentColorAlpha )}
                                                colorBy={'group'}
                                                borderRadius={2}
                                                borderWidth={2}
                                                borderColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.3
                                                        ]
                                                    ]
                                                }}
                                                medianWidth={2}
                                                medianColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.3
                                                        ]
                                                    ]
                                                }}
                                                whiskerEndSize={0.6}
                                                whiskerColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.3
                                                        ]
                                                    ]
                                                }}
                                                motionConfig="stiff"
                                                tooltip={showTooltip}
                                                onClick={handleBoxClick}                                                
                                                areaOpacity={0.7}
                                            />
                                        </Paper>
                                        { allevamento ? 
                                        <Grid container spacing={2}>
                                            <Grid item xs={7}>
                                                <Paper className={classes.paper} style={{ height: '50vh', margin: '1em', padding: '1em', gridColumn:'2' }}>
                                                    <Typography variant={'h6'}>{t('poultryChart.table.title', {farm: allevamento})}</Typography>
                                                    <Table className={classes.table}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell>Ciclo</TableCell>
                                                                {tabs_conf.map(measure=>(<TableCell key={'m_'+measure.index}>{t('poultryChart.measures.'+measure.field)}</TableCell>))}                                                                                                                            
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {detailData.map((row, i)=>(
                                                                <TableRow key={'detail_row_'+i}>
                                                                    <TableCell title={t('poultryChart.table.copyTooltip')} onClick={()=>copyToClipboard(row.epc)} style={{cursor: 'pointer'}}><FileCopyIcon/></TableCell>
                                                                    <TableCell>
                                                                        <span className={classes.dot} style={{backgroundColor: pickNivoColor(i)}}>&nbsp;</span>&nbsp;{row.Ciclo}
                                                                    </TableCell>
                                                                    {tabs_conf.map(measure=>(
                                                                        <TableCell key={'detail_cell_'+i+'_'+measure.index}>{measure.format(row[t('poultryChart.measures.'+measure.field)])}</TableCell>
                                                                    ))}
                                                                    
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Paper className={classes.paper} style={{ height: '50vh', margin: '1em', padding: '1em', gridColumn: '1' }}>
                                                    <Typography variant={'h6'}>{t('poultryChart.radar.title', {farm: allevamento})}</Typography>
                                                    <ResponsiveRadar
                                                        data={radarData}
                                                        keys={detailData.map(row=>row.Ciclo)}
                                                        maxValue={100}
                                                        fillOpacity={0.1}
                                                        indexBy="measure"
                                                        margin={{ top: 70, right: 80, bottom: 90, left: 80 }}                                                        
                                                        borderColor={{ from: 'color' }}
                                                        sliceTooltip={ event => showRadarTooltip(event) }
                                                        blendMode="normal"
                                                        gridLabelOffset={25}
                                                        enableDots={false}
                                                        dotSize={10}
                                                        dotColor={{ theme: 'background' }}
                                                        dotBorderWidth={2}
                                                        colors={(d)=>pickNivoColor(d.index)}
                                                        motionConfig="wobbly"
                                                        legends={[
                                                            {
                                                                anchor: 'top-left',
                                                                direction: 'column',
                                                                translateX: -50,
                                                                translateY: -40,
                                                                itemWidth: 80,
                                                                itemHeight: 20,
                                                                itemTextColor: '#999',
                                                                symbolSize: 12,
                                                                symbolShape: 'circle',
                                                                effects: [
                                                                    {
                                                                        on: 'hover',
                                                                        style: {
                                                                            itemTextColor: '#000'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                    />
                                                </Paper>                                            
                                            </Grid>
                                            <Grid item xs>
                                                <Paper className={classes.paper} style={{ height: '50vh', margin: '1em', padding: '1em', gridColumn: '1' }}>
                                                    <Typography variant={"h6"}>{t('poultryChart.bullet.title', {farm: allevamento})}</Typography>
                                                    <ResponsiveBullet
                                                        animate={false}
                                                        data={bulletData}
                                                        minValue= 'auto'
                                                        margin={{ top: 30, right: 20, bottom: 70, left: 150 }}
                                                        spacing={40}
                                                        titleAlign={"end"}
                                                        titleOffsetX={-10}
                                                        titleOffsetY={0}
                                                        measureSize={0.4}
                                                        rangeColors={customRangeColors}
                                                        measureColors={"seq:rainbow"}
                                                        rangeComponent={CustomRangeComponent}
                                                        measureComponent={CustomMeasureComponent}
                                                        //tooltip={showBulletTooltip}
                                                        />
                                                </Paper>
                                            </Grid>                                            
                                        </Grid> :
                                        <Grid container spacing={2} justifyContent="space-evenly" alignItems="center">
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Typography variant={"h5"} align="center">{t("poultryChart.messages.selectFarm")}</Typography>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        }
                                    </TabPanel>)
                                })}
                            </div>
                        }
                    </div>
                }
            </ContainerDimensions>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={copyDone}
                autoHideDuration={3000}
                onClose={()=> {setCopy({copyDone:false, copyMessage:''})}}
                message={copyMessage}                
            />
        </div>
    )
}

export default PoultryChart