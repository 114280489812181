import React, {useContext, useState} from 'react'
import {Handle} from "react-flow-renderer";
import Typography from "@material-ui/core/Typography";
import {RulesConfiguratorContext, ThemeContext} from "../../contexts";
import chroma from "chroma-js"
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import _ from 'lodash'
import Box from "@material-ui/core/Box";
import {Comment, Delete, Edit, Lock, PostAdd, Visibility} from "@material-ui/icons";
import {
    Avatar,
    ClickAwayListener,
    Dialog,
    DialogContent,
    DialogTitle,
    Grow,
    Paper,
    Popper,
    Tooltip
} from "@material-ui/core";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import {useTranslation} from "react-i18next";
import EventModelDetail from "../event_models/EventModelDetail";
import CreateEventTemplate from "../event_models/create_event_model/CreateEventTemplate";
import {setModels, setNodeModel, updateGraphNodeInfo} from "./configurator_reducer";
import IconButton from "@material-ui/core/IconButton";
import {splitCbvValues} from "../../utils/CbvUtils";
import CreateTemplateDraft from "./CreateTemplateDraft";
import {fetchDomainModels} from "../../utils/Utils";
import {makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const CustomNodeComponent = (props) => {
    const {t} = useTranslation()
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        avatar: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            boxShadow: theme.shadows[5]
        },
        statusChip: {
            color: accentColor,
            border: '2px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'white'
        }
    }));
    const classes = useStyles()

    const [confContext, dispatch] = useContext(RulesConfiguratorContext)

    const nodeDomain = props.data.customProps.companyDomain
    const nodeModel = props.data.customProps.model
    const domainPermission = _.some(confContext.domains, ['domain', nodeDomain.domain])

    const initialDialogState = {newModel: false, edit: false, open: false}
    const [state, setState] = useState({
        openModelDialog: initialDialogState,
    })

    const bizStep = nodeModel?.biz_step||(nodeModel?.why ? nodeModel?.why[0]?.value : null)
    const isDraft = nodeModel?.is_draft === true
    const color = isDraft ? '#333333'
        : chroma(nodeDomain?.color).luminance() < 0.45 ? 'white' : 'black'

    const [{data: modelInfo, laoding: loadingModelInfo, error: errorModelInfo}, fetchModelInfo] = useAxios({
            url: `/api/capture/event_templates/${nodeModel?._id||''}`,
            method: "GET",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    const handleOpenModelDialog = (editMode, isDraft) => {
        //if (!isDraft) {
            fetchModelInfo().then(() => {
                setState({
                    ...state,
                    openModelDialog: {edit: editMode, open: true}
                });
            })
       // }
    };

    /** updateGraphAndSidebarOptions */
    function updateGraphAndSidebarOptions(data) {
        data && !!data._id && dispatch(updateGraphNodeInfo(data))
        dispatch(setNodeModel(null))
        confContext.nodeDomain?.domain && fetchDomainModels(confContext.nodeDomain?.domain).then((r) => {
            dispatch(setModels(r.data))
        })
    }
    /** ----------------------------------- */

    const handleCloseModelDialog = (newModel) => {
        if(newModel) {
            updateGraphAndSidebarOptions({...newModel, domain: nodeDomain?.domain});
        }
        setState({
            ...state,
            openModelDialog: {edit: false, open: false},
        })
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClosePopper = (newModel) => {
        updateGraphAndSidebarOptions(newModel);
        setAnchorEl(null);
    };

    return (<div>
            {!domainPermission && <Tooltip title={t('errors.forbidden')}>
                <Lock fontSize={'large'} style={{
                    color: chroma(nodeDomain?.color).darken(0.65).hex()/*'#555'*/,
                    position: 'absolute',
                    top: '-20px',
                    left: '-20px'
                }}/></Tooltip>}
            <div className={/*!domainPermission ? 'customNode nodrag' : */'customNode'} style={{
                minWidth: '200px',
                background: chroma(nodeDomain?.color || primaryColor).alpha(isDraft === true ? 0.08 : 0.55).hex()
                /*//'repeating-linear-gradient( -45deg, #dbdbdb, #dbdbdb 3px, #f2f2f2 3px, #f2f2f2 25px )'+
                "linear-gradient(135deg, #fafafa 25%, transparent 25%) -40px 0px / 80px 80px," +
                "linear-gradient(225deg, #fafafa 25%, transparent 25%) -40px 0px / 80px 80px," +
                "linear-gradient(315deg, #fafafa 25%, transparent 25%) 0px 0px / 80px 80px," +
                "linear-gradient(45deg, #fafafa 25%, "+ chroma(nodeDomain?.color || primaryColor).alpha(0.3).hex() +
                " 25%) 0px 0px / 80px 80px"
                : chroma(nodeDomain?.color || primaryColor).alpha(0.55).hex(),*/,
                color: color,
                border: isDraft === true ? '10px dashed ' + (nodeDomain?.color || accentColor)//' #adadad'
                    : props.selected ?
                        '2px solid' + (chroma(nodeDomain?.color || primaryColor).darken(0.4).hex())
                        : null,
                boxShadow: props.selected ? "8px 8px 9px 0px #bcbcbc" : null
            }}>
                <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                    {isDraft !== true && <Tooltip title={t('actions.view')} arrow>
                        <IconButton size={'small'} onClick={() => handleOpenModelDialog(false)}>
                            <Visibility style={{color: color, cursor: "pointer"}}/>
                        </IconButton>
                    </Tooltip>}
                    {isDraft === true && <Tooltip title={domainPermission ?
                        t('rulesConfigurator.messages.fulfillDraft', 'Fill in draft model')
                        : t('errors.forbidden')} arrow>
                    <span><IconButton size={'small'}
                                      disabled={!domainPermission}
                                      onClick={(event) => handleOpenModelDialog(true, isDraft)}>
                        <PostAdd style={{
                            color: domainPermission ? color : chroma(color).alpha(0.5).hex(),
                            cursor: "pointer"
                        }}/>
                    </IconButton></span>
                    </Tooltip>}
                    {<Tooltip title={domainPermission ? t('actions.edit') : t('errors.forbidden')} arrow>
                    <span><IconButton size={'small'} disabled={!domainPermission} onClick={(event) => {
                        if (isDraft === true) {
                            setAnchorEl(anchorEl ? null : event.currentTarget);
                        } else {
                            handleOpenModelDialog(true, isDraft)
                        }
                    }}>
                        <Edit style={{
                            color: domainPermission ? color : chroma(color).alpha(0.5).hex(),
                            cursor: "pointer"
                        }}/>
                    </IconButton></span>
                    </Tooltip>}
                    <Popper
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        placement="top"
                        disablePortal={false}
                        style={{zIndex: 1101}}
                        modifiers={{flip: {enabled: true}, arrow: {enabled: true, element: anchorEl}}} transition>
                        {({TransitionProps}) => (
                            <Grow {...TransitionProps} timeout={350}>
                                <ClickAwayListener onClickAway={handleClosePopper}>
                                    <Paper>
                                        <CreateTemplateDraft editMode={true}
                                                             draftName={nodeModel?.name}
                                                             draftDescription={nodeModel?.description}
                                                             id={nodeModel?._id}
                                                             nodeDomain={nodeDomain?.domain}
                                                             handleCloseDialog={handleClosePopper}/>
                                    </Paper>
                                </ClickAwayListener>
                            </Grow>
                        )}
                    </Popper>
                    {nodeModel?.description && <Tooltip style={{fontSize:'xxx-large'}} arrow title={nodeModel?.description}>
                        <Avatar className={classes.avatar} style={{
                            color: 'white',
                            backgroundColor: chroma(nodeDomain?.color).darken(0.65).hex()/*'#555'*/,
                            position: 'absolute',
                            top: '-30px',
                            left: '200px'
                        }}>
                            <Comment fontSize={'large'} />
                        </Avatar>
                    </Tooltip>}
                    {<Tooltip title={domainPermission ? t('actions.delete') : t('errors.forbidden')} arrow>
                    <span><IconButton size={'small'} disabled={!domainPermission} onClick={(event) => {
                        // TODO delete item
                        console.log("DELETE")
                        confContext.removeElement([{id: props.id}])
                    }}>
                        <Delete style={{
                            color: domainPermission ? color : chroma(color).alpha(0.5).hex(),
                            cursor: "pointer"
                        }}/>
                    </IconButton></span>
                    </Tooltip>}
                </Box>
                <Typography className={'fontStyle'} variant={"h5"}>{_.startCase(nodeModel?.name)}</Typography>
                {nodeModel?.type && <Typography gutterBottom variant={"subtitle1"}>{_.startCase(nodeModel?.type)}</Typography>}
                <Typography variant={"subtitle1"}>{nodeDomain?.name}</Typography>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    {isDraft &&
                    <Tooltip title={t('rulesConfigurator.messages.draftModel')} arrow>
                        <Chip label={t("entities.draft")} variant={'outlined'} className={classes.statusChip}/>
                    </Tooltip>}
                    <Tooltip title={splitCbvValues(bizStep)} arrow>
                        <div style={{marginLeft:'auto'}}>
                        {bizStep && <BizStepIcon bizstep={bizStep} color={color}/>}
                        </div>
                    </Tooltip>
                </Box>
                <Handle className={'customLeftHandle'} id="a" type="target" position="left" style={{borderRadius: 4}}/>
                <Handle className={'customRightHandle'} id="c" type="source" position="right"
                        style={{borderRadius: 4}}/>
                <Handle className={'customTopHandle'} id="b" type="target" position="top" style={{borderRadius: 4}}/>
                <Handle className={'customBottomHandle'} id="d" type="source" position="bottom"
                        style={{borderRadius: 4}}/>
                <Dialog open={state.openModelDialog.open} onClose={() => handleCloseModelDialog(null)}
                        fullWidth maxWidth={"md"} scroll='paper'>
                    <DialogTitle>{_.startCase(nodeModel?.name)}</DialogTitle>
                    <DialogContent>
                        {state.openModelDialog.open && modelInfo && state.openModelDialog.edit === false ?
                                <EventModelDetail hideVersion={true} props={modelInfo} noShow={true}/>
                                : state.openModelDialog.open && modelInfo && state.openModelDialog.edit === true
                                && <CreateEventTemplate fromRulesConf={handleCloseModelDialog}
                                                        is_draft={modelInfo.is_draft}
                                                        _id={nodeModel?._id||nodeModel?.mongoid}
                                                        editMode={true}
                                                        editEventModel={modelInfo}
                                                        domain={nodeDomain?.domain}/>}
                        {loadingModelInfo && <CustomLoader size={80}/>}
                        {errorModelInfo && <Typography align={'center'}
                                                       style={{color: 'darkgrey'}}>{t('errors.genericServerError') + errorModelInfo}</Typography>}
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

export default CustomNodeComponent
