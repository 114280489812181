import React, {useContext} from "react";
import {Box} from "@material-ui/core";
import WBooleanForm from "./WBooleanForm";
import MasterDataForm from "./MasterDataForm";
import {RelationshipContext} from "../../../contexts";

const RulesComponent = ({value, onChange, places, products, eventTemplates}) => {
    const relationshipContext = useContext(RelationshipContext);

    let ruleType = relationshipContext.getValues("selectType")

    return (
        <Box>
            {
                ruleType === relationshipContext.types[0] ?
                    <WBooleanForm value={value}
                                  onChange={onChange}
                                  places={places}
                                  products={products}
                                  eventTemplates={eventTemplates}
                    /> :
                    ruleType === relationshipContext.types[1] ?
                        <MasterDataForm value={value} onChange={onChange} places={places}/> : null
            }
        </Box>
    )

}

export default RulesComponent
