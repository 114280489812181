export const DELETE_SELECTED_INSTANCE_CODE = 'del_selected_instance_code'
export const CLEAR_ALL_INSTANCE_CODES = 'clear_all_instance_codes'

export const SET_SELECTED_CLASS_CODE_TYPE = 'set_selected_class_code_type'
export const SET_SELECTED_CLASS_CODE = 'set_selected_class_code'
export const SET_SERIAL_NUMBER = 'set_serial_number'

export const SET_LOADING = 'set_loading'

export const RESET_FORM = 'reset_form'

export const SET_HISTORY_ITEMS = 'set_history_items' // history items in response from the api
export const SET_EVENTS_MAP = 'set_events_map' // children/parentMap
export const SET_GRAPH_MODE = 'set_graph_mode' // true=graph; false=table
export const SET_HISTORY_DIRECTIONS = 'set_history_directions' // [forward?,backward?]

export function deleteSelectedInstanceCode(filteredSgtins, serverProductsHistory) {
    return {type: DELETE_SELECTED_INSTANCE_CODE, filteredSgtins, serverProductsHistory}
}
export function clearAllInstanceCodes() {
    return {type: CLEAR_ALL_INSTANCE_CODES }
}

export function setClassCodeType(serialType) {
    return {type: SET_SELECTED_CLASS_CODE_TYPE, serialType}
}

export function setSelectedClassCode(selectedGtin) {
    return {type: SET_SELECTED_CLASS_CODE, selectedGtin}
}

export function setSerialNumber(serialNumber) {
    return {type: SET_SERIAL_NUMBER, serialNumber}
}

export function setLoading(loading) {
    return {type: SET_LOADING, loading}
}

export function resetForm() {
    return {type: RESET_FORM}
}

export function setEventsMap(eventsMap) {
    return {type: SET_EVENTS_MAP, eventsMap}
}

export function setHistoryItems(serverProductsHistory, selectedSgtins, truncated, maxChildren, graphMode, graphType, loading, directions) {
    return {type: SET_HISTORY_ITEMS, serverProductsHistory, selectedSgtins, truncated, maxChildren, graphMode, graphType, loading, directions}
}

export function setGraphMode(graphMode) {
    return {type: SET_GRAPH_MODE, graphMode}
}

export function setHistoryDirections(directions) {
    return {type: SET_HISTORY_DIRECTIONS, directions}
}

export function productHistoryInitialize() {
    console.log("init product history graph provider")

    return {
        loading: false,

        selectedSgtins: [],

        serialType: null,
        selectedGtin: null,
        serialNumber: '',

        graphMode: false,
        graphType: 'dag', // grafico dettaglio history
        historyItems: null, // items arrivati dal server
        truncatedHistoryItems: null, // items troncati arrivati dal server
        maxChildren: 1,
        eventsMap: null,
        historyDirections: 'all',

        gtinNameLut: {},
        locationsLut: {},
        prodClassCounter: {}
    }
}

export function productHistoryReducer(state, action) {
    switch (action.type) {
        case DELETE_SELECTED_INSTANCE_CODE:
            //const newHistoryItems = state.historyItems.filter((item) => item.origin !== action.sgtinToDelete)
            return { ...state,
                selectedSgtins: action.filteredSgtins,
                historyItems: action.serverProductsHistory.items,
                gtinNameLut: action.serverProductsHistory.names,
                locationsLut: action.serverProductsHistory.locations,
                prodClassCounter: action.serverProductsHistory.prod_class_cnt
            }

        case CLEAR_ALL_INSTANCE_CODES:
            return { ...state, selectedSgtins: [],
                historyItems: null,
                truncatedHistoryItems: null,
                gtinNameLut: {},
                locationsLut: {},
                prodClassCounter: {}
            }

        case SET_SELECTED_CLASS_CODE_TYPE:
            return { ...state, serialType: action.serialType }

        case SET_SELECTED_CLASS_CODE:
            return { ...state, selectedGtin: action.selectedGtin }

        case SET_SERIAL_NUMBER:
            return { ...state, serialNumber: action.serialNumber }

        case SET_LOADING:
            return { ...state,
                loading: action.loading
            }

        case RESET_FORM:
            return { ...state,
                serialType: null,
                selectedGtin: null,
                serialNumber: ''
            }

        case SET_HISTORY_ITEMS:
            //console.log("SET_HISTORY_ITEMS --> action.serverProductsHistory:",action.serverProductsHistory)
            //console.log("SET_HISTORY_ITEMS --> action.serverProductsHistory:",action.truncated)
            const id_transformations = action.serverProductsHistory.items;
            const events = {}
            id_transformations.forEach((item) => {
                console.log("item:",item)
                // Mappa degli eventi
                if (!events[item.input]) {
                    events[item.input] = [item.event_id]
                    if(item.output) {
                        if (!events[item.output]) {
                            events[item.output] = [item.event_id]
                        } else {
                            if (events[item.output].indexOf(item.event_id) === -1)
                                events[item.output].push(item.event_id)
                        }
                    }
                } else {
                    if (events[item.input].indexOf(item.event_id) === -1)
                        events[item.input].push(item.event_id)
                }
            })

            return {
                ...state,
                selectedSgtins: action.selectedSgtins ? action.selectedSgtins : state.selectedSgtins,
                historyItems: action.truncated ? state.historyItems : action.serverProductsHistory.items,
                truncatedHistoryItems: action.truncated ? action.serverProductsHistory.items : state.truncatedHistoryItems,
                graphMode: (action.graphMode !== null && action.graphMode !== undefined) ? action.graphMode : state.graphMode,
                graphType: action.graphType ? action.graphType : state.graphType,
                historyDirections: action.directions !== undefined ? action.directions : state.historyDirections,
                eventsMap: events,
                gtinNameLut: action.serverProductsHistory.names,
                locationsLut: action.serverProductsHistory.locations,
                prodClassCounter: action.serverProductsHistory.prod_class_cnt,
                maxChildren: action.maxChildren,
                loading: action.loading
            }

        case SET_EVENTS_MAP:
            //console.log("SET_EVENTS_MAPS --> action.eventsMap:",action.eventsMap)
            return {
                ...state,
                eventsMap: action.eventsMap,
            }

        case SET_GRAPH_MODE:
            console.log("SET_GRAPH_MODE --> action.graphMode:",action.graphMode)
            return {
                ...state,
                graphMode: action.graphMode,
            }

        case SET_HISTORY_DIRECTIONS:
            console.log("SET_HISTORY_DIRECTIONS --> action.direction:",action.directions)
            return {
                ...state,
                historyDirections: action.directions,
            }

        default:
            console.log("Errore: azione", action.type, "sconosciuta")
            return state;
    }
}
