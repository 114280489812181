import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    MiniMap,
    ReactFlowProvider,
    removeElements,
    updateEdge,
} from 'react-flow-renderer';
import Sidebar from './Sidebar';
import CustomNode from './CustomNode';
import './configurator.css';
import {Backdrop, DialogActions, DialogTitle, Paper, TextField, Tooltip, Typography} from "@material-ui/core";
import CustomEdge from "./CustomEdge";
import {AuthContext, RulesConfiguratorContext, RulesConfiguratorProvider, ThemeContext} from "../../contexts";
import _ from "lodash";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import i18next, {langResources} from "../../i18n/i18next";
import {
    configuratorReducer,
    setGraphPlotInfo,
    setModels,
    setNodeModel,
    updateGraphNodeInfo
} from "./configurator_reducer";
import chroma from "chroma-js";
import distinctColors from 'distinct-colors'
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {Controller, useForm} from "react-hook-form";
import shortid from "shortid";
import * as yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {fetchDomainModels, severityTypes, useDomains} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {CircularPercentStatusProgress} from "./ProjectsTable";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {PostAdd} from "@material-ui/icons";
import MaterialTable from 'material-table';
import CreateEventTemplate from "../event_models/create_event_model/CreateEventTemplate";

//let id = 0;
//const getId = () => `dndnode_${id++}`;

const DraftsTable = ({elements}) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        }
    }));
    const classes = useStyles()
    const [confContext, dispatch] = useContext(RulesConfiguratorContext)
    const [state, setState] = useState({openModelDialog: {newModel: false, open: false, data: null}})

    const onRowClick = (event, rowData) => {
        //console.log("TABLE EV MOD -> rowData: ", rowData)
    }

    function infillModel(rowData) {
        const domain = rowData.data?.customProps?.companyDomain?.domain
        const mongoId = (rowData.data?.customProps?.model?._id||rowData.data?.customProps?.model?.mongoid)
        axios.get(`/api/capture/event_templates/${mongoId}`)
            .then((res) => {
                setState({
                    openModelDialog: {
                        newModel: true,
                        open: true,
                        data: {
                            domain: domain,
                            model: res.data,
                            _id: mongoId
                        }
                    }
                })
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    const handleCloseModelDialog = (newModel) => {
        //console.log("handleCloseModelDialog --> newModel:",newModel)
        newModel && !!newModel._id && dispatch(updateGraphNodeInfo(
            {...newModel, domain: state.openModelDialog.data.domain}))

        setState({openModelDialog: {newModel: false, open: false, data: null}})
    };

    function hasPermission(rowData) {
        return (_.some(confContext.domains, ['domain', rowData.data?.customProps?.companyDomain?.domain]));
    }

    const materialTableRef = React.createRef();
    const [tableData, setTableData] = useState(_.partition(elements, (el) => {
        return el.type === 'customNode' && el.data?.customProps?.model?.is_draft === true
    })[0])

    /*useEffect(() => {
        if (projectInfos) {
            setTableData(_.map(projectInfos, (project) => ({...project, status: Math.random().toPrecision(2)*100})))
        }
    }, [projectInfos])*/

    const tab = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable
    return (<div className={classes.root}>
        <MaterialTable
            title={t('entities.draft_plural')}
            localization={tab}
            columns={[{
                title: t('entities.company.companyDomain'),
                field: 'data.customProps.companyDomain.domain',
                sorting: true,
                align: 'right',
                editable: 'never'
            }, {
                title: t('rulesConfigurator.draftModelName'),
                field: 'data.customProps.model.name',
                sorting: true,
            }, {
                title: t('rulesConfigurator.draftModelDescription'),
                field: 'data.customProps.model.description',
                sorting: true,
            }]}
            data={tableData}
            tableRef={materialTableRef}
            actions={[
                rowData => (hasPermission(rowData) && {
                    icon: () => <PostAdd style={{color: accentColor}}/>,
                    tooltip: t('eventModels.fulfillModel', "Fill in this model"),
                    onClick: (event, rowData) => infillModel(rowData),
                    disabled: !hasPermission(rowData),
                })
                /*,
                rowData => (hasPermission(rowData) && {
                    icon: () => <Delete style={{color: accentColor}}/>,
                    tooltip: t('actions.delete', "Delete"),
                    onClick: (event, rowData) => {
                        console.log("delete:", rowData.data?.customProps?.model?.name)
                    },
                })*/
            ]}
            components={{Container: props => <Paper {...props} elevation={0}/>,}}
            options={{actionsColumnIndex: -1, padding: 'dense'}}
            onRowClick={((event, rowData) => {
                hasPermission(rowData) && onRowClick(event, rowData)
            })}
            editable={{
                isEditable: rowData => hasPermission(rowData),
                isEditHidden: rowData => !hasPermission(rowData),
                isDeletable: rowData => hasPermission(rowData),
                isDeleteHidden: rowData => !hasPermission(rowData),
                onRowUpdate: async (newData, oldData) => {
                    //console.log("newData:", newData)
                    //console.log("oldData:", oldData)
                    const url = `api/capture/event_templates/${oldData.data.customProps.model._id}?domain=${oldData.data.customProps.companyDomain.domain}`

                    axios({
                        method: 'patch',
                        url: url,
                        data: {name: newData.data.customProps.model.name, description: newData.data.customProps.model.description || ''},
                        config: {headers: {'Media-Type': 'application/json'}}
                    }).then(function (res) {
                        if (res) {
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...tableData];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setTableData([...dataUpdate]);
                                    resolve();
                                    dispatch(updateGraphNodeInfo({
                                        _id: newData.data.customProps.model._id,
                                        name: newData.data.customProps.model.name,
                                        description: newData.data.customProps.model.description,
                                        is_draft: true
                                    }))
                                }, 100)
                            })
                        }
                        //console.log("EDIT OKAY --> res:",res)
                    }).catch((error) => {
                        console.log('error editing draft name:', error);
                        if (error.response.status === 403) {
                            //setServerError((error.response.data?.detail[0]?.msg || error.response.data.detail) + ' ' + t('errors.forbidden', 'You don\'t have the permission on this domain!'));
                        } else //setServerError(error.response.status + ' ' + t("errors.genericServerError"));
                            return Promise.reject(error)
                    })
                },
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            /** TODO: delete con counter ref */
                            const dataDelete = [...tableData];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setTableData([...dataDelete]);

                            resolve()
                        }, 100)
                    }),
            }}
        />
        {state.openModelDialog.data && <Dialog open={state.openModelDialog.open} onClose={handleCloseModelDialog}
                                               fullWidth={true} maxWidth={"md"} scroll='paper'>
            <DialogTitle>{_.startCase(state.openModelDialog.data.model)}</DialogTitle>
            <DialogContent>
                {state.openModelDialog.newModel &&
                <CreateEventTemplate fromRulesConf={handleCloseModelDialog}
                                     is_draft={true}
                                     _id={state.openModelDialog.data._id}
                                     domain={state.openModelDialog.data.domain}
                                     editEventModel={state.openModelDialog.data.model}
                                     editMode={true}/>}
            </DialogContent>
        </Dialog>}
    </div>);
}

const RulesConfigurator = () => {
    const {t} = useTranslation()

    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiPopover-paper': {
                width: '60%',
                borderRadius: '8px'
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            margin: 'auto',
            borderRadius: '20px',
            paddingLeft: '16px',
            paddingRight: '16px',
            boxShadow: theme.shadows[2]
        },
    }));
    const classes = useStyles()

    const [state, dispatch] = useContext(RulesConfiguratorContext)
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const [{data: graphPlot, loading: loadingGraphPlot, error: errorGraphPlot}, getGraphPlot] = useAxios({
            url: state.project_id ?
                `/api/graphic_conf/project/${state.project_id}/event_graph/plot/`
                : `/api/graphic_conf/project/by_name/event_graph/plot/?project_name=${state.project_name}`
        },
        {useCache: false}
    )

    useEffect(() => {
        if (graphPlot) {
            dispatch(setGraphPlotInfo(graphPlot))
            setValue('project_name', graphPlot.project_name)
            setElements(_.concat(graphPlot.nodes, graphPlot.edges))
        }
    }, [graphPlot])

    const [elements, setElements] = useState(null);

    const schema = yup.object().shape({
        project_name: yup.string().required(t('rulesConfigurator.messages.nameRequired')),
    });
    const {control, getValues, setValue, handleSubmit, errors} = useForm({
        validationSchema: schema,
        defaultValues: {project_name: state.project_name || ''}
    })

    /** ON CONNECT senza controllo dei PERMESSi
     * const _onConnect = (params) => setElements((els) => addEdge(
     {...params, type: 'customEdge', animated: true, arrowHeadType: null}, els));
     */

    /**
     * Si possono connettere i nodi SE ALMENO UNO ci "Appartiene" e ne abbiamo i diritti.
     * Se tutti e due i nodi non sono nostri non posso connetterli.
     */
    const onConnect = (params) => {
        const nodes = _.filter(elements, function (o) {
            return o.id === params.source || o.id === params.target
        })
        const permission = _.some(nodes, (el) => {
            return el.type === 'customNode'
                && _.some(state.domains, ['domain', el.data?.customProps?.companyDomain?.domain || ''])
        })

        if (!permission) {
            setOpenAlertMessage({open: true, message: t('errors.forbidden'), severity: severityTypes.ERROR})
        } else {
            setElements((els) => addEdge({...params, type: 'customEdge', arrowHeadType: null}, els));
        }
    }

    // gets called after end of edge gets dragged to another source or target
    const onEdgeUpdate = (oldEdge, newConnection) =>
        setElements((els) => updateEdge(oldEdge, newConnection, els));

    const onElementsRemove = (elementsToRemove) => {
        //console.log("elementsToRemove:",elementsToRemove)
        const permission = _.every(elementsToRemove, (el) => {
            return el.type !== 'customNode' || (el.type === 'customNode'
                && _.some(state.domains, ['domain', el.data?.customProps?.companyDomain?.domain || '']))
        })

        if (!permission)
            setOpenAlertMessage({open: true, message: t('errors.forbidden'), severity: severityTypes.ERROR})
        else {
            setElements((els) => removeElements(elementsToRemove, els));
            /** TODO (se serve): riabilitare riga sotto (ed eliminare riga sopra) per chiedere conferma all'utente */
            //setOpenDialog({...openDialog, open: true, isConfirmDialog: true, elementsToRemove: elementsToRemove})
        }
    }

    state.removeElement = onElementsRemove

    const onSave = useCallback(async (_data, updatedElements) => {
        //console.log("updatedElements:",updatedElements)
        if (reactFlowInstance) {
            const flow = Array.isArray(updatedElements) ? updatedElements : reactFlowInstance.toObject().elements;
            const url = `api/graphic_conf/project/${state.graphPlotInfo._id}/event_graph/plot/`
            let [nodes, edges] = _.partition(flow, (el) => {
                return el.type === 'customNode'
            })

            const __data = {
                ...state.graphPlotInfo,
                project_name: _data.project_name,
                zoom: flow.zoom,
                position: flow.position,
                nodes: _.map(nodes, (node) => {
                    return ({
                        ...node,
                        data: {
                            ...node.data,
                            customProps: {
                                companyDomain: {
                                    domain: node.data.customProps.companyDomain.domain,
                                    color: node.data.customProps.companyDomain.color
                                },
                                model: {
                                    _id: node.data.customProps.model._id,
                                    is_draft: node.data.customProps.model.is_draft,
                                }
                            }
                        }
                    })
                }),
                edges: edges
            }

            await axios.put(url, __data)
                .then(res => {
                    if (res) {
                        setOpenAlertMessage({
                            open: true,
                            message: t('rulesConfigurator.messages.saved'),
                            severity: severityTypes.SUCCESS
                        })
                    }
                }).catch((error) => {
                    if (error.response.status === 403)
                        setOpenAlertMessage({
                            open: true,
                            message: t('rulesConfigurator.messages.nameAlreadyExists'),
                            severity: 'warning'
                        });
                    else
                        setOpenAlertMessage({
                            open: true,
                            message: t('rulesConfigurator.messages.errorSaving'),
                            severity: severityTypes.ERROR
                        })

                    console.log("error editing project:", error)
                    return Promise.reject(error)
                })
        }
    }, [reactFlowInstance]);

    // TODO: Cmd+S || Ctrl+S
    document.addEventListener("keydown", function (e) {
        if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
            e.preventDefault();
            // Process the event here (such as click on submit button)
            //const values = getValues()
            //if(values.project_name)
            //onSave(values)
            //else setOpenAlertMessage({open: true, message: "Please, insert a Project Name", severity: severityTypes.ERROR})
        }
    }, false);

    const onRestore = useCallback(() => {
        getGraphPlot() // fetch dell'ultima versione salvata
    }, [setElements]);

    const status = useMemo(() => {
        if (elements) {
            if (state.nodeToUpdate && !!state.nodeToUpdate._id) {
                const values = getValues()
                if (values.project_name)
                    onSave(values, elements)
                else {
                    onSave({...values, project_name: state.project_name}, elements)
                }
                dispatch(updateGraphNodeInfo(null))
            }
            let [nodes,] = _.partition(elements, (el) => {
                return el?.type === 'customNode'
            })

            const [drafts,] = _.partition(nodes || [], (node) => {
                return node?.data?.customProps?.model?.is_draft
            })

            return ((nodes.length - drafts.length) / nodes.length).toPrecision(1) * 100
        }

    }, [elements])

    useEffect(() => {
        if (state.nodeToUpdate) {
            setElements((els) =>
                _.map(els, (el) => {
                    if (el.type === 'customNode') {
                        if (((state.nodeToUpdate?.name === el.data?.customProps?.model?.name)
                                && (state.nodeToUpdate?.domain === el.data?.customProps?.companyDomain.domain))
                            || (state.nodeToUpdate._id && state.nodeToUpdate._id === el.data?.customProps?.model?._id)) {
                            return {
                                ...el,
                                data: {
                                    ...el.data,
                                    customProps: {
                                        ...el.data.customProps,
                                        model: state.nodeToUpdate,
                                    }
                                }
                            }
                        } else return el
                    } else return el
                }))
        }
    }, [state.nodeToUpdate, setElements])

    const onLoad = (_reactFlowInstance) =>
        setReactFlowInstance(_reactFlowInstance);

    const initialErrorState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialErrorState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const postNewDraft = async (newDraftToPost) => {
        const res = {
            "name": newDraftToPost,
            "is_draft": true,
            "version": 0,
        }
        const url = 'api/capture/event_templates/?domain=' + state.nodeDomain.domain
        return await axios.post(url,
            {...res}
            ).then((res) => {
            state.nodeDomain?.domain && fetchDomainModels(state.nodeDomain?.domain).then((r) => {
                dispatch(setModels(r.data))
            })
            setOpenAlertMessage({
                open: true,
                message: t('rulesConfigurator.messages.added'),
                severity: severityTypes.SUCCESS
            })
            return res.data._id
        }).catch((err) => {
            console.log("error adding new model draft:", err)
            if (err.response.status === 400) {
                setOpenAlertMessage({
                    open: true,
                    message: t('createEventModel.eventNameBusy'),
                    severity: severityTypes.ERROR
                })
            } else {
                setOpenAlertMessage({
                    open: true,
                    message: t('errors.errorAddingModelDraft'),
                    severity: severityTypes.ERROR
                })
            }
        })
    }

    const addNode = async (customProps) => {
        if(customProps) {
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const position = reactFlowInstance?.project({
                x: reactFlowBounds.left + document.body.offsetWidth / 2,
                y: reactFlowBounds.top + document.documentElement.clientHeight / 4,
            });

            let _id
            if (customProps?.model?.is_new) {
                _id = await postNewDraft(customProps.model?.name);
            }
            if ((customProps?.model?.is_new && !!_id) || !customProps?.model?.is_new) {
                const newNode = {
                    id: shortid.generate(),
                    type: "customNode",
                    position,
                    data: {
                        customProps: {
                            companyDomain: customProps.companyDomain,
                            model: customProps.model.is_new ? {...customProps.model, _id: _id} : customProps.model
                        },
                        removeElement: onElementsRemove
                    },
                    sourcePosition: 'right',
                    targetPosition: 'left',
                };

                setElements((els) => {
                    return [...els, newNode];
                });
            }
        }
    }

    const onDrop = async (event) => {
        event.persist();
        const okayData = event.dataTransfer.getData('application/configurator/okayData');
        if (okayData) {
            const type = event.dataTransfer.getData('application/configurator/type');
            let domain = event.dataTransfer.getData('application/configurator/domain')
            let model = event.dataTransfer.getData('application/configurator/model')

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            try {
                domain = JSON.parse(domain);
                model = JSON.parse(model);
                //console.log("ONDROP --> domain:", domain)
                //console.log("ONDROP --> model:", model)
            } catch (e) {
                console.log("error parsing json:", e)
            }

            let _id
            if (model.is_new) {
                _id = await postNewDraft(/*_.snakeCase(*/model.name/*)*/);
            }
            if ((model.is_new && !!_id) || !model.is_new) {
                const position = reactFlowInstance?.project({
                    x: event.clientX - reactFlowBounds.left,
                    y: event.clientY - reactFlowBounds.top,
                });
                const newNode = {
                    id: shortid.generate(),
                    type,
                    position,
                    data: {
                        customProps: {companyDomain: domain, model: model.is_new ? {...model, _id: _id} : model},
                    },
                    sourcePosition: 'right',
                    targetPosition: 'left',
                };

                setElements((es) => es.concat(newNode));
            }
        }
    };

    const [openDialog, setOpenDialog] = useState({open: false, isConfirmDialog: false, elementsToRemove: null})
    const handleCloseDialog = () => {
        state.nodeDomain?.domain && fetchDomainModels(state.nodeDomain?.domain).then((r) => {
            dispatch(setModels(r.data))
            dispatch(setNodeModel(null))
        })
        setOpenDialog({...openDialog, open: false});
    };

    const handleConfirmDelete = () => {
        openDialog.elementsToRemove && setElements((els) => removeElements(openDialog.elementsToRemove, els));
        setOpenDialog({...openDialog, open: false, elementsToRemove: null})
    }

    const checkForDrafts = () => {
        setOpenDialog({open: true, isConfirmDialog: false, elementsToRemove: null})
    }

    return (
        <div className={classes.root}>
            {loadingGraphPlot &&
            <Backdrop style={{zIndex: 1000}} open={loadingGraphPlot}><CustomLoader size={80}/></Backdrop>}
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                      autoHideDuration={3000} onClose={handleCloseErrorMessage}>
                <Alert onClose={handleCloseErrorMessage}
                       severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
            </Snackbar>
            <Box display={'flex'} alignItems={'flex-start'} mb={1} style={{position: "absolute", top: '75px'}}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    {loadingGraphPlot ? <CustomLoader/> :
                        <CircularPercentStatusProgress color={accentColor} status={status || 0}/>}
                    {!loadingGraphPlot &&
                    <Tooltip arrow title={t('rulesConfigurator.messages.checkForDraft')}>
                        <Button size={'small'} onClick={checkForDrafts}>{t('actions.check')}</Button>
                    </Tooltip>}
                </Box>
                <form onSubmit={handleSubmit(onSave)}>
                    <Box marginLeft={'2%'} marginBottom={'1%'} alignItems={'start'} display={'flex'}
                         justifyContent={'flexStart'}>
                        <Controller
                            as={<TextField
                                style={{flex: 1}}
                                //variant={'outlined'}
                                size={'small'}
                                placeholder={t('rulesConfigurator.projectName')}
                                error={!!errors.project_name}
                                helperText={errors.project_name?.message}
                            />}
                            name="project_name"
                            control={control}
                        />
                        <Box marginLeft={'2%'} display={'flex'}>
                            <Button className={classes.button}
                                    type="submit">{t('actions.save', {what: t('entities.project')})}</Button>
                            <Button style={{textTransform: 'none'}}
                                    onClick={onRestore}>{t('rulesConfigurator.restoreSavedVersion')}</Button>
                        </Box>
                    </Box>
                </form>
                <Dialog open={openDialog.open} maxWidth={'sm'} scroll='paper' onClose={handleCloseDialog}>
                    <DialogContent>
                        {openDialog.isConfirmDialog === true && t('rulesConfigurator.messages.areYouSureToDeleteNodes')}
                        {openDialog.isConfirmDialog === false && <DraftsTable elements={elements}/>}
                    </DialogContent>
                    {openDialog.isConfirmDialog === true && <DialogActions>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Button onClick={handleCloseDialog} color="default">
                                {t('actions.cancel')}
                            </Button>
                            <Button onClick={handleConfirmDelete} className={classes.button}
                                    autoFocus>
                                {t('actions.confirm')}
                            </Button>
                        </Box>
                    </DialogActions>}
                </Dialog>
            </Box>
            {elements && <div className="dndflow">
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            snapToGrid
                            minZoom={0.2}
                            defaultZoom={0.5}
                            nodeTypes={{customNode: CustomNode}}
                            edgeTypes={{customEdge: CustomEdge}}
                            elements={elements}
                            onConnect={onConnect}
                            onEdgeUpdate={onEdgeUpdate}
                            connectionMode={'loose'}
                            onElementsRemove={onElementsRemove}
                            onLoad={onLoad}
                            onDrop={onDrop}
                            onDragOver={onDragOver}>
                            <MiniMap
                                nodeStrokeWidth={16}
                                nodeStrokeColor={(n) => {
                                    /*if (n.type === 'input') return '#0041d0';
                                    if (n.type === 'selectorNode') return '#aabad7';
                                    if (n.type === 'output') return '#ff0072';
                                    if (n.type === 'default') return 'black';
                                    if (n.type === 'customNode') return '#9ca8b3';*/
                                    if (n.data?.customProps?.model?.is_draft === true)
                                        return n.data?.customProps?.companyDomain?.color || accentColor;
                                }}
                                nodeColor={(n) => {
                                    if (n.data?.customProps?.model?.is_draft === false)
                                        return chroma(n.data?.customProps?.companyDomain?.color || '#9ca8b3').alpha(0.5).hex() || '#9ca8b3';
                                    else return 'white'
                                }}
                            />
                            <Controls/>
                            <Background/>
                        </ReactFlow>
                    </div>
                    <Sidebar classes={classes} setOpenAlertMessage={setOpenAlertMessage} addNode={addNode}/>

                </ReactFlowProvider>
            </div>}
            {errorGraphPlot && <Typography align={'center'}style={{color: 'darkgrey'}}>{i18next.t('errors.genericServerError')}</Typography>}
        </div>
    );
}

const RulesConfiguratorWrapper = (props) => {
    const authContext = useContext(AuthContext)
    const project_id = props.location.state?._id
    const project_name = props.location.state?.project_name

    const {t} = useTranslation()

    const domains = useDomains(authContext.isAdmin, authContext.activeDomains)
    const domainsWithColors = useMemo(() => {
        if (domains.loading)
            return null
        let _domainsWithColors = []

        // colori per domain
        let palette// = chroma.scale('RdYlBu').padding(0.15).colors(Object.keys(_brands).length)
        palette = distinctColors({count: domains?.data?.length || 1, lightMin: 50})
        _domainsWithColors = _.map(domains.data, (brand, index) => ({...brand, color: chroma(palette[index]).hex()}));
        return _domainsWithColors

    }, [domains])

    return (props.location.state && domainsWithColors ?
            <RulesConfiguratorProvider reducer={configuratorReducer}
                                       initialState={{
                                           project_id: project_id,
                                           project_name: project_name,
                                           domains: domainsWithColors,
                                           models: [],
                                           modelsNames: []
                                       }}>
                <RulesConfigurator/>
            </RulesConfiguratorProvider>
            : domains.loading ? <CustomLoader size={80}/>
                : domains.error ?
                    <Typography align={'center'} style={{color: 'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                    : null

    )
}

export default RulesConfiguratorWrapper;
