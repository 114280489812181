import React, {useState} from "react";
import TableRevocations from "../components/tables/TableRevocations";
import useAxios from 'axios-hooks';
import MaterialTable from "material-table";

const Revocations = () => {

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const increment = skip * limit

    const [{data: revocations, loading, error},] = useAxios({
            url: "/api/revocations/?skip=" + increment + "&limit=" + limit
        },
        {useCache: false}
    )

    return (
        <div>
            {revocations ? <TableRevocations revocations={revocations} limit={limit} setLimit={setLimit} skip={skip} setSkip={setSkip}/> :
                loading ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error ? <p>ERROR</p> : null}
        </div>
    )

}

export default Revocations;
