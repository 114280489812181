import React, {useMemo, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Box} from "@material-ui/core";
import TagValue from "../TagValue";
import useAxios from "axios-hooks";
import _ from 'lodash'
import {useTranslation} from "react-i18next";

const Tags = ({value, onChange}) => {
    const {t} = useTranslation()

    const [{data: availableTags}] = useAxios({
            url: "api/items/_tags/"
        },
        {useCache: false}
    );

    const [new_tag, setNewTag] = useState(null);
    const [error, setError] = useState(false)

    const tags = _.filter(value, function (o) {
        return _.lowerCase(o) !== 'other'
    }) || [];

    const used_tags = tags.map((value) => value);

    let filtered_tags = useMemo(() => {
        if(!availableTags)
            return []

        return availableTags.filter((tag) => !_.includes(used_tags, tag) && _.lowerCase(tag) !== 'other')
    }, [availableTags, used_tags])

    const onClearClick = (tag) => {
        let new_tags = tags.filter(val => val !== tag)
        onChange && onChange(new_tags);

        if (availableTags.filter(val => val === tag).length) {
            filtered_tags.push(tag)
        }
    }

    const handleChange = (event, values) => {
        if (values.indexOf(' ') >= 0 && new_tag !== null && _.lowerCase(new_tag) !== 'other') {
            if (new_tag.trim().length > 0) {
                const new_tags = [...tags, new_tag];
                onChange && onChange(new_tags);
                filtered_tags.filter(val => val !== new_tag)
                setNewTag(null)
            }
        } else {
            if (values) {
                if (!values.trim()) {
                    setError(!error)
                }
                setNewTag(values);
            }
        }
        if (!values) {
            setError(!error)
            setNewTag(null)
        }
    };

    return (
        <Box>
            {
                tags.length > 0 ? (
                    <Box pt={1} pb={1}>
                        {
                            tags.map((tag) => <TagValue key={tag} tag={tag} remove={onClearClick}/>)
                        }
                    </Box>
                ) : null
            }
            <Box>
            <Autocomplete
                freeSolo
                options={filtered_tags}
                key={tags}
                getOptionLabel={(option) => option}
                onInputChange={handleChange}
                renderInput={(params) =>
                    <TextField {...params}
                               label="Tags"
                               helperText={t("addItem.pressSpaceToAddTag")}/>}
            />
            </Box>
            {error ? <p style={{color: 'red'}}>{t("addItem.tagErrorMessage")}</p>
                : null
            }
        </Box>
    )

}

export default Tags;
