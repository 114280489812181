import React, {useContext, useEffect, useMemo} from "react";
import TextField from "@material-ui/core/TextField";
import {createFilterOptions} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import useAxios from "axios-hooks";
import AddIcon from "@material-ui/icons/Add";
import {IconButton} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import {AuthContext, ThemeContext} from "../../contexts";
import axios from "axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import FreeSoloAutocomplete from "../FreeSoloAutocomplete";
import {useTranslation} from "react-i18next";

const filter = createFilterOptions();

const EditTag = (props) => {
    const { t } = useTranslation();

    const {primaryColor, primaryColorAlpha, accentColor} = useContext(ThemeContext)
    const {activeMainDomain} = useContext(AuthContext)
    const useStyles = makeStyles({
        root: {
            width: 300,
            flex: 1,
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        addIcon: {
            marginLeft: '2%'
        },
        delIcon: {
            marginRight: '2%'
        },
        avatar: {
            backgroundColor: primaryColorAlpha,
            color: primaryColor,
        }
    });
    const classes = useStyles()

    const {object, handleClose} = props
    const open = object?.open
    const objectTags = object.product ? object.product.tags : object.relation.chain

    const [value, setValue] = React.useState(null);

    const [{data: ownerTags}, postExecute] = useAxios({
            url: object.relation ? `api/rules/_supply_chain_tags/?domain=${activeMainDomain}` : "api/items/_tags/"
        },
        {useCache: false, manual: true}
    );

    useEffect(() => {
        postExecute()
    }, [])

    const tags = useMemo(() => {
        if (!ownerTags)
            return []
        //console.log("_tags:", ownerTags)
        return ownerTags.map((tag) => ({
            tag: tag
        }))
    }, [ownerTags])

    const handleEditTagClick = (action, value) => {
        if (value && props.editable) {
            console.log("object:",object)
            const url = object.product ?
                'api/items/_' + action + '_tag/' + (object.product._id||object.product.id) + '?tag=' + value
                : 'api/rules/_' + action + '_chain_tag/' + (object.relation._id||object.relation.id) + '?tag=' + value
            switch (action) {
                case 'add':
                    if ((object.product && !object.product.tags.includes(value))
                        || (object.relation && !object.relation.chain.includes(value))) {
                        axios({
                            method: 'patch',
                            url: url,
                            config: {headers: {'Media-Type': 'application/json'}}
                        })
                            .then(function (response) {
                                if (response.status === 200) {
                                    object.product ? object.product.tags.push(value)
                                        : object.relation.chain.push(value)
                                    postExecute()
                                    setValue(null)
                                }
                            })
                            .catch(function (response) {
                                console.log('edit tag error ---> ', response);
                            });
                    }
                    break
                case 'delete':
                    if ((object.product && object.product.tags.includes(value))
                        || (object.relation && object.relation.chain.includes(value))) {
                        axios({
                            method: 'patch',
                            url: url,
                            config: {headers: {'Media-Type': 'application/json'}}
                        })
                            .then(function (response) {
                                if (response.status === 200) {
                                    object.product ?
                                        _.remove(object.product.tags, function (o) {
                                            return o === value
                                        })
                                        : _.remove(object.relation.chain, function (o) {
                                            return o === value
                                        })
                                }
                                postExecute()
                                setValue(null)
                            }).catch(function (response) {
                            console.log('delete tag error ---> ', response);
                        });
                    }
                    break
                default:
                    console.log("unknown action case")
            }
        }
    };

    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                open={open}
                scroll={'paper'}
                aria-labelledby="simple-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle
                    id="simple-dialog-title">{props.editable ? t('actions.edit', {what: 'tags'}) : t('actions.view', {what: 'tags'})} - {object.product ? object.product.name : object.relation.name}</DialogTitle>
                <DialogContent dividers={true}>
                    <List>
                        {objectTags.map((tag) => (
                            <ListItem/* button onClick={() => handleListItemClick(tag)}*/ key={tag}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <LocalOfferIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={tag}/>
                                {
                                    props.editable ?
                                        <IconButton
                                            disabled={_.lowerCase(tag) === 'other' || !props.editable}
                                            className={classes.delIcon}
                                            size={'medium'}
                                            onClick={() => handleEditTagClick('delete', tag)}>
                                            <DeleteOutlineIcon/>
                                        </IconButton> : null
                                }
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                {
                    props.editable ?
                        <DialogActions>
                            <Box p={2} style={{display: "contents"}}>
                                <FreeSoloAutocomplete
                                    classes={classes}
                                    value={value}
                                    //disabled={!props.editable}
                                    fullWidth={false}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setValue({
                                                tag: newValue.trim(),
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setValue({
                                                tag: newValue.inputValue.trim(),
                                            });
                                            newValue && handleEditTagClick('add', newValue?.inputValue)

                                        } else {
                                            setValue(newValue);
                                            newValue && handleEditTagClick('add', newValue?.tag)
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                tag: `${t('actions.add', {what: "\""+params.inputValue+"\""})}`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    options={tags}
                                    optionLabel={(option) => {
                                        // e.g value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.tag;
                                    }}
                                    renderOption={(option) => option.tag} renderInput={(params) => (
                                    <TextField {...params} size={'small'} label={t('actions.add', {what: 'Tag'})} variant="outlined"/>
                                )
                                }/>
                                <IconButton
                                    className={classes.addIcon}
                                    size={"small"}
                                    onClick={() => handleEditTagClick('add', value.tag)}
                                    disabled={!value}>
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                        </DialogActions>
                        : null
                }
            </Dialog>
        </React.Fragment>
    )
}

export default EditTag
