import React, {useContext, useMemo, useState} from 'react';
import MaterialTable from 'material-table'
import {useWindowSize} from "../../utils/Utils"
import {findCodeTypeAndValue} from "../../utils/CodesUtils"
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormGroup,
    ListItem,
    Paper,
    ThemeProvider
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {RoutingContext, ThemeContext} from "../../contexts";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {langResources} from "../../i18n/i18next";
import {useTranslation} from "react-i18next";
import _ from 'lodash'
import {
    CheckBox,
    CheckBoxOutlineBlank,
    EmailOutlined,
    GroupAdd,
    Input,
    PermContactCalendar,
    PermContactCalendarOutlined,
    PersonAdd,
} from "@material-ui/icons";
import List from "@material-ui/core/List";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import useAxios from "axios-hooks";
import ErrorMessage from "../../pages/companies/ErrorMessage";
import Chip from "@material-ui/core/Chip";
import chroma from 'chroma-js';
import Box from "@material-ui/core/Box";

const MaterialTableDemo = ({_myPartners, companies, pendingRequests, requestsToAccept, activePartners, refetch, limit, setLimit, skip, setSkip}) => {
    const {t} = useTranslation()
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: accentColor,
            },
        },
    });

    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: '100%',
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        avatar: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        button: {
            backgroundColor: accentColor,
            color: 'white',
            borderRadius: '25px',
            width: '25%',
            fontWeight: 'bold',
            boxShadow: theme.shadows[2]
        },
    }))
    const classes = useStyles()
    const size = useWindowSize();
    const myContext = useContext(RoutingContext);

    const [state, setState] = useState({
        openDialog: false,
        data: null,
        selectedBrands: [],
        requestStatus: null,
        error: false
    })
    const [filterTable, setFilterTable] = useState({multipleSelection: false, myPartners: _myPartners})

    const [{data: data, loading: requestLoading, error: requestError}, postRequest] = useAxios(
        {
            url: state.requestStatus === "to_accept" ? "/api/partnership_requests/accept/token" : "/api/partnership_requests",
            method: "POST",
            headers: {
                'Media-Type': 'application/json',
            }
        },
        {manual: true}
    )

    const CustomNameField = ({src, name}) => {
        const _src = "/assets/company.png";

        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar
                        className={classes.avatar}
                        src={src ? "api/uploads/uploads/" + src : _src}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography variant={'body2'}>{name}</Typography>}
                />
            </ListItem>
        )
    };

    const CustomCodeField = ({company_prefix}) => {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        {findCodeTypeAndValue(company_prefix)[1]}
                    </Grid>
                </Grid>
            </div>
        )
    }

    const getActionIcon = (rowData) => {
        if(!filterTable.multipleSelection)
            return rowData.requestStatus === "to_accept" ?
                <Chip label={t("otherCompanies.incomingRequest")}
                      variant={'outlined'} style={{cursor: 'default'}}
                      icon={<Input style={{color:accentColor}}/>}/>
                : rowData.requestStatus === "partner" ?
                    <Chip label={t("otherCompanies.partner")}
                          variant={'outlined'} style={{cursor: 'default'}}
                          icon={<PermContactCalendar style={{color:primaryColor}}/>}/>
                    : rowData.requestStatus === "pending" ?
                        <Chip label={t('otherCompanies.requestSent')}
                              variant={'outlined'} style={{cursor: 'default'}}
                              icon={<EmailOutlined fontSize={'small'} color={'inherit'}/>}/>
                        : <Chip label={t('otherCompanies.sendPartnershipRequest')}
                                variant={'outlined'} style={{cursor: 'pointer'}}
                                icon={<PersonAdd fontSize={'small'} style={{color: accentColor}}/>}/>
        else return <GroupAdd fontSize={'large'} style={{color: accentColor}}/>
    }

    let columns = [
        {
            title: _.startCase(t('entities.name')),
            field: 'name',
            render: rowData => <CustomNameField src={rowData.image} name={rowData.name}/>
        },
        {title: t('entities.company.domain'), field: 'domain'},
        {
            title: t('entities.company.prefix'),
            field: 'company_prefix',
            render: rowData => <CustomCodeField company_prefix={rowData.company_prefix}/>
        },
    ]

    if (size.width > 600) {
        columns = [
            {
                title: _.startCase(t('entities.name')),
                field: 'name',
                render: rowData => <CustomNameField src={rowData.image} name={rowData.name}/>
            },
            {title: t('entities.company.domain'), field: 'domain'},
            {
                title: t('entities.company.prefix'),
                field: 'company_prefix',
                render: rowData => <CustomCodeField company_prefix={rowData.company_prefix}/>
            },
            {title: t('entities.company.fiscalCode'), field: 'fiscal_code'},
        ]
    }
    if (size.width > 700) {
        columns = [
            {
                title: _.startCase(t('entities.name')),
                field: 'name',
                render: rowData => <CustomNameField src={rowData.image} name={rowData.name}/>
            },
            {title: t('entities.company.domain'), field: 'domain'},
            {
                title: t('entities.company.prefix'),
                field: 'company_prefix',
                render: rowData => <CustomCodeField company_prefix={rowData.company_prefix}/>
            },
            {title: t('entities.company.fiscalCode'), field: 'fiscal_code'},
            {title: t('entities.description'), field: 'description'},
        ]
    }

    const onRowClick = (event, rowData) => {
        !isChild(rowData) && myContext.setRoute('CompanyDetail', rowData);
    }

    /** Per paginare i dati dal server */
    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const localizedTable = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable

    const handleCloseDialog = () => {
        setState({...state, openDialog: false, data: null, error: false});
    }

    const handleRequest = () => {
        if(!state.requestStatus) {
            const data = _.map(state.selectedBrands, (brand) => (brand.domain))
            postRequest({
                data: data
            }).then((res) => {
                setState({...state, openDialog: false, data: null, selectedBrands: [], error: false});
                refetch()
            }).catch((err) => {
                setState({...state, error: err});
            })
        } else if(state.requestStatus === "to_accept") {
            const request = _.find(requestsToAccept, ["brand_domain", state.selectedBrands[0].domain])||""
            postRequest({data: `${JSON.stringify(request.token)}`})
                .then((res) => {
                    setState({...state, openDialog: false, data: null, selectedBrands: [], error: false});
                    refetch()})
                .catch((err) => { setState({...state, error: err}); })
        }
    }

    const filteredData = useMemo(() => {
        return filterTable.multipleSelection ?
            _.filter(companies.data, (brand) => {
                return !(pendingRequests.includes(brand.domain))
                    && !(activePartners.includes(brand.domain))
                    && !(_.find(requestsToAccept, ["brand_domain", brand.domain]))
            })
            : filterTable.myPartners ?
                _.filter(companies.data, (brand) => {
                    return (pendingRequests.includes(brand.domain))
                        || (activePartners.includes(brand.domain))
                        || (_.find(requestsToAccept, ["brand_domain", brand.domain]))
                }) : companies.data
    }, [filterTable.multipleSelection, companies, filterTable.myPartners])

    function isChild(rowData) {
        return !!rowData.parentId;
    }

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                {!_myPartners && <Box display={'flex'}>
                    <Button startIcon={filterTable.multipleSelection ? <CheckBox/> : <CheckBoxOutlineBlank/>}
                            onClick={() => setFilterTable({multipleSelection: !filterTable.multipleSelection, myPartners: filterTable.myPartners ? !filterTable.myPartners : filterTable.myPartners})}>{t("otherCompanies.multipleSelection")}</Button>
                    <Button startIcon={filterTable.myPartners ? <PermContactCalendar/> : <PermContactCalendarOutlined/>}
                            onClick={() => setFilterTable({multipleSelection: filterTable.multipleSelection ? !filterTable.multipleSelection : filterTable.multipleSelection, myPartners: !filterTable.myPartners})}>{t("otherCompanies.onlyMyPartners")}</Button>
                </Box>}
                <MaterialTable
                    columns={columns}
                    totalCount={companies.count}
                    /** filtro la mia azienda */
                    data={filteredData}
                    parentChildData={(row, rows) => rows.find(a => {return a.id === row.parentId})}
                    localization={localizedTable}
                    onRowClick={onRowClick}
                    options={{
                        search: true,
                        sorting: true,
                        showTitle: false,
                        paging: true,
                        defaultExpanded: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                        padding: 'dense',
                        rowStyle: rowData => {
                            if(isChild(rowData))
                                return {backgroundColor: chroma("lightgray").alpha(0.2).hex()}
                        },

                        showSelectAllCheckbox: filterTable.multipleSelection,
                        showTextRowsSelected: filterTable.multipleSelection,
                        selection: filterTable.multipleSelection,
                        /*selectionProps: rowData => {
                            const selProps = {disabled: rowData.pendingRequest || rowData.activeRequest,}
                            if(rowData.pendingRequest || rowData.activeRequest)
                                return ({...selProps, checked: false})
                            else return selProps
                        }*/
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>,
                    }}
                    actions={[
                        rowData => ({
                            icon: () => getActionIcon(rowData),
                            disabled: !filterTable.multipleSelection && (rowData.requestStatus === "pending" || rowData.requestStatus === "partner"),
                            position: 'row',
                            onClick: (event, rowData) => {
                                let data, requestStatus = null, selectedBrands = []
                                if(filterTable.multipleSelection) {
                                    data = _.map(rowData, (row) => ({name: row?.name, domain: row?.domain}))
                                    selectedBrands = data
                                } else {
                                    data = {name: rowData?.name, domain: rowData?.domain}
                                    selectedBrands = [data]
                                    requestStatus = rowData?.requestStatus
                                }
                                setState({...state, openDialog: true, requestStatus: requestStatus, data: data, selectedBrands: selectedBrands})
                            }})
                    ]}
                    //onChangePage={handleChangePage}
                    //onRowsPerPageChange={handleChangeRowsPerPage}
                    //page={skip}
                />
                <Dialog fullWidth open={state.openDialog} onClose={handleCloseDialog} scroll={"paper"}>
                    <div>
                        <ErrorMessage requestError={state.error} requestLoading={requestLoading}/>
                        {filterTable.multipleSelection && state.data ?
                            <>
                                <DialogTitle style={{position: "sticky", top: 0, background: 'white', zIndex: 100}}>
                                    {t('otherCompanies.chooseDomains')}
                                </DialogTitle>
                                <DialogContent dividers={true}>
                                    <FormControl fullWidth>
                                        <FormGroup>
                                            <List disablePadding>
                                                {state.data.map((brand) => {
                                                    return <div key={brand.domain} style={{width: '100%'}}>
                                                        <ListItem>
                                                            <Checkbox color={'default'}
                                                                      name="brand"
                                                                      checked={state.selectedBrands?.includes(brand)}
                                                                      onClick={(ev, checked) => {
                                                                          const res = [...state.selectedBrands]
                                                                          const currentIndex = res?.indexOf(brand);
                                                                          if (res?.includes(brand))
                                                                              res?.splice(currentIndex, 1);
                                                                          else res?.push(brand)

                                                                          setState({...state, selectedBrands: res})
                                                                      }}/>
                                                            <ListItemText primary={brand.name} secondary={brand.domain}/>
                                                        </ListItem>
                                                        <Divider/>
                                                    </div>
                                                })}
                                            </List>

                                        </FormGroup>
                                    </FormControl>
                                </DialogContent>
                            </>
                            : !filterTable.multipleSelection && !state.requestStatus ? <DialogTitle>{t('otherCompanies.doYouReallyWantToSendRequestTo')}</DialogTitle>
                                : !filterTable.multipleSelection && state.requestStatus === "to_accept" ? <DialogTitle>{t('otherCompanies.doYouWantToAcceptTheRequest')}</DialogTitle>
                                    : ""
                        }
                        <DialogActions style={{position: "sticky", bottom: 0, background: 'white', zIindex: 1000}}>
                            <Button onClick={handleCloseDialog} color="default">
                                {t('actions.cancel')}
                            </Button>
                            <Button disabled={!state.selectedBrands || state.selectedBrands.length === 0} className={classes.button}
                                    onClick={handleRequest} color="default" autoFocus type={'submit'}>
                                {t('actions.ok')}
                            </Button>
                        </DialogActions>
                    </div>

                </Dialog>
            </ThemeProvider>
        </div>
    );
}

export default MaterialTableDemo
