import React, {useContext, useMemo, useState, useEffect} from "react";
import useAxios from "axios-hooks";
import {
    Backdrop, Box, Tabs, Tab, FormControlLabel,
    Table, TableContainer, TableHead, TableRow, TableBody, TableCell,
    Chip, TextField, IconButton, Checkbox,
    Collapse, Paper, Grid, Snackbar, Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {AuthContext, ThemeContext} from "../../contexts";
import i18next from "../../i18n/i18next";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FilterListIcon from '@material-ui/icons/FilterList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Autocomplete, Alert, AlertTitle} from "@material-ui/lab";
import TableReports from "./TableReports";
import _ from 'lodash'
import WhenRuleComponent from "../create_new_relationship/rules_components/WhenRuleComponent";
import Moment from "moment";
import CustomLoader from "../../components/CustomLoader";
import ContainerDimensions from "react-container-dimensions";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveRadar } from '@nivo/radar';


const ingredienti = [
    {
        index: 0,
        label: "Grano",
        field: "GRANO",
        color: "hsl(210, 70%, 50%)"
    },
    {
        index: 1,
        label: "Mais",
        field: "MAIS",
        color: "hsl(244, 70%, 50%)"
    },
    {
        index: 2,
        label: "Soya 48%",
        field: "SOYA 48%",
        color: "hsl(34, 70%, 50%)"
    },
    {
        index: 3,
        label: "Soya Seme Integr",
        field: "SOYA SEME INTEGR",
        color: "hsl(131, 70%, 50%)"
    },
    {
        index: 4,
        label: "Olio di Soia",
        field: "OLIO DI SOIA",
        color: "hsl(95, 70%, 50%)"
    },
    {
        index: 5,
        label: "Altro",
        color: "hsl(60, 70%, 50%)"
    }
]

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const FeedChart =  () => {
    const { activeMainDomain } = useContext(AuthContext)
    const { accentColorAlpha, accentColor } = useContext(ThemeContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
            '& ..MuiAutocomplete-inputRoot': {
                maxHeigth: '30px'
            }
        },
        chip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            fontWeight: 'bold',
            marginLeft: '2%',
            borderRadius: '20px',
        },
        paper: {
            paddingTop: '1%',
            paddingLeft:'2%',
            paddingBottom: '5%',
            paddingRight: '2%',
            fontSize: 'small'
        },
        graph_tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            fontWeight: 'thin',
            borderRadius: '5px',
            border: '1px solid '            
        },
        graph_tooltip_inner: {
            marginLeft: '5px'
        },
    }));

    const classes = useStyles()

    const { t } = useTranslation();
    const responseType = "blob"
    const mimeType = "application/json"

    const [{ data: pezzatura, loadingPezzatura, errorReports },] = useAxios({
        url: `/api/dataservices/records/_keys/?key_type=group_key&domain=${activeMainDomain}`,
        useCache: false
    },
    )

    const [filter, setFilter] = useState({
        pezz: { _id: "group_key:POLLO GROSSO" },
        selectedDates: [{
            startDate: Moment().subtract(6, 'months').toDate(),
            endDate: Moment().toDate(),
            color: 'darkgrey',
            key: 'selection'
        }
        ],
        selected_cicli: []
    })

    const [dataMap, setDataMap] = useState(new Map())
    const [graphData, setGraphData] = useState([])
    const [cicli, setCicli] = useState([])
    const [showPerc, setShowPerc] = useState(false)
    const [measureStats, setMeasureStats] = useState({})
    const [{tableData, ciclo}, setAdditionalData] = useState({tableData:[], ciclo:''})
    const [{radarData, selectedLot}, setRadarData] = useState({radarData:[], selectedLot: ''})
    const [{copyDone, copyMessage}, setCopy] = useState({copyDone: false, copyMessage: ''})

    const [{ data, loading, error, response }, reloadData] = useAxios({
        url: `/api/dataservices/chemio_reports_data/`,
        params: {
            pezzatura: filter.pezz._id.replace("group_key:", ""),
            type_of_report: 'Analisi_mangime',
            start: Moment(filter.selectedDates[0].startDate).format('YYYY-MM-DD HH:mm:ss'),
            end: Moment(filter.selectedDates[0].endDate).format('YYYY-MM-DD HH:mm:ss'),
            domain: activeMainDomain
        },
    })

    useEffect(()=>{
        if (data?.records)
        {
            let dataMap_tmp = new Map()
            data.records.forEach((record) => {
                if ((!dataMap_tmp.get(record['Chiave Ciclo']))) //&& dataMap.size < maxAllev) 
                {
                    dataMap_tmp.set(record['Chiave Ciclo'], { records: [] })
                }
                dataMap_tmp.get(record['Chiave Ciclo']).records.push(record)
                
            })            
            const all_tmp = dataMap_tmp.keys().toArray()
            setDataMap(dataMap_tmp)
            setFilter(prev => ({ ...prev, selected_cicli: all_tmp.slice(0) }))
            setCicli(all_tmp)            
        }
    }, [data])

    useEffect(() => { reloadData() }, [filter.pezz, filter.selectedDates])

    useEffect(() => {
        const new_graph_data = [];
        const measure_stats = new Map();
        dataMap.keys().forEach(ciclo => {
            if (filter.selected_cicli.find( v => v === ciclo)) {
                const ciclo_data = { "ciclo": ciclo, "qta": 0 }
                dataMap.get(ciclo).records.forEach((r, i) => {
                    let qta_residua = Number.parseFloat(r["Quantita Movimento"])
                    ciclo_data["qta"] = ciclo_data["qta"] + qta_residua
                    ingredienti.forEach(ingrediente => {                        
                        if (!ciclo_data[ingrediente.label])
                        {
                            ciclo_data[ingrediente.label]=0
                            ciclo_data[ingrediente.label+"Color"] = ingrediente.color
                            ciclo_data["% "+ingrediente.label+"Color"] = ingrediente.color
                        }
                        if (ingrediente.field){
                            const qta_ingr = r[ingrediente.field]*r["Quantita Movimento"] /100
                            ciclo_data[ingrediente.label] = ciclo_data[ingrediente.label] + qta_ingr
                            qta_residua = qta_residua - qta_ingr
                        }
                    })
                    ciclo_data["Altro"] = ciclo_data["Altro"]+ qta_residua                
                })
                //Calcolo percentuali
                ingredienti.forEach(ingrediente =>{
                    ciclo_data["% "+ingrediente.label] = ciclo_data[ingrediente.label] * 100 / ciclo_data["qta"]
                })
                new_graph_data.push(ciclo_data)

            }
        })        
        setGraphData(new_graph_data)
        setAdditionalData({tableData: [], ciclo:''})
        setRadarData({radarData:[], selectedLot:''})
    }, [dataMap, filter.selected_cicli])
    
    function handleBoxClick(event){
        const selectedCiclo = event.indexValue
        let listaMangimi = [...dataMap.get(selectedCiclo).records]
        listaMangimi.sort((a,b)=>( Moment(a["Data Movimento Allev."]) > Moment(b["Data Movimento Allev."])? 1: -1))

        const tableDataTmp = new Map()
        listaMangimi.forEach(mangime => {
            if (!tableDataTmp.get(mangime["klass_id"])){
                tableDataTmp.set(mangime["klass_id"], 
                    {
                        codice_parte: mangime["Codice Parte FILENI"], 
                        fornitore: mangime["Rag Soc Fornitore"],
                        descrizione: mangime["Descrizione Parte"],
                        open: false,
                        lotti: []
                    }
                )    
            }
            const lotto_obj={lotto: mangime["LottoSped"], qta: Number.parseFloat(mangime["Quantita Movimento"]), data_mov: mangime["Data Movimento Allev."], Altro: Number.parseFloat(mangime["Quantita Movimento"]), epc: mangime["epc"]}
            ingredienti.forEach((ingrediente)=>{
                if (ingrediente.field)
                {
                    lotto_obj[ingrediente.label] = Number.parseFloat(mangime[ingrediente.field])
                    lotto_obj["Altro"] = lotto_obj["Altro"] - (lotto_obj.qta *mangime[ingrediente.field] /100)
                }
            })
            lotto_obj["Altro"] = lotto_obj["Altro"] * 100 / lotto_obj.qta
            tableDataTmp.get(mangime["klass_id"]).lotti.push(lotto_obj)

        })
        setAdditionalData({tableData: tableDataTmp.values().toArray(), ciclo: selectedCiclo})   
        setRadarData({radarData:[], selectedLot: ''})     
    }

    function setOpen(index){
        tableData[index].open= !tableData[index].open
        setAdditionalData(prev => ({...prev, tableData: tableData}))
    }

    function MangimiRow({row, index}){
        //const [open, setOpen] = useState(false)        
        return (
            <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(index)}>
                        {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.codice_parte}</TableCell>
                <TableCell>{row.descrizione}</TableCell>
                <TableCell>{row.fornitore}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={row.open} timeout="auto" unmountOnExit>
                        <Box margin={1}>                            
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell><Typography>{t('feedChart.table.columns.lot', 'Lot')}</Typography></TableCell>
                                        <TableCell><Typography>{t('feedChart.table.columns.date', 'Movement Date')}</Typography></TableCell>
                                        <TableCell align="right"><Typography>{t('feedChart.table.columns.quantity', 'Quantity')}</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.lotti.map((lotto,j) => (
                                        <TableRow key={index+"_lotto_"+j} onClick={(e)=>handleClickLotto(e, lotto)} style={{ cursor: 'pointer'}}>
                                            <TableCell component="th" scope="row"  onClick={()=>copyToClipboard(lotto.epc)} title={t('feedChart.table.copyTooltip', 'Copy feed lot code')}><FileCopyIcon/></TableCell>
                                            <TableCell >{lotto.lotto}</TableCell>
                                            <TableCell>{i18next.t("formatters:date", {date: Moment(lotto.data_mov)})}</TableCell>
                                            <TableCell align="right">{lotto.qta}</TableCell>
                                        </TableRow>                                        
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            </>
        )

    }

    function handleClickLotto(e, lotto){
        e.preventDefault();
        const radarDataTmp=ingredienti.map( ingrediente => ({ "Ingrediente": ingrediente.label, "Percentuale": lotto[ingrediente.label] }))
        setRadarData({radarData:radarDataTmp, selectedLot: lotto.lotto})
    }

    function copyToClipboard(epc){
        navigator.clipboard.writeText(epc).then(()=>setCopy({copyDone: true, copyMessage: t('feedChart.messages.copied', {epc: epc})}))
    }

    return (
        <div className={classes.root} id="feed_charts_page" style={{ height: "100%" }}>
            <ContainerDimensions>
                {({ width, height }) =>
                    <div style={{ height: height * 0.9, width: width}}>
                        <Box mb="5em" display={'flex'} alignItems={'end'}>
                                <FilterListIcon style={{ color: accentColor, marginRight: 16 }} />
                                <Autocomplete
                                    style={{ width: '30%', marginRight: 16 }}
                                    limitTags={3}
                                    id="pezzaturaAC"
                                    options={_.uniqBy(pezzatura, '_id')}
                                    getOptionLabel={(option) => option._id.replace('group_key:', '')}
                                    getOptionSelected={(option, value) => option._id == value._id}
                                    value={filter.pezz}
                                    //defaultValue={{"name": "Pollo Piccolo", "id":"pollo_piccolo"}}
                                    onChange={(event, newSelection) => {
                                        setFilter(prev => ({ ...prev, pezz: newSelection }));

                                    }}
                                    renderInput={params => {
                                        const { InputProps, ...restParams } = params;
                                        const { startAdornment, ...restInputProps } = InputProps;
                                        return (
                                            <TextField
                                                {...restParams}
                                                size={'small'}
                                                variant={"standard"}
                                                placeholder={t('actions.filterBy', { by: 'pezzatura' })}
                                                //defaultValue="Pollo Piccolo"
                                                InputProps={{
                                                    ...restInputProps,
                                                    startAdornment: (
                                                        <div style={{
                                                            maxHeight: '100px',
                                                            overflowY: 'auto',
                                                        }}
                                                        >
                                                            {startAdornment}
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} size='small' {...getTagProps({ index })} />
                                        ))
                                    }
                                />
                                <WhenRuleComponent state={filter} setState={setFilter} width={'30%'} outlined={false} />
                                <Autocomplete
                                    style={{ width: '30%', marginRight: 16, marginLeft: 16, maxHeight:'30px' }}
                                    multiple={true}
                                    limitTags={3}
                                    id="cicliAC"
                                    options={cicli}
                                    filterSelectedOptions={false}
                                    disableCloseOnSelect
                                    //getOptionLabel={(option) => option.name}
                                    //getOptionSelected={(option, value) => value.id == option.id }
                                    value={filter.selected_cicli}
                                    onChange={(event, newSelection) => {
                                        setFilter(prev => ({ ...prev, selected_cicli: newSelection }));

                                    }}
                                    renderInput={params => {
                                        const { InputProps, ...restParams } = params;
                                        const { startAdornment, ...restInputProps } = InputProps;
                                        return (
                                            <TextField
                                                {...restParams}
                                                size={'small'}
                                                variant={"standard"}
                                                placeholder={t('actions.filterBy', { by: 'Ciclo' })}
                                                InputProps={{
                                                    ...restInputProps,
                                                    startAdornment: (
                                                        <div style={{
                                                            maxHeight: '100px',
                                                            overflowY: 'auto',
                                                        }}
                                                        >
                                                            {startAdornment}
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option} size='small' {...getTagProps({ index })} />
                                        ))
                                    }
                                />
                        </Box>
                        {loading &&
                            <Backdrop style={{ zIndex: 1000 }} open={loading}><CustomLoader size={80} /></Backdrop>}
                        {!loading && (error || graphData.length == 0 )&&
                            <div style={{display: 'flex', padding: '2em', alignItems: 'center', justifyContent: 'center', fontStyle: 'italic', fontSize: 'medium'}}>
                                <div><Typography>{t("feedChart.messages.noData", "No data for active filters")}</Typography></div>
                            </div>
                        }
                        {!loading && !error && graphData.length > 0 &&
                            <div>                                
                                {/*<SummaryPanel summaryData={measureStats.get(tabConf.index)} />*/}
                                <FormControlLabel control={<Checkbox checked={showPerc} onChange={(e)=>{setShowPerc(e.target.checked)}} name="show_perc" id="show_perc" color="primary"/>}
                                    label={t("feedChart.showPercentage", "Show percentage")} 
                                />
                                
                                <Paper className={classes.paper} style={{ height: '70vh', margin: '1em', padding: '1em' }}>
                                <ResponsiveBar
                                    onClick={handleBoxClick}
                                    data={graphData}
                                    keys={ingredienti.map(ingr=>showPerc?"% "+ingr.label:ingr.label)}
                                    indexBy="ciclo"
                                    margin={{ top: 50, right: 130, bottom: 200, left: 60 }}
                                    padding={0.3}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={{ scheme: 'nivo' }}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: '#38bcb2',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: '#eed312',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: ingredienti[0].label
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: "% "+ingredienti[0].label
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: ingredienti[1].label
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: "% "+ingredienti[1].label
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 90,
                                        legend: 'Ciclo',
                                        legendPosition: 'middle',
                                        legendOffset: 190,
                                        truncateTickAt: 0
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: showPerc?'%':'Kg',
                                        legendPosition: 'middle',
                                        legendOffset: -50,
                                        truncateTickAt: 0
                                    }}
                                    enableLabel={false}
                                    enableTotals={true}
                                    
                                    totalsOffset={0}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'bottom-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}                                    
                                />
                                </Paper>
                                <Grid container spacing={2}> 
                                    <Grid item xs={8}>                                   
                                    {!ciclo && <Paper className={classes.paper} style={{minHeight: '50vh', margin: '1em', padding: '1em'}}><h5><Typography>{t('feedChart.messages.selectBar', 'Select an item from bar chart')}</Typography></h5></Paper>}
                                    {ciclo &&                                     
                                    <TableContainer component={Paper} style={{minHeight: '50vh', margin: '1em', padding: '1em'}}>
                                        <h5><Typography>{t("feedChart.table.title", {cycle: ciclo})}</Typography></h5>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell/>
                                                    <TableCell style={{font: 'bold'}}><Typography>{t('feedChart.table.columns.partCode','Part Code')}</Typography></TableCell>
                                                    <TableCell style={{font: 'bold'}}><Typography>{t('feedChart.table.columns.description','Description')}</Typography></TableCell>
                                                    <TableCell style={{font: 'bold'}}><Typography>{t('feedChart.table.columns.supplier', 'Supplier')}</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData.map((mangime, i)=>(
                                                    <MangimiRow key={'row_'+mangime.codice_parte+'_'+mangime.fornitore} row={mangime} index={i}/>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    }
                                    </Grid>
                                    <Grid item xs={4}>                                    
                                    {radarData?.length == 0 && <Paper className={classes.paper} style={{ height: '50vh', margin: '1em', padding: '1em' }}><h5><Typography>{t('feedChart.messages.selectLot','Select a lot from table')}</Typography></h5></Paper>}
                                    {radarData?.length >0 &&
                                    <Paper className={classes.paper} style={{ height: '50vh', margin: '1em', padding: '1em' }}>
                                        <h5><Typography>{t('feedChart.radarTitle', {lot: selectedLot})}</Typography></h5>
                                        <ResponsiveRadar
                                            data={radarData}
                                            keys={[ 'Percentuale' ]}
                                            indexBy="Ingrediente"
                                            valueFormat=">-.2f"
                                            maxValue={'auto'}
                                            margin={{ top: 30, right: 80, bottom: 80, left: 80 }}
                                            borderColor={{ from: 'color' }}
                                            gridLabelOffset={15}
                                            dotSize={10}
                                            dotColor={{ theme: 'background' }}
                                            dotBorderWidth={2}
                                            colors={{"scheme":"nivo"}}
                                            blendMode="multiply"
                                            motionConfig="wobbly"                                            
                                            
                                        />
                                    </Paper>
                                    }
                                    </Grid>                                
                                </Grid>
                            </div>
                        }

                    </div>
                }
            </ContainerDimensions>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={copyDone}
                autoHideDuration={3000}
                onClose={()=> {setCopy({copyDone:false, copyMessage:''})}}
                message={copyMessage}                
            />
        </div>
    )
}

export default FeedChart