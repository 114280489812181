import React, {useContext} from "react";
import {AuthContext, CreateEventContext, ThemeContext} from "../../../contexts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createWhiteListedGtins, findCodeTypeAndValue} from "../../../utils/CodesUtils";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import {useTranslation} from "react-i18next";

const StrictedWhitelist = ({field, state, setState, classes}) => {
    const {t} = useTranslation()
    const {primaryColorAlpha} = useContext(ThemeContext)
    const eventContext = useContext(CreateEventContext)
    const authContext = useContext(AuthContext)
    const whitelist = createWhiteListedGtins(eventContext.products.data, field.whitelist)
    const options = _.orderBy(whitelist.map((product) => ({
        name: product.name,
        key: product.key,
        mine: product.producer === authContext.activeMainDomain,
        brandName: product.brandName ? product.brandName : authContext.activeBrandName,
        companyPrefix: product.companyPrefix ? product.companyPrefix : null
    })), ['mine', 'brandName'], ['desc', 'asc'])
    return (
        <Autocomplete
            fullWidth
            size={'small'}
            //multiple
            //value={state.productClass}
            disabled={!!state.parentID}
            filterSelectedOptions
            groupBy={(option) => (_.includes(option.key, 'x.'+authContext.activeMainDomain) ? "Others from " : '')
                + (option.brandName ? option.brandName : t('entities.product.myProducts', "My Products"))}
            options={options}
            getOptionLabel={(option) => option.name +' - '+ (findCodeTypeAndValue(option.key)[2]?
                findCodeTypeAndValue(option.key)[2] + ': ' : '') + findCodeTypeAndValue(option.key)[1]}
            onChange={(event, newValue) => {
                if(newValue) {
                    setState({
                        ...state, productClass: {
                            key: newValue.key,
                            name: newValue.name,
                            companyPrefix: newValue.companyPrefix,
                            classType: findCodeTypeAndValue(newValue.key)[0]
                        }
                    });
                }
                else {
                    setState({...state, productClass: null});
                }
            }}
            renderInput={(params) =>
                <TextField {...params}
                           size={'small'}
                           label={t('createEvent.enterProductClassCode', "Enter the product class code")}
                           variant="outlined"
                           error={!state.productClass}
                />}
            renderOption={(option, {selected}) => {
                const [codeType, codeValue, domain] = option ? findCodeTypeAndValue(option.key) : [null, null, null]
                return <React.Fragment>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={classes.name}>{option.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {
                                    codeType ?
                                        <Chip
                                            size={'small'}
                                            label={codeType.type}
                                            className={classes.labelChip}
                                        /> : null
                                }
                                {
                                    domain ?
                                        <Chip
                                            size={'small'}
                                            label={domain}
                                            className={classes.labelChip}
                                        /> : null
                                }
                                <Typography className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.name ? option.name : option.address}
                        style={{backgroundColor: primaryColorAlpha, opacity: '0.8'}}
                        {...getTagProps({index})}
                    />
                ))
            }
        />
    )
}

export default StrictedWhitelist