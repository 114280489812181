import React, {useContext, useMemo} from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {AuthContext, CreateEventContext, ThemeContext} from "../../../contexts";
import useAxios from "axios-hooks";
import _ from "lodash";
import CustomLoader from "../../../components/CustomLoader";
import CreateEventTemplateForm from "./CreateEventTemplateForm";
import {useTranslation} from "react-i18next";
import {useDomains} from "../../../utils/Utils";

const CreateEventTemplate = (props) => {
    //console.log("CreateEventTemplate ---> PROPS:",props)
    const {t} = useTranslation();

    const {isAdmin, activeMainDomain, activeDomains, companyPrefix} = useContext(AuthContext)
    const {primaryColor, primaryColorAlpha, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            "& .MuiStepIcon-root.MuiStepIcon-active": {
                color: primaryColor,
            },
            "& .MuiStepIcon-root.MuiStepIcon-completed": {
                color: primaryColorAlpha,
            }
        }
    }));
    const classes = useStyles()

    const domains = useDomains(isAdmin, activeDomains)

    const [{data: allProducts, loading: loadingProducts, error: errorProducts},] = useAxios({
            url: "/api/items/?domain=" + activeMainDomain + "&not_producer=true&producer=true"
        },
        {useCache: false}
    )

    const [{data: cbvValues, loading: loadinCbvValues, error: errorCbvValues},] = useAxios({
            url: "/api/cbv_values/"
        },
        {useCache: false}
    )

    const [{data: eventsModelNames},] = useAxios({
            url: !!props.fromRulesConf ?
                ("/api/capture/event_templates/_by_domain/?synthesis=true&domain=" + props.domain)
                : "/api/capture/event_templates/latest_versions/names/"
        },
        {useCache: false}
    )

    const context = useMemo(() => {
        if (!domains.data || !cbvValues || !eventsModelNames || !allProducts)
            return

        return {
            products: allProducts.data,

            /** domini attivi dello user (enriched con tutti i dati) */
            activeDomains: domains.data,
            chosenDomain: _.find(domains.data, ['domain', props.domain||activeMainDomain]) || domains.data[0],

            cbvValues: cbvValues,
            companyPrefix: companyPrefix,
            modelsName: !!props?.fromRulesConf ? _.map(eventsModelNames, (model) => (model.name)) : eventsModelNames,
            editEvent: props?.editEventModel || props?.location?.state || null,
            editMode: props?.editMode || props?.location?.state?.editMode || false,
            primaryColor: primaryColor,
            ilmd: [],

            /** SOLO per il dialog nel Configuratore delle Regole (Production Flow Configurator) */
            fromRulesConf: props?.fromRulesConf || props?.location?.state?.fromRulesConf || false,
            is_draft: props?.is_draft || props?.location?.state?.is_draft || false,
            domain: props?.domain || props?.location?.state?.domain || '',
            /** per fill in di un draft model */
            _id: props?._id || props?.location?.state?._id || '',
            modelName: props?.name || props?.location?.state?.name || '',
            description: props?.description || props?.location?.state?.description || '',
        }

    }, [domains.data, cbvValues, eventsModelNames, allProducts])

    function onSubmit(data) {
        //console.log("data to send: ", data)
    }

    return (
        <Paper className={classes.root}>
            {
                context ?
                    <CreateEventContext.Provider value={context}>
                        <CreateEventTemplateForm onSubmit={onSubmit}/>
                    </CreateEventContext.Provider> :
                    (loadinCbvValues || loadingProducts) ? <CustomLoader size={80} text={t('actions.loading')}/> :
                        (errorCbvValues || errorProducts) ? "Server Error" : null
            }
        </Paper>
    )
}

export default CreateEventTemplate
