import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import Box from "@material-ui/core/Box";
import {ProductHistoryContext, ThemeContext} from "../../contexts";
import {clearAllInstanceCodes, setHistoryItems} from "./product_history_reducer";
import {IconButton} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {useTranslation} from "react-i18next";
import {extractProductClassCode} from "../../utils/CodesUtils";
import {fetchProductHistoryInfo} from "../../utils/Utils";

export default function SelectedSgtinChipArray({graphModeType, gtinNameLut}) {
    const { t } = useTranslation();

    const {accentColorAlpha} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            listStyle: 'none',
            padding: theme.spacing(0.6),
            margin: '0',
        },
        chipContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            flexWrap: 'wrap',
        },
        chip: {
            margin: theme.spacing(0.5),
            backgroundColor: accentColorAlpha,
            opacity: '0.8'
        },
    }));

    const classes = useStyles();
    const [state, dispatch] = useContext(ProductHistoryContext)

    const handleDelete = (chipToDelete) => async () => {
        const filteredSgtins = state.selectedSgtins.filter((sgtin) => sgtin !== chipToDelete)
        if(filteredSgtins.length === 0)
            dispatch(clearAllInstanceCodes())
        else {
            const truncate = (graphModeType || state.maxChildren === 'all')

            const history = await fetchProductHistoryInfo(filteredSgtins, true, true, truncate, state.maxChildren, null)
                .catch((error) => {
                    console.log("error fetching history info", error)
                })

            if (history?.data?.items?.length !== 0)
                dispatch(setHistoryItems(history?.data, filteredSgtins, truncate, state.maxChildren)) // aggiorna anche gli sgtin selezionati
        }};

    const handleClearAll = () => {
        dispatch(clearAllInstanceCodes())
    }

    return (
        <Box component="ul" className={classes.root}>
            {
                state.selectedSgtins.length > 1 ?
                    <Tooltip title={t('productHistory.messages.clearSelection')} arrow={true}>
                        <IconButton
                            size={'small'}
                            edge={"start"}
                            onClick={handleClearAll}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </Tooltip> : null
            }

            <Box className={classes.chipContainer}>
                {state.selectedSgtins.map((data) => {
                    return (
                        <li key={data}>
                            <Zoom in={state.selectedSgtins.length > 0} timeout={200}>
                                <Chip
                                    label={(gtinNameLut[extractProductClassCode(data)]? (gtinNameLut[extractProductClassCode(data)] + ' - ') : '')+ data}
                                    onDelete={handleDelete(data)}
                                    className={classes.chip}
                                    size={'small'}
                                />
                            </Zoom>
                        </li>
                    );
                })}
            </Box>
        </Box>
    );
}
