import _ from "lodash";
import React, {useContext, useMemo, useState} from "react";
import {ProductHistoryContext, ThemeContext} from "../../../contexts";
import MaterialTable from 'material-table';
import {useTranslation} from "react-i18next";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import i18next, {langResources} from "../../../i18n/i18next";
import {Breadcrumbs, Paper} from "@material-ui/core";
import {extractProductClassCode, findCodeTypeAndValue} from "../../../utils/CodesUtils";
import GraphEventDetail from "../../../components/events/GraphEventDetail";
import {FlipToFront, NavigateNext} from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import {NewSearchIcon} from "../../../components/CustomIcons";
import {extractSerialCode} from "../../../utils/Utils";

const NodeDetail = ({data, searchSgtin}) => {
    //console.log("NodeDetail ---> data:",data)
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: '100%',
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        avatar: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        }
    }))
    const classes = useStyles()

    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: accentColor,
            },
        },
    });

    const [state,] = useContext(ProductHistoryContext)
    const {gtinNameLut, eventsMap, selectedSgtins} = state

    const [showDetail, setShowDetail] = useState({show: false, data: null})

    const _data = useMemo(() => {
        if (!data)
            return null
        const remapKeys = {}
        Object.entries(eventsMap).forEach(([key, value]) => {
            const [remappedKey,] = key.split(",")
            if(!remapKeys[remappedKey]) {
                remapKeys[remappedKey] = value
            } else remapKeys[remappedKey] = _.union(remapKeys[remappedKey], value)
        })

        let classCodeType = findCodeTypeAndValue(data.name)[0].type;
        const res = _.map(data.relatedSerialCodes, (code) => {
            const ids = remapKeys[code]
            return {
                serial: code,
                productName: gtinNameLut[extractProductClassCode(code)],
                codeType: classCodeType,//+' - '+findCodeTypeAndValue(code)[0],
                events: ids || [],
                eventsNumber: ids?.length || 0
            }})
        return _.orderBy(res, 'eventsNumber', 'desc')
    }, [data])

    let columns = [
        {title: _.startCase(t('entities.name')), field: 'productName'},
        {title: _.startCase(t('entities.product.serialNumber')), field: 'serial'},
        {title: _.startCase(t('entities.product.codeType')), field: 'codeType'},
        {title: _.startCase(t('entities.eventsNumber', 'Events number')), field: 'eventsNumber'},
    ]

    const onRowClick = (event, rowData) => {
        rowData.eventsNumber !== 0 && setShowDetail({show: true, data: {serial: rowData.serial, eventIds: rowData.events}})
    }

    const tab = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
                    <Link color="inherit" href={'#'}
                          onClick={() => {
                              if (showDetail.show === true)
                                  setShowDetail({...showDetail, show: false})
                          }}>
                        {gtinNameLut[data.name] || data.name }
                    </Link>
                    {showDetail.data?.serial ?
                        <Link href={'#'} color="inherit"
                              onClick={() => {
                                  if (showDetail.show === false)
                                      setShowDetail({...showDetail, show: true})
                              }}>
                            {showDetail.data?.serial}
                        </Link> : null}
                </Breadcrumbs>
                {!showDetail.show && _data ? <MaterialTable
                        columns={columns}
                        data={_data}
                        localization={tab}
                        onRowClick={onRowClick}
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>,
                        }}
                        options={{
                            search: true,
                            sorting: true,
                            showTitle: false,
                            paging: true,
                            padding: 'dense',
                            selection: true,
                            exportButton: true,
                            actionsColumnIndex: -1,
                        }}
                        actions={[
                            {
                                tooltip: 'Copy the selected codes',
                                icon: () => <FlipToFront/>,
                                position: 'toolbarOnSelect',
                                onClick: (evt, rowData) => {
                                    const serials = _.map(rowData, (row) => (row.serial))
                                    navigator.clipboard.writeText(gtinNameLut[data.name]+'\n'
                                        +serials.join('\n'))
                                        .then(r => alert(i18next.t('productHistory.menuLabels.copyAllSerialCodesSuccess','Codes copied to clipboard!')))
                                }
                            },
                            {
                                tooltip: 'Copy this code',
                                icon: () => <FlipToFront/>,
                                position: 'row',
                                onClick: (evt, rowData) => {
                                    const serial = rowData.serial
                                    navigator.clipboard.writeText(gtinNameLut[data.name]+'\n'
                                        +serial)
                                        .then(r => alert(i18next.t('productHistory.menuLabels.copySerialCode','Code copied to clipboard!')))
                                }
                            },
                            {
                                tooltip: 'Add to search',
                                icon: () => <NewSearchIcon/>,
                                position: 'row',
                                onClick: (evt, rowData) => {
                                    searchSgtin([...selectedSgtins, extractSerialCode(rowData.serial)])
                                }
                            }
                        ]}
                    />
                    : showDetail.data && <GraphEventDetail eventsIds={showDetail.data.eventIds}/>}
            </ThemeProvider>
        </div>
    );
}

export default NodeDetail
