import React, {useState} from 'react';
import { Box, Typography, Button, TextField} from '@material-ui/core';
import useAxios from 'axios-hooks'

const TestPage = () => {

    const [page, setPage] = useState(1);
    const [user, setUser] = useState({name: "", job: ""});

    const [{data, loading, error}] = useAxios({
        url: "https://reqres.in/api/users?page=" + page 
    });

    const [{data: postData, loading: postLoading, error: postError}, postExecute] = useAxios(
        {
            url: "https://reqres.in/api/user",
            method: "POST",
        },
        {manual: true} 
    );
    
    const updateForm = (data) => {
        setUser(user => ({...user, ...data}))    
    }
    
    //console.log("loading", loading, error, user)
    
    return (
        <Box>
            <Typography variant="h3">GET</Typography>            
            <ul>
                <li> loading: {loading} </li> {/*questa per vedere spinner, mentre carica loading a true, quando finisce loading va a false*/}
                <li> error: {JSON.stringify(error)} </li>
                <li>
                data: {JSON.stringify(data)}
                </li>
            </ul>       
            <p>
               <Button onClick={()=>setPage(page => page-1)} disabled={page<2}>Prev </Button>
               <Button onClick={()=>setPage(page => page+1)}>Next </Button>
            </p>
            <Typography variant="h3">POST</Typography>            
            <p>
                <TextField value={user.name} onChange={e => updateForm({name: e.target.value})} label="name"/><br/>
                <TextField value={user.job} onChange={e => updateForm({job: e.target.value})} label="job"/><br/>

                <Button onClick={()=> postExecute({data: user})} disabled={user.name === "" || user.job === "" }>create</Button><br/>

                <li> working: {postLoading} </li>
                <li> error: {JSON.stringify(postError)} </li>
                <li>
                result: {JSON.stringify(postData)}
                </li>

            </p>
        </Box>

    )

}

export default TestPage;
