import FormControl from "@material-ui/core/FormControl";
import StrictedWhitelist from "./what/StrictedWhitelist";
import _ from "lodash";
import React, {useContext, useEffect, useMemo, useState} from "react";
import StarsIcon from "@material-ui/icons/Stars";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {AuthContext, CreateEventContext, ThemeContext} from "../../contexts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import {eventFieldNameTypes, splitCbvValues} from "../../utils/CbvUtils";
import {
    availableProductCodeTypes,
    buildInstanceCode,
    checkPrecedence,
    findCodeTypeAndValue,
    serialTypes
} from "../../utils/CodesUtils";
import {severityTypes} from "../../utils/Utils";
import {useTranslation} from "react-i18next";

const ItemCodeBuilder = ({state, setState, field, classes}) => {
    const {t} = useTranslation()
    const digit_regex = /^[0-9]*$/;
    const alpha_digit_regex = /^[0-9a-zA-Z]*$/;
    const float_regex = /^[0-9]*(,[0-9]{3})*([.,][0-9]+)*$/;

    const initialAlertMessageState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialAlertMessageState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const eventContext = useContext(CreateEventContext)
    const authContext = useContext(AuthContext)
    const {primaryColorAlpha, accentColor} = useContext(ThemeContext)
    //const isQuantityList = field.type === (eventFieldNameTypes.QUANTITYLIST.type)
    useEffect(() => {
        if (field.type === (eventFieldNameTypes.QUANTITYLIST.type))
            setState({...state, codeType: serialTypes.LOT})
        else if (field.type === (eventFieldNameTypes.EPCLIST.type))
            setState({...state, codeType: serialTypes.INSTANCE})
        else if (field.name === (eventFieldNameTypes.PARENT_ID.name))
            setState({...state, codeType: serialTypes.INSTANCE})
    }, [])

    /*function CodeType(props) {
        function onChange(event) {
            setState({...state, codeType: event.target.value})
        }

        return <FormControl
            variant="outlined"
            style={{flex: 1, marginRight: "2%"}}>
            <InputLabel>Type</InputLabel>
            <Select
                displayEmpty
                disabled={props.disabled}
                value={props.codeType}
                onChange={onChange}
                label="Type">
                {
                    gtinTypes.map(props.callbackfn)
                }
            </Select>
            <FormHelperText>Select the code type</FormHelperText>
        </FormControl>;
    }*/

    const options = useMemo(() => {
        return _.orderBy(eventContext.products.data.map((product) => ({
            name: product.name,
            key: product.key,
            mine: product.mine,
            brandName: product.brandName ? product.brandName : authContext.activeBrandName,
            companyPrefix: product.companyPrefix ? product.companyPrefix : null,
            whitelisted: _.includes(field.whitelist, product.key)
        })), ['whitelisted', 'mine', 'brandName'], ['desc', 'desc', 'asc'])
    }, [eventContext.products.data])

    const handleChangeSerialNumber = (event) => {
        setState({...state, serialNumber: _.toUpper(event.target.value)})
    }

    const handleChangeQuantity = (event) => {
        setState({...state, quantity: event.target.value})
    }
    const handleChangeUom = (event) => {
        setState({...state, uom: event.target.value})
    }

    const validateItemCode = () => {
        return state.productClass?.classType === availableProductCodeTypes.GTIN14 && state.codeType === serialTypes.INSTANCE ?
            [!digit_regex.test(state.serialNumber) || state.serialNumber.length > 20, t('createEvent.numericFormat', 'Numeric format')+' - '+t('createEvent.upTo20digits', 'up to 20 digits')]
            : (state.productClass?.classType === availableProductCodeTypes.INTERNAL || state.codeType === serialTypes.LOT) ?
                [!alpha_digit_regex.test(state.serialNumber), t('createEvent.alphaNumericFormat', 'Alpha numeric format')] : [true, '']
    }

    const validation = (field) => {
        let validate = !state.codeType || !state.productClass || (!state.serialNumber && !state.generateCodeCheck)
        validate = validate || validateItemCode()[0]
        if (field.name === eventFieldNameTypes.PARENT_ID.name)
            validate = validate || !!state.parentID
        if (field.type === eventFieldNameTypes.QUANTITYLIST.type)
            validate = validate || (!state.quantity || !float_regex.test(state.quantity)) || !state.uom

        return validate
    }

    const handleGenerateCodeCheckedChange = (event) => {
        setState({...state, /*serialNumber:'', */generateCodeCheck: event.target.checked})
    };

    async function handleSetParentID() {
        const code = await buildInstanceCode(state.generateCodeCheck, state.codeType, state.productClass.key, state.productClass.companyPrefix, state.serialNumber, setOpenAlertMessage)
        const parentID = {type: state.productClass ? state.productClass.name : null, value: code}

        eventContext.setValidationField('what', field.name, true)
        eventContext.setFormField('what', field.name, parentID)
        setState({...state, parentID: parentID})
    }

    async function handleAddCode() {
        const code = await buildInstanceCode(state.generateCodeCheck, state.codeType, state.productClass.key, state.productClass.companyPrefix, state.serialNumber, setOpenAlertMessage)
        const res = await checkPrecedence(eventContext.getForm().name, code, setOpenAlertMessage)
        if (res.checkPrecedence) {
            if (!_.some(state.codes, ['value', code])) {
                let newCodes = [...state.codes, {
                    type: state.productClass ?
                        state.productClass.name : null,
                    value: code,
                    quantity: state.quantity ? _.replace(state.quantity.trim(), ',', '.') : null,
                    uom: state.uom ? state.uom : null
                }]
                eventContext.setValidationField('what', field.name, newCodes.length !== 0)
                eventContext.setFormField('what', field.name, newCodes)
                //eventContext.setFormField('what', field.name, newCodes.map((code) => (isQuantityList ? [code.value, code.quantity, code.uom] : code)))

                setState({
                    ...state,
                    codes: newCodes
                })
            } else {
                setOpenAlertMessage({
                    open: true,
                    message: t('createEvent.itemsCodeMustBeUnique', "Item codes must be unique! Please change the serial number"),
                    severity: 'warning'
                });
            }
        } else {
            setOpenAlertMessage({
                open: true,
                message: t('createEvent.precedenceWarningMessage', {
                    precedence: splitCbvValues(field.precedence),
                    eventName: splitCbvValues(eventContext.getForm().name)
                }),
                severity: 'warning'
            });
        }
    }

    /**
     * - se STRICTED posso scegliere solo le opzioni nella whitelist
     * - se NOT stricted metto in cima le opzioni nella whitelist, segnalandole
     */
    return (
        <Box pr={2} pl={2} className={classes.builderArea}>
            <Box pt={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Tooltip arrow
                         title={t('createEvent.autogeneratedCodeMessage', "If you don't have a serial code, iChain will generate an instance code for this item")}>
                    <WarningIcon className={classes.warningIcon}/>
                </Tooltip>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={state.generateCodeCheck}
                                           onChange={handleGenerateCodeCheckedChange}
                                           disabled={!!state.parentID}
                                           color="default"/>}
                        label={<Typography variant={"caption"}>{t('createEvent.iDontHaveACode', 'I don\'t have a serial code')}</Typography>}
                        labelPlacement="end"/>
                </FormControl>
            </Box>
            <div className={classes.codeBuilderArea}>
                <FormControl style={{flex: 2, marginRight: '2%', marginBottom: '2%'}}>
                    {
                        field.strict && field.whitelist && field.whitelist.length !== 0 ?
                            <StrictedWhitelist
                                field={field}
                                state={state}
                                setState={setState}
                                classes={classes}/>
                            : <Autocomplete
                                fullWidth
                                size={'small'}
                                //multiple
                                value={state.productClass}
                                /*groupBy={(option) => field.whitelist && field.whitelist.length !== 0 ?
                                    option.whitelisted ? "Suggested" : "Others" : ""}*/
                                groupBy={(option) => (_.includes(option.key, 'x.'+authContext.activeMainDomain) ? t('entities.product.othersFrom',"Others from ") : '')
                                    + (option.brandName ? option.brandName : '')}
                                disabled={!!state.parentID}
                                filterSelectedOptions
                                options={options}
                                getOptionLabel={(option) => option.name +' - ' + option.brandName +' - '+ (findCodeTypeAndValue(option.key)[2]?
                                    findCodeTypeAndValue(option.key)[2] + ': ' : '') + findCodeTypeAndValue(option.key)[1]}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setState({
                                            ...state, productClass: {
                                                key: newValue.key,
                                                name: newValue.name,
                                                brandName: newValue.brandName,
                                                companyPrefix: newValue.companyPrefix,
                                                classType: findCodeTypeAndValue(newValue.key)[0]
                                            }
                                        });
                                    } else {
                                        setState({...state, productClass: null});
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               size={'small'}
                                               label={t('createEvent.enterProductClassCode', "Enter the product class code")}
                                               variant="outlined"
                                               error={!state.productClass}
                                    />}
                                renderOption={(option, {selected}) => {
                                    const [codeType, codeValue, domain] = option ? findCodeTypeAndValue(option.key) : [null, null, null]
                                    return <React.Fragment>
                                        {
                                            option.whitelisted ?
                                                <StarsIcon style={{
                                                    color: accentColor,
                                                    marginRight: '3%'
                                                }}/> : null
                                        }
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography className={classes.name}>{option.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {
                                                        codeType ?
                                                            <Chip
                                                                size={'small'}
                                                                label={codeType.type}
                                                                className={classes.labelChip}
                                                            /> : null
                                                    }
                                                    {
                                                        domain ?
                                                            <Chip
                                                                size={'small'}
                                                                label={domain}
                                                                className={classes.labelChip}
                                                            /> : null
                                                    }
                                                    <Typography className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.name ? option.name : option.address}
                                            style={{backgroundColor: primaryColorAlpha, opacity: '0.8'}}
                                            {...getTagProps({index})}
                                        />
                                    ))
                                }
                            />
                    }
                    <FormHelperText>
                        {field.strict && field.whitelist && field.whitelist.length !== 0 ?
                            t('createEvent.onlyTheseProductsAreAllowed', 'Only products in this list are allowed')
                            : !field.strict && field.whitelist && field.whitelist.length !== 0 ? t('createEvent.firstElementAreSuggested', 'First elements of the list are recommended')
                                : ''}
                    </FormHelperText>
                </FormControl>
                {/*
                    field.name === eventFieldNameTypes.PARENT_ID.name?
                        <CodeType codeType={state.codeType}
                          disabled={!!state.parentID}
                          callbackfn={(type) => (
                              <MenuItem value={type}
                                        key={type.value}>{type.label}</MenuItem>)}/> : null
                */}
                <FormControl
                    variant="outlined"
                    style={{flex: 2}}>
                    <TextField
                        size={'small'}
                        variant={"outlined"}
                        placeholder={"e.g. 12345678901234567890"}
                        label={!state.generateCodeCheck ? t('entities.product.serialNumber', "Serial Number") : t('createEvent.iChainWillGenerateAnItemCode', "iChain will generate an instance code for this item")}
                        //defaultValue={state.serialNumber || ''}
                        onChange={handleChangeSerialNumber}
                        error={validateItemCode()[0]}
                        disabled={!!state.parentID || state.generateCodeCheck}
                    />
                    <FormHelperText error={validateItemCode()[0]}>
                        {validateItemCode()[1]}
                    </FormHelperText>
                </FormControl>
            </div>
            {
                field.type === eventFieldNameTypes.QUANTITYLIST.type ?
                    <div style={{display: 'flex', margin: 'auto', marginTop: '1%'}}>
                        <TextField
                            size={'small'}
                            style={{marginRight: '3%'}}
                            label={t('entities.quantity', "Quantity")}
                            //defaultValue={state.quantity}
                            onChange={handleChangeQuantity}
                            error={!float_regex.test(state.quantity)}
                            placeholder={t('createEvent.numericFormat', 'Numeric format')}
                        />
                        <TextField
                            size={'small'}
                            label={t('entities.uom', "Unity of Measurement")}
                            //defaultValue={state.uom}
                            onChange={handleChangeUom}
                        />
                    </div> : null
            }
            <Box p={2} style={{display: 'flex', margin: 'auto'}}>
                <Button aria-label="set"
                        disabled={validation(field)}
                        onClick={field.name !== eventFieldNameTypes.PARENT_ID.name ? handleAddCode : handleSetParentID}
                        endIcon={field.name !== eventFieldNameTypes.PARENT_ID.name ? <AddCircleIcon style={{
                                color: (validation(field)) ? 'lightgray' : accentColor
                            }}/>
                            : <CheckCircleIcon style={{
                                color: (validation(field)) ? 'lightgray' : accentColor
                            }}/>}
                >
                    {field.name !== eventFieldNameTypes.PARENT_ID.name ? t('actions.add') : t('actions.confirm')}
                </Button>
            </Box>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                      onClose={handleCloseErrorMessage}>
                <Alert onClose={handleCloseErrorMessage}
                       severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
            </Snackbar>
        </Box>)
}

export default ItemCodeBuilder
