import React, {useContext, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import * as yup from "yup";
import {RulesConfiguratorContext, ThemeContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";

const CreateTemplateDraft = ({editMode, draftName, draftDescription, id, nodeDomain, handleCloseDialog}) => {
    const {t} = useTranslation()

    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiTextField-root': {
                marginBottom: theme.spacing(2),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        }
    }));
    const classes = useStyles()

    const [state,] = useContext(RulesConfiguratorContext)

    const schema = yup.object().shape({
        name: yup.string()
            .required(t('rulesConfigurator.messages.draftNameRequired', 'Draft Model Name Required'))
            //.notOneOf(state.modelsNames, t('createEventModel.eventNameBusy', 'There is already a template with the same provided name in the same domain'))
            .test('cannotContainSpecialChar',
                t('createEventModel.cannotContainSpecialChar', {specialChar: '/'}),
                function(str) {
                    return !str.includes('/')
                })
            .test('cannotContainWhiteSpaces', t('createEventModel.cannotContainWhiteSpaces', 'Cannot contain white spaces'),
                function (str) {
                return !/\s/g.test(str)
            })
    });

    const {control, handleSubmit, errors} = useForm({
        validationSchema:schema,
        defaultValues: {name:draftName||'',description: _.replace(draftDescription, /(^\s*(?!.+)\n+)|(\n+\s+(?!.+)$)/g, '')||''}
    })

    const [serverError, setServerError] = useState(null)

    const onSubmit = (data) => {
        //console.log("onSubmit DRAFT --> data",data);
        const payload = editMode ? {name: data.name, description: _.replace(data.description, /(^\s*(?!.+)\n+)|(\n+\s+(?!.+)$)/g, '')||''}
            : {
                name: data.name,
                description: data.description || '',
                is_draft: true,
                version: 0,
            }

        let url = `api/capture/event_templates/${editMode ? id : ''}?domain=${nodeDomain||state.nodeDomain.domain}`

        if(editMode) {
            if(data.name !== draftName || data.description !== draftDescription)
                axios({
                    method: 'patch',
                    url: url,
                    data: payload,
                    config: {headers: {'Media-Type': 'application/json'}}
                }).then(function (res) {
                    handleCloseDialog({...data, is_draft: true, _id:id})
                }).catch((error) => {
                    console.log('error editing draft name:', error);
                    if (error.response?.status === 400) {
                        setServerError(t('createEventModel.eventNameBusy'));
                    } else if (error.response?.status === 403) {
                        setServerError((error.response?.data?.detail[0]?.msg || error.response?.data?.detail) + ' ' + t('errors.forbidden'));
                    } else setServerError(error.response?.status + ' ' + t("errors.genericServerError"));
                    return Promise.reject(error)
                });
            else handleCloseDialog()
        } else {
            url = `api/capture/event_templates/?domain=${state.nodeDomain.domain}`
            axios({
                method: 'post',
                url: url,
                data: {
                    name: data.name,
                    description: data.description,
                    is_draft: true,
                    version: 0
                },
                config: {headers: {'Media-Type': 'application/json'}}
            }).then(function (res) {
                handleCloseDialog({...data, is_draft: true, _id:res.data._id})
            }).catch((error) => {
                console.log('error editing draft name:', error);
                if (error.response.status === 400) {
                    //console.log("ERROR:",error)
                    setServerError(t('createEventModel.eventNameBusy'));
                } else if (error.response.status === 403) {
                    //console.log("ERROR:",error)
                    setServerError((error.response.data?.detail[0]?.msg || error.response.data.detail) + ' ' + t('errors.forbidden'));
                } else {
                    //console.log("ERROR:",error)
                    /*setOpenError({
                        open: true,
                        message: error.response.status + ' ' + t("errors.genericServerError"),
                    });*/
                }
                return Promise.reject(error)
            });
        }
    }

    return (<Box className={classes.root} p={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                as={<TextField
                    fullWidth
                    autoFocus
                    //variant={'outlined'}
                    size={'small'}
                    placeholder={t('rulesConfigurator.draftModelName')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                />}
                name="name"
                control={control}
            />
            <Controller
                as={<TextField
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={4}
                    variant={'outlined'}
                    size={'small'}
                    placeholder={t('rulesConfigurator.draftModelDescription')}
                />}
                name="description"
                control={control}
            />
            {serverError && <FormHelperText style={{color:'red'}}>{serverError}</FormHelperText>}
            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={() => handleCloseDialog(undefined)}>{t('actions.cancel')}</Button>
                <Button type="submit">{t('actions.confirm')}</Button>
            </Box>
        </form>
    </Box>)
}

export default CreateTemplateDraft
