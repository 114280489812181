import React, {useContext} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FeatureValue from "./FeatureValue";
import {Controller, useForm} from "react-hook-form";
import {ThemeContext} from "../../contexts";
import {useTranslation} from "react-i18next";
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    box: {
        backgroundColor: "#f6f6f6",
        boxShadow: theme.shadows[1]
    },
}));

const CustomFeature = (props) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const classes = useStyles();
    const {handleSubmit, register, reset, control, watch} = useForm({
        defaultValues: {
            prefix: 'iChain',
            required: false
        }
    });

    const features = props.value || [];

    const onAddClick = (data) => {
        console.log("onAddClick ---> data:",data)
        const new_features = [...features, {/*ns: data.prefix,*/
            ns: 'iChain',
            name: data.name,
            value: data.value,
            required: data.required,
        }];
        props.onChange && props.onChange(new_features);
        reset()
    }

    const onClearClick = (feature) => {
        let new_features = features.filter(val => val.name !== feature.name)
        props.onChange && props.onChange(new_features);
    }

    /**
     * PER I NAMESPACES:
     * const available_prefix = ['Prefix 1','Prefix 2','Prefix 3'];
     * const PrefixInput = ({value, onChange}) => {
        const {t} = useTranslation()
        return (
            <FormControl fullWidth>
                <InputLabel>{t('entities.prefix')}</InputLabel>
                <Select value={value || ''} onChange={onChange}>
                    <MenuItem value='Default' key='Default'>
                        <em>Default</em>
                    </MenuItem>
                    {
                        available_prefix.map(
                            (prefix) => (
                                <MenuItem value={prefix} key={prefix}>{prefix}</MenuItem>)
                        )
                    }
                </Select>
            </FormControl>
        )
    };*/

    return (
        <Box className={classes.box} p={2} borderRadius={6}>
            <Typography>{t('entities.product.customFeatures')}</Typography>
            <Box pt={1} pl={3} pr={3}>
                {
                    features.map((feature, index) => <FeatureValue key={index} remove={onClearClick} feature={feature}/>)
                }
            </Box>
            <Box pt={2} pb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Grid container direction="row" justify="center" alignItems="center">
                    {/*<Grid item xs={11} md={2}>
                        <Box>
                            <Controller
                                as={
                                    <PrefixInput/>
                                }
                                name="prefix"
                                control={control}
                            />
                        </Box>
                    </Grid>*/}
                    <Grid item xs={12} md={4}>
                        <Box pl={3}>
                            <TextField fullWidth label={_.startCase(t('entities.name'))} name="name" inputRef={register}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box pl={3}>
                            <TextField fullWidth label={_.startCase(t('entities.value'))} name="value" inputRef={register}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box pl={3} display="flex" justifyContent="flex-end">
                            <Controller
                                as={
                                    <FormControlLabel control={<Checkbox color='default'/>}
                                                      label={t('entities.required')}
                                                      labelPlacement="end"/>
                                }
                                name="required"
                                control={control}
                                type="checkbox"
                            />
                        </Box>
                    </Grid>
                </Grid>
                <IconButton style={{color: (!watch('name') || !watch('value')) ? 'lightgray' : accentColor}}
                            aria-label="add"
                            onClick={handleSubmit(onAddClick)}
                            disabled={!watch('name') || !watch('value')}>
                    <AddCircleIcon/>
                </IconButton>
            </Box>
        </Box>
    )
}

export default CustomFeature
