import React, {Fragment, useContext, useEffect, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {makeStyles} from "@material-ui/core/styles";
import WhyRuleComponent from "./WhyRuleComponent";
import WhenRuleComponent from "./WhenRuleComponent";
import _ from "lodash"
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';
import {parseName, searchPlaceFromSgln, searchProductFromPrefixes,} from "../../../utils/Utils";
import {splitCbvValues} from '../../../utils/CbvUtils'
import {
    convertClassToInstancesArray,
    extractProductClassCode,
    findCodeTypeAndValue,
    findLocationCodeTypeValue
} from '../../../utils/CodesUtils'
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button";
import {RelationshipContext, ThemeContext} from "../../../contexts";
import Chip from "@material-ui/core/Chip";
import CustomLoader from "../../../components/CustomLoader";
import i18next from "../../../i18n/i18next";
import FieldInfo from "../../generate_new_event/FieldInfo";

/**import {createFilterOptions} from "@material-ui/lab";
const filter = createFilterOptions();*/

export const name_types = {
    product_name: {value: 'product_name', label: i18next.t('entities.product.name')},
    prefix: {value: 'prefix', label: _.startCase(i18next.t('entities.prefix'))},
    serial_code_range: {value:'serial_code_range', label:i18next.t('createRelationship.rule.sgtinRange', 'Serial code range')},
    all_products: {value: 'all_products', label: i18next.t('createRelationship.rule.allProducts', 'All products')}
}

const where_types = [
    i18next.t('cbvValues.bizLocation.name', 'Business Location'),
    i18next.t('cbvValues.readPoint.name', 'Read point'),
]

const why_types = [
    i18next.t('cbvValues.eventName.label', 'Event name'),
    i18next.t('cbvValues.bizStep.name', 'Business Step'),
]

const WBooleanForm = ({value, onChange, places, products, eventTemplates}) => {
    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        form: {
            width: '100%',
            height: '100%'
        },
        typography: {
            padding: theme.spacing(2),
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        labelChip: {
            margin: '0.6%',
            color: _theme.accentColor,
            border: '1px solid ' + _theme.accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }));
    const classes = useStyles();
    const relationshipContext = useContext(RelationshipContext);

    let rules = value || [];

    const initialState = {
        nameType: name_types.product_name,
        whereType: where_types[0],
        whyType: why_types[0],
        name: '',
        prefixes: [],
        nameTo: '',
        where: '',
        why: '',
        prefixError: false,
        sgtinFromError: false,
        sgtinToError: false,
        selectedDates: [{
            startDate: undefined,
            endDate: undefined,
            color: 'darkgrey',
            key: 'selection'
        }],
    }
    const [state, setState] = useState(initialState)

    if(_.has(value, 'locations') && _.has(value, 'items')){
        // se in value ho il campo locations e items è perchè ho una regola master data e qui svuoto le regole //
        rules = []
    }

    useEffect(() => {
        if (!relationshipContext.editMode.state) {
            setState(initialState)
        }
    }, [relationshipContext.editMode])

    // per popover d'errore
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    // ------------------------------------------

    const handleChangeNameType = (event) => {
        setState({
            ...state,
            name: event.target.value === name_types.all_products ? null : '',
            nameType: event.target.value
        })
    };

    const handleChangeWhereType = (event) => {
        setState({
            ...state,
            where: '',
            whereType: event.target.value
        })
    };

    const onChangeTextName = (event) => {
        setState({
            ...state,
            name: event.target.value
        })
    }

    const onChangeTextNameTo = (event) => {
        setState({
            ...state,
            nameTo: event.target.value
        })
    }

    const handleChangeWhat = (event, values) => {
        setState({
            ...state,
            name: values
        })
    };

    const handleChangeWhere = (event, values) => {
        setState({
            ...state,
            where: values
        })
    };

    const onAddClick = (event) => {
        function addRule(event) {
            if (state.sgtinToError) {
                setState({...state, sgtinToError: false})
            }
            if (state.sgtinFromError) {
                setState({...state, sgtinFromError: false})
            }

            if ((state.nameType.value === name_types.serial_code_range.value && state.nameTo === '') ||
                (state.nameType.value === name_types.prefix.value && state.prefixes.length === 0) ||
                ((state.nameType.value !== name_types.prefix.value && state.name === '') &&
                state.why === '' && state.where === '' && !state.selectedDates[0].startDate)) {
                setAnchorEl(event.currentTarget);
                return
            }

            const new_rules = [
                ...rules, {
                    what: state.nameType.value === name_types.all_products.value ? null
                        : state.nameType.value === name_types.serial_code_range.value ? {
                                name_types: name_types.serial_code_range.value,
                                serial_range_begin: state.name,
                                serial_range_end: state.nameTo
                            }
                            :
                            state.nameType.value === name_types.prefix.value ? {name_types: state.nameType.value, prefixes: state.prefixes} :
                                state.nameType.value === name_types.product_name.value && state.name !== "" && state.name !== null ? {
                                        name_types: state.nameType.value,
                                        prefixes: convertClassToInstancesArray(state.name)
                                    }
                                    : null,
                    biz_step: state.whyType === why_types[1] ? state.why !== '' ? state.why : null : null,
                    event_name: state.whyType === why_types[0] ? state.why !== '' ? state.why : null : null,
                    biz_location: state.whereType === where_types[0] ? state.where !== null && state.where !== '' ? state.where.sgln : null : null,
                    read_point: state.whereType === where_types[1] ? state.where !== null && state.where !== '' ? state.where.sgln : null : null,
                    when: state.selectedDates[0].startDate ? {
                        start: Moment(state.selectedDates[0].startDate).format('YYYY-MM-DDThh:mm:ss'),
                        end: state.selectedDates[0].endDate !== state.selectedDates[0].startDate ? Moment(state.selectedDates[0].endDate).format('YYYY-MM-DDThh:mm:ss') : null
                    } : null
                }
            ]

            onChange && onChange(new_rules);
            setState({
                ...state,
                nameType: name_types.product_name,
                whereType: where_types[0],
                whyType: why_types[0],
                name: '',
                nameTo: '',
                prefixes: [],
                where: '',
                why: '',
                selectedDates: [{
                        startDate: undefined,
                        endDate: undefined,
                        color: 'darkgrey',
                        key: 'selection'
                    }],
            })
        }

        if (state.nameType.value === name_types.prefix.value) {
                addRule(event)
        }
        if (state.nameType.value === name_types.serial_code_range.value) {
            //validazione degli sgtin, da definire
            if (((!state.name.includes("urn:epc:") || !state.nameTo.includes("urn:epc:"))
                && ((!state.name.includes("http://") || !state.nameTo.includes("http://"))))) {
                if (!state.name.includes("urn:epc:") || !state.name.includes("http://")) {
                    setState({...state, sgtinFromError: true})
                } else setState({...state, sgtinFromError: false})
                if (!state.nameTo.includes("urn:epc:") || !state.nameTo.includes("http://")) {
                    setState({...state, sgtinToError: true})
                } else setState({...state, sgtinToError: false})
            } else {
                addRule(event)
            }
        }
        if (state.nameType.value !== name_types.prefix.value && state.nameType.value !== name_types.serial_code_range.value) {
            addRule(event)
        }
    }

    const onDeleteRow = (index) => {
        _.pullAt(rules, [index])
        onChange && onChange([...rules]);
    }

    const editWhat = (rule) => {
        if (rule.what !== null) {
            let objProducts = {}
            let sgtinFrom = '';
            let sgtinTo = '';
            if (rule.what.name_types === name_types.product_name.value) {
                let what = rule.what.prefixes[0];
                let classCode = extractProductClassCode(what)
                objProducts = _.find(products, ['key', classCode])
            }
            if (rule.what.name_types === name_types.serial_code_range.value) {
                sgtinFrom = rule.what.serial_range_begin;
                sgtinTo = rule.what.serial_range_end
            }
            return {
                nameType: _.find(name_types, ['value', rule.what.name_types]),
                name: rule.what.name_types === name_types.product_name.value ? objProducts :
                    rule.what.name_types === name_types.serial_code_range.value ? sgtinFrom : '',
                nameTo: rule.what.name_types === name_types.serial_code_range.value ? sgtinTo : '',
                prefixes: rule.what.name_types === name_types.prefix.value ? rule.what.prefixes : []
            }
        } else {
            return {nameType: name_types.all_products, name: name_types.all_products.label}
        }
    }

    const editWhy = (rule) => {
        if (rule.biz_step !== null) {
            return {whyType: why_types[1], why: splitCbvValues(rule.biz_step)}
        } else {
            return {whyType: why_types[0], why: splitCbvValues(rule.event_name)}
        }
    }

    const editWhere = (rule) => {
        if (rule.biz_location !== null) {
            return {
                whereType: where_types[0],
                where: searchPlaceFromSgln(rule.biz_location, places.bizLocation)
            }
        } else {
            return {
                whereType: where_types[1],
                where: searchPlaceFromSgln(rule.read_point, places.readPoint)
            }
        }
    }

    const onEditClick = (rule) => {
        let what, why, where, when
        if (rule.what && Object.keys(rule.what).length > 0) {
            what = editWhat(rule)
        }
        if (rule.biz_step !== null || rule.event_name !== null) {
            why = editWhy(rule)
        }
        if (rule.read_point !== null || rule.biz_location !== null) {
            where = editWhere(rule)
        }
        if (rule.when != null) {
            when = {
                selectedDates: [{
                    startDate: rule.when.start !== null ? new Date(rule.when.start) : null,
                    endDate: rule.when.end !== null ? new Date(rule.when.end) : null,
                    color: 'darkgrey',
                    key: 'selection'
                }]
            }
        }
        const editState = {...what, ...why, ...where, ...when}
        setState({...state, ...editState})
    }

    const handleInputChange = (e, data) => {
        return data;
    };

    let ruleLabel = i18next.t('createRelationship.rule.label')
    return (
        <Box pt={2}>
            <div style={{border: '2px solid lightGray', borderRadius: '15px', borderStyle: 'dotted'}}>
                <Box>
                    <Box p={2} className={classes.rulesSummary}>
                        <Typography>{_.startCase(i18next.t('createRelationship.rule.label_plural'))}</Typography>
                        {
                            rules.length > 0 ? <TableContainer>
                                <Table size="small" padding={"none"}>
                                    <TableBody>
                                        {rules.map((rule, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    <div style={{display: 'inline-flex'}}>
                                                        <IconButton onClick={() => {
                                                            onDeleteRow(index)
                                                        }}>
                                                            <DeleteIcon fontSize="small"/>
                                                        </IconButton>
                                                        <IconButton onClick={() => {
                                                            onDeleteRow(index)
                                                            onEditClick(rule)
                                                        }}>
                                                            <EditIcon fontSize="small"/>
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                                {
                                                    rule.biz_location === null &&
                                                    rule.biz_step === null &&
                                                    rule.event_name === null &&
                                                    rule.read_point === null &&
                                                    rule.what === null &&
                                                    rule.when === null && <TableCell component="th" scope="row">{i18next.t('createRelationship.rule.completeObservation')}</TableCell>
                                                }
                                                {
                                                    rule.what !== "" ?
                                                        <TableCell component="th" scope="row">
                                                            {
                                                                rule.what !== null ? rule.what.name_types ? (
                                                                    rule.what.name_types === name_types.prefix.value ? (
                                                                        <div style={{display:'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                                                                            {_.startCase(i18next.t('entities.prefix_plural'))}:
                                                                            {
                                                                                rule.what.prefixes.map((prefix, index) =>
                                                                                    <Chip size={"small"} className={classes.labelChip} key={index} label={prefix}/>)
                                                                            }
                                                                        </div>)

                                                                    : (_.find(name_types, ['value', rule.what.name_types])?.label || rule.what.name_types) + ": " + (
                                                                        rule.what.name_types === name_types.product_name.value ?
                                                                            searchProductFromPrefixes(rule.what.prefixes, products)
                                                                                : rule.what.name_types === name_types.serial_code_range.value ?
                                                                                    i18next.t('entities.interval.from')+": " + rule.what.serial_range_begin +" "+i18next.t('entities.interval.to')+": " + rule.what.serial_range_end
                                                                                    : rule.what.name_types === name_types.all_products.value ? i18next.t('createRelationship.rule.allProductsAreSelected', 'All products are selected') : null
                                                                    )
                                                                ) : null : null
                                                            }
                                                        </TableCell> : null
                                                }
                                                {
                                                    rule.biz_step !== null ?
                                                        <TableCell component="th" scope="row">
                                                            <strong>Business step: </strong>{parseName(splitCbvValues(rule.biz_step))}
                                                        </TableCell> :
                                                        rule.event_name !== null ?
                                                            <TableCell component="th" scope="row">
                                                                <strong>Event name: </strong>{parseName(rule.event_name)}
                                                            </TableCell> : null
                                                }
                                                {
                                                    rule.biz_location !== null ?
                                                        <TableCell component="th" scope="row">
                                                            <strong>Business Location: </strong>{(_.find(places.bizLocation, ['sgln', rule.biz_location])?.name
                                                            + ' ('+_.find(relationshipContext.allCompanies, ['domain', _.find(places.bizLocation, ['sgln', rule.biz_location])?.data_origin]).name
                                                            +') - '+(_.find(places.bizLocation, ['sgln', rule.biz_location])?.gln) || rule.biz_location)}
                                                        </TableCell> :
                                                        rule.read_point !== null ?
                                                            <TableCell component="th" scope="row">
                                                                <strong>Read point: </strong>{(_.find(places.readPoint, ['sgln', rule.read_point])?.name
                                                                + ' ('+_.find(relationshipContext.allCompanies, ['domain', _.find(places.readPoint, ['sgln', rule.read_point])?.data_origin]).name
                                                                +') - '+(_.find(places.readPoint, ['sgln', rule.read_point])?.sgln) || rule.read_point)}
                                                            </TableCell>
                                                            : null
                                                }
                                                {
                                                    rule.when !== null ?
                                                        <TableCell component="th" scope="row">
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Grid item>
                                                                        <strong>{i18next.t('entities.interval.from')}: </strong>
                                                                        {i18next.t("formatters:dateLong", {date: Moment(rule.when.start)})}
                                                                    </Grid>
                                                                        {
                                                                            rule.when.end !== null ?
                                                                                <Grid item>
                                                                                    <strong>{i18next.t('entities.interval.to')}: </strong>
                                                                                    {i18next.t("formatters:dateLong", {date: Moment(rule.when.end)})}
                                                                                </Grid> : ''
                                                                        }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        : null
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : null
                        }
                    </Box>
                    <Grid container>
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                <Box pl={2} pr={2}>
                                    <FormControl size={'small'}
                                                 variant="outlined"
                                                 className={classes.form}>
                                        <InputLabel>What</InputLabel>
                                        <Select
                                            value={state.nameType}
                                            onChange={handleChangeNameType}
                                            label={_.startCase(i18next.t('entities.name'))}>
                                            {
                                                Object.values(name_types).map(
                                                    (type) => (
                                                        <MenuItem value={type} key={type.value}>{type.label}</MenuItem>)
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Box pr={2}>
                                    {
                                        state.nameType.value === name_types.product_name.value ?
                                            <Autocomplete
                                                size={'small'}
                                                options={products}
                                                groupBy={(option) => option.mine ? i18next.t('entities.product.myProducts') : i18next.t('entities.product.othersProducts', "Others' products")}
                                                renderOption={option => {
                                                    const [codeType, codeValue, domain] = option ? findCodeTypeAndValue(option.key) : [null, null, null]

                                                    return (<Fragment>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.name}>{option.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                {
                                                                    codeType ?
                                                                        <Chip
                                                                            size={'small'}
                                                                            label={codeType.type}
                                                                            className={classes.labelChip}
                                                                        /> : null
                                                                }
                                                                {
                                                                    domain ?
                                                                        <Chip
                                                                            size={'small'}
                                                                            label={domain}
                                                                            className={classes.labelChip}
                                                                        /> : null
                                                                }
                                                                <Typography className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Fragment>)}
                                                }
                                                getOptionLabel={(option) => {
                                                    const [type, codeValue, domain] = option ? findCodeTypeAndValue(option.key) : [null, null, null]
                                                    return (option ? option.name + ' - ' + (domain ? domain : type.type) +':' + codeValue : '')
                                                }}
                                                onChange={handleChangeWhat}
                                                value={state.name}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        label="What"
                                                        variant="outlined"
                                                    />
                                                )}
                                            /> : state.nameType.value === name_types.prefix.value
                                                ? <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
                                                <Autocomplete
                                                    freeSolo
                                                    style={{flex:2}}
                                                    size={'small'}
                                                    multiple
                                                    limitTags={5}
                                                    options={[]}
                                                    value={state.prefixes}
                                                    onChange={(event, value1) => {setState({...state, prefixes: value1})}}
                                                    onInputChange={(e, data) => handleInputChange(e, data)}
                                                    getOptionLabel={option => option ? option : ''}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label={_.startCase(i18next.t('entities.prefix_plural'))}
                                                            variant="outlined"
                                                            error={state.prefixError}
                                                            onKeyDown={e => {
                                                                if (e.keyCode === 32 && e.target.value && e.target.value.trim() !== '') {
                                                                    if(e.target.value.includes("urn:epc:") || e.target.value.includes("http://"))
                                                                        setState({...state, prefixError: false, prefixes: state.prefixes.concat(e.target.value)})
                                                                    else setState({...state, prefixError: true})

                                                                }
                                                            }}
                                                            //placeholder={i18next.t('createRelationship.rule.enterPrefixAndPressConfirm')}
                                                            helperText={(state.prefixError ? i18next.t('createRelationship.rule.incorrectPrefixEntry') : "") +' - '+ i18next.t('createRelationship.rule.enterPrefixAndPressConfirm')}
                                                        />
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip label={option} {...getTagProps({index})} />
                                                        ))
                                                    }
                                                />
                                                <FieldInfo description={i18next.t('createRelationship.rule.prefixExample')}/>
                                            </Box>
                                            : state.nameType.value === name_types.serial_code_range.value ?
                                                <Box display={'flex'} alignItems={'flext-start'} justifyContent={'space-between'}>
                                                    <Grid container item xs={12}>
                                                        <Grid item xs={6}>
                                                            <Box pr={2}>
                                                                <TextField
                                                                    size={'small'}
                                                                    fullWidth
                                                                    label={_.startCase(i18next.t('entities.interval.from'))}
                                                                    error={state.sgtinFromError}
                                                                    helperText={state.sgtinFromError ? i18next.t('createRelationship.rule.incorrectPrefixEntry') : ''}
                                                                    onChange={onChangeTextName}
                                                                    value={state.name}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Box>
                                                                <TextField
                                                                    size={'small'}
                                                                    fullWidth
                                                                    label={_.startCase(i18next.t('entities.interval.to'))}
                                                                    error={state.sgtinToError}
                                                                    helperText={state.sgtinToError ? i18next.t('createRelationship.rule.incorrectPrefixEntry') : ''}
                                                                    onChange={onChangeTextNameTo}
                                                                    value={state.nameTo}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <FieldInfo description={i18next.t('createRelationship.rule.prefixExample')}/>
                                                </Box> :
                                                state.nameType.value === name_types.all_products.value ?
                                                    <Box justifyContent="center" alignItems="center">
                                                        <p>{i18next.t('createRelationship.rule.nProductSelected', {count: _.filter(products, ['mine', true]).length})}</p>
                                                    </Box> : null
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <WhyRuleComponent state={state} setState={setState} why_types={why_types} eventTemplates={eventTemplates}/>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={4}>
                                <Box pl={2} pr={2} pt={2}>
                                    <FormControl size={'small'} variant="outlined" className={classes.form}>
                                        <InputLabel>Where</InputLabel>
                                        <Select
                                            value={state.whereType}
                                            onChange={handleChangeWhereType}
                                            label="Where"
                                        >
                                            {
                                                where_types.map(
                                                    (type) => (
                                                        <MenuItem value={type} key={type}>{type}</MenuItem>)
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                {
                                    places ?
                                        <Box pr={2} pt={2}>
                                        <Autocomplete
                                            size={'small'}
                                            options={state.whereType === where_types[0] ? _.orderBy(places.bizLocation, 'mine', 'desc') : _.orderBy(places.readPoint, 'mine', 'desc')}
                                            groupBy={(option) => option.mine ? i18next.t('entities.location.myPlaces', "My Places") : i18next.t('entities.location.othersPlaces', "Others' places")}
                                            renderOption={option => {
                                                const [codeType, codeValue, domain] = findLocationCodeTypeValue(option.gln || option.sgln)
                                                return <React.Fragment>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.name}>{option.name} ({option.companyName})</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                {
                                                                    codeType ?
                                                                        <Chip
                                                                            size={'small'}
                                                                            label={codeType.type ? codeType.type : codeType}
                                                                            className={classes.labelChip}
                                                                        /> : null
                                                                }
                                                                {
                                                                    domain ?
                                                                        <Chip
                                                                            size={'small'}
                                                                            label={domain}
                                                                            className={classes.labelChip}
                                                                        /> : null
                                                                }
                                                                <Typography className={classes.code}>{option && codeValue ? codeValue : (option.gln || option.sgln)}</Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            }}
                                            getOptionLabel={option => {
                                                const company = _.find(relationshipContext.allCompanies, ['domain', option.data_origin])
                                                return option ? (option.name + ' ('+company.name+')'
                                                    + ' - ' + (option && (option.gln || option.sgln)))
                                                    : ''
                                            }}
                                            onChange={handleChangeWhere}
                                            value={state.where}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label="Where"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Box> : <CustomLoader size={80} />
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{padding: 16}}>
                            <WhenRuleComponent state={state} setState={setState}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={2} pr={2} pb={2} style={{display: "flex"}}>
                    <Tooltip title={i18next.t('createRelationship.rule.addThisRule')}>
                        <Button
                            onClick={onAddClick}
                            variant="outlined"
                            color="default"
                            size="medium"
                            style={{marginLeft: 'auto'}}

                            startIcon={<SaveIcon />}>
                            {i18next.t('actions.save', {what: ruleLabel})}
                        </Button>
                    </Tooltip>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography className={classes.typography}>{i18next.t('createRelationship.rule.fillAtLeastOneField')}</Typography>
                    </Popover>
                </Box>
            </div>
        </Box>
    )

}

export default WBooleanForm
