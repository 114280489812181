import React, {useContext} from "react";
import useAxios from "axios-hooks";
import CompanyDetail from "./companies/CompanyDetail";
import {AuthContext} from "../contexts";
import CustomLoader from "../components/CustomLoader";
import {useTranslation} from "react-i18next";

const MyCompany = () => {
    const {t} = useTranslation()
    const authContext = useContext(AuthContext)

    const [{data: my_company, loading, error}, refetchData] = useAxios({
            url: "/api/companies/_by_subdomain/?domain="+authContext.activeMainDomain,
        },
        {useCache: false}
    )

    return (
        <div>
            {
                my_company ? <CompanyDetail myCompany={my_company} refetchData={refetchData}/> : null
            }
            {loading ?
                <CustomLoader size={80} />
                : null}
            {error ? t('errors.genericServerError') : null}
        </div>
    )
}

export default MyCompany;
