import {SETDATA} from './actions'

function reducer(state, action) {
    //console.log("reducer", action, state)
    switch (action.type) {
        case SETDATA: {
            return { ...state,
                whatAggregate: action.whatAggregate,
                whenAggregate: action.whenAggregate,
                whereAggregate: action.whereAggregate,
                whyAggregate: action.whyAggregate
            }
        }
        default:
            console.log("Errore: azione", action.type, "sconosciuta")
            return state;
    }
}

export {reducer}