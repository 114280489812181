import React, {useContext, useState} from "react";
import {ProductHistoryContext, ProductHistoryProvider, ThemeContext} from "../../contexts";
import BuildProductInstanceCode from "./BuildProductInstanceCode";
import {
    productHistoryInitialize,
    productHistoryReducer,
    setGraphMode,
    setHistoryItems,
    setLoading
} from "./product_history_reducer";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import SelectedSgtinChipArray from "./SelectedSgtinChipArray";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HistoryIcon from '@material-ui/icons/History';
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import ProductHistoryViewV6 from "./ProductHistoryViewV6";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import _ from 'lodash'
import {buildInstanceCode} from "../../utils/CodesUtils";
import {useTranslation} from "react-i18next";
import TimelineIcon from "@material-ui/icons/Timeline";
import ListIcon from "@material-ui/icons/List";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {Grow, InputLabel} from "@material-ui/core";
import FieldInfo from "../generate_new_event/FieldInfo";
import i18next from "../../i18n/i18next";
import {fetchProductHistoryInfo, severityTypes} from "../../utils/Utils";

const _ProductHistoryV6 = () => {
    const {t} = useTranslation();

    const searchTypes = [t('productHistory.searchSchemas.fullCode'), t('productHistory.searchSchemas.advanced')]

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        stickyComponent: {
            position: 'sticky',
            top: 75,
            bottom: 60,
            zIndex: 1010,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            minHeight: '200px',
        },
        icon: {
            color: 'darkgray',
            '&:hover': {
                background: "#e0e0e0",
            },
        },
        iconGraphMode: {
            color: accentColor,
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        date: {
            fontSize: '0.725rem',
        },
        heading: {
            color: primaryColor
        },
        dottedDiv: {
            border: '2px solid lightGray',
            borderRadius: '8px',
            borderStyle: 'dotted',
        }

    }));
    const classes = useStyles()

    const [{data: productClasses, loading: loadingProductClasses, error},] = useAxios({
            url: "api/capture/epcis_events/_product_classes"
        },
        {useCache: false}
    )

    const [state, dispatch] = useContext(ProductHistoryContext)
    const {loading, selectedSgtins, historyItems, truncatedHistoryItems, gtinNameLut,
        graphMode, graphType, historyDirections, maxChildren,
        selectedGtin, serialType, serialNumber} = state
    const [searchType, setSearchType] = useState(searchTypes[0])
    const [instanceCodeInputValue, setInstanceCodeInputValue] = useState('')

    const initialErrorState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openErrorMessage, setOpenErrorMessage] = useState(initialErrorState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorMessage({...openErrorMessage, open: false})
    };

    const handleChangeSchema = (event) => {
        setSearchType(event.target.value)
    }

    const handleInstanceCodeInputChange = (event) => {
        event.persist();

        setTimeout(() => {
            setInstanceCodeInputValue(event.target.value)
        }, 700);
    }

    const searchSgtin = async (sgtins) => {
        console.log("sgtins:",sgtins)
        dispatch(setLoading(true))
        const forward = (historyDirections === 'all' || historyDirections === 'forward')
        const backward = (historyDirections === 'all' || historyDirections === 'backward')
        const truncate = (graphMode && graphType === 'dag'/* || maxChildren === 'all'*/)
        //const t0 = performance.now()

        const history = await fetchProductHistoryInfo(sgtins, forward, backward, truncate, maxChildren, setOpenErrorMessage)
        if (history) {
            if (history.data.items.length !== 0) {
                // TODO SERVE FIX DELL'API nei found_whats !!!
                /*if (_.some(history.data.found_whats, function (o) {
                    return !o
                })) {
                    setOpenErrorMessage({
                        open: true,
                        message: i18next.t('productHistory.messages.productNotFound'),
                        severity: 'error'
                    })
                }*/

                const foundSgtins = _.filter(sgtins, function (o) {
                    return true//history.data.found_whats[o]
                })

                dispatch(setHistoryItems(history.data, foundSgtins, truncate, maxChildren, null, null, false))
            } else {
                setOpenErrorMessage({
                    open: true,
                    message: t('productHistory.messages.eventsNotFound', "No events found for this item!"),
                    severity: severityTypes.WARNING
                })
                dispatch(setLoading(false))
            }
        } else dispatch(setLoading(false))
        //const t1 = performance.now()
        //console.log("searchSgtin --->", t1-t0)
    }

    const handleSearchClick = async () => {
        let _sgtin = ''
        switch (searchType) {
            case searchTypes[0]:
                _sgtin = instanceCodeInputValue ? instanceCodeInputValue.trim() : ''
                break;
            case searchTypes[1]:
                //_sgtin = buildSgtin(serialType, selectedGtin[0], serialNumber).trim()
                _sgtin = await buildInstanceCode(false, serialType, selectedGtin[0], productClasses.gs1key_prefixes[selectedGtin[0]], serialNumber, setOpenErrorMessage)
                break;
            default:
                console.log("handleSearch -> unknown action")
                return
        }

        if (!selectedSgtins.includes(_sgtin)) {
            await searchSgtin([...selectedSgtins, _sgtin])
        } else {
            setOpenErrorMessage({
                open: true,
                message: _sgtin ? t('productHistory.messages.codeAlreadyPresent') : t('productHistory.messages.insertCode'),
                severity: severityTypes.WARNING
            })
        }
    }

    const handleGraphModeChange = async () => {
        const forward = (historyDirections === 'all' || historyDirections === 'forward')
        const backward = (historyDirections === 'all' || historyDirections === 'backward')
        const truncate = (graphType === 'dag'/* || maxChildren === 'all'*/) && !graphMode
        if(graphType === 'dag') {
            dispatch(setLoading(true))
            const history = await fetchProductHistoryInfo(selectedSgtins, forward, backward, truncate, maxChildren, setOpenErrorMessage)
            if (history && history.data.items.length !== 0) {
                //setGraphMode(!graphMode);
                dispatch(setHistoryItems(history.data, selectedSgtins, truncate, maxChildren, !graphMode, null, false))
            } else {
                dispatch(setLoading(false))
            }
        } else {
            dispatch(setGraphMode(!graphMode))
        }
    };

    const handleGraphTypeChange = async (event, newType) => {
        const forward = (historyDirections === 'all' || historyDirections === 'forward')
        const backward = (historyDirections === 'all' || historyDirections === 'backward')

        dispatch(setLoading(true))

        const truncate = (graphMode && newType === 'dag'/* || maxChildren === 'all'*/)

        const history = await fetchProductHistoryInfo(selectedSgtins, forward, backward, truncate, maxChildren, setOpenErrorMessage)
        if (history && history.data.items.length !== 0) {
            if (newType !== null) {
                //setGraphType(newType);
                dispatch(setHistoryItems(history.data, selectedSgtins, truncate, maxChildren, null, newType, false))
            }
        } else dispatch(setLoading(false))
    };

    const handleHistoryDirectionChange = async (event, directions) => {
        if (directions !== null) {
            dispatch(setLoading(true))

            const forward = directions === 'all' || directions === 'forward'
            const backward = directions === 'all' || directions === 'backward'
            const truncate = (graphType === 'dag'/* || maxChildren === 'all'*/ && graphMode)

            const history = await fetchProductHistoryInfo(selectedSgtins, forward, backward, truncate, maxChildren, setOpenErrorMessage)
            if (history && history.data.items.length !== 0) {
                if (directions !== historyDirections) {
                    //dispatch(setHistoryDirections(directions))
                    dispatch(setHistoryItems(history.data, selectedSgtins, truncate, maxChildren, null, null, false, directions))
                }
            } else {
                dispatch(setLoading(false))
            }
        }
    };

    const [showLoops, setShowLoops] = useState(true)
    /**
     * PER LOOP
     * const toggleShowLoops = (event) => {
        setShowLoops(event.target.checked);
    };*/

    const handleChangeMaxChildren = async (event) => {
        const forward = (historyDirections === 'all' || historyDirections === 'forward')
        const backward = (historyDirections === 'all' || historyDirections === 'backward')

        dispatch(setLoading(true))

        const history = await fetchProductHistoryInfo(selectedSgtins, forward, backward, event.target.value !== 'all', event.target.value, setOpenErrorMessage)
        if (history && history.data.items.length !== 0) {
            dispatch(setHistoryItems(history.data, selectedSgtins, true/*event.target.value !== 'all'*/, event.target.value, null, null, false))
        } else {
            dispatch(setLoading(false))
            setOpenErrorMessage({
                open: true,
                message: t('productHistory.messages.eventsNotFound', "No events found for this item!"),
                severity: severityTypes.WARNING
            })
        }
    };

    return (
        <div className={classes.root}>
            <Box pl={2} pr={2} /*className={classes.stickyComponent}*/>
                <Accordion
                    defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        style={{display: 'flex', cursor: 'pointer'}}
                        className={classes.headingFilter}>
                        <HistoryIcon style={{color: primaryColor, marginRight: '3%'}}/>
                        <Typography className={classes.heading}>{t('productHistory.label')}</Typography>
                    </AccordionSummary>
                    <Box pb={2}>
                        <Grid container spacing={1} direction="row" style={{width: '100%', alignItems: "baseline"}}>
                            <Grid item xs={11} style={{display: "flex", alignItems: 'center'}}>
                                <div style={{paddingLeft: '5%'}}>
                                    <Select
                                        displayEmpty
                                        value={searchType}
                                        onChange={handleChangeSchema}>
                                        {
                                            searchTypes.map((schema) => (
                                                <MenuItem value={schema} key={schema}>{schema}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText>{t('productHistory.searchSchemas.selectSearchSchemas')}</FormHelperText>
                                </div>
                                {
                                    searchType === searchTypes[0] ?
                                        <Box p={3} style={{flex: 1, marginLeft: '2%'}} className={classes.dottedDiv}>
                                            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                                <FieldInfo description={i18next.t('productHistory.messages.prefixExample', 'e.g. urn:epc:id:sgtin:1234567.123456.123 or http://producer_domain.com.xtap.info/codes/obj/12345.123')}/>
                                                <FormControl
                                                    variant="outlined"
                                                    style={{flex: 2, marginRight: '3%'}}
                                                    error={!instanceCodeInputValue}>
                                                    <TextField
                                                        variant={"outlined"}
                                                        fullWidth={true}
                                                        size={'small'}
                                                        placeholder={`e.g. urn:epc:id:sgtin:1234567.123456.1234`}
                                                        label={t('entities.product.insertItemSerialCode')}
                                                        onChange={handleInstanceCodeInputChange}
                                                    />
                                                    <FormHelperText>{t('entities.product.insertItemSerialCode', {context: 'extended'})}</FormHelperText>
                                                </FormControl>
                                            </div>
                                        </Box>
                                        : productClasses ?
                                        <BuildProductInstanceCode productClasses={productClasses}/>
                                        : loadingProductClasses ? <CustomLoader size={80}/>
                                            : error ? <p>{t('errors.genericServerError')}</p> : null
                                }
                            </Grid>
                            <Grid item xs={1} style={{flex: 'inherit'}}>
                                <IconButton
                                    edge={"end"}
                                    onClick={handleSearchClick}
                                    disabled={
                                        (searchType === searchTypes[1] && !(serialType && selectedGtin && serialNumber))
                                        || (searchType === searchTypes[0] && !instanceCodeInputValue)
                                    }>
                                    <SearchIcon style={{color: primaryColor}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Accordion>
                {
                    selectedSgtins.length > 0 ?
                        <SelectedSgtinChipArray graphModeType={graphMode && graphType === 'dag'} gtinNameLut={gtinNameLut}/> : null
                }
            </Box>
            <Box mr={2} display={'flex'} justifyContent={'flex-end'}>
                {
                    selectedSgtins.length > 0 &&
                    (historyItems || truncatedHistoryItems) ?
                        <Box display={'flex'} alignItems={'center'}>
                            <Grow in={graphMode} mountOnEnter>
                                <Box display={'flex'} alignItems={'center'} mr={2} zIndex={1000}>
                                    {graphType === 'dag' ?
                                        <FormControl variant="outlined" size={'small'} style={{minWidth: 200, marginRight: '8px'}}>
                                            <InputLabel>{t('productHistory.maxNumberOfChildren', "Max number of children")}</InputLabel>
                                            <Select
                                                value={maxChildren}
                                                onChange={handleChangeMaxChildren}
                                                label={t('productHistory.maxNumberOfChildren', "Max number of children")}>
                                                {/*<MenuItem value="all">
                                                    <em>{t('productHistory.showAllChildren', "Show All")}</em>
                                                </MenuItem>*/}
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                            </Select>
                                        </FormControl>: null}
                                    <ToggleButtonGroup value={historyDirections}
                                                       onChange={handleHistoryDirectionChange}
                                                       exclusive
                                                       size={"small"}
                                                       aria-label="history-dir">
                                        <ToggleButton value="backward" aria-label="backward">
                                            {t('productHistory.historyDirection.backward', 'Show Backward')}
                                        </ToggleButton>
                                        <ToggleButton value="all" aria-label="all">
                                            {t('productHistory.historyDirection.all', 'Show all')}
                                        </ToggleButton>
                                        <ToggleButton value="forward" aria-label="forward">
                                            {t('productHistory.historyDirection.forward', 'Show Forward')}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <ToggleButtonGroup value={graphType}
                                                       onChange={handleGraphTypeChange}
                                                       exclusive
                                                       size={"small"}
                                                       aria-label="graph-type" style={{margin: '8px'}}>
                                        <ToggleButton value="sankey" aria-label="sankey">
                                            {t('productHistory.graphDetail.grouping', 'Grouping')}
                                        </ToggleButton>
                                        <ToggleButton value="dag" aria-label="dag">
                                            {t('productHistory.graphDetail.serial', 'Detail')}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Grow>
                            <Box display={'flex'} alignItems={'center'} zIndex={1000}>
                                <IconButton
                                    style={{color: primaryColor}}
                                    onClick={handleGraphModeChange}>
                                    {!graphMode ? <TimelineIcon className={classes.iconGraphMode}/> :
                                        <ListIcon className={classes.iconGraphMode}/>}
                                </IconButton>
                                <Typography
                                    style={{color: primaryColor}}>
                                    {!graphMode ? t('entities.viewMode.graph') : t('entities.viewMode.table')}
                                </Typography>
                            </Box>
                        </Box> : null
                }
            </Box>

            {
                selectedSgtins.length > 0 &&
                (historyItems || truncatedHistoryItems) ?
                    <ProductHistoryViewV6
                        searchSgtin={searchSgtin}
                        showLoops={showLoops}
                    />
                    : loading && <CustomLoader size={80}/>
            }
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={openErrorMessage.open}
                      autoHideDuration={4000} onClose={handleCloseErrorMessage}>
                <Alert onClose={handleCloseErrorMessage}
                       severity={openErrorMessage.severity}>{openErrorMessage.message}</Alert>
            </Snackbar>
        </div>
    )
}

const ProductHistoryV6 = () => {
    return (
        <ProductHistoryProvider reducer={productHistoryReducer} init={productHistoryInitialize}>
            <_ProductHistoryV6/>
        </ProductHistoryProvider>
    )
}

export default ProductHistoryV6
