import React, {useContext, useState} from "react";
import {Box} from "@material-ui/core";
import {CreateEventContext} from "../../contexts";
import {eventFieldNameTypes, inputTypes} from "../../utils/CbvUtils";
import _ from 'lodash'
import TimeComponent from "./when/TimeComponent";
import UtcOffsetComponent from "./when/UtcOffsetComponent";

const EventWhenComponent = ({templateData}) => {

    const eventContext = useContext(CreateEventContext);

    const [state, setState] = useState({
        [eventFieldNameTypes.EVENT_TIME.name]: eventContext.getFormField('when', eventFieldNameTypes.EVENT_TIME.name) ?
            eventContext.getFormField('when', eventFieldNameTypes.EVENT_TIME.name).value : null,
        [eventFieldNameTypes.RECORD_TIME.name]: eventContext.getFormField('when', eventFieldNameTypes.RECORD_TIME.name) ?
            eventContext.getFormField('when', eventFieldNameTypes.RECORD_TIME.name).value : null,
        [eventFieldNameTypes.TZ_OFFSET.name]: eventContext.getFormField('when', eventFieldNameTypes.TZ_OFFSET.name) ?
            eventContext.getFormField('when', eventFieldNameTypes.TZ_OFFSET.name).value : null,
    })

    return (
        <div>
            {
                _.filter(templateData.when, _.overSome([['input', inputTypes.EXTERNAL.value], ['input', inputTypes.CLIENT_COMPUTED.value]])).map((field, index) => (
                    <Box pb={2} key={index}>
                        {
                            field.name !== eventFieldNameTypes.TZ_OFFSET.name ?
                                <TimeComponent field={field} state={state} setState={setState}/>
                                : <UtcOffsetComponent field={field} state={state} setState={setState}/>
                        }
                    </Box>))
            }
        </div>
    )
}

export default EventWhenComponent