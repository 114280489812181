import React from 'react'
import Box from "@material-ui/core/Box";
import {Divider, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import i18next from "./i18n/i18next";
import Button from "@material-ui/core/Button";

class ErrorFallback extends React.Component {

    cleanLS() {
        localStorage.clear()
        window.location.reload()
    }

    render()
    {
        return <Box p={3} display={'flex'} flexDirection={'column'}>
            <Paper style={{borderRadius: '8px'}}>
                <Box display={'flex'} justifyContent={'center'} flexWrap={'wrap'}>
                    <img src="/assets/ops_something_wrong.png"
                         style={{width: '18%', objectFit: 'contain', opacity: '40%'}} alt={''}/>
                    <Box p={4} display={'grid'} justifyContent={'center'}>
                        <Typography gutterBottom variant={'h2'} align={'center'}
                                    style={{color: 'darkgrey'}}>Ops!</Typography>
                        <Typography gutterBottom variant={'h3'} align={'center'} style={{color: '#9aabc0'}}>
                            {i18next.t('errors.somethingWentWrong', 'Something went wrong...')}
                        </Typography>
                        {/*<pre>{error.message}</pre>*/}
                        <Divider/>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-evenly'}>
                            <Button fullWidth onClick={() => {
                                window.location.reload()
                            }}>{i18next.t('actions.retry', 'Retry')}</Button>
                            <Button fullWidth
                                    onClick={this.cleanLS}>{i18next.t('actions.clearLocalStorageAndRetry', 'Clear local storage and Retry')}</Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <img src="/assets/logo_iChain.png" style={{width: '120px', marginLeft: 'auto'}} alt={''}/>
        </Box>
    }

}

export default class AppErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("componentDidCatch -->",error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorFallback/>;
        }

        return this.props.children;
    }
}