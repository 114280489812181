import React, {useContext} from "react";
import {ProductHistoryContext} from "../../contexts";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import ProductHistoryTableV6 from "./ProductHistoryTableV6";
import D3DagHistoryGraph from "./d3_dag_graph/D3DagHistoryGraph";
import D3SankeyHistoryGraph from "./sankey_graph/D3SankeyHistoryGraph";
import CustomLoader from "../../components/CustomLoader";

const ProductHistoryViewV6 = (props) => {
    const {searchSgtin, showLoops} = props
    const [state] = useContext(ProductHistoryContext)
    const {loading, selectedSgtins, graphMode, graphType} = state

    return (
        <Box>
            <Box pl={2} pr={2} mt={4}>
                {
                    loading ? <CustomLoader size={80}/> :
                    !graphMode ?
                        <Paper>
                            <Box p={2} style={{width: '100%'}}>
                                <ProductHistoryTableV6/>
                            </Box>
                        </Paper>
                        : graphType === 'dag' ?
                        <D3DagHistoryGraph
                            selectedSgtins={selectedSgtins}
                            searchSgtin={searchSgtin}/>
                        : graphType === 'sankey' ?
                                <D3SankeyHistoryGraph
                                selectedSgtins={selectedSgtins}
                                searchSgtin={searchSgtin}
                                showLoops={showLoops}
                                /> : null
                }
            </Box>
        </Box>
    )
}

export default ProductHistoryViewV6
