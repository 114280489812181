import React, {useContext, useEffect, useRef} from "react";
import {MarbleContext, ThemeContext} from "../../contexts";
import * as d3 from "d3";
import dagreD3 from "dagre-d3";
import {computeTimeSlot, getSliderLabel, getTimeCluster} from "./Time";
import "./marble-style.css";
import {setExpandedSublinks, setFilterProducts, setShowGraph} from "./marble_reducer";
import {bizStepIconPathMap} from "../../components/CustomBizStepIcons";
import chroma from "chroma-js";
import d3Tip from 'd3-tip'
import _ from 'lodash'
import SpeedDials from "./SpeedDialMarbleActions";
import EditTag from "../../components/tag_management/EditTag";
import {findCodeTypeAndValue} from "../../utils/CodesUtils";
import {useTranslation} from "react-i18next";
import {aggregationTypes} from "./MarbleUtility";

/**
 * Icona occhietto
 * M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z
 * */
//const eyePath = "M 16.8 6.3 C 9.8 6.3 3.822 10.654 1.4 16.8 c 2.422 6.146 8.4 10.5 15.4 10.5 s 12.978 -4.354 15.4 -10.5 c -2.422 -6.146 -8.4 -10.5 -15.4 -10.5 z M 16.8 23.8 c -3.864 0 -7 -3.136 -7 -7 s 3.136 -7 7 -7 s 7 3.136 7 7 s -3.136 7 -7 7 z m 0 -11.2 c -2.324 0 -4.2 1.876 -4.2 4.2 s 1.876 4.2 4.2 4.2 s 4.2 -1.876 4.2 -4.2 s -1.876 -4.2 -4.2 -4.2 z"

function Graph({width, nodes, links, fitBoundaries, zoomable, animate,
                   onParentClick, onNodeClick, onLinkClick, onEventClick, onExpandClick, onDialogOpen }) {
    const {t} = useTranslation()
    const [state, dispatch] = useContext(MarbleContext)
    const {aggregationType, timeScale} = state
    const {primaryColor, accentColor} = useContext(ThemeContext)
    console.log("Graph---> nodes,:",nodes,"links:",links)

    const svgRef = useRef(null)
    const innerGRef = useRef(null)
    const centralBin = computeTimeSlot(state.timeScale)(state.date)
    let labels = [-3,-2,-1,0,1,2,3].map(b => String(centralBin+b))
    const maxLinkSize = Math.max(...links.map(l=>l.eventsCount))

    /** per tag controller --------------------- */
    const [openTagProductDialog, setOpenTagProductDialog] = React.useState({open: false, product: null});

    const handleClickOpen = (gtin) => {
        setOpenTagProductDialog({open: true, product: state.products[gtin]});
    }

    const handleClose = () => {
        setOpenTagProductDialog({open: false, product: null});
    };
    /**----------------------------------------- */

    /** COSTRUZIONE GRAFICO CON D3 */
    useEffect(() => {
        const svg = d3.select(svgRef.current)
        const inner = d3.select(innerGRef.current)
        inner.selectAll("*").remove()
        const render = new dagreD3.render()

        render.shapes().custom = function(parent, bbox, node) {
            const w = 270 // per centrare le barrette dei clusteredEvents e farle tutte regolari
            const h = 180
            const g = parent.insert("g", ":first-child")
            g.append("rect")
                .attr('x', (-bbox.width*1.2) / 2)
                .attr('y', -bbox.height / 2 + 20)
                .attr('width', bbox.width*1.25)
                .attr('height', bbox.height)

            let max=1;
            labels.forEach( l=> {
                let len = ((node.clusteredEvents && node.clusteredEvents[state.timeScale][l])||[]).event_count
                if (len>max) max=len;
            })
            labels.forEach((l,i) => {
                let clusteredEventElement = node.clusteredEvents && node.clusteredEvents[state.timeScale][l]
                let x = (i-3)*w*0.12
                console.log("cluster", clusteredEventElement)
                if (clusteredEventElement) {
                    const timeLabel = getSliderLabel(getTimeCluster(+l, timeScale)[0], timeScale);
                    /* Initialize tooltip */
                    const tip = d3Tip()
                        .attr('class', 'd3-tip')
                        .offset([20,0])
                        .html(function(d) {
                            return `<div class="tooltip tooltipCluster">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <p>${timeLabel}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p>${t("productionFlow.clusterTooltip.eventsCount", {count: clusteredEventElement.event_count})}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p>${t("productionFlow.clusterTooltip.itemsCount", {count: clusteredEventElement.items_count})}</p>
                                    </Grid>
                                </Grid>
                            </div>`;
                        })
                    g.append('rect')
                        .attr('class', 'clusteredEventsBar')
                        .attr('x',x-w*0.04)
                        .attr('y',h/3-w*0.2*clusteredEventElement.event_count/max-2 + 20)
                        .attr('width', w*0.08)
                        .attr('height', w*0.2*clusteredEventElement.event_count/max+2)
                        .attr('cursor', !node.isParent ? 'pointer' : 'default')
                        .call(tip) // Invoke the tip in the context of your visualization
                        .on('mouseover', tip.show)
                        .on('mouseout', function() {
                            d3.selectAll(".tooltip")
                                .transition()
                                .duration(100)
                                .style("opacity",0)
                                .style('pointer-events', 'none')
                        })
                        .on("click", (event) => {
                            window.event.stopPropagation()
                            //console.log("node:",node)
                            if(!node.isParent) {
                                const events = {nodeSignature: node.id, groupBy: state.timeScale, timeSlot: l, label: node.label}
                                onEventClick({type: 'events', events})
                            }
                            d3.selectAll(".tooltip")
                                .transition()
                                .duration(100)
                                .style("opacity",0)
                                .style('pointer-events', 'none')
                        }).append('title')
                }
            })

            const wEach = w/node.products.length
            node.products.forEach((gtin, index) => {
                const message = t('productionFlow.addTagControllerMessage')
                const [type, codeValue, domain] = findCodeTypeAndValue(gtin)
                /* Initialize tooltip */
                let tip = d3Tip()
                    .attr('class', 'd3-tip')
                    .offset([20,0])
                    .html(function(d) {
                    return `<div class="tooltip ${ !_.some(state.filterProducts, ['key', gtin]) ? 'tooltipAddFilter' : 'tooltipRemoveFilter'}"
                                style="background: ${chroma(state.gtinColorMap[gtin]).alpha(0.85)};
                                color: ${chroma(state.gtinColorMap[gtin]).luminance() < 0.45 ? 'white' : 'black'};">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <p>${state.products[gtin].name}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p>${(domain ? domain : type.type) + ': ' + codeValue}</p>
                                    </Grid>
                                </Grid>
                                <p style="font-style: italic; font-weight: bolder">${message}</p>
                            </div>`;
                })

                const stroke_width = 15

                g.append('path')
                    .attr('class', 'productsLine')
                    .attr('stroke', state.gtinColorMap[gtin])
                    .attr('stroke-width',
                        _.findIndex(state.filterProducts, ['key', gtin]) === -1
                        ? stroke_width : stroke_width*1.5)
                    .attr('cursor', 'pointer')
                    .attr('d', d3.line()([[(index * wEach) - (w*0.5), h-60 + 25], [((index+1) * wEach) - (w*0.5), h-60 + 25]]))
                    .call(tip) // Invoke the tip in the context of your visualization
                    .on('mouseover', tip.show)
                    .on('mouseout', function() {
                        d3.selectAll(".tooltip")
                            .transition()
                            .duration(100)
                            .style("opacity",0)
                            .style('pointer-events', 'none')
                    })
                    .on("click", ()=> {
                        /** GESTURE ALT+CLICK -> per aggiungere tag al prodotto
                         * */
                        if (window.event.altKey) {
                            handleClickOpen(gtin)
                        } else {
                            const gtins = []

                            let gtinPulled = _.remove(state.filterProducts, function(o) {return o.key === gtin})
                            if(gtinPulled.length === 0) {
                                gtins.push({key: gtin, name: state.products[gtin].name})
                            }
                            let newSelection = _.concat(state.filterProducts, gtins);
                            localStorage.setItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_filterProducts`, JSON.stringify(newSelection))

                            dispatch(setFilterProducts(newSelection))

                            // pulisci dal tooltip
                            d3.selectAll(".tooltip")
                                .transition()
                                .duration(100)
                                .style("opacity",0)
                                .style('pointer-events', 'none')
                        }

                        window.event.stopPropagation()
                    })
            })

            if(node.isParent) {
                g.attr('class', 'parentNode')
                g.attr('cursor', 'pointer')
                g.attr('fill', node.isLoggedUser? accentColor : primaryColor)
            } else {
                g.attr('class', 'childNode')
                g.attr('fill', node.isLoggedUser? accentColor : /*primaryColor*/'#4377b6')

                g.append('circle')
                    .attr('fill', node.isLoggedUser ? accentColor : '#4377b6')
                    .attr('stroke', 'none')
                    .attr('cx', (bbox.width)/3)
                    .attr('cy', -100 + 20)
                    .attr('r', 48)
                const bizStepIcon = g.append("svg")
                    .attr("width", 50)
                    .attr("height", 50)
                    .attr('view-box', "0 0 50 50")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr('x', (bbox.width)/3 - 25)
                    .attr('y', -140 + 15 + 20)

                bizStepIcon.append('path')
                    .attr('class', 'bizStepIcon')
                    .attr("d", bizStepIconPathMap[node.bizStep])
            }

            node.intersect = function(point) {
                return dagreD3.intersect.rect(node, point)
            }
            parent
                .select('.label')
                .attr("transform",`translate(0,${-h/4})`)

            return g;
        }

        const g = new dagreD3.graphlib.Graph({compound:true})
            .setGraph({ranksep: 120, nodesep: 40, edgesep: 40})
            .setDefaultEdgeLabel(() => ({}))
        nodes.forEach( node => {
            g.setNode(node.id, {
                label: aggregationType === aggregationTypes.where ?
                    (!state.companyNameLut.company_name[node.id] && !state.companyNameLut.loc_name[node.id]) ? node.parent
                        : `${state.companyNameLut.company_name[node.id]||''}${'\n('+(state.companyNameLut.loc_name[node.id]||'no readpoint info')+')'}`
                    : node.parent ?
                        `${state.companyNameLut.company_name[node.parent]||''}${'\n('+(state.companyNameLut.loc_name[node.parent]||'no readpoint info')+')'}`
                        : node.id,

                labelStyle: "font-size: 2.2em; padding:50px",
                class: node.class || '',
                labelType: node.labelType || 'string',
                shape: 'custom',
                id: node.id,
                bizStep: node.biz_step,
                isParent: !node.parent,
                isLoggedUser: node.loggedUser,
                clusteredEvents: node.clusteredEvents,
                products: node.products,
                //width: 600, // commentare per avere la width:fit-content
                height: 190,
                ...node.config
            })
        },[])
        nodes.forEach( node => {
            if (node.label) {
                g.setNode(node.label, {
                    label: aggregationType === aggregationTypes.where ?
                        `${state.companyNameLut.company_name[node.label]||''}${'\n('+(state.companyNameLut.loc_name[node.label]||'no info')+')'}`
                        : node.label,
                    labelStyle: "font-size: 2.3em; padding:50px",
                    clusterLabelPos: 'top',
                    isParent: !node.label,
                    style: node.label && node.label === state.lastExpandedNode ?
                        'stroke: indianred; fill: #cd5c5c21; stroke-width: 3'
                        : null
                })
                g.setParent(node.id, node.label)
            }
        })

        links.forEach( link => {
            let expandedSubLink = false

            if(link.subGraphLink) {
                const sublinkId = `${link.originalSource}-->${link.originalTarget}`
                expandedSubLink = state.expandedSublinks[sublinkId] && state.expandedSublinks[sublinkId].expanded
            }

            g.setEdge( link.source, link.target, {
                label: link.subGraphLink ? expandedSubLink ? "\u2296" : '\u2295' : '', // circled-plus e -minus
                labelStyle: /*"font-style: italic; text-decoration: underline;"*/
                    `cursor: pointer; font-size:65px; position: absolute; margin-left:15px; color: ${primaryColor};`,
                class: link.class || '',
                style: link.subGraphLink ?
                    `stroke-width: 6;
                    fill:none;
                    stroke: ${expandedSubLink ? 'lightcoral' : primaryColor};
                    stroke-dasharray: ${expandedSubLink ? '6, 12' : '20, 15'};
                    cursor: pointer`
                : `stroke-width: ${Math.pow(link.eventsCount/maxLinkSize,0.25)*7+1}; fill:none`,
                curve: link.subGraphLink && expandedSubLink ? d3.curveLinear : d3.curveBasis,
                arrowhead: link.subGraphLink && expandedSubLink ? "undirected" : "vee",
                ...link.config
            })
        })

        let zoom = d3.zoom().on('zoom',
            (event) => inner.attr('transform', event.transform)
        )

        if (zoomable) {
            svg.call(zoom)
        }
        if (animate) {
            g.graph().transition = function transition(selection) {
                return selection.transition().duration(animate || 1000)
            }
        }

        render(inner,g)

        if (onParentClick) {
            svg.selectAll('g.cluster').on('click', (e,id) => {
                //console.log('onParentClick ----> id:',id)
                onParentClick({ type: 'parent', parentId: id})
            })
        }
        if (onNodeClick) {
            svg.selectAll('g.node').on('click', (e,id) => {
                const _original = nodes.find( node => node.id === id)
                //console.log('onNodeClick ----> _original',_original)
                onNodeClick({ type: 'node', node: _original })
            })
        }
        if (onLinkClick) {
            svg.selectAll('g.edgeLabel, g.edgePath').on('click', (e,id) => {
                //console.log("id:",id)
                const linkId = `${id.v}-->${id.w}`
                const link = links.find( l => l.id === linkId)
                //console.log("link:",link)

                if(link.subGraphLink) {
                    /** TODO: espansione subLink */
                    /*onLinkClick({
                        type: 'link',
                        link
                    })*/
                    //dispatch(setNodeData(link.children))
                    dispatch(setExpandedSublinks(link))
                }
            })
        }
        if (onExpandClick) {
            svg.selectAll('g.node').on('click', (e,id) => {
                const _original = nodes.find( node => node.id === id)
                if((_original.children && Object.keys(_original.children).length < 10 && aggregationType === aggregationTypes.why)
                    || aggregationType === aggregationTypes.where) {
                    dispatch(setShowGraph(false))
                    setTimeout(function () {
                        dispatch(setShowGraph(true))
                        onExpandClick(id)
                    }, 200);
                } else if(_original.children && Object.keys(_original.children).length >= 10 && aggregationType === aggregationTypes.why) {
                    onDialogOpen({label: _original.id, children: _original.children})
                }
            })
        }
    },[centralBin, state.timeScale, nodes, links])

    function centerFitBoundaries(scaleFactor) {
        const svg = d3.select(svgRef.current)
        const inner = d3.select(innerGRef.current)
        let zoom = d3.zoom().on('zoom',
            (event) => inner.attr('transform', event.transform)
        )

        if (fitBoundaries) {

            //@BertCh recommendation for fitting boundaries
            const bounds = inner.node().getBBox()
            const parent = inner.node().parentElement || inner.node().parentNode
            const fullWidth = parent.clientWidth || parent.parentNode.clientWidth
            const fullHeight = parent.clientHeight || parent.parentNode.clientHeight
            const width = bounds.width
            const height = bounds.height
            const midX = bounds.x + width / 2
            const midY = bounds.y + height / 2
            if (width === 0 || height === 0) return // nothing to fit

            //let scaleFactor = 0.9
            const scale = scaleFactor / Math.max(width / fullWidth, height / fullHeight)
            const translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY]
            const transform = d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)

            svg
                .transition()
                .duration(animate || 0) // milliseconds
                .call(zoom.transform, transform)
        }
    }

    useEffect(() => {
        centerFitBoundaries(0.9);
    }, [])

    return (
        <div>
            <SpeedDials centerFitBoundaries={centerFitBoundaries} numberOfNodes={nodes.length}/>
            <svg ref={svgRef} width={width} height={'100%'}>
                <g ref={innerGRef} />
            </svg>
            {
                openTagProductDialog.product?
                    <EditTag editable={true} object={openTagProductDialog} handleClose={handleClose}/>
                    : null
            }
        </div>

    )
}

export default Graph
