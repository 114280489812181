import _ from "lodash";

export const SET_MODELS = 'restore_elements'
export const SET_MODELS_NAMES = 'restore_elements'
export const RESTORE_ELEMENTS = 'restore_elements'
export const SET_NODE_DOMAIN = 'set_node_domain'
export const SET_NODE_MODEL = 'set_node_model'
export const SET_GRAPH_PLOT_INFO = 'set_graph_plot_info'
export const UPDATE_GRAPH_NODE_INFO = 'update_graph_node_info'

export function setModels(models) {
    return {type: SET_MODELS, models}
}

export function restoreElements(projectName, elements) {
    return {type: RESTORE_ELEMENTS, projectName, elements}
}

/** settano node domain e node model nella sidebar */
export function setNodeDomain(nodeDomain) {
    return {type: SET_NODE_DOMAIN, nodeDomain}
}

export function setNodeModel(nodeModel) {
    return {type: SET_NODE_MODEL, nodeModel}
}
/** --------------------------------------- */

export function setGraphPlotInfo(graphPlot) {
    return {type: SET_GRAPH_PLOT_INFO, graphPlot}
}

export function updateGraphNodeInfo(updatedGraphNode) {
    return {type: UPDATE_GRAPH_NODE_INFO, updatedGraphNode}
}

export function configuratorReducer(state, action) {
    switch (action.type) {
        case SET_MODELS:
            console.log("SET_MODELS --> action:",action)
            /** resetta anche il modello selezionato */
            return { ...state, models: action.models, modelsNames: _.map(action.models, (model) => (model.name)) }
        case RESTORE_ELEMENTS:
            console.log("RESTORE --> action:",action)
            return { ...state, elements: action.elements }
        case SET_NODE_DOMAIN:
            return { ...state, nodeDomain: action.nodeDomain, nodeModel: null }
        case SET_NODE_MODEL:
            return { ...state, nodeModel: action.nodeModel }
        case SET_GRAPH_PLOT_INFO:
            console.log("SET_GRAPH_PLOT_INFO ---> action:",action)
            return { ...state,
                graphPlotInfo: {
                    _id: action.graphPlot._id,
                    project_name: action.graphPlot.project_name,
                    creator: action.graphPlot.creator,
                    last_editor: action.graphPlot.last_editor,
                    last_update_time: action.graphPlot.last_update_time
                }
            }

        case UPDATE_GRAPH_NODE_INFO:
            console.log("UPDATE_GRAPH_NODE_INFO ---> graphNode:",action.updatedGraphNode)
            return { ...state,
                nodeToUpdate: action.updatedGraphNode
            }
        default:
            console.log("Errore: azione", action.type, "sconosciuta")
            return state;
    }
}