import _ from "lodash";
import {inputTypes, sourceDestinationListInputTypes} from "../../../utils/CbvUtils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FieldInfo from "../FieldInfo";
import TextField from "@material-ui/core/TextField";
import React, {useContext, useState} from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@material-ui/core/Popover";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import SourceDestComponent from "./SourceDestComponent";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const SourceDestListBuilder = ({
                                   places,
                                   disabled,
                                   sourceDestInputType,
                                   field,
                                   setSourceDestList,
                                   control,
                                   inputRef
                               }) => {
    const {t} = useTranslation()
    const eventContext = useContext(CreateEventContext)
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiPopover-paper': {
                width: '60%',
                borderRadius: '8px'
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        sourceDestinationBuilderArea: {
            borderRadius: '12px',
            backgroundColor: 'rgba(245,245,245,0.7)',
            border: '1px solid lightgray',
            borderStyle: 'dotted',
        },
    }));
    const classes = useStyles()

    const fieldName = field.name
    const optional = field.optional
    const validationLength = field.children.length

    const [state, setState] = useState({
        sourceDestValue: null,
        sourceDestInputType: sourceDestInputType ?
            _.find(sourceDestinationListInputTypes, ['value', sourceDestInputType])
            : '',

        locationLabel: '',
        anchorEl: null,
    })

    //const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(state.anchorEl);

    const handleClear = () => {
        let newSourceDestList = _.cloneDeep(eventContext.getFormField('where', fieldName).value) || []
        _.remove(newSourceDestList, function (o) {
            return o[1] === state.sourceDestInputType.value
        })

        eventContext.setFormField('where', fieldName, newSourceDestList)
        if (!optional && newSourceDestList.length !== validationLength) {
            eventContext.setValidationField('where', fieldName, false)
        }
        setState({...state, locationLabel: ''})
    };

    const handleOpenPopover = (event) => {
        if (field.input !== inputTypes.EXTERNAL.value || state.sourceDestInputType)
            setState({...state, anchorEl: event.currentTarget});
    };

    const handleClose = () => {
        setState({...state, anchorEl: null});
    };

    const handleChange = (event) => {
        setState({
            ...state,
            sourceDestInputType: event.target.value,
        })
    }

    return <Box><Box p={2} mb={2} className={classes.sourceDestinationBuilderArea}>
        <Grid spacing={1} container justify={"flex-start"} alignItems={"center"}>
            <Grid item md={4} xs={12} container direction={'row'} justify={"flex-start"} alignItems={"center"}>
                <FormControl fullWidth error={field.input === inputTypes.EXTERNAL.value && !state.sourceDestInputType}>
                    <InputLabel>{t('entities.inputType', 'Type of Input')}</InputLabel>
                    <Select
                        onChange={handleChange}
                        value={state.sourceDestInputType}
                        displayEmpty
                        disabled={disabled}
                    >
                        {
                            _.map(sourceDestinationListInputTypes, (type, index) => (
                                <MenuItem value={type} key={index}>{type.name}</MenuItem>))
                        }
                    </Select>
                </FormControl>
                <FieldInfo description={state.sourceDestInputType?.description}/>
            </Grid>
            <Grid item md={8} xs={12}>
                <TextField fullWidth
                           size={'small'}
                           disabled={field.input === inputTypes.EXTERNAL.value && !state.sourceDestInputType}
                           variant={"outlined"}
                           value={state.locationLabel}
                           label={t('createEvent.locationCodeOfParty', "The location code of the party")}
                           error={!optional &&
                           ((field.input === inputTypes.EXTERNAL.value && !state.sourceDestInputType) || !state.locationLabel)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <IconButton onClick={handleClear}>
                                           <CloseIcon fontSize="small"/>
                                       </IconButton>
                                   </InputAdornment>,
                               readOnly: true
                           }}
                           onClick={handleOpenPopover}
                />
                <Popover
                    className={classes.root}
                    open={open}
                    anchorEl={state.anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <SourceDestComponent places={places}
                                         field={field}
                                         sourceDestInputType={state.sourceDestInputType}
                                         setSourceDestList={setSourceDestList}
                                         state={state} setState={setState}/>
                </Popover>
            </Grid>
        </Grid>
    </Box>
    </Box>
}

export default SourceDestListBuilder