import React, {useEffect, useState} from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Moment from "moment";
import { DateRangePicker } from 'react-date-range';
import {Box, } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Popover from "@material-ui/core/Popover";
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: "none",
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'darkgrey',
        color: 'darkgrey',
        //backgroundColor : 'darkgrey',
        '&:hover': {
            background: "lightgrey",
            color: "whitesmoke"
        },
    },
}));

const CustomDateRangePicker = ({filters, setFilters, setSkip}) => {
    const {t} = useTranslation()

    const classes = useStyles();
    const [, setIsSelected] = useState(false)
    const [selected, setSelected] = useState([
        {
            startDate: new Date(),
            endDate: null,
            color: 'darkgrey',
            key: 'selection'
        }
    ]);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    useEffect(() => {
        if(!filters.start_date || !filters.end_date) {
            setIsSelected(false)
            setSelected([{
                startDate: new Date(),
                endDate: null,
                color: 'darkgrey',
                key: 'selection'
            }])
        }
    }, [filters])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        //console.log('CHIUDO')
        setAnchorEl(null);
    };

    const handleSave = () => {
        if(!setSkip || !setFilters || !filters) {
            handleClose()
            return null
        }
        //console.log('SALVO')
        setAnchorEl(null);
        //let startDate = Moment(selected[0].startDate).format("YYYY-MM-DDThh:mm:ss")
        //let endDate = Moment(selected[0].endDate).format("YYYY-MM-DDThh:mm:ss")
        let startDate = Moment(selected[0].startDate).format("YYYY-MM-DDT00:00:00")
        let endDate = Moment(selected[0].endDate).format("YYYY-MM-DDT23:59:59")

        //console.log('ho start --->', startDate)
        //.log('ho end --->', endDate)

        if(selected[0].endDate !== null) {
            //clearFilterCount++;
            setIsSelected(true)
            setSkip(0)
            setFilters({
                ...filters,
                start_date: startDate ? "start_date=" + startDate : '',
                end_date: endDate ? "end_date=" + endDate : ''
            })
        }
    }

    /*const clearFilter = () => {
        //clearFilterCount = 0;
        setIsSelected(false)
        setSelected([
            {
                startDate: new Date(),
                endDate: null,
                color: 'darkgrey',
                key: 'selection'
            }
        ])
        setSkip(0)
        setFilters({
            ...filters,
            start_date: '',
            end_date: ''
        })
    }*/

    function handlePress(e) {
        e.stopPropagation();
    }

    return (
        <Box onMouseDown={e => handlePress(e)}>
            <IconButton onClick={handleClick}>
                <FilterListIcon fontSize="small" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <DateRangePicker
                    editableDateInputs={true}
                    onChange={item => setSelected([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={selected}
                />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Box p={2} width={1} >
                        <Button fullWidth onClick={handleSave} className={classes.button}>{t('actions.save', {what: t('entities.filter')})}</Button>
                    </Box>
                </Grid>
            </Popover>
            {
                /*
                isSelected ?
                    <IconButton onClick={clearFilter}>
                        <CancelIcon fontSize="small" />
                    </IconButton> : null
               */
            }
        </Box>
    )
}

export default CustomDateRangePicker
