import FormControl from "@material-ui/core/FormControl";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import {setSelectedClassCode, setSerialNumber} from "./product_history_reducer";
import {ProductHistoryContext, ThemeContext} from "../../contexts";
import SerialType from "./SerialType";
import _ from 'lodash'
import {findCodeTypeAndValue} from "../../utils/CodesUtils";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import {useWindowSize} from "../../utils/Utils";
import {useTranslation} from "react-i18next";

const BuildProductInstanceCode = ({productClasses}) => {
    const { t } = useTranslation();

    //console.log("productClasses:",productClasses)
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        dottedDiv: {
            border: '2px solid lightGray',
            borderRadius: '8px',
            borderStyle: 'dotted',
        },
        name: {
            fontSize: '1rem',
        },
        code: {
        fontSize: '0.725rem'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }))
    const classes = useStyles()
    const size = useWindowSize();

    //console.log("productClasses:",_.toPairs(productClasses.names))
    const [state, dispatch] = useContext(ProductHistoryContext)
    const {selectedGtin, serialNumber} = state

    const handleChangeSerialNumber = (event) => {
        //console.log("handleChangeSerialNumber:", event.target.value)
        event.persist();

        setTimeout(() => {
            dispatch(setSerialNumber(event.target.value))
        }, 700);
    }

    return (
        <div style={{flex: 1, marginLeft: '2%'}}>
            <Box p={3} className={classes.dottedDiv}>
                <div style={{display: size.width > 700 ? 'flex' : 'grid'}}>
                    <SerialType />
                    <FormControl
                        variant="outlined"
                        style={{flex: 2}}
                        error={!selectedGtin}>
                        <Autocomplete
                            value={selectedGtin || ''}
                            size={'small'}
                            fullWidth
                            onChange={(event, newValue) => {
                                //console.log("onChange --> newValue:", newValue)
                                dispatch(setSelectedClassCode(newValue));
                            }}
                            getOptionLabel={(option) => {
                                const [, codeValue, domain] = option ? findCodeTypeAndValue(option[0]) : [null, null, null]
                                return (option ? option[1] + ' - ' + (domain ? domain +':' : '') + codeValue : '')
                            }}
                            options={_.toPairs(productClasses.names)/*[["urn:gs1:gtin14:01639070442961", "carcassa"], ['http://main.spinnato.com.xtap.info/codes/class/1', 'internal']]*/}
                            renderOption={(option, {selected}) => {
                                const [codeType, codeValue, domain] = option ? findCodeTypeAndValue(option[0]) : [null, null, null]
                                return (
                                <React.Fragment>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography className={classes.name}>{option ? option[1] : ''}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                            {
                                                codeType ?
                                                    <Chip
                                                        size={'small'}
                                                        label={codeType.type}
                                                        className={classes.codeTypeChip}
                                                    /> : null
                                            }
                                            {
                                                domain ?
                                                    <Chip
                                                        size={'small'}
                                                        label={domain}
                                                        className={classes.codeTypeChip}
                                                    /> : null
                                            }
                                                <Typography className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}}
                            renderInput={(params) => <TextField {...params} label={t('entities.product.productClass')} variant="outlined"/>}
                        />
                        <FormHelperText>{t('entities.product.selectProductClass')}</FormHelperText>
                    </FormControl>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: '2%'}}>
                    <FormControl
                        variant="outlined"
                        style={{flex: 1}}
                        error={!serialNumber}>
                        <TextField
                            variant={"outlined"}
                            fullWidth
                            size={'small'}
                            placeholder={"e.g. 12345678901234567890"}
                            label={t('entities.product.serialNumber')}
                            //value={serialNumber || ''}
                            onChange={handleChangeSerialNumber}
                        />
                        <FormHelperText>{t('entities.product.insertItemSerialNumber')}</FormHelperText>
                    </FormControl>
                </div>
            </Box>
        </div>
    )
}

export default BuildProductInstanceCode