import React, {useContext, useMemo, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Button} from '@material-ui/core';
import TableProducts from "./TableProducts";
import useAxios from 'axios-hooks';
import {AuthContext, RoutingContext} from "../../contexts"
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import {Route} from "react-router-dom";
import Login from "../login/Login";
import _ from 'lodash'
import {findCodeTypeAndValue, reverseGS1Gtin} from "../../utils/CodesUtils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        marginBottom: "1%",
        color: theme.secondary,
        textTransform: "none"
    },
}));

const MyProducts = () => {
    const {t} = useTranslation()
    const myContext = useContext(RoutingContext);
    const {activeMainDomain} = useContext(AuthContext);
    const classes = useStyles();

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const increment = skip * limit

    const [orderBy, setOrderBy] = useState('name');
    const [direction, setDirection] = useState(1);

    const [{data: _products, loading, error},] = useAxios({
            url: "/api/items/?domain="+activeMainDomain+"&skip=" + increment + "&limit=" + limit + "&sort_by=" + orderBy + "&dir=" + direction,
        },
        {useCache: false}
    )

    console.log("products:",_products)

    const onItemClick = () => {
        myContext.setRoute('AddItem', {edit: false, other: false});
    }

    const products = useMemo(() => {
        if(!_products)
            return null
        const parsedProd = _.map(_products.data, (product) => {
            let [type, value] = findCodeTypeAndValue(product.key)
            let originalKey = product.key
            if(type.type !== product.original_key_type) {
                console.log("KEY CODE ORIGINALE DIVERSO")
                originalKey = reverseGS1Gtin(product.original_key_type, value)
            }
            return {
                ...product,
                key: originalKey
            }
        })

        console.log("parsedProd:", parsedProd)
        return {
            ..._products,
            data: parsedProd,
        }
    },[_products])

    return (
        <div>
            {
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button
                                className={classes.button}
                                onClick={onItemClick}
                                startIcon={<AddIcon/>}>
                                {t('myItems.addNewItem')}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            }
            {products ?
                <TableProducts products={products} limit={limit} setLimit={setLimit} skip={skip} setSkip={setSkip}
                               orderBy={orderBy} setOrderBy={setOrderBy} dir={direction} setDirection={setDirection}/> :
                loading ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error ? <Route path="/Login" component={Login}/> : null}
        </div>
    )

}

export default MyProducts;
