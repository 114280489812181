import Autocomplete from "@material-ui/lab/Autocomplete";
import * as PropTypes from "prop-types";
import React from "react";

function FreeSoloAutocomplete(props) {
    return <Autocomplete
                className={props.classes.root}
                value={props.value}
                fullWidth={props.fullWidth}
                groupBy={props.groupBy}
                onChange={props.onChange}
                disabled={props.disabled}
                filterOptions={props.filterOptions}
                options={props.options}
                getOptionLabel={props.optionLabel}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={props.renderOption}
                freeSolo
                renderInput={props.renderInput}
            />;
}

FreeSoloAutocomplete.propTypes = {
    classes: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    groupBy: PropTypes.any,
    filterOptions: PropTypes.func,
    options: PropTypes.any,
    optionLabel: PropTypes.func,
    renderOption: PropTypes.func,
    renderInput: PropTypes.func,
};

export default FreeSoloAutocomplete