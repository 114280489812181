import React from "react";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import {useTranslation} from "react-i18next";

const FeatureValue = ({feature, remove}) => {
    const {t} = useTranslation()
    const handleClick = () => {
        remove(feature)
    }

    return (
        <div>
            <Box pl={2} pr={2}>
                <Grid container direction="row" justify="flex-start" >
                    <Grid item xs={9} container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">
                        <Box width={1}>
                            <Typography>{/*<i className={classes.prefix}>{feature.ns}</i>*/} <strong>{feature.name}:</strong> {feature.default ? feature.default : feature.value}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                        { feature.required ?  <Typography>{t('entities.required')}</Typography>
                            :  <Typography>{t('entities.notRequired')}</Typography> }
                    </Grid>
                    <Grid item xs={1} container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                        <IconButton aria-label="clear" onClick={handleClick}>
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={1} pr={1}>
                <Divider/>
            </Box>
        </div>
    )
}

export default FeatureValue;
