import React, {useContext} from 'react'
import {Box, IconButton} from "@material-ui/core";
import WhyIcon from "@material-ui/icons/MyLocation";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {ThemeContext} from "../../contexts";
import IncomigEventsIcon from '@material-ui/icons/DynamicFeed';
import WhereIcon from '@material-ui/icons/Explore';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";

const HeaderPanelComponent = (props) => {
    const { t } = useTranslation();

    const {
        title,
        pieGraphMode,
        setPieGraphMode,
        topNumberToDisplay,
        setTopNumberToDisplay
    } = props

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            cursor: 'grab'
        },
        icon: {
            fontSize: '1.25rem'
        },
        headerTitle: {
            fontWeight: "bolder",
            fontSize: '0.85rem'
        },
        dragBox: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        iconDrag: {
            color: 'lightgrey',
            width: '0.75em',
            height: '0.75em',
        },
    }));
    const classes = useStyles()

    const getIcon = (title) => {
        switch (title) {
            case t('landingPage.panelTitles.bizStepOverall'): {
                return (
                    <WhyIcon style={{color: primaryColor}} className={classes.icon}/>
                )
            }
            case t('landingPage.panelTitles.recentEvents'): {
                return (
                    <IncomigEventsIcon style={{color: primaryColor}} className={classes.icon}/>
                )
            }
            case t('landingPage.panelTitles.whereMap'): {
                return (
                    <WhereIcon style={{color: primaryColor}} className={classes.icon}/>
                )
            }
            default:
                return (
                    <Box/>
                )
        }
    }

    const handleChange = (event) => {
        setTopNumberToDisplay(event.target.value)
    }

    return (
        <Box p={1} pt={0} className={classes.root}>
            {getIcon(title)}
            <Box pl={1} pr={2} className="drag-handle">
                <Typography align={"left"}
                            style={{color: primaryColor}}
                            className={classes.headerTitle}>{title}</Typography>
            </Box>
            <Box style={{marginLeft: 'auto'}}>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    {title === t('landingPage.panelTitles.bizStepOverall') ?
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={topNumberToDisplay}
                                defaultValue={5}
                                onChange={handleChange}>
                                    <MenuItem value={5}>Top 5</MenuItem>
                                    <MenuItem value={10}>Top 10</MenuItem>
                                    <MenuItem value={30}>Top 30</MenuItem>
                                    {/*<MenuItem value={30}>Show All</MenuItem>*/}
                            </Select>
                        </FormControl> : null}
                    {title === t('landingPage.panelTitles.bizStepOverall') ?

                        <IconButton onClick={() => setPieGraphMode(!pieGraphMode)}>
                            {
                                !pieGraphMode ?
                                    <PieChartIcon style={{color: accentColor}} className={classes.icon}/>
                                    : <BarChartIcon style={{color: accentColor}} className={classes.icon}/>
                            }
                        </IconButton>
                        : null}
                    {/*<CustomDateRangePicker filters={{}} setFilters={setFilters} setSkip={setSkip}/>*/}
                    {
                       // <DragIndicatorIcon className={classes.iconDrag}/>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default HeaderPanelComponent
