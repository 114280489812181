import React, {useMemo} from "react";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';

const LocationValue = ({location, remove, editMode, onEditClick, editableSubplaces}) => {

    const handleClick = () => {
        remove(location)
    }

    const handleClickEdit = () => {
        onEditClick(location)
    }

    const ifNewSubplace = useMemo( () => {
        const arr = editableSubplaces.filter(subplace => subplace.sgln === location.sgln)
        return !arr.length;
    }, [])

    return (
        <div>
            <Box pl={2} pr={2}>
                <Grid container direction="row" justify="flex-start" >
                    <Grid item xs={11} container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">
                        <Box width={1}>
                            <Typography><b>{location.name}</b> - {location.description}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                        {
                            !editMode.editMode || ifNewSubplace ?
                                <IconButton aria-label="clear" onClick={handleClick}>
                                    <ClearIcon fontSize="small"/>
                                </IconButton> :
                                <IconButton aria-label="clear" onClick={handleClickEdit}>
                                    <EditIcon fontSize="small"/>
                                </IconButton>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box pl={1} pr={1}>
                <Divider/>
            </Box>
        </div>
    )
}

export default LocationValue;