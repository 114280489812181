import {Box, MenuItem, Select} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {languages} from "../../i18n/i18next";
//import 'flag-icon-css/css/flag-icon.min.css'
import i18next from 'i18next'
import _ from 'lodash'
import FormControl from "@material-ui/core/FormControl";

const LanguageSelect = () => {
    const lng = localStorage.getItem(`iChain_lang`)
    const _lng = _.find(languages, ['code', lng])
    const [selectedLanguage, setSelectedLanguage] = useState(_lng || languages[0])

    useEffect(() => {
        if (selectedLanguage) {
            i18next.changeLanguage(selectedLanguage.code, (err, t) => {
                if (err) return console.log('something went wrong loading lang:', err);
            })
            document.body.dir = (selectedLanguage.dir || 'ltr')
            localStorage.setItem(`iChain_lang`, selectedLanguage.code)
        }
    }, [selectedLanguage])

    return (
        <Box display={'contents'}>
            {selectedLanguage ?
                <div style={{marginLeft:'auto'}}>
                    <FormControl size={'small'}>
                        <Select autoWidth variant={'outlined'}
                                value={selectedLanguage}
                                defaultValue={selectedLanguage}>
                            {languages.map((lang) =>
                                (<MenuItem key={lang.country_code}
                                           value={lang}
                                           onClick={() => {
                                               setSelectedLanguage(lang)
                                           }}>
                                    {/** NO-FLAG */
                                    /*<span className={`flag-icon flag-icon-${lang.country_code}`}
                                           style={{marginRight: '8px'}}/>*/}
                                    {lang.name}
                                </MenuItem>))}
                        </Select>
                    </FormControl>
                </div> : null}
        </Box>
    )
}

export default LanguageSelect