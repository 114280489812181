import React, {useContext, useMemo, useRef, useState} from "react";
import {Box, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {AuthContext, CreateEventContext, RoutingContext, ThemeContext} from "../../../contexts";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import TypologyComponent from "./TypologyComponent";
import WhatComponent from "./WhatComponent";
import WhyComponent from "./WhyComponent";
import WhereComponent from "./WhereComponent";
import WhenComponent from "./WhenComponent";
import IlmdComponent from "./IlmdComponent";
import EventModelDetail from "../EventModelDetail";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CustomLoader from "../../../components/CustomLoader";
import _ from "lodash"
import axios from "axios";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {eventFieldNameTypes, eventTypes, inputTypes} from "../../../utils/CbvUtils";
import {convertLocationClassToInstance, createWhiteListedGtins} from "../../../utils/CodesUtils";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import i18next from "../../../i18n/i18next";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FieldInfo from "../../generate_new_event/FieldInfo";
import {Warning} from "@material-ui/icons";

const initialSchema = {
    eventName: '',
    initialName: '',
    description: '',
    eventType: eventTypes.OBJECT.value,

    // WHAT -----------------------------------------------------------------
    itemListType: eventFieldNameTypes.EPCLIST.type, // epc o quantity
    itemListInputType: inputTypes.EXTERNAL.value, // external, computed, ecc...

    inputItemListType: eventFieldNameTypes.EPCLIST.type, // epc o quantity
    inputItemListInputType: inputTypes.EXTERNAL.value, // external, computed, ecc...

    outputItemListType: eventFieldNameTypes.EPCLIST.type, // epc o quantity
    outputItemListInputType: inputTypes.EXTERNAL.value, // external, computed, ecc...

    bizTransactionListType: eventFieldNameTypes.BIZTRANSACTIONLIST.type,
    bizTransactionListInputType: inputTypes.EXTERNAL.value, // external, computed, ecc...
    bizTransactionList: [],

    childListType: eventFieldNameTypes.EPCLIST.type, // epc o quantity

    whiteListedGtins: [],
    strictedWhitelist: false,

    whiteListedGtinsOut: [],
    strictedWhitelistOut: false,

    precedence: null, // optional, se non NULL è una stringa che indica il valore del WHY associato al field

    // WHY -------------------------------------------------------------------
    bizStep: null,
    dispositionInputType: inputTypes.FIXED.value,
    disposition: null,

    // WHERE -----------------------------------------------------------------
    optionalBizLocation: false,
    bizLocInputType: inputTypes.FIXED.value,
    fixedBizLocation: null,
    whiteListedBizLocations: [],

    optionalReadPoint: false,
    readPointInputType: inputTypes.EXTERNAL.value,
    fixedReadPoint: null,
    whiteListedReadPoints: [],

    // WHERE per transaction events
    sourceListInputType: inputTypes.EXTERNAL.value, // external, computed, ecc...
    destinationListInputType: inputTypes.EXTERNAL.value, // external, computed, ecc...
    sourceListTypes: [],
    destinationListTypes: [],
    requiredSourceDestLists: true,

    // WHEN ------------------------------------------------------------------
    eventTime: inputTypes.COMPUTED.value,
    recordTime: inputTypes.COMPUTED.value,
    eventTimeZoneOffset: inputTypes.COMPUTED.value,

    // ILMD ------------------------------------------------------------------
    ilmd: []
}

function parseFieldName(fieldName) {
    let res = ''
    if (fieldName.includes('EPCList')) {
        res = 'epcList'
    } else {
        res = `${fieldName[0].toLowerCase()}${fieldName.slice(1)}`
    }

    return res
}

function prepareSummaryData(formData) {
    //console.log("prepareSummaryData ---> formData: ", formData)

    // EVENT TYPOLOGY
    let templateToSend = {is_draft: formData.is_draft, name: formData.eventName, type: formData.eventType, description: formData.description.trim()}

    // WHY
    let why = [{
        name: 'bizStep',
        type: 'str',
        input: inputTypes.FIXED.value,
        alias: null,
        value: formData.bizStep,
        fun: null,
        whitelist: [],
        strict: false,
        optional: false,
        children: []
    }, {
        name: 'disposition',
        type: 'str',
        input: formData.dispositionInputType,
        alias: null,
        value: formData.dispositionInputType === inputTypes.FIXED.value && formData.disposition ? formData.disposition : null,
        fun: null,
        whitelist: [],
        strict: false,
        optional: formData.dispositionInputType !== inputTypes.FIXED.value,
        children: []
    }]
    if (formData.action && formData.eventType !== eventTypes.TRANSFORMATION.value) {
        why.push({
            name: 'action',
            type: 'str',
            input: inputTypes.FIXED.value,
            alias: null,
            value: formData.action,
            fun: null,
            whitelist: [],
            strict: false,
            optional: false,
            children: []
        })
    }

    let where = [{
        name: 'bizLocation',
        type: 'str',
        input: formData.readPointInputType === inputTypes.FIXED.value ? inputTypes.FIXED.value : formData.bizLocInputType,
        alias: null,
        value: formData.readPointInputType === inputTypes.FIXED.value && formData.fixedReadPoint ? convertLocationClassToInstance(formData.fixedReadPoint.gln) :
            formData.bizLocInputType === inputTypes.FIXED.value && formData.fixedBizLocation ? (formData.fixedBizLocation.sgln||formData.fixedBizLocation) : null,
        fun: null,
        whitelist: formData.bizLocInputType === inputTypes.EXTERNAL.value && formData.whiteListedBizLocations.length !== 0 ? formData.whiteListedBizLocations.map((place) => (place.sgln||place))/*.toString()*/ : [],
        strict: false,
        optional: formData.bizLocInputType === inputTypes.FIXED.value ? false : formData.optionalBizLocation,
        children: [],
    }, {
        name: 'readPoint',
        type: 'str',
        input: formData.readPointInputType,
        alias: null,
        value: formData.readPointInputType === inputTypes.FIXED.value && formData.fixedReadPoint ? (formData.fixedReadPoint.sgln || formData.fixedReadPoint) : null,
        fun: null,
        whitelist: formData.readPointInputType === inputTypes.EXTERNAL.value && formData.whiteListedReadPoints.length !== 0 ? formData.whiteListedReadPoints.map((place) => (place.sgln||place))/*.toString()*/ : [],
        strict: false,
        optional: formData.readPointInputType === inputTypes.FIXED.value ? false : formData.optionalReadPoint,
        children: [],
    }]

    // WHEN
    let when = [{
        name: 'eventTime',
        type: 'date',
        input: formData.eventTime, // fixed, external
        alias: null,
        value: null,
        //fun: 'now',
        fun: formData.eventTime === inputTypes.COMPUTED.value ? 'now' : null,
        optional: false
    }, {
        name: 'recordTime',
        type: 'date',
        input: formData.recordTime, // fixed, external
        alias: null,
        value: null,
        fun: formData.recordTime === inputTypes.COMPUTED.value ? 'now' : null,
        optional: false,
    }, {
        name: 'eventTimeZoneOffset',
        type: 'date',
        input: formData.eventTimeZoneOffset,
        alias: null,
        value: null,
        fun: formData.eventTimeZoneOffset === inputTypes.COMPUTED.value ? 'now_offset' : null,
        optional: false
    }]


    // WHAT + specificità
    let what = []
    what.push({
        name: 'eventID',
        type: "str",
        input: "computed",
        alias: null,
        value: null,
        fun: "uuid",
        precedence: formData.precedence,
        whitelist: [],
        strict: false,
        optional: false,
        children: []
    })

    switch (formData.eventType) {
        case eventTypes.OBJECT.value:
        case eventTypes.TRANSACTION.value:
            if (formData.itemListType === eventFieldNameTypes.EPC_QUANTITY_LIST.type) {
                what.push({
                    name: eventFieldNameTypes.EPCLIST.name,
                    type: eventFieldNameTypes.EPCLIST.type,
                    input: formData.itemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist,
                    children: []
                })
                what.push({
                    name: eventFieldNameTypes.QUANTITYLIST.name,
                    type: eventFieldNameTypes.QUANTITYLIST.type,
                    input: formData.itemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist,
                    children: []
                })
            } else {
                what.push({
                    name: parseFieldName(formData.itemListType),
                    type: formData.itemListType,
                    input: formData.itemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist,
                    children: []
                })
            }
            if (formData.eventType === eventTypes.TRANSACTION.value) {
                what.push({
                    name: eventFieldNameTypes.BIZTRANSACTIONLIST.name,
                    type: formData.bizTransactionListType,
                    input: formData.bizTransactionListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    whitelist: [],
                    strict: false,
                    optional: false,
                    children: formData.bizTransactionListInputType === inputTypes.PARTIALLY_FIXED.value ? formData.bizTransactionList.map((value) => (value)) : []
                })

                if(formData.requiredSourceDestLists) {
                    where.push({
                        name: eventFieldNameTypes.SOURCELIST.name,
                        type: eventFieldNameTypes.SOURCELIST.type,
                        input: formData.sourceListInputType,
                        alias: null,
                        value: null,
                        fun: null,
                        optional: false,
                        children: formData.sourceListInputType === inputTypes.PARTIALLY_FIXED.value ? formData.sourceListTypes.map((source_type) => (source_type)) : [],
                    }, {
                        name: eventFieldNameTypes.DESTINATIONLIST.name,
                        type: eventFieldNameTypes.DESTINATIONLIST.type,
                        input: formData.destinationListInputType,
                        alias: null,
                        value: null,
                        fun: null,
                        optional: false,
                        children: formData.destinationListInputType === inputTypes.PARTIALLY_FIXED.value ? formData.destinationListTypes.map((destination_type) => (destination_type)) : [],
                    })
                }
            }
            break;

        case eventTypes.AGGREGATION.value:
        case eventTypes.ASSOCIATION.value:
            what.push({
                name: 'parentID',
                type: 'str',
                input: inputTypes.EXTERNAL.value,
                alias: null,
                value: null,
                fun: null,
                precedence: formData.precedence,
                whitelist: [],
                strict: false,
                optional: false,
                children: []
            })
            if (formData.childListType === eventFieldNameTypes.EPC_QUANTITY_LIST.type) {
                what.push({
                    name: eventFieldNameTypes.CHILD_EPC_LIST.name,
                    type: eventFieldNameTypes.CHILD_EPC_LIST.type,
                    input: inputTypes.EXTERNAL.value,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist,
                    optional: false,
                    children: []
                }, {
                    name: eventFieldNameTypes.CHILD_QUANTITYLIST_LIST.name,
                    type: eventFieldNameTypes.CHILD_QUANTITYLIST_LIST.type,
                    input: inputTypes.EXTERNAL.value,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist,
                    optional: false,
                    children: []
                })
            } else {
                what.push({
                    name: formData.childListType === 'QuantityList' ? 'childQuantityList' : 'childEPCs',
                    type: formData.childListType,
                    input: inputTypes.EXTERNAL.value,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist,
                    optional: false,
                    children: []
                })
            }
            break;
        case eventTypes.TRANSFORMATION.value:
            if (formData.inputItemListType === eventFieldNameTypes.EPC_QUANTITY_LIST.type) {
                what.push({
                    name: eventFieldNameTypes.INPUT_EPCLIST.name,
                    type: eventFieldNameTypes.INPUT_EPCLIST.type,
                    input: formData.inputItemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    children: [],
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist
                }, {
                    name: eventFieldNameTypes.INPUT_QUANTITYLIST.name,
                    type: eventFieldNameTypes.INPUT_QUANTITYLIST.type,
                    input: formData.inputItemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    children: [],
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist
                })
            } else {
                what.push({
                    name: formData.inputItemListType === 'QuantityList' ? 'inputQuantityList' : 'inputEPCList',
                    type: formData.inputItemListType,
                    input: formData.inputItemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    children: [],
                    whitelist: formData.whiteListedGtins.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelist
                })
            }

            if (formData.outputItemListType === eventFieldNameTypes.EPC_QUANTITY_LIST.type) {
                what.push({
                    name: eventFieldNameTypes.OUTPUT_EPCLIST.name,
                    type: eventFieldNameTypes.OUTPUT_EPCLIST.type,
                    input: formData.outputItemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    children: [],
                    whitelist: formData.whiteListedGtinsOut.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelistOut
                }, {
                    name: eventFieldNameTypes.OUTPUT_QUANTITYLIST.name,
                    type: eventFieldNameTypes.OUTPUT_QUANTITYLIST.type,
                    input: formData.outputItemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    children: [],
                    whitelist: formData.whiteListedGtinsOut.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelistOut
                })
            } else {
                what.push({
                    name: formData.outputItemListType === 'QuantityList' ? 'outputQuantityList' : 'outputEPCList',
                    type: formData.outputItemListType,
                    input: formData.outputItemListInputType,
                    alias: null,
                    value: null,
                    fun: null,
                    precedence: formData.precedence,
                    optional: false,
                    children: [],
                    whitelist: formData.whiteListedGtinsOut.map((product) => (product.key||product)),//.toString(),
                    strict: formData.strictedWhitelistOut
                })
            }
            break;
        default:
            console.log("Unknown Event Type!")
            break
    }

    const ilmd = formData.ilmd.map((field) => ({
        ns: field.ns,
        name: field.name,
        input: inputTypes.EXTERNAL.value,
        value: null,
        default: field.default ? field.default : null,
        optional: field.optional
    }))

    templateToSend = {
        ...templateToSend,
        what: what,
        why: why,
        where: where,
        when: when,
        ilmd: ilmd,
        // TODO: lut per i nomi/address nel riepilogo?
    }

    return templateToSend
}

const setEditEventModel = (eventContext) => {
    const eventModel = eventContext.editEvent
    const products = eventContext.products
    //console.log('MODELLO DA EDITARE ---> ', eventModel)

    //WHAT
    let itemListType = eventFieldNameTypes.EPCLIST.type;
    let itemListInputType = inputTypes.EXTERNAL.value;
    let whiteListedGtins = [];
    let whiteListedGtinsOut = [];
    let strictedWhitelist = false;
    let strictedWhitelistOut = false;
    let childListType = eventFieldNameTypes.EPCLIST.type;
    let bizTransactionListType = eventFieldNameTypes.BIZTRANSACTIONLIST.type
    let bizTransactionListInputType = inputTypes.EXTERNAL.value
    let bizTransactionList = []
    let inputItemListType = eventFieldNameTypes.EPCLIST.type
    let inputItemListInputType = inputTypes.EXTERNAL.value
    let outputItemListType = eventFieldNameTypes.EPCLIST.type
    let outputItemListInputType = inputTypes.EXTERNAL.value
    let precedence = null

    //WHY
    let bizStep = null;
    let dispositionInputType = inputTypes.FIXED.value;
    let disposition = null;
    let action = null;

    //WHERE
    let optionalBizLocation = false;
    let bizLocInputType = inputTypes.FIXED.value;
    let fixedBizLocation = null;
    let whiteListedBizLocations = [];

    let optionalReadPoint = false;
    let readPointInputType = inputTypes.EXTERNAL.value;
    let fixedReadPoint = null;
    let whiteListedReadPoints = [];

    let sourceListInputType = inputTypes.EXTERNAL.value;
    let sourceListTypes = [];
    let destinationListInputType = inputTypes.EXTERNAL.value;
    let destinationListTypes = [];
    let requiredSourceDestLists = true

    //WHEN
    let eventTime = inputTypes.COMPUTED.value;
    let recordTime = inputTypes.COMPUTED.value;
    let eventTimeZoneOffset = inputTypes.COMPUTED.value;

    //ILMD
    let ilmd = []

    let obj, objQuantity
    switch (eventModel?.type) {
        case eventTypes.OBJECT.value:
        case eventTypes.TRANSACTION.value:
            obj = _.find(eventModel.what, ['name','epcList'])
            objQuantity = _.find(eventModel.what, ['name','quantityList'])
            if(obj && objQuantity) {
                itemListType = eventFieldNameTypes.EPC_QUANTITY_LIST.type
            } else {
                obj = obj || objQuantity
                itemListType = obj.type
            }

            itemListInputType = obj.input
            whiteListedGtins = createWhiteListedGtins(products, obj.whitelist)
            strictedWhitelist = obj.strict

            obj = _.find(eventModel.what, ['name', eventFieldNameTypes.BIZTRANSACTIONLIST.name])
            if(obj?.type)
                bizTransactionListType = obj.type
            bizTransactionListInputType = obj?.input
            bizTransactionList = obj?.children

            requiredSourceDestLists = !!_.find(eventModel.where, ['name',eventFieldNameTypes.SOURCELIST.name])
            || !!_.find(eventModel.where, ['name',eventFieldNameTypes.DESTINATIONLIST.name])

            break;
        case eventTypes.AGGREGATION.value:
        case eventTypes.ASSOCIATION.value:
            obj = _.find(eventModel.what, ['name','childEPCs'])
            objQuantity = _.find(eventModel.what, ['name','childQuantityList'])
            if(obj && objQuantity) {
                childListType = eventFieldNameTypes.EPC_QUANTITY_LIST.type
            } else {
                obj = obj || objQuantity
                childListType = obj.type
            }

            whiteListedGtins = createWhiteListedGtins(products, obj.whitelist)
            strictedWhitelist = obj.strict
            break;
        case eventTypes.TRANSFORMATION.value:
            obj = _.find(eventModel.what, ['name','inputEPCList'])
            objQuantity = _.find(eventModel.what, ['name','inputQuantityList'])
            if(obj && objQuantity) {
                inputItemListType = eventFieldNameTypes.EPC_QUANTITY_LIST.type
            } else {
                obj = obj || objQuantity
                inputItemListType = obj.type
            }

            inputItemListInputType = obj.input
            whiteListedGtins = createWhiteListedGtins(products, obj.whitelist)
            strictedWhitelist = obj.strict

            obj = _.find(eventModel.what, ['name','outputEPCList'])
            objQuantity = _.find(eventModel.what, ['name','outputQuantityList'])
            if(obj && objQuantity) {
                outputItemListType = eventFieldNameTypes.EPC_QUANTITY_LIST.type
            } else {
                obj = obj || objQuantity
                outputItemListType = obj.type
            }

            outputItemListInputType = obj.input
            whiteListedGtinsOut = createWhiteListedGtins(products, obj.whitelist)
            strictedWhitelistOut = obj.strict
            break;
        default:
            console.log("Event Type Unknown!")
            break
    }

    eventModel?.why?.forEach(obj => {
        if (obj.name === 'bizStep') {
            bizStep = obj.value
        }
        if (obj.name === 'disposition') {
            dispositionInputType = obj.input
            disposition = obj.value
        }
        if (obj.name === 'action') {
            action = obj.value
        }
    })
    eventModel?.where.forEach(obj => {
        if (obj.name === 'bizLocation') {
            optionalBizLocation = obj.optional
            bizLocInputType = obj.input
            //fixedBizLocation = createPlaceObj(obj.value)
            fixedBizLocation = obj.value
            whiteListedBizLocations = obj.whitelist
        }
        if (obj.name === 'readPoint') {
            optionalReadPoint = obj.optional
            readPointInputType = obj.input
            //fixedReadPoint = createPlaceObj(obj.value);
            fixedReadPoint = obj.value
            whiteListedReadPoints = obj.whitelist
        }
        if (obj.name === 'sourceList') {
            sourceListInputType = obj.input
            sourceListTypes = obj.children
        }
        if (obj.name === 'destinationList') {
            destinationListInputType = obj.input
            destinationListTypes = obj.children
        }
    })

    eventModel?.when.forEach(obj => {
        if (obj.name === 'eventTime') {
            eventTime = obj.input
        }
        if (obj.name === 'recordTime') {
            recordTime = obj.input
        }
        if (obj.name === 'eventTimeZoneOffset') {
            eventTimeZoneOffset = obj.input
        }
    })

    eventModel?.ilmd.forEach((obj) => {
        ilmd.push(obj)
    })

    const schema = {
        initialName: eventModel?.name||eventContext.modelName,
        eventName: eventModel?.name||eventContext.modelName,
        eventType: eventModel?.type||eventTypes.OBJECT.value,
        description: eventModel?.description||eventContext.description,
        //WHAT
        precedence: precedence,
        itemListType: itemListType,
        itemListInputType: itemListInputType,
        whiteListedGtins: whiteListedGtins,
        whiteListedGtinsOut: whiteListedGtinsOut,
        strictedWhitelist: strictedWhitelist,
        strictedWhitelistOut: strictedWhitelistOut,
        childListType: childListType,
        bizTransactionListType: bizTransactionListType,
        bizTransactionListInputType: bizTransactionListInputType,
        bizTransactionList: bizTransactionList,
        inputItemListType: inputItemListType,
        inputItemListInputType: inputItemListInputType,
        outputItemListType: outputItemListType,
        outputItemListInputType: outputItemListInputType,

        //WHY
        bizStep: bizStep,
        dispositionInputType: dispositionInputType,
        disposition: disposition,
        action: action,

        //WHERE
        optionalBizLocation: optionalBizLocation,
        bizLocInputType: bizLocInputType,
        fixedBizLocation: fixedBizLocation,
        whiteListedBizLocations: whiteListedBizLocations,
        optionalReadPoint: optionalReadPoint,
        readPointInputType: readPointInputType,
        fixedReadPoint: fixedReadPoint,
        whiteListedReadPoints: whiteListedReadPoints,
        sourceListInputType: sourceListInputType,
        sourceListTypes: sourceListTypes,
        destinationListInputType: destinationListInputType,
        destinationListTypes: destinationListTypes,
        requiredSourceDestLists: requiredSourceDestLists,

        //WHEN
        eventTime: eventTime,
        recordTime: recordTime,
        eventTimeZoneOffset: eventTimeZoneOffset,

        ilmd: ilmd,
        version: eventModel?.version
    }
    return schema
}

const CreateEventTemplateForm = () => {
    const { t } = useTranslation();

    const {activeMainDomain} = useContext(AuthContext)
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        error: {
            color: 'red',
            margin: '0px'
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            fontWeight: 'bold',
            marginTop: '1%',
            marginRight: '1%',
            borderRadius: '20px',
        },
        confirmButton: {
            backgroundColor: accentColor,
            color: "white",
            paddingLeft: '10%',
            paddingRight: '10%',
            borderRadius: '20px',
            boxShadow: theme.shadows[2]
        }
    }));
    const classes = useStyles()

    const eventContext = useContext(CreateEventContext);

    const [stepValidation, setStepValidation] = useState(false);
    const descriptionElementRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openOK, setOpenOk] = useState({open: false, _id: null, is_draft: eventContext.is_draft});
    const [openError, setOpenError] = useState({open: false, message: ''});
    const [show, setShow] = useState(!eventContext.editMode)
    const myContext = useContext(RoutingContext);

    const [formData, setFormData] = useState({...initialSchema, initialName: eventContext.modelName||'', eventName:eventContext.modelName||'', description:eventContext.description||''})

    useMemo(() => {
        if (eventContext.editMode && eventContext.products) {
            async function setData() {
                setFormData(setEditEventModel(eventContext))
            }

            setData().then(() => setShow(true))
        }
    }, [eventContext.editMode, eventContext.products])

    const handleChange = (input1, value1, input2, value2) => {
        if(Array.isArray(input1)) {
            let newData = {}
            for (let i = 0; i < input1.length; i++) {
                newData = {...newData, [input1[i]]: value1[i]}
            }
            setFormData({...formData, ...newData})
        } else if (input2)
            setFormData({...formData, [input1]: value1, [input2]: value2})
        else
            setFormData({...formData, [input1]: value1})
    }

    const [activeStep, setActiveStep] = useState(0);

    const getSteps = () => {
        return [t('entities.eventTypology', 'Event Typology'), 'What', 'Why', 'Where', 'When', 'Instance/Lot Master Data'/*, 'Summary'*/];
    }

    const getStepContent = (step) => {
        if (step === 0) return <TypologyComponent eventTypes={eventContext.cbvValues.is_a} handleChange={handleChange}
                                                  formData={formData} setStepValidation={setStepValidation}/>
        else if (step === 1) return <WhatComponent products={eventContext.products} handleChange={handleChange}
                                                   formData={formData}
                                                   setStepValidation={setStepValidation}/>
        else if (step === 2) return <WhyComponent cbvValues={eventContext.cbvValues} formData={formData}
                                                  handleChange={handleChange}
                                                  setStepValidation={setStepValidation}/>
        else if (step === 3) return <WhereComponent cbvValues={eventContext.cbvValues}
                                                    formData={formData} handleChange={handleChange}
                                                    setStepValidation={setStepValidation}/>
        else if (step === 4) return <WhenComponent formData={formData} handleChange={handleChange}
                                                   setStepValidation={setStepValidation}/>
        else if (step === 5) return <IlmdComponent formData={formData}
                                                   formDataFieldName={'ilmd'}
                                                   formDataFieldValue={formData.ilmd}
                                                   handleChange={handleChange}
                                                   setStepValidation={setStepValidation}/>
    }

    const steps = getSteps();

    const handleNext = () => {
        setStepValidation(false)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleTemplateUpdate = (finalize) => {
        const myData = prepareSummaryData({...formData, is_draft: eventContext.is_draft, description: _.replace(eventContext.description, /(^\s*(?!.+)\n+)|(\n+\s+(?!.+)$)/g, ''), ilmd: eventContext.ilmd})
        const url = `api/capture/event_templates/${eventContext.is_draft && eventContext.editMode ? (eventContext.editEvent?.mongoid||eventContext.editEvent?._id||eventContext._id) : ''}?domain=${(eventContext.domain||activeMainDomain)}`
        axios({
            method: eventContext.is_draft && eventContext.editMode ? 'put' : 'post',
            url: url,
            data: {...myData,
                is_draft: eventContext.is_draft ? !finalize : eventContext.is_draft,
                version: eventContext.is_draft && !finalize ? 0 : (formData?.version||0)+1
            },
            config: {headers: {'Media-Type': 'application/json'}}
        }).then(function (res) {
            setOpenOk({open: true, _id: res.data._id, is_draft: eventContext.is_draft ? !finalize : eventContext.is_draft})
        }).catch((error) => {
            console.log('error editing draft name:', error);
            if (error.response.status === 400) {
                //console.log("ERROR:",error)
                setOpenError({
                    open: true,
                    message: t('createEventModel.eventNameBusy'),
                });
            } else if (error.response.status === 403) {
                //console.log("ERROR:",error)
                setOpenError({
                    open: true,
                    message: (error.response.data?.detail[0]?.msg || error.response.data.detail) + ' ' + t('errors.forbidden'),
                });
            } else {
                //console.log("ERROR:",error)
                setOpenError({
                    open: true,
                    message: error.response.status + ' ' + t("errors.genericServerError"),
                });
            }
            return Promise.reject(error)
        });
        setOpen(false);
    };

    const handleFinish = () => {
        setOpen(true)
    };

    const handleCloseOk = () => {
        if (!!eventContext.fromRulesConf && typeof eventContext.fromRulesConf === 'function') {
            eventContext.fromRulesConf({
                _id: openOK._id,
                is_draft: openOK.is_draft,
                name: formData.eventName,
                type: formData.eventType,
                biz_step: formData.bizStep,
            })
        }

        else myContext.setRoute('EventModels');
        setOpenOk({open:false, _id: null});
    };

    const handleCloseError = () => {
        setOpenError(false);
    };

    return (
        <Box>
            <form>
                {
                    show ? <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    {getStepContent(index)}
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                style={{
                                                    marginTop: '1%',
                                                    marginRight: '1%',
                                                }}
                                            >
                                                {t('actions.back')}
                                            </Button>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                disabled={!stepValidation}
                                                onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
                                            >
                                                {activeStep === steps.length - 1 ? _.startCase(t('actions.review')) : _.startCase(t('actions.next'))}
                                            </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper> : <CustomLoader size={80} text={t('actions.loading')}/>
                }

                {
                    open ?
                        <Dialog disableBackdropClick fullWidth={true} maxWidth={"md"} open={open}
                                scroll='paper'>
                            <DialogTitle id="customized-dialog-title">
                                <Box display="flex" alignItems="center">
                                    <Autocomplete
                                        style={{width:'70%'}}
                                        disableClearable
                                        disabled={eventContext.editMode}
                                        defaultValue={_.find(eventContext.activeDomains, ['domain', eventContext.domain])||{name:'', img: null, domain:activeMainDomain}}
                                        options={eventContext.activeDomains}
                                        getOptionLabel={(option) => !option.other ? option.name + ' - ' + option.domain : option.name}
                                        onChange={(event, newValue) => {
                                            setFormData({...formData, postOnDomain: newValue})
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            size={'small'}
                                            label={i18next.t('entities.company.companyDomain')}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    (formData.postOnDomain?.image || formData.postOnDomain?.img)
                                                    && <Box p={1}><Avatar
                                                        src={(formData.postOnDomain.image || formData.postOnDomain?.img) ? ("api/uploads/uploads/" + (formData.postOnDomain.image || formData.postOnDomain?.img)) : null}>
                                                    </Avatar></Box>
                                                ),
                                            }}
                                        />}
                                        renderOption={(option, {selected}) => {
                                            return (<React.Fragment>
                                                    {(!option.other && (option.image || option.img)) && <ListItemAvatar>
                                                        <Avatar
                                                            src={(option.image || option.img) ? ("api/uploads/uploads/" + (option.image || option.img)) : null}>
                                                        </Avatar>
                                                    </ListItemAvatar>}
                                                    <ListItemText
                                                        primary={`${option.name}`}
                                                        secondary={!option.other && option.domain}
                                                        style={{color: primaryColor}}/>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                    <Box marginLeft={'auto'}>
                                        <IconButton onClick={() => {
                                            setOpen(false)
                                        }}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent style={{marginTop: '-2%'}} ref={descriptionElementRef}>
                                <EventModelDetail hideVersion={true} props={prepareSummaryData({...formData, is_draft: eventContext.is_draft, ilmd:eventContext.ilmd, description: _.replace(eventContext.description, /(^\s*(?!.+)\n+)|(\n+\s+(?!.+)$)/g, '')})}
                                                  noShow={true} />
                            </DialogContent>
                            <Box p={1} display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                                {(!eventContext.editMode || eventContext.is_draft) &&
                                <Box display={'flex'}>
                                    <FieldInfo icon={<Warning/>} description={t('createEventModel.activateModelDescription')}/>
                                    <Button onClick={() => handleTemplateUpdate(true)}>
                                        {t('actions.create', {what: t('entities.model.label')})}
                                    </Button>
                                </Box>}
                                <Button onClick={() => handleTemplateUpdate(false)} className={classes.confirmButton}>
                                    {
                                        eventContext.editMode && !eventContext.is_draft ? t('actions.edit', {what: t('entities.model.label')}) : t('actions.save', {what: t('entities.draft')})
                                    }
                                </Button>
                            </Box>
                        </Dialog> : null
                }
            </form>
            {
                openOK.open ?
                    <Dialog fullWidth={true} maxWidth={"md"} open={openOK.open} onClose={handleCloseOk} scroll='paper'>
                        <DialogTitle>{
                            formData.eventName ? formData.eventName : null
                        }</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {
                                    !eventContext.editMode ?
                                        !openOK.is_draft ?
                                            t('eventModels.modelCreatedSuccess') :
                                            t('eventModels.draftCreatedSuccess')
                                        : !openOK.is_draft ?
                                            t('eventModels.modelModifiedSuccess')
                                            : t('eventModels.draftModifiedSuccess')

                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseOk} color="default">
                                {t('actions.ok')}
                            </Button>
                        </DialogActions>
                    </Dialog> : null
            }
            {
                openError.open ?
                    <Dialog fullWidth={true} maxWidth={"md"} open={openError.open} onClose={handleCloseError}
                            scroll='paper'>
                        <DialogTitle>{
                            formData.eventName ? _.startCase(t('errors.label'))+' - ' + formData.eventName : null
                        }</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {openError.message}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseError} color="default">
                                {t('actions.ok')}
                            </Button>
                        </DialogActions>
                    </Dialog> : null
            }
        </Box>
    )
}

export default CreateEventTemplateForm
