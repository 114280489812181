import React, {createRef, useContext, useMemo} from "react";
import _ from "lodash";
import ContainerDimensions from "react-container-dimensions";
import CustomD3DagComponent from "./CustomD3DagComponent";
import {ProductHistoryContext} from "../../../contexts";

const buildDag2 = (rootCodes, items) => {
    /** const TYPE = 0;const TIME = 1;const PARENT = 2;const CHILD = 3const EID = 4; */
    //const t0 = performance.now()

    /**
     *  const parentTree = items2map2(items, {leftright: true, keepSingles: false});
        const childTree = items2map2(items, {leftright: false, keepSingles: false});
        const remappedChildTree = remappedItems2map2(items, {leftright: false, keepSingles: false});
        const remappedParentTree =  remappedItems2map2(items, {leftright: true, keepSingles: false});
    */
    /**
     *
     * @param codes
     * @param tree
     * @returns {*[]}
     */
    /** const findDescendents = (codes, tree) => {
        const t0 = performance.now()
        const descendents = [];
        let visiting = [...codes];

        const visited = {};
        while (visiting.length > 0) {
            const x = visiting.pop();
            // * remap necessario:
            //  * ora i codici che ci arrivano dal server non sono solo SGTIN,
            //  * ma possono essere nella forma "SGTIN,EVENT_ID" in caso di Transformation/Aggregation Event
            //  * -->  devo controllare quindi che l'sgtin selezionato dall'utente
            //  *      sia "incluso" nell'id dell'item della history
            //  *

            const remapKeys = _.filter(_.keys(tree), (key) => {
                if (_.split(key, ',')[0] === _.split(x, ',')[0]) {
                    return key
                }
            })

            if (!(x in visited)) {
                visited[x] = 1;
                x && descendents.push(x);
                // _.each(tree[x], child => {
                //     const serial = child && child.split(","); // ff
                //     serial && visiting.push(serial[0])
                // })
                _.each(remapKeys, (key) => {
                    _.each(tree[key], (child) => visiting.push(child)); // old mio
                })
            }
        }

        const t1 = performance.now()
        console.log("findDescendents --->", t1-t0)
        return descendents;
    }
    const forwardCodes = !historyDirections || historyDirections === 'forward' ? findDescendents(rootCodes, remappedParentTree) : [];
    const backwardCodes = !historyDirections || historyDirections === 'backward' ? findDescendents(rootCodes, remappedChildTree) : [];
 */

    const stratifyTree = () => {
        const sortedItems = _.sortBy(items, (x) => x.when); // sort by time

        // now stratify tree
        let tree = [
            {id: "Roots", parentIds: []}
        ]
        const nodeMap = {
            "Roots": tree[0]
        } // code -> node (to add parents)
        //const codeRemap = {} // code -> latest remapping

        _.each(sortedItems, (item) => {
            const parent = item.input;
            const child = item.output;
            const eventId = item.event_id;
            const eventTime = item.when;

            //debugger
            // verify if parent must be added to root
            if (!(parent in nodeMap)) {
                //const serial = _.split(parent, ',')
                //const prodClass = extractProductClassCode(serial[0])
                //return {...node, cnt: prodClassCounter[prodClass]}
                const node = {id: parent, parentIds: ["Roots"], eventIds: [eventId], eventTime:eventTime};
                nodeMap[parent] = node;
                tree.push(node);
            }

            // add or update node
            let id = child;
            let node;
            if (id !== null) {
                if (id in nodeMap) {
                    node = nodeMap[id]
                } else {
                    //const serial = _.split(id, ',')
                    //const prodClass = extractProductClassCode(serial[0])
                    //return {...node, cnt: prodClassCounter[prodClass]}
                    node = {id: id, parentIds: [], eventIds: [], originInput: parent, eventTime:eventTime};
                    nodeMap[id] = node;
                    tree.push(node);
                }
                node.parentIds.push(parent);
                node.eventIds.push(eventId);
            } else {
                // il nodo corrente non ha child... quindi prendo solo i suoi eventi
                nodeMap[parent].eventIds.push(eventId);
            }

        })

        tree = _.map(tree, (node) => {
            //const serial = _.split(node.id, ',')
            //const prodClass = extractProductClassCode(serial[0])
            const _inputProdClassCnt = _.find(items, ['input', node.id])?.input_prod_class_cnt
            const _outputProdClassCnt = _.find(items, ['output', node.id])?.output_prod_class_cnt
            return {...node, inputProdClassCnt: _inputProdClassCnt, outputProdClassCnt: _outputProdClassCnt}
        })

        //const t1 = performance.now()
        //console.log("stratifyTree --->",t1-t0)

        return tree;
    }

    //const t1 = performance.now()
    //console.log("buildDag2 --->",t1-t0)
    return stratifyTree();
}

const D3DagHistoryGraph = ({selectedSgtins, setSelectedSgtins, gtinNameLUT, searchSgtin}) => {
    //const t0 = performance.now()

    const [state,] = useContext(ProductHistoryContext)
    const {historyItems, truncatedHistoryItems, maxChildren, prodClassCounter, historyDirections, eventsMap} = state
    const items = maxChildren === 'all' ? historyItems : truncatedHistoryItems

    const dagData = useMemo(() => {
        if (!eventsMap || !items) {
            return null
        } else {
            return buildDag2(selectedSgtins, items, historyDirections, prodClassCounter, eventsMap)
        }
    }, [eventsMap, selectedSgtins, historyDirections]);

    const ref = createRef();

    //const t1 = performance.now()
    //console.log("D3DagHistoryGraph --->",t1-t0)
    return (
        <div style={{width: '100%'}}>
            {
                dagData ?
                    <ContainerDimensions>
                        {({width, height}) =>
                            <CustomD3DagComponent ref={ref} data={dagData}
                                                  searchSgtin={searchSgtin}
                                                  selectedSgtins={selectedSgtins}
                                                  setSelectedSgtins={setSelectedSgtins}
                                                  gtinNameLUT={gtinNameLUT}
                                                  w={width} h={750}/>
                        }
                    </ContainerDimensions>
                    : null
            }
        </div>
    )
}
export default D3DagHistoryGraph;
