import React, {useContext, useMemo, useState} from 'react'
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import {gray} from "color-name";
import FormGroup from "@material-ui/core/FormGroup";
import Download from '@material-ui/icons/GetApp';
import _ from 'lodash'
import {CSVLink} from "react-csv";
import Moment from "moment";
import Grow from "@material-ui/core/Grow";
import {ProductHistoryContext, ThemeContext} from "../../contexts";
import i18next from "../../i18n/i18next";
import Box from "@material-ui/core/Box";

const CustomCheckbox = withStyles({
    root: {
        color: gray[400],
        '&$checked': {
            color: gray[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SgtinListDownloadComponent = ({productCategories}) => {
    const {accentColor, iconHoverBg} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: 'max-content',
            marginLeft: 'auto',
            position: 'sticky',
            top: 0,
            bottom: 0,
            //zIndex: 1010,
            padding: '2%',
            //margin: '2%',
            //backgroundColor:'rgba(250,250,250,0)'
        },
        panel: {
            backgroundColor: '#fafafa',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: 'bottom',
            color: 'darkgray',
            marginRight: '1.5%',
            '&:hover': {
                background: iconHoverBg,
            },
        },
        details: {
            alignItems: 'center',
        },
        column: {
            //flexBasis: '33.33%',
            marginRight: '2%'
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
            width: '150px'
        },
        chip: {
            marginLeft: '5px',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        }
    }));
    const classes = useStyles()
    const [state] = useContext(ProductHistoryContext)
    const {gtinNameLut} = state

    const icon = <CheckBoxOutlineBlankIcon className={classes.icon} fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon className={classes.icon} fontSize="small"/>;

    const states = useMemo(() => {
        let obj = {}
        //console.log("productCategories: ", productCategories)
        Object.keys(productCategories || []).forEach((category) => {
            obj = {...obj, [category]: false,}
        })
        //console.log("states: ", obj)
        return obj
    }, [productCategories])

    const [checkboxesState, setCheckboxesStateState] = useState(states);
    const [sgtinList, setSgtinList] = useState([["Product Name", "Sgtin / Lgtin"]]);

    const handleChange = (event) => {
        //console.log("checkboxesState: ", checkboxesState)
        setCheckboxesStateState({...checkboxesState, [event.target.name]: event.target.checked});
    };

    const categoryToDownload = useMemo(() => {
        let res = []
        _.each(checkboxesState, function (value, key) {
            if (value)
                res.push(key)
        })
        //console.log("categoryToDownload: ", res)
        return res
    }, [checkboxesState])

    useMemo(() => {
        let res = [["Product Name", "Sgtin / Lgtin"]]
        _.each(categoryToDownload, category => {
            //console.log("handleDownload ---> productCategories  : ", productCategories)
            //res = _.concat(res, productCategories[category])
            productCategories[category].forEach((sgtin) => {
                res.push([gtinNameLut[category], category, sgtin])
            })
        })
        //console.log("Download res: ", res);

        setSgtinList(res)
    }, [categoryToDownload])

    return (
        <div className={classes.root}>
            {productCategories && Object.keys(productCategories).length > 0 ?
            <Grow in={ productCategories && Object.keys(productCategories).length > 0}>
                <Accordion /*defaultExpanded*/ className={classes.panel}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1c-content"
                        id="panel1c-header">
                        <div className={classes.column}>
                            <Typography className={classes.heading}>{i18next.t('productHistory.downloadSerials','Download Serial Codes List')}</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <FormGroup className={classes.column}>
                            {
                                productCategories && Object.keys(productCategories).length > 0 ?
                                    Object.keys(productCategories).map((category) => {
                                        return <FormControlLabel
                                            control={<CustomCheckbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                className={classes.icon}
                                                onChange={handleChange}
                                                name={category}
                                            />}
                                            label={<Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                                                <div>{gtinNameLut[category] || category}</div>
                                                    <Chip
                                                        size={'small'}
                                                        label={productCategories[category].length}
                                                        className={classes.chip}
                                                    />
                                                </Box>
                                            }
                                            key={category}
                                        />
                                    }) : null
                            }
                        </FormGroup>
                        {/*<div className={clsx(classes.column, classes.helper)}>
                    <Typography variant="caption">
                        Select the categories of which you wish to download the related sgtins/lgtins
                        <br/>
                    </Typography>
                </div>*/}
                    </AccordionDetails>
                    <Divider/>
                    <AccordionActions>
                        <CSVLink
                            data={sgtinList}
                            filename={"product_id_list_" + Moment(new Date()).format('YYYY-MM-DDThh:mm:ss') + ".csv"}
                            style={{textDecoration:'none'}}
                        >
                            <Button size="small" color="default"
                                    endIcon={<Download style={{color: 'darkgray',}}/>}
                            >
                                Download CSV file
                            </Button>
                        </CSVLink>
                    </AccordionActions>
                </Accordion>
            </Grow> : null}
        </div>
    )
}

export default SgtinListDownloadComponent
