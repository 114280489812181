import React from 'react'
//import {computeTimeSlot, getTimeClusters} from "./Time";
//import XYPlot from "react-vis/es/plot/xy-plot";
//import {HorizontalGridLines, LineMarkSeries, LineSeries, VerticalGridLines, XAxis, YAxis} from "react-vis";
//import {palette} from "./MarbleDiagram";
import moment from "moment";
import ".//marble-style.css";
//import useAxios from "axios-hooks";
//import axios from "axios";

export function formatFunction(max, decimals = 0) {
    const k = 10 ** Math.floor(decimals)
    if (max < 36e5) return v => `${Math.round(k * moment.duration(v).asMinutes()) / k} min`
    if (max < 96 * 36e5) return v => `${Math.round(k * moment.duration(v).asHours()) / k} h`
    if (max < 21 * 24 * 36e5) return v => `${Math.round(k * moment.duration(v).asDays()) / k} d`
    if (max < 56 * 24 * 36e5) return v => `${Math.round(k * moment.duration(v).asWeeks()) / k} w`
    return v => `${Math.round(k * moment.duration(v).asMonths()) / k} m`
}


function LinkDetails({link, date, timeScale, onClick, companyNameLut}) {
    /*

    const [{data: pairs, loading: loadingPairs, error: errorLinkPairs},] = useAxios({
            url: 'api/capture/epcis_events/marble/v2/arc_pairs/?arc_id=' + link.id.replace("-->", ':')
        },
        {useCache: false}
    );

    const [eventsMap, setEventsMap] = useState(null)


    useMemo(() => {
        if (!pairs)
            return null

        let ids = ''
        pairs.forEach((pair) => {
            if (!ids.includes(pair[0])) {
                ids += pair[0] + ','
            }
            if (!ids.includes(pair[1])) {
                ids += pair[1] + ','
            }

        })

        if (ids.endsWith(','))
            ids = ids.substring(0, ids.length - 1)

        const url = "/api/capture/epcis_events/_by_event_ids/" + ids + "?limit=0"
        axios.get(url).then(res => {
            if (res) {
                console.log("THEN --> eventsMap: ", res.data)
                const eventsMap = {}
                res.data.data.forEach((event) => {
                    eventsMap[event.eventID] = event
                })

                setEventsMap(eventsMap)
            }
        })
    }, [pairs])

    const linkPairsData = useMemo(() => {
        if(!eventsMap)
            return
        console.log("eventsMap: ", eventsMap)

        return pairs.map((pair) => ({
                e0: eventsMap[pair[0]],
                e1: eventsMap[pair[1]]
            }))

    }, [eventsMap])

    const timeSlot = computeTimeSlot(timeScale)(date)

    const timeClusters = getTimeClusters(date, timeScale)
    const data = useMemo(() =>
            linkPairsData ?             console.log("linkPairsData:",linkPairsData)
            && (timeClusters.map(([from,to],cluster) => {
                    const mid = new Date((from.getTime()+to.getTime())/2)
                        return linkPairsData
                            .filter(p => p.e1.eventTime >= from && p.e1.eventTime <= to)
                            .map((p, i) =>
                                ({
                                    x: p.e1.eventTime,
                                    y: (p.e1.eventTime.getTime() - p.e0.eventTime.getTime()),
                                    color: cluster,
                                    pair: p
                                }))
                    }
                ).reduce((a,b)=> {a.push.apply(a,b); return a},[])
                    .sort((a,b) =>
                        a.x.getTime()-b.x.getTime()
                    )
            ) : null,
        [timeSlot,timeScale, linkPairsData]
    )

    const [max, min, average, averageData, f] = useMemo(() => {
        if(!data || data.length === 0)
            return [null, null, null, null, null]

        console.log("LinkDetails ---> data:",data)
        let max = data[0].y;
        let min = data[0].y;
        data.forEach(d => {
            if (d.y > max) max = d.y;
            if (d.y < min) min = d.y
        })
        let average = data.reduce((a, b) => a + b.y, 0) / data.length
        let averageData = [
            {x: data[0].x, y: average},
            {x: data[data.length - 1].x, y: average}]
        let f = formatFunction(max)
        return [max, min, average, averageData, f]
    }, [data])
     */

    function parseLinkName(id) {
        const res = id.split("-->")
        const source = res[0]
        const target = res[1]
        return companyNameLut[source] ? companyNameLut[source] + ' --> ' + companyNameLut[target] : source + ' --> ' + target
    }

    /*
    if (data && data.length === 0) return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <h3>{parseLinkName(link.id)}</h3>
            <div style={{flex: 1}}>No data in the current time frame</div>
        </div>
    )
    */

    return (
        <div>

            <div>
                <h3>{parseLinkName(link.id)}</h3>
                {
                    /*
                    <XYPlot
                    height={300}
                    width={300}
                    xType={'time'}
                    yType={'time'}
                    colorType={'category'}
                    colorDomain={[0, 1, 2, 3, 4, 5, 6]}
                    colorRange={palette}
                    style={{fill: 'none'}}
                >
                    <VerticalGridLines style={{stroke: '#cccccc'}}/>
                    <HorizontalGridLines style={{stroke: '#cccccc'}}/>
                    <XAxis style={{line: {stroke: '#222222'}, text: {fontSize: '6px'}}}/>
                    <YAxis tickFormat={f}
                           style={{
                               line: {stroke: '#222222'},
                               text: {fontSize: '6px'}
                           }}
                    />
                    <LineMarkSeries
                        data={data.reduce((a, b, i, l) => {
                            const len = l.length
                            if (len <= 1000) {
                                a.push(b)
                                return a
                            } else {
                                if (a.length <= i * 1000 / l.length)
                                    a.push(b)
                                return a
                            }
                        }, [])
                        }
                        stroke={'#186c6f'}
                        stroke={'rgba(24,108,11,0.1)'}
                        onValueClick={(datapoint) => onClick && onClick({type: 'pair', pair: datapoint.pair})}
                    />
                    <LineSeries
                        data={averageData}
                        stroke={'darkred'}
                        strokeWidth={1}
                        strokeStyle={'dashed'}
                    />
                </XYPlot>
                <div style={{textAlign: 'center'}}>Delivery time</div>
                <div>
                    <p>Average: {formatFunction(average, 1)(average)}</p>
                    <p>Maximum: {formatFunction(max, 1)(max)}</p>
                    <p>Number of items: {data.length} {data.length > 1000 ? " (clamped to 1000)" : ""}</p>
                </div>
                    */
                }
            </div>
        </div>
    )

}

export default LinkDetails
