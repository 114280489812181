import React, {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import SelectInputTypeComponent from "./SelectInputTypeComponent";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {eventFieldNameTypes, inputTypes} from "../../../utils/CbvUtils";
import i18next from "../../../i18n/i18next";

const UtcOffsetComponent = ({formDataFieldName, formDataFieldValue, handleChange}) => {

    const [value, setValue] = useState(formDataFieldValue ? formDataFieldValue : inputTypes.EXTERNAL.value)

    const handleSelectChange = (event) => {
        setValue(event.target.value)
        handleChange(formDataFieldName, event.target.value)
    };

    return (
        <Box p={2} pt={1} style={{/*flex:'1', */marginRight:'2%', marginTop:'2%'}}>
            <FormLabel component="legend">{i18next.t('createEventModel.chooseUTC', "Choose the UTC Event Timezone Offset")}</FormLabel>
            <Select onChange={handleSelectChange}
                    value={value/*(formData.fixedBizLocation !== null
                && formData.bizLocInputType !== inputTypes.EXTERNAL.value) ?
                inputTypes.COMPUTED.value : inputTypes.EXTERNAL.value*/}>
                {
                    [inputTypes.COMPUTED.value, inputTypes.EXTERNAL.value].map(
                        (option) => (
                            <MenuItem value={option} key={option}>{option}</MenuItem>)
                    )
                }
            </Select>
            {
                value === inputTypes.COMPUTED.value ?
                    <FormHelperText>{i18next.t('createEventModel.computedUTC', 'The Event Timezone Offset is going to be computed by the system based on the spatial information provided in the WHERE section above')}</FormHelperText>
                : <FormHelperText>{i18next.t('createEventModel.externalUTC', 'The Event Timezone Offset is going to be specified by the user during the creation the event')}</FormHelperText>
            }
        </Box>
    )
}

const WhenComponent = ({handleChange, formData, setStepValidation}) => {

    useEffect(() => {
        setStepValidation(true)
    }, [])

    return (
        <div>
            <SelectInputTypeComponent options={[inputTypes.COMPUTED.value, inputTypes.CLIENT_COMPUTED.value, inputTypes.EXTERNAL.value]}
                                      formLabel={i18next.t('createEventModel.eventTimeInputType', "Event Time Input Type")}
                                      formDataFieldName={eventFieldNameTypes.EVENT_TIME.name}
                                      formDataFieldValue={formData.eventTime}
                                      handleChange={handleChange}
                                      description={eventFieldNameTypes.EVENT_TIME.description}
                                      disabled={false}
            />
            <SelectInputTypeComponent options={[inputTypes.COMPUTED.value, inputTypes.CLIENT_COMPUTED.value, inputTypes.EXTERNAL.value]}
                                      formLabel={i18next.t('createEventModel.eventRecordTimeInputType', "Choose the Record Time Input Type")}
                                      formDataFieldName={eventFieldNameTypes.RECORD_TIME.name}
                                      formDataFieldValue={formData.recordTime}
                                      description={eventFieldNameTypes.RECORD_TIME.description}
                                      handleChange={handleChange}
            />
            <UtcOffsetComponent formData={formData}
                                formDataFieldName={'eventTimeZoneOffset'}
                                formDataFieldValue={formData.eventTimeZoneOffset}
                                handleChange={handleChange}/>
        </div>
    )
}

export default WhenComponent
