const login = ( jwt_token ) => {
    localStorage.setItem('token', jwt_token.access_token);
    localStorage.setItem('refresh_token', jwt_token.refresh_token);
}

const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    //localStorage.clear()
}

export { login, logout }
