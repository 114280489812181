import React, {useContext} from "react";
import _ from "lodash";
import {bizTransactionTypes} from "../../../utils/CbvUtils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Box, Collapse, Fade} from "@material-ui/core";
import {Controller} from "react-hook-form";
import FieldInfo from "../FieldInfo";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import {ThemeContext} from "../../../contexts";
import {useTranslation} from "react-i18next";

const BizTransactionBuilder = (props) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)

    const BizType = ({value, onChange}) => {

        const _bizType = value ? value : _.find(bizTransactionTypes, ['value', props.bizType])

        const handleChange = (event) => {
            //console.log("BizType handleChange --> ",event.target.value.value)
            //(async () => {
            // versione asincrona
            // let descriptions = {}
            //descriptions[event.target.value] = await fetchDescription(event.target.value)
            //console.log('DESCRIPTIONS ---> ', descriptions)
            props.setState({
                ...props.state,
                bizTransactionType: event.target.value.value,
                //bizTransactionDescription: descriptions
                bizTransactionDescription: _bizType ? _bizType.description : ''
            })
            // })();

            onChange(event.target.value)
        }

        return (
            <FormControl style={{flex: 1}}>
                <InputLabel>{t('createEvent.transactionType', 'Type of Transaction')}</InputLabel>
                <Select
                    value={_bizType}
                    //defaultValue={value ? value : ''}
                    error={!_bizType}
                    onChange={handleChange}
                    displayEmpty
                    disabled={props.disabled}
                >
                    {
                        _.map(bizTransactionTypes, (type, index) => (
                            <MenuItem value={type} key={index}>{type.name}</MenuItem>))
                    }
                </Select>
            </FormControl>
        )
    };

    function CodeType(props) {
        function handleChange(event) {
            props.setState({...props.state, codeType: event.target.value})
            props.onChange(event.target.value)
        }

        return <div style={{flex: 1, display: 'flex', alignItems: 'baseline', marginRight: "2%"}}>
        <FormControl
            style={{flex: 1}}>
            <InputLabel>{t('createEvent.whereDidYouGetTheCode')}</InputLabel>
            <Select
                //displayEmpty
                //disabled={props.disabled}
                value={props.value}
                onChange={handleChange}
                label="Code Type">
                <MenuItem value={'inside'}>{t('createEvent.codeIsMine')}</MenuItem>
                <MenuItem value={'outside'}>{t('createEvent.codeIsFromPartner')}</MenuItem>
            </Select>
            {/*<FormHelperText>Select the source type of the transaction code</FormHelperText>*/}
        </FormControl>
            {
                props.value?
                    <FieldInfo description={props.value === 'inside' ?
                        t('createEvent.codeIsMineDescription')
                        :  t('createEvent.codeIsFromPartnerDescription')}/> : null
            }
        </div>
    }

    const _bizType = props.bizType ? _.find(bizTransactionTypes, ['value', props.bizType])
        : _.find(bizTransactionTypes, ['value', props.state.bizTransactionType])

    const handleGenerateCodeCheckedChange = (event) => {
        props.setState({...props.state,
            generateCodeCheck: event.target.checked,
            codeType: undefined,
            bizTransaction: null,
        })
    };

    return <Box>
        <Box mb={3} style={{display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", alignItems: "center", flex: 1}}>
            {
                <Controller
                    as={
                        <BizType/>
                    }
                    name="bizType"
                    control={props.control}
                    disabled={props.disabled}
                    state={props.state}
                    setState={props.setState}
                />
            }
            {/* VERSIONE ASINCRONA<FieldInfo description={props.state.bizTransactionDescription ?
                props.state.bizTransactionDescription[props.bizType] : null}/>*/}
            <FieldInfo description={_bizType ? _bizType.description : ''}/>
            {<Fade in={props.state.generateCodeCheck} unmountOnExit mountOnEnter>
                <Chip
                    size={'small'}
                    className={props.classes.infoChip}
                    icon={<InfoIcon style={{color: accentColor}}/>}
                    label={<i>{t('createEvent.iChainWillGenerateAbizTransCodeByAdding', "iChain will generate a code for this transaction, by pressing 'Add'")}</i>}
                />
            </Fade>}
        </Box>
        <Collapse in={!!props.state.bizTransactionType}>
            <Divider/>
            <Box pt={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Tooltip arrow
                         title={t('createEvent.iChainWillGenerateAbizTransCodeByAddingV2', "If you don't have a transaction code, by clicking 'Add' iChain will generate a code for the selected type of transaction")}>
                    <WarningIcon className={props.classes.warningIcon}/>
                </Tooltip>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={props.state.generateCodeCheck}
                                           onChange={handleGenerateCodeCheckedChange}
                                           color="default"/>}
                        label={<Typography variant={"caption"}>{t('createEvent.iDontHaveAdocId')}</Typography>}
                        labelPlacement="end"/>
                </FormControl>
            </Box>
            <Collapse in={!props.state.generateCodeCheck} unmountOnExit={true}>
                <div style={{display: 'flex', justifyContent: "flex-start", alignItems: "baseline"}}>
                    <Controller
                        as={
                            <CodeType/>
                        }
                        name="codeType"
                        control={props.control}
                        state={props.state}
                        setState={props.setState}
                    />
                    <TextField
                        style={{flex: 2}}
                        size={'small'}
                        variant={"outlined"}
                        label={t('entities.document.identifier', "Document Identifier")}
                        name={props.bizType ? props.bizType : 'bizTransaction'}
                        inputRef={props.inputRef}
                        error={props.state.generateCodeCheck ? false : !props.fieldValues}
                        disabled={props.state.generateCodeCheck}
                        helperText={t('createEvent.insertDocId')+(props.state.codeType && props.state.codeType === 'outside'?
                            t('createEvent.insertDocIdDescription') : '')
                        }
                    />
                </div>
            </Collapse>
        </Collapse>

    </Box>
}

export default BizTransactionBuilder