import React, {useContext} from "react";
import {Box, Button} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import useAxios from "axios-hooks";
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import ClearIcon from '@material-ui/icons/Clear';
import {ThemeContext} from "../contexts";
import {useTranslation} from "react-i18next";

const UploadImage = ({value: imageId, onChange}) => {
    const { t } = useTranslation();

    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        preview: {
            //width: "120px",
            height: "120px",
            objectFit: "cover"
        },
        button: {
            borderRadius: '20px',
            backgroundColor: accentColor,
            color: 'white',
        },
    }));
    const classes = useStyles();
    // const [src,setSrc] = useState(null)

    const [, postExecute] = useAxios(
        {
            url: "api/uploads/uploads/",
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    // useEffect( () => {
    //     if(imageId){
    //         axios.get("api/uploads/uploads/" + imageId).then(res => {
    //             console.log('ID --> ', imageId)
    //             setSrc(res.data)
    //         })
    //     }
    // }, [imageId])


    const handleChange = async (e) => {
        let formData = new FormData();
        let file = e.target.files[0]
        let raw = Object.defineProperty(file, 'name', {
            writable: true,
            value: 'idcliente_' + file.name
        });
        formData.append('file', raw)

        postExecute({data: formData})
            .then(
                (res) => {
                    onChange(res.data.id)
                }
            )
            .catch(
                (err) => console.log('error uploading image:', err)
            )
    }

    const onClearClick = () => {
        onChange(undefined)
    }

    const src = "/api/uploads/uploads/" + imageId;
    const imageLabel = t('entities.image')

    return (
        imageId ? (
            <Box>
                <img alt="my_product_img" src={src} className={classes.preview}/>
                <Box pt={1} textAlign="center">
                    <IconButton color="default" onClick={onClearClick}>
                        <ClearIcon />
                    </IconButton>
                    <input color="default" accept="image/*" type="file"
                           onChange={handleChange} id="icon-button-file"
                           style={{display: 'none'}}/>
                    <label htmlFor="icon-button-file">
                        <IconButton color="default" component="span">
                            <ReplayIcon />
                        </IconButton>
                    </label>
                </Box>
            </Box>
        ) : (
            <Box>
                <input color="default" accept="image/*" type="file"
                       onChange={handleChange} id="icon-button-file"
                       style={{display: 'none'}}/>
                <label htmlFor="icon-button-file">
                    <Button
                        className={classes.button}
                        color="default"
                        variant="contained"
                        size="medium"
                        component="span"
                        startIcon={<PublishOutlinedIcon/>}>
                        {t('actions.upload', {what: imageLabel})}
                    </Button>
                </label>
            </Box>
        )
    )
}

export default UploadImage
