import React, {useContext, useEffect, useState} from "react";
import WTable from "../../components/events/WTable";
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid";
import {Box, Button, Tooltip} from "@material-ui/core";
import ReactJson from "react-json-view";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {RoutingContext, ThemeContext} from "../../contexts";
import useAxios from "axios-hooks";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import _ from 'lodash'
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import FieldInfo from "../generate_new_event/FieldInfo";
import Chip from "@material-ui/core/Chip";
import {Check, Receipt} from "@material-ui/icons";

const EventModelDetail = (props) => {
    const {t} = useTranslation()
    const wData = (props.location ? props.location.state : props.props)
    console.log("wData:",props)
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles({
        stepIcon: {
            "& .MuiStepIcon-root.MuiStepIcon-active": {
                color: primaryColor,
            },
            "& .MuiPaper-root": {
                color: 'rgba(0, 0, 0, 0.87)',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: 'transparent'
            }
        },
        statusChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        }
    })
    const classes = useStyles()

    const myContext = useContext(RoutingContext);

    const [state, setState] = useState({
        anchorEl: null,
        rawView: false,
        activeStep: wData.version - 1,
        activeVersion: wData
    });

    const [{data: allVersionsTemplate}, fetchAllVersions] = useAxios({
            url: "/api/capture/event_templates/by_name/all/?name=" + wData.name
        },
        {manual: true}
    )

    useEffect(() => {
        if(!props.hideVersion)
            fetchAllVersions()
    }, [])

    const handleVersionMenuClose = () => {
        setState({...state, anchorEl: null});
    };

    const handleButtonClickEdit = (data) => {
        const _data = !data.is_draft ? {...data, editMode: true} : {
            ...data,
            fromRulesConf: true, // le Bozze sono legate al configuratore grafico delle regole
            editMode: true,
            is_draft: data.is_draft,
            _id: data.mongoid||data._id,
        }
        myContext.setRoute('CreateEventTemplate', _data);
    }

    const onRawViewButtonClick = () => {
        setState({...state, rawView: !state.rawView});
    }

    function handleStep(version, index) {
        //console.log("activeVersion", version)
        setState({...state, activeStep: index, activeVersion: version});
    }

    const onGenerateNewButtonClick = () => {
        myContext.setRoute('CreateEvent', wData)
    }

    return (
        <div>
            <Box mb={3} style={{
                position: 'sticky',
                top: !props.noShow ? 54 : 0,
                bottom: 60,
                zIndex: 1010
            }}
                 display="flex"
                 justifyContent="flex-end"
                 alignItems={'center'}
            >
                { !props.noShow ?
                    <Box display={'flex'} alignItems={'center'} mr={1}>
                    <Tooltip arrow title={t('eventModels.generateNewEventOfThisType')}>
                        <IconButton
                            variant="contained"
                            onClick={onGenerateNewButtonClick}
                        >
                            <AddAlertIcon style={{color: accentColor}}/>
                        </IconButton>
                    </Tooltip>

                        <Box ml={1}>
                            <Button
                                variant="contained"
                                endIcon={<EditIcon style={{color: 'darkgray'}}/>}
                                onClick={() => {
                                    handleButtonClickEdit(wData)}}>
                                {t('actions.edit', {what: `${wData.version === 0 ? t('entities.draft') : "v"+wData.version}`})}
                            </Button>
                            {
                                allVersionsTemplate && allVersionsTemplate.count > 1 ?
                                    <Menu
                                        variant={"selectedMenu"}
                                        anchorEl={state.anchorEl}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        keepMounted
                                        open={Boolean(state.anchorEl)}
                                        onClose={handleVersionMenuClose}
                                    >
                                        {
                                            allVersionsTemplate.data.map((version, index) =>
                                                <MenuItem
                                                    key={index}
                                                    onClick={() => {
                                                        handleButtonClickEdit(version)
                                                    }}
                                                >
                                                    <ListItemText primary={`${_.startCase(t('entities.version'))} ${version.version}`}
                                                                  style={{color: primaryColor}}/>
                                                </MenuItem>
                                            )
                                        }
                                    </Menu> : null
                            }
                        </Box>
                    </Box> : null
                }
                <Button
                    variant="contained"
                    endIcon={<VisibilityIcon style={{color: 'darkgray'}}/>}
                    onClick={onRawViewButtonClick}>
                    {state.rawView ? t('entities.viewMode.parsed') : t('entities.viewMode.raw')}
                </Button>
            </Box>
            <div className={classes.stepIcon}>
                {
                    !props.hideVersion && allVersionsTemplate && allVersionsTemplate.count > 1 ?
                        <Stepper alternativeLabel nonLinear activeStep={state.activeStep}
                                 style={{backgroundColor: 'trasparent'}}>
                            {
                                _.orderBy(allVersionsTemplate.data, 'version').map((version, index) => {
                                    const stepProps = {};
                                    const buttonProps = {};

                                    return (
                                        <Step key={version.version} {...stepProps}>
                                            <StepButton
                                                onClick={() => {
                                                    handleStep(version, index)
                                                }}
                                                {...buttonProps}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Grid item>
                                                            <ListItemText primary={`${_.startCase(t('entities.version'))} ${version.version}`}
                                                                          secondary={_.startCase(version.type)}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                        </Stepper>
                        : null
                }
            </div>
            {
                state.rawView ? <ReactJson src={state.activeVersion} style={{marginLeft: '5%'}}/> :
                    <Grid container>
                        <Grid item md={12} xs={12} style={{paddingInline: '2%'}}>
                            <Box mb={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Typography variant={"h5"} align={'center'}>{state.activeVersion.name}</Typography>
                                <FieldInfo description={state.activeVersion.description}/>
                                <Chip variant={'outlined'}
                                      className={classes.statusChip}
                                      icon={state.activeVersion.is_draft === false
                                          ? <Check style={{color: accentColor}}/>
                                          : <Receipt style={{color: accentColor}}/>}
                                      label={state.activeVersion.is_draft === false
                                          ? state.activeVersion.type + (state.activeVersion.version ? (state.activeVersion.version + ', version '+state.activeVersion.version) : '')
                                          : t('entities.draft')}/>
                            </Box>
                        </Grid>
                        <Grid item md={12} xs={12} style={{paddingInline: '2%'}}>
                            <WTable title={"What"} bgColor={primaryColor} wData={state.activeVersion}/>
                            <WTable title={"When"} bgColor={primaryColor} wData={state.activeVersion}/>
                            <WTable title={"Where"} bgColor={primaryColor} wData={state.activeVersion}/>
                            <WTable title={"Why"} bgColor={primaryColor} wData={state.activeVersion}/>
                            {
                                state.activeVersion.ilmd.length !== 0 ?
                                    <WTable title={"Instance/Lot Master Data"}
                                            bgColor={primaryColor}
                                            wData={state.activeVersion}/> : null
                            }
                        </Grid>
                    </Grid>
            }
        </div>
    )
}

export default EventModelDetail
