import React, {useContext, useRef, useState} from "react";
import {Box, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CreateEventContext, ThemeContext} from "../../contexts";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import useAxios from "axios-hooks";
import _ from "lodash"
import axios from "axios";
import EventTypologyComponent from "./EventTypologyComponent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import EventWhatComponent from "./EventWhatComponent";
import EventWhyComponent from "./EventWhyComponent";
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import EventWhereComponent from "./EventWhereComponent";
import EventWhenComponent from "./EventWhenComponent";
import Moment from 'moment'
import EventIlmdComponent from "./EventIlmdComponent";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import EventDetail from "../../components/events/EventDetail";
import {useHistory} from "react-router-dom";
import {eventFieldNameTypes, inputTypes} from "../../utils/CbvUtils";
import {useTranslation} from "react-i18next";
import {severityTypes} from "../../utils/Utils";

const CreateEventForm = () => {
    const {t} = useTranslation()
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        actionsContainer: {
            alignItems: 'baseline',
            display: 'flex',
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        error: {
            color: 'red',
            margin: '0px'
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            fontWeight: 'bold',
            marginRight: '1%',
            borderRadius: '20px',
            width: 'fit-content',
            boxShadow: theme.shadows[2]
        },
    }));
    const classes = useStyles()

    const eventContext = useContext(CreateEventContext);
    const history = useHistory()

    const initialAlertMessageState = {open: false, message: '', severity: severityTypes.WARNING}
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({...state, openAlertMessage: {...state.openAlertMessage, open: false}});
    };

    const initialState = {
        activeStep: 0,
        openSummary: false,
        eventName: eventContext.fromEventModelsTableData ? eventContext.fromEventModelsTableData.name : '',
        eventType: eventContext.fromEventModelsTableData ? eventContext.fromEventModelsTableData.type : '',
        bizStep: eventContext.fromEventModelsTableData ? _.find(eventContext.fromEventModelsTableData.why, ['name', 'bizStep']).value : null,
        templateData: eventContext.fromEventModelsTableData ? eventContext.fromEventModelsTableData : {},

        openAlertMessage: initialAlertMessageState,

        summaryData: null,
        data2send: {},
        dry_run: true
    }

    const [state, setState] = useState(initialState)
    const [stepValidation, setStepValidation] = useState(false);

    const descriptionElementRef = useRef(null);

    /** POST EVENT al server */
    const [, postExecute] = useAxios(
        {
            url: "api/capture/epcis_events/simplified/?event_name=" + state.eventName+"&dry_run="+state.dry_run,
            method: "POST",
            headers: {
                'Media-Type': 'application/json',
            }
        },
        {manual: true}
    )

    const handleChange = (_eventName, _type, _bizStep) => {
        setState({...state, eventName: _eventName, eventType: _type, bizStep: _bizStep})
    }

    const getSteps = () => {
        return [t('entities.eventTypology'), t('createEvent.insertDataAndGenerate', 'Insert data and Generate')];
    }
    const getStepContent = (step) => {
        if (step === 0)
            return <EventTypologyComponent eventName={state.eventName}
                                           handleChange={handleChange}
                                           setStepValidation={setStepValidation}/>
        else if (step === 1) {
            return <Box p={3}>
                <EventWhatComponent templateData={state.templateData}/>
                {_.some(state.templateData.why, ['input', inputTypes.EXTERNAL.value])?
                    <EventWhyComponent templateData={state.templateData}/> : null}
                {_.some(state.templateData.where, _.overSome([['input', inputTypes.EXTERNAL.value], ['input', inputTypes.PARTIALLY_FIXED.value]])) ?
                    <EventWhereComponent whereData={state.templateData.where}/> : null}
                {_.some(state.templateData.when, _.overSome([['input', inputTypes.EXTERNAL.value], ['input', inputTypes.CLIENT_COMPUTED.value]])) ?
                    <EventWhenComponent templateData={state.templateData}/> : null}
                {state.templateData.ilmd && state.templateData.ilmd.length > 0 ?
                    <EventIlmdComponent templateData={state.templateData}/> : null}
            </Box>
        } else if (step === 2)
            return <Box p={3}>{t('entities.summary')}</Box>

    }

    const steps = getSteps();

    const handleNext = async () => {
        if (state.activeStep === 0 && !eventContext.fromEventModelsTableData) {
            const url = "/api/capture/event_templates/by_name/?name=" + state.eventName
            axios.get(url).then(model => {
                if (model) {
                    eventContext.setForm(model.data)
                    setState({
                        ...state,
                        activeStep: state.activeStep + 1,
                        //eventForm: schema,
                        eventName: model.data.name,
                        eventType: model.data.type,
                        bizStep: _.find(model.data.why, ['name', 'bizStep']).value,
                        templateData: model.data
                    })
                    //setStepValidation(eventContext.getValidation())
                }
            }).catch(() => {
                setState({
                    ...state,
                    openAlertMessage: {
                        open: true,
                        message: t('createEvent.modelNotFound', "Event model not found!"),
                        severity: severityTypes.ERROR
                    }
                });
                console.log('error fetching model')
            })
        } else {
            setState({...state, activeStep: state.activeStep + 1})
            //setStepValidation(eventContext.getValidation())
        }
    };

    const handleBack = () => {
        if (window.confirm(t('createEvent.dataWillBeLost', 'If you exit the page the input data will be lost'))) {
            eventContext.resetValidation()
            setState({...state, activeStep: state.activeStep - 1})
        }
    };

    const prepareData2Send = (formattedData) => {
        //console.log("prepareData2Send --> formattedData:",formattedData)
        const data2Send = {params:{}, docids: []}
        _.forEach(formattedData, (fields, section) => {
            if (section !== 'ilmd' && section !== 'docids' && Array.isArray(fields)) {
                _.forEach(fields, (field) => {
                    if (field.name === eventFieldNameTypes.BIZTRANSACTIONLIST.name
                        || field.name === eventFieldNameTypes.SOURCELIST.name
                        || field.name === eventFieldNameTypes.DESTINATIONLIST.name) {
                        data2Send.params[field.name] = field.value
                        //console.log("data2Send[field.name]:", data2Send[field.name])
                    } else if (eventContext.getValidationSchema()[section][field.name] && field.value)
                        data2Send.params[field.name] = field.value
                })
            } else if (section === 'ilmd' && fields.length > 0) {
                data2Send.params[section] = _.map(fields, (feature) => ([feature.ns, feature.name, feature.value]))
            } else if (section === 'docids' && fields.length > 0) {
                data2Send[section] = _.map(fields, (association) => ({doc_public_id: association.bizTransaction, doc_db_id: association.documentId}))
            }
        })
        return data2Send
    }

    const handleGenerateEvent = () => {
        if (state.data2send) {
            //console.log("INVIO AL SERVER! ---> data2Send:", state.data2send)
            /** POST REALE CON DRY_RUN A FALSE */
            postExecute({data: state.data2send})
                .then((res) => {
                    setTimeout(function () {
                        setState({
                            ...state,
                            openAlertMessage: {open: true, message: t('createEvent.eventGenerated', "Event Generated!"), severity: severityTypes.SUCCESS},
                            openSummary: true,
                            summaryData: null,
                            data2send: {},
                            dry_run: true,
                        });
                        eventContext.resetForm()
                    }, 500);
                })
                .catch((err) => {
                    console.log('error posting new event', err)
                    setState({
                        ...state,
                        openSummary: false,
                        openAlertMessage: {
                            open: true,
                            message: t('errors.createEventError'),
                            severity: severityTypes.ERROR
                        }
                    });
                })
        }
    };

    const handleFinish = async () => {
        if (eventContext.onSubmit()) {
            const formattedData = _.cloneDeep(eventContext.getForm())
            _.forEach(formattedData.what, ((field) => {
                if (field.type === eventFieldNameTypes.QUANTITYLIST.type)
                    field.value = field.value.map((codeObj) => ([codeObj.value, codeObj.quantity, codeObj.uom]))
                else if (field.type === eventFieldNameTypes.EPCLIST.type)
                    field.value = field.value.map((codeObj) => (codeObj.value))
                else if (field.name === eventFieldNameTypes.PARENT_ID.name) {
                    field.value = field.value.value // field.value.value è il codice effettivo del parent
                }
            }))

            _.forEach(formattedData.when, ((field) => {
                if (field.input === inputTypes.CLIENT_COMPUTED.value) {
                    field.value = Moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
                }
            }))

            _.forEach(formattedData.where, ((field) => {
                const newFieldValue = new Array(field.children.length)
                if ((field.name === eventFieldNameTypes.SOURCELIST.name || field.name === eventFieldNameTypes.DESTINATIONLIST.name)
                    && field.input === inputTypes.PARTIALLY_FIXED.value) {
                    /** le location code devono essere nello stesso ordine in cui sono i tipi di children */
                    _.forEach(field.children, (type, index) => {
                        newFieldValue[index] = _.find(field.value, function (o) {return o[1] === type})[0]
                    })
                    field.value = newFieldValue
                }
            }))

            const data2Send = prepareData2Send(formattedData)
            //console.log("data2Send:",data2Send)
            /** CHIAMATA DRY_RUN PER VISUALIZZARE IL SUMMARY */
            postExecute({data: data2Send})
                .then((res) => {
                    //setTimeout(function () {
                        setState({
                            ...state,
                            summaryData: res.data,
                            data2send: data2Send,
                            dry_run: false,
                            openSummary: true,
                        });
                    //}, 500);
                })
                .catch((err) => {
                    console.log('error visualizing summary', err)
                    setState({
                        ...state,
                        openSummary: false,
                        openAlertMessage: {
                            open: true,
                            message: t('errors.createEventError'),
                            severity: severityTypes.ERROR
                        }
                    });
                })

        } else {
            setState({
                ...state,
                openAlertMessage: {
                    open: true,
                    message: t('errors.allRequiredField'),
                    severity: severityTypes.WARNING
                }
            });
        }
    };

    return (
        <Box>
            <form>
                <Stepper style={{backgroundColor: 'transparent'}} activeStep={state.activeStep}
                         orientation="horizontal">
                    {steps.map((label, index) => {
                        const labelProps = {}
                        return (<Step key={label}>
                            <StepLabel {...labelProps}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginLeft: '10px'
                                }}>
                                    {
                                        !!state.bizStep && index === 0 ?
                                            <BizStepIcon bizstep={state.bizStep}
                                                         color={primaryColor}/>
                                            : null
                                    }
                                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '10px'}}>
                                        {label}
                                        <Typography variant="caption">
                                            {state.eventName && index === 0 ? _.startCase(state.eventName) : ''}
                                        </Typography>
                                        <Typography variant="caption">
                                            {!!state.eventType && index === 0 ? _.startCase(state.eventType) : ''}
                                        </Typography>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>)
                    })}
                </Stepper>
                <div className={classes.actionsContainer}>
                    <Button
                        disabled={state.activeStep === 0}
                        onClick={handleBack}
                        style={{marginRight: '1%',}}>
                        {t('actions.back')}
                    </Button>
                    <Button
                        className={classes.button}
                        variant="contained"
                        disabled={!stepValidation}
                        onClick={state.activeStep === steps.length - 1 ? handleFinish : handleNext}>
                        {state.activeStep === steps.length - 1 ? t('actions.generate') : t('actions.next')}
                    </Button>
                </div>
                <Paper>
                    {getStepContent(state.activeStep)}
                </Paper>
                <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={state.openAlertMessage.open}
                          onClose={handleCloseErrorMessage}>
                    <Alert onClose={handleCloseErrorMessage}
                           severity={state.openAlertMessage.severity}>{state.openAlertMessage.message}</Alert>
                </Snackbar>
                {
                    state.openSummary ?
                    state.summaryData ?
                        <Dialog disableBackdropClick fullWidth={true} maxWidth={"md"} open={state.openSummary}
                                scroll='paper'>
                            <DialogTitle id="customized-dialog-title">
                                {t('createEvent.generateEvent')} <strong><i>{_.startCase(eventContext.getForm().name)}</i></strong>?
                            </DialogTitle>
                            <DialogContent ref={descriptionElementRef}>
                                {
                                    state.data2send ?
                                        <EventDetail data={state.summaryData} dryRun={true} docids={state.data2send.docids} classes={classes}/> : null
                                }
                            </DialogContent>
                            <DialogActions style={{alignItems:'baseline'}}>
                                <Button onClick={() => {
                                    setState({...state, dry_run: true, openSummary: false});
                                }} color="default">
                                    {t('actions.cancel')}
                                </Button>
                                <Button onClick={handleGenerateEvent}
                                        autoFocus
                                        className={classes.button}>
                                    {t('actions.generate')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        : <Dialog disableBackdropClick open={state.openSummary}>
                            <DialogTitle id="customized-dialog-title">
                                {t('createEvent.generateEvent')}?
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={() => {history.goBack()}}
                                        color="default">
                                    {t('actions.back')}
                                </Button>
                                <Button onClick={() => {
                                    eventContext.resetValidation()
                                    setState({...state, activeStep: 0, dry_run: true, openSummary: false});
                                }}
                                    autoFocus
                                    style={{backgroundColor: accentColor, color: "white", borderRadius: '20px', boxShadow: '0px 0px 8px #d8d8d8'}}>
                                    {t('createEvent.generateAnother')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        : null
                }
            </form>
        </Box>
    )
}

export default CreateEventForm
