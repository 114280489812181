import React, {useContext, useEffect, useMemo, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CodeValue from "./CodeValue";
import {Controller, useForm} from "react-hook-form";
import {ThemeContext} from "../../contexts";
import _ from 'lodash'
import FormHelperText from "@material-ui/core/FormHelperText";
import {useTranslation} from "react-i18next";

const cdigit = require("cdigit");

// se generateCodeCheck === true -> iChain dovrà generare il codice per lui
const CodeProduct = ({availableProductCodeTypes, value, onChange, errors, generateCodeCheck, editMode, editCodesChecked}) => {
    const digit_regex = /^[0-9]*$/;
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        error: {
            color: 'red',
            margin: '0px'
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        box: {
            backgroundColor: "#f6f6f6",
            boxShadow: theme.shadows[1]
        },
        boxStyle: {
            width: '100%',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));
    const classes = useStyles();

    const [okData, setOkData] = useState(false)

    const {handleSubmit, reset, control, watch, getValues} = useForm({
        defaultValues: {
            code_type: '',
            code_value: '',
        }
    });

    const codes = useMemo(() => {
        return value || [];
    }, [value])

    //const used_codes = _.map(codes, (code) => (_.find(availableProductCodeTypes, ['name', code.type])))
    const used_codes = codes.map(({type}) => type);

    //let filtered_codes = available_codes.filter((code) => used_codes.indexOf(code) < 0)
    let filtered_codes = _.filter(availableProductCodeTypes, (codeType) => {
        return !_.some(used_codes, ['type', codeType.type])
        //return code.type === 'gtin' && !_.some(used_codes, ['type', 'gtin'])
    })

    const onAddClick = (data) => {
        //console.log("onAddClick --> data:",data)
        let check = cdigit.gtin.compute(data.code_value.substr(0, data.code_value.length-1))
        if(data.code_type.type === 'gtin' && check !== data.code_value.charAt(data.code_value.length-1)) {
            alert(t('addItem.gs1checkDigitError'))
            return
        }

        let new_codes = [...codes, {type: data.code_type, value: data.code_value}];

        /*new_codes = _.map(new_codes, (code, index) => {
            let main = false

            if (index === 0) {
                main = true
            }
            return {...code, main: main}
        })*/

        onChange && onChange(new_codes);
        filtered_codes.filter(val => val.type !== data.code_type)
        reset()
    }

    const onClearClick = (code) => {
        const new_codes = codes.filter(val => val.type !== code.type)
        onChange && onChange(new_codes);
        filtered_codes.push({type: code.type, value: code.value})
    }

    useEffect(() => {
        //const gtin = codes.filter(x => _.includes(x.type, 'gtin'))
        if (codes.length > 0) {
            setOkData(true)
        } else setOkData(false)
    }, [codes])

    function checkValidation() {
        if(!watch('code_type') || !watch('code_value'))
            return false
        else if(getValues('code_type')) {
            if(getValues('code_type').digitCount < 0)
                return !!getValues('code_value')
            else if(getValues('code_type').digitCount > 0)
                return digit_regex.test(getValues('code_value')) && getValues('code_value').length === getValues('code_type').digitCount
        }
        //return !(getValues('code_type') && getValues('code_type').digitCount > 0 && getValues('code_value').length <= getValues('code_type').digitCount);
    }

    return (
        <Box p={2} borderRadius={6}>
            <Box>
                {
                    !editMode && !generateCodeCheck ?
                        codes.map((code, index) => <CodeValue key={index} remove={onClearClick} code={code}/>)
                        : editMode ? codes.map((code, index) => <CodeValue key={index} remove={onClearClick} editCodesChecked={editCodesChecked} code={code}/>)
                        : null
                }
            </Box>
            <Box pt={1}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs={1} md={1}>
                        <IconButton aria-label="add"
                                    style={{color: !checkValidation() ? 'lightgray' : accentColor}}
                                    onClick={handleSubmit(onAddClick)}
                                    disabled={!checkValidation()}>
                            <AddCircleIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11} md={4}>
                        <Box>
                            <Controller
                                as={
                                    <Autocomplete
                                        options={filtered_codes}
                                        disabled={editMode && !editCodesChecked}
                                        getOptionLabel={option => option.label ? option.label : ''}
                                        //getOptionSelected={(option, value) => option.type === value.type}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t('entities.product.codeType')}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                }
                                onChange={([, data]) => {
                                    //console.log("onChange --> DATA:", data)
                                    return data
                                }}
                                name="code_type"
                                control={control}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={!okData ? 6 : 7}>
                        <Box p={2}>
                            <Controller
                                as={
                                    <TextField fullWidth
                                               disabled={editMode && !editCodesChecked}
                                               label={!generateCodeCheck ?
                                                       t('addItem.insertTheCodeHere', {type: getValues('code_type')?.label || ''})
                                                   : t('addItem.iChainWillGenerateAproductCode')}/>
                                }
                                name="code_value"
                                control={control}
                            />
                            {
                                getValues('code_type') && getValues('code_type').type === 'gtin'
                                && !checkValidation() ?
                                    <FormHelperText error>
                                        {t('addItem.digitCountError', {number: getValues('code_type').digitCount})}

                                    </FormHelperText> : null
                            }
                        </Box>
                    </Grid>
                    {/*<Grid item xs={12} md={1}>
                        {
                            !okData ?
                                <Box pl={3}>
                                    <Tooltip
                                        title={"get Gtin from the platform"}
                                    >
                                        <IconButton
                                            color="default"
                                            onClick={getGtin}
                                        >
                                            <ArrowDownwardIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Box> : null
                        }
                    </Grid>*/}
                </Grid>
            </Box>
            {errors.codes &&
            <p className={classes.error}>{errors.codes.message}</p>}
        </Box>
    )
}

export default CodeProduct
