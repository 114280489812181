import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

export const PinnedIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M14,10l-0.6-5.5h2c0.6,0,1.1-0.5,1.1-1.1V1.1C16.5,0.5,16,0,15.4,0H2.6C2,0,1.5,0.5,1.5,1.1v2.2C1.5,4,2,4.5,2.6,4.5h2L4,10 c-2.3,1.1-4,3-4,5.3c0,0.6,0.5,1.1,1.1,1.1h6.4v4.9c0,0.1,0,0.1,0,0.2l1.1,2.2c0.1,0.3,0.5,0.3,0.7,0l1.1-2.2c0-0.1,0-0.1,0-0.2 v-4.9h6.4c0.6,0,1.1-0.5,1.1-1.1C18,13,16.2,11.1,14,10z"/>
        </SvgIcon>
    );
}

export const UnpinnedIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M10,4L4.5,4.6v-2C4.5,2,4,1.5,3.4,1.5H1.1C0.5,1.5,0,2,0,2.6v12.7c0,0.6,0.5,1.1,1.1,1.1h2.2c0.6,0,1.1-0.5,1.1-1.1v-2 L10,14c1.1,2.3,3,4,5.3,4c0.6,0,1.1-0.5,1.1-1.1v-6.4h4.9c0.1,0,0.1,0,0.2,0l2.2-1.1c0.3-0.1,0.3-0.5,0-0.7l-2.2-1.1 c-0.1,0-0.1,0-0.2,0h-4.9V1.1C16.5,0.5,16,0,15.4,0C13,0,11.1,1.8,10,4z"/>
        </SvgIcon>
    );
}

export const QrCodeIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="st0" d="M2.3,0h3c1.3,0,2.3,1,2.3,2.3c0,0,0,0,0,0v3c0,1.3-1,2.3-2.3,2.3l0,0h-3C1.1,7.7,0,6.6,0,5.3c0,0,0,0,0,0v-3
	C0,1,1.1,0,2.3,0C2.3,0,2.3,0,2.3,0z M10.2,0h0.3C10.8,0,11,0.2,11,0.4v0.2c0,0.2,0.2,0.4,0.4,0.4h0.2c0.2,0,0.4-0.2,0.4-0.4V0.4
	c0-0.2,0.2-0.4,0.4-0.4h1.3c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0v0.2c0,0.2-0.2,0.4-0.4,0.4h-0.2c-0.2,0-0.4,0.2-0.4,0.4v0.2
	c0,0.2,0.2,0.4,0.4,0.4h0.2c0.3,0,0.5,0.2,0.5,0.5v0.2c0,0.2-0.2,0.4-0.4,0.4h-0.2c-0.2,0-0.4,0.2-0.4,0.4v0.2
	c0,0.2-0.2,0.4-0.4,0.4h-0.2c-0.2,0-0.4-0.2-0.4-0.4V2.6c0-0.2-0.2-0.4-0.4-0.4h-0.2c-0.2,0-0.4,0.2-0.4,0.4v1.3
	c0,0.2-0.2,0.4-0.4,0.4h-0.2c-0.2,0-0.4-0.2-0.4-0.4V3.7c0-0.2-0.2-0.4-0.4-0.4H9.2C9,3.3,8.8,3.1,8.8,2.9c0,0,0,0,0,0V2.6
	c0-0.2,0.2-0.4,0.4-0.4h0.2c0.2,0,0.4-0.2,0.4-0.4V0.4C9.9,0.2,10,0,10.2,0C10.2,0,10.2,0,10.2,0z M17.7,0h3C22,0,23,1,23,2.3
	c0,0,0,0,0,0v3c0,1.3-1,2.3-2.3,2.3c0,0,0,0,0,0h-3c-1.3,0-2.3-1-2.3-2.3c0,0,0,0,0,0v-3C15.3,1,16.4,0,17.7,0C17.7,0,17.7,0,17.7,0
	L17.7,0z M1.5,2.9v1.9c0,0.8,0.6,1.4,1.4,1.4l0,0h1.9c0.8,0,1.4-0.6,1.4-1.4l0,0V2.9c0-0.8-0.6-1.4-1.4-1.4l0,0H2.9
	C2.1,1.5,1.5,2.1,1.5,2.9L1.5,2.9z M1.5,18.2v1.9c0,0.8,0.6,1.4,1.4,1.4h1.9c0.8,0,1.4-0.6,1.4-1.4v-1.9c0-0.8-0.6-1.4-1.4-1.4H2.9
	C2.1,16.8,1.5,17.4,1.5,18.2z M16.8,2.9v1.9c0,0.8,0.6,1.4,1.4,1.4h1.9c0.8,0,1.4-0.6,1.4-1.4V2.9c0-0.8-0.6-1.4-1.4-1.4h-1.9
	C17.5,1.5,16.8,2.1,16.8,2.9z M3.8,2.7L3.8,2.7C4.5,2.7,5,3.2,5,3.8l0,0C5,4.5,4.5,5,3.8,5l0,0C3.2,5,2.7,4.5,2.7,3.8l0,0
	C2.7,3.2,3.2,2.7,3.8,2.7L3.8,2.7z M19.2,2.7L19.2,2.7c0.6,0,1.1,0.5,1.1,1.1l0,0c0,0.6-0.5,1.1-1.1,1.1l0,0C18.5,5,18,4.5,18,3.8
	l0,0C18,3.2,18.5,2.7,19.2,2.7L19.2,2.7z M11.5,4.4L11.5,4.4c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0l0,0c0,0.3-0.2,0.6-0.5,0.6l0,0
	c-0.3,0-0.6-0.2-0.6-0.5l0,0C11,4.6,11.2,4.4,11.5,4.4L11.5,4.4z M9.3,4.8L9.3,4.8c0.3,0,0.6,0.2,0.6,0.5l0,0c0,0.3-0.2,0.6-0.5,0.6
	l0,0C9,5.9,8.8,5.7,8.8,5.4c0,0,0,0,0,0l0,0C8.7,5.1,9,4.8,9.3,4.8L9.3,4.8z M13.7,4.4L13.7,4.4c0.3,0,0.5,0.2,0.5,0.5v3.3
	c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5,0.2-0.5,0.5l0,0l0,0c0,0.3-0.2,0.5-0.5,0.5H6c-0.3,0-0.5,0.2-0.5,0.5l0,0
	c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0l0,0c-0.3,0-0.5-0.2-0.5-0.5v-1C4.4,9,4.7,8.8,5,8.8l0,0h3.1c0.4,0,0.6-0.3,0.6-0.6l0,0V6.6
	C8.8,6.3,9,6,9.3,6c0,0,0,0,0,0l0,0c0.3,0,0.5,0.2,0.5,0.5v0.5c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0l0,0c0.3,0,0.5,0.2,0.5,0.5
	c0,0,0,0,0,0l0,0c0,0.3,0.2,0.5,0.5,0.5l0,0c0.3,0,0.5-0.2,0.5-0.5l0,0c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5-0.2,0.5-0.5V4.9
	C13.1,4.6,13.4,4.4,13.7,4.4C13.7,4.4,13.7,4.4,13.7,4.4z M11.5,6.6L11.5,6.6c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5
	c0,0,0,0,0,0l0,0c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0,0C11,6.8,11.2,6.6,11.5,6.6C11.5,6.6,11.5,6.6,11.5,6.6z M0,10.7
	c0-1.1,0.9-1.9,1.9-1.9h0.8c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5H1.7c-0.3,0-0.5,0.2-0.5,0.5l0,0c0,0.3,0.2,0.5,0.5,0.5
	h2.2c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5h0H1.7c-0.3,0-0.5,0.2-0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0
	c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0L0,10.7z M14.8,8.8h1.1c0.3,0,0.5,0.2,0.5,0.5v1.1c0,0.3-0.2,0.5-0.5,0.5l0,0
	c-0.3,0-0.5-0.2-0.5-0.5l0,0c0-0.3-0.2-0.5-0.5-0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5l0,0C14.2,9,14.5,8.8,14.8,8.8z M19.2,9.9L19.2,9.9
	c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5l0,0C18.6,10.1,18.9,9.8,19.2,9.9L19.2,9.9L19.2,9.9z
	 M19.3,16.4L19.3,16.4c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5l0,0
	C18.8,16.7,19,16.4,19.3,16.4C19.3,16.4,19.3,16.4,19.3,16.4L19.3,16.4z M22.4,9.9h0.2c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0v0.2
	c0,0.9-0.7,1.5-1.5,1.5h-0.2c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0,0c0-0.4,0.3-0.6,0.6-0.6l0,0c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0,0
	v-0.2C21.9,10.1,22.1,9.9,22.4,9.9C22.4,9.8,22.4,9.8,22.4,9.9L22.4,9.9z M11.3,10.9h0.2c0.3,0,0.5,0.2,0.5,0.5l0,0v2.4
	c0,0.2,0.1,0.3,0.3,0.3l0,0h0.4c0.2,0,0.3,0.1,0.3,0.3l0,0v1.5c0,0.2,0.1,0.3,0.3,0.3l0,0h0.4c0.2,0,0.3-0.1,0.3-0.3l0,0v-1.5
	c0-0.2,0.1-0.3,0.3-0.3l0,0H15c0.2,0,0.3,0.1,0.3,0.3l0,0V15c0,0.2,0.1,0.3,0.3,0.3l0,0h0.4c0.2,0,0.3-0.1,0.3-0.3l0,0v-0.4
	c0-0.2,0.1-0.3,0.3-0.3H17c0.3,0,0.5,0.2,0.5,0.5v1.4c0,0.2,0.1,0.3,0.3,0.3l0,0h0.4c0.2,0,0.3,0.1,0.3,0.3l0,0v0.4
	c0,0.2-0.1,0.3-0.3,0.3l0,0h-0.4c-0.2,0-0.3,0.1-0.3,0.3l0,0v0.9c0,0.5-0.4,0.9-0.9,0.9h-0.9c-0.2,0-0.3-0.1-0.3-0.3l0,0v-0.4
	c0-0.2-0.1-0.3-0.3-0.3l0,0h-0.4c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0,0v-0.4c0-0.2-0.1-0.3-0.3-0.3l0,0h-0.4c-0.2,0-0.3,0.1-0.3,0.3
	l0,0v0.4c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0,0,0h-1.5c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0,0,0v0.4c0,0.2-0.1,0.3-0.3,0.3h0h-0.4
	c-0.2,0-0.3-0.1-0.3-0.3l0,0v-0.4c0-0.2-0.1-0.3-0.3-0.3h0H9.1c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0,0v-3c0-0.6,0.5-1.1,1.1-1.1l0,0
	c0.6,0,1.1-0.5,1.1-1.1v-1.9C11,11.1,11.1,10.9,11.3,10.9C11.3,10.9,11.3,10.9,11.3,10.9z M18.1,10.9L18.1,10.9
	c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5l0,0C17.5,11.2,17.8,10.9,18.1,10.9z M4.8,12h1.4
	c0.2,0,0.4,0.2,0.4,0.4v0.3c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h0.3c0.2,0,0.4-0.2,0.4-0.4v-0.3C7.7,12.2,7.8,12,8,12c0,0,0,0,0,0
	h0.3c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0v0.2c0,0.9-0.7,1.6-1.6,1.6H5.9c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0v-0.3
	c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0H4.8c-0.2,0-0.4-0.2-0.4-0.4v-0.3C4.4,12.2,4.6,12,4.8,12C4.8,12,4.8,12,4.8,12z M15.9,12
	L15.9,12c0.3,0,0.5,0.2,0.5,0.5v1.1c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5v-1.1C15.3,12.3,15.6,12,15.9,12z M13.7,10.9
	L13.7,10.9c0.3,0,0.5,0.2,0.5,0.5v1.1c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5v-1.1C13.1,11.2,13.4,10.9,13.7,10.9z
	 M2.6,13.1H4c0.2,0,0.4,0.2,0.4,0.4v0.3c0,0.2-0.2,0.4-0.4,0.4H2.6c-0.2,0-0.4-0.2-0.4-0.4v-0.3C2.2,13.3,2.4,13.1,2.6,13.1z
	 M13.7,13.3L13.7,13.3c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0,0
	C13.1,13.6,13.4,13.3,13.7,13.3z M18,13.1L18,13.1c0.9,0,1.7,0.7,1.7,1.7l0,0c0,0.3-0.2,0.5-0.5,0.5h-0.1c-0.3,0-0.5-0.2-0.5-0.5
	v-0.1c0-0.3-0.2-0.5-0.5-0.5H18c-0.3,0-0.5-0.2-0.5-0.5v-0.1C17.5,13.4,17.8,13.1,18,13.1C18,13.1,18,13.1,18,13.1L18,13.1z
	 M20.8,14.8c0-0.9,0.7-1.6,1.6-1.6h0.1c0.3,0,0.5,0.2,0.5,0.5v0.1c0,0.3-0.2,0.5-0.5,0.5h-0.1c-0.3,0-0.5,0.2-0.5,0.5v0.1
	c0,0.3,0.2,0.5,0.5,0.5h0.1c0.3,0,0.5,0.2,0.5,0.5v0.1c0,0.3-0.2,0.5-0.5,0.5h-0.1C21.5,16.4,20.8,15.7,20.8,14.8z M2.3,15.3h3.1
	c1.3,0,2.3,1,2.3,2.3c0,0,0,0,0,0v3.1c0,1.3-1,2.3-2.3,2.3l0,0H2.3C1,23,0,22,0,20.7v-3.1C0,16.4,1,15.3,2.3,15.3L2.3,15.3z
	 M9.9,16.1v0.7c0,0.4,0.3,0.8,0.8,0.8c0,0,0,0,0,0h0.7c0.4,0,0.8-0.3,0.8-0.8c0,0,0,0,0,0v-0.7c0-0.4-0.3-0.8-0.8-0.8c0,0,0,0,0,0
	h-0.7C10.2,15.3,9.9,15.7,9.9,16.1C9.9,16.1,9.9,16.1,9.9,16.1L9.9,16.1z M15.3,16.7v0.5c0,0.2,0.1,0.3,0.3,0.3h0.5
	c0.2,0,0.3-0.1,0.3-0.3v-0.5c0-0.2-0.1-0.3-0.3-0.3h-0.5C15.5,16.4,15.3,16.6,15.3,16.7C15.3,16.7,15.3,16.7,15.3,16.7L15.3,16.7z
	 M3.8,18L3.8,18C4.5,18,5,18.5,5,19.2l0,0l0,0c0,0.6-0.5,1.1-1.1,1.1l0,0l0,0c-0.6,0-1.1-0.5-1.1-1.1l0,0l0,0
	C2.7,18.5,3.2,18,3.8,18L3.8,18z M21.2,17.5h1.4c0.2,0,0.4,0.2,0.4,0.4l0,0v0.3c0,0.2-0.2,0.4-0.4,0.4h-0.3c-0.2,0-0.4,0.2-0.4,0.4
	c0,0,0,0,0,0v0.3c0,0.2-0.2,0.4-0.4,0.4c0,0,0,0,0,0h-1.4c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0V19c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0
	h0.3c0.2,0,0.4-0.2,0.4-0.4c0,0,0,0,0,0v-0.3C20.8,17.7,21,17.5,21.2,17.5C21.2,17.5,21.2,17.5,21.2,17.5z M9.2,19.7h0.2
	c0.3,0,0.5,0.2,0.5,0.5l0,0v0.7c0,0.6,0.5,1,1,1h0.7c0.3,0,0.5,0.2,0.5,0.5l0,0v0.2c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0h-1.4
	c-0.8,0-1.4-0.6-1.4-1.4v-1.4C8.8,19.9,9,19.7,9.2,19.7C9.2,19.7,9.2,19.7,9.2,19.7L9.2,19.7z M11.5,19.7L11.5,19.7
	c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5l0,0C11,19.9,11.2,19.7,11.5,19.7
	C11.5,19.7,11.5,19.7,11.5,19.7L11.5,19.7z M13.6,19.6h0.2c0.2,0,0.4,0.2,0.4,0.4v1.3c0,0.2-0.2,0.4-0.4,0.4h-0.2
	c-0.2,0-0.4-0.2-0.4-0.4V20C13.2,19.8,13.3,19.6,13.6,19.6C13.6,19.6,13.6,19.6,13.6,19.6L13.6,19.6z M17.9,19.7h1.3
	c0.2,0,0.4,0.2,0.4,0.4v0.2c0,0.2-0.2,0.4-0.4,0.4h-0.2c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0v0.2c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0
	h0.2c0.2,0,0.4,0.2,0.4,0.4v0.2c0,0.2-0.2,0.4-0.4,0.4h-1.3c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0h-0.2
	c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0v0.2c0,0.2-0.2,0.4-0.4,0.4h-0.2c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.7-1.6,1.5-1.6H17
	c0.2,0,0.4-0.2,0.4-0.4c0,0,0,0,0,0v-0.2C17.4,19.9,17.6,19.7,17.9,19.7C17.9,19.7,17.9,19.7,17.9,19.7L17.9,19.7z M22.5,19.7
	L22.5,19.7c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5l0,0
	C21.9,19.9,22.2,19.7,22.5,19.7L22.5,19.7z M21.4,20.8L21.4,20.8c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3,0.2,0.5,0.5,0.5l0,0
	c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0h-1.1c-0.3,0-0.5-0.2-0.5-0.5v-1.1C20.8,21,21.1,20.8,21.4,20.8
	C21.4,20.8,21.4,20.8,21.4,20.8z M13.7,21.9L13.7,21.9c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5
	l0,0C13.1,22.1,13.4,21.9,13.7,21.9z M1.1,13.8c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5l0,0
	C0.8,13.3,1.1,13.5,1.1,13.8z M8.8,11.3c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5l0,0
	C8.5,10.8,8.8,11,8.8,11.3C8.8,11.3,8.8,11.3,8.8,11.3L8.8,11.3z M20.8,9.3c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5
	c0-0.3,0.2-0.5,0.5-0.5l0,0C20.6,8.7,20.8,9,20.8,9.3C20.8,9.3,20.8,9.3,20.8,9.3L20.8,9.3z"/>
             
        </SvgIcon>
    );
}

export const SupplyChainIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="cls-1"
                  d="M11.2,9V4.86a2.23,2.23,0,1,0-1.11,0V9a3.76,3.76,0,0,0-2.7,1.89L5,9A1.61,1.61,0,1,0,3.44,10a1.55,1.55,0,0,0,.8-.22L7,12a3.82,3.82,0,0,0-.06.68,3.74,3.74,0,0,0,.72,2.2L4.55,18a.54.54,0,0,0,0,.78.53.53,0,0,0,.39.16.54.54,0,0,0,.39-.16l3.12-3.12a3.54,3.54,0,0,0,1.64.69v4.12a1.61,1.61,0,1,0,1.11,0V16.3A3.71,3.71,0,0,0,11.2,9Zm-7.76,0a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5A.5.5,0,0,1,3.44,8.92ZM9.53,2.7a1.12,1.12,0,1,1,1.12,1.12A1.12,1.12,0,0,1,9.53,2.7Zm1.12,19.72a.5.5,0,0,1-.5-.5.48.48,0,0,1,.4-.48h.2a.49.49,0,0,1,.39.48A.49.49,0,0,1,10.65,22.42Zm0-7.19A2.61,2.61,0,0,1,8,12.63a2.53,2.53,0,0,1,.11-.73v0a2.6,2.6,0,1,1,2.49,3.36Z"
                  transform="translate(-0.16 -0.47)"/>
            <path className="cls-1"
                  d="M21.61,7.25a2.23,2.23,0,0,0-2.22,2.23v.17l-4,1.36a.56.56,0,0,0-.35.71.55.55,0,0,0,.53.37l.17,0,4-1.37a2.23,2.23,0,1,0,1.86-3.44Zm0,3.34a1.12,1.12,0,1,1,1.12-1.11A1.11,1.11,0,0,1,21.61,10.59Z"
                  transform="translate(-0.16 -0.47)"/>
            <path className="cls-1"
                  d="M2.39,18.66a2.23,2.23,0,1,0,2.22,2.23A2.23,2.23,0,0,0,2.39,18.66Zm0,3.35A1.12,1.12,0,1,1,3.5,20.89,1.12,1.12,0,0,1,2.39,22Z"
                  transform="translate(-0.16 -0.47)"/>
        </SvgIcon>
    );
}

export const MonitoringIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="cls-1"
                  d="M18.25.46H1.91A1.45,1.45,0,0,0,.46,1.91V13.44a1.44,1.44,0,0,0,1.45,1.44H8.15v-1H1.91a.47.47,0,0,1-.48-.48V1.91a.47.47,0,0,1,.48-.48H18.25a.48.48,0,0,1,.48.48v8.65h1V1.91A1.44,1.44,0,0,0,18.25.46Z"
                  transform="translate(-0.46 -0.46)"/>
            <rect className="cls-1" x="5.77" y="13.94" width="0.96" height="2.88"/>
            <path className="cls-1" d="M3.83,12a.48.48,0,0,1-.48-.48v-1h-1v1A1.43,1.43,0,0,0,3.83,13h1V12Z"
                  transform="translate(-0.46 -0.46)"/>
            <rect className="cls-1" x="1.92" y="8.17" width="0.96" height="0.96"/>
            <path className="cls-1"
                  d="M18,10.65A5.22,5.22,0,1,0,18,18,5.22,5.22,0,0,0,18,10.65Zm-3.69,8a4.26,4.26,0,1,1,0-8.52,4.17,4.17,0,0,1,3,1.25,4.26,4.26,0,0,1-3,7.27Z"
                  transform="translate(-0.46 -0.46)"/>
            <path className="cls-1"
                  d="M23.4,21.37l-3-3a.48.48,0,0,0-.68,0L18.36,19.7a.48.48,0,0,0,0,.68l3,3a.49.49,0,0,0,.34.14h0a.49.49,0,0,0,.34-.15l1.35-1.34A.5.5,0,0,0,23.4,21.37Zm-1.69,1L19.38,20l.66-.66,2.34,2.33Z"
                  transform="translate(-0.46 -0.46)"/>
            <rect className="cls-1" x="18.05" y="17.34" width="0.96" height="2.37"
                  transform="translate(-8.14 18.07) rotate(-45)"/>
            <path className="cls-1" d="M14.4,11v1a2.41,2.41,0,0,1,2.41,2.4h1A3.37,3.37,0,0,0,14.4,11Z"
                  transform="translate(-0.46 -0.46)"/>
            <rect className="cls-1" x="4.81" y="16.34" width="3.85" height="0.96"/>
            <path className="cls-1"
                  d="M7.67,2.39A1.44,1.44,0,1,0,9.12,3.83,1.44,1.44,0,0,0,7.67,2.39Zm0,1.92a.49.49,0,0,1-.48-.48.48.48,0,0,1,.48-.48.47.47,0,0,1,.48.48A.48.48,0,0,1,7.67,4.31Z"
                  transform="translate(-0.46 -0.46)"/>
            <path className="cls-1"
                  d="M11,5.75a1.45,1.45,0,1,0,1.44,1.44A1.44,1.44,0,0,0,11,5.75Zm0,1.92a.47.47,0,0,1-.48-.48A.48.48,0,0,1,11,6.71a.49.49,0,0,1,.48.48A.48.48,0,0,1,11,7.67Z"
                  transform="translate(-0.46 -0.46)"/>
            <rect className="cls-1" x="4.29" y="5.03" width="3.4" height="0.96"
                  transform="translate(-2.61 5.39) rotate(-45)"/>
            <rect className="cls-1" x="8.87" y="3.81" width="0.96" height="3.4"
                  transform="translate(-1.62 7.76) rotate(-45)"/>
            <path className="cls-1"
                  d="M14.4,2.39a1.44,1.44,0,1,0,1.45,1.44A1.44,1.44,0,0,0,14.4,2.39Zm0,1.92a.49.49,0,0,1-.48-.48.48.48,0,0,1,.48-.48.47.47,0,0,1,.48.48A.48.48,0,0,1,14.4,4.31Z"
                  transform="translate(-0.46 -0.46)"/>
            <rect className="cls-1" x="11.02" y="5.03" width="3.4" height="0.96"
                  transform="translate(-0.64 10.15) rotate(-45)"/>

        </SvgIcon>
    );
}

export const LinkIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="st0" d="M12.7,21.9c-0.2-0.1-0.3-0.3-0.3-0.5v-5.1c-0.8-0.2-1.6-0.3-2.4-0.3c-3.8-0.1-8.2,1.7-8.6,4.9
			c0,0.3-0.3,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0-0.6,0-0.9c0-5.9,5-11,12.1-12.4V2.5c0-0.2,0.1-0.4,0.3-0.5
			c0.2-0.1,0.4,0,0.6,0.1l10.3,9.5c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4l-10.3,9.5C13.2,21.9,13,22,12.9,22
			C12.8,22,12.7,22,12.7,21.9z"/>
        </SvgIcon>
    );
}

/**
 * Bookmarks Icon
 * */

export const ProductHistoryIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="st0" d="M16.2,9.1c0.2-0.3,0.5-0.6,0.8-0.8c0.2-0.1,0.3-0.3,0.3-0.5V7.4c0-0.4,0.3-0.6,0.7-0.6h0c0.4,0,0.7,0.3,0.7,0.6v0.4
	c0,0.2,0.1,0.4,0.3,0.5c0.3,0.2,0.6,0.5,0.8,0.8c0.1,0.2,0.3,0.3,0.6,0.3h2.1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-2.1
	c-0.2,0-0.5,0.1-0.6,0.3c-0.3,0.6-1,1-1.8,1s-1.4-0.4-1.8-1c-0.1-0.2-0.3-0.3-0.6-0.3h-1.7c-0.2,0-0.5,0.1-0.6,0.3
	c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.1-0.3,0.3-0.3,0.5v0.4c0,0.4-0.3,0.6-0.7,0.6h0c-0.4,0-0.7-0.3-0.7-0.6v-0.4
	c0-0.2-0.1-0.4-0.3-0.5c-0.3-0.2-0.6-0.5-0.8-0.8c-0.1-0.2-0.3-0.3-0.6-0.3H7.4c-0.2,0-0.5,0.1-0.6,0.3c-0.3,0.6-1,1-1.8,1
	s-1.4-0.4-1.8-1c-0.1-0.2-0.3-0.3-0.6-0.3H0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h2.1c0.2,0,0.5-0.1,0.6-0.3
	c0.2-0.3,0.5-0.6,0.8-0.8C4.3,8.3,4.4,8,4.4,7.8V7.4c0-0.4,0.3-0.6,0.7-0.6h0c0.4,0,0.7,0.3,0.7,0.6v0.4c0,0.2,0.1,0.4,0.3,0.5
	c0.3,0.2,0.6,0.5,0.8,0.8C7,9.3,7.2,9.5,7.4,9.5h1.7c0.2,0,0.5-0.1,0.6-0.3c0.3-0.6,1-1,1.8-1s1.4,0.4,1.8,1
	c0.1,0.2,0.3,0.3,0.6,0.3h1.7C15.8,9.5,16,9.3,16.2,9.1z M6.2,10.1C6.2,9.5,5.7,9,5.1,9s-1.1,0.5-1.1,1.1c0,0.6,0.5,1.2,1.1,1.2
	S6.2,10.8,6.2,10.1z M12.6,10.1c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1c0,0.6,0.5,1.2,1.1,1.2S12.6,10.8,12.6,10.1z M19.1,10.1
	c0-0.6-0.5-1.1-1.2-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.2,1.1,1.2C18.6,11.3,19.1,10.8,19.1,10.1z M3,0.6v4.6
	c0,0.3,0.3,0.6,0.7,0.6h2.7c0.4,0,0.7-0.3,0.7-0.6V0.6C7.1,0.3,6.8,0,6.4,0H3.7C3.3,0,3,0.3,3,0.6L3,0.6z M9.5,15v4.6
	c0,0.3,0.3,0.6,0.7,0.6h2.7c0.4,0,0.7-0.3,0.7-0.6V15c0-0.3-0.3-0.6-0.7-0.6h-2.7C9.8,14.5,9.5,14.7,9.5,15L9.5,15z M15.9,0.6v4.6
	c0,0.3,0.3,0.6,0.7,0.6h2.7c0.4,0,0.7-0.3,0.7-0.6V0.6C20,0.3,19.7,0,19.3,0h-2.7C16.2,0,15.9,0.3,15.9,0.6L15.9,0.6z"/>
        </SvgIcon>
    );
}

export const KpiAnalyticsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="st0" d="M13.4,2.2L15,0.5C13.9,0.2,12.7,0,11.5,0C5.2,0,0,5.2,0,11.5c0,0.5,0.4,1,1,1s1-0.4,1-1C2,6.2,6.2,2,11.5,2
C12.1,2,12.8,2,13.4,2.2z M19.2,3l-0.6,2.2C20.1,6.8,21,9,21,11.5c0,0.5,0.4,1,1,1s1-0.4,1-1C23,8.1,21.5,5.1,19.2,3z M16.5,1.8L9.6,9.1c-0.7,0.7-1,1.8-0.7,2.8c0.4,1.6,2.1,2.5,3.6,2.1c1-0.3,1.8-1,2.1-2l2.8-9.6C17.5,1.8,16.9,1.4,16.5,1.8z"/>
        </SvgIcon>
    );
}

export const ProductionFlowIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="st0" d="M21.5,1.8h-4.4c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5H21v6.8h-2.7l1.1-1.1c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0 l-2,2c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0
c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1l2,2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.1-1.1h3.2
	c0.3,0,0.5-0.2,0.5-0.5V2.3C22,2.1,21.8,1.8,21.5,1.8z M4.9,11.2c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H0.5
	c-0.3,0-0.5,0.2-0.5,0.5v7.8c0,0.3,0.2,0.5,0.5,0.5h3.2l-1.1,1.1c-0.2,0.2-0.2,0.5,0,0.7C2.7,21,2.8,21,2.9,21c0.1,0,0.2,0,0.3-0.1
	l2-2c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1l-2-2c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7l1.1,1.1H1v-6.8L4.9,11.2L4.9,11.2z
	 M7.3,0h7.3c0.3,0,0.6,0.3,0.6,0.6v4.7c0,0.3-0.3,0.6-0.6,0.6H7.3C7,5.9,6.7,5.6,6.7,5.3V0.6C6.7,0.3,7,0,7.3,0z M7.3,22.6h7.3
	c0.3,0,0.6-0.3,0.6-0.6v-4.7c0-0.3-0.3-0.6-0.6-0.6H7.3c-0.3,0-0.6,0.3-0.6,0.6V22C6.7,22.3,7,22.6,7.3,22.6z M7.3,14.2h7.3
	c0.3,0,0.6-0.3,0.6-0.6V9c0-0.3-0.3-0.6-0.6-0.6H7.3C7,8.3,6.7,8.6,6.7,9v4.7C6.7,14,7,14.2,7.3,14.2z"/>
        </SvgIcon>
    );
}

export const SupplyChainGraphFlowIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="st0" d="M2.5,14.5c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1l-1.3,0.8C0,13.2-0.3,10.6,0.3,8l1.5,0.4c0,0.1,0,0.2-0.1,0.4c0,0,0,0,0,0
	c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0,0,0c-0.1,1.2,0,2.3,0.2,3.4l0,0l0,0c0,0.1,0.1,0.2,0.1,0.4l0,0l0,0C2.1,13.7,2.3,14.1,2.5,14.5z
	 M7.5,18.9L7.5,18.9c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.3l0,0
	l-0.1-0.1c0,0,0,0,0,0l-0.1,0l-0.2-0.1l0,0C5.7,18,5.6,18,5.5,17.9l0,0c-0.2-0.1-0.3-0.2-0.5-0.3l0,0c-0.3-0.2-0.6-0.5-0.9-0.8
	c0,0,0,0,0,0c-0.3-0.3-0.5-0.5-0.7-0.8c0,0,0,0,0,0c-0.1-0.1-0.2-0.3-0.3-0.4l0,0c-0.1-0.1-0.1-0.2-0.2-0.3l0,0l0,0l-1.3,0.8
	C2.8,18,4.7,19.6,7,20.4l0.4-1.1L7.5,18.9L7.5,18.9z M9.8,19.5l-0.1,0h0l-0.1,0l-0.2,0l0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0
	c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0l-0.5,1.5c1.8,0.5,3.7,0.6,5.5,0.1l-0.4-1.5
	c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0
	c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0C10,19.5,9.9,19.5,9.8,19.5L9.8,19.5z M12.9,1.8L12.9,1.8l0.1,0c0.1,0,0.1,0,0.2,0.1l0.1,0
	l0,0C13.5,2,13.8,2.1,14,2.2c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1l0.7-1.3
	C13.1,0,10.5-0.3,8,0.3l0.4,1.5c0.1,0,0.2,0,0.4-0.1c0,0,0,0,0,0c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0,0.1,0C10.6,1.5,11.7,1.5,12.9,1.8z
	 M15.1,2.8L15.1,2.8c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0.3,0.2,0.5,0.4,0.8,0.5c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1l0,0l0.2,0.1
	l0.1,0.1c0,0,0,0,0,0l0,0l0,0c0.8,0.7,1.4,1.6,1.9,2.6c0,0,0,0,0,0.1c0.1,0.2,0.2,0.3,0.2,0.5c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3
	L20.4,7c-0.8-2.4-2.4-4.4-4.6-5.6L15.1,2.8L15.1,2.8z M19.3,8.6c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1l0,0l0,0.2
	l0,0l0,0.2l0,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1
	c0,0.2,0,0.3-0.1,0.5l0,0c0,0.2-0.1,0.3-0.1,0.5c0,0,0,0,0,0l1.5,0.4c0.4-1.8,0.4-3.7-0.1-5.5L19.1,8c0,0,0,0,0,0.1
	c0,0.1,0.1,0.2,0.1,0.3C19.2,8.5,19.3,8.5,19.3,8.6z M2.3,12.7c0,0,0.1,0,0.2,0c2.6-0.8,4.7-2.1,6.4-3.8c1.7-1.7,3-3.9,3.9-6.6
	C11.9,2.1,11,2,10.1,2l0,0c-0.2,0-0.3,0-0.5,0l0,0c-0.2,0-0.3,0-0.5,0.1c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0.1c0,0,0,0,0,0
	C9.2,5,9.1,6.9,8,8S5,9.2,2.2,8.5c0,0,0,0,0,0.1c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0.1C1.9,10.3,2,11.5,2.3,12.7z M12.9,7.2
	C13,7.1,13.1,7,13.2,6.9c0,0,0,0,0,0c0.2-0.2,0.5-0.5,0.7-0.7c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.3-0.3,0.5-0.5
	c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.5-0.6C15.9,4.2,16,4.1,16,4l0,0C16,4,15.9,3.9,15.8,3.8c0,0,0,0-0.1-0.1
	c0,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0
	C14.1,4.5,13.5,5.8,12.9,7.2z M3.6,14.2c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.8-0.5c0.1,0,0.2-0.1,0.2-0.1
	c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.4
	c0.1,0,0.1-0.1,0.2-0.1c0.6-0.4,1.1-0.8,1.7-1.2c0,0,0,0,0.1,0c0.2-0.2,0.5-0.4,0.7-0.6c0,0,0.1-0.1,0.1-0.1
	c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.3,0.2-0.6,0.3-0.9c-2.2,2.1-4.9,3.7-8.1,4.8c0,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0
	c0.1,0.2,0.1,0.4,0.2,0.6c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1
	C3.2,14.4,3.4,14.3,3.6,14.2z M6.4,17.2c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3
	c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
	c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.2-0.2
	c0.4-1.1,0.7-2.3,1.1-3.4c0,0,0,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
	c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
	c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.2C6.4,13,6.3,13,6.1,13.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.3
	c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0.1-0.2,0.1-0.4,0.2
	c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0,0,0,0,0,0
	C3.8,15.9,3.9,16,4,16.2c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0c0.2,0.2,0.5,0.4,0.7,0.6c0,0,0,0,0,0
	c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0.1,0c0.1,0.1,0.2,0.2,0.4,0.2l0,0C6,17.7,6,17.6,6,17.5C6.2,17.4,6.3,17.3,6.4,17.2z M15.5,5.3
	c-0.3,0.3-0.6,0.7-0.9,1c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
	c-0.1,0.1-0.3,0.2-0.4,0.4c0,0-0.1,0.1-0.1,0.1c-0.4,1.1-0.8,2.2-1.1,3.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2
	c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2
	c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1
	c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
	c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1C17.4,8,17.5,8,17.7,8
	c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0-0.1
	c-0.1-0.3-0.3-0.6-0.4-0.9l0,0c-0.1-0.1-0.2-0.3-0.2-0.4l0,0c-0.3-0.5-0.8-1.1-1.3-1.5l0,0c-0.2,0.2-0.4,0.4-0.5,0.6
	C15.7,5,15.6,5.1,15.5,5.3z M18.4,8.2c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1C16.5,9,16.4,9,16.3,9c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2
	c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
	c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.2-0.1,0.4-0.2,0.6c1.9-1.9,4.5-3.1,7.6-3.5c0.1,0,0.2,0,0.2,0
	c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1
	C18.6,8.2,18.5,8.2,18.4,8.2z M8.9,14.5c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.2
	c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
	C7.1,16.9,7.1,17,7,17.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.2l0,0c0.2,0.1,0.3,0.2,0.5,0.3
	c0,0,0,0,0.1,0c0.2,0.1,0.3,0.1,0.5,0.2c0,0,0,0,0,0C8.1,17.2,8.5,15.8,8.9,14.5z M18.8,9.9c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0
	c-2.5,0.4-4.4,1.3-5.8,2.7c-1.5,1.5-2.4,3.6-2.7,6.3c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.3,0
	c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.2,0
	C11.8,16,12,14.1,13,13s3-1.2,5.8-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2C18.9,9.9,18.9,9.9,18.8,9.9z M14.4,3
	C14.4,2.9,14.4,2.9,14.4,3c-0.2-0.1-0.4-0.2-0.6-0.3c0,0,0,0,0,0c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,1.4-0.8,2.7-1.2,4
	c0,0.2-0.1,0.4-0.2,0.6c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.3-0.2,0.7-0.3,1.1l0,0c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4l0,0
	c-0.3,1.1-0.7,2.1-1,3.2l0,0c0,0.1-0.1,0.3-0.1,0.5c-0.1,0.2-0.1,0.3-0.2,0.5l0,0c-0.1,0.5-0.3,1-0.5,1.5c-0.1,0.3-0.2,0.7-0.3,1.1
	c-0.3,0.8-0.5,1.6-0.8,2.4l0,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.4,0.1c0,0,0.1,0,0.1,0
	c0.1-1.4,0.4-2.8,0.7-4.2c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.3,0.1-0.5c0.1-0.3,0.1-0.5,0.2-0.8l0,0c0-0.1,0.1-0.3,0.1-0.4
	c0-0.1,0.1-0.3,0.1-0.4l0,0c0.3-1.1,0.6-2.1,1-3.2l0,0c0-0.1,0.1-0.3,0.2-0.5C12,8.3,12.1,8.1,12.1,8l0,0c0.2-0.6,0.5-1.2,0.7-1.8
	c0.1-0.3,0.3-0.7,0.5-1C13.7,4.4,14,3.7,14.4,3L14.4,3z"/>
        </SvgIcon>
    );
}

export const CreateRelationshipIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path className="st0" d="M20.9,1.1c-1.4-1.4-3.8-1.4-5.2,0l-3.7,3.7C10.8,6,10.6,8,11.7,9.5l-2.2,2.2c-1.4-1-3.4-0.9-4.7,0.4l-3.7,3.7
	c-1.4,1.4-1.4,3.8,0,5.2C1.8,21.6,2.7,22,3.7,22s1.9-0.4,2.6-1.1l3.7-3.7c1.3-1.3,1.4-3.3,0.4-4.7l2.2-2.2c0.6,0.5,1.4,0.7,2.1,0.7
	c0.9,0,1.9-0.4,2.6-1.1l3.7-3.7C22.4,4.8,22.4,2.5,20.9,1.1L20.9,1.1z M9.1,16.4l-3.7,3.7c-1,1-2.5,1-3.4,0
	c-0.5-0.5-0.7-1.1-0.7-1.7c0-0.7,0.2-1.3,0.7-1.7l3.7-3.7c0.5-0.5,1.1-0.7,1.7-0.7c0.4,0,0.9,0.1,1.2,0.3l-2,2
	c-0.2,0.2-0.2,0.6,0,0.9c0.1,0.1,0.3,0.2,0.4,0.2s0.3,0,0.4-0.2l2-2c0.2,0.4,0.3,0.8,0.3,1.2C9.8,15.3,9.5,15.9,9.1,16.4z M20.1,5.4
	l-3.7,3.7c-0.8,0.8-2,0.9-3,0.4l2-2c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0l-2,2c-0.2-0.4-0.4-0.8-0.4-1.2
	c0-0.7,0.2-1.3,0.7-1.7l3.7-3.7c0.5-0.5,1.1-0.7,1.7-0.7c0.6,0,1.2,0.2,1.7,0.7l0,0c0.5,0.5,0.7,1.1,0.7,1.7S20.5,4.9,20.1,5.4z"/>
        </SvgIcon>
    );
}

export const EventModelsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.4,0C4.4,0,4.3,0,4.4,0C4.3,0,4.3,0,4.4,0C4.3,0,4.3,0,4.4,0C4.3,0,4.3,0,4.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L0.2,3.9C0.1,4,0,4.2,0,4.4V17
		c0,0.4,0.3,0.7,0.7,0.7h11.9c0.4,0,0.7-0.3,0.7-0.7V0.7C13.3,0.3,13,0,12.6,0H4.4C4.4,0,4.4,0,4.4,0z M5.1,1.4h6.8v14.9H1.4V5.1h3
		c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V1.4z M14.7,2.2c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v15.6H2.9c-0.4,0-0.7,0.3-0.7,0.7
		c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h11.9c0.4,0,0.7-0.3,0.7-0.7V2.9C15.4,2.5,15.1,2.2,14.7,2.2C14.7,2.2,14.7,2.2,14.7,2.2z
		 M3.7,2.3v1.4H2.3L3,3L3.7,2.3z M16.8,4.3c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v15.6H5c-0.4,0-0.7,0.3-0.7,0.7C4.3,21.7,4.6,22,5,22
		c0,0,0,0,0,0h11.9c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V5C17.5,4.6,17.2,4.3,16.8,4.3C16.9,4.3,16.8,4.3,16.8,4.3z M3.7,7.1
		C3.3,7.1,3,7.4,3,7.7c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h5.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0H3.7z
		 M3.7,9.9C3.3,9.9,3,10.2,3,10.5c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h5.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0
		H3.7z M3.7,12.7C3.3,12.7,3,13,3,13.3C3,13.7,3.3,14,3.7,14c0,0,0,0,0,0h5.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
		c0,0,0,0,0,0H3.7z"/>
        </SvgIcon>
    );
}

export const CreateNewEventTemplateIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M15.7,0H5.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0L0.3,4.9C0.1,5,0,5.2,0,5.5v15.7
			C0,21.6,0.4,22,0.9,22h14.8c0.5,0,0.9-0.4,0.9-0.9V0.9C16.5,0.4,16.1,0,15.7,0z M3.8,3.8l0.8-0.8v1.7H2.9L3.8,3.8z M14.8,20.3H1.7
			v-14h3.8c0.5,0,0.9-0.4,0.9-0.9c0,0,0,0,0,0V1.7h8.5V20.3z M8.3,8.1c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S10.7,8.1,8.3,8.1z M10.7,13.2H8.8v1.9c0,0,0,0,0,0
			c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6v-1.9H5.8c0,0,0,0,0,0c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h1.9v-1.9
			c0,0,0,0,0,0c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v1.9h1.9c0,0,0,0,0,0c0.3,0,0.6,0.3,0.6,0.6S11,13.2,10.7,13.2z"/>
        </SvgIcon>
    );
}

export const MyCompanyIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g transform="translate(0,-280.06664)">
                <path d="M8.1,280.1c-0.4,0-0.7,0.3-0.7,0.7v2.2H0.7c-0.4,0-0.7,0.3-0.7,0.7v14.7c0,0.4,0.3,0.7,0.7,0.7h20.5c0.4,0,0.7-0.3,0.7-0.7
                v-14.7c0-0.4-0.3-0.7-0.7-0.7h-6.6v-2.2c0-0.4-0.3-0.7-0.7-0.7H8.1z M8.8,281.5h4.4v1.5H8.8V281.5z M1.5,284.5c6.4,0,12.7,0,19.1,0
                v13.2H1.5V284.5z M8.1,285.9c-1.6,0-2.9,1.3-2.9,2.9c0,0.8,0.3,1.4,0.8,2c-1.7,0.8-3,2.6-3,4.6c0,0.4,0.3,0.7,0.7,0.7h8.8
                c0.4,0,0.7-0.3,0.7-0.7c0-2-1.2-3.8-3-4.6c0.5-0.5,0.8-1.2,0.8-2C11,287.3,9.7,285.9,8.1,285.9z M8.1,287.4c0.8,0,1.5,0.6,1.5,1.5
                c0,0.8-0.6,1.5-1.5,1.5s-1.5-0.6-1.5-1.5S7.2,287.4,8.1,287.4z M8.1,291.8c1.8,0,3.2,1.3,3.5,2.9h-7C4.9,293.1,6.3,291.8,8.1,291.8z M13.9,287.4v1.5h5.1v-1.5H13.9z M13.9,290.3v1.5h5.1v-1.5H13.9z M13.9,293.3v1.5h5.1v-1.5H13.9z"
                />
            </g>
        </SvgIcon>
    );
}

export const MyItemsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M17.5,12.1l-4.8,4.8l0.7,0.7c0.8,0.8,2.2,0.8,3.1,0l5.4-5.4c0.8-0.8,0.8-2.2,0-3.1L14.7,2c-0.4-0.4-0.9-0.6-1.5-0.6l-2,0
		l6.3,6.3c0.6,0.6,0.9,1.4,0.9,2.2C18.4,10.7,18.1,11.5,17.5,12.1z"/>
                <path d="M9.9,17.9C9.9,17.9,9.9,17.9,9.9,17.9c-0.7,0-1.4-0.3-1.9-0.8L0.8,10C0.3,9.5,0,8.8,0,8.2l0-5.5C0,1.2,1.2,0,2.7,0l5.5,0
		C8.8,0,9.5,0.3,10,0.8L17.1,8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9l-5.4,5.4C11.2,17.6,10.6,17.9,9.9,17.9z M1,2.7v5.5
		C1,8.6,1.2,9,1.5,9.2l7.2,7.2c0.3,0.3,0.7,0.5,1.2,0.5c0,0,0,0,0,0c0.5,0,0.9-0.2,1.2-0.5l5.4-5.4c0.3-0.3,0.5-0.7,0.5-1.2
		c0-0.5-0.2-0.9-0.5-1.2L9.2,1.5C8.9,1.2,8.5,1,8.1,1L2.7,1C1.8,1,1,1.8,1,2.7L1,2.7z"/>
            </g>
            <circle cx="4.3" cy="4.3" r="1.5"/>
        </SvgIcon>
    );
}

export const OthersItemsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <g>
                    <path d="M17.5,12.1l-4.8,4.8l0.7,0.7c0.8,0.8,2.2,0.8,3.1,0l5.4-5.4c0.8-0.8,0.8-2.2,0-3.1L14.7,2c-0.4-0.4-0.9-0.6-1.5-0.6l-2,0
			l6.3,6.3c0.6,0.6,0.9,1.4,0.9,2.2C18.4,10.7,18.1,11.5,17.5,12.1z"/>
                </g>
                <g>
                    <path d="M9.9,17.9C9.9,17.9,9.9,17.9,9.9,17.9c-0.7,0-1.4-0.3-1.9-0.8L0.8,10C0.3,9.5,0,8.8,0,8.2l0-5.5C0,1.2,1.2,0,2.7,0l5.5,0
			C8.8,0,9.5,0.3,10,0.8L17.1,8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9l-5.4,5.4C11.2,17.6,10.6,17.9,9.9,17.9z M1,2.7v5.5
			C1,8.6,1.2,9,1.5,9.2l7.2,7.2c0.3,0.3,0.7,0.5,1.2,0.5c0,0,0,0,0,0c0.5,0,0.9-0.2,1.2-0.5l5.4-5.4c0.3-0.3,0.5-0.7,0.5-1.2
			c0-0.5-0.2-0.9-0.5-1.2L9.2,1.5C8.9,1.2,8.5,1,8.1,1L2.7,1C1.8,1,1,1.8,1,2.7L1,2.7z"/>
                </g>
            </g>
            <g>
                <circle cx="4.3" cy="4.3" r="1.5"/>
            </g>
            <g>
                <circle cx="11" cy="10.9" r="0.8"/>
            </g>
            <g>
                <circle cx="9" cy="13" r="0.8"/>
            </g>
            <g>
                <circle cx="13" cy="8.9" r="0.8"/>
            </g>
        </SvgIcon>
    );
}

export const AddItemIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <g>
                    <path d="M11.8,21.5C11.8,21.5,11.8,21.5,11.8,21.5c-0.9,0-1.7-0.3-2.3-0.9l-8.6-8.6C0.4,11.4,0,10.6,0,9.8l0-6.6
			C0,1.4,1.4,0,3.2,0l6.5,0c0.8,0,1.6,0.4,2.2,0.9l8.6,8.6c0.6,0.6,0.9,1.4,0.9,2.3c0,0.9-0.3,1.7-0.9,2.3l-6.5,6.5
			C13.5,21.2,12.7,21.5,11.8,21.5z M1.2,3.2v6.6c0,0.5,0.2,1,0.6,1.3l8.6,8.6c0.4,0.4,0.9,0.6,1.4,0.6c0,0,0,0,0,0
			c0.5,0,1.1-0.2,1.4-0.6l6.5-6.5c0.4-0.4,0.6-0.9,0.6-1.4c0-0.5-0.2-1.1-0.6-1.4l-8.6-8.6c-0.4-0.4-0.8-0.6-1.4-0.6l-6.5,0
			C2.1,1.2,1.2,2.1,1.2,3.2L1.2,3.2z"/>
                </g>
            </g>
            <path d="M11.7,6.8c-2.8,0-5,2.2-5,5s2.2,5,5,5s5-2.2,5-5S14.5,6.8,11.7,6.8z M14.4,12.4h-2.1v2.1c0,0,0,0,0,0c0,0.3-0.3,0.6-0.6,0.6
	c-0.3,0-0.6-0.3-0.6-0.6v-2.1H9.1c0,0,0,0,0,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h2.1V9.1c0,0,0,0,0,0
	c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.1h2.1c0,0,0,0,0,0c0.3,0,0.6,0.3,0.6,0.6C15.1,12.1,14.8,12.4,14.4,12.4z"/>
            <g>
                <circle cx="3.8" cy="3.9" r="1.5"/>
            </g>
             
        </SvgIcon>
    );
}

export const MyPlacesIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M14.5,0.7c1.8,1,3.1,3,3.1,5.2c0,3.9-3.7,9.3-5.3,11.3c0.5,0.7,0.9,1.1,1,1.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
		c0.8-1,5.8-7.5,5.8-12C19.4,3.6,17.3,1.2,14.5,0.7z"/>
            </g>
            <g>
                <path d="M11.1,0v1c2.7,0,4.9,2.2,4.9,4.9c0,3.5-3.4,8.4-4.9,10.5c-1.5-2.1-4.9-7-4.9-10.5C6.2,3.2,8.4,1,11.1,1L11.1,0 M11.1,8.7
		c0.1,0,0.2,0,0.4,0c1.2-0.2,2.2-1.1,2.4-2.4c0.1-0.7-0.1-1.5-0.6-2c-0.4-0.6-1.1-1-1.8-1c-0.1,0-0.2,0-0.4,0c-1.4,0-2.6,1-2.7,2.4
		c0,0.2,0,0.5,0,0.7C8.6,7.6,9.7,8.7,11.1,8.7 M11.1,0C11.1,0,11.1,0,11.1,0C7.8,0,5.2,2.7,5.2,5.9c0,4.5,5.1,11.1,5.8,12
		c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.8-1,5.8-7.5,5.8-12C17.1,2.6,14.4,0,11.1,0L11.1,0z M11.1,7.7
		c-0.9,0-1.6-0.6-1.7-1.5c0-0.1,0-0.3,0-0.4c0.1-0.9,0.9-1.5,1.7-1.5c0.1,0,0.1,0,0.2,0c1,0.1,1.6,1,1.5,2c-0.1,0.8-0.7,1.4-1.5,1.5
		C11.3,7.7,11.2,7.7,11.1,7.7L11.1,7.7z"/>
            </g>
            <path d="M15.2,13.9c-0.2,0.4-0.5,0.8-0.7,1.1c3.9,0.5,6.5,1.8,6.5,3c0,1.5-4.2,3.2-9.9,3.2S1.2,19.6,1.2,18c0-1.2,2.6-2.5,6.5-3
	c-0.2-0.4-0.5-0.7-0.7-1.1c-3.9,0.6-7,2-7,4.2c0,2.9,5.7,4.5,11.1,4.5s11.1-1.6,11.1-4.5C22.3,15.9,19.1,14.5,15.2,13.9z"/>
        </SvgIcon>
    );
}

export const AddPlaceIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M11.1,2.2C8.9,2.2,7,4.1,7,6.3s1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1S13.4,2.2,11.1,2.2z M13.3,6.9h-1.7v1.7c0,0,0,0,0,0
	c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V6.9H8.9c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h1.7V4.1
	c0,0,0,0,0,0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v1.7h1.7c0,0,0,0,0,0c0.3,0,0.5,0.2,0.5,0.5C13.9,6.6,13.6,6.9,13.3,6.9z"/>
            <path d="M11.1,0v1.1c2.9,0,5.3,2.4,5.3,5.2c0,3.7-3.6,9-5.3,11.2C9.5,15.3,5.9,10,5.9,6.3c0-2.9,2.4-5.2,5.2-5.2L11.1,0 M11.1,0
	C11.1,0,11.1,0,11.1,0C7.6,0,4.8,2.8,4.8,6.3c0,4.8,5.4,11.8,6.2,12.8c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0
	c0.8-1,6.2-8,6.2-12.8C17.4,2.8,14.6,0,11.1,0L11.1,0z"/>
            <path d="M15.2,14.5c-0.2,0.4-0.5,0.8-0.7,1.1c3.9,0.5,6.5,1.8,6.5,3c0,1.5-4.2,3.2-9.9,3.2s-9.9-1.7-9.9-3.2c0-1.2,2.6-2.5,6.5-3
	c-0.2-0.4-0.5-0.7-0.7-1.1c-3.9,0.6-7,2-7,4.2c0,2.9,5.7,4.5,11.1,4.5s11.1-1.6,11.1-4.5C22.3,16.5,19.1,15.1,15.2,14.5z"/>
             
        </SvgIcon>
    );
}

export const CompaniesIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M7,7.1H0.3C0.1,7.1,0,7.3,0,7.5v13.4c0,0.2,0.1,0.3,0.3,0.3H7c0.2,0,0.3-0.1,0.3-0.3V7.5C7.3,7.3,7.1,7.1,7,7.1z M2.9,12.8
		V14H1.7v-1.2H2.9z M1.7,11.2V9.9h1.2v1.3H1.7z M2.9,15.5v1.3H1.7v-1.3H2.9z M5.6,12.8V14H4.4v-1.2H5.6z M4.4,11.2V9.9h1.2v1.3H4.4z
		 M5.6,15.5v1.3H4.4v-1.3H5.6z"/>
                <path d="M4.5,6.4h3.3v3c0,0.2,0.1,0.3,0.3,0.3H13c0.2,0,0.3-0.1,0.3-0.3V1.8c0-0.2-0.1-0.3-0.3-0.3H4.5c-0.2,0-0.3,0.1-0.3,0.3v4.3
		C4.2,6.2,4.4,6.4,4.5,6.4z M4.9,2.1h7.8v7H8.5v-3c0-0.2-0.1-0.3-0.3-0.3H4.9V2.1z"/>
                <path d="M15,10.4H8.2c-0.2,0-0.3,0.1-0.3,0.3v10.1c0,0.2,0.1,0.3,0.3,0.3H15c0.2,0,0.3-0.1,0.3-0.3V10.7
		C15.3,10.6,15.2,10.4,15,10.4z M14.6,20.5H8.5v-9.4h6.1V20.5z"/>
                <path d="M22.2,0h-8c-0.2,0-0.3,0.1-0.3,0.3v9.1c0,0.2,0.1,0.3,0.3,0.3h1.7v11.1c0,0.2,0.1,0.3,0.3,0.3h6c0.2,0,0.3-0.1,0.3-0.3V0.3
		C22.5,0.1,22.4,0,22.2,0z M17.2,5.8v1.6h-1.6V5.8H17.2z M15.6,3.8V2.2h1.6v1.6H15.6z M20.7,5.8v1.6h-1.6V5.8H20.7z M19.1,3.8V2.2
		h1.6v1.6H19.1z M20.7,9.4V11h-1.6V9.4H20.7z"/>
                <path d="M9.6,14.1h1.2c0.2,0,0.3-0.1,0.3-0.3v-1.2c0-0.2-0.1-0.3-0.3-0.3H9.6c-0.2,0-0.3,0.1-0.3,0.3v1.2C9.3,14,9.5,14.1,9.6,14.1
		z M10,12.9h0.6v0.6H10V12.9z"/>
                <path d="M12.4,14.1h1.2c0.2,0,0.3-0.1,0.3-0.3v-1.2c0-0.2-0.1-0.3-0.3-0.3h-1.2c-0.2,0-0.3,0.1-0.3,0.3v1.2
		C12,14,12.2,14.1,12.4,14.1z M12.7,12.9h0.6v0.6h-0.6V12.9z"/>
                <path d="M9.6,17h1.2c0.2,0,0.3-0.1,0.3-0.3v-1.2c0-0.2-0.1-0.3-0.3-0.3H9.6c-0.2,0-0.3,0.1-0.3,0.3v1.2C9.3,16.8,9.5,17,9.6,17z
		 M10,15.7h0.6v0.6H10V15.7z"/>
                <path d="M12.4,17h1.2c0.2,0,0.3-0.1,0.3-0.3v-1.2c0-0.2-0.1-0.3-0.3-0.3h-1.2c-0.2,0-0.3,0.1-0.3,0.3v1.2C12,16.8,12.2,17,12.4,17z
		 M12.7,15.7h0.6v0.6h-0.6V15.7z"/>
                <path d="M9.5,4.9h2c0.2,0,0.3-0.1,0.3-0.3V3.4c0-0.2-0.1-0.3-0.3-0.3h-2C9.4,3,9.2,3.2,9.2,3.4v1.2C9.2,4.8,9.4,4.9,9.5,4.9z
		 M9.9,3.7h1.4v0.6H9.9V3.7z"/>
                <path d="M9.5,7.5h2c0.2,0,0.3-0.1,0.3-0.3V6c0-0.2-0.1-0.3-0.3-0.3h-2C9.4,5.6,9.2,5.8,9.2,6v1.2C9.2,7.4,9.4,7.5,9.5,7.5z
		 M9.9,6.3h1.4v0.6H9.9V6.3z"/>
                <path d="M8.5,4.6V3.4C8.5,3.2,8.4,3,8.2,3h-2C6,3,5.8,3.2,5.8,3.4v1.2c0,0.2,0.1,0.3,0.3,0.3h2C8.4,4.9,8.5,4.8,8.5,4.6z M7.9,4.3
		H6.5V3.7h1.4V4.3z"/>
            </g>
             
        </SvgIcon>
    );
}

export const CertificatesIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M11 8h-7v-1h7v1zm0 2h-7v1h7v-1zm8.692-.939c-.628-.436-.544-.327-.782-1.034-.099-.295-.384-.496-.705-.496h-.003c-.773.003-.64.044-1.265-.394-.129-.092-.283-.137-.437-.137-.154
            0-.308.045-.438.137-.629.442-.492.398-1.265.394h-.003c-.321 0-.606.201-.705.496-.238.71-.156.6-.781 1.034-.198.137-.308.353-.308.578l.037.222c.242.708.242.572
            0 1.278l-.037.222c0 .224.11.441.309.578.625.434.545.325.781 1.033.099.296.384.495.705.495h.003c.773-.003.64-.045 1.265.394.129.093.283.139.437.139.154
            0 .308-.046.437-.138.625-.439.49-.397 1.265-.394h.003c.321
            0 .606-.199.705-.495.238-.708.154-.599.782-1.033.198-.137.308-.355.308-.579l-.037-.222c-.242-.71-.24-.573 0-1.278l.037-.222c0-.225-.11-.443-.308-.578zm-3.192 2.939c-.828 0-1.5-.672-1.5-1.5
            0-.829.672-1.5 1.5-1.5s1.5.671 1.5 1.5c0 .828-.672 1.5-1.5 1.5zm1.241 3.008l.021-.008h1.238v7l-2.479-1.499-2.521 1.499v-7h1.231c.415.291.69.5 1.269.5.484 0 .931-.203
            1.241-.492zm-17.741-13.008v17h12v-2h-10v-13h20v13h-1v2h3v-17h-24zm8 11h-4v1h4v-1z"/>
        </SvgIcon>
    );
}

export const DevicesMonitoringIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M21.169 19.754c.522-.79.831-1.735.831-2.754 0-2.761-2.238-5-5-5s-5 2.239-5 5 2.238 5 5 5c1.019 0 1.964-.309 2.755-.832l2.831
            2.832 1.414-1.414-2.831-2.832zm-4.169.246c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm-4.89 2h-7.11l2.599-3h2.696c.345
            1.152.976 2.18 1.815 3zm-2.11-5h-10v-17h22v12.11c-.574-.586-1.251-1.068-2-1.425v-8.685h-18v13h8.295c-.19.634-.295
            1.305-.295 2zm-2.131-4.372l.945-5.759.965 4.145c.096.425.686.473.847.063l.895-2.328.479.974c.08.169.164.277.438.277h1.208v-.877h-.921l-.836-1.624c-.156-.364-.677-.356-.82.014l-.741
            1.895-1.144-5.062c-.052-.232-.242-.346-.43-.346-.2 0-.4.127-.44.373l-.948 5.847-.969-3.6c-.109-.43-.715-.455-.853-.029l-.721
            2.545h-.823v.864h1.172c.16 0 .334-.13.38-.284l.406-1.257 1.043 4.206c.117.468.791.437.868-.037z"/>
        </SvgIcon>
    );
}

export const NewSearchIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M 10.944 6.912 v 1.152 c 0 0.2376 -0.1944 0.432 -0.432 0.432 h -2.016 v 2.016 c 0 0.2376 -0.1944 0.432 -0.432 0.432 h -1.152 c -0.2376 0 -0.432 -0.1944 -0.432 -0.432 v -2.016 h -2.016 c -0.2376 0 -0.432 -0.1944 -0.432 -0.432 v -1.152 c 0 -0.2376 0.1944 -0.432 0.432 -0.432 h 2.016 v -2.016 c 0 -0.2376 0.1944 -0.432 0.432 -0.432 h 1.152 c 0.2376 0 0.432 0.1944 0.432 0.432 v 2.016 h 2.016 c 0.2376 0 0.432 0.1944 0.432 0.432 z m 7.236 10.2492 L 17.1612 18.18 c -0.3384 0.3384 -0.8856 0.3384 -1.2204 0 L 12.348 14.5908 c -0.162 -0.162 -0.252 -0.3816 -0.252 -0.612 V 13.392 c -1.2708 0.9936 -2.8692 1.584 -4.608 1.584 C 3.3516 14.976 0 11.6244 0 7.488 S 3.3516 0 7.488 0 s 7.488 3.3516 7.488 7.488 c 0 1.7388 -0.5904 3.3372 -1.584 4.608 h 0.5868 c 0.2304 0 0.45 0.09 0.612 0.252 l 3.5892 3.5892 c 0.3348 0.3384 0.3348 0.8856 0 1.224 z M 12.384 7.488 c 0 -2.7072 -2.1888 -4.896 -4.896 -4.896 S 2.592 4.7808 2.592 7.488 s 2.1888 4.896 4.896 4.896 s 4.896 -2.1888 4.896 -4.896 z"/>
        </SvgIcon>
    );
}

export const PoultryChartIcon = (props) =>{
    return (
        <SvgIcon {...props} viewBox="0 0 362.582 362.582">
            <path d="M335.841,39.604c-6.93-4.742-6.93-4.742-10.57-11.657c-1.914-3.638-2.614-7.376-3.077-9.851 
            c-0.435-2.319-0.652-3.483-1.848-3.483c-0.311,0-0.565,0.116-0.771,0.27c-0.175-0.516-0.362-1.21-0.505-1.736 
            c-0.655-2.429-1.274-4.722-2.956-4.722l-0.185,0.011c-1.756,0.202-2.029,2.029-2.193,3.12c-0.009,0.063-0.02,0.13-0.03,0.2 
            c-0.344-0.718-0.575-2.146-0.727-3.079c-0.354-2.188-0.722-4.45-2.535-4.45c-1.713,0-2.148,1.768-2.408,2.824 
            c-0.051,0.205-0.117,0.475-0.184,0.684c-0.697-0.596-1.036-2.219-1.314-3.549C306.128,2.224,305.662,0,303.732,0 
            c-0.562,0-1.06,0.201-1.438,0.583c-0.895,0.896-0.889,2.56-0.883,4.026c0.001,0.257,0.002,0.576-0.007,0.864 
            c-0.319-0.247-0.687-0.556-0.975-0.797c-1.651-1.385-2.816-2.292-3.918-2.292c-0.369,0-1.59,0.138-1.787,1.907 
            c-0.211,1.9,0.878,3.874,1.838,5.615c0.457,0.829,1.223,2.217,1.09,2.59c-0.41,0.205-1.276,0.403-2.28,0.632
            c-3.672,0.837-10.507,2.396-15.354,9.063c-2.228,3.063-5.274,5.773-8.499,8.645c-5.429,4.831-11.042,9.827-14.15,17.478 
            c-2.163,5.324-4.619,14.608-7.219,24.437c-3.223,12.183-6.555,24.78-9.229,29.113c-8.392,13.598-28.729,20.493-60.447,20.494 
            c-9.78,0-18.455-0.681-23.638-1.088c-1.78-0.14-3.204-0.251-4.181-0.298c-4.961-1.708-8.641-4.51-10.938-8.328 
            c-4.726-7.855-12.54-26.714-20.097-44.951C107.328,33.2,102.402,22.46,99.425,22.46l-0.143,0.007 
            c-2.35,0.223-2.929,2.099-3.025,3.752c-0.464-0.729-0.921-1.473-1.187-1.904c-4.26-6.92-9.162-10.73-13.805-10.73 
            c-2.144,0-4.158,0.849-5.824,2.455c-3.996,3.854-4.801,6.667-5.448,8.928c-0.521,1.824-0.898,3.142-2.759,4.668 
            c-0.555,0.455-1.999,0.816-3.527,1.2c-4.37,1.095-10.975,2.749-11.388,9.979c-0.51,8.928-3.042,12.189-5.077,14.81 
            c-2.128,2.74-3.965,5.106-2.571,11.865c1.001,4.85,1.028,6.144-4.266,13.828c-4.999,7.257-21.137,40.815-11.441,55.43 
            c3.238,4.883,2.152,9.554,0.508,16.627c-2.708,11.645-6.8,29.238,6.586,62.282c7.818,19.303,23.578,31.248,32.994,38.389 
            c1.798,1.364,3.352,2.529,4.614,3.573c7.537,6.231,26.299,15.425,42.634,15.425c3.724,0,7.19-0.532,10.307-1.474 
            c3.415-1.029,7.476,2.634,12.839,7.647c7.419,6.935,17.643,16.361,33.89,20.838c2.025,7.043,3.394,12.093,3.414,12.904 
            c-0.159,1.735-4.818,4.321-9.752,7.059c-3.521,1.953-5.37,4.101-4.945,5.743c0.316,1.219,1.714,1.712,3.504,1.152 
            c0.473-0.147,0.937-0.313,1.409-0.48c1.629-0.58,3.313-1.18,5.876-1.18c1.643,0,3.469,0.241,5.581,0.738 
            c3.968,0.933,4.974,2.25,6.038,3.644c1.039,1.36,2.216,2.901,5.783,3.883c1.362,0.375,2.829,0.714,4.381,1.072 
            c6.057,1.399,12.921,2.985,15.015,7.22c0.254,0.513,0.412,0.941,0.54,1.286c0.277,0.75,0.623,1.685,1.811,1.685 
            c0.324,0,0.693-0.075,1.234-0.25c1.424-0.461,2.832-1.908,3.426-3.52c0.529-1.438,0.396-2.921-0.375-4.175 
            c-1.247-2.026-3.291-3.75-5.475-5.151c1.782,0.262,3.697,0.45,5.671,0.498c8.232,0.2,14.141,1.659,17.996,2.901 
            c-1.117,1.779-4.82,4.308-8.714,6.963c-3.31,2.257-4.922,4.478-4.424,6.092c0.378,1.229,1.844,1.647,3.622,0.907 
            c0.456-0.19,0.903-0.396,1.358-0.607c1.898-0.88,3.862-1.79,7.46-1.79c1.179,0,2.494,0.1,3.908,0.298 
            c4.037,0.565,5.159,1.784,6.348,3.075c1.158,1.259,2.473,2.686,6.115,3.336c1.39,0.248,2.881,0.451,4.459,0.665 
            c6.159,0.838,13.141,1.786,15.615,5.812c0.299,0.487,0.496,0.9,0.654,1.231c0.341,0.712,0.728,1.52,1.779,1.519 
            c0.37,0,0.771-0.107,1.384-0.37c1.376-0.589,2.646-2.16,3.09-3.819c0.396-1.48,0.127-2.944-0.756-4.121 
            c-1.429-1.905-3.624-3.435-5.929-4.63c1.836,0.101,3.852,0.102,5.696-0.022c1.65-0.112,3.299-0.169,4.9-0.169 
            c8.324,0,13.961,1.522,16.67,2.253c1.084,0.293,1.682,0.454,2.121,0.454c0.631,0,0.936-0.34,1.061-0.543 
            c0.126-0.203,0.294-0.628,0.013-1.19c-1.295-2.59-3.693-5.383-12.394-8.252c-2.976-0.982-8.327-1.442-13.049-1.85 
            c-2.467-0.212-5.365-0.462-7.064-0.757c0.952-0.335,2.021-0.527,2.813-0.67c1.231-0.222,2.122-0.382,2.141-1.347 
            c0.021-1.096-0.868-1.293-5.146-2.237c-1.679-0.371-3.276-0.551-4.886-0.551c-1.957,0-3.549,0.272-4.827,0.491 
            c-1.332,0.229-2.378,0.384-3.016,0.153c-1.769-1.812-11.424-22.73-19.702-41.26c3.99-1.454,8.062-3.089,12.221-4.928 
            c1.807-0.483,3.477-0.735,5.103-1.215c29.062-8.566,74.569-42.8,80.826-101.998c6.78-64.153-6.703-88.669-11.134-96.725 
            c-6.076-11.049-10.811-25.081-5.876-31.332c4.339-5.497,4.034-12.29,3.871-15.94c-0.033-0.736-0.07-1.551-0.044-1.988 
            c0.089-0.007,0.204-0.012,0.351-0.012c1.642,0,4.934,0.588,7.838,1.106c2.683,0.479,5,0.894,6.146,0.894 
            c0.314,0,1.272,0,1.536-0.851C336.782,40.594,336.524,40.072,335.841,39.604z M193.143,302.621c8.624,0,17.985-1.288,27.991-3.889 
            c4.348,10.776,8.703,21.722,11.297,28.558c-0.89-0.453-1.869-0.916-2.958-1.39c-2.873-1.25-8.158-2.199-12.821-3.037 
            c-2.438-0.438-5.304-0.953-6.968-1.403c0.979-0.246,2.061-0.34,2.861-0.409c1.247-0.107,2.148-0.186,2.256-1.144 
            c0.122-1.09-0.747-1.367-4.919-2.7c-2.291-0.732-4.523-1.089-6.826-1.089c-1.075,0-2.013,0.073-2.84,0.138 
            c-0.639,0.05-1.189,0.093-1.676,0.093c-0.599,0-1.019-0.067-1.342-0.217c-0.749-0.923-2.844-6.203-5.532-13.533 
            c0.488,0.008,0.971,0.022,1.467,0.022C193.137,302.621,193.139,302.621,193.143,302.621z M211.99,343.771L211.99,343.771v0.009 V343.771z"/>
        </SvgIcon>
    );
}

export const FeedChartIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512">
            <path d="M311.796,223.427c-3.413,3.413-3.413,8.533,0,11.947s8.533,3.413,11.947,0s3.413-8.533,0-11.947 S315.21,220.013,311.796,223.427z"/> 
            <path d="M289.61,240.493c-3.413-3.413-8.533-3.413-11.947,0s-3.413,8.533,0,11.947s8.533,3.413,11.947,0 S293.023,243.907,289.61,240.493z"/> 
            <path d="M294.73,257.56c-3.413,3.413-3.413,8.533,0,11.947s8.533,3.413,11.947,0c3.413-3.413,3.413-8.533,0-11.947 S298.143,254.147,294.73,257.56z"/> 
            <path d="M269.13,283.16c-3.413,3.413-3.413,8.533,0,11.947s8.533,3.413,11.947,0s3.413-8.533,0-11.947 S272.543,279.747,269.13,283.16z"/> 
            <path d="M255.476,278.04c3.413-3.413,3.413-8.533,0-11.947s-8.533-3.413-11.947,0s-3.413,8.533,0,11.947 S252.063,281.453,255.476,278.04z"/> 
            <path d="M252.063,308.76c-3.413,3.413-3.413,8.533,0,11.947c3.413,3.413,8.533,3.413,11.947,0c3.413-3.413,3.413-8.533,0-11.947 S255.476,305.347,252.063,308.76z"/> 
            <path d="M226.463,291.693c-3.413,3.413-3.413,8.533,0,11.947s8.533,3.413,11.947,0s3.413-8.533,0-11.947 C234.996,288.28,229.876,288.28,226.463,291.693z"/> 
            <path d="M226.463,325.827c-3.413,3.413-3.413,8.533,0,11.947c3.413,3.413,8.533,3.413,11.947,0c3.413-3.413,3.413-8.533,0-11.947 C234.996,322.413,229.876,322.413,226.463,325.827z"/> 
            <path d="M209.396,351.427c-3.413,3.413-3.413,8.533,0,11.947s8.533,3.413,11.947,0s3.413-8.533,0-11.947 C217.93,348.013,212.81,348.013,209.396,351.427z"/> 
            <path d="M334.836-1c-52.053,0-93.867,41.813-93.867,93.867V127v34.133v68.028c-5.74,4.316-11.41,8.798-16.941,13.366 
            c0.885-14.627,0.22-29.134-2.686-42.994c-0.853-4.267-3.413-6.827-7.68-6.827s-7.68,2.56-8.533,5.973 
            c0,0-14.507,46.08-41.813,72.533l-3.413,3.413c-26.453,24.747-53.76,51.2-53.76,125.44c0,6.827-3.413,12.8-9.387,15.36 C84.81,421.4,78.836,434.2,82.25,447c1.707,8.533,6.827,16.213,14.507,23.04c1.707,1.707,13.653,14.507,28.16,14.507 
            c1.707,0,2.56,0,3.413,0.853c10.24-1.707,17.92-8.533,23.04-20.48c1.968-4.263,3.554-8.527,4.765-12.79 
            c11.496,4.139,22.992,5.964,34.488,5.964c17.509,0,34.489-4.468,49.493-12.954v57.327c0,5.12,3.413,8.533,8.533,8.533h60.587 
            h51.2h58.88c5.12,0,8.533-3.413,9.387-8.533V161.133V127V92.867C428.703,40.813,386.89-1,334.836-1z M258.036,135.533h153.6 
            V152.6h-153.6V135.533z M258.036,92.867c0-42.667,34.133-76.8,76.8-76.8c42.667,0,76.8,34.133,76.8,76.8v25.6h-153.6V92.867z 
            M339.956,206.36c4.267,5.12,4.267,20.48-11.093,50.347c-15.36,29.013-40.96,62.293-72.533,93.867l-1.69,1.352 
            c-33.328,17.35-64.895,41.505-69.99,45.581c0,0-9.299,6.337-20.755,11.877c1.828-5.216,4.886-9.748,7.955-13.584 
            c11.093-14.507,18.773-26.453,23.893-39.253c9.387-24.747,20.48-56.32,26.453-90.453c54.558-48.011,94.847-64.391,112.422-62.366 
            C336.893,204.218,338.693,205.097,339.956,206.36z M125.77,466.627c-5.973,0.853-14.507-5.973-17.067-9.387 
            c-5.12-4.267-8.533-10.24-10.24-15.36c-0.853-4.267,1.707-8.533,5.973-11.093c11.947-5.12,18.773-17.067,18.773-30.72 c0-67.413,22.187-88.747,48.64-113.493l3.413-3.413c13.653-13.653,24.747-30.72,32.427-46.08 
            c-1.707,40.107-16.213,81.92-28.16,113.493c-4.267,10.24-11.093,21.333-21.333,34.133c-7.68,8.533-12.8,20.48-14.507,33.28 
            c-0.681,2.042-0.817,3.539-0.844,4.929c-0.002,0.064-0.009,0.127-0.009,0.191c0,1.707,0,4.267,0,5.973 
            c0,0.278,0,0.57-0.001,0.867c-0.854,8.529-2.561,17.058-6.825,26.44C133.45,460.653,130.036,465.773,125.77,466.627z 
            M159.05,435.907c0-1.5,0.001-3,0.581-4.5c4.891-1.828,9.67-4.014,14.056-6.235c11.725-5.573,20.579-11.68,21.203-12.304 
            c0.853,0,34.133-26.453,68.267-44.373c0.237-0.237,0.405-0.405,0.547-0.547c17.889-9.526,35.143-16.363,47.11-15.807 
            c-20.068,14.381-37.664,32.072-52.776,53.901l-0.853,0.853c-3.413,5.12-8.533,10.24-13.653,14.507 
            c-1.485,0.99-2.382,2.27-2.885,3.668C217.614,442.141,187.032,446.566,159.05,435.907z M317.77,493.933v-51.2 
            c0-9.387,7.68-17.067,17.067-17.067s17.067,7.68,17.067,17.067v51.2H317.77z M410.783,493.933H368.97v-51.2 
            c0-18.773-15.36-34.133-34.133-34.133c-18.773,0-34.133,15.36-34.133,34.133v51.2h-43.52v-60.969 
            c5.278-4.67,10.146-9.954,14.507-15.831v-0.853c17.067-23.893,36.693-42.667,58.88-56.32l1.707-0.853 
            c2.56-1.707,3.413-3.413,4.267-5.973s0-5.12-0.853-6.827c-4.267-6.827-11.093-10.24-18.773-11.947 
            c-6.582-1.097-14.248-0.38-22.508,1.613c21.474-24.314,38.812-48.892,50.668-70.733c17.92-33.28,20.48-58.027,7.68-70.827 
            c-4.301-4.301-9.713-6.742-15.964-7.64c-23.412-4.151-57.007,14.759-79.609,29.827v-46.933h153.6V493.933z"/> 
        </SvgIcon>
    )
}

export const SlaughtersChartIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 215 256">
            <path d="M107.5,43.63c-11.01,0-19.82,8.61-19.82,19.82c0,10.81,8.81,19.81,19.82,19.81s19.82-8.8,19.82-19.81 S118.51,43.63,107.5,43.63z M107.5,2L2.02,55.04V254h210.96V55.04L107.5,2z M142.98,227.74c0,5.51-4.46,9.98-9.98,9.98H82 c-5.52,0-9.98-4.47-9.98-9.98v-1.2c0-13.77,8.4-25.58,20.34-30.57c0.39-0.14,0.7-0.39,0.95-0.73c0.77-0.99,1.6-1.96,2.57-2.93 c5.55-5.55,13.64-8.9,20.05-9.18c3.21-0.11,6.24-1.44,8.51-3.71l1.83-1.86c1.13-1.14,1.61-2.83,1.13-4.35 c-0.19-0.64-0.27-1.34-0.19-2.03c0.22-2.3,2.08-4.18,4.38-4.43c3.02-0.34,5.57,2.02,5.57,4.96c0,0.58,0.36,0.86,0.81,0.86 c2.75,0,4.99,2.24,4.99,5c0,0.19-0.01,0.38-0.03,0.57c-0.25,2.3-2.14,4.16-4.44,4.38c-0.72,0.06-1.38-0.03-2.02-0.22 c-1.53-0.5-3.22,0-4.35,1.14l-1.89,1.88c-4.07,4.08-3.3,9.45-4.74,14.58c-1.44,5.21-4.24,10.07-8.07,13.89 c-3.79,3.83-8.03,6.13-12.22,6.68c-1.22,0.17-2.08,1.28-1.91,2.5c0.13,1.13,1.08,1.94,2.19,1.94c0.08,0,0.19-0.03,0.27-0.03 c5.16-0.67,10.29-3.41,14.81-7.93c4.4-4.41,7.81-10.45,9.45-16.72c7.87,6.08,12.97,15.61,12.97,26.31V227.74z M75.47,118.89 c0-1.4,1.01-2.6,2.61-2.6c1.4,0,2.6,1,2.6,2.6v26.02h-4.81L75.47,118.89z M134.72,118.89c0-1.4,1-2.6,2.6-2.6c1.6,0,2.61,1,2.61,2.6 v26.02h-4.81v-26.02H134.72z M204.58,144.91h-47.64v-30.82c0-13.61-11.01-24.62-24.62-24.62H83.08c-13.61,0-24.62,11.01-24.62,24.62 v30.82H10.62V60.25l96.88-48.64l97.08,48.84V144.91z M87.68,63.45c0,10.81,8.81,19.81,19.82,19.81s19.82-8.8,19.82-19.81 s-8.81-19.82-19.82-19.82S87.68,52.24,87.68,63.45z M107.5,43.63c-11.01,0-19.82,8.61-19.82,19.82c0,10.81,8.81,19.81,19.82,19.81 s19.82-8.8,19.82-19.81S118.51,43.63,107.5,43.63z"/>           
        </SvgIcon>
    )
}

