import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Box, ListItemText, TableCell, Typography} from "@material-ui/core";
import {getSensorDataReportValueTypesFormat, sensorDataReportValueTypes, uomLut} from "../../utils/CbvUtils";
import TableBody from "@material-ui/core/TableBody";
import i18next from "../../i18n/i18next";
import Moment from "moment";
import React from "react";
import _ from "lodash";
import {CustomTagField} from "../tag_management/TagController";

const CustomReportTable = ({data, classes}) => {
    const getValues = (report) => {
        return _.filter(sensorDataReportValueTypes, function (o) {
            return Object.keys(report).includes(o.name)
        })
    }

    return <TableContainer>
        <Table aria-label="spanning table" size="small">
            <TableHead>
                <TableRow style={{background:'whitesmoke'}}>
                    <TableCell  className={classes.stickyCell} style={{background:'white'}}/>
                    <TableCell align="center" size={'small'} className={classes.tableBorder}>
                        <Typography variant={'body2'}><strong>{i18next.t('entities.producer')}</strong></Typography>
                    </TableCell>
                    <TableCell align="center" size={'small'} className={classes.tableBorder}>
                        <Typography variant={'body2'}><strong>Tag</strong></Typography>
                    </TableCell>
                    {data[0]?.data?.elements && data[0]?.data?.elements[0].values.map((report, i) => (
                        <TableCell key={i} className={classes.tableBorder} size={'small'} align={'center'}>
                            <Typography variant={'body2'}><strong>{report.label}</strong></Typography>
                            <Typography variant={'caption'}>{report.uom ? `(${uomLut[report.uom]||report.uom})` : ''}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            {data?.map((record, i) => (
                record?.data?.elements.map((element, j) => (
                <TableBody key={j}>
                    <TableRow>
                        <TableCell align="center" className={classes.stickyCell} size={'small'}>
                            <ListItemText
                                primary={<Typography variant={'body2'}>{element?.time && i18next.t("formatters:dateTime", {date: Moment(element.time)})}</Typography>}
                                secondary={element?.key && element.key}
                            />
                        </TableCell>
                        <TableCell align="center" size={'small'} className={classes.tableBorder}>
                            <Typography variant={'body2'}>{record.data?.metadata?.producer_id || ''}</Typography>
                        </TableCell>
                        <TableCell align="center" size={'small'} className={classes.tableBorder}>
                            <CustomTagField tags={record.data?.metadata?.tags}/>
                        </TableCell>
                        {element?.values && element?.values.map((report, i) => (
                            <TableCell key={i} className={classes.tableBorder} size={'small'} style={{padding: 0}}>
                                {getValues(report).map((value, j) => (
                                    <Box key={j}
                                         style={{paddingInline: '8px', textAlign: value.align}} alignItems={'start'}>
                                        {getSensorDataReportValueTypesFormat(value, report[value.name])}
                                    </Box>
                                ))}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            ))))}
        </Table>
    </TableContainer>;
}

export default CustomReportTable
