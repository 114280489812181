import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import * as PropTypes from "prop-types";
import React, {useContext} from "react";
import EditCompanyImage from "./EditCompanyImage";
import {ThemeContext} from "../../contexts";
import {useTranslation} from "react-i18next";

const BrandDetailComponent = (props) => {
    const {t} = useTranslation()
    const _theme = useContext(ThemeContext)

    return <Accordion className={props.classes.expansion}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}>
            <ListItem alignItems="center">
                <Avatar alt={props.brand.name}
                        src={props.brand.image ? "api/uploads/uploads/" + props.brand.image : props.src}
                        className={props.classes.brandListAvatar}/>
                <ListItemText primary={props.brand.name}/>
                {props.index !== 0 && props.myCompany ? <EditCompanyImage company={props.company} data={props.brand} theme={_theme}/> : null}
            </ListItem>
        </AccordionSummary>
        <AccordionDetails style={{paddingTop: 0}}>
            <List dense disablePadding={true}>
                {props.brand.company_prefix ? <ListItem>
                    <ListItemText primary={props.brand.company_prefix}
                                  secondary={t('entities.company.prefix', 'Company Prefix')}/>
                </ListItem> : null}
                <ListItem>
                    <ListItemText primary={props.brand.domain} secondary={t('entities.company.domain', 'Domain')}/>
                </ListItem>
            </List>
        </AccordionDetails>
    </Accordion>;
}

BrandDetailComponent.propTypes = {
    classes: PropTypes.any,
    brand: PropTypes.any,
    src: PropTypes.string
};

export default BrandDetailComponent
