import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {MarbleContext, MarbleProvider, ThemeContext} from "../../contexts";
import Graph from "./Graph";
import * as d3 from 'd3'
import {getSliderLabel, getSliderMarks, getTimeClusters, getTimeTick, TimeScaleEnum} from "./Time";
import LinkDetails, {formatFunction} from "./LinkDetails";
import moment from "moment";
import ".//marble-style.css";
import {makeStyles} from "@material-ui/core/styles";
import Draggable from 'react-draggable';
import {parseName} from "../../utils/Utils";
import axios from "axios";
import {
    marbleInitialize,
    marbleReducer,
    setDetails, setDialogContent,
    setExpandedNode,
    setPeriod,
    setServerNodes,
    setShowGraph,
} from "./marble_reducer";
import MarbleEventsDetails from "./MarbleEventsDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import Button from "@material-ui/core/Button";
import {
    Backdrop,
    Box,
    Dialog,
    DialogContent, DialogContentText,
    DialogTitle,
    List,
    ListItem, ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, Slider
} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import {
    aggregationTypes,
    createSubGraphLinks,
    filterCompaniesGraph,
    filterNodesByTags,
    filterProductGraph,
    setDagreLinks,
    setDagreNodes,
    setProductLineHistoryNodes
} from "./MarbleUtility";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import Fade from "@material-ui/core/Fade";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Tooltip from "@material-ui/core/Tooltip";
import Filters from "./filters/Filters";
import {useTranslation} from "react-i18next";
import CustomLoader from "../../components/CustomLoader";
import Chip from "@material-ui/core/Chip";
import {
    convertLocationClassToInstance,
    extractLocationClassCode,
} from "../../utils/CodesUtils";
import {LocationOn, RemoveRedEye} from "@material-ui/icons";

export const palette = [
    // fill
    '#274d7b',
    '#31619B',
    '#3B74BA',
    '#5489C9',
    '#7ba3d4',
    '#92B4DD',
    '#c7d9f1',

    // strokes
    '#274d7b',
    '#31619B',
    '#3B74BA',
    '#5489C9',
    '#7ba3d4',
    '#92B4DD',
    '#c7d9f1',

    // clustering button
    '#e3eaf5',
]

function CustomButton({onClick, selected, children}) {
    return (
        <Button onClick={onClick}
                style={{
                    //flex: 1,
                    margin: '0.25em',
                    backgroundColor: palette[palette.length - 1]
                }}
                disabled={selected}>
            {children}
        </Button>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: "8px",
        margin: "0 8px",
        width: "calc(100% - 16px)"
    },
    thumb: {
        height: "16px",
        width: "16px",
        marginTop: "-4px",
        marginLeft: "-8px",
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    track: {
        height: "8px",
        borderRadius: "4px",
    },
    rail: {
        height: "8px",
        borderRadius: "4px",
    },
    mark: {
        display: 'none'
    }
}));

function TimeScale({fetchClusteredEvents}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [state, dispatch] = useContext(MarbleContext);

    const svgRef = useRef(null)
    const timeClusters = getTimeClusters(state.date, state.timeScale)
    const interval = [timeClusters[0][0], timeClusters[timeClusters.length - 1][1]]
    const scale = d3
        .scaleTime()
        .domain(interval)
        .range([20, 780])
    const axis = d3.axisBottom(scale)
        .ticks(getTimeTick(state.timeScale))
    useEffect(() => {
        d3.select(".axis").call(axis)
    });

    const sliderMarks = useMemo(() => {
        return getSliderMarks(state.oldestDate, state.mostRecentDate, state.timeScale);
    }, [state.oldestDate, state.mostRecentDate, state.timeScale]);

    const [sliderValue, setSliderValue] = useState(state.date);
    useEffect(() => {
        for (let i = 0; i<sliderMarks.length; i++) {
            const sliderTime = sliderValue.getTime();
            const sliderMarkTime = sliderMarks[i].value;
            // same value, no need to adjust its value
            if (sliderTime === sliderMarkTime)
                break;
            if (sliderTime > sliderMarkTime && (i === sliderMarks.length -1 || sliderTime < sliderMarks[i+1].value)) {
                setSliderValue(new Date(+sliderMarkTime));
            }
        }
    }, [state.date, sliderMarks]);


    return (
        <div>
            <div style={{display: 'flex', width: '800px', alignItems: 'center', justifyContent: 'center'}}>
                <div>{t('productionFlow.timeLineControlPanel.clustering')}:</div>
                <CustomButton
                    selected={state.timeScale === TimeScaleEnum.one_hour}
                    onClick={() => {
                        fetchClusteredEvents(TimeScaleEnum.one_hour)
                    }}>{t('productionFlow.timeLineControlPanel.timeScaleHour', {count: 1})}</CustomButton>
                <CustomButton
                    selected={state.timeScale === TimeScaleEnum.four_hours}
                    onClick={() => {
                        fetchClusteredEvents(TimeScaleEnum.four_hours)
                    }}>{t('productionFlow.timeLineControlPanel.timeScaleHour', {count: 4})}</CustomButton>
                <CustomButton
                    selected={state.timeScale === TimeScaleEnum.one_day}
                    onClick={() => {
                        fetchClusteredEvents(TimeScaleEnum.one_day)
                    }}>{t('productionFlow.timeLineControlPanel.timeScaleDay', {count: 1})}</CustomButton>
                <CustomButton
                    selected={state.timeScale === TimeScaleEnum.one_week}
                    onClick={() => {
                        fetchClusteredEvents(TimeScaleEnum.one_week)
                    }}>{t('productionFlow.timeLineControlPanel.timeScaleWeek', {count: 1})}</CustomButton>
                <CustomButton
                    selected={state.timeScale === TimeScaleEnum.one_month}
                    onClick={() => {
                        fetchClusteredEvents(TimeScaleEnum.one_month)
                    }}>{t('productionFlow.timeLineControlPanel.timeScaleMonth', {count: 1})}</CustomButton>
                <CustomButton
                    selected={state.timeScale === TimeScaleEnum.three_months}
                    onClick={() => {
                        fetchClusteredEvents(TimeScaleEnum.three_months)
                    }}>{t('productionFlow.timeLineControlPanel.timeScaleMonth', {count: 3})}</CustomButton>
                <CustomButton
                    selected={state.timeScale === TimeScaleEnum.one_year}
                    onClick={() => {
                        fetchClusteredEvents(TimeScaleEnum.one_year)
                    }}>{t('productionFlow.timeLineControlPanel.timeScaleYear', {count: 1})}</CustomButton>
            </div>

            <svg ref={svgRef} width={800} height={80}>
                <g className={"axis"} transform="translate(0,20)"/>
                {timeClusters.map(([from, to], i) =>
                    <rect
                        key={i}
                        x={scale(from) - 1}
                        y={4}
                        width={scale(to) - scale(from) - 1}
                        height={5}
                        rx={2}
                        ry={2}
                        fill={palette[i]}
                        stroke={palette[i + 7]}
                    />)}
                <line x1={400} y1={0} x2={400} y2={40} stroke={"red"}/>
                <text transform="translate(400,60)" textAnchor={"middle"}>{getSliderLabel(sliderValue, state.timeScale)}</text>
            </svg>
            <div style={{width: 800}}>
                <Slider
                    classes={{
                        root: classes.root,
                        thumb: classes.thumb,
                        track: classes.track,
                        rail: classes.rail,
                        mark: classes.mark
                    }}
                    min={sliderMarks[0].value}
                    max={sliderMarks[sliderMarks.length-1].value}
                    step={null}
                    value={sliderValue.getTime()}
                    marks={sliderMarks}
                    onChange={(e, newValue) => {
                        setSliderValue(new Date(+newValue))
                    }}
                    onChangeCommitted={(e, newValue) => {
                        dispatch(setPeriod(new Date(+newValue), state.timeScale))
                    }}
                />
            </div>
        </div>
    )
}

function Details(_) {
    const [state, dispatch] = useContext(MarbleContext);
    const {primaryColorAlpha} = useContext(ThemeContext)

    const handleCloseDetails = () => {
        dispatch(setDetails(null))
    }

    if (!state.details) return null
    switch (state.details.type) {
        case 'link':
            return (
                <LinkDetails
                    link={state.details.link}
                    date={state.date}
                    timeScale={state.timeScale}
                    onClick={d => dispatch(setDetails(d))}
                    companyNameLut={state.companyNameLut}
                />
            )
        case 'events':
            return (
                <Fade in={!!state.details}>
                    <Box style={{
                        position: 'fixed',
                        top: '65px',
                        zIndex: 1100
                    }}>
                        <IconButton style={{
                            position: 'fixed',
                            left: 'auto',
                            right:' 30%',
                            color: primaryColorAlpha
                        }} aria-label="cancel" onClick={handleCloseDetails}>
                            <CancelIcon fontSize="large"/>
                        </IconButton>
                        <MarbleEventsDetails clusteredEventParameters={state.details.events}/>
                    </Box>
                </Fade>
            )

        case 'pair':
            const t0 = state.details.pair.e0.eventTime.getTime()
            const t1 = state.details.pair.e1.eventTime.getTime()
            const d = moment.duration(t1 - t0)
            return (
                <div style={{height: '100%', overflow: 'auto'}}>
          <pre style={{textAlign: 'left'}}>
            {JSON.stringify(state.details.pair, null, 4)}
          </pre>
                    <p>Delay: {formatFunction(d, 2)(d)}</p>
                </div>
            )

        case 'node':
            return <h3>{state.companyNameLut[state.details.node.id] ?
                state.companyNameLut[state.details.node.id]
                : parseName(state.details.node.label.replace('urn:epcglobal:cbv:bizstep:', ''))}</h3>
        case 'parent':
            return (<h3>{state.companyNameLut[state.details.parentId]}</h3>)
        default:
            return null
    }
}

const MarbleDiagram2 = () => {
    const { t } = useTranslation()
    const {primaryColor, primaryColorAlpha, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        panel: {
            backgroundColor: primaryColor,
            position: 'absolute',
            bottom: '0px',
            left: '20px'
        },
        panelFilter: {
            backgroundColor: 'white',
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            color: 'whitesmoke'
        },
        headingFilter: {
            fontSize: theme.typography.pxToRem(15),
            color: primaryColor
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        details: {
            alignItems: 'center',
            backgroundColor: '#fafafa'
        },
        column: {
            //flexBasis: '33.33%',
            marginRight: '2%'
        },
        dialog: {
            webkitBoxShadow: " 3px 4px 18px -1px",
            MozBoxShadow: "3px 4px 18px -1px rgba(150,150,150,0.71)",
            boxShadow: "3px 4px 18px -1px rgba(150,150,150,0.71)",
            borderRadius: "4px",
            width: '40%'
        },
    }));

    const classes = useStyles()
    const [state, dispatch] = useContext(MarbleContext)
    const {aggregationType} = state

    const aggregationTypeNodes = useMemo(() => {
        if(!state.serverNodes)
            return null
        if(aggregationType === aggregationTypes.why)
            return state.serverNodes
        if(aggregationType === aggregationTypes.where) {
            return _.map(state.serverNodes, (node) => ({...node, parent: node.label, label: node.parent}))
        }
    }, [aggregationType, state.serverNodes])

    const tagNodes = useMemo(() => {
        if(!aggregationTypeNodes || !state.filterTags)
            return null

        //console.log("state.filterTags:",state.filterTags)
        return filterNodesByTags(aggregationTypeNodes, state.products, state.filterTags)
    }, [aggregationTypeNodes, state.filterTags])

    const filteredProductsNodes = useMemo(() => {
        if (!tagNodes || !aggregationTypeNodes || !state.filterProducts)
            return null
        if(state.filterProducts.length === 0 && tagNodes.length !== 0)
            return tagNodes
        return filterProductGraph(tagNodes.length === 0 ? aggregationTypeNodes : tagNodes, state.filterProducts)
    }, [tagNodes, aggregationTypeNodes, state.filterProducts])

    // TODO: check if this is correct in case of filter by companies (variable used in other useMemo)
    const historyNodes = useMemo(() => {
        if (!filteredProductsNodes)
            return null
        //console.log("useMemo --> filteredProductsNodes:", filteredProductsNodes)

        return setProductLineHistoryNodes(aggregationTypeNodes, filteredProductsNodes, state.filterProducts);
    }, [filteredProductsNodes])

    const filteredCompaniesNodes = useMemo(() => {
        if(!filteredProductsNodes || !state.filterCompanies || !state.aggregationType)
            return null
        return filterCompaniesGraph(filteredProductsNodes, state.filterCompanies, state.aggregationType)
    }, [filteredProductsNodes, state.filterCompanies, state.aggregationType])

    const filteredNodes = state.filterCompanies && state.filterCompanies.length > 0 ? filteredCompaniesNodes :
        state.filterProducts && state.filterProducts.length > 0  ? filteredProductsNodes :
        state.filterTags && state.filterTags.length > 0  ? tagNodes : aggregationTypeNodes;

    /*const filteredNodes = useMemo(() => {
        if(!aggregationTypeNodes || !filteredProductsNodes || !filteredCompaniesNodes)
            return null
        if(filteredProductsNodes.length === 0 && filteredCompaniesNodes.length === 0) {
            return aggregationTypeNodes
        }
        return _.unionBy(filteredProductsNodes, filteredCompaniesNodes, 'id')
    }, [aggregationTypeNodes, filteredProductsNodes, filteredCompaniesNodes])*/

    const subLinks = useMemo(() => {
        if (!aggregationTypeNodes || !filteredNodes || !historyNodes || !state.expandedSublinks)
            return null

        return createSubGraphLinks(aggregationTypeNodes,
            state.lineProductChecked && historyNodes ? _.unionBy(historyNodes, filteredNodes, 'id') : filteredNodes,
            state.expandedSublinks)
    }, [aggregationTypeNodes, filteredNodes, historyNodes, state.expandedSublinks, state.lineProductChecked])

    const dagreNodes = useMemo(() => {
        if (!aggregationTypeNodes || !filteredNodes || !historyNodes || !state.expandedNodes || !state.nodesToAdd)
            return null

        //console.log("dagreNodes --> filteredNodes:", filteredNodes)
        let allGraphNodes = state.lineProductChecked && historyNodes ? _.cloneDeep(_.unionBy(historyNodes, filteredNodes)) : _.cloneDeep(filteredNodes)
        // children dei subGraphLink da aggiungere
        if (state.nodesToAdd && state.nodesToAdd.length !== 0) {
            allGraphNodes = _.unionBy(allGraphNodes, state.nodesToAdd, 'id')
            //console.log("setDagreNodes ---> graph+nodesToAdd nodes:", allGraphNodes)
        }
        return setDagreNodes(allGraphNodes, state.expandedNodes, aggregationType)
    }, [aggregationTypeNodes, filteredNodes, historyNodes, state.expandedNodes, state.nodesToAdd, state.lineProductChecked])

    const [dagreLinks, allDagreNodes] = useMemo(() => {
        if (!aggregationTypeNodes || !dagreNodes || !subLinks || !state.expandedNodes || !state.childrenParentMap)
            return [null, null]

        const [links, nodes] = setDagreLinks(aggregationTypeNodes, state.serverLinks, subLinks, dagreNodes, historyNodes, state.expandedNodes, state.childrenParentMap)
        return [links, nodes]
    }, [aggregationTypeNodes, dagreNodes, subLinks, state.expandedNodes, state.childrenParentMap])

    const fetchClusteredEvents = (timeScale) => {
        const url = 'api/capture/epcis_events/marble/v2/clustered_events/?group=' + timeScale
        dispatch(setShowGraph(false))
        axios.get(url).then(res => {
            if (res) {
                const clusteredNodes = state.serverNodes.map(node => ({...node, clusteredEvents: res.data[node.id]}))
                //console.log("clusteredNodes:", clusteredNodes)
                dispatch(setServerNodes(clusteredNodes, timeScale))
            }
        }).finally(() => {dispatch(setShowGraph(true))})
    }

    return (
        <div>
            <div className="graph">
                {<Backdrop open={!state.showGraph} children={<CustomLoader/>} style={{zIndex:1, color: 'white'}}/>}
                {
                    allDagreNodes && dagreLinks ?
                        <Graph
                            width={document.body.offsetWidth - 250} // drawer width
                            nodes={Object.values(allDagreNodes)}
                            links={Object.values(dagreLinks)}
                            fitBoundaries
                            zoomable
                            onParentClick={(e) => {dispatch(setExpandedNode(e.parentId))}}
                            onNodeClick={(e) => dispatch(setDetails(e))}
                            onLinkClick={(e) => dispatch(setDetails(e))}
                            onEventClick={(e) => dispatch(setDetails(e))}
                            onExpandClick={(id) => {dispatch(setExpandedNode(id))}}
                            onDialogOpen={(dialogContent) => {
                                dispatch(setShowGraph(false))
                                setTimeout(function () {
                                    dispatch(setDialogContent(dialogContent))
                                    dispatch(setShowGraph(true))
                                }, 50);}}
                        /> : <CustomLoader/>
                }
            </div>
            <Box style={{width: '25%', position: 'absolute', top: '90px', right: '20px'}}>
                <Draggable onStop={() => {}}>
                    <Box>
                        <Accordion className={classes.panelFilter}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <div className={classes.column}>
                                    <div style={{display: 'flex', cursor: 'pointer'}}
                                         className={classes.headingFilter}>
                                        <Tooltip arrow title={t('actions.dragComponent')}>
                                            <DragIndicatorIcon style={{color: primaryColorAlpha}}/>
                                        </Tooltip>
                                        <FilterListIcon/>
                                        <Box pl={2}>
                                            <Typography>{_.startCase(t('entities.filter_plural'))} {(state.filterProducts || state.filterCompanies || state.filterTags) ?
                                                `(${state.filterProducts.length + state.filterCompanies.length + state.filterTags.length})`
                                                : ''}
                                            </Typography>
                                        </Box>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Box width="100%">
                                    <Filters/>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Draggable>
            </Box>

            <Details/>

            <Box style={{position: 'absolute', bottom: '40px', left: '40px'}}>
                <Accordion defaultExpanded className={classes.panel}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: 'whitesmoke'}}/>}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <div className={classes.column}>
                            <Typography className={classes.heading}>{t('productionFlow.timeLineControlPanel.label')}</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <Box width="100%">
                            <Box width="100%">
                                <TimeScale fetchClusteredEvents={fetchClusteredEvents}/>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            {/** Dialog solo per la lista delle location (solo se l'aggregation type è WHY) */}
            <Dialog hideBackdrop open={!!state.dialogContent} onClose={() => {dispatch(setDialogContent(null))}}>
                <DialogTitle>{state.dialogContent?.label}</DialogTitle>
                {state.dialogContent && <DialogContent>
                    <DialogContentText><Typography variant={"subtitle1"}>{t('productionFlow.whereEventsHappened', "Where the events happened")}</Typography></DialogContentText>
                    <List>
                        {
                            Object.values(state.dialogContent?.children).map((child, index) => {
                                const codeLoc = convertLocationClassToInstance(extractLocationClassCode(child?.parent))
                                const events = {
                                    nodeSignature: child?.id,
                                    label: state.companyNameLut?.loc_name[codeLoc] || child?.parent,
                                    groupBy: state.timeScale,
                                    timeSlot: Object.keys(child.clusteredEvents[state.timeScale]) // eg: (ts_num) 614, 615, 617...
                                }

                                let eventsCount = 0
                                Object.values(child.clusteredEvents[state.timeScale]).forEach((ts) => {
                                    eventsCount += ts.event_count
                                })
                                return <ListItem key={index} style={{minWidth: '500px'}}>
                                    <ListItemIcon children={<LocationOn color={'disabled'}/>}/>
                                    <ListItemText primary={state.companyNameLut?.loc_name[codeLoc]}
                                                  secondary={state.companyNameLut?.company_name[codeLoc]}
                                    />
                                    <ListItemSecondaryAction children={
                                            <Chip label={t('entities.eventWithCount', {count: eventsCount})}
                                                  icon={<Tooltip arrow title={_.startCase(t('entities.event_plural'))}>
                                                      <RemoveRedEye/>
                                                  </Tooltip>}
                                                  onClick={() => dispatch(setDetails({type: 'events', events}))}
                                            />
                                    }/>
                                </ListItem>
                            })
                        }
                    </List>
                </DialogContent>}
            </Dialog>

        </div>
    )
}

const MarbleDiagram = ({clusteredData}) => {
    return (
        <MarbleProvider reducer={marbleReducer} initialState={clusteredData} init={marbleInitialize}>
            <MarbleDiagram2 clusteredData={clusteredData}/>
        </MarbleProvider>
    )
}

export default MarbleDiagram
