import React, {useContext} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Summarylabel from "../../components/SummaryLabel";
import Divider from '@material-ui/core/Divider';
import Header from "../../components/Header";
import {makeStyles} from '@material-ui/core/styles';
import SubjectIcon from '@material-ui/icons/Subject';
import {ThemeContext} from "../../contexts";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Avatar from "@material-ui/core/Avatar";
import chroma from 'chroma-js'
import AddBrand from "./AddBrand";
import EditCompanyImage from "./EditCompanyImage";
import BrandDetailComponent from "./BrandDetailComponent";
import Badge from "@material-ui/core/Badge";
import {useTranslation} from "react-i18next";
import _ from 'lodash'

const CompanyDetail = (props) => {
    const {t} = useTranslation()
    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiPaper-rounded': {
                borderRadius: '8px'
            },
            '& .MuiAccordion-root:before': {
                display: 'none'
            }
        },
        largePreview: {
            flex: 1,
            width: theme.spacing(18),
            height: theme.spacing(18),
            '& .MuiAvatar-img': {objectFit: "contain",},
            backgroundColor: chroma(_theme.primaryColor).alpha(0.2).hex(),
            boxShadow: theme.shadows[3]
            //cursor: 'pointer'
        },
        expansion: {
            width: '100%',
            backgroundColor: _theme.backgroundColor,
            marginBottom: '2%'
        },
        summary: {
            marginLeft: '4%',
            marginRight: '4%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
        infoDiv: {
            display: 'flex',
            flexDirection: 'column',
            flex: '2',
            marginRight: '2%',
            marginBottom: '24px'
        },
        labelChip: {
            border: '1px solid ' + _theme.accentColor,
            color: _theme.accentColor,
            fontWeight: 'bold',
        },
        brandBox: {
           // flex: 1
        },
        brandTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        brandListAvatar: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            marginRight: '4%',
            backgroundColor: chroma(_theme.primaryColor).alpha(0.2).hex(),
            '& .MuiAvatar-img': {objectFit: "contain",},
            boxShadow: theme.shadows[1]
        },
    }));
    const classes = useStyles();

    const _src = "/assets/company.png";
    const data = props.myCompany ? props.myCompany : props.location.state;

    /*const [isHover, setIsHover] = useState(false)
    const handleMouseHover = (hover) => {
        setIsHover(hover)
    }*/

    return (
        <Box pt={2} pb={2} className={classes.root}>
            {!props.myCompany ? <Header title=''/> : <Box pt={3}/>}
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                //onMouseEnter={props.myCompany ? () => handleMouseHover(true) : null}
                //onMouseLeave={props.myCompany ? () => handleMouseHover(false) : null}
                >
                <Grid item>
                    {
                        props.myCompany ?
                            <Badge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={<EditCompanyImage company={data} isMainImg={true} data={data} theme={_theme}/>}
                            >
                                <Avatar
                                    alt={"my_company_img"}
                                    src={data.image ? "api/uploads/uploads/" + data.image : _src}
                                    className={classes.largePreview}/>
                                {/*<img alt="my_company_img"
                              src={data.image ? "api/uploads/uploads/" + data.image : _src}
                              className={classes.preview}/>*/}
                            </Badge>
                            : <Avatar
                                alt={"my_company_img"}
                                src={data.image ? "api/uploads/uploads/" + data.image : _src}
                                className={classes.largePreview}/>
                    }

                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" align="center">{data.name.toUpperCase()}</Typography>
                </Grid>
            </Grid>
            <Box pt={3} pb={2} className={classes.summary}>
                <Paper className={classes.infoDiv}>
                    <Box p={2} pl={4} pr={4}>
                        <Summarylabel icon={<SubjectIcon style={{color: _theme.accentColor}}/>} title={t('entities.description', 'Description')}
                                      value={data.description}/>
                        <Divider light variant="middle"/>
                        <Summarylabel icon={<FingerprintIcon style={{color: _theme.accentColor}}/>} title={t('entities.company.domain', 'Domain')}
                                      value={data.domain}/>
                        <Summarylabel icon={<FingerprintIcon style={{color: _theme.accentColor}}/>}
                                      title={t('entities.company.prefix', 'Company Prefix')}
                                      value={data.company_prefix}/>
                        <Divider light variant="middle"/>
                        <Summarylabel //icon={<AccountBalanceIcon style={{color: _theme.accentColor}}/>}
                                      title={t('entities.company.fiscalCode', 'Fiscal Code')}
                                      value={data.fiscal_code}/>
                        <Summarylabel //icon={<AccountBalanceIcon style={{color: _theme.accentColor}}/>}
                                      title={t('entities.company.vatNumber', 'Vat Number')}
                                      value={data.vat_number}/>
                        {/*<Divider light variant="middle"/>
                        <Box pb={2} pt={2}>
                            <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
                                <Grid item md={4} xs={12}>
                                    <Chip className={classes.labelChip}
                                        //icon={<LabelIcon style={{color: _theme.accentColor}}/>}
                                          label={'Supply Chain Label'}
                                          variant={'outlined'}
                                    />
                                </Grid>
                                <Grid item md={8} xs={12}>

                                    {
                                        data.supply_chain_label.map(
                                            (label) => (
                                                <Typography key={label} variant="subtitle2" gutterBottom
                                                            style={{color: 'grey', marginLeft: '2%'}}>
                                                    {label}
                                                </Typography>
                                            )
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Box>*/}
                        {/*<Divider light variant="middle"/>*/}
                    </Box>
                </Paper>
                <div className={classes.brandBox}>
                    <Box className={classes.brandTitle}>
                        <Typography variant="h6" align="center">{_.upperCase(t('entities.company.domain_plural', 'Domains'))}</Typography>
                        {
                            props.myCompany && props.refetchData ?
                                <AddBrand data={props.myCompany} _theme={_theme}/> : null
                        }
                    </Box>
                    <Box>
                        {
                            data.brands.map(
                                (brand, index) => (
                                    <BrandDetailComponent index={index} key={brand.domain} classes={classes} company={data} brand={brand}
                                                          src={_src} myCompany={props.myCompany} refetchData={props.refetchData}/>
                                )
                            )
                        }</Box>
                </div>
            </Box>
            {/*<Grid item xs={12} sm={6}>
                        <Box p={4} style={{paddingBottom: '0px'}}>
                            <Grid item container xs={12} direction="row" justify="center"
                                  alignItems="center">
                                {places.length !== 0 ? <CustomMap places={places}/> : <CustomLoader size={80} />}
                            </Grid>
                        </Box>

                    </Grid>*/}
        </Box>
    )
}

export default CompanyDetail
