import React, {useContext, useEffect, useState} from "react";
import {Box, Divider} from "@material-ui/core";
import {AuthContext, CreateEventContext} from "../../contexts";
import {convertLocationClassToInstance} from "../../utils/CodesUtils";
import {eventFieldNameTypes, inputTypes} from "../../utils/CbvUtils";
import _ from "lodash";
import SourceDestListComponent from "./where/SourceDestListComponent";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import Typography from "@material-ui/core/Typography";
import BizLocReadPointComponent from "./where/BizLocReadPointComponent";
import {useTranslation} from "react-i18next";

const EventWhereComponent = ({whereData}) => {
    const {t} = useTranslation()
    //console.log("EventWhereComponent --> whereData:",whereData)
    const eventContext = useContext(CreateEventContext);
    const {activeMainDomain} = useContext(AuthContext);
    const cbvValues = eventContext.cbvValues

    const [{data: places, loading: loadingPlaces, error: errorPlaces},] = useAxios({
            url: "/api/places/?domain="+activeMainDomain+"&mine=true&not_mine=true",
        },
        {useCache: false}
    )

    const [{data: subplaces, loading: loadingSubplaces, error: errorSublaces},] = useAxios({
            url: "/api/subplaces/?domain="+activeMainDomain+"&mine=true&not_mine=true",
        },
        {useCache: false}
    )

    const [state, setState] = useState({
        places: {bizLocation: [], readPoint: []},
    })

    useEffect(() => {
        if(places && subplaces) {
            let _places = places.data
            let _subplaces = subplaces.data

            setState({...state,
                places: {
                    bizLocation: _.map(_places, (place) => {
                        let sgln = convertLocationClassToInstance(place.gln)
                        return {name: place.name, gln: place.gln, sgln: sgln, address: place.address, data_origin:place.data_origin}
                    }),
                    readPoint: _.map(_subplaces, (subplace) => ({gln: subplace.place_id, sgln: subplace.sgln, name: subplace.name, data_origin:subplace.data_origin})),
            }})
        }
    }, [places, subplaces])

    return (
        <div>
            {
                places && subplaces && cbvValues
                && state.places.readPoint.length && state.places.bizLocation.length ?
                    <div>
                        {_.overSome([['name', eventFieldNameTypes.BIZ_LOCATION.name], ['name', eventFieldNameTypes.READ_POINT.name], ['input', inputTypes.EXTERNAL.value], ['input', inputTypes.PARTIALLY_FIXED.value]]) ?
                            <BizLocReadPointComponent places={state.places}
                                                      whereData={whereData}/> : null}
                        <Divider/>
                        {
                        _.filter(whereData, _.overSome([['input', inputTypes.EXTERNAL.value], ['input', inputTypes.PARTIALLY_FIXED.value]]))
                            .map((field, index) => (
                                <div key={index}>
                                    {
                                        field.type !== 'str' ?
                                            <Box pb={1} pt={1}>
                                                <SourceDestListComponent
                                                    places={state.places}
                                                    field={field}
                                                    state={state} setState={setState}/>
                                                <Divider/>
                                            </Box> : null
                                    }
                                </div>))
                        }
                    </div> : null}
            {loadingPlaces || loadingSubplaces ? <CustomLoader/> : null}
            {errorPlaces || errorSublaces ? <Typography style={{color: 'darkgrey'}}>{t('errors.genericServerError')} {errorPlaces || errorSublaces}</Typography> : null}
        </div>
    )
}

export default EventWhereComponent
