import React, {useContext} from 'react';
import MaterialTable from 'material-table';
import {useWindowSize} from "../../utils/Utils"
import {findLocationCodeTypeValue} from "../../utils/CodesUtils"
import {RoutingContext, ThemeContext} from "../../contexts";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import {langResources} from "../../i18n/i18next";
import {useTranslation} from "react-i18next";
import _ from 'lodash'

const MaterialTableDemo = ({areMyPlaces, places}) =>  {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        typography: {
            display: 'inline-block',
            backgroundColor: '#f6f6f6',
            padding: '2.5%',
            borderRadius: '8px',
            marginRight: '2%',
            marginTop: '2%'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }));
    const classes = useStyles();
    const size = useWindowSize();
    const routingContext = useContext(RoutingContext);

    const CustomCodeField = ({code}) => {
        const [type, locationCode, domain] = findLocationCodeTypeValue(code)
        return(
            <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                <Chip
                    size={'small'}
                    className={classes.codeTypeChip}
                    label={type}
                />
                {
                    domain ?
                        <Chip
                            size={'small'}
                            className={classes.codeTypeChip}
                            label={domain}
                        /> : null
                }
                {locationCode}
            </div>
        )
    }

    let columns = [
        { title: _.startCase(t('entities.name')), field: 'name'},
        { title: _.startCase(t('entities.location.city')), field: 'city'},
        { title: _.startCase(t('entities.location.address')), field: 'address'},
    ]

    if(size.width>300){
        columns = [
            { title: _.startCase(t('entities.name')), field: 'name'},
            { title: _.startCase(t('entities.location.city')), field: 'city'},
            { title: _.startCase(t('entities.location.address')), field: 'address'},
            { title: _.startCase(t('entities.location.code')), field: 'gln', render: rowData => <CustomCodeField code={rowData.gln}/>},
        ]
    }

    const onRowClick = (event, place) => {
        const data = {other: !areMyPlaces, place: place}
        routingContext.setRoute('PlacesDetail', data);
    }

    const tab = (langResources[localStorage.getItem('xtap_lang') || 'en'])?.translation.entities.localizedTable

    return (
        <div className={classes.root} style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                localization={tab}
                data={places.data}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: t('actions.edit', {what: t('entities.location.label')}),
                        onClick: (event, rowData) =>
                            routingContext.setRoute('AddPlace',
                                {edit: true, place:rowData, other: !areMyPlaces})
                    },
                ]}
                onRowClick={onRowClick}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 20],
                    padding: 'dense',
                    actionsColumnIndex: -1,
                }}
            />
        </div>
    );
}

export default MaterialTableDemo
