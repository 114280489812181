import React, {useContext} from 'react';
import MaterialTable from 'material-table';
import {RoutingContext} from "../../contexts";

const MaterialTableDemo = ({devices, limit, setLimit, skip, setSkip}) =>  {

    const myContext = useContext(RoutingContext);

    let columns = [
        { title: 'Device Name', field: 'name'},
        { title: 'Current Configuration', field: 'configuration'}
    ]

    const onRowClick = (event, rowData) => {
        myContext.setRoute('DeviceControlPanel', rowData);
    }

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    return (
        <div style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                data={devices.data}
                onRowClick={onRowClick}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20]
                }}
                totalCount={devices.count}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={skip}
            />
        </div>
    );
}

export default MaterialTableDemo
