import {useContext, useEffect, useMemo, useRef, useState} from "react";
import * as d3 from "d3";
import {sankeyCircular, sankeyLeft} from "d3-sankey-circular"
import chroma from "chroma-js";
import {Fade, Grow, Typography, Zoom} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import _ from 'lodash'
import {findCodeTypeAndValue} from "../../../utils/CodesUtils";
import {ProductHistoryContext} from "../../../contexts";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {bizStep, bizStepIconPathMap} from "../../../components/CustomBizStepIcons";
import i18next from "../../../i18n/i18next";
import NodeDetail from "./NodeDetail";
import {createContextMenu} from "./context_menu_utils";
//import {sankey, sankeyLinkHorizontal} from "d3-sankey"

const SankeyNode = ({node, width, height, color, handleNodeEnter, handleNodeExit, handleNodeClick}) => {
    const [state,] = useContext(ProductHistoryContext)
    const {gtinNameLut} = state
    const midWidth = node.x0 < width / 2
    const maxWidth = node.x0 < width - 210 // per evitare che il menu contestuale esca fuori dal boundary

    const key = _.first(_.split(node.name, ','))
    const [, value, domain] = findCodeTypeAndValue(key)

    // create a tooltip
    var tooltip2 = d3.select("#div_graph")
        .append("div")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background-color", "white")
        .style("border-radius", "6px")
        .style("padding", "10px")
        .style("opacity", ".9")
        .style("box-shadow", `0px 2px 5px 1px gray`)
    //.html(function() {
    //return `<div><p>I'm a tooltip written in HTML</p></div>`});

    const eyePath = "M 18 6.75 C 10.5 6.75 4.095 11.415 1.5 18 c 2.595 6.585 9 11.25 16.5 11.25 s 13.905 -4.665 16.5 -11.25 c -2.595 -6.585 -9 -11.25 -16.5 -11.25 z M 18 25.5 c -4.14 0 -7.5 -3.36 -7.5 -7.5 s 3.36 -7.5 7.5 -7.5 s 7.5 3.36 7.5 7.5 s -3.36 7.5 -7.5 7.5 z m 0 -12 c -2.49 0 -4.5 2.01 -4.5 4.5 s 2.01 4.5 4.5 4.5 s 4.5 -2.01 4.5 -4.5 s -2.01 -4.5 -4.5 -4.5 z"

    const menuItems = [
        {
            title: i18next.t('productHistory.menuLabels.seeDetails', 'Open details'),
            icon: eyePath,
            action: (d) => {
                handleNodeClick(node)
            }
        },
        {
            title: i18next.t('productHistory.menuLabels.copyCode', "Copy class code to clipboard"),
            icon: eyePath,
            action: (d) => {
                navigator.clipboard.writeText(gtinNameLut[key] + ',' + key).then(r => alert(i18next.t('productHistory.menuLabels.copyCodeSuccess', 'Class code copied to clipboard!')))
            }
        },
        {
            title: i18next.t('productHistory.menuLabels.copyAllSerialCodes', "Copy all serial codes to clipboard"),
            icon: eyePath,
            action: (d) => {
                navigator.clipboard.writeText(gtinNameLut[key] + '\n' + _.join(node.relatedSerialCodes, '\n')).then(r => alert(i18next.t('productHistory.menuLabels.copyAllSerialCodesSuccess', 'Codes copied to clipboard!')))
            }
        }
    ];

    return <Grow in={true} appear={true} timeout={1000}>
        <g>
            <rect x={node.x0} y={node.y0}
                  width={node.x1 - node.x0} height={(node.y1 - node.y0)}
                  rx={'4px'}
                  fill={color}
                  strokeWidth={node.selected ? '5px' : '0px'}
                  cursor={'pointer'}
                  onMouseEnter={() => handleNodeEnter(node.name, node.index)}
                  onMouseOver={function () {
                      return tooltip2.style("visibility", "visible")
                          .text(`(${node.relatedSerialCodes.length}) ${gtinNameLut[key]}
                          \n-\n
                          ${domain ? (domain + ' - ') : ''} ${value}`)
                  }
                  }
                  onMouseMove={function (e) {
                      return tooltip2.style("top", (e.pageY - 50) + "px")
                          .style("left", (midWidth ? e.pageX - 50 : e.pageX - 250) + "px");
                  }}
                  onMouseOut={() => {
                      handleNodeExit(node.name, node.index)
                      tooltip2.style("visibility", "hidden")
                  }}
                  onClick={(e, d) => {
                      tooltip2.style("visibility", "hidden")
                      //handleNodeClick(node)
                      createContextMenu(e, d, menuItems, width, maxWidth, height, '#svgGraph');
                  }}
                  onContextMenu={(e, d) => {
                      createContextMenu(e, d, menuItems, width, maxWidth, height, '#svgGraph');
                  }}
            />
            {node.selected ? <rect x={node.x0-6} y={node.y0-6}
                   width={(node.x1 - node.x0)+12} height={(node.y1 - node.y0)+12}
                   rx={'8px'}
                   fill={'none'} stroke={chroma(color).darken(2)}
                   strokeWidth={node.selected ? '3px' : '0px'}/> : null}
            <text x={midWidth ? node.x1 + 10 : node.x0 - 10}
                  y={midWidth ? ((node.y1 + node.y0) / 2) - 16 : ((node.y1 + node.y0) / 2) + 12}
                  textAnchor={midWidth ? "start" : "end"}
                  fontFamily={"sans-serif"} fontSize={13}>
                {gtinNameLut[key]}
            </text>
            {<text x={node.x0 < width / 2 ? node.x1 + 10 : node.x0 - 10}
                   y={midWidth ? ((node.y1 + node.y0) / 2) : ((node.y1 + node.y0) / 2) + 26}
                   textAnchor={node.x0 < width / 2 ? "start" : "end"}
                   fontFamily={"sans-serif"} fontSize={12} width={50}>
                {_.startCase(i18next.t('entities.code')) + ": " + (domain ? (domain + ' - ') : '')} {value}
            </text>}
            <path d={eyePath} fill={chroma(color).darken(2).hex()}
                  transform={`translate(${midWidth ? node.x1 + 10 : node.x1 - 64}, 
                  ${midWidth ? ((node.y1 + node.y0) / 2) + 2 : ((node.y1 + node.y0) / 2) + 26})`}
                  cursor={'pointer'}
                  onClick={() => {
                      tooltip2.style("visibility", "hidden")
                      handleNodeClick(node)
                  }}/>
        </g>
    </Grow>
}

const SankeyLink = ({state, link, color, handleLinkHover}) => {
    const useStyles = makeStyles({
        link: {
            fill: "none",
            strokeOpacity: state.hoveredNode !== null && (link.source.index === state.hoveredNode
                || link.target.index === state.hoveredNode) ? ".5" : ".2",
            stroke: color,
            strokeWidth: link.circular ? Math.max(1, link.width / 3) : Math.max(1, link.width - 30),
            '&:hover': {
                strokeOpacity: ".6",
            },
        }
    })
    const classes = useStyles()
    const x = link.source.x0// ? link.source.x0 : link.target.x0
    const y = link.source.y0 //? link.source.y0 : link.target.y0
    return <Zoom in={true} appear={true} timeout={1000}>
        <g>
            <path
                id={'link'}
                //d={sankeyLinkHorizontal()(link)}
                d={link.path}
                className={classes.link}
                onMouseEnter={() => handleLinkHover(link)}
                //onClick={() => handleLinkClick(link)}
            />
            {link.circular ?
                <path d={bizStepIconPathMap[bizStep.ACCEPTING]} fill={chroma(color).darken(3).hex()}
                      transform={`translate(${x - 15}, ${y + 15})`}/> : null}
        </g>
    </Zoom>
}

const SankeyComponent = ({data, searchSgtin, showLoops}) => {
    //const t0 = performance.now()

    const svgRef = useRef();
    const wrapperRef = useRef();

    const [dimensions, setDimensions] = useState({width: 1300, height: 1300})
    const [innerState, setInnerState] = useState({
        openDialog: false,
        hoveredNode: null,
        selectedLink: null, selectedNode: null
    })

    const [nodes,links] = useMemo(() => {
        const {nodes, links} = sankeyCircular()
            .nodeAlign(sankeyLeft)
            .nodeWidth(20)
            .nodePadding(10)
            .extent([[1, 5], [dimensions.width - 1, dimensions.height - 5]])//(data)
            .iterations(32)
            .circularLinkGap(2)(data)
        return [nodes,links]
    }, [data])

    const [_nodes, _links] = useMemo(() => {
        if(!nodes || !links)
            return [null, null]
        let nodes2 = _.cloneDeep(nodes)
        let _links = links
        if(!showLoops) {
            _links = _.filter(links, function (o) {
                return (o.circular === false && (o.source.partOfCycle === false || o.target.partOfCycle === false))
            })

            const circularLinks = _.filter(links, function (o) {
                return (o.circular === true)
            })

            let indexToRemove = []
            _.each(circularLinks, (circularLink) => {
                //const source = _.findIndex(nodes2, circularLink.source)
                //const target = _.findIndex(nodes2, circularLink.target)
                indexToRemove.push(circularLink.target.index)
                nodes2[circularLink.source.index].relatedSerialCodes = _.uniq(_.concat(nodes2[circularLink.source.index]?.relatedSerialCodes, nodes2[circularLink.target.index]?.relatedSerialCodes))
                nodes2[circularLink.source.index].y1 = nodes2[circularLink.source.index].y1 - (nodes2[circularLink.source.index].y1 - nodes2[circularLink.source.index].y0)/2
            })

            _.each(_.uniq(indexToRemove), (index) => {
                _.remove(nodes2, ['index', index])
            })
        }
        return [nodes2, _links]
    }, [nodes, links, showLoops])

    //let _nodes = nodes//_.filter(nodes, function (node) {return node.sourceLinks.length !== 0 || node.targetLinks.length !== 0})
    const color = chroma.scale("Set2").classes(_nodes.length);
    const colorScale = d3
        .scaleLinear()
        .domain([0, _nodes.length])
        .range([0, 1]);

    //console.log("links:", links)

    /** responsività */
    const measureSVG = () => {
        if (wrapperRef.current) {
            //if(_.every(_nodes),)
            const {width, height} = wrapperRef.current.getBoundingClientRect();
            setDimensions({
                width: width,
                height: height
            });
        }
    };

    useEffect(() => {
        measureSVG()
    }, [])

    /** responsività, resize della finestra */
    useEffect(() => {
        window.addEventListener("resize", measureSVG)
        return () => {
            window.removeEventListener("resize", measureSVG);
        }
    }, [wrapperRef])

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        /** pan&zoom del grafo */
        const zoomBehavior = d3.zoom()
            .scaleExtent([0.1, 2])
            .on("zoom", (event) => {
                const g = svg.select('#graph')
                g.attr("transform", event.transform)
            })

        svg.call(zoomBehavior);
    }, [])

    function handleNodeEnter(name, index) {
        /** per evidenziare tutti i link associati al nodo in hover */
        setInnerState({...innerState, hoveredNode: index})
        //console.log("handleNodeEnter:", name, index)
    }

    function handleNodeExit(name, index) {
        setInnerState({...innerState, hoveredNode: null})
        //console.log("handleNodeExit:", name, index)
    }

    function handleNodeClick(node) {
        /** apre il dialog con le info di dettaglio del nodo */
        //console.log("handleNodeClick:", node)
        setInnerState({...innerState, openDialog: true, selectedNode: node, selectedLink: null})
    }

    function handleLinkHover(data) {
        //console.log("handleLinkHover:", data)
    }

    /*function handleLinkClick(data) {
        console.log("handleLinkClick --> source:", data.source)
        console.log("handleLinkClick --> target:", data.target)
        //setInnerState({...innerState, openDialog:true, selectedLink:data, selectedNode:null})
    }*/

    const dialogRef = useRef(null);
    const handleClose = () => {
        setInnerState({openDialog: false});
    };

    //const t1 = performance.now()
    //console.log("SankeyComponent --->",t1-t0)

    return (
        <Fade in={true} appear={true} timeout={300}>
            <div>
                {_.every(_nodes, function (node) {return node.sourceLinks.length === 0 && node.targetLinks.length === 0})
                &&
                <Typography align={'center'} style={{color:'darkgrey'}}>
                    {i18next.t('productHistory.messages.notEnoughData', 'Not enough data for this representation')}
                </Typography>}
                {_nodes && _links && <div id="div_graph">
                    <div ref={wrapperRef} style={{whiteSpace: 'pre-line'}}>
                        <svg id={'svgGraph'} ref={svgRef} width="100%" height="950">
                            <g id={'graph'} style={{mixBlendMode: "multiply"}}>
                                {_links.map((link, i) => (
                                    <SankeyLink
                                        key={i}
                                        state={innerState}
                                        link={link}
                                        color={color(colorScale(link.source.index)).hex()}
                                        handleLinkHover={handleLinkHover}
                                        //handleLinkClick={handleLinkClick}
                                    />
                                ))}
                                {_nodes.map((node, i) => (
                                        node.sourceLinks.length !== 0 || node.targetLinks.length !== 0 ? <SankeyNode
                                            key={i}
                                            height={dimensions.height}
                                            width={dimensions.width}
                                            node={node}
                                            color={color(colorScale(i)).hex()}
                                            handleNodeEnter={handleNodeEnter}
                                            handleNodeExit={handleNodeExit}
                                            handleNodeClick={handleNodeClick}
                                        /> : null
                                    )
                                )}}
                            </g>
                        </svg>
                    </div>
                    <Dialog fullWidth={true} maxWidth={"md"} open={innerState.openDialog} onClose={handleClose}
                            scroll='paper'>
                        <DialogContent ref={dialogRef} tabIndex={-1}>
                            {innerState.selectedLink ?
                                findCodeTypeAndValue(_.first(_.split(innerState.selectedLink?.source.originId, ',')))[1]
                                + '->' +
                                findCodeTypeAndValue(_.first(_.split(innerState.selectedLink?.target.originId, ',')))[1]
                                : null}
                            {innerState.selectedNode ?
                                <NodeDetail data={innerState.selectedNode} searchSgtin={searchSgtin}/>
                                : null}
                        </DialogContent>
                    </Dialog>
                </div>}
            </div>
        </Fade>
    )
}

export default SankeyComponent
