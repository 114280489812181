import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Button, Box} from '@material-ui/core';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import Header from "../../components/Header";
import ConfInfo from "../../components/new_configuration/ConfInfo";
import SummaryPage from "../../components/new_configuration/SummaryPage";
import useAxios from 'axios-hooks'
import {useHistory} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        '& > *': {
            margin: theme.spacing(1),
        },
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
    grid: {
        marginLeft: '2%',
        paddingTop: '2%',
        width: '96%',
    },
    button: {
        textTransform: "none",
        marginLeft: "1%"
    }
}));

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    version: yup.string().required("Version is required")
        .matches(/^\d+(?:\.\d+){1,2}$/, "Insert a correct version number"),
    configuration: yup.string().required("Configuration cannot be blank")
        .test('checkIfValidJson', 'Configuration must be a valid Json',
            function(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            })
});

const TheForm = ({initValues, onSubmit}) => {

    const classes = useStyles();

    // ripristino i valori salvati nel contenitore
    const {handleSubmit, errors, control} = useForm({
        validationSchema: schema,
        defaultValues: initValues
    });

    return (
        <Box>
            <Box>
                <Paper>
                    <Header title='Edit your Configuration'/>
                    <Grid container spacing={1} className={classes.grid} justify="center">
                        {/* salvo il submit nel contenitore */}
                        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
                            <ConfInfo control={control} errors={errors} edit={true}/>
                            <Box mt={3} pb={2}>
                                <Button type="submit" color="default" variant="contained"
                                        className={classes.button}>Continue</Button>
                            </Box>
                        </form>
                    </Grid>
                </Paper>
            </Box>
        </Box>
    )
}


const TheSummary = ({configurationId, data, onGoBack}) => {

    const [{data: postData, loading: postSaving, error: postError}, postExecute] = useAxios(
        {
            url: "api/configurations/"+configurationId,
            method: "PUT",
            headers: {
                'Media-Type': 'application/json',
            }
        },
        {manual: true}
    )

    let history = useHistory();

    function onCompleteClick() {

        let myData = {
            name: data.name,
            version: data.version,
            configuration: JSON.parse(data.configuration)
        }

        postExecute({data: myData})
            .then( (res) => console.log('res', res))
            .catch( (err) => console.log('err', err) )
    }

    function goBack() {
        history.goBack()
    }

    return (
        <Box>
            {
                postSaving ? <CircularProgress /> :
                    (
                        postData ? goBack :
                            <SummaryPage data={data} onGoBack={onGoBack} onCompleteClick={onCompleteClick} error={postError}/>
                    )
            }
        </Box>
    )
}

const EditConfiguration = (props) => {

    const oldData = props.location.state;

    const configurationId = oldData._id

    try {
        JSON.parse(oldData.configuration);
    } catch (e) {
        oldData.configuration = JSON.stringify(oldData.configuration, undefined, 2);
    }

    const [step, setStep] = useState(0);
    const [data, setData] = useState(oldData);

    function onSubmit(data) {
        setData(data)
        setStep(1);
    }

    return (
        <div>
            {step == 0 ? (
                <TheForm onSubmit={onSubmit} initValues={data}/>
            ) : (
                <TheSummary configurationId={configurationId} data={data} onGoBack={() => setStep(0)}/>
            )
            }
        </div>
    );
}

export default EditConfiguration

