import React, {useContext} from "react";
import {
    AddItemIcon,
    AddPlaceIcon,
    CertificatesIcon,
    CompaniesIcon,
    CreateNewEventTemplateIcon,
    CreateRelationshipIcon,
    DevicesMonitoringIcon,
    EventModelsIcon,
    MyCompanyIcon,
    MyItemsIcon,
    MyPlacesIcon,
    OthersItemsIcon,
    ProductHistoryIcon,
    ProductionFlowIcon,
    SupplyChainGraphFlowIcon,
    FeedChartIcon,
    PoultryChartIcon,
    SlaughtersChartIcon,
} from "../components/CustomIcons";
import {makeStyles} from "@material-ui/core/styles";
import {ThemeContext} from "../contexts";
import _ from "lodash";
import BookIcon from '@material-ui/icons/Book';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import DescriptionIcon from '@material-ui/icons/Description';
import AndroidIcon from '@material-ui/icons/Android';
import DevicesIcon from '@material-ui/icons/Devices';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import i18next from "../i18n/i18next"
import {AccountTree, Assignment, PermContactCalendar} from "@material-ui/icons";

/** INFO (title, path, description) PAGINE di routing -------------------------------------------- */

/** MACRO SEZIONI DEL MENU DRAWER */
export const drawerSections = {
    landingPage: false,
    monitoring: false,
    registry: false,
    partners: false,
    eventModels: false,
    supplyChain: false,
    dataAnalysis: false,
    settings: false
}

/** Setta il titolo corretto alle pagine del routing */
export const setLayoutTitle = (location, authContext, welcomeMessage) => {
    const wData = location.state
    console.log("location:",location)

    const pageObj =  _.find(pathPageObj(), ['path', location.pathname])
    let title = pageObj ? pageObj.title : ''
    let drawerExp = pageObj && pageObj.section ? {...drawerSections, [pageObj.section]: true} : drawerSections

    /** casi specifici in cui il titolo dipende da dati dinamici */
    switch (location.pathname) {
        case '/': {
            //title = 'Landing Page'
            title = welcomeMessage
            return {
                title: title,
                drawerExp: {...drawerSections, landingPage: true}
            }
        }
        case '/EventModelDetail': {
            //console.log("EventModelDetail --> wData.name:",wData.name)
            title = i18next.t('entities.model.templateOf', {what: _.startCase(wData.name)})
            // title = parseName(wData.name) + ': version ' + wData.version // + ' Event\'s Template'
            return {
                title: title,
                drawerExp: drawerExp
            }
        }
        default:
            return {
                title: title,
                drawerExp: drawerExp
            }
    }
}

/**
 * pathPageObj -> restituisce tutte le pagine e relative informazioni (title, path, section?, description)
 *  - utile per recuperare le info di pagine specifiche con lodash
 *      -> pageObj = _.find(pathPageObj(), ['path', path])
 *  */
export const pathPageObj = () => {
    const pages = [
        {
            title: i18next.t('incomingEvents.title'),
            path: "/IncomingEvents",
            section: 'monitoring',
            description: i18next.t('incomingEvents.pageDescription'),
        },
        {
            title: i18next.t('profile.title'),
            path: "/MyProfile",
            section: 'registry',
            description: i18next.t('profile.pageDescription'),
        },
        {
            title: i18next.t('myCompany.title'),
            path: "/MyCompany",
            section: 'registry',
            description: i18next.t('myCompany.pageDescription'),
        },
        {
            title: i18next.t('myItems.title'),
            path: "/Items",
            section: 'registry',
            description: i18next.t('myItems.pageDescription'),
        },
        {
            title: i18next.t('addItem.title'),
            path: "/AddItem",
            description: i18next.t('addItem.pageDescription'),
        },
        {
            title: i18next.t('addPlace.title'),
            path: "/AddPlace",
            description: i18next.t('addPlace.pageDescription'),
        },
        {
            title: i18next.t('myPlaces.title'),
            path: "/MyPlaces",
            section: 'registry',
            description: i18next.t('myPlaces.pageDescription'),
        },
        {
            title: i18next.t('myPartners.title'),
            path: "/MyPartners",
            section: 'registry',
            description: i18next.t('myPartners.pageDescription'),
        },
        {
            title: i18next.t('otherCompanies.title'),
            path: "/Companies",
            section: 'partners',
            description: i18next.t('otherCompanies.pageDescription'),
        },
        {
            title: i18next.t('othersItems.title'),
            path: "/OtherItems",
            section: 'partners',
            description: i18next.t('othersItems.pageDescription'),
        },
        {
            title: i18next.t('othersPlaces.title'),
            path: "/OtherPlaces",
            section: 'partners',
            description: i18next.t('othersPlaces.pageDescription'),
        },
        {
            title: i18next.t('eventModels.title'),
            path: "/EventModels",
            section: 'eventModels',
            description: i18next.t('eventModels.pageDescription'),
        },
        {
            title: i18next.t('relationshipsGraph.title'),
            path: "/RelationshipsGraph",
            section: 'supplyChain',
            description: i18next.t('relationshipsGraph.pageDescription'),
        },
        {
            title: i18next.t('rulesConfigurator.title'),
            path: "/RulesConfiguratorProjects",
            section: 'supplyChain',
            description: i18next.t('rulesConfigurator.pageDescription'),
        },
        {
            title: i18next.t('productHistory.title'),
            path: "/ProductHistory",
            section: 'monitoring',
            description: i18next.t('productHistory.pageDescription'),
        },
        {
            title: i18next.t('reports.title'),
            path: "/Reports",
            section: 'dataAnalysis',
            description: i18next.t('reports.pageDescription'),
        },
        {
            title: i18next.t('chemioDataset.title'),
            path: "/ChemioDataset",
            section: 'dataAnalysis',
            description: i18next.t('chemioDataset.pageDescription'),
        },
        {
            title: i18next.t('howChart.title'),
            path: "/HowChart",
            section: 'dataAnalysis',
            description: i18next.t('howChart.pageDescription'),
        },
        {
            title: i18next.t('feedChart.title'),
            path: "/FeedChart",
            section: 'dataAnalysis',
            description: i18next.t('feedChart.pageDescription'),
        },
        {
            title: i18next.t('poultryChart.title'),
            path: "/PoultryChart",
            section: 'dataAnalysis',
            description: i18next.t('poultryChart.pageDescription'),
        },
        {
            title: i18next.t('slaughtersChart.title'),
            path: "/SlaughtersChart",
            section: 'dataAnalysis',
            description: i18next.t('slaughtersChart.pageDescription'),
        },
        {
            title: i18next.t('createRelationship.title'),
            path: "/CreateRelationship",
            section: 'supplyChain',
            description: i18next.t('createRelationship.pageDescription'),
        },
        {
            title: i18next.t('createEventModel.title'),
            path: "/CreateEventTemplate",
            section: 'eventModels',
            description: i18next.t('createEventModel.pageDescription'),
        },
        {
            title: i18next.t('createEvent.title'),
            path: "/CreateEvent",
            description: i18next.t('createEvent.pageDescription'),
        },
        /*{
            title: i18next.t('kpiPage.title'),
            path: "/KpiAnalytics",
            section: 'monitoring',
            description: i18next.t('kpiPage.pageDescription'),
        },*/
        {
            title: i18next.t('productionFlow.title'),
            path: "/ProductionFlow",
            section: 'supplyChain',
            description: i18next.t('productionFlow.pageDescription'),
        },
        {
            title: "Auth Requests",
            path: "/AuthRequests",
            section: "deviceAdmin",
            description: "See devices authentication requests"
        },
        {
            title: "Devices",
            path: "/Devices",
            section: "deviceAdmin",
            description: "See and create devices"
        },
        {
            title: "Certificates",
            path: "/Certificates",
            section: "deviceAdmin",
            description: "See and revoke devices certificates"
        },
        {
            title: "Revocations",
            path: "/Revocations",
            section: "deviceAdmin",
            description: "See certificates revocations"
        },
        {
            title: "Configurations",
            path: "/Configurations",
            section: "deviceAdmin",
            description: "See and create configurations"
        },
        {
            title: "Apks",
            path: "/Apks",
            section: "deviceAdmin",
            description: "See and upload apks"
        },
        {
            title: "Compose Files",
            path: "/ComposeFiles",
            section: "deviceAdmin",
            description: "See and upload docker compose files"
        },
        {
            title: "Devices Monitoring",
            path: "/DevicesMonitoring",
            section: "deviceAdmin",
            description: "See devices monitoring panels"
        }
    ]

    return _.sortBy(pages, 'title')
}

/** GetIconComponent restituisce l'icona relativa al suo path/pagina */
export const GetIconComponent = ({path}) => {
    const useStyles = makeStyles(() => ({
        image: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '25px',
            height: '25px'
        },
    }))
    const classes = useStyles()
    const {primaryColor} = useContext(ThemeContext)
    //console.log("path:",path)

    switch (path) {
        case '/IncomingEvents': {
            return <SpeakerNotesIcon style={{color: primaryColor}} className={classes.image}/>
        }
        case '/ProductHistory' : {
            return <ProductHistoryIcon style={{color: primaryColor}} className={classes.image}/>
        }
        case '/Reports' : {
            return <Assignment style={{color: primaryColor}} className={classes.image}/>
        }
        /*case '/KpiAnalytics' : {
            return <KpiAnalyticsIcon style={{color: primaryColor}} className={classes.image}/>
        }*/
        case '/ProductionFlow': {
            return <ProductionFlowIcon style={{color: primaryColor}} className={classes.image}/>
        }
        case '/RelationshipsGraph': {
            return <SupplyChainGraphFlowIcon style={{color: primaryColor}} className={classes.image}/>
        }
        case '/RulesConfiguratorProjects': {
            return <AccountTree style={{color: primaryColor}} className={classes.image}/>
        }
        case '/CreateRelationship' : {
            return <CreateRelationshipIcon style={{color: primaryColor}} className={classes.image}/>
        }
        case '/EventModels' : {
            return <EventModelsIcon style={{color: primaryColor}} className={classes.image}/>
        }
        case '/CreateEventTemplate' : {
            return <CreateNewEventTemplateIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/CreateEvent' : {
            return <AddAlertIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/MyProfile': {
            return <AccountCircleIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/MyCompany': {
            return <MyCompanyIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/Items' : {
            return <MyItemsIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/AddItem' : {
            return <AddItemIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/MyPlaces' : {
            return <MyPlacesIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/AddPlace' : {
            return <AddPlaceIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/MyPartners' : {
            return <PermContactCalendar style={{color: primaryColor}} className={classes.image} />
        }
        case '/Companies' : {
            return <CompaniesIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/OtherItems' : {
            return <OthersItemsIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/OtherPlaces' : {
            return <MyPlacesIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/AuthRequests' : {
            return <LockOpenIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/Devices' : {
            return <DevicesIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/Certificates' : {
            return <CertificatesIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/Revocations' : {
            return <HighlightOffIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/Configurations' : {
            return <PhonelinkSetupIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/Apks' : {
            return <AndroidIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/ComposeFiles' : {
            return <DescriptionIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/DevicesMonitoring' : {
            return <DevicesMonitoringIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/FeedChart': {
            return <FeedChartIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/PoultryChart': {
            return <PoultryChartIcon style={{color: primaryColor}} className={classes.image} />
        }
        case '/SlaughtersChart': {
            return <SlaughtersChartIcon style={{color: primaryColor}} className={classes.image} />
        }
        default:
            return <BookIcon style={{color: primaryColor}} className={classes.image} />
    }
}
