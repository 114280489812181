import React, {useContext} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import {RoutingContext} from "../contexts";

const useStyles = makeStyles((theme) => ({
    grid: {
        marginLeft: '2%',
        paddingTop: '2%',
        width:'96%',
    },
}));

/** other indica se il prodotto di cui si sta visualizzando l'header sia my o other */
const Header = ({title, edit, product, other, place, data}) => {
    const classes = useStyles();
    let history = useHistory();
    const myContext = useContext(RoutingContext);
    return(
        <Grid container className={classes.grid} justify="space-between" alignItems="center">
            <Grid item xs={8}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid container item justify="flex-end" xs={3}>
                {
                    edit && product && data  ?
                        <IconButton onClick={ () =>
                            myContext.setRoute('AddItem', {...data, edit: true, other: other})}>
                            <EditIcon />
                        </IconButton>
                        : null
                }
                {
                    edit && place && data  ?
                        <IconButton onClick={ () =>
                            myContext.setRoute('AddPlace', {place: data.place, other: data.other, edit: true})}>
                            <EditIcon />
                        </IconButton>
                        : null
                }
                <IconButton aria-label="close" onClick={ () => history.goBack()}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default Header
