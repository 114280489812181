import React, {useContext, useMemo} from "react";
import _ from "lodash";
import {ProductHistoryContext} from "../../../contexts";
import SankeyComponent from "./SankeyComponent";
import Box from "@material-ui/core/Box";
import {extractProductClassCode} from "../../../utils/CodesUtils";
import SgtinListDownloadComponent from "../SgtinListDownloadComponent";

export const items2map2 = (items, {leftright = true, keepSingles = false, type = null} = {}) => {
    const tree = {};
    /**
     * [type, time , parent, child, eid] -->
     * event_id, event_type, input, output, when
     **/
    items && items.forEach((item) => {
        if (item.input && item.output) {
            const input = leftright ? item.input : item.output
            const output = leftright ? item.output : item.input

            if (input in tree) {
                tree[input].push(output)
            } else
                tree[input] = [output]
        }
    });

    //console.log("items2map2 --> tree:", tree)
    //console.log("items2map2 --> tree LENGHT:", Object.keys(tree).length)
    return tree;
}

export const remappedItems2map2 = (items, {leftright = true, keepSingles = false, type = null} = {}) => {
    const tree = {};

    items && items.forEach((item) => {
        //console.log("item:",item)
        if ((item.input && item.output) || (keepSingles && item.input)) {
            const input = leftright ? item.input : item.output
            const output = leftright ? item.output : item.input

            const [key,] = input.split(",");
            if (key in tree) {
                tree[key].push(output)
            } else
                tree[key] = [output]
        }

    });

    //console.log("items2map2 --> tree:", tree)
    //console.log("items2map2 --> tree LENGHT:", Object.keys(tree).length)
    return tree;
}

const buildSankey = (rootCodes, items, gtinNameLut, historyDirection) => {
    /** const TYPE = 0;const TIME = 1;const PARENT = 2;const CHILD = 3const EID = 4; */

    //const parentTree = items2map2(items, {leftright: true, keepSingles: true});
    const remappedParentTree =  remappedItems2map2(items, {leftright: true, keepSingles: true});
    //const childTree = items2map2(items, {leftright: false, keepSingles: false});
    const remappedChildTree = remappedItems2map2(items, {leftright: false, keepSingles: false});

    //const keyTable = new Map();
    //const addKey = k => {
    //    const [id, eid] = k.split(",");
    //    if(!keyTable.has(id)) keyTable.set(id, new Set());
    //    keyTable.get(id).add(k)
    //}

    const findDescendents = (codes, tree) => {
        const descendents = [];
        const visiting = [...codes];

        const visited = {};
        while (visiting.length > 0) {
            const x = visiting.pop();

            /** remap necessario:
             * ora i codici che ci arrivano dal server non sono solo SGTIN,
             * ma possono essere nella forma "SGTIN,EVENT_ID" in caso di Transformation/Aggregation Event
             * -->  devo controllare quindi che l'sgtin selezionato dall'utente
             *      sia "incluso" nell'id dell'item della history
             * */
            // const remapKeys = _.filter(_.keys(tree), (key) => {
            //     if(_.includes(key, x)) {return key}
            // })

            // if (!(x in visited)) {
            //     visited[x] = 1;
            //     x && descendents.push(x);
            //     _.each(remapKeys, (key) => {
            //         _.each(tree[key], (child) => visiting.push(child));
            //     })
            // }
            if (!(x in visited)) {
                visited[x] = 1;
                x && descendents.push(x);
                _.each(tree[x], child => {
                    const serial = child && child.split(",");
                    serial && visiting.push(serial[0])
                })
                //_.each(remapKeys, (key) => {
                //    _.each(tree[key], (child) => visiting.push(child));
                //})//
            }
        }
        return descendents;
    }
    const forwardCodes = historyDirection === 'all' || historyDirection === 'forward' ? findDescendents(rootCodes, remappedParentTree) : [];
    const backwardCodes = historyDirection === 'all' || historyDirection === 'backward' ? findDescendents(rootCodes, remappedChildTree) : [];

    const codes = _.union(backwardCodes, forwardCodes)

    //let i = 0
    let visited={}
    let _nodes = []
    //_.each(_.keys(parentTree), (code) => {
    _.each(codes, (code) => {
        const keyEventId = _.split(code, ',')
        let name = extractProductClassCode(_.first(keyEventId))

        if(!visited[name])
            visited[name] = [_.first(keyEventId)]
        else visited[name] = [...visited[name], _.first(keyEventId)]

        let nodeToUpdateIndex = _.findIndex(_nodes, ['name', name])
        let nodeToUpdate = {}
        if(nodeToUpdateIndex !== -1) {
            nodeToUpdate = {
                ..._nodes[nodeToUpdateIndex],
                relatedSerialCodes: _.uniq(visited[name]),
                selected: _.some(rootCodes, function (rootCode) {
                    return _.some(visited[name], function (rel) {
                        return _.includes(rel, rootCode)
                    })
                })
            }
            _nodes[nodeToUpdateIndex] = nodeToUpdate
        } else {
            nodeToUpdate = {
                originId: code,
                relatedSerialCodes: _.uniq(visited[name]),
                selected: _.some(rootCodes, function (rootCode) {
                    return _.some(visited[name], function (rel) {
                        return _.includes(rel, rootCode)
                    })
                }),
                //name: code, // campo su cui si costruisce il d3-sankey
                name: name//extractProductClassCode(_.first(keyEventId))+','+keyEventId[1], // campo su cui si costruisce il d3-sankey
            }
            _nodes.push(nodeToUpdate)
        }
    })

    const nodes = _nodes
    const links = []
    _.each(remappedParentTree, (value, key) => {
        _.each(value, ((child) => {
            if(child) {
                const keyEventId = _.split(key, ',')
                const childEventId = _.split(child, ',')
                const source = extractProductClassCode(_.first(keyEventId))//+','+keyEventId[1]
                const target = extractProductClassCode(_.first(childEventId))//+','+childEventId[1]
                const sourceIndex = _.findIndex(nodes, ['name', source])
                const targetIndex = _.findIndex(nodes, ['name', target])

                if(sourceIndex !== -1 && targetIndex !== -1)
                    links.push({
                        source: sourceIndex,
                        target: targetIndex,
                        value: 1
                    })
            }
        }))
    })

    return {nodes: _.uniqWith(nodes, _.isEqual), links: _.uniqWith(links, _.isEqual)}//createSankey(_.union(backwardCodes, forwardCodes));
}

const D3SankeyHistoryGraph = ({selectedSgtins, searchSgtin, showLoops}) => {
    //const t0 = performance.now()

    /** urn:epc:class:lgtin:1639070.094434.1 */
    const [state,] = useContext(ProductHistoryContext)
    const {historyItems, historyDirections, eventsMap, gtinNameLut} = state

    const [sankeyData, data] = useMemo(() => {
        if (!eventsMap) {
            return [null, null]
        } else {
            const _sankeyData = buildSankey(selectedSgtins, historyItems, gtinNameLut, historyDirections)
            const _data = {}
            _.forEach(_sankeyData.nodes, (node) => {
                _data[node.name] = node.relatedSerialCodes
            })
            return [_sankeyData, _data]
        }
    }, [eventsMap, selectedSgtins, historyDirections]);

    //const t1 = performance.now()
    //console.log("D3SankeyHistoryGraph --->",t1-t0)
    return (
        <Box pl={2} pr={2} style={{width: '100%'}}>
            {sankeyData && (<SankeyComponent data={sankeyData} searchSgtin={searchSgtin} showLoops={showLoops}/>)}
            {data && <SgtinListDownloadComponent productCategories={data}/>}
        </Box>
    )
}

export default D3SankeyHistoryGraph;
