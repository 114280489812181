import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {convertToDomain, parseName} from "../../utils/Utils";
import Grid from "@material-ui/core/Grid";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Divider from "@material-ui/core/Divider";
import WhatIcon from '@material-ui/icons/LocalOffer';
import WhereIcon from '@material-ui/icons/Explore';
import {useTranslation} from "react-i18next";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        }
    },
    expandableRuleTable: {
        '& .MuiTable-root': {
            'borderCollapse': 'none'
        }
    },
    activeIcon: {
        verticalAlign: 'bottom',
        color: 'lightgray',
    },
    icon: {
        color: 'darkgray',
        marginEnd: '15px'
    },
    ruleTableCell: {
        display: 'flex'
    }
});

/*function parseProductPrefixes(prefixes) {
    let res = ''
    prefixes.map((pre) => {
        res += pre + "\n"
    })
    return res;
}*/

const MasterDataDetailRow = ({relation, companies, products}) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell align={"right"} component="th" scope="row">{parseName(relation.name)}</TableCell>
                <TableCell component="th" scope="row">{companies[convertToDomain(relation.source)].name}</TableCell>
                <TableCell component="th" scope="row">{companies[convertToDomain(relation.destination)].name}</TableCell>
                <TableCell align={"center"} component="th" scope="row">
                    {relation.active ? <CheckBoxIcon className={classes.activeIcon} fontSize="small"/>
                        : <CheckBoxOutlineBlankIcon className={classes.activeIcon} fontSize="small"/>}
                </TableCell>
                <TableCell component="th" scope="row">{parseName(relation.chain)}</TableCell>
                <TableCell component="th" scope="row">{parseName(relation.type)}</TableCell>
                <TableCell component="th" scope="row">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography align={"center"} component="div"
                                        style={{margin: 10, fontWeight: 'bold'}}>Rules</Typography>
                            <Divider variant={"fullWidth"}/>
                            <Box p={2}>
                                <Table size="small">
                                    <TableBody className={classes.expandableRuleTable}>
                                            {
                                                relation.rules && relation.rules.items && relation.rules.items.length > 0 ?
                                                    relation.rules.items.map((item, index) => {
                                                        return <TableRow key={index}><TableCell>
                                                                <Grid container alignItems={"center"} justify={"center"} key={index}>
                                                                    <Grid item xs={1}>
                                                                        <WhatIcon fontSize={"small"}
                                                                                  className={classes.icon}/>
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        {
                                                                            products.data.filter(product => product.key === item).length > 0 ?
                                                                                products.data.filter(product => product.key === item)[0].name
                                                                                + " ["+item+"]"
                                                                                : item
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell></TableRow>
                                                    }) : <TableRow><TableCell>
                                                        <Grid container alignItems={"center"} justify={"center"}>
                                                            <Grid item xs={1}>
                                                                <WhatIcon fontSize={"small"}
                                                                          className={classes.icon}/>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell></TableRow>
                                            }
                                            {
                                                relation.rules && relation.rules.locations && relation.rules.locations.length > 0 ?
                                                    relation.rules.locations.map((location, index) => {
                                                        return <TableRow key={index}><TableCell>
                                                            <Grid container alignItems={"center"} justify={"center"}>
                                                                <Grid item xs={1}>
                                                                    <WhereIcon fontSize={"small"}
                                                                               className={classes.icon}/>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    {location}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell></TableRow>
                                                    })
                                                    : <TableRow><TableCell>
                                                        <Grid container alignItems={"center"} justify={"center"}>
                                                            <Grid item xs={1}>
                                                                <WhereIcon fontSize={"small"}
                                                                          className={classes.icon}/>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell></TableRow>
                                            }
                                            {/*
                                                !relation.rules ||
                                                (relation.rules.locations && relation.rules.locations.length === 0
                                                    && relation.rules.items && relation.rules.items.length === 0) ?
                                                    <TableRow><TableCell>
                                                        <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                                                    </TableCell></TableRow> : null
                                            */}

                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default MasterDataDetailRow
