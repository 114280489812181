import React, {useState} from 'react'
import TableIncomingEvents from "../../components/tables/TableIncomingEvents";
import ContainerDimensions from "react-container-dimensions";
import useAxios from "axios-hooks";
import HeaderPanelComponent from "./HeaderPanelComponent";
import CustomLoader from "../../components/CustomLoader";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

const IncomingEventsComponent = () => {
    const { t } = useTranslation();

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(30);
    const increment = skip * limit
    const [direction, setDirection] = useState(-1);

    const [{data: events, loadingEvents, errorEvents}] = useAxios({
            url: "api/capture/epcis_events/?show_attatch=true&skip=" + increment
                + "&limit=" + limit
                + "&sort_by=eventTime"
                + "&direction=" + direction,
        },
        {useCache: false}
    )

    //console.log("events:", events)
    return (
        <ContainerDimensions>
            {({width, height}) =>
                <div style={{overflow: 'scroll', height: height}}>
                    <HeaderPanelComponent title={t('landingPage.panelTitles.recentEvents')}/>
                    <div>
                        {events && events.data.length > 0 ?
                            <TableIncomingEvents events={events}
                                                 setDirection={setDirection}
                                                 direction={direction}
                                                 limit={limit}
                                                 setLimit={setLimit}
                                                 skip={skip}
                                                 setSkip={setSkip}
                            />
                            : events && events.data.length === 0 ?
                                <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                                : loadingEvents ?
                                    <CustomLoader size={80} />: null}
                            {errorEvents ? <p>{t('errors.genericServerError')}</p> : null}
                    </div>
                </div>
            }
        </ContainerDimensions>
    )
}

export default IncomingEventsComponent
