import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import MaterialTable, {MTableGroupbar} from 'material-table';
import {Paper, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import EventDetail from "../../components/events/EventDetail";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {ProductHistoryContext, ThemeContext} from "../../contexts";
import {parseName} from "../../utils/Utils";
import {eventTypes, splitCbvValues} from "../../utils/CbvUtils";
import {
    extractLocationClassCode,
    extractProductClassCode,
    findCodeTypeAndValue,
    findLocationCodeTypeValue
} from "../../utils/CodesUtils";
import axios from "axios";
import _ from "lodash";
import Moment from 'moment';
import CustomLoader from "../../components/CustomLoader";
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import {AttachedBizTrans} from "../../components/tables/TableIncomingEvents";
import {useTranslation} from "react-i18next";
import i18next, {langResources} from "../../i18n/i18next";

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    grid: {
        marginLeft: '2%',
        paddingTop: '2%',
        width: '96%',
    },
});

const ProductHistoryTableV6 = () => {
    const { t } = useTranslation();

    const classes = useStyles()

    const [state,] = useContext(ProductHistoryContext)
    const {selectedSgtins, gtinNameLut, locationsLut, historyItems} = state
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(20);

    const [eventsLut, setEventsLut] = useState(null)
    const [finalDataToDisplay, setFinalDataToDisplay] = useState(null)

    const sortedDataToDisplay = useMemo(() => {
        if (!selectedSgtins || !historyItems)
            return null
        return _.sortBy(_.map(historyItems, (item) => ({
            eventID: item.event_id,
            time: item.when,
            gtin: extractProductClassCode(_.first(_.split(item.output ? item.output : item.input, ','))),
            inputGtin: extractProductClassCode(_.first(_.split(item.input, ','))),
            outputGtin: item.output ? extractProductClassCode(_.first(_.split(item.output, ','))) : ''
        })), 'time')
    }, [selectedSgtins, historyItems])

    const eventIDs = useMemo(() => {
        if (!sortedDataToDisplay)
            return null
        let eventIds = ""
        let eventsUniqByID = _.uniqBy(sortedDataToDisplay, 'eventID')
        //console.log("eventsUniqByID:", eventsUniqByID)

        _.slice(eventsUniqByID, limit * skip, limit * (skip + 1)).forEach((d, index) => {
            eventIds += (d.eventID + (index < limit * (skip + 1) - 1 ? "%2C" : ""))
        })

        return eventIds
    }, [sortedDataToDisplay, limit, skip])

    useEffect(() => {
        if (eventIDs) {
            let eventsDetailsUrl = "api/capture/epcis_events/_by_event_ids/"+ eventIDs+"?show_attatch=true"
            axios.get(eventsDetailsUrl).then(events => {
                if (events) {
                    let eventsIdLut = {}
                    events.data.data.forEach((event, index) => {
                        eventsIdLut[event.eventID] = event
                    })
                    setEventsLut(eventsIdLut)

                    let finalData = _.slice(_.uniqBy(sortedDataToDisplay, 'eventID'), limit * skip, limit * (skip + 1)).map((d) => {
                        const locationClassCode = extractLocationClassCode(eventsIdLut[d.eventID].bizLocation)
                        const locationObj = locationsLut[locationClassCode]
                        const [, inputWhatCode, inputWhatDomain] = findCodeTypeAndValue(d.inputGtin)
                        const [, outputWhatCode, outputWhatDomain] = findCodeTypeAndValue(d.outputGtin)
                        return {
                            ...eventsIdLut[d.eventID],
                            sgtin: d.sgtin,
                            gtin: d.gtin,
                            valueToGroupBy: {
                                why: eventsIdLut[d.eventID].eventName ? parseName(`${eventsIdLut[d.eventID].eventName[0].toUpperCase()}${eventsIdLut[d.eventID].eventName.slice(1)}`) : splitCbvValues(eventsIdLut[d.eventID].bizStep),
                                inputWhat: (gtinNameLut[d.inputGtin] ? gtinNameLut[d.inputGtin] : '') + " - " + (inputWhatDomain? (inputWhatDomain +':') :'') + inputWhatCode,
                                outputWhat: (gtinNameLut[d.outputGtin] ? gtinNameLut[d.outputGtin] : '') + " - " + (outputWhatDomain? (outputWhatDomain +':'):'') + outputWhatCode,
                                where: locationObj ? locationObj.name +', ' + locationObj.address + " - " + findLocationCodeTypeValue(locationClassCode)[1] : findLocationCodeTypeValue(locationClassCode)[1],
                                name: gtinNameLut[d.gtin],
                                place: locationObj,
                                when: i18next.t("formatters:dateTime", {date: Moment(eventsIdLut[d.eventID].eventTime)})
                            }
                        }
                    })
                    setFinalDataToDisplay(finalData)
                }
            })
        }
        return () => {
            console.log("unmount Product History View")
        }//TODO: cancel axios
    }, [eventIDs])

    const descriptionElementRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [detailData, setDetailData] = useState([])
    const [groupedBy, setGroupedBy] = useState(
        {
            groupedByEvent: -1,
            groupedByInputWhat: -1,
            groupedByOutputWhat: -1,
            groupedByWhere: -1,
            groupedByWhy: -1,
            groupedByWhen: -1
        })
    const {primaryColor} = useContext(ThemeContext)

    useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open])

    const handleClickOpen = (_, rowData) => {
        setDetailData(rowData)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (nRows) => {
        setLimit(nRows)
        setSkip(0);
    };


    let columns = [
        {
            title: '', field: 'bizStepIcon', align: 'center', sorting: false,
            render: rowData => <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap:'wrap'}}>
                <BizStepIcon bizstep={rowData.bizStep} color={primaryColor}/>
                {rowData.type === eventTypes.TRANSACTION.value && rowData.attatchment_counter > 0 ?
                    <AttachedBizTrans numberOfAttachment={rowData.attatchment_counter} bizTransactionList={rowData.bizTransactionList}/> : null}
            </div>
        },
        {title: 'Input What', field: 'valueToGroupBy.inputWhat', defaultGroupOrder: groupedBy.groupedByInputWhat},
        {title: 'Output What', field: 'valueToGroupBy.outputWhat', defaultGroupOrder: groupedBy.groupedByOutputWhat},
        {title: 'Why', field: 'valueToGroupBy.why', defaultGroupOrder: groupedBy.groupedByWhy},
        {title: 'Where', field: 'valueToGroupBy.where', defaultGroupOrder: groupedBy.groupedByWhere},
        {title: 'When', field: 'valueToGroupBy.when', defaultGroupOrder: groupedBy.groupedByWhen},
    ]

    const setGroups = (data) => {
        let groups = groupedBy
        if (data.length) {
            data.forEach((group) => {
                groups.groupedByEvent = group.title === 'Event Type' ? group.tableData.groupOrder : groups.groupedByEvent
                groups.groupedByInputWhat = group.title === 'Input What' ? group.tableData.groupOrder : groups.groupedByInputWhat
                groups.groupedByOutputWhat = group.title === 'Output What' ? group.tableData.groupOrder : groups.groupedByOutputWhat
                groups.groupedByWhen = group.title === 'When' ? group.tableData.groupOrder : groups.groupedByWhen
                groups.groupedByWhy = group.title === 'Why' ? group.tableData.groupOrder : groups.groupedByWhy
                groups.groupedByWhere = group.title === 'Where' ? group.tableData.groupOrder : groups.groupedByWhere
            })
        } else {
            groups.groupedByEvent = data.title === 'Event Type' ? data.tableData.groupOrder : groups.groupedByEvent
            groups.groupedByInputWhat = data.title === 'Input What' ? data.tableData.groupOrder : groups.groupedByInputWhat
            groups.groupedByOutputWhat = data.title === 'Output What' ? data.tableData.groupOrder : groups.groupedByOutputWhat
            groups.groupedByWhen = data.title === 'When' ? data.tableData.groupOrder : groups.groupedByWhen
            groups.groupedByWhy = data.title === 'Why' ? data.tableData.groupOrder : groups.groupedByWhy
            groups.groupedByWhere = data.title === 'Where' ? data.tableData.groupOrder : groups.groupedByWhere
        }
        setGroupedBy(groups)
    }

    const MyGroupBar = (props) => {

        useEffect(() => {
            setGroups(props.groupColumns)
        }, [props.groupColumns])

        return (
            <MTableGroupbar {...props} style={{backgroundColor: 'red'}}/>
        )
    }

    const tab = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable

    return (
        <Box pb={2}>
            <div style={{maxWidth: '100%'}}>
                {
                    finalDataToDisplay && eventsLut ?
                        <MaterialTable
                            columns={columns}
                            localization={tab}
                            onRowClick={handleClickOpen}
                            onGroupRemoved={(finalDataToDisplay) => setGroups(finalDataToDisplay)}
                            data={finalDataToDisplay}
                            options={{
                                draggable: true,
                                search: false,
                                sorting: false,
                                showTitle: false,
                                toolbar: false,
                                grouping: true,
                                paging: true,
                                pageSize: limit,
                                pageSizeOptions: [20, 40, 80],
                                headerStyle: {
                                    backgroundColor: '#fafafa',
                                    color: primaryColor,
                                    fontWeight: '600',
                                    position: 'sticky', // per sticky header de/commentare 'maxBodyHeight'
                                    top: 0 // per sticky header de/commentare 'maxBodyHeight'
                                },
                                maxBodyHeight: '1200px'
                            }}
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>,
                                Groupbar: props => <MyGroupBar {...props} />,
                            }}
                            totalCount={_.uniqBy(sortedDataToDisplay, 'eventID').length}
                            onChangePage={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            page={skip}
                        /> : <CustomLoader size={80}  text={t('actions.loading')}/>
                }
                {
                    eventsLut ?
                        <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} scroll='paper'>
                            <Grid container className={classes.grid} justify="space-between" alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h6">{_.startCase(t('entities.event'))}</Typography>
                                </Grid>
                                <Grid container item justify="flex-end" xs={3}>
                                    <IconButton aria-label="close" onClick={handleClose}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <DialogContent ref={descriptionElementRef} tabIndex={-1}>
                                {<EventDetail data={detailData}
                                              gtinLut={gtinNameLut}
                                              glnLut={locationsLut}/>}
                            </DialogContent>
                        </Dialog> : null
                }
            </div>
        </Box>
    )
}

export default ProductHistoryTableV6
