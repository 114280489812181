import FormControl from "@material-ui/core/FormControl";
import {Box, FormControlLabel, Switch} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useContext, useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import chroma from "chroma-js";
import _ from "lodash";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import CompaniesSelectComponent from "./CompaniesSelectComponent";
import LocationComponent from "./LocationComponent";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import FieldInfo from "../FieldInfo";
import {useTranslation} from "react-i18next";
import {severityTypes} from "../../../utils/Utils";

const BizLocReadPointComponent = ({places, whereData}) => {
    const {t} = useTranslation()
    const eventContext = useContext(CreateEventContext);
    const {accentColor} = useContext(ThemeContext)

    const initialAlertMessageState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialAlertMessageState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const CustomSwitch = withStyles({
        switchBase: {
            color: accentColor,
            '&$checked': {
                color: accentColor,
            },
            '&$checked + $track': {
                backgroundColor: chroma(accentColor).alpha(0.5).hex(),
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const [state, setState] = useState({
        onlyMine: false,

        brand: null,
        bizLocation: eventContext.getFormField('where', 'bizLocation') ? eventContext.getFormField('where', 'bizLocation').value : null,//_.find(whereData, ['name','bizLocation']).value,
        readPoint: eventContext.getFormField('where', 'readPoint') ? eventContext.getFormField('where', 'readPoint').value : null,//_.find(whereData, ['name','readPoint']).value,
    })

    console.log("state:",state)
    const handleChange = (event) => {
        setState({...state, onlyMine:event.target.checked/*, bizLocation: null, readPoint: null*/});
    };

    return (<Box mb={2} mt={1} display={'flex'} flexDirection={'column'}>
        {state ? <Box mb={1}>
            <FieldInfo name={t('createEvent.whereEventHappened', 'Where the event happened')} description={t('createEvent.whereEventHappenedDescription', 'The business location or the read point in which the event was generated')}/>
            <Box display={'flex'} alignItems={'center'}>
                <CompaniesSelectComponent companies={eventContext.companies}
                                          disabled={!!_.find(whereData, ['name', 'bizLocation']).value}
                                          state={state} setState={setState}/>
                {!_.find(whereData, ['name', 'bizLocation']).value && !_.find(whereData, ['name', 'readPoint']).value ?
                    <FormControl>
                        <FormControlLabel
                            //style={{marginLeft: 'auto'}}
                            control={
                                <CustomSwitch checked={state.onlyMine} onChange={handleChange}/>
                            }
                            label={<Typography variant={"caption"}>{t('createEvent.showOnlyMyLocation', 'Show only my locations')}</Typography>}
                            labelPlacement="top"
                        />
                    </FormControl> : null}
            </Box>
            <LocationComponent places={places.bizLocation}
                               field={_.find(whereData, ['name', 'bizLocation'])}
                               setOpenAlertMessage={setOpenAlertMessage}
                               inset={1}
                               state={state} setState={setState}/>
            <LocationComponent places={places.readPoint}
                               field={_.find(whereData, ['name', 'readPoint'])}
                               setOpenAlertMessage={setOpenAlertMessage}
                               inset={2}
                               state={state} setState={setState}/>
        </Box> : null}
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                  onClose={handleCloseErrorMessage}>
            <Alert onClose={handleCloseErrorMessage}
                   severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
        </Snackbar>
    </Box>)
}

export default BizLocReadPointComponent