import {extractLocationClassCode, findLocationCodeTypeValue} from "../../../utils/CodesUtils";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import StarsIcon from "@material-ui/icons/Stars";
import {inputTypes, sourceDestinationListInputTypes} from "../../../utils/CbvUtils";
import {SubdirectoryArrowRight} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {useTranslation} from "react-i18next";

const SourceDestComponent = ({places, sourceDestInputType, field, state, setState, setSourceDestList}) => {
    const {t} = useTranslation()
    const _theme = useContext(ThemeContext)
    const eventContext = useContext(CreateEventContext)
    const useStyles = makeStyles((theme) => ({
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: _theme.accentColor,
            border: '1px solid ' + _theme.accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
        avatar: {
            marginRight: '2%',
        }
    }));
    const classes = useStyles()

    const fieldName = field.name
    const optional = field.optional
    const validationLength = field.children.length

    const [location, setLocation] = useState({
        brand: null,
        bizLocation: null,
        readPoint: null,
    })

    const optionsCompanies = useMemo(() => {
        if (!eventContext.companies)
            return null

        let _brands = []

        _.forEach(eventContext.companies, (company) => {
            const brand = _.map(company.brands, (brand) => ({...brand, mainCompanyName: company.brands[0].name}))
            _brands = _.union(_brands, brand)
        })

        return _brands
    }, [eventContext.companies])

    const [optionsPlaces, optionsSubplaces] = useMemo(() => {
        if (!places)
            return [null, null]

        let _optionsPlaces = places.bizLocation
        let _optionsSubplaces = sourceDestInputType === sourceDestinationListInputTypes.LOCATION ? places.readPoint : null
        if (location.brand) {
            _optionsPlaces = _.filter(_optionsPlaces, function (o) {
                return o.data_origin === (location.brand.domain)
                    || o.data_origin === (location.brand.other_domain)
            })
            if(sourceDestInputType === sourceDestinationListInputTypes.LOCATION)
                _optionsSubplaces = _.filter(_optionsSubplaces, function (o) {
                    return o.data_origin === (location.brand.domain)
                        || o.data_origin === (location.brand.other_domain)
                })
        }

        if (location.bizLocation && sourceDestInputType === sourceDestinationListInputTypes.LOCATION)
            _optionsSubplaces = _.filter(_optionsSubplaces, function (o) {
                return o.gln === (location.bizLocation.gln || extractLocationClassCode(location.bizLocation))
                    && o.sgln !== (location.bizLocation.sgln || location.bizLocation)
            })

        return [_optionsPlaces, _optionsSubplaces]
    }, [places, location.brand, location.bizLocation])

    const _src = "/assets/company.png"

    const onChangeBrand = (event, newValue) => {
        setLocation({...location, brand: newValue, bizLocation: null, readPoint: null})
    }

    const onChangePlace = (event, newValue) => {
        setLocation({...location, bizLocation: newValue, readPoint: null})

    }

    const onChangeSubplaces = (event, newValue) => {
        setLocation({...location, readPoint: newValue})
    }

    useEffect(() => {
        handleAddSourceDest(field.input !== inputTypes.PARTIALLY_FIXED.value)
    }, [location.bizLocation, location.readPoint])

    function handleAddSourceDest(dryRun) {
        if (location.bizLocation || location.readPoint) {
            let sourceDestValue
            let newSourceDestList = _.cloneDeep(eventContext.getFormField('where', fieldName).value) || []
            sourceDestValue = location.readPoint ? location.readPoint : location.bizLocation
            const index = _.findIndex(newSourceDestList, function (o) {
                return o[1] === sourceDestInputType.value
                //return JSON.stringify(o) === JSON.stringify([sourceDestValue.sgln, sourceDestInputType.value])
            })
            if (index > -1 && field.input === inputTypes.PARTIALLY_FIXED.value)
                newSourceDestList[index] = [sourceDestValue.sgln, sourceDestInputType.value]
            else if(_.findIndex(newSourceDestList, function (o) {
                return JSON.stringify(o) === JSON.stringify([sourceDestValue.sgln, sourceDestInputType.value])
            }) === -1) newSourceDestList.push([sourceDestValue.sgln, sourceDestInputType.value])

            newSourceDestList = _.uniq(newSourceDestList)
            if(!dryRun) {
                eventContext.setFormField('where', fieldName, newSourceDestList)
                if (field.input === inputTypes.PARTIALLY_FIXED.value) {
                    if (!optional && newSourceDestList.length === validationLength)
                        eventContext.setValidationField('where', fieldName, true)
                } else if (field.input === inputTypes.EXTERNAL.value) {
                    if (!optional && newSourceDestList.length !== 0)
                        eventContext.setValidationField('where', fieldName, true)
                }
            }

            if(field.input === inputTypes.EXTERNAL.value) {
                if(!dryRun) {
                    setState({...state, locationLabel: '', sourceDestValue: null, sourceDestInputType: '', anchorEl: null})
                    setSourceDestList(newSourceDestList)
                }
            } else if(field.input === inputTypes.PARTIALLY_FIXED.value) {
                if (location && (location.bizLocation || location.readPoint)) {
                    const _label = ((location.bizLocation ? location.bizLocation.name + " - " : '') + (location.readPoint?.name || '')
                        || location.bizLocation?.name)
                    setState({...state, locationLabel: _label, sourceDestValue: sourceDestValue})
                }
            }
        }
    }

    return (<Box>
            <Box p={2}>
                {
                    optionsCompanies ?
                        <Autocomplete
                            //fullWidth
                            size={'small'}
                            clearOnEscape
                            filterSelectedOptions
                            options={optionsCompanies}
                            value={location.brand}
                            groupBy={(option) => option.mainCompanyName}
                            getOptionLabel={(option) => option.name ? option.name : ''}
                            onChange={onChangeBrand}
                            renderInput={(params) =>
                                <TextField {...params}
                                           size={'small'}
                                           label={t('createEvent.filterByCompany')}/>}
                            renderOption={(option, {selected}) => {
                                return (<React.Fragment>
                                        <Avatar
                                            className={classes.avatar}
                                            //alt="company_logo"
                                            src={option.image ? "api/uploads/uploads/" + option.image : _src}>
                                        </Avatar>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography
                                                    className={classes.name}>{option.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Chip
                                                    size={'small'}
                                                    label={option.domain}
                                                    className={classes.codeTypeChip}
                                                />
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }}
                        /> : null
                }
                <Box mt={2} flex={1} display={'flex'} alignItems={'center'}>
                    <SubdirectoryArrowRight style={{color: _theme.accentColor}}/>
                    {
                        optionsPlaces ?
                            <Autocomplete
                                fullWidth
                                size={'small'}
                                options={optionsPlaces}
                                clearOnEscape
                                value={location.bizLocation}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                onChange={onChangePlace}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               size={'small'}
                                               label={t('createEvent.enterBizLoc', "Enter the Business Location")}
                                               variant="outlined"/>}
                                renderOption={(option, {selected}) => {
                                    const [codeType, codeValue, domain] = findLocationCodeTypeValue(option.sgln)
                                    return (<React.Fragment>
                                            {
                                                option.whitelisted ?
                                                    <StarsIcon style={{
                                                        color: _theme.accentColor,
                                                        marginRight: '3%'
                                                    }}/> : null
                                            }
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        className={classes.name}>{option.name}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        {
                                                            codeType ?
                                                                <Chip
                                                                    size={'small'}
                                                                    label={codeType.type ? codeType.type : codeType}
                                                                    className={classes.codeTypeChip}
                                                                /> : null
                                                        }
                                                        {
                                                            domain ?
                                                                <Chip
                                                                    size={'small'}
                                                                    label={domain}
                                                                    className={classes.codeTypeChip}
                                                                /> : null
                                                        }
                                                        <Typography
                                                            className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }}
                            /> : null
                    }
                </Box>
                {
                    optionsSubplaces ?
                        <Box pl={4} mt={1} flex={1} display={'flex'} alignItems={'center'}>
                            <SubdirectoryArrowRight style={{color: _theme.accentColor}}/>
                            <Autocomplete
                                fullWidth
                                size={'small'}
                                options={optionsSubplaces}
                                clearOnEscape
                                value={location.readPoint}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                onChange={onChangeSubplaces}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               size={'small'}
                                               label={t('createEvent.enterSubplace', "Enter the subplace")}
                                               variant="outlined"/>}
                                renderOption={(option, {selected}) => {
                                    const [codeType, codeValue, domain] = findLocationCodeTypeValue(option.sgln)
                                    return (<React.Fragment>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        className={classes.name}>{option.name}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        {
                                                            codeType ?
                                                                <Chip
                                                                    size={'small'}
                                                                    label={codeType.type ? codeType.type : codeType}
                                                                    className={classes.codeTypeChip}
                                                                /> : null
                                                        }
                                                        {
                                                            domain ?
                                                                <Chip
                                                                    size={'small'}
                                                                    label={domain}
                                                                    className={classes.codeTypeChip}
                                                                /> : null
                                                        }
                                                        <Typography
                                                            className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.address}
                                            style={{backgroundColor: _theme.primaryColorAlpha, opacity: '0.8'}}
                                            {...getTagProps({index})}
                                        />
                                    ))
                                }
                            />
                        </Box> : null
                }
            </Box>
            <Box pb={1} style={{display: 'flex', justifyContent: 'center'}}>
                {
                    field.input === inputTypes.EXTERNAL.value ?
                        <Button aria-label="add"
                            onClick={() => {handleAddSourceDest(false)}}
                                endIcon={<AddCircleIcon style={{color: _theme.accentColor}}/>}>
                            {t('actions.add')}
                        </Button>
                        : null
                }
            </Box>
        </Box>
    )
}

export default SourceDestComponent