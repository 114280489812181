import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import axios from "axios";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const DeviceDeleteDialog = (props) => {

    let history = useHistory()

    const [errorOpen, setErrorOpen] = useState(false);

    const handleErrorClick = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    function deleteDevice() {
        axios.delete("api/devices/"+props.deviceId)
            .then((res) => {
                console.log('res', res)
                history.goBack()
            })
            .catch((err) => {
                console.log('err', err)
                props.onClose()
                handleErrorClick()
            })


    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this device?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        No
                    </Button>
                    <Button onClick={deleteDevice} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Error during operation
                </Alert>
            </Snackbar>
        </div>
    );
}

export default DeviceDeleteDialog