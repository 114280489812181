import React, {Fragment} from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {Grid} from "@material-ui/core";
import useAxios from "axios-hooks";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {parseName} from "../../../utils/Utils";
import {makeStyles} from "@material-ui/core/styles";
import {splitCbvValues} from "../../../utils/CbvUtils";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        height: '100%'
    }
}));

const WhyRuleComponent = ({state, setState, why_types, eventTemplates}) => {
    //const bizSteps = allBizSteps.default
    const classes = useStyles();
    //console.log("Object.keys(bizSteps):", Object.keys(bizSteps))

    const [{data: cbvValues},] = useAxios({
            url: "/api/cbv_values/"
        },
        {useCache: false}
    )

    function handleChangeType(event) {
        setState({...state, why: '', whyType: event.target.value})
    }

    function handleChangeWhy(event, values) {
        setState({...state, why: values})
    }

    return (
        <Grid container>
            <Grid item xs={4}>
                <Box pt={2} pl={2} pr={2}>
                    <FormControl size={'small'} variant="outlined" className={classes.form}>
                        <InputLabel>Why</InputLabel>
                        <Select
                            value={state.whyType}
                            onChange={handleChangeType}
                            label="Why"
                        >
                            {
                                why_types.map(
                                    (type) => (
                                        <MenuItem value={type} key={type}>{type}</MenuItem>)
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box pt={2} pr={2}>
                    {
                        eventTemplates && state.whyType === why_types[0] ?
                            <Autocomplete
                                size={'small'}
                                options={eventTemplates}
                                renderOption={option => <Fragment>{parseName(splitCbvValues(option))}</Fragment>}
                                getOptionLabel={option => state.why !== '' ? parseName(splitCbvValues(option)) : option}
                                onChange={handleChangeWhy}
                                value={state.why}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Why"
                                        variant="outlined"
                                    />
                                )}
                            /> : null
                    }
                    {
                        cbvValues && state.whyType === why_types[1] ?
                            <Autocomplete
                                size={'small'}
                                options={cbvValues.biz_step}
                                renderOption={option => <Fragment>{parseName(splitCbvValues(option))}</Fragment>}
                                getOptionLabel={option => state.why !== '' ? parseName(splitCbvValues(option)) : option}
                                onChange={handleChangeWhy}
                                value={state.why}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Why"
                                        variant="outlined"
                                    />
                                )}
                            /> : null
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default WhyRuleComponent
