import _ from "lodash";
import axios from "axios";
import {extractCompanyPrefix} from "./Utils";
import i18next from "../i18n/i18next";
import data from '../mock_server/prefix_lut.json'

const cdigit = require("cdigit");

/** UTILITY GENERICHE PER CODICI PRODUCT/LOCATION -------------------------------------------------- */
export function buildInternalCode(mine, activeMainDomain, type, internalCode) {
    /** FORMATO per i "miei" codes http://{activeDomain}.xtap.info/codes/{obj|lot|class|loc}/{codice} */
    /** FORMATO per other codes creati da "me" http://x.{activeDomain}.xtap.info/codes/{obj|lot|class|loc}/{codice} */
    //console.log(`http://${mine ? "x." : ""}${activeMainDomain}.xtap.info/codes/${type}/${internalCode}`)
    return `http://${mine ? "x." : ""}${activeMainDomain}.xtap.info/codes/${type}/${internalCode}`;
}

/**
 * getNewPrivateCode -> data l'ultima parte del path la funzione contatta l'api di generazione codici privati
 *  e resistituisce quello richiesto
 *  lastPath per CLASSES = [not_mine]/[prod_class|loc_class]/
 *  lastPath per INSTANCE = [not_mine]/[prod_instance|loc_instance]/[?prod_class=<value>|loc_class=<value>]
 *  es: lastPath: 'product_class/' -> res.code: "http://[main_domain_attivo].xtap.info/codes/class/1" code privato di classe di prodotto
 *      setOpenAlertMessage -> usato nel form generazione eventi (comp CodeBuilder)
 *  */
export const getNewPrivateCode = async (lastPath, setOpenAlertMessage) => {
    const res = await axios.get("/api/codes_gen/private/"+lastPath, {
        headers: {
            'Media-Type': 'application/json'
        }
    }).catch((err) => {
        if (err.response.status === 403)
            setOpenAlertMessage && setOpenAlertMessage({
                open: true,
                message: i18next.t('createEvent.iChainCantGenerateCode', "iChain can't generate instance codes for products you don't own"),
                severity: 'warning'
            });
        else
            setOpenAlertMessage && setOpenAlertMessage({open: true, message: "Server error...", severity: 'error'});

        console.log("ERROR getNewPrivateCode --> err:", err)
        return Promise.reject(err)
    })
    return {
        code: res.data,
    }
}

/** UTILITY PER CODICI PRODOTTO ------------------------------------------------------------------- */
export const availableProductCodeTypes = {
    GTIN12: {type: 'gtin', name: 'gtin12', label: 'GTIN-12', digitCount: 12, prefix: "urn:gs1:gtin12:"},
    GTIN13: {type: 'gtin', name: 'gtin13', label: 'GTIN-13', digitCount: 13, prefix: "urn:gs1:gtin13:"},
    GTIN14: {type: 'gtin', name: 'gtin14', label: 'GTIN-14', digitCount: 14, prefix: "urn:gs1:gtin14:"},
    INTERNAL: {type: 'internal', name: 'internal', label: i18next.t('availableCodeTypes.internalCode', 'Internal Code'), digitCount: -1, prefix: "http://"},
}

/** UTILITY PER CODICI LOCATION ------------------------------------------------------------------- */
export const availableLocationCodeTypes = {
    GLN: {type: 'gln', name: 'gln', label: 'GLN', digitCount: 12, prefix: "urn:gs1:gtin12:"},
    INTERNAL: {type: 'internal', name: 'internal', label: i18next.t('availableCodeTypes.internalCode', 'Internal Code'), digitCount: -1, prefix: "http://"},
}

/** TIPI DI CODICI SERIALI, o codici "singoli" di istanza, o codici di lotti */
export const serialTypes = {
    INSTANCE: {
        type: 'instance',
        label: i18next.t('entities.sgtinType'),
        gs1name: 'sgtin',
        gs1prefix: "urn:epc:id:sgtin:",
        internalName: 'obj'
    },
    LOT: {
        type: 'lot',
        label: i18next.t('entities.sgtinType_plural'),
        gs1name: 'lgtin',
        gs1prefix: "urn:epc:class:lgtin:",
        internalName: 'lot'
    }
}

/** extractGtin estrae il GTIN14 dato un l/sgtin */
export const extractGtin = (sgtin) => {
    let value = sgtin.split(':')
    let value1 = value[4].split('.')
    let value2 = value1[1].charAt(0) + value1[0] + value1[1].substring(1)
    let gtin = value2 + cdigit.gtin.compute(value2)
    //console.log("extractGtin: ", gtin)
    return gtin
}

export const extractProductClassCode = (instanceCode) => {
    const type = findCodeTypeAndValue(instanceCode)[0]

    switch (type) {
        case serialTypes.INSTANCE.gs1name:
        case serialTypes.LOT.gs1name:
            //console.log("extractGtin(instanceCode):",availableProductCodeTypes.GTIN14.prefix+extractGtin(instanceCode))
            return availableProductCodeTypes.GTIN14.prefix+extractGtin(instanceCode)
        case serialTypes.INSTANCE.internalName:
        case serialTypes.LOT.internalName:
            const values = _.split(instanceCode, '.')
            //console.log("extractProductClassCode -> values:",values)
            _.pullAt(values, values.length-1)
            let res = _.join(values, '.')
            res = _.replace(res, type, 'class')
            return res
        default:
            return instanceCode
    }
}

/**
 * buildGS1gtin14 --> per ottenere un gtin14 aggiunge padding '0' in testa alla stringa
 * finchè non si raggiunge la lunghezza di 14
 *  - gtinValue = gtin12 || 13
 * */
export function buildGS1gtin14(gtinValue) {
    let gtin14 = gtinValue
    while(gtin14.length < 14) {
        gtin14 = "0"+gtin14
    }
    //console.log("prefix+gtin14:",availableProductCodeTypes.GTIN14.prefix+gtin14)
    return availableProductCodeTypes.GTIN14.prefix+gtin14
}

export function buildGS1gtin(gtinType, gtinValue) {
    /** controllo se il numero di digit  */
    if (gtinValue.length === gtinType.digitCount) {
        return gtinType.prefix + gtinValue
    } else {
        return ''
    }
}

/** Per mostrare il gtin originale */
export function reverseGS1Gtin(originalCodeType, value) {
    const originalCodeTypeObj = _.find(availableProductCodeTypes, ['name', originalCodeType])
    let originalCodeValue = value.substr(value.length-originalCodeTypeObj.digitCount, value.length)
    return buildGS1gtin(originalCodeTypeObj, originalCodeValue);
}

/**
 * buildSgtin costruisce il codice sgtin/lgtin seriale a partire da:
 *  - il tipo (istanza o lotto) di gtin
 *  - il gtin (ovvero la classe di prodotto)
 *  - il numero seriale (alfanumerico, fino a 20 cifre, lettere solo MAIUSC)
 *  NB: FA USO DI SOLI GTIN14
 *
 - Esempio: parto da GTIN14 -> 01234567890123
 - Mi serve sapere la lunghezza del company prefix -> lo estraggo da companyPrefix
        - Esempio: 7 (da 7 a 9)
 - Scelgo il prefisso -> 'urn:epc:id:sgtin:' per un SGTIN, 'urn:epc:class:lgtin:' per un LGTIN
 - Poi mi concentro sui primi 13 numeri del GTIN (scarto il 14esimo) -> 0123456789012
 - Comincio dalla seconda cifra del GTIN e ne prendo 7 (la lunghezza del company prefix)
 e metto un punto
 - Ottengo -> urn:epc:id:sgtin:1234567.
 - Poi prendo la cifra iniziale del GTIN e le cifre finali che mi rimangono e metto il separatore che precederà il seriale:
 - Ottengo -> urn:epc:id:sgtin:1234567.089012.
 - Il seriale o me lo danno o mi fermo qui.
 - Ottengo urn:epc:id:sgtin:1234567.089012.<seriale>

 Vale lo stesso procedimento per LGTIN
 * */
export function buildSgtin(sgtinType, selectedGtin, companyPrefixLength, serialNumber) {
    let str = selectedGtin.substring(1, selectedGtin.length - 1);
    let str_1 = str.substring(0, companyPrefixLength);
    return `${sgtinType.gs1prefix}${str_1}.${selectedGtin[0]}${str.substring(companyPrefixLength, str.length)}.${serialNumber}`
}

export function buildInternalInstanceCode(serialType, classCode, serialCode) {
    return _.replace(classCode, 'class', serialType.internalName) + '.' + serialCode
}

export async function buildInstanceCode(generateCode, serialType, productClass, companyPrefix, serialNumber, setOpenAlertMessage) {
    const [type, value] = findCodeTypeAndValue(productClass)
    switch (type.type) {
        case availableProductCodeTypes.GTIN12.type:
        case availableProductCodeTypes.GTIN13.type:
        case availableProductCodeTypes.GTIN14.type:
            if (!generateCode) {
                const lutL = getCompanyPrefixLength(productClass)
                const cpLength = extractCompanyPrefix(companyPrefix).length
                if(lutL === cpLength) {
                    return buildSgtin(serialType, value, cpLength, serialNumber)
                }
                else {
                    console.log("ERROR CHECK CP LENGTH")
                    setOpenAlertMessage({
                        open: true,
                        message: i18next.t('createEvent.cpLengthDoesntMatch', "There's an error with the company prefix of the producer company of the item"),
                        severity: 'error'
                    });
                    return null
                }
            }
            else {
                const res = await getNewSgtin(serialType, productClass, setOpenAlertMessage)
                return res.sgtin
            }
        case availableProductCodeTypes.INTERNAL.type:
            if (!generateCode)
                return buildInternalInstanceCode(serialType, productClass, serialNumber)
            else {
                const notMine = _.includes(productClass, "http://x.")
                //console.log("buildInstanceCode ---> productClass.key:",productClass)
                const res = await getNewPrivateCode(`${notMine ? 'not_mine/' : ''}prod_instance/?prod_class=${productClass}&serial_type=${serialType.type}`, setOpenAlertMessage)
                return res.code
            }
        default:
            return undefined
    }
}

/**
 * Usato nel form delle Relationship (CreateRelationship)
 * converte:
 *  gtin in -> [sgtin, lgtin]
 *  internal -> class to obj
 */
export const convertClassToInstancesArray = (name) => {
    const [type, value] = findCodeTypeAndValue(name.key)

    switch (type.type) {
        case availableProductCodeTypes.GTIN12.type:
        case availableProductCodeTypes.GTIN13.type:
        case availableProductCodeTypes.GTIN14.type:
            const lutL = getCompanyPrefixLength(name.key)
            const cpLength = extractCompanyPrefix(name.companyPrefix).length
            if(lutL === cpLength) {
                const inst = buildSgtin(serialTypes.INSTANCE, value, cpLength, '')
                const lot = buildSgtin(serialTypes.LOT, value, cpLength, '')
                //console.log("[inst:",inst,",lot:",lot,']')
                return [inst, lot]
            } else {
                console.log("ERROR CHECK CP LENGTH")
                console.log("convertClassToInstancesArray --> lutL:",lutL)
                console.log("convertClassToInstancesArray --> cpLength:",cpLength)
                return null
            }
        case availableProductCodeTypes.INTERNAL.type:
            return [
                buildInternalInstanceCode(serialTypes.INSTANCE, name.key, ''),
                buildInternalInstanceCode(serialTypes.LOT, name.key, ''),
            ]

        default:
            return undefined
    }
}

/**
 * findCodeTypeAndValue ->
 *  Data una stringa rappresentante un codice gtin di classe di prodotto
 *  es: 'urn:gs1:gtin14:12345678901234', restituisce
 *      - [0] tipo di codice: gtin14|gtin13|gtin12|internal|sscc
 *      - [1] valore del codice: 12345678901..
 *      - [2]? se di tipo internal: il domain
 * Se non è codice gs1 gtin -> restituisce il valore e il tipo "internal/legacy"
 * */
export function findCodeTypeAndValue(key) {
    if (_.startsWith(key, 'urn:gs1:')) {
        /** codice di classe gs1 */
        const values = _.split(key, ':')
        //console.log("findCodeType ---> values:", values)
        const type = _.find(availableProductCodeTypes, ['name', values[2]])
        return [type ? type : values[2], values[3]]
    } else if (_.startsWith(key, serialTypes.INSTANCE.gs1prefix) || _.includes(key, serialTypes.LOT.gs1prefix)) {
        /** codice seriale gs1 (sgtin o lgtin) */
        const values = _.split(key, ':')
        //console.log("findCodeType ---> values:", values)
        return [values[3], values[4]]

    } else if(_.startsWith(key, 'http://')) {
        /** FORMATO per i "miei" internal codes
         * http://{activeDomain}.xtap.info/codes/{obj|lot|class|loc}/{codice} */
        const values = _.split(key, '/')
        //console.log("findCodeType ---> values:", values)
        const serialType = values[values.length-2] || ''
        if (serialType === serialTypes.INSTANCE.internalName || serialType === serialTypes.LOT.internalName) {
            /** codice seriale interno/legacy (obj o lot) */
            //console.log("findCodeType --> values[values.length-2], values[4]:",values[values.length-2], values[4])
            return [values[values.length-2], values[values.length-1]]
        }

        /** codice interno/legacy */
        //console.log("findCodeType ---> values:", values)
        const domain = _.replace(values[2], ".xtap.info", '')
        return [availableProductCodeTypes.INTERNAL, values[values.length-1], domain]
    } else return [availableProductCodeTypes.INTERNAL, key];
}

export const getNewSgtin = async (serialType, gtin, setOpenAlertMessage) => {
    const res = await axios.get("api/codes_gen/item_serials/batch/?item_key_code=" + gtin + "&serial_type="+serialType.type, {
        headers: {
            'Media-Type': 'application/json'
        }
    })
        .catch((err) => {
        if (err.response.status === 403)
            setOpenAlertMessage && setOpenAlertMessage({
                open: true,
                message: i18next.t('createEvent.iChainCantGenerateCode', "iChain can't generate instance codes for products you don't own"),
                severity: 'warning'
            });
        else
            setOpenAlertMessage && setOpenAlertMessage({open: true, message: "Server error...", severity: 'error'});

        console.log("ERROR getNewSgtin --> err:", err)
        return Promise.reject(err)
    })
    return {sgtin: res.data[0]}
}

export const checkPrecedence = async (eventName, whatValue, setOpenAlertMessage) => {
    const res = await axios.get("/api/capture/epcis_events/simplified/check_what_precedence/?event_name=" + eventName + "&what_value=" + whatValue, {
        headers: {
            'Media-Type': 'application/json'
        }
    }).catch((err) => {
        if (err.response.status === 403)
            setOpenAlertMessage && setOpenAlertMessage({
                open: true,
                message: "The code you're trying to insert is already assigned to another item",
                severity: 'warning'
            })
        else
            setOpenAlertMessage && setOpenAlertMessage({open: true, message: "Server error...", severity: 'error'});

        console.log("ERROR CHECKING PRECEDENCE --> err:", err)
        return Promise.reject(err)
    })
    return {
        checkPrecedence: res.data,
    }
}

export function prepareFormattedProductClassCode(editMode, activeMainDomain, keyCode) {
    switch (keyCode.type.type) {
        case 'gtin':
            return {
                type: keyCode.type.name,
                value: buildGS1gtin(keyCode.type, keyCode.value)
            }
        case 'internal':
            return {type: keyCode.type.name, value: buildInternalCode(editMode.otherProduct, activeMainDomain, 'class', keyCode.value)}
        default:
            console.log("Unknown code type")
            break

    }
}

/**
 * createWhiteListedGtins crea una lista di oggetti product con attributi a partire dalla lista dei soli codici
 *  - products: la lista di tutti products
 *  - whitelist: la lista dei codici dei prodotti di cui creare la lista di obj con attributi
 * */
export const createWhiteListedGtins = (products, whitelist) => {
    const data = []
    if(whitelist) {
        whitelist.forEach((gtin) => {
            const objProduct = _.find(products, ['key', gtin])
            data.push(objProduct||gtin)
        })
    }
    return data
}

/** UTILITY PER CODICI LOCATION ------------------------------------------------------------------- */

/** extractGln estrae il GLN dato un SGLN */
export const extractGln = (sgln) => {
    let value = _.split(sgln, ':');
    if(value && value[4]) {
        let valu2 = _.split(value[4], '.')
        return valu2 && valu2.length > 1 ? valu2[0] + valu2[1] + cdigit.gtin.compute(valu2[0] + valu2[1]) : sgln
    } else return sgln+': couldn\'t extract the GLN'
}

export const extractLocationClassCode = (instanceCode) => {
    //console.log("extractLocationClassCode --> instanceCode:",instanceCode)
    const [type, ] = findLocationCodeTypeValue(instanceCode)
    //console.log("[type, value]:",type, value)
    switch (type) {
        case 'sgln':
            //console.log("extractGln(instanceCode):","urn:gs1:gln:"+extractGln(instanceCode))
            return "urn:gs1:gln:"+extractGln(instanceCode)
        case availableProductCodeTypes.INTERNAL.type:
            const values = _.split(instanceCode, '.')
            _.pullAt(values, values.length-1)
            return _.join(values, '.')
        default:
            return 'Unknown Location Code'
    }
}

export function buildGS1gln(glnValue) {
    // TODO: check correttezza formato, da company_prefix
    return "urn:gs1:gln:"+glnValue
}

/**
 * findLocationCodeTypeValue ->
 *  Data una stringa rappresentante un codice location di tipo
 *      - GLN -> 'urn:gs1:gln:<gln_code>'
 *      - SGLN -> 'urn:epc:id:sgln:<dotted_code>'
 *      - HTTP Internal -> 'http://{brandDomain}.xtap.info/codes/loc/{codice}'
 * Resituisce
 *      - [0] tipo di codice: sgln|gln|internal
 *      - [1] valore del codice
 *      - [2]? se di tipo internal -> brand domain
 *
 *  es: 'urn:gs1:gln:1234567890123'
 *      - tipo di codice: gln
 *      - codice: 1234567890123
 * */
export function findLocationCodeTypeValue(code) {
    if (_.includes(code, 'urn:gs1:')) {
        const values = _.split(code, ':')
        //console.log("findLocCodeType ---> values:", values)
        return [values[2], values[3]]
    } else if(_.includes(code, 'http://')) {
        const values = _.split(code, '/')
        //console.log("findLocCodeType ---> values:", values)
        const domain = _.replace(values[2], ".xtap.info", '')
        return [availableProductCodeTypes.INTERNAL.type, values[values.length-1], domain]
    } else if (_.includes(code, 'urn:epc:id:sgln:')) {
        const values = _.split(code, ':')
        //console.log("findLocCodeType ---> values:", values)
        return [values[3], values[4]]
    } else return ['Unknown Code Type', 'Unknown']
}

export const convertGlnToSgln = (gln, company_prefix_length) => {
    let str = gln.substring(0, gln.length - 1);
    let str_1 = str.substring(0, company_prefix_length);
    return "urn:epc:id:sgln:" + str_1 + '.' + str.substring(company_prefix_length, str.length) + '.'
}

export function getCompanyPrefixLength(classCode) {
    const wrongGtins = {"urn:gs1:gtin14:04512347657015":1, "urn:gs1:gtin14:04512347657107":1, "urn:gs1:gtin14:04512347657008":1, "urn:gs1:gtin14:04120219876596":1, "urn:gs1:gtin14:04312347657097":1, "urn:gs1:gtin14:04512347657091":1, "urn:gs1:gtin14:04512347657084":1, "urn:gs1:gtin14:04312347657080":1, "urn:gs1:gtin14:04120219876589":1}
    /** PROVE
        const _classCode = "urn:gs1:gtin14:04512347657107"//7:"urn:gs1:gtin14:08662191953231"//9:"urn:gs1:gtin14:04120219876596"
     */
    //console.log('ricevo classCode --->', classCode)

    let prefix, prefix7, prefix9
    let l
    if(wrongGtins[classCode] || classCode.startsWith('urn:gs1:gln:')) {
        prefix = classCode.replace(/^(urn:gs1:gln:|urn:gs1:gtin1[2-4]:)/, '')
        prefix7 = prefix.substring(0, 7)
        prefix9 = prefix.substring(0, 9)
    } else {
        prefix = classCode.replace(/^(urn:gs1:gln:|urn:gs1:gtin1[2-4]:[0-9])/, '')
        prefix7 = prefix.substring(0, 7)
        prefix9 = prefix.substring(0, 9)
    }

    l = data.GCPPrefixFormatList.iChainPrefixes[prefix7] || data.GCPPrefixFormatList.iChainPrefixes[prefix9]

    if(!l || l===0) {
        // prefix senza urn (e se è gtin 14 senza primo carattere) //TODO: da verificare
        let plen = 3
        while(plen < prefix.length) {
            l = data.GCPPrefixFormatList.entry[prefix.substr(0, plen)]
            if(l !== undefined) return l
            plen++
        }
    }

    return l || 0
}

export const convertLocationClassToInstance = (classCode) => {
    const [type, value] = findLocationCodeTypeValue(classCode)
    //console.log("[type, value]:",type, value)
    switch(type) {
        case 'gln':
            const cpLength = getCompanyPrefixLength(classCode)
            //console.log("cpLength:",cpLength)
            return convertGlnToSgln(value, cpLength)+'0'
        case availableProductCodeTypes.INTERNAL.type:
            return classCode+'.0'
        default:
            return undefined
    }
}

export const getNewSgln = async (gln, companyPrefix) => {
    const res = await axios.get("api/codes_gen/sgln/new/?gln=" + gln + "&company_prefix=" + companyPrefix, {
        headers: {
            'Media-Type': 'application/json'
        }
    })
    return {
        code: res.data,
    }
}

/** UTILITY PER CODICI TRANSAZIONE ------------------------------------------------------------------- */

/**
 * findTransactionCodeTypeValue ->
 *  Data una stringa rappresentante un codice transazione di tipo
 *      - GDTI -> 'urn:epc:id:gdti:<gdti_code>'
 *      - HTTP Internal -> 'http://{brandDomain}.xtap.info/codes/doc/{codice_legacy}'
 *  es: 'urn:gs1:gln:1234567890123'
 *      - [0] tipo di codice: gdti|internal|bt (business transaction)
 *      - [1] codice: 1234567890123
 *      - [2]? se di tipo internal -> brand domain
 * */
export function findTransactionCodeTypeValue(code) {
    if(_.includes(code, 'http://') && _.includes(code, '.xtap.info/codes/doc/')) {
        const values = _.split(code, '/')
        //console.log("findTransactionCodeTypeValue ---> values:", values)
        const domain = _.replace(values[2], ".xtap.info", '')
        return [availableProductCodeTypes.INTERNAL.type, values[values.length-1], domain]
    } else if (_.includes(code, 'urn:epc:id:gdti:')) {
        const values = _.split(code, ':')
        //console.log("findTransactionCodeTypeValue ---> values:", values)
        return [values[3], values[4]]
    } /*else if (_.includes(code, 'urn:epcglobal:cbv:bt:')) {
        const values = _.split(code, ':')
        console.log("findTransactionCodeTypeValue ---> values:", values)
        return [values[3], values[5], values[4]] //[tipo, numero purchase order, GLN]
    }*/
    else return [undefined, 'Unknown Code Type', 'Unknown']
}
