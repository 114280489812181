import React, {useContext} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Summarylabel from "../../components/SummaryLabel";
import Divider from '@material-ui/core/Divider';
import Header from "../../components/Header";
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useAxios from "axios-hooks";
import CustomMap from "../../components/CustomMap";
import {useTranslation} from "react-i18next";
import _ from 'lodash'
import {AuthContext} from "../../contexts";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPaper-rounded': {
            borderRadius: '8px'
        },
    },
    preview: {
        width: "120px",
        height: "120px",
        objectFit: "cover"
    },
    box: {
        backgroundColor: "#f6f6f6",
    },
    typography: {
        display: 'inline-block',
        backgroundColor: '#f6f6f6',
        padding: '2.5%',
        borderRadius: '18px',
        marginRight: '2%',
        marginTop: '2%'
    },
    prefix: {
        backgroundColor: 'darkgrey',
        color: 'whitesmoke',
        padding: '2.5%',
        borderRadius: '18px',
        marginRight: '2%',
        marginTop: '2%'
    },
    button: {
        textTransform: "none",
    },
    buttonComplete: {
        textTransform: "none",
        color: "whitesmoke",
        backgroundColor: "rgba(0, 0, 0, 0.87)",
        marginLeft: "2%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    expansion: {
        backgroundColor: '#f6f6f6'
    }
}));

const PlacesDetail = (props) => {
    const { t } = useTranslation();
    const { activeMainDomain } = useContext(AuthContext)
    const classes = useStyles();
    //const _src = "https://mocdn.gs1.org/sites/default/files/retail.png";

    const {place} = props.location.state
    const [{data: subplaces, loading, error},] = useAxios({
            url: "/api/subplaces/_by_classcode/?domain="+ activeMainDomain +"&classcode=" + place.gln
        },
        {useCache: false}
    )

    //console.log("PLACE DETAILS --> places: ", place)
    //console.log("PLACE DETAILS --> subplaces: ", subplaces)

    const Placeslabel = ({title, value}) => {

        return (
            <Box>
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        {title}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="subtitle2" gutterBottom style={{color: 'grey'}}>
                        {value}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Box className={classes.root}>
            <Paper>
                <Header title='' edit={true} data={props.location.state} place={true}/>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box p={4} pt={1}>
                            <Box pl={2} pr={2}>
                                <Summarylabel title={_.startCase(t('entities.name'))} value={place.name}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.location.code')} value={place.gln}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.location.country')} value={place.country}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.location.city')} value={place.city}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={_.startCase(t('entities.location.address'))} value={place.address}/>
                                <Divider light variant="middle"/>

                            </Box>
                            {subplaces && subplaces.data && subplaces.data.length > 1 ? (
                                    <Box p={2}>
                                        <Box pb={2}>
                                            <Typography>{t('entities.subLocation.label_plural')}</Typography>
                                        </Box>
                                        {
                                            subplaces.data.slice(1).map(
                                                (subplace) => (
                                                    <Accordion key={subplace._id} className={classes.expansion}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon/>}
                                                        >
                                                            <Typography className={classes.heading}>{subplace.name}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Placeslabel title='Sgln' value={subplace.sgln}/>
                                                        </AccordionDetails>
                                                        <AccordionDetails>
                                                            <Placeslabel title={t('entities.description')} value={subplace.small_description}/>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            )
                                        }
                                    </Box>
                                ) : null
                            }
                            {loading ? <p>{t('actions.loading', {what: ''})}</p> : null}
                            {error ? <p>{t('errors.genericServerError')}</p> : null}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box p={4}>
                            <Grid container direction="row" justify="center"
                                  alignItems="center">
                                {/*
                                    <img alt="my_product_img"
                                         src={place.image ? "api/uploads/uploads/" + place.image : _src}
                                         className={classes.preview}/>
                                */}
                                {
                                    place.latitude && place.longitude ? <CustomMap marker={place} /> : <Typography>{t('errors.noPlacesToDisplay')}</Typography>
                                }
                            </Grid>
                            {
                                /*
                                <Grid item xs={12} direction="row" justify="center"
                                  alignItems="center">
                                {
                                    subplaces ? (
                                        <Box p={2}>
                                            <Box pb={2}>
                                                <Typography>Sub Places</Typography>
                                            </Box>
                                            {
                                                subplaces.data.map(
                                                    (subplace) => (
                                                        <Accordion key={subplace._id} className={classes.expansion}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon/>}
                                                            >
                                                                <Typography className={classes.heading}>SGLN
                                                                    - {subplace.sgln}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Placeslabel title='Name' value={subplace.name}/>
                                                            </AccordionDetails>
                                                            <AccordionDetails>
                                                                <Placeslabel title='Description' value={subplace.small_description}/>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                )
                                            }
                                        </Box>
                                    ) : null
                                }
                                {loading ? <p>Loading...</p> : null}
                                {error ? <p>ERROR</p> : null}
                            </Grid>
                                */
                            }
                        </Box>
                    </Grid>
                </Grid>
                {/*<Grid container>
                    <Grid item xs={12} sm={7}>
                        <Box p={4} pt={1}>
                            {
                                subplaces ? (
                                    <Box p={2}>
                                        <Box pb={2}>
                                            <Typography>Sub Places</Typography>
                                        </Box>
                                        {
                                            subplaces.data.map(
                                                (subplace) => (
                                                    <Accordion key={subplace._id} className={classes.expansion}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon/>}
                                                        >
                                                            <Typography className={classes.heading}>SGLN
                                                                - {subplace.sgln}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Placeslabel title='Name' value={subplace.name}/>
                                                        </AccordionDetails>
                                                        <AccordionDetails>
                                                            <Placeslabel title='Description' value={subplace.small_description}/>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            )
                                        }
                                    </Box>
                                ) : null
                            }
                            {loading ? <p>Loading...</p> : null}
                            {error ? <p>ERROR</p> : null}

                        </Box>
                    </Grid>
                </Grid>*/}
            </Paper>
        </Box>
    )
}

export default PlacesDetail
