import Button from "@material-ui/core/Button";
import {
    Dialog,
    DialogActions,
    FormControl,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
    InputLabel,
    FormControlLabel, Switch, LinearProgress
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import React, {useState} from "react";
import useAxios from "axios-hooks";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";

const CreateDeviceDialog = (props) => {

    const useStyles = makeStyles((theme) => ({
        confField: {
            marginTop: 8
        },
        error: {
            color: 'red',
            margin: '0px'
        }
    }));

    const classes = useStyles();

    const [{data: configurations},] = useAxios({
            url: "/api/configurations/?skip=0&limit=100"
        },
        {useCache: false}
    )

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        configuration: yup.string()
    });

    const {handleSubmit, errors, control} = useForm({
        validationSchema: schema
    });

    const [successOpen, setSuccessOpen] = useState(false);

    const [errorOpen, setErrorOpen] = useState(false);

    const handleSuccessClick = () => {
        setSuccessOpen(true);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
    };

    const handleErrorClick = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    const [switchState, setSwitchState] = useState(false)

    const [loadingB, setLoadingB] = useState(0)

    const onSubmit = (data) => {
        setLoadingB(1)
        axios({
            method: 'post',
            url: '/api/devices/',
            data: {
                name: data.name,
                configuration: data.configuration,
                manual_authorization: switchState
            }
        })
            .then((res) => {
                console.log('res', res)
                setSwitchState(false)
                setLoadingB(0)
                props.onClose()
                props.afterDelete()
                handleSuccessClick()
            })
            .catch((err) => {
                setLoadingB(0)
                console.log('err', err)
                handleErrorClick()
            })
    }

    function onClose() {
        props.onClose()
        setSwitchState(false)
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="alert-dialog-title">{"Create New Device"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Specify a name and set a configuration for the device
                    </DialogContentText>
                    <Controller
                        as={({value, onChange}) => {
                            return (
                                <TextField
                                    fullWidth
                                    name="name"
                                    autoFocus
                                    label="Name"
                                    value={value}
                                    onChange={onChange}
                                    margin="dense"
                                />
                            )}}
                        name="name"
                        control={control}
                    />
                    {errors.name &&
                    <p className={classes.error}>{errors.name.message}</p>}

                    <Controller
                        as={({value, onChange}) => {
                            if (configurations)
                                return (
                                    <FormControl fullWidth>
                                    <InputLabel id="conf-label">Configuration</InputLabel>
                                    <Select className={classes.confField}
                                            fullWidth
                                            value={value}
                                            labelId="conf-label"
                                            onChange={onChange}
                                            name="configuration"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            configurations.data.map(
                                                (conf) => (
                                                    <MenuItem value={conf.name}>{conf.name}</MenuItem>
                                                )
                                            )
                                        }
                                    </Select>
                                    </FormControl>
                                )
                            return (
                                <Select disabled className={classes.confField}
                                        fullWidth
                                        value={value}
                                        labelId="conf-label"
                                        onChange={onChange}
                                        name="configuration"/>
                            )}}
                        name="configuration"
                        control={control}
                    />

                    <FormControlLabel style={{marginTop: 20}} control={
                        <Switch
                            checked={switchState}
                            onClick={() => {
                                if (switchState)
                                    setSwitchState(false)
                                else
                                    setSwitchState(true)
                            }}
                            color="primary"
                        />} label="Manual Authorization"
                    />
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Create
                    </Button>
                </DialogActions>
                </DialogContent>
                </form>
                <LinearProgress style={{opacity: loadingB}} color="primary"/>
            </Dialog>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    Operation done successfully
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Error during operation
                </Alert>
            </Snackbar>
        </div>
    );
}

export default CreateDeviceDialog