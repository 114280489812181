import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import axios from "axios";
import React, {useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const AuthRequestDialog = (props) => {

    const [successOpen, setSuccessOpen] = useState(false);

    const [errorOpen, setErrorOpen] = useState(false);

    const handleSuccessClick = () => {
        setSuccessOpen(true);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
    };

    const handleErrorClick = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    function updateAuthRequest() {
        axios({
            method: 'patch',
            url: '/api/auth_requests/'+props.authRequestId,
            data: {
                state: props.operation,
            }
        })
            .then((res) => {
                console.log('res', res)
                props.onClose()
                handleSuccessClick()
                props.updateList()
            })
            .catch((err) => {
                console.log('err', err)
                props.onClose()
                handleErrorClick()
            })
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm operation"}</DialogTitle>
                <DialogContent>
                    {
                        props.operation == 1 && (
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to accept this request?
                            </DialogContentText>
                        )
                    }
                    {
                        props.operation == 2 && (
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to reject this request?
                            </DialogContentText>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        No
                    </Button>
                    <Button onClick={updateAuthRequest} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    Operation done successfully
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Error during operation
                </Alert>
            </Snackbar>
        </div>
    );
}

export default AuthRequestDialog
