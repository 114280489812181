import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Box, Button, Typography} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import Summarylabel from "../../components/SummaryLabel";
import Header from "../../components/Header";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    preview: {
        width: "160px",
        height: "160px",
        objectFit: "cover"
    },
    box: {
        backgroundColor: "#f6f6f6",
    },
    typografy: {
        display: 'inline-block',
        backgroundColor: '#f6f6f6',
        padding: '1.5%',
        borderRadius: '18px',
        marginRight: '2%',
        marginTop: '2%'
    },
    prefix: {
        backgroundColor: 'darkgrey',
        color: 'whitesmoke',
        padding: '2.5%',
        borderRadius: '18px',
        marginRight: '2%',
        marginTop: '2%'
    },
    button: {
        textTransform: "none",
    },
    buttonComplete:{
        textTransform: "none",
        color:"whitesmoke",
        backgroundColor:"rgba(0, 0, 0, 0.87)",
        marginLeft: "2%"
    }
}));

const SummaryPage = ({data, onGoBack, onCompleteClick, error}) => {

    const classes = useStyles();

    return (
        <Box>
            <Paper>
                <Header title='Summary'/>
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <Box p={4} pt={1}>
                            <Box pl={2} pr={2}>
                                <Summarylabel title='Name' value={data.name}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title='Version' value={data.version}/>
                                <Divider light variant="middle"/>
                            </Box>
                            <Box p={2}>
                                <Box pb={2}>
                                    <Typography>Configuration</Typography>
                                </Box>
                                <Box>
                                    <textarea style={{resize: "none"}} value={JSON.stringify(JSON.parse(data.configuration), undefined, 2)} readOnly={true} cols={64} rows={20} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box ml={4} pb={3}>
                    <Button color="default" variant="contained" onClick={onGoBack}
                            className={classes.button}>Back</Button>
                    <Button  variant="contained" className={classes.buttonComplete} onClick={onCompleteClick}>Complete</Button>
                </Box>
            </Paper>
            {
                error ? <p style={{color: 'red'}}>{error.response.data.detail}</p> : null
            }
        </Box>
    )
}

export default SummaryPage
