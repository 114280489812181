import React, {useContext} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Box, Button, Typography} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import Summarylabel from "../../components/SummaryLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Header from "../../components/Header";
import {makeStyles} from '@material-ui/core/styles';
import InfoIcon from "@material-ui/icons/Info";
import Chip from "@material-ui/core/Chip";
import {ThemeContext} from "../../contexts";
import {useTranslation} from "react-i18next";

const SummaryPage = ({data, onGoBack, onCompleteClick, error}) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        preview: {
            //width: "160px",
            height: "170px",
            objectFit: "cover"
        },
        box: {
            backgroundColor: "#f6f6f6",
        },
        typography: {
            display: 'inline-block',
            backgroundColor: '#f6f6f6',
            padding: '1.5%',
            borderRadius: '18px',
            marginRight: '2%',
            marginTop: '2%'
        },
        prefix: {
            backgroundColor: 'darkgrey',
            color: 'whitesmoke',
            padding: '2.5%',
            borderRadius: '18px',
            marginRight: '2%',
            marginTop: '2%'
        },
        infoChip: {
            marginLeft: '1%',
            border: '1px solid '+ accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
        button: {
            textTransform: "none",
        },
        buttonComplete:{
            textTransform: "none",
            color:"whitesmoke",
            backgroundColor:"rgba(0, 0, 0, 0.87)",
            marginLeft: "2%"
        }
    }));
    const classes = useStyles();

    return (
        <Box>
            <Paper>
                <Header title={t('entities.summary')}/>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Box p={4}>
                            <Box pl={1} pr={1}>
                                <Summarylabel title={t('entities.product.name')} value={data.name}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.description')} value={data.description}/>
                                <Divider light variant="middle"/>
                            </Box>
                            <Box p={2}>

                                <FormControlLabel disabled control={<Checkbox defaultChecked={data.active}
                                                                              color='default'/>} label={t('entities.product.activeProduct')}
                                                  labelPlacement="end"/>
                            </Box>
                            <Box className={classes.box} p={2} borderRadius={2}>
                                <Box pl={2} pt={2} style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                                    <Typography>
                                        {!data.codes ? t('entities.autoGenCode') : t('entities.product.code_plural')}
                                    </Typography>
                                    <Chip
                                        //size={'small'}
                                        className={classes.infoChip}
                                        icon={<InfoIcon style={{color: accentColor}}/>}
                                        label={!data.codes ?
                                            <i>{t('addItem.iChainWillGenerateAproductCode')}</i>
                                            : <i>{t('addItem.firstCodeIsGoingoToBeMain')}</i>}
                                    />
                                </Box>
                                {
                                    data.codes !== undefined ? (
                                        data.codes.map(
                                            (code) => (
                                                <Box key={code.type.name}>
                                                    <Summarylabel title={code.type.label} value={code.value}/>
                                                    <Divider variant="inset" light/>
                                                </Box>
                                            )
                                        )
                                    ) : null
                                }
                            </Box>
                            <Box p={2}>
                                <Typography>Tags</Typography>
                                {
                                    data.tags !== undefined ? (
                                        data.tags.map(
                                            (tag) => (
                                                <Typography key={tag}
                                                            className={classes.typography}>{tag}</Typography>
                                            )
                                        )
                                    ) : null
                                }
                            </Box>
                            <Divider light variant="middle"/>
                            <Box p={2}>
                                <Typography>{t('entities.product.customFeatures')}</Typography>
                                {
                                    data.custom_features !== undefined ? (
                                        data.custom_features.map(
                                            (feature) => (
                                                <Box pt={3} key={feature.name}>
                                                    <Grid container direction="row" justify="flex-start" >
                                                        <Grid item xs={9} container
                                                              direction="row"
                                                              justify="flex-start"
                                                              alignItems="center">
                                                            <Box width={1}>
                                                                <Typography>{/*<iclassName={classes.prefix}>{feature.ns}</i>*/}
                                                                    <strong>{feature.name}:</strong> {feature.value}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3} container
                                                              direction="row"
                                                              justify="flex-end"
                                                              alignItems="center">
                                                            { feature.required ?  <Typography>{t('entities.required')}</Typography>
                                                                :  <Typography>{t('entities.notRequired')}</Typography> }
                                                        </Grid>
                                                    </Grid>
                                                    <Box pt={2}>
                                                        <Divider variant="inset" light/>
                                                    </Box>
                                                </Box>
                                            )
                                        )
                                    ) : null
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box p={4}>
                            <Grid item container xs={12} direction="row" justify="center"
                                  alignItems="center">

                                {
                                    data.image ? <img alt="my_product_img" src={"api/uploads/uploads/" + data.image}
                                                 className={classes.preview}/> : null
                                }
                            </Grid>
                            {
                                /*
                                <Grid item container xs={12} direction="row" justify="center"
                                  alignItems="center">
                                 <p>QR CODE</p>
                                 </Grid>
                                */
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box ml={4} pb={3}>
                    <Button color="default" variant="contained" onClick={onGoBack}
                            className={classes.button}>{t('actions.back')}</Button>
                    <Button  variant="contained" className={classes.buttonComplete} onClick={onCompleteClick}>{t('actions.finish')}</Button>
                </Box>
            </Paper>
            {
                error ? <p style={{color:'red'}}>{t('errors.genericServerError')}</p> : null
            }
        </Box>
    )
}

export default SummaryPage
