import React, {useContext, useMemo, useState} from "react";
import EventDetail from "./EventDetail";
import {parseName} from "../../utils/Utils";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "moment";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import useAxios from "axios-hooks";
import _ from "lodash"
import CustomLoader from "../CustomLoader";
import {ThemeContext} from "../../contexts";
import {splitCbvValues} from "../../utils/CbvUtils";
import {useTranslation} from "react-i18next";
import i18next from "../../i18n/i18next";

const GraphEventDetail = ({eventsIds, gtinNameLUT}) => {
    const { t } = useTranslation();

    const { primaryColor } = useContext(ThemeContext)
    const useStyles = makeStyles({
        icon: {
            "& .MuiStepIcon-root.MuiStepIcon-active": {
                color: primaryColor,
            },
            "& .MuiStepIcon-text": {
                display: 'none'
            }
        }
    })
    const classes = useStyles()

    const [activeEvent, setActiveEvent] = useState(0);
    const [show, setShow] = useState(true);
    //const events = data;

    const [{data: eventsDetails},] = useAxios({
            url: "api/capture/epcis_events/_by_event_ids/" + eventsIds
        },
        {useCache: false}
    );

    const events = useMemo(() => {
        if(eventsDetails === null || eventsDetails === undefined || !eventsDetails)
            return
        // console.log("eventsDetails: ", eventsDetails)

        let sortedEvents = _.sortBy(eventsDetails.data, 'eventTime')
        // console.log("sortedEvents: ", sortedEvents)
        return sortedEvents

    }, [eventsDetails])

    function handleStep(index) {
        setShow(false)
        setTimeout(function () {
            setShow(true)
        }, 1000);
        setActiveEvent(index);
    }

    return (
        <div>
            {
                events && events.length > 1 ?
                    <div className={classes.icon}>
                        <Stepper alternativeLabel nonLinear activeStep={activeEvent}>
                            {
                                events.map((event, index) => {
                                    const stepProps = {};
                                    const buttonProps = {};
                                    //const what = gtinNameLUT[extractGtin(event.sgtin)] + " - " + extractGtin(event.sgtin)
                                    //const when = Moment(event.eventTime).format('MMM Do \'YY,\n hh:mm a')
                                    const when = i18next.t("formatters:dateTime", {date: Moment(event.eventTime)})
                                    const why = event.eventName ? parseName(event.eventName) : splitCbvValues(event.bizStep)
                                    //const where = glnPlaceLUT[extractGln(event.bizLocation)][0]

                                    return (
                                        <Step key={event.eventID} {...stepProps}>
                                            <StepButton
                                                onClick={() => {
                                                    handleStep(index)
                                                }}
                                                {...buttonProps}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Grid item>
                                                            <ListItemText primary={when}
                                                                          secondary={why}/>
                                                            {/*<ListItemText secondary={where} />*/}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                        </Stepper>
                    </div> : null
            }
            {
                events ?
                    <div>
                        {!show ? <CustomLoader size={30}/> : null}
                        {<EventDetail data={events[activeEvent]} classes={classes}/>}
                    </div> : <CustomLoader size={80}  text={t('actions.loading')}/>
            }
        </div>
    )
}

export default GraphEventDetail
