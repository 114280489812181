import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import React from "react";

const CustomLoader = ({ text, size }) => {
    return (
        <Box>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    item
                    xs={12}>
                    <CircularProgress size={size} style={{color: 'lightgrey'}}/>
                </Grid>
                {text && <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    item xs={12}>
                    <Box pt={2}>
                        <Typography style={{color: 'lightgrey'}}>{text}</Typography>
                    </Box>
                </Grid>}
            </Grid>
        </Box>
    )
}

export default CustomLoader