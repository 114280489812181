import React, { useContext, useState, useEffect} from "react";
import { Backdrop, Box, Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import useAxios from "axios-hooks";
import { AuthContext, ThemeContext } from "../../contexts";
import i18next from "../../i18n/i18next";
import { Autocomplete } from "@material-ui/lab";
import { Table, TableHead, TableRow, TableBody, TableCell, TextField,
    Chip, Snackbar, Paper, Grid, Typography
} from '@material-ui/core/';
import _ from 'lodash'
import FilterListIcon from '@material-ui/icons/FilterList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import WhenRuleComponent from "../create_new_relationship/rules_components/WhenRuleComponent";
import Moment from "moment";
import CustomLoader from "../../components/CustomLoader";
import ContainerDimensions from "react-container-dimensions";
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBoxPlot } from '@nivo/boxplot';
import { BasicTooltip } from "@nivo/tooltip";

const tabs_conf = [
    {
        index: 0,        
        field: "% Resa V/M Eff",
    },
    {
        index: 1,        
        field: "%Disformita"
    },
    {
        index: 2,        
        field: "%Morti"
    },
    {
        index: 3,        
        field: "%Scarti Cachettici"
    },
    {
        index: 4,
        field: "% SPAGHETTI MEAT"
    },
    {
        index: 5,        
        field: "%OREGON FRESCO",
    },
    {
        index: 6,        
        field: "% EMATOMI COSCIOTTO"
    }
]

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (<div id="graph" style={{ height: "50vh" }}>{children}</div>)}
        </div>

    )
}

const SlaughtersChart = () => {
    
    const { activeMainDomain } = useContext(AuthContext)
    const { accentColor, accentColorAlpha, primaryColor } = useContext(ThemeContext)
    
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
            '& ..MuiAutocomplete-inputRoot': {
                maxHeigth: '30px'
            }
        },
        chip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            fontWeight: 'bold',
            marginLeft: '2%',
            borderRadius: '20px',
        },
        paper: {
            paddingTop: '1%',
            paddingLeft:'2%',
            paddingBottom: '5%',
            paddingRight: '2%',
            fontSize: 'small'
        },
        graph_tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            fontWeight: 'thin',
            borderRadius: '5px',
            border: '1px solid',
            padding: '0.25em',
            minWidth: '310px'
            
        },
        graph_tooltip_inner: {
            marginLeft: '1em',
            marginRight: '1em'
        },
    }));

    const classes = useStyles()

    const { t } = useTranslation();    
    const responseType = "blob"
    const mimeType = "application/json"
    
    const [{ data: pezzatura, loadingPezzatura, errorReports },] = useAxios({
        url: `/api/dataservices/records/_keys/?key_type=group_key&domain=${activeMainDomain}`,
        useCache: false
    },
    )

    const [filter, setFilter] = useState({
        pezz: { _id: "group_key:POLLO GROSSO" },
        selectedDates: [{
            startDate: Moment().subtract(1, 'months').toDate(),
            endDate: Moment().toDate(),
            color: 'darkgrey',
            key: 'selection'
        }
        ],
        selected_tab_index: 0,
        selected_allevamenti: []
    })
    
    const [dataMap, setDataMap] = useState(new Map())
    const [graphData, setGraphData] = useState([])
    const [allevamenti, setAllevamenti] = useState([])
    const [measureStats, setMeasureStats] = useState({})
    const [{lineData, allevamento, detailData}, setLineData] = useState({lineData:[], allevamento:'', detailData:[]})
    const [{copyDone, copyMessage}, setCopy] = useState({copyDone: false, copyMessage: ''})

    const [{ data, loading, error, response }, reloadData] = useAxios({
        url: `/api/dataservices/chemio_reports_data/` ,
        params: {
            pezzatura: filter.pezz._id.replace("group_key:", ""),
            type_of_report: 'Macellazioni',
            start: Moment(filter.selectedDates[0].startDate).format('YYYY-MM-DD HH:mm:ss'),
            end: Moment(filter.selectedDates[0].endDate).format('YYYY-MM-DD HH:mm:ss'),
            domain: activeMainDomain
        }
    })

    const changeTab = (event, newValue) => {
        setFilter((prev) => ({ ...prev, selected_tab_index: newValue }))
    }

    useEffect(()=>{
        if (data?.records)
        {
            let dataMap_tmp = new Map()
            data.records.forEach((record) => {
                if ((!dataMap_tmp.get(record['Allevamento']))) //&& dataMap.size < maxAllev) 
                {
                    dataMap_tmp.set(record['Allevamento'], { records: [] })
                }
                dataMap_tmp.get(record['Allevamento']).records.push(record)
            })
            
            const all_tmp = dataMap_tmp.keys().toArray()
            setDataMap(dataMap_tmp)
            setFilter(prev => ({ ...prev, selected_allevamenti: all_tmp.slice(0) }))
            setAllevamenti(all_tmp)
        }
    }, [data])

    useEffect(() => { reloadData() }, [filter.pezz, filter.selectedDates])   
    
    useEffect(() => {
        const new_graph_data = [];
        const measure_stats = new Map();
        dataMap.keys().forEach(allev => {
            if (filter.selected_allevamenti.find( v => v === allev)) {
                dataMap.get(allev).records.forEach((r, i) => {
                    let xy = {
                        "group": allev,
                        "subgroup": tabs_conf[filter.selected_tab_index].label,
                        "value":  r[tabs_conf[filter.selected_tab_index].field],
                    }
                    new_graph_data.push(xy);

                    tabs_conf.forEach(measure => {
                        if (!measure_stats.has(measure.index)){
                            measure_stats.set(measure.index, {max: {y: Number.MIN_SAFE_INTEGER}, min: {y: Number.MAX_SAFE_INTEGER}, mean:0, std_dvt:0, values: []})
                        }
                        const measure_value = parseFloat(r[measure.field])
                        if (measure_value > measure_stats.get(measure.index).max.y)
                        {
                            measure_stats.get(measure.index).max = {
                                y: measure_value,
                                ciclo: r["Chiave Ciclo"],
                                allevamento: r["Allevamento"],
                                data_macellazione: r["Data Mac D"]
                            }
                        }
                        if (measure_value  < measure_stats.get(measure.index).min.y) 
                        {
                            measure_stats.get(measure.index).min = {
                                y: measure_value,
                                ciclo: r["Chiave Ciclo"],
                                allevamento: r["Allevamento"],
                                data_macellazione: r["Data Mac D"]
                            }
                        }
                        measure_stats.get(measure.index).mean = measure_stats.get(measure.index).mean + measure_value
                        measure_stats.get(measure.index).values.push(measure_value)
                    })
                })
            }
        })
        measure_stats.forEach(measure_stat=>{
            measure_stat.mean = measure_stat.mean / measure_stat.values.length
            measure_stat.values.forEach(measure_value=>{
                measure_stat.std_dvt = measure_stat.std_dvt + Math.pow(measure_value - measure_stat.mean, 2)
            })
            measure_stat.std_dvt = Math.sqrt(measure_stat.std_dvt / measure_stat.values.length)
        })
        setMeasureStats(measure_stats)
        setGraphData(new_graph_data)
        setLineData({lineData:[], allevamento:'', detailData:[]})
    }, [dataMap, filter.selected_allevamenti, filter.selected_tab_index])

    
    function SummaryPanel({summaryData}) {
        return (
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <Typography variant={'h6'}>{t('slaughtersChart.summary.max.title','Maximum')}</Typography>
                        <div style={{paddingTop: '1em'}}>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.max.value', 'Value')}</span>:&nbsp; {summaryData?.max.y !== Number.MIN_SAFE_INTEGER ? summaryData?.max.y : '--' }<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.max.cycle', 'Breeding Cycle')}</span>:&nbsp; {summaryData?.max.ciclo}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.max.farm', 'Farm')}</span>:&nbsp; {summaryData?.max.allevamento}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.max.slaughterDate', 'Slaughter Date')}</span>:&nbsp; {i18next.t("formatters:date", {date: Moment(summaryData?.max.data_macellazione)})}</Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}> 
                        <Typography variant={'h6'}>{t('slaughtersChart.summary.min.title','Minimum')}</Typography>
                        <div style={{paddingTop: '1em'}}>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.min.value', 'Value')}</span>:&nbsp; {summaryData?.min.y !== Number.MAX_SAFE_INTEGER? summaryData?.min.y:'--'}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.min.cycle', 'Breeding Cycle')}</span>:&nbsp; {summaryData?.min.ciclo}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.min.farm', 'Farm')}</span>:&nbsp; {summaryData?.min.allevamento}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.min.slaughterDate', 'Slaughter Date')}</span>:&nbsp; {i18next.t("formatters:date", {date: Moment(summaryData?.min.data_macellazione)})}</Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <Typography variant={'h6'}>Media</Typography>
                        <div style={{paddingTop: '1em'}}>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.mean.mean', 'Mean Value')}</span>:&nbsp; {(Math.round(summaryData?.mean * 100) / 100).toFixed(2)}<br/></Typography>
                            <Typography><span style={{fontStyle: 'italic'}}>{t('slaughtersChart.summary.mean.std_dvt', 'Standard Deviation')}</span>:&nbsp; {(Math.round(summaryData?.std_dvt *100) / 100).toFixed(2)}<br/></Typography>
                            <Typography><br/></Typography>
                            <Typography>&nbsp;</Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>


        )
    }

    const showTooltip = (event) => {        
        return (
            <Grid style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', fontWeight: 'thin', borderRadius: '5px', border: '1px solid', padding: '0.25em', minWidth: '310px'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{marginLeft: '1em', marginRight: '1em'}}>
                        <div>{t('slaughtersChart.boxPlot.tooltip.title', 'Farm')}: <strong>{event.group}</strong></div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>    
                    <Grid item xs style={{marginLeft: '1em', marginRight: '1em'}}>
                        <div><strong>{t('slaughtersChart.boxPlot.tooltip.summary', 'Summary')}</strong></div>                        
                        <div>{t('slaughtersChart.boxPlot.tooltip.nOfSlaughters','N. of slaughters')}: <strong>{event.formatted.n}</strong></div>
                        <div>{t('slaughtersChart.boxPlot.tooltip.max', 'Max')}: <strong>{event.formatted.extrema[1]}</strong></div>
                        <div>{t('slaughtersChart.boxPlot.tooltip.min', 'Min')}: <strong>{event.formatted.extrema[0]}</strong></div>
                        <div>{t('slaughtersChart.boxPlot.tooltip.mean', 'Mean')}: <strong>{event.formatted.mean}</strong></div>
                    </Grid>
                    <Grid item xs style={{marginLeft: '1em', marginRight: '1em'}}>
                        <div><strong>{t('slaughtersChart.boxPlot.tooltip.quantiles', 'Quantiles')}</strong></div> 
                        <div>{event.formatted.quantiles[0]}%: <strong>{event.formatted.values[0]}</strong></div>
                        <div>{event.formatted.quantiles[1]}%: <strong>{event.formatted.values[1]}</strong></div>
                        <div>{event.formatted.quantiles[2]}%: <strong>{event.formatted.values[2]}</strong></div>
                        <div>{event.formatted.quantiles[3]}%: <strong>{event.formatted.values[3]}</strong></div>
                        <div>{event.formatted.quantiles[4]}%: <strong>{event.formatted.values[4]}</strong></div>
                    </Grid>          
                </Grid>
            </Grid>
        )
    }

    function handleBoxClick(event){
        const lineData_tmp = [{id: event.group, data:[], color: accentColor }]
        const detailTempData = []
        let allev_data = dataMap.get(event.group).records.slice(0)
        allev_data.sort((a,b)=> {return Moment(a["Data Mac D"]).toDate() > Moment(b["Data Mac D"]).toDate()? 1:-1})
        allev_data.forEach(r =>{
            lineData_tmp[0].data.push({
                x: Moment(r["Data Mac D"]).format("YYYY-MM-DD"),
                y: r[tabs_conf[filter.selected_tab_index].field],
                ciclo: r["Chiave Ciclo"],                
            })
            const detailData = {Ciclo: r["Chiave Ciclo"], 'Data Macellazione': r["Data Mac D"], 'epc': r['what_ids'][0]}
            tabs_conf.forEach(measure => detailData[t('slaughtersChart.measures.'+measure.field)]=r[measure.field])
            detailTempData.push(detailData)

        })
        setLineData({lineData: lineData_tmp, allevamento: event.group, detailData: detailTempData})
    }

    function showMacTooltip(event){
        return (
            <div className={classes.graph_tooltip}>
                <Grid container spacing={1}>
                    <Grid item xs className={classes.graph_tooltip_inner}>
                        <div>{t('slaughtersChart.linePlot.tooltip.cycle', 'Breeding cycle')}: <span style={{fontWeight: 'bold'}}>{event.point.data.ciclo}</span></div>
                        <div>{t('slaughtersChart.linePlot.tooltip.slaughterDate', 'Slaughter date')}: <span style={{fontWeight: 'bold'}}>{i18next.t("formatters:date",{date:Moment(event.point.data.x)})}</span></div>
                        <div>{t('slaughtersChart.measures.'+tabs_conf[filter.selected_tab_index].field)}: <span style={{fontWeight: 'bold'}}>{event.point.data.yFormatted}</span></div>
                    </Grid>
            </Grid>
            </div>
        )
    }

    function copyToClipboard(epc){
        navigator.clipboard.writeText(epc).then(()=>{setCopy({copyDone:true, copyMessage: t('slaughtersChart.messages.copied',{epc: epc})})})
    }

    return (
        <div className={classes.root} id="slaughters_charts_page" style={{ height: "100%" }}>
            <ContainerDimensions>
                {({ width, height }) =>
                    <div style={{ height: height * 0.9, width: width}}>
                        <Box mb={'5em'} display={'flex'} alignItems={'end'}>
                            <FilterListIcon style={{ color: accentColor, marginRight: 16 }} />
                            <Autocomplete
                                style={{ width: '30%', marginRight: 16 }}
                                limitTags={3}
                                id="pezzaturaAC"
                                options={_.uniqBy(pezzatura, '_id')}
                                getOptionLabel={(option) => option._id.replace('group_key:', '')}
                                getOptionSelected={(option, value) => option._id === value._id}
                                value={filter.pezz}
                                //defaultValue={{"name": "Pollo Piccolo", "id":"pollo_piccolo"}}
                                onChange={(event, newSelection) => {
                                    setFilter(prev => ({ ...prev, pezz: newSelection }));

                                }}
                                renderInput={params => {
                                    const { InputProps, ...restParams } = params;
                                    const { startAdornment, ...restInputProps } = InputProps;
                                    return (
                                        <TextField
                                            {...restParams}                                            
                                            size={'small'}
                                            variant={"standard"}
                                            placeholder={t('actions.filterBy', { by: 'pezzatura' })}
                                            //defaultValue="Pollo Piccolo"
                                            InputProps={{
                                                ...restInputProps,
                                                startAdornment: (
                                                    <div style={{
                                                        maxHeight: '100px',
                                                        overflowY: 'auto',
                                                    }}
                                                    >
                                                        {startAdornment}
                                                    </div>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip label={option.name} size='small' {...getTagProps({ index })} />
                                    ))
                                }
                            />
                            <WhenRuleComponent state={filter} setState={setFilter} width={'30%'} outlined={false} />
                            <Autocomplete
                                style={{ width: '30%', marginRight: 16, marginLeft: 16, maxHeight:'30px' }}
                                multiple={true}
                                limitTags={3}
                                id="allevamentiAC"
                                options={allevamenti}
                                filterSelectedOptions={false}
                                disableCloseOnSelect
                                //getOptionLabel={(option) => option.name}
                                //getOptionSelected={(option, value) => value.id == option.id }
                                value={filter.selected_allevamenti}
                                onChange={(event, newSelection) => {
                                    setFilter(prev => ({ ...prev, selected_allevamenti: newSelection }));

                                }}
                                renderInput={params => {
                                    const { InputProps, ...restParams } = params;
                                    const { startAdornment, ...restInputProps } = InputProps;
                                    return (
                                        <TextField
                                            {...restParams}
                                            size={'small'}
                                            variant={"standard"}
                                            placeholder={t('actions.filterBy', { by: 'Allevamento' })}
                                            InputProps={{
                                                ...restInputProps,
                                                startAdornment: (
                                                    <div style={{
                                                        maxHeight: '100px',
                                                        overflowY: 'auto',
                                                    }}
                                                    >
                                                        {startAdornment}
                                                    </div>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip label={option} size='small' {...getTagProps({ index })} />
                                    ))
                                }
                            />
                        </Box>                        
                        <Tabs value={filter.selected_tab_index} onChange={changeTab}>
                            {tabs_conf.map((tabConf) => {
                                return <Tab key={tabConf.index} label={t('slaughtersChart.measures.'+tabConf.field)} {...a11yProps(tabConf.index)} />
                            })
                            }
                        </Tabs>
                        {loading &&
                            <Backdrop style={{ zIndex: 1000 }} open={loading}><CustomLoader size={80} /></Backdrop>}
                        {!loading && (error || graphData?.length === 0 )&&
                            <div style={{display: 'flex', padding: '2em', alignItems: 'center', justifyContent: 'center', fontStyle: 'italic', fontSize: 'medium'}}>
                                <div><Typography>{t('slaughtersChart.messages.noData', 'No data for active filters')}</Typography></div>
                            </div>
                        }
                        {!loading && !error && graphData?.length > 0 &&
                            <div>
                                {tabs_conf.map((tabConf) => {
                                    return <TabPanel key={tabConf.index} value={filter.selected_tab_index} index={tabConf.index} >
                                        <br />
                                        <SummaryPanel summaryData={measureStats.get(tabConf.index)} />
                                        <br/>
                                        <Paper className={classes.paper} style={{ height: '70vh', margin: '1em', padding: '1em' }}>
                                            <ResponsiveBoxPlot
                                                data={graphData}
                                                margin={{ top: 60, right: 140, bottom: 140, left: 60 }}
                                                padding={0.5}
                                                subGroupBy="subgroup"
                                                enableGridX={true}
                                                quantiles={[0.1, 0.25, 0.5, 0.7, 0.9]}
                                                axisTop={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 90,
                                                    legend: '',
                                                    legendOffset: 36,
                                                    truncateTickAt: 11
                                                }}
                                                axisRight={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendOffset: 0,
                                                    truncateTickAt: 0
                                                }}
                                                axisBottom={{
                                                    tickSize: 7,
                                                    tickPadding: 10,
                                                    tickRotation: 90,
                                                    legend: t('slaughtersChart.boxPlot.xAxis', 'Farm'),
                                                    legendPosition: 'middle',
                                                    legendOffset: 110,
                                                    truncateTickAt: 11
                                                }}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: tabConf.label,
                                                    legendPosition: 'middle',
                                                    legendOffset: -40,
                                                    truncateTickAt: 0
                                                }}
                                                colors={ ({group})=> ( group === allevamento? accentColor : accentColorAlpha )}
                                                colorBy={'group'}
                                                borderRadius={2}
                                                borderWidth={2}
                                                borderColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.3
                                                        ]
                                                    ]
                                                }}
                                                medianWidth={2}
                                                medianColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.3
                                                        ]
                                                    ]
                                                }}
                                                whiskerEndSize={0.6}
                                                whiskerColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.3
                                                        ]
                                                    ]
                                                }}
                                                motionConfig="stiff"
                                                tooltip={showTooltip}
                                                onClick={handleBoxClick}                                                
                                                areaOpacity={0.7}
                                            />
                                        </Paper>                                        
                                        {allevamento ?
                                        <Grid container spacing={2}>                                            
                                            <Grid item xs={4}>
                                                <Paper className={classes.paper} style={{ height:'50vh', margin: '1em', padding: '1em'}}>
                                                    <Typography variant={'h6'} style={{fontSize: "1.265em"}}>
                                                        {t('slaughtersChart.linePlot.title', { measure: t('slaughtersChart.measures.'+tabConf.field), farm:allevamento})}
                                                    </Typography>
                                                    <ResponsiveLine
                                                    layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
                                                    animate={false}
                                                    data={lineData}
                                                    colorBy={'data'}
                                                    colors={d=>d.color}
                                                    curve={"monotoneX"}
                                                    margin={{ top: 20, right: 20, bottom: 100, left: 40 }}
                                                    xFormat="time:%Y-%m-%d"
                                                    xScale={{
                                                        format: '%Y-%m-%d',
                                                        precision: 'day',
                                                        type: 'time',
                                                        useUTC: false
                                                    }}
                                                    yScale={{
                                                        type: 'linear',
                                                        min: measureStats.get(tabConf.index).min.y,
                                                        max: measureStats.get(tabConf.index).max.y,
                                                        stacked: false,
                                                        reverse: false
                                                    }}
                                                    yFormat=" >-.2f"
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        format: '%b %d',
                                                        legend: t('slaughtersChart.linePlot.xTitle', 'Slaughter Date'),
                                                        legendOffset: 36,
                                                        legendPosition: 'middle',
                                                        tickValues: 'every 2 days'
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: tabConf.label,
                                                        legendOffset: -40,
                                                        legendPosition: 'middle',
                                                        truncateTickAt: 0
                                                    }}
                                                    pointSize={10}
                                                    pointColor={{ theme: 'background' }}
                                                    pointBorderWidth={2}
                                                    pointBorderColor={{ from: 'serieColor' }}
                                                    pointLabelYOffset={-12}
                                                    enableTouchCrosshair={true}
                                                    useMesh={true}
                                                    tooltip={showMacTooltip}
                                                    markers={[
                                                        {
                                                            axis: 'y',
                                                            legend: t('slaughtersChart.linePlot.meanValue', 'Mean Value'),
                                                            lineStyle: {
                                                                strokeDasharray: "10, 15",
                                                                stroke: primaryColor,
                                                                strokeWidth: 2
                                                            },
                                                            value: measureStats.get(tabConf.index).mean
                                                        }
                                                        
                                                    ]}
                                                    />
                                                </Paper>
                                            </Grid>                                            
                                            <Grid item xs={8} >
                                                <Paper style={{ minHeight: '50vh', margin: '1em', padding: '1em'}}>
                                                <Typography variant={'h6'} style={{fontSize: "1.265em"}}>
                                                    {t('slaughtersChart.table.title', {farm: allevamento})}
                                                </Typography>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell>{t('slaughtersChart.table.cycle', 'Breeding Cycle')}</TableCell>
                                                            <TableCell>{t('slaughtersChart.table.slaughterDate', 'Slaughter Date')}</TableCell>
                                                            {tabs_conf.map( (measure) => (
                                                                <TableCell key={'detail_table_h_'+measure.index}>{t('slaughtersChart.measures.'+measure.field)}</TableCell>
                                                            ))
                                                        }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {detailData.map((row, i)=>(
                                                            <TableRow key={'detail_row_'+i}>
                                                                <TableCell title={t('slaughtersChart.table.copyTooltip','Copy breeding cycle code to clipboard')} onClick={()=>copyToClipboard(row.epc)} style={{cursor: 'pointer'}}><FileCopyIcon/></TableCell>
                                                                <TableCell>{row.Ciclo}</TableCell>
                                                                <TableCell>{i18next.t('formatters:date', {date: Moment(row['Data Macellazione'])})}</TableCell>
                                                                {tabs_conf.map(measure=>(
                                                                    <TableCell key={'detail_cell_'+i+'_'+measure.index}>{row[t('slaughtersChart.measures.'+measure.field)]}</TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                </Paper>
                                            </Grid>
                                        </Grid> :
                                        <Grid container spacing={2} justifyContent="space-evenly" alignItems="center">
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Typography variant={'h5'} align="center">{t('slaughtersChart.messages.selectFarm')}</Typography>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        }
                                            
                                            
                                    </TabPanel>
                                })}
                            </div>
                        }
                    </div>
                }
            </ContainerDimensions>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={copyDone}
                autoHideDuration={3000}
                onClose={()=> {setCopy({copyDone:false, copyMessage:''})}}
                message={copyMessage}                
            />
        </div>
    )
}

export default SlaughtersChart