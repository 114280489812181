import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, {useContext, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import _ from 'lodash'
import {Box, FormControl} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import UploadImage from "../../components/UploadImage";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {login} from "../login/auth";
import {AuthContext} from "../../contexts";
import {useTranslation} from "react-i18next";
import {severityTypes} from "../../utils/Utils";

const AddBrand = (props) => {
    const {t} = useTranslation()
    const {data, _theme} = props
    const useStyles = makeStyles((theme) => ({
        root: {
            /*'& .MuiDialog-paperWidthSm': {
                width: '100%'
            },*/
            '& label.Mui-focused': {
                color: _theme.accentColor,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: _theme.accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: _theme.accentColor,
                },
            },
        },
        addBrand: {
            color: _theme.accentColor,
        },
        confirmBrand: {
            backgroundColor: _theme.accentColor,
            color: "white",
            fontWeight: 'bold',
            marginTop: '1%',
            marginRight: '1%',
            borderRadius: '20px',
        },
        labelChip: {
            marginRight: '1%',
            marginBottom: '1%',
            border: '1px solid ' + _theme.accentColor,
            color: _theme.accentColor,
            fontWeight: 'bold',
        },
        dottedBox: {
            borderRadius: '10px',
            border: '2px solid lightGray',
            borderStyle: 'dotted'
        }
    }))
    const classes = useStyles()

    const authContext = useContext(AuthContext)

    const initialState = {
        open: false,
        brandName: '',
        checked: false, // se checked === true -> HO UN COMPANY PREFIX
        brandCompanyPrefix: '',
        brandDomain: '',
        image: null
    }

    const [state, setState] = useState(initialState)

    const handleClickOpen = () => {
        setState({...state, open: true});
    };
    const handleClose = () => {
        setState(initialState);
    };

    const handleChange = (event) => {
        event.persist();
        setTimeout(() => {
            setState({...state, [event.target.id]: event.target.value})
        }, 500);
    }

    const handleChangeCompanyPrefix = (event) => {
        setState({...state, [event.target.id]: event.target.value})
    }

    const errorDomain = () => {
        return state.brandDomain !== '' &&
            _.some(data.brands, function (o) {
                return _.replace(o.domain, '.' + data.domain, '').toLowerCase() === state.brandDomain.trim().toLowerCase()
            })
    }

    const errorDuplicateCompanyPrefix = () => {
        return state.brandCompanyPrefix !== '' &&
            _.some(data.brands, function (o) {
                if (o.company_prefix)
                    return o.company_prefix.toLowerCase() === state.brandCompanyPrefix.trim().toLowerCase()
                else return false
            })
    }

    const errorDuplicateBrandName = () => {
        return state.brandName !== '' &&
            _.some(data.brands, function (o) {
                return o.name.toLowerCase() === state.brandName.trim().toLowerCase()
            })
    }

    const initialErrorState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialErrorState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const updateMyCompany = async () => {
        //const cp = state.checked ? state.brandCompanyPrefix : await getCompanyPrefix()
        const newBrand = {
            name: state.brandName,
            company_prefix: state.brandCompanyPrefix ? state.brandCompanyPrefix : null,
            domain: state.brandDomain.includes(data.domain) ? state.brandDomain : state.brandDomain + '.' + data.domain,
            other_domain: state.brandDomain.includes(data.domain) ? 'x.'+state.brandDomain : 'x.'+state.brandDomain + '.' + data.domain,
            image: state.image
        }
        //const dataToSend = {...data, brands: [...data.brands, newBrand]}
        console.log("dataToSend:", newBrand)
        axios
            .patch('/api/companies/' + data._id+'/add_brand/', newBrand)
            .then((res) => {
                if (res.status === 200) {
                    console.log("company updated okay")
                    let url = "/auth/refresh_token_from_header"
                    const instance = axios.create(
                        {
                            method: 'post',
                            headers: {'Authorization': `Bearer ${localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null}`},
                        }
                    );
                    return instance.post(url)
                        .then(
                            res => {
                                if (res.status === 200) {
                                    login(res.data)
                                    setOpenAlertMessage({open: true, message: t('myCompany.addedNewDomain'), severity: severityTypes.SUCCESS})
                                    setState(initialState)
                                    authContext.setActiveBrands([...data.brands, newBrand])
                                }
                            })
                        .catch(
                            error => {
                                console.log("error: ", error)
                                return Promise.reject(error)
                            }
                        )
                }
            })
            .catch((err) => {
                setOpenAlertMessage({
                    open: true,
                    message: t('errors.genericServerError') + err.message,
                    severity: severityTypes.ERROR
                })
            })
    }

    const onChangeImage = (id) => {
        console.log("id:", id)
        setState({...state, image: id})
    }

    return <div>
        <IconButton onClick={handleClickOpen}>
            <AddCircleIcon fontSize={"default"} className={classes.addBrand}/>
        </IconButton>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                  autoHideDuration={3000} onClose={handleCloseErrorMessage}>
            <Alert severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
        </Snackbar>
        <Dialog className={classes.root} open={state.open} maxWidth={"md"} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('myCompany.addDomain')}</DialogTitle>
            <DialogContent
                style={{minWidth: '700px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                <div style={{marginRight: '5%', flex: 2, display: 'flex', flexDirection: 'column'}}>
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="brandName"
                            label={t('myCompany.domainName')}
                            onChange={handleChange}
                            fullWidth
                            //variant={"outlined"}
                        />
                        {
                            errorDuplicateBrandName() ?
                                <FormHelperText error>{t('myCompany.domainNameAlreadyExists', {name: state.brandName})}</FormHelperText> : null
                        }
                    </FormControl>
                    <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                        <FormControl style={{width: '100%'}}>
                            <TextField
                                //disabled={!state.checked}
                                margin="dense"
                                id="brandCompanyPrefix"
                                label={/*state.checked ? */t('myCompany.insertGS1CompanyPrefix')/* : "iChain will generate a company prefix for you"*/}
                                //onChange={handleChange}
                                fullWidth
                                value={state.brandCompanyPrefix}
                                onChange={handleChangeCompanyPrefix}
                                //variant={"outlined"}
                            />
                            {
                                errorDuplicateCompanyPrefix() ?
                                    <FormHelperText error>
                                        {t('myCompany.companyPrefixAlreadyExists', {prefix: state.brandCompanyPrefix})}
                                    </FormHelperText>
                                    : <FormHelperText>
                                        {t('entities.optionalField', "This field is optional")}
                                    </FormHelperText>
                            }
                        </FormControl>
                        {/*<FormControl>
                            <FormControlLabel
                                control={<Checkbox checked={state.checked} onChange={handleCheckedChange}
                                                   color="default"/>}
                                label={<FormHelperText>I already have a company prefix to insert</FormHelperText>}
                            />
                        </FormControl>*/}
                    </div>
                    <div style={{display: 'flex', alignItems: 'baseline', width: 'max-content'}}>
                        <FormControl>
                            <TextField
                                margin="dense"
                                id="brandDomain"
                                label={t('myCompany.domainPrefix')}
                                //value={state.brandDomain}
                                onChange={handleChange}
                                fullWidth
                                //variant={"outlined"}
                            />
                            {
                                errorDomain() ?
                                    <FormHelperText error>
                                        {t('myCompany.domainAlreadyExists', {domain: state.brandDomain !== data.domain ? state.brandDomain + '.' + data.domain : data.domain})}
                                    </FormHelperText> : null
                            }
                        </FormControl>
                        <Chip className={classes.labelChip}
                            //icon={<LabelIcon style={{color: theme.accentColor}}/>}
                              label={state.brandDomain + '.' + data.domain}
                              variant={'outlined'}
                        />
                    </div>
                </div>
                <Box p={4} className={classes.dottedBox}>
                    <UploadImage value={state.image} onChange={onChangeImage}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}
                        style={{
                            marginTop: '1%',
                            marginRight: '1%'
                        }}>
                    {t('actions.cancel')}
                </Button>
                <Button disabled={errorDomain() || errorDuplicateCompanyPrefix() || errorDuplicateBrandName()
                || !state.brandName || (!state.brandCompanyPrefix && state.checked) || !state.brandDomain}
                        variant="contained"
                        onClick={updateMyCompany}
                        className={classes.confirmBrand}>
                    {t('actions.add', {what: t('entities.company.brand')})}
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
}

export default AddBrand
