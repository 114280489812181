import React, {useContext, useEffect, useMemo, useState} from "react";
import {Backdrop, Box} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableIncomingEvents from "../../components/tables/TableIncomingEvents";
import useAxios from "axios-hooks";
import MaterialTable from 'material-table';
import TableWhatEvents from "./TableWhatEvents";
import TableWhyEvents from "./TableWhyEvents";
import CustomMap from "../../components/CustomMap";
import CustomChartWhen from "./CustomChartWhen";
import IncomigEventsIcon from '@material-ui/icons/DynamicFeed';
import WhatIcon from '@material-ui/icons/LocalOffer';
import WhenIcon from '@material-ui/icons/DateRange';
import WhereIcon from '@material-ui/icons/Explore';
import WhyIcon from '@material-ui/icons/MyLocation';
import FilterListIcon from '@material-ui/icons/FilterList';
import axios from "axios";
import {ThemeContext, useStateValue} from "../../contexts";
import {extractValue, extractValueData} from "../../utils/Utils"
import {doSetData} from "../../actions";
import CustomDateRangePicker from "./CustomDateRangePicker";
import {useHistory} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import _ from "lodash";
import CustomLoader from "../../components/CustomLoader";
import {TimeScaleEnum} from "../production_flow_marble/Time";
import Moment from "moment";
import chroma from 'chroma-js';
import {findCodeTypeAndValue, findLocationCodeTypeValue} from "../../utils/CodesUtils";
import {useTranslation} from "react-i18next";

const Dashboard = () => {
    const { t } = useTranslation();
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        stickyRoot: {
            position: 'sticky',
            top: 60,
            bottom: 60,
            zIndex: 1010,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            minHeight: '200px',
        },
        historyIcon: {
            color: 'rgba(0, 0, 0, 0.54)',
            margin: '5px'
        },
        whenPaper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            minHeight: '200px',
            height: 500,
            paddingBottom: '7%'
        },
        filtersPanel: {
            padding: theme.spacing(2),
            //backgroundColor: 'whitesmoke',
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        button: {
            textTransform: "none",
            marginLeft: "1%",
            color: 'rgba(0, 0, 0, 0.54)'
        },
        icon: {
            marginEnd: '15px',
            color: accentColor
        },
        chip: {
            marginRight: '1%',
            marginBottom: '1%',
            backgroundColor: chroma(accentColor).alpha(0.9).hex(),
            color: 'white',
            fontWeight: 'bold',
        },
        fab: {
            textTransform: "none",
            margin: '0px',
            top: 'auto',
            right: '20px',
            bottom: '20px',
            left: 'auto',
            position: 'fixed',
            zIndex: 1010,
            //backgroundColor: 'lightgrey',
        }
    }));
    const classes = useStyles();

    let history = useHistory();

    const [aggregateEvents, setAggregateEvents] = useState({})
    const [aggregateData, dispatchAggregateData] = useStateValue()
    const [filters, setFilters] = useState({})
    const [nEvents, setNumEvents] = useState(0)
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(30);
    const increment = skip * limit
    const [direction, setDirection] = useState(-1);
    const [noDataToDisplay, setNoDataToDisplay] = useState(false)

    console.log("FF filters", filters)
    
    {/** $DEMOFILENI identify why we have vents with spaces, in the backend they don't have spaces!!! */}
    const safe_why = filters.why ? filters.why.split(" ").join("_") : '';

    const [{data: events, loading, error}, refetchEvents] = useAxios({
            url: "api/capture/epcis_events/?show_attatch=true&skip=" + increment
                + (filters.gtin ? "&" + filters.gtin : '')
                + (filters.gln ? "&" + filters.gln : '')
                + (safe_why ? "&" + safe_why : '')
                + (filters.start_date ? "&" + filters.start_date : '')
                + (filters.end_date ? "&" + filters.end_date : '')
                + "&limit=" + limit
                + "&sort_by=eventTime"
                + "&direction=" + direction,
        },
        {useCache: false}
    )

    useEffect((() => {
        let unmounted = false;

        refetchEvents()
        let url = "api/capture/epcis_events/dashboard_4w_counters?"
        let source = axios.CancelToken.source();

        Object.entries(filters).forEach(([key, value]) => {
            if(key !== 'companyName' && key !== 'itemName')
                url += value ? value + '&' : ''
        })

        axios.get(url, {cancelToken: source.token})
            .then(res => {
                if (res && !unmounted) {
                    setAggregateEvents(res.data)
                }
            })
            .catch((err) => {
                if (!unmounted) {
                    setNoDataToDisplay(true)
                    if (axios.isCancel(err)) {
                        console.log(`dashboard request cancelled:${err.message}`);
                    } else {
                        console.log("dashboard error happened:" + err.message);
                    }
                }
                setNoDataToDisplay(true)
            })

        return () => {
            source.cancel("Cancelling in cleanup");
        }
    }), [filters])

    const setBizStep = (whyName, bizStep) => {
        let step = whyName/*.replace(/_/g, " ")*/ + ' ('
        return step + bizStep/*.concat(splitCbvValues(bizStep).replace(/_/g, " "))*/ + ')'
    }

    useEffect((() => {
        if(aggregateEvents) {
            const map_where = {}
            const map_why = {}
            const map_when = {}
            const map_what = {}
            let event_counter = 0
            if(aggregateEvents.what_panel) {
                Object.entries(aggregateEvents.what_panel).forEach(([key, whatObj]) => {
                    map_what[whatObj._id] = {
                        n_events: whatObj.events_count,
                        n_items: whatObj.items_count,
                        last_event: {
                            date: whatObj.last_datetime,
                            eventID: whatObj.last_event_id,
                            biz_step: whatObj.last_why
                        },
                    }
                })
            }
            if(aggregateEvents.when_panel) {
                Object.entries(aggregateEvents.when_panel).forEach(([key, whenObj]) => {
                    let date
                    switch (whenObj.time_group) {
                        case TimeScaleEnum.one_hour:
                            date = Moment(whenObj._id*3600000).format("YYYY-MM-DDT00")
                            break;
                        case TimeScaleEnum.four_hours:
                            date = Moment(whenObj._id*3600000*4).format("YYYY-MM-DDT00")
                            break;
                        case TimeScaleEnum.one_day:
                            date = Moment(whenObj._id*3600000*24).format("YYYY-MM-DDT00")
                            break;
                        case TimeScaleEnum.one_week:
                            date = Moment(whenObj._id*3600000*24*7).format("YYYY-MM-DDT00")
                            break;
                        case TimeScaleEnum.one_month:
                            let year = 1970+Math.floor(whenObj._id/12)
                            let month = (whenObj._id%12) + 1
                            date = Moment(year + '-' + month  + '-1').format("YYYY-MM-DDT00")
                            break;
                        default:
                            throw new Error(`Unexpected TimeScaleEnum value ${whenObj.time_group}`)
                    }
                    event_counter += whenObj.events_count;
                    map_when[date] = {
                        n_events: whenObj.events_count,
                        n_items: whenObj.items_count,
                        time_group: whenObj.time_group
                    }
                })
            }
            if(aggregateEvents.where_panel) {
                Object.entries(aggregateEvents.where_panel).forEach(([key, whereObj]) => {
                    map_where[whereObj._id] = {
                        n_events: whereObj.events_count,
                        n_items: whereObj.items_count,
                    }
                })
            }
            if(aggregateEvents.why_panel) {
               //console.log("aggregateEvents.why_panel:",aggregateEvents.why_panel)
                Object.entries(aggregateEvents.why_panel).forEach(([key, whyObj]) => {
                    if(whyObj._id.length > 1){
                        let event_name = whyObj._id[0]
                        let why_bs = whyObj._id[1].split(':')
                        let business_step = why_bs[4]
                        let key = setBizStep(event_name, business_step)
                        map_why[key] = {
                            n_events: whyObj.events_count,
                            n_items: whyObj.items_count,
                        }
                    } else {
                        let why_bs = whyObj._id[0].split(':')
                        let business_step = why_bs[5]
                        map_why[business_step] = {
                            n_events: whyObj.events_count,
                            n_items: whyObj.items_count,
                        }
                    }
                })
            }

            dispatchAggregateData(doSetData(map_what, map_when, map_where, map_why))
            setNumEvents(event_counter)
        }
    }), [aggregateEvents])

    const onFabClick= () => {
        //console.log('click')
        history.go()
    }

    const Header = ({title}) => {
        return (
            <Box>
                <Typography gutterBottom align={"left"} style={{color: primaryColor, fontWeight: "bolder"}}>{title}</Typography>
            </Box>
        )
    }

    const showLoading = useMemo(() => {
        if ((aggregateEvents && Object.entries(aggregateEvents).length > 0) || nEvents) {
            return true
        } else if(!aggregateEvents) {
            setNoDataToDisplay(true)
        }
        return false
    }, [aggregateEvents, nEvents])

    const allFiltersLabel = t('incomingEvents.allFilters') // esplicito se no non estrae la stringa
    return (
        <div className={classes.root}>
            <Backdrop open={loading} style={{zIndex:1200}} children={<CustomLoader size={80} text={t('actions.loading')}/>}/>
            {showLoading ?
                <div>
                    <Fab
                        style={{backgroundColor: accentColor, color: 'whitesmoke'}}
                        className={classes.fab}
                        onClick={onFabClick}
                    >
                        <RefreshIcon/>
                    </Fab>
                    <Box pb={3} className={classes.stickyRoot}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={classes.filtersPanel}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid item xs={12}>
                                                <Box p={1} style={{display: "flex"}}>
                                                    <FilterListIcon className={classes.historyIcon} style={{color: primaryColor}}/>
                                                    <div style={{paddingLeft: '2%', display: "flex", flexWrap: "wrap"}}>
                                                        {
                                                            filters.gtin ?
                                                                <Chip
                                                                    className={classes.chip}
                                                                    avatar={<WhatIcon style={{color: 'white'}}/>}
                                                                    label={filters.itemName + ' - ' + (findCodeTypeAndValue(filters.gtin)[2] ?
                                                                        _.replace(findCodeTypeAndValue(filters.gtin)[2], 'what=', '') +':' : '' ) + _.replace(findCodeTypeAndValue(filters.gtin)[1], 'what=', '')}
                                                                    onDelete={() => {
                                                                        setSkip(0)
                                                                        setFilters({
                                                                            ...filters,
                                                                            gtin: '',
                                                                            itemName: ''
                                                                        })
                                                                    }}
                                                                /> : null
                                                        }
                                                        {
                                                            filters.start_date && filters.end_date ?
                                                                <Chip
                                                                    className={classes.chip}
                                                                    avatar={<WhenIcon style={{color: 'white'}}/>}
                                                                    label={extractValueData(filters.start_date) +
                                                                    ' - ' + extractValueData(filters.end_date)}
                                                                    onDelete={() => {
                                                                        setSkip(0)
                                                                        setFilters({
                                                                            ...filters,
                                                                            start_date: '',
                                                                            end_date: ''
                                                                        })
                                                                    }}
                                                                /> : null
                                                        }
                                                        {
                                                            filters.gln ?
                                                                <Chip
                                                                    className={classes.chip}
                                                                    avatar={<WhereIcon style={{color: 'white'}}/>}
                                                                    label={filters.gln ? filters.companyName
                                                                        + ' - ' + findLocationCodeTypeValue(filters.gln)[1] : findLocationCodeTypeValue(filters.gln)[1]}
                                                                    onDelete={() => {
                                                                        setSkip(0)
                                                                        setFilters({
                                                                            ...filters,
                                                                            gln: '',
                                                                            companyName: ''
                                                                        })
                                                                    }}
                                                                /> : null
                                                        }
                                                        {
                                                            filters.why ?
                                                                <Chip
                                                                    className={classes.chip}
                                                                    avatar={<WhyIcon style={{color: 'white'}}/>}
                                                                    label={
                                                                        /** per trasfomare creating_class_instance in
                                                                         * Creating class Instance
                                                                         * */
                                                                        _.startCase(extractValue(filters.why))
                                                                    }
                                                                    onDelete={() => {
                                                                        setSkip(0)
                                                                        setFilters({
                                                                            ...filters,
                                                                            why: ''
                                                                        })
                                                                    }}
                                                                /> : null
                                                        }
                                                    </div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item
                                              xs={4}
                                              container
                                              direction="row"
                                              justifyContent="flex-end"
                                              alignItems="center"
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                            >
                                                {filters.gtin || filters.start_date || filters.end_date || filters.gln || filters.why
                                                    ? <Tooltip title={t('actions.clear', {what: allFiltersLabel})} arrow>
                                                        <IconButton onClick={() => {
                                                                    setSkip(0)
                                                                    setFilters({})
                                                        }}>
                                                            <SettingsBackupRestoreIcon className={classes.historyIcon}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Paper className={classes.paper} style={{minHeight: '100%'}}>
                                <Box p={1} style={{display: 'flex'}}>
                                    <IncomigEventsIcon className={classes.icon} style={{color: primaryColor}}/>
                                    <Header title={t('landingPage.panelTitles.recentEvents')}/>
                                </Box>
                                {events ?
                                    <TableIncomingEvents
                                        events={events} setDirection={setDirection}
                                        direction={direction}
                                        limit={limit}
                                        setLimit={setLimit}
                                        skip={skip}
                                        setSkip={setSkip}
                                    /> :
                                    loading ?
                                        <MaterialTable title={''} isLoading={true}
                                                       options={{loadingType: 'overlay'}}/> : null}
                                {error ? <p>{t('errors.genericServerError')}</p> : null}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Box p={1} style={{display: 'flex'}}>
                                            <WhatIcon className={classes.icon} style={{color: primaryColor}}/>
                                            <Header title={"What"}/>
                                        </Box>
                                        {
                                            Object.keys(aggregateData.whatAggregate).length > 0 ?
                                                //console.log('AGGREGATE WHAT: ', aggregateData.whatAggregate) &&
                                                <TableWhatEvents what={aggregateData.whatAggregate}
                                                                 filters={filters}
                                                                 setSkip={setSkip}
                                                                 setFilters={setFilters}/>
                                                : <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>

                                        }
                                    </Paper>
                                </Grid>
                               <Grid item xs={12}>
                                    <Paper
                                        className={Object.keys(aggregateData.whenAggregate).length > 0 ? classes.whenPaper : classes.paper}>
                                        <Box style={{display: 'flex', justifyContent: "space-between"}}>
                                            <Box p={1} style={{display: 'flex'}}>
                                                <WhenIcon className={classes.icon} style={{color: primaryColor}}/>
                                                <Header title={"When"}/>
                                            </Box>
                                            <CustomDateRangePicker filters={filters} setFilters={setFilters} setSkip={setSkip}/>
                                        </Box>
                                        {
                                            aggregateData.whenAggregate && Object.keys(aggregateData.whenAggregate).length > 0 ?
                                                <CustomChartWhen when={aggregateData.whenAggregate}/>
                                                : <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>

                                        }
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Box display={'flex'}>
                                            <WhereIcon className={classes.icon} style={{color: primaryColor}}/>
                                            <Header title={"Where"}/>
                                        </Box>
                                        {
                                            aggregateData.whereAggregate ?
                                                <CustomMap places={aggregateData.whereAggregate}
                                                           filters={filters}
                                                           setSkip={setSkip}
                                                           setFilters={setFilters}/>
                                                : <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                                        }
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Box p={1} style={{display: 'flex'}}>
                                            <WhyIcon className={classes.icon} style={{color: primaryColor}}/>
                                            <Header title={"Why"}/>
                                        </Box>
                                        {
                                            Object.keys(aggregateData.whyAggregate).length > 0 ?
                                                <TableWhyEvents why={aggregateData.whyAggregate}
                                                                filters={filters}
                                                                setSkip={setSkip}
                                                                setFilters={setFilters}/>
                                                : <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div> :
                noDataToDisplay ?
                    <Typography style={{ margin: '4%', color:'darkgray'}} variant={'h5'} align={'center'}>
                        {t('errors.noDataToDisplay')}
                    </Typography>
                    : null
            }
        </div>
    )
}

export default Dashboard;
