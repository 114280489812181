import React, {useContext, useEffect, useState} from "react";
import BrandGroupedAvatars from "./BrandGroupedAvatars";
import Chip from "@material-ui/core/Chip";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ThemeContext} from "../contexts";
import {makeStyles} from "@material-ui/core/styles";
import {Popper, TextField, Tooltip} from "@material-ui/core";
import axios from "axios";
import _ from 'lodash'
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import {login} from "../pages/login/auth";
import {allCompaniesDomains, fetchActiveBrandsInfo, fetchUserInfo} from "../utils/Utils";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@material-ui/lab";
import Box from "@material-ui/core/Box";

const ActiveBrandSelection = ({toolbar = true, auth: authContext}) => {
    const {t} = useTranslation();
    //console.log("authContext:",authContext)
    const _theme = useContext(ThemeContext)
    const StyledBadge = withStyles((theme) => ({
        badge: {
            backgroundColor: '#44b700',
            color: '#44b700',
            position: 'absolute',
            top: '30',
            left: '30',
            boxShadow: `0 0 0 2px ${_theme.accentColor}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                border: '3px solid currentColor',
                content: '""',
            },
        },
    }))(Badge);
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        popper: {
            zIndex: 1400,
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: _theme.accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: _theme.accentColor,
                },
            },
        },
        activeBrand: {
            marginLeft: toolbar ? '1%' : null,
            border: '2px solid ' + _theme.accentColor,
            color: toolbar ? 'white' : _theme.accentColor,
            fontWeight: 'bold'
        },
        avatar: {
            //width: '35px',
            //height: '35px',
            borderWidth: '0px',
            //borderStyle: 'solid',
            //borderColor: _theme.primaryColor,
            backgroundColor: _theme.primaryColor,
            //cursor: 'pointer',
            fontSize: 'small'
        },
        selectedAvatar: {
            borderWidth: '3px',
            borderStyle: 'solid',
            borderColor: _theme.accentColor,
            backgroundColor: _theme.primaryColor,
            fontSize: 'small'
            //cursor: 'auto',
        },
        button: {
            width: '40%',
            backgroundColor: _theme.accentColor,
            borderRadius: '20px',
            color: 'white',
            fontWeight: 'bold',
            boxShadow: theme.shadows[2]
        }
    }));
    const classes = useStyles()

    const [state, setState] = useState({
        activeMainDomain: null,
        activeBrands: null,
        anchorEl: null,
        openSwitchDialog: false,
        selectedBrand: null
    });

    useEffect(() => {
        function setActive(activeBrands) {
            activeBrands = _.map(activeBrands, (brand) => {
                return {...brand, active: brand.domain === authContext.activeMainDomain}
            })
            setState({...state, activeMainDomain: _.find(activeBrands, 'active'), activeBrands: activeBrands})
        }

        (async () => {
            let activeBrands
            if (authContext.isAdmin) {
                activeBrands = await allCompaniesDomains()
                setActive(activeBrands);
            } else {
                activeBrands = await fetchActiveBrandsInfo()
                setActive(activeBrands);
            }

        })()
    }, [authContext])

    const handleToggleAutocomplete = (event) => {
        if(!state.anchorEl)
            setState({...state, anchorEl: event.currentTarget});
        else
            setState({...state, anchorEl: null});
    };

    const handleMenuClose = () => {
        setState({...state, anchorEl: null});
    };

    const handleClickOpenDialog = (value) => {
        setState({...state, openSwitchDialog: true, anchorEl: null, selectedBrand: value});
    };
    const handleDialogClose = () => {
        setState({...state, openSwitchDialog: false, selectedBrand: null});
    };

    const handleChangeActiveBrand = async () => {
        const data = {
            main_domain: state.selectedBrand.domain,
            brname: state.selectedBrand.name,
            company_prefix: state.selectedBrand.company_prefix
        }

        const loggedUser = await fetchUserInfo()

        const url = 'users/' + loggedUser.info._id
        axios({
            method: 'patch',
            url: url,
            data: data,
            config: {headers: {'Media-Type': 'application/json'}}
        }).then(function (response) {
            if (response.status === 200) {
                let url = "/auth/refresh_token_from_header"
                const instance = axios.create(
                    {
                        method: 'post',
                        headers: {'Authorization': `Bearer ${localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null}`},
                    }
                );
                return instance.post(url)
                    .then(
                        res => {
                            if (res.status === 200) {
                                login(res.data)
                                const activeBrands = _.map(state.activeBrands, (brand) => {
                                    return {...brand, active: brand.domain === state.selectedBrand.domain}
                                })
                                setState({
                                    ...state,
                                    activeMainDomain: state.selectedBrand,
                                    activeBrands: activeBrands,
                                    openSwitchDialog: false
                                });
                                authContext.setMainActiveBrand(state.selectedBrand.domain)
                            }
                        })
                    .catch(
                        error => {
                            console.log("auth error: ", error)
                            return Promise.reject(error)
                        }
                    )
            }
        }).catch(function (response) {
            console.log("error editing user:", response)
        });
    };

    return (<div>
        {
            state.activeBrands && state.activeMainDomain ?
                <div className={classes.root}>
                    {toolbar && <BrandGroupedAvatars brands={state.activeBrands} handleClickOpenDialog={handleClickOpenDialog}/>}
                    {<Tooltip
                        title={state.activeBrands.length > 1 ? t('appBar.changeActiveDomain') : t('profile.activeDomain')+": "+state.activeMainDomain.domain}>
                        <Chip variant={'outlined'}
                              className={classes.activeBrand}
                              icon={<LocalOfferIcon style={{color: toolbar ? _theme.secondaryColor : _theme.accentColor}}/>}
                            // avatar={<Avatar
                            //       style={{color:_theme.secondaryColor, backgroundColor:_theme.primaryColor}}
                            //       src={"api/uploads/uploads/" + _.find(state.activeBrands, ['domain', authContext.activeMainDomain]).img}>
                            //             {_.find(state.activeBrands, ['domain', authContext.activeMainDomain]).img ?
                            //                null :  <LocalOfferIcon/>}
                            //         </Avatar>}
                              label={`${state.activeMainDomain ? state.activeMainDomain.name : authContext.companyName}`}
                              onClick={state.activeBrands.length > 1 ? handleToggleAutocomplete : null}
                              onDelete={state.activeBrands.length > 1 ? handleToggleAutocomplete : null}
                              deleteIcon={state.activeBrands.length > 1 ?
                                  <ExpandMoreIcon style={{color: toolbar ? _theme.secondaryColor : _theme.accentColor}}/> : null}
                        />
                    </Tooltip>}
                    {authContext.isAdmin && <Popper
                        className={classes.popper}
                        open={Boolean(state.anchorEl)}
                        anchorEl={state.anchorEl}
                        placement="bottom"
                    > <Box p={1} mt={3} style={{background: 'white'}} borderRadius={8}
                           boxShadow={"3px 4px 18px -1px rgba(150,150,150,0.71)"} >
                        <Autocomplete
                            open
                            clearOnEscape
                            renderTags={() => null}
                            noOptionsText={t("appBar.noDomainsMatch", "No domains matches your input")}
                            //onClose={handleToggleAutocomplete}
                            style={{minWidth: '400px'}}
                            options={_.orderBy(state.activeBrands, ['active'], ['desc'])}
                            renderInput={(params) => <TextField {...params} size="small" label="active brand selection"
                                                                variant="outlined"/>}
                            getOptionLabel={option => option.name}
                            renderOption={(brand, {selected}) => {
                                return (<Box display={'flex'} justifyContent={'start'} alignItems={'center'}
                                             onClick={() => !brand.active && handleClickOpenDialog(brand)} /*component={Link} to="/MyCompany"*/>
                                    <ListItemAvatar>
                                        {
                                            brand.active ?
                                                <StyledBadge
                                                    overlap="circle"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    variant="dot">
                                                    <Avatar
                                                        //alt={brand.name}
                                                        src={(brand.image || brand.img) ? "api/uploads/uploads/" + (brand.image || brand.img) : null}
                                                        className={brand.active ? classes.selectedAvatar : classes.avatar}>
                                                        {
                                                            !brand.image && !brand.img ?
                                                                _.split(brand.name, ' ').map((part) =>
                                                                    (part.charAt(0))) : null
                                                        }
                                                    </Avatar>
                                                </StyledBadge>
                                                : <Avatar
                                                    src={(brand.image || brand.img) ? "api/uploads/uploads/" + (brand.image || brand.img) : null}
                                                    className={brand.active ? classes.selectedAvatar : classes.avatar}>
                                                    {
                                                        !brand.image && !brand.img ?
                                                            _.split(brand.name, ' ').map((part) =>
                                                                (part.charAt(0))) : null
                                                    }
                                                </Avatar>
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${brand.name}`}
                                        secondary={brand.domain}
                                        style={{color: _theme.primaryColor}}/>
                                </Box>)
                            }}
                        />
                    </Box>
                    </Popper>}
                    {!authContext.isAdmin && <Menu
                        variant={"selectedMenu"}
                        id="simple-menu"
                        anchorEl={state.anchorEl}
                        keepMounted
                        open={Boolean(state.anchorEl)}
                        //TransitionComponent={Slide}
                        onClose={handleMenuClose}>
                        {<Typography align={'center'} variant={"h6"}>{t('appBar.switchActiveDomain?')}</Typography>}
                        {
                            _.orderBy(state.activeBrands, ['active'], ['desc']).map((brand, index) => (
                                <MenuItem disabled={brand.active}
                                          onClick={() => handleClickOpenDialog(brand)}
                                          key={index} /*component={Link} to="/MyCompany"*/>
                                    <ListItemAvatar>
                                        {
                                            brand.active ?
                                                <StyledBadge
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    variant="dot">
                                                    <Avatar
                                                        //alt={brand.name}
                                                        src={(brand.image || brand.img) ? "api/uploads/uploads/" + (brand.image || brand.img) : null}
                                                        className={brand.active ? classes.selectedAvatar : classes.avatar}>
                                                        {
                                                            !brand.image && !brand.img ?
                                                                _.split(brand.name, ' ').map((part) =>
                                                                    (part.charAt(0))) : null
                                                        }
                                                    </Avatar>
                                                </StyledBadge>
                                                : <Avatar
                                                    src={(brand.image || brand.img) ? "api/uploads/uploads/" + (brand.image || brand.img) : null}
                                                    className={brand.active ? classes.selectedAvatar : classes.avatar}>
                                                    {
                                                        !brand.image && !brand.img ?
                                                            _.split(brand.name, ' ').map((part) =>
                                                                (part.charAt(0))) : null
                                                    }
                                                </Avatar>
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${brand.name}`}
                                        secondary={brand.domain}
                                        style={{color: _theme.primaryColor}}/>
                                </MenuItem>))
                        }
                    </Menu>}
                    <Dialog
                        open={state.openSwitchDialog}
                        onClose={handleDialogClose}>
                        <DialogContent>
                            <DialogContentText>
                                {t('appBar.areYouSureToSwitchDomain?')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="default">
                                {t('actions.cancel')}
                            </Button>
                            <Button onClick={handleChangeActiveBrand} className={classes.button} color="default"
                                    autoFocus>
                                {t('actions.confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div> : null
        }
    </div>)
}

export default ActiveBrandSelection
