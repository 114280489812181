import React, {useEffect, useState} from 'react'
import TableIncomingEvents from "../../components/tables/TableIncomingEvents";
import axios from "axios";
import CustomLoader from "../../components/CustomLoader";
import _ from 'lodash'
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";

const MarbleEventsDetails = ({clusteredEventParameters}) => {
    const { t } = useTranslation();

    const [events, setEvents] = useState(null)
    const [limit, setLimit] = useState(30)
    const [skip, setSkip] = useState(0)
    const [direction, setDirection] = useState(-1)

    useEffect(() => {
        async function fetchData() {
            if (clusteredEventParameters) {
                const order = direction < 0 ? 'desc' : 'asc'
                let sortedData = []
                setEvents(null)

                if (!Array.isArray(clusteredEventParameters.timeSlot)) {
                    let url = `api/capture/epcis_events/marble/v2/clustered_events/events/?group_by=${clusteredEventParameters.groupBy}&timeslot=${clusteredEventParameters.timeSlot}&node_signature=${clusteredEventParameters.nodeSignature}`
                    const res = await axios.get(url)
                    sortedData = _.orderBy(res.data, ['eventTime'], [order])
                } else {
                    for (const ts of clusteredEventParameters.timeSlot) {
                        console.log("ts:", ts)
                        let url = `api/capture/epcis_events/marble/v2/clustered_events/events/?group_by=${clusteredEventParameters.groupBy}&timeslot=${ts}&node_signature=${clusteredEventParameters.nodeSignature}`

                        const res = await axios.get(url)
                        sortedData = [...sortedData, ...res.data]
                    }
                    console.log("out")
                }

                console.log("here")
                setEvents({label: clusteredEventParameters.label, count: sortedData.length, data: _.orderBy(sortedData, ['eventTime'], [order])})
            } else setEvents(null)
        }
        fetchData()
    }, [clusteredEventParameters])

    return <Paper style={{
        width: '30%',
        position: 'fixed',
        right: 0,
        paddingTop: 8,
    }}>
        {events?.label && <Typography gutterBottom variant={'h6'} align={'center'}>{events.label}</Typography>}
        {events?.data
            ? <TableIncomingEvents
                maxBodyHeight={document.body.offsetHeight - 100}
                events={
                    {
                        data: events.data.slice(
                            Math.min(
                                skip * limit, events.data.length),
                            Math.min(skip * limit + limit, events.data.length)
                        ),
                        count: events.count
                    }
                }
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                direction={direction}
                setDirection={setDirection}
            />
            : <CustomLoader size={80} text={t('actions.loading', {what: ''})}/>}
    </Paper>
}

export default MarbleEventsDetails
