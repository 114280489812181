import {TimeScaleEnum} from './Time';
import {aggregationTypes, createExpandedGraphData} from "./MarbleUtility";
import _ from 'lodash'

export const SET_DETAILS = 'set_details'
export const SET_PERIOD = 'set_period'
export const SET_NODE_DATA = 'set_node_data'
export const SET_CLUSTERED_EVENTS = 'set_clustered_events'
export const SET_EXPANDED_NODES = 'set_expanded_nodes'
export const SET_DIALOG_CONTENT = 'set_dialog_content'
export const SET_EXPANDED_SUBLINKS = 'set_expanded_sublinks'
export const SET_SERVER_NODES = 'set_server_nodes'
export const SET_SHOW_GRAPH = 'set_show_graph'
export const SET_AGGREGATION_TYPE = 'set_aggregation_type'
export const SET_FILTER_TAGS = 'set_filter_tags_graph'
export const SET_FILTER_PRODUCTS = 'set_filter_products_graph'
export const SET_FILTER_PRODUCTS_BY_LINE = 'set_filter_products_graph_by_line'
export const SET_FILTER_COMPANIES = 'set_filter_companies_graph'
export const RESET_GRAPH = 'reset_graph'

export function setDetails(details) {
    return {type: SET_DETAILS, details}
}

export function setPeriod(date, timeScale) {
    return {type: SET_PERIOD, date, timeScale}
}

export function setClusteredEvents(nodes, timeScale) {
    return {type: SET_CLUSTERED_EVENTS, nodes, timeScale}
}

export function setExpandedNode(expandedNode) {
    return {type: SET_EXPANDED_NODES, expandedNode}
}

export function setDialogContent(dialogContent) {
    return {type: SET_DIALOG_CONTENT, dialogContent}
}

export function setExpandedSublinks(expandedSublink) {
    return {type: SET_EXPANDED_SUBLINKS, expandedSublink}
}

export function setServerNodes(serverNodes, timeScale) {
    return {type: SET_SERVER_NODES, serverNodes, timeScale}
}

export function setShowGraph(show) {
    console.log("show:",show)
    return {type: SET_SHOW_GRAPH, show}
}

export function setAggregationType(aggregationType) {
    return {type: SET_AGGREGATION_TYPE, aggregationType}
}

export function setFilterProducts(selectedProducts) {
    return {type: SET_FILTER_PRODUCTS, selectedProducts}
}

export function setFilterTags(selectedTags) {
    return {type: SET_FILTER_TAGS, selectedTags}
}

export function setFilterProductsByLine(productLineChecked) {
    return {type: SET_FILTER_PRODUCTS_BY_LINE, productLineChecked}
}

export function setFilterCompanies(selectedCompanies) {
    return {type: SET_FILTER_COMPANIES, selectedCompanies}
}

export function setNodeData(nodesToAdd) {
    return {type: SET_NODE_DATA, nodesToAdd}
}

export function resetGraph() {
    console.log("reset")
    return {type: RESET_GRAPH}
}

export function marbleInitialize({nodes, older_datetime, most_recent_datetime, companyNameLut, showGraph, products, gtinColorMap, forwardHistory, backwardHistory, authContext}) {

    console.log("init marble:")
    const mostRecentDate = new Date(most_recent_datetime)
    const oldestDate = new Date(older_datetime)
    const childrenParentMap = {};

    const expandedNodes = nodes.reduce((a,b) => {
        childrenParentMap[b.id] = b.label
        a[b.label] = false
        return a
    }, {})

    const [, linksMap] = createExpandedGraphData(nodes)

    return {
        authContext: authContext,
        date: mostRecentDate,
        mostRecentDate: mostRecentDate,
        oldestDate: oldestDate,
        timeScale: TimeScaleEnum.one_month,
        details: '',

        dialogContent: null,

        childrenParentMap: childrenParentMap, // per rimappare i figli sui parent (why -> where)
        serverNodes: nodes, // tutti i nodi why nodes dal server (espansi)
        serverLinks: Object.values(linksMap), // tutti i link tra i why nodes dal server (espansi)

        expandedNodes: JSON.parse(localStorage.getItem(`${authContext.userName}_${authContext.companyPrefix}_expandedNodes`)) || expandedNodes,
        nodesToAdd: [],
        expandedSublinks: {},

        lastExpandedNode: JSON.parse(localStorage.getItem(`${authContext.userName}_${authContext.companyPrefix}_lastExpandedNode`)),

        showGraph: showGraph,
        gtinColorMap: gtinColorMap,
        companyNameLut: companyNameLut,

        aggregationType: aggregationTypes.why,
        filterTags: JSON.parse(localStorage.getItem(`${authContext.userName}_${authContext.companyPrefix}_filterTags`) || "[]"),

        forwardHistory: forwardHistory,
        backwardHistory: backwardHistory,
        products: products,
        filterProducts: JSON.parse(localStorage.getItem(`${authContext.userName}_${authContext.companyPrefix}_filterProducts`) || "[]"),
        lineProductChecked: localStorage.getItem(`${authContext.userName}_${authContext.companyPrefix}_lineProductChecked`) === 'true',

        filterCompanies: JSON.parse(localStorage.getItem(`${authContext.userName}_${authContext.companyPrefix}_filterCompanies`) || "[]"),
    }
}

/**
 * const subLinks = createSubGraphLinks(state.serverNodes, state.filterProducts, newExpandedNodes)
 * const [newActiveNodes, filteredNodes] = setActiveNodes(state.serverNodes, newExpandedNodes, state.filterProducts, state.filterCompanies)
 * const newActiveLinks = setActiveLinks(state.serverNodes, state.serverLinks, subLinks, newActiveNodes, filteredNodes, newExpandedNodes, state.childrenParentMap) // return array
 * return {...state,
                lastExpandedNode: newExpandedNodes[action.expandedNode] === true ? action.expandedNode : state.lastExpandedNode,
                expandedNodes: newExpandedNodes,
                nodes: Object.values(newActiveNodes),
                links: Object.values(newActiveLinks)
            }
 */


export function marbleReducer(state, action) {
    switch (action.type) {
        case SET_PERIOD:
            return { ...state, date: action.date, timeScale: action.timeScale }
        case SET_DETAILS:
            return { ...state, details: action.details }
        case SET_CLUSTERED_EVENTS:
            return { ...state, nodes: action.nodes, timeScale: action.timeScale }
        case SET_SERVER_NODES:
            const _nodesToAdd = state.nodesToAdd.map((node) => {
                const _node = _.find(action.serverNodes, ['id', node.id])

                return {
                ...node,
                clusteredEvents: _node.clusteredEvents
            }})
            return { ...state, serverNodes: action.serverNodes, timeScale: action.timeScale, nodesToAdd: _nodesToAdd}
        case SET_EXPANDED_NODES:
            const newExpandedNodes = {
                ...state.expandedNodes,
                [action.expandedNode]: !state.expandedNodes[action.expandedNode]
            }

            localStorage.setItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_expandedNodes`, JSON.stringify(newExpandedNodes))
            localStorage.setItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_lastExpandedNode`, JSON.stringify(newExpandedNodes[action.expandedNode] === true ? action.expandedNode : state.lastExpandedNode))

            return {...state,
                lastExpandedNode: newExpandedNodes[action.expandedNode] === true ? action.expandedNode : state.lastExpandedNode,
                expandedNodes: newExpandedNodes
            }
        case SET_DIALOG_CONTENT:
            const {dialogContent} = action

            return {...state,
                dialogContent: dialogContent
            }
        case SET_EXPANDED_SUBLINKS:
            //console.log("state.expandedSublinks:", state.expandedSublinks)

            const linkId = `${action.expandedSublink.originalSource}-->${action.expandedSublink.originalTarget}`

            const newExpandedSublinks = {
                ...state.expandedSublinks,
                [linkId]: {
                    expanded: state.expandedSublinks[linkId]? !state.expandedSublinks[linkId].expanded : true,
                    children: action.expandedSublink.children
                }
            }

            let result = []
            if(newExpandedSublinks[linkId].expanded) {
                // aggiungi children
                result = _.concat(state.nodesToAdd, newExpandedSublinks[linkId].children)
            } else {
                // rimuovi children
                result = _.cloneDeep(state.nodesToAdd)
                // se ho copie di children perchè sono stati aggiunti espandendo più sublink che li "condividivono",
                // in questo modo rimuovo solo la prima occorrenza
                newExpandedSublinks[linkId].children.forEach((child) => {
                    const indexToPull = _.findIndex(result, ['id', child.id])
                    _.pullAt(result, indexToPull)
                })
            }
            //console.log("newExpandedSublinks:", newExpandedSublinks)
            return {...state,
                expandedSublinks: newExpandedSublinks,
                nodesToAdd: result
            }

        /** TODO: gestire i nodi aggiunti dall'espansione dei sublink */
        case SET_NODE_DATA:
            //console.log("SET_NODE_DATA ---> state.nodesToAdd:",state.nodesToAdd)
            const newNodesToAdd = _.unionBy(state.nodesToAdd, action.nodesToAdd, 'id')
            //console.log("SET_NODE_DATA ---> newNodesToAdd:",newNodesToAdd)

            return { ...state, nodesToAdd: newNodesToAdd }

        case SET_SHOW_GRAPH:
            return {...state, showGraph: action.show}

        case SET_AGGREGATION_TYPE:
            const aggregationAttribute = action.aggregationType === aggregationTypes.where ? 'parent' : 'label'
            const _childrenParentMap = {}
            const _initialExpandedNodes = state.serverNodes.reduce((a,b) => {
                _childrenParentMap[b.id] = b[aggregationAttribute]
                a[b[aggregationAttribute]] = false
                return a
            }, {})
            return {
                ...state,
                aggregationType: action.aggregationType,
                expandedNodes: _initialExpandedNodes,
                childrenParentMap: _childrenParentMap
            }

        case SET_FILTER_TAGS:
            //console.log("SET_FILTER_TAGS ---> state.filterTags:",state.filterTags)
            //console.log("SET_FILTER_TAGS ---> action.selectedTags:",action.selectedTags)
            return { ...state, filterTags: action.selectedTags }

        case SET_FILTER_PRODUCTS:
            //console.log("SET_FILTER_PRODUCTS ---> state.filterProducts:",state.filterProducts)
            //console.log("SET_FILTER_PRODUCTS ---> action.selectedProducts:",action.selectedProducts)

            if(action.selectedProducts.length > 0)
                return { ...state, filterProducts: action.selectedProducts }
            else
                return { ...state, filterProducts: action.selectedProducts, lineProductChecked: false }

        case SET_FILTER_PRODUCTS_BY_LINE:
            return { ...state, lineProductChecked: action.productLineChecked }

        case SET_FILTER_COMPANIES:
            return { ...state, filterCompanies: action.selectedCompanies }

        case RESET_GRAPH:
            //console.log("RESET_GRAPH")
            const resetExpandedNodes = _.cloneDeep(state.expandedNodes)
            for (const key in state.expandedNodes) {
                resetExpandedNodes[key] = false
            }
            localStorage.removeItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_expandedNodes`)
            localStorage.removeItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_lastExpandedNode`)
            localStorage.removeItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_filterTags`)
            localStorage.removeItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_filterProducts`)
            localStorage.removeItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_lineProductChecked`)
            localStorage.removeItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_filterCompanies`)

            return { ...state, filterTags: [], filterProducts: [], filterCompanies: [], nodesToAdd: [], expandedNodes: resetExpandedNodes, lastExpandedNode: null }
        default:
            console.log("Errore: azione", action.type, "sconosciuta")
            return state;
    }
}
