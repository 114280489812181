import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {DateRangePicker} from 'react-date-range'
import * as locales from 'react-date-range/dist/locale';
import Moment from "moment";
import Popover from "@material-ui/core/Popover";
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import i18next from "../../../i18n/i18next";

/** Componente usato anche in Reports e KPI Analytics */
const WhenRuleComponent = ({state, setState, width = '100%', outlined = true}) => {
    const {t} = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const lng = localStorage.getItem(`iChain_lang`) || 'en'
    const locale = locales[lng]

    const handleClick = (event, clear) => {
        event.stopPropagation()
        if(clear) {
            handleChangeDate({selection: {
                    startDate: undefined,
                    endDate: undefined,
                    color: 'darkgrey',
                    key: 'selection'
                }})
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    function handleChangeDate(item) {
        setState({...state, selectedDates: [item.selection]})
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const timePeriodLabel = t('entities.timePeriod', 'period of time')
    return (
        <Box width={width}>
                <TextField
                    id={'when_rule_component_date_range'}
                    size={'small'}
                    placeholder={t('actions.filterBy', {by: timePeriodLabel})}
                    value={state.selectedDates[0].startDate ?
                        `${i18next.t("formatters:date", {date: Moment(state.selectedDates[0].startDate)})} ${state.selectedDates[0].endDate !== state.selectedDates[0].startDate ? ' - ' + i18next.t("formatters:date", {date: Moment(state.selectedDates[0].endDate)})
                    : ''}` : ''}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={(event) => {handleClick(event, true)}}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>
                                <IconButton onClick={handleClick}>
                                    <DateRangeIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>,
                        readOnly: true
                    }}
                    onClick={handleClick}
                    variant={outlined ? "outlined" : "standard"}
                    fullWidth={true}
                />

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <DateRangePicker
                        editableDateInputs={true}
                        onChange={item => handleChangeDate(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={state.selectedDates}
                        showMonthAndYearPickers={true}
                        locale={locale}
                    />
                </Popover>
        </Box>
    )
}

export default WhenRuleComponent
