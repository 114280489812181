import React from "react";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import ProfileDetail from "./ProfileDetail";

const MyProfile = () => {
    const [{data: my_profile, loading, error}, refetchData] = useAxios({
            url: "users/me/"
        },
        {useCache: false}
    )

    return (
        <div>
            {
                my_profile ? <ProfileDetail myProfile={my_profile} refetchData={refetchData}/> : null
            }
            {loading ?
                <CustomLoader size={80} />
                : null}
            {error ? 'Server Error fetching profile data' : null}
        </div>
    )
}

export default MyProfile;
