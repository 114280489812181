import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import React, {useState} from "react";
import useAxios from "axios-hooks";
import {useHistory} from "react-router-dom";

const ChangeConfigurationDialog = (props) => {

    const [{data: configurations},] = useAxios({
            url: "/api/configurations/?skip=0&limit=100"
        },
        {useCache: false}
    )

    let history = useHistory()

    const [configuration, setConfiguration] = useState(props.configuration);

    const [errorOpen, setErrorOpen] = useState(false);

    const handleErrorClick = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    const onChangeConfigurationClick = () => {
        axios({
            method: 'patch',
            url: '/api/devices/update_configuration/'+props.deviceId,
            data: {
                name: "device",
                configuration: configuration
            }
        })
            .then((res) => {
                console.log('res', res)
                history.goBack()
            })
            .catch((err) => {
                console.log('err', err)
                props.onClose()
                handleErrorClick()
            })
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Select configuration"}</DialogTitle>
                <DialogContent>
                    { configurations ?
                    <Select style={{width: 400}}
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            value={configuration}
                            onChange={(event) => {
                                setConfiguration(event.target.value);
                            }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            configurations.data.map(
                                (conf) => (
                                    <MenuItem value={conf.name}>{conf.name}</MenuItem>
                                )
                            )
                        }
                    </Select> : <Select disabled/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onChangeConfigurationClick} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Error during operation
                </Alert>
            </Snackbar>
        </div>
    );
}

export default ChangeConfigurationDialog