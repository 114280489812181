import React, {useContext, useMemo, useState} from "react";
import useAxios from "axios-hooks";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';
import {makeStyles} from "@material-ui/core/styles";
import MapViewPlaces from "./MapViewPlaces";
import TablePlaces from "./TablePlaces";
import MaterialTable from 'material-table';
import AddIcon from "@material-ui/icons/Add";
import {AuthContext, RoutingContext} from "../../contexts";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    button: {
        marginBottom: "6%",
        color: theme.secondary,
        textTransform: "none"
    },
    grid: {
        marginLeft: '2%',
        paddingTop: '2%',
        width: '96%',
    },
}));

const Places = ({areMyPlaces}) => {
    const {t} = useTranslation()
    const classes = useStyles();

    const [state, setState] = useState({
        skip: 0, limit: 5,
        mapViewMode: false,
        myPlaces: {count:0, data:[]},
        load: !!areMyPlaces
    })

    const increment = state.skip * state.limit

    const myContext = useContext(RoutingContext);
    const {activeMainDomain} = useContext(AuthContext);

    const [{data: places, loading, error},] = useAxios({
            url: "/api/places/?domain="+activeMainDomain+"&mine="+areMyPlaces+"&not_mine="+!areMyPlaces+"&skip=" + increment + "&limit=0" //+ state.limit
        },
        {useCache: false}
    )

    const parsedPlaces = useMemo(() => {
        if(!places)
            return null
        let glnMapPlaces = {}
        places.data.forEach((place) => {
            glnMapPlaces[place.gln] = place
        })
        console.log("glnMapPlaces:",glnMapPlaces)
        return glnMapPlaces
    }, [places])

    const onViewModeClick = () => {
        setState({...state, mapViewMode: !state.mapViewMode});
    }

    const onAddClick = () => {
        myContext.setRoute('AddPlace', {edit: false, other: !areMyPlaces});
    }

    return (
        <div>
            <div>
                <Grid container
                      spacing={1}
                      direction="row"
                      justify="flex-start"
                >
                    <Grid item >
                        <Button
                            className={classes.button}
                            onClick={onAddClick}
                            startIcon={<AddIcon/>}>
                            { areMyPlaces ? t('myPlaces.addNewPlace') : t('othersPlaces.addNewPlace')}
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button
                            className={classes.button}
                            startIcon={state.mapViewMode ? <ListIcon/> : <MapIcon/>}
                            onClick={onViewModeClick}
                        >
                            {state.mapViewMode ? t('entities.viewMode.table') : t('entities.viewMode.map')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
            {parsedPlaces ? <div id="map_id">
                {
                    state.mapViewMode ?
                        <MapViewPlaces places={parsedPlaces} classes={classes}/>
                        : <TablePlaces areMyPlaces={areMyPlaces} places={places}/>
                }
            </div>
            : loading ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error  ? <p>{t('errors.genericServerError')}</p> : null}
        </div>
    )
}

export default Places;
