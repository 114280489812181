import React, {useContext, useEffect, useState} from "react";
import {CreateEventContext} from "../../../contexts";
import _ from 'lodash'
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {sourceDestinationListInputTypes, eventFieldNameTypes, inputTypes} from "../../../utils/CbvUtils";
import FieldInfo from "../FieldInfo";
import Divider from "@material-ui/core/Divider";
import FormHelperText from "@material-ui/core/FormHelperText";
import ClearIcon from "@material-ui/icons/Clear";
import SourceDestListBuilder from "./SourceDestListBuilder";
import {extractLocationClassCode} from "../../../utils/CodesUtils";
import {useTranslation} from "react-i18next";

/**
 *  sourceList with no alias:
 *   -- Input method: PARTIALLY-FIXED. The value must be given as parameeter as follow:
 *      It is a list of Source or a list of Destination, the parameeter is the name of the attribute followed by a list of tuple of 2 elements: (source or destination, type)
 *      - source or destination is a string which represent a location, usually it is an urn of a SGLN
 *      - The size of the list and the types were fixed, so you must provide a list of string corresponding to the following ordered types:
 *      --- ['urn:epcglobal:cbv:sdt:location', 'urn:epcglobal:cbv:sdt:possessing_party']
 --- sourceList: [str]

 sourceList with no alias:
 -- Input method: EXTERNAL. The value must be given as parameeter as follow:
 --- It is a list of Source or a list of Destination, the parameeter is the name of the attribute followed by a list of tuple of 2 elements: (source or destination, type)
 ---- source or destination is a string which represent a location, usually it is an urn of a SGLN
 ---- The type of the source (or destination) must be one of these values:
 ----- urn:epcglobal:cbv:sdt:location
 ----- urn:epcglobal:cbv:sdt:owning_party
 ----- urn:epcglobal:cbv:sdt:possessing_party
 --- destinationList: [(str, str))]
 */

const SourceDestListComponent = ({field, places}) => {
    const {t} = useTranslation()

    const eventContext = useContext(CreateEventContext)

    const [sourceDestList, setSourceDestList] = useState(eventContext.getFormField('where', field.name).value || []);

    /** versione asincrona */
    /*useEffect(() => {
        if (field && field.children) {
            (async () => {
                let descriptions = {}
                for (let sourceDestType of field.children) {
                    descriptions[sourceDestType] = await fetchDescription('source-destination_type',sourceDestType)
                }
                //console.log('DESCRIPTIONS ---> ', descriptions)
                setState({...state, sourceDestDescription: descriptions})
            })();
        }
    }, [field])*/

    useEffect(() => {
        eventContext.setValidationField(
            'where',
            field.name,
            field.optional)

    }, [field])

    const handleClear = (sourceDestInputType, sourceDestValue) => {
        let newSourceDestList = _.cloneDeep(sourceDestList) || []

        _.remove(newSourceDestList, function (o) {
            return o[1] === sourceDestInputType && o[0] === sourceDestValue
        })
        eventContext.setFormField('where', field.name, newSourceDestList)
        if (!field.optional && newSourceDestList.length !== field.children.length)
            eventContext.setValidationField('where', field.name, false)
        setSourceDestList(newSourceDestList)
    };

    return (
        <div>
            {
                <Box mb={2} pb={2}>
                    <FieldInfo
                        name={_.find(eventFieldNameTypes, ['name', field.name]).label}
                        description={_.find(eventFieldNameTypes, ['name', field.name]).description}
                        external={true}
                        optional={field.optional}
                    />
                    {
                        field.input === inputTypes.EXTERNAL.value ?
                            sourceDestList && sourceDestList.length !== 0 ?
                                sourceDestList.map((code, index) => (
                                    <Box key={index} pl={2} pr={2}>
                                        <Grid container direction="row" justify="flex-start">
                                            <Grid item xs={11} container
                                                  direction="row"
                                                  justify="flex-start"
                                                  alignItems="center">
                                                    <Typography>
                                                        <b>{_.find(sourceDestinationListInputTypes, ['value', code[1]]).name + ': '}</b>
                                                        {
                                                            _.find(places.bizLocation, ['gln', extractLocationClassCode(code[0])])?.name
                                                            + ' - '
                                                            + _.find(places.readPoint, ['sgln', code[0]])?.name
                                                            + ' ('+code[0]+')'
                                                        }
                                                    </Typography>
                                            </Grid>
                                            <Grid item xs={1} container
                                                  direction="row"
                                                  justify="flex-end"
                                                  alignItems="center">
                                                <IconButton aria-label="clear" onClick={() => {handleClear(code[1], code[0])}}>
                                                    <ClearIcon fontSize="small"/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Divider/>
                                    </Box>
                                )) : <FormHelperText error>{t('createEvent.fieldCannotBeEmpty')}</FormHelperText>
                            : null
                    }
                    <Box>
                        {
                            field.input === inputTypes.EXTERNAL.value ?
                                <SourceDestListBuilder
                                    places={places}
                                    field={field}
                                    setSourceDestList={setSourceDestList}
                                    disabled={false}/>
                                :
                                field.children && field.children.length !== 0 ?
                                _.map(field.children, (sourceDestType, index) => (
                                    <SourceDestListBuilder
                                        key={index}
                                        places={places}
                                        sourceDestInputType={sourceDestType}
                                        field={field}
                                        disabled={true}/>))
                                : null
                        }
                    </Box>
                </Box>
            }
        </div>
    )
}

export default SourceDestListComponent