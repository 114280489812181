import React, {useContext, useEffect} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import {MarbleContext} from "../../../contexts";
import {setFilterProductsByLine} from "../marble_reducer";

const LineProductCheckBox = ({ label, color, checkedColor }) => {
    const [state, dispatch] = useContext(MarbleContext)

    useEffect(() => {
        localStorage.setItem(`${state.authContext.userName}_${state.authContext.company_prefix}_lineProductChecked`, state.lineProductChecked)
    }, [state.lineProductChecked])

    const handleChange = (event) => {
        console.log("line change",event.target.checked)
        dispatch(setFilterProductsByLine(event.target.checked))
    };

    const CustomCheckbox = withStyles({
        root: {
            color: color,
            '&$checked': {
                color: checkedColor,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} disabled={!state.filterProducts.length > 0}/>);

    return (
        <FormControlLabel
            control={<CustomCheckbox checked={state.lineProductChecked}
                                     disabled={!state.filterProducts || state.filterProducts.length === 0}
                                     onChange={handleChange}
                                     name="checked"/>}
            label={label}
        />
    )
}

export default LineProductCheckBox
