import React, {useContext, useState} from "react";
import TableConfigurations from "../../components/tables/TableConfigurations";
import useAxios from 'axios-hooks';
import MaterialTable from 'material-table';
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import {RoutingContext} from "../../contexts";

const Configurations = () => {

    const myContext = useContext(RoutingContext);
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        button: {
            marginBottom: "6%",
            color: theme.secondary,
            textTransform: "none"
        },
    }));

    const classes = useStyles();

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const increment = skip * limit

    const [{data: configurations, loading, error}, getExecute] = useAxios({
            url: "/api/configurations/?skip=" + increment + "&limit=" + limit
        },
        {useCache: false}
    )

    const onItemClick = () => {
        myContext.setRoute('AddConfiguration');
    }

    return (
        <div>
            {
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button
                                className={classes.button}
                                onClick={onItemClick}
                                startIcon={<AddIcon/>}>
                                Add New
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            }
            {configurations ? <TableConfigurations configurations={configurations} limit={limit} setLimit={setLimit}
                                                   skip={skip} setSkip={setSkip} updateList={() => {getExecute()}}/> :
                loading ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error ? <p>ERROR</p> : null}
        </div>
    )

}

export default Configurations;
