import React, {useContext, useMemo} from "react";
import {ResponsiveChord} from "@nivo/chord";
import {TableTooltip, BasicTooltip, Chip} from '@nivo/tooltip'
import Box from "@material-ui/core/Box";
import * as d3 from 'd3'
import chroma from "chroma-js";
import {ThemeContext} from "../../contexts";
import {useTranslation} from "react-i18next";

const CustomChordSupplyChain = ({data, companies, handleClickOpen}) => {
    const {t} = useTranslation()
    const {primaryColor, accentColor, secondAccentColor} = useContext(ThemeContext)

    /** Tooltip con hover sull'arco */
    const ArcTooltip = ({arc}) => {
        return (
            <div>
                <BasicTooltip
                    id={`${arc.label}`}
                    value={`${companies[arc.id].relations.length} relationships`}
                    color={arc.color}
                    enableChip={true}
                />
                <Box pl={1} pr={1} style={{backgroundColor: arc.color,
                    color: chroma(arc.color).luminance() < 0.45 ? 'white' : 'black',
                    opacity: '0.7',
                    textAlign: 'center'}}>
                    {t('relationshipsGraph.allRelationshipsDetails')}
                </Box>
            </div>)
    }

    /** Tooltip con hover sul nastro */
    const RibbonTooltip = ({ribbon}) => {
        return (
            <div>
                <TableTooltip
                    rows={[
                        [
                            <Chip key="chip" color={ribbon.source.color}/>,
                            //'Source: ',
                            <strong key="id">{ribbon.source.label}</strong>,
                            //ribbon.source.value,
                        ],
                        [
                            <Chip key="chip" color={ribbon.target.color}/>,
                            //'Destination: ',
                            <strong key="id">{ribbon.target.label}</strong>,
                            //ribbon.target.value,
                        ],
                    ]}
                />
                <Box pl={1} pr={1} style={{
                    backgroundColor: ribbon.source.color,
                    color: chroma(ribbon.source.color).luminance() < 0.45 ? 'white' : 'black',
                    opacity: '0.7',
                    textAlign: 'center'}}>
                    {t('relationshipsGraph.relationshipsDetails')}
                </Box>
            </div>)
    }

    const [companiesPrefixes, colorScale] = useMemo(() => {
        if(!companies)
            return [[], []]

        let res = []
        Object.keys(companies).forEach((company) => {
            res.push(company)
        })

        const _colorScale = chroma.scale([accentColor, chroma(secondAccentColor).alpha(1).hex(), primaryColor]).padding(-0.1).colors(Object.keys(companies).length)
        return [res, _colorScale]
    }, [companies])

    return (
        <ResponsiveChord
            matrix={data}
            keys={companiesPrefixes}
            margin={{top: 220, right: 200, bottom: 220, left: 200}} // se enableLabel={true}
            valueFormat=".2f"
            padAngle={0.03} //0.06
            innerRadiusRatio={0.92} //0.97
            innerRadiusOffset={0.00} //0.02
            arcOpacity={1}
            arcBorderWidth={0}
            //arcBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.5 ] ] }}
            ribbonOpacity={0.5}
            ribbonBorderWidth={0.5}
            //ribbonBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.5 ] ] }}
            enableLabel={true}
            label={(d) => {
                return companies[d.id].name
            }}
            labelOffset={15}
            labelRotation={-90}
            labelTextColor={{from: 'color', modifiers: [['darker', 1.3]]}}
            colors={colorScale}
            isInteractive={true}

            onArcClick={(arc) => {
                handleClickOpen(arc, true)
            }}
            onArcMouseEnter={(e) => {
                d3.selectAll('g').attr('cursor', 'pointer')
            }}

            onRibbonClick={(ribbon) => {
                handleClickOpen(ribbon, false)
            }}
            arcTooltip={ArcTooltip}
            ribbonTooltip={RibbonTooltip}
            arcHoverOpacity={1}
            arcHoverOthersOpacity={0.2}
            ribbonHoverOpacity={0.8}
            ribbonHoverOthersOpacity={0.2}
            animate={true}
            motionStiffness={90}
            motionDamping={7}
            /** legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 250,
                    translateY: 0,
                    itemWidth: 150,
                    itemHeight: 0,
                    itemsSpacing: 35,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    symbolSize: 20,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            attr: {
                              cursor: 'pointer'
                            },
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}*/
        />
    )
}

export default CustomChordSupplyChain
