import React, {useContext} from 'react';
import MaterialTable from 'material-table';
import {useWindowSize} from "../../utils/Utils"
import {availableProductCodeTypes, findCodeTypeAndValue} from "../../utils/CodesUtils"
import {ListItem} from "@material-ui/core";
import {RoutingContext, ThemeContext} from "../../contexts";
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import TagController from "../../components/tag_management/TagController";
import Chip from "@material-ui/core/Chip";
import _ from 'lodash'
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {langResources} from "../../i18n/i18next";
import {useTranslation} from "react-i18next";

const MaterialTableDemo = ({products, limit, setLimit, skip, setSkip, setOrderBy, setDirection}) =>  {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        typography: {
            display: 'inline-block',
            backgroundColor: '#f6f6f6',
            padding: '2.5%',
            borderRadius: '8px',
            marginRight: '2%',
            marginTop: '2%'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
        avatar: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        }
    }));
    const classes = useStyles();

    const size = useWindowSize();
    const myContext = useContext(RoutingContext);
    let history = useHistory();

    const CustomNameField = ({src, name}) => {

        const _src = "/assets/product.png";

        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar
                        className={classes.avatar}
                        //alt="product_image"
                        src={src ? "api/uploads/uploads/" + src : _src}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                    //secondary={brand.active ? 'Active Domain' : null}
                />
            </ListItem>
        )
    };

    const CustomCodeField = ({code, originalCodeType, producer}) => {
        const [type, value] = findCodeTypeAndValue(code)
        return(
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <Chip
                    size={'small'}
                    className={classes.codeTypeChip}
                    label={type.label}
                />
                {
                    _.find(availableProductCodeTypes, ['name', originalCodeType]) === availableProductCodeTypes.INTERNAL
                        /*&& producer !== activeMainDomain*/ ?
                        <Chip
                            size={'small'}
                            className={classes.codeTypeChip}
                            label={producer}
                        /> : null
                }
                {value}
            </div>
        )
    }

    let columns = [
        { title: t('entities.name'), field: 'name' , render: rowData => <CustomNameField src={rowData.image} name={rowData.name}/>}
    ]

    if(size.width>300){
        columns = [
            { customSort: () => {}, title: _.startCase(t('entities.name')), field: 'name' , render: rowData => <CustomNameField src={rowData.image} name={rowData.name}/>},
            { customSort: () => {}, title: _.startCase(t('entities.product.productClassCode')), sorting: false, field: 'key' , render: rowData =>  <CustomCodeField code={rowData.key} originalCodeType={rowData.original_key_type}  producer={rowData.producer}/>},
        ]
    }
    if(size.width>600){
       columns = [
            { customSort: () => {}, title: _.startCase(t('entities.name')), field: 'name', render: rowData => <CustomNameField src={rowData.image} name={rowData.name}/>},
            { customSort: () => {}, title: _.startCase(t('entities.product.productClassCode')), sorting: false, field: 'key' , render: rowData => <CustomCodeField code={rowData.key} originalCodeType={rowData.original_key_type} producer={rowData.producer}/>},
            { customSort: () => {}, title: _.startCase(t('entities.description')) , sorting: false, field: 'description' },
           { customSort: () => {}, title: 'Tags' , sorting: false, field: 'tags', disableClick: true, render: rowData => <TagController selected={false} product={rowData} value={rowData.key} editable={true}/>},
        ]
    }

    const onRowClick = (event, rowData) => {
        //console.log('history --->', history.location.pathname)
        history.location.pathname === '/OtherItems' ? myContext.setRoute('ProductDetailOther', rowData) : myContext.setRoute('ProductDetail', rowData);
    }

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const handleOrderChange = (orderedColumnId, orderDirection) => {
        if (orderedColumnId !== -1) {
            setOrderBy(columns[orderedColumnId].field)
            orderDirection === 'desc' ? setDirection(-1) : setDirection(1)
        }
    };

    const localizedTable = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable

    return (
        <div className={classes.root} style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                localization={localizedTable}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: t('actions.edit', {what: t('entities.product.label')}),
                        onClick: (event, rowData) =>
                            myContext.setRoute('AddItem',
                                {...rowData, edit: true, other: history.location.pathname === '/OtherItems'})
                    },
                ]}
                onRowClick={onRowClick}
                data={products.data}
                options={{
                    headerStyle: {
                        zIndex:0
                    },
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    padding: 'dense',
                    actionsColumnIndex: -1,
                }}
                totalCount={products.count}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onOrderChange={handleOrderChange}
                page={skip}
            />
        </div>
    );
}

export default MaterialTableDemo
