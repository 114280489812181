import React, {useContext, useState} from "react";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Collapse} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import useAxios from "axios-hooks";
import CustomLoader from "../../../components/CustomLoader";
import Typography from "@material-ui/core/Typography";
import _ from 'lodash'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";

const PrecedenceComponent = ({handleChange, formDataFieldName, formDataFieldValue, formLabel}) => {
    const {t} = useTranslation()
    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        epcArea: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: _theme.accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: _theme.accentColor,
                },
            },
            backgroundColor: '#f6f6f6',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
        },
        radioGroup: {
            justifyContent: 'center',
            flexDirection: 'row'
        }
    }))
    const classes = useStyles()

    const [{data: availableEventsName, loading: loadingEventsName, error: errorEventsName}] = useAxios({
            url: "api/capture/event_templates/latest_versions/names/"
        },
        {useCache: false}
    );

    const eventContext = useContext(CreateEventContext);

    const whyTypes = [t('cbvValues.eventName.label', 'Event Name'), t('cbvValues.bizStep.name', 'Business Step')]
    const [state, setState] = useState({
        radioValue: !!formDataFieldValue,
        selectValue: whyTypes[0]
    })
    const handleRadioChange = (event) => {
        const toBool = event.target.value === 'true'
        !toBool && handleChange(formDataFieldName, null)
        setState({...state, radioValue: toBool})
    };

    const handleSelectChange = (event) => {
        handleChange(formDataFieldName, null)
        setState({...state, selectValue: event.target.value})
    };

    return <Box p={2} className={classes.epcArea}>
        {
            availableEventsName ?
                <FormControl>
                    <FormLabel>
                        {formLabel}
                    </FormLabel>
                    <RadioGroup
                        className={classes.radioGroup}
                        name={formDataFieldName + "RadioGroup"}
                        value={state.radioValue}
                        onChange={handleRadioChange}>

                        <FormControlLabel value={true} control={<Radio color={"default"}/>}
                                          label={t('actions.yes', 'Yes')}/>
                        <FormControlLabel value={false}
                                          control={<Radio color={"default"}/>}
                                          label={t('actions.no', 'No')}/>
                    </RadioGroup>

                    <Collapse in={state.radioValue}>
                        <Box p={2} style={{display:'flex', alignItems: 'flex-end'}}>
                            <Select
                                style={{flex:1, marginRight: '4%'}}
                                value={state.selectValue}
                                onChange={handleSelectChange}
                                displayEmpty>
                                {
                                    whyTypes.map(
                                        (type) => (
                                            <MenuItem value={type} key={type}>{type}</MenuItem>)
                                    )
                                }
                            </Select>
                            <Autocomplete
                                style={{flex:4}}
                                options={state.selectValue === whyTypes[0] ? availableEventsName : eventContext.cbvValues.biz_step}
                                getOptionLabel={(option) =>  _.startCase(state.selectValue === whyTypes[0] ? option : _.replace(option,'urn:epcglobal:cbv:bizstep:',''))}
                                renderInput={(params) =>
                                    <TextField {...params} label={t('createEventModel.what.precedencePlaceholder', "Choose the previously recorded event type")} variant="outlined"/>}
                                value={formDataFieldValue ? formDataFieldValue : null}
                                onChange={(event, newValue) => {
                                    handleChange(formDataFieldName, newValue)
                                }}
                            />
                        </Box>
                    </Collapse>
                </FormControl>
                : loadingEventsName ? <CustomLoader size={80} />
                : errorEventsName ? <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.genericServerError')} {JSON.stringify(errorEventsName)}</Typography>
                    : null
        }
    </Box>
}

export default PrecedenceComponent
