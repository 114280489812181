import React, {useContext} from 'react';
import {getBezierPath, getEdgeCenter, getMarkerEnd} from 'react-flow-renderer';
import './configurator.css'
import {Tooltip} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {RulesConfiguratorContext} from "../../contexts";
import {Delete} from "@material-ui/icons";

const foreignObjectSize = 40;

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, data, arrowHeadType, markerEndId,}) => {
    const {t} = useTranslation()
    const [confContext,] = useContext(RulesConfiguratorContext)

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition
    });

    const onDeleteEdgeClick = (evt, id) => {
        evt.stopPropagation();

        console.log("DELETE:", id)
        confContext.removeElement([{id: id}])
        //alert(`remove ${id}`);
    };

    return (
        <>
            <path
                id={id}
                style={{
                    strokeWidth: '3px',
                    fill: 'none',
                    cursor: 'pointer'
                }}
                //className="custom-edge-path"
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            {<foreignObject
                width={foreignObjectSize*3}
                height={foreignObjectSize*3}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="customEdgeButton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml">
                <div style={{background: 'transparent'}}>
                    <button
                        className="customEdgeButton"
                        onClick={(event) => onDeleteEdgeClick(event, id)}>
                        <Tooltip arrow title={t('actions.delete')}><Delete fontSize={'large'}/></Tooltip>
                    </button>
                </div>
            </foreignObject>}
        </>
    );
}

export default CustomEdge
