import React, {useContext, useState} from "react";
import {Paper, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import _ from 'lodash'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FourWeventsDetailRow from "./FourWeventsDetailRow";
import MasterDataDetailRow from "./MasterDataDetailRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import * as PropTypes from "prop-types";
import {dataTypes} from "./SupplyChainGraph";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import {AuthContext} from "../../contexts";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: 'auto',
    },
}));

function TablePaginationActions(props) {
    const {t} = useTranslation()
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={t('entities.localizedTable.pagination.firstAriaLabel')}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={t('entities.localizedTable.pagination.previousAriaLabel')}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t('entities.localizedTable.pagination.nextAriaLabel')}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t('entities.localizedTable.pagination.lastAriaLabel')}
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const RelationshipsTable = ({ruleType, relations, companies}) => {
    const { t } = useTranslation();
    const {activeMainDomain} = useContext(AuthContext)

    const [{data: products, loading: loadinProd, error: errorProd}] = useAxios({
            url: "/api/items/?domain="+activeMainDomain+"&producer=true&not_producer=true"
        },
        {useCache: false}
    )

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, relations.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let allLabel = t('entities.all')
    let rowsPerPageLabel = _.startCase(t('entities.localizedTable.pagination.labelRowsPerPage'))
    return (
        <Box mt={1}>
            {products ?
                <TableContainer component={(props) => <Paper {...props} elevation={0}/>}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={"right"}>{_.startCase(t('entities.name'))}</TableCell>
                            <TableCell>{_.startCase(t('entities.source'))}</TableCell>
                            <TableCell>{_.startCase(t('entities.destination'))}</TableCell>
                            <TableCell>{_.startCase(t('relationshipsGraph.active_female'))}</TableCell>
                            <TableCell>{_.startCase(t('entities.chain_plural'))}</TableCell>
                            <TableCell>{_.startCase(t('entities.type'))}</TableCell>
                            <TableCell>{_.startCase(t('entities.rule_plural'))}</TableCell>
                            <TableCell>{_.startCase(t('entities.editable'))}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (rowsPerPage > 0
                                    ? relations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : relations
                            ).map((relation) => (
                                ruleType === dataTypes[0] ?
                                    <FourWeventsDetailRow key={relation._id} relation={relation} companies={companies}
                                                          products={products}/>
                                    :
                                    ruleType === dataTypes[1] ?
                                        <MasterDataDetailRow key={relation._id} relation={relation}
                                                             companies={companies}
                                                             products={products}/>
                                        : null
                            ))
                        }
                        {relations.length === 0 && (
                            <TableRow style={{height: 53 * emptyRows}}>
                                <TableCell colSpan={8}>
                                    <Typography align={'center'} style={{color: 'darkgrey'}}>
                                        {t('errors.noDataToDisplay')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, {label: allLabel, value: -1}]}
                                colSpan={8}
                                count={relations.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage={rowsPerPageLabel}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer> : null}
            {loadinProd ? <CustomLoader size={80}/> : null}
            {errorProd ? <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography> : null}
        </Box>
    );
}

export default RelationshipsTable
