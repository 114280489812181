import React, {useContext, useState} from 'react';
import MaterialTable from 'material-table';
import {RoutingContext} from "../../contexts";
import RevokeCertificateDialog from "../RevokeCertificateDialog";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const MaterialTableDemo = ({certificates, limit, setLimit, skip, setSkip, updateList}) =>  {

    const myContext = useContext(RoutingContext);

    const CustomRevokedField = ({revoked}) => {
        if (revoked)
            return (
                <div>Yes</div>
            )
        return <div>No</div>
    };

    let columns = [
            { title: 'Serial Number', field: '_id'},
            { title: 'Common Name', field: 'common_name'},
            { title: 'Revoked', field: 'revoked', render: rowData => <CustomRevokedField revoked={rowData.revoked} />},
        ]

    const onRowClick = (event, rowData) => {
        myContext.setRoute('CertificateDetail', rowData);
    }

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const [open, setOpen] = useState(false);

    const [id, setCertificateId] = useState("")

    return (
        <div style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                data={certificates.data}
                onRowClick={onRowClick}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: () => <HighlightOffIcon/>,
                        tooltip: 'Revoke Certificate',
                        onClick: (event, rowData) => {
                            setCertificateId(rowData._id)
                            setOpen(true)
                        },
                    }
                ]}
                totalCount={certificates.count}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={skip}
            />
            <RevokeCertificateDialog certificateId={id} open={open} onClose={() => {setOpen(false)}} afterDelete={updateList}/>
        </div>
    );
}

export default MaterialTableDemo
