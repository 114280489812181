import {makeStyles} from "@material-ui/core/styles";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import React, {useContext} from "react";
import {ThemeContext} from "../contexts";
import _ from 'lodash'
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import {useTranslation} from "react-i18next";

const BrandGroupedAvatars = ({brands, handleClickOpenDialog}) => {
    const { t } = useTranslation();

    const _theme = useContext(ThemeContext)
    const StyledBadge = withStyles((theme) => ({
        badge: {
            backgroundColor: '#44b700',
            color: '#44b700',
            position: 'absolute',
            top: '25',
            left: '25',
            boxShadow: `0 0 0 3px ${_theme.primaryColor}`,
                '&::after': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    //animation: '$ripple 1.2s infinite ease-in-out',
                    border: '1px solid currentColor',
                    content: '""',
                },
            },
    }))(Badge);
    const useStyles = makeStyles((theme) => ({
        root: {
            '&.MuiAvatarGroup-avatar:first-child' : {
                border: '3px solid red',
            }
        },
        avatarGroup: {
            marginLeft: '-1%',
        },
        genericAvatar: {
            border: '0px solid'+_theme.primaryColor,
            fontSize: 'small',
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        smallAvatar: {
            border: '4px solid' + _theme.primaryColor,
            cursor: 'pointer',
            fontSize: 'small',
        },
        selectedAvatar: {
            border: '0px solid'+_theme.primaryColor,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: _theme.primaryColor,
            backgroundColor: _theme.accentColor,
            cursor: 'auto',
            fontSize: 'small',
        }
    }))
    const classes = useStyles()

    //console.log("BrandGroupedAvatars --> brands:", brands)
    return (
        <AvatarGroup className={classes.avatarGroup} max={6} classes={{avatar: classes.genericAvatar}}>
            {
                _.orderBy(brands, ['active'], ['desc']).map((brand) =>
                    (
                        <Tooltip key={brand.domain} title={brand.active ?
                            brand.name : t('actions.switchTo') +" "+ brand.name} arrow>
                            {
                                brand.active ?
                                    <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant="dot"
                                    >
                                        <Avatar
                                            //alt={brand.name}
                                            src={(brand.image || brand.img) ? "api/uploads/uploads/" + (brand.image || brand.img) : null}
                                            className={classes.selectedAvatar}>
                                            {
                                                !brand.image && !brand.img ?
                                                    _.split(brand.name, ' ').map((part) =>
                                                        (part.charAt(0))) : null
                                            }
                                        </Avatar>
                                    </StyledBadge>
                                    : <Avatar
                                        //alt={brand.name}
                                        onClick={() => handleClickOpenDialog(brand)}
                                        src={(brand.image || brand.img) ? "api/uploads/uploads/" + (brand.image || brand.img) : null}
                                        className={classes.smallAvatar}>
                                        {
                                            !brand.image && !brand.img ?
                                                _.split(brand.name, ' ').map((part) =>
                                                    (part.charAt(0))) : null
                                        }
                                    </Avatar>
                            }

                        </Tooltip>
                    ))

            }
        </AvatarGroup>
    );
}

export default BrandGroupedAvatars
