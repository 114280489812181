import React, {useContext, useEffect, useState} from "react";
import {Box, Divider} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {CreateEventContext, ThemeContext} from "../../contexts";
import Typography from "@material-ui/core/Typography";
import {inputTypes} from "../../utils/CbvUtils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {splitCbvValues, eventFieldNameTypes} from "../../utils/CbvUtils";
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import _ from 'lodash'
import FieldInfo from "./FieldInfo";
import i18next from "../../i18n/i18next";

const EventWhyComponent = ({templateData}) => {
    const eventContext = useContext(CreateEventContext)
    const {primaryColor} = useContext(ThemeContext)

    const [cbvDescription, setCbvDescription] = useState({
        disposition: {label: eventFieldNameTypes.DISPOSITION.name, value: null},
        bizStep: {label: eventFieldNameTypes.BIZ_STEP.name, value: null}
    })
    const [dispValue, setDispValue] = useState(eventContext.getFormField('why', eventFieldNameTypes.DISPOSITION.name) ?
        eventContext.getFormField('why', eventFieldNameTypes.DISPOSITION.name).value : null)

    useEffect(() => {
        const dispositionField = _.find(templateData.why, ['name', eventFieldNameTypes.DISPOSITION.name])
        if(dispositionField.input === inputTypes.EXTERNAL.value)
            eventContext.setValidationField('why', eventFieldNameTypes.DISPOSITION.name, !dispositionField.optional ? !!dispValue : true)
    }, [dispValue])

    useEffect(() => {
        const disposition = _.find(templateData.why, ['name', eventFieldNameTypes.DISPOSITION.name])
        const bizStep = _.find(templateData.why, ['name', eventFieldNameTypes.BIZ_STEP.name])
        if (disposition && disposition.value && bizStep && bizStep.value) {
            //(async () => {
                const _descrBiz = i18next.t(`cbvValues.bizStepTypes.${_.camelCase(splitCbvValues(bizStep.value))}`)
                const _descrDisp = i18next.t(`cbvValues.dispositionTypes.${_.camelCase(splitCbvValues(disposition.value))}`)

                //const _descrDisp = await fetchCbvInfoDescription('disposition', disposition.value)
                //const _descrBiz = await fetchCbvInfoDescription('biz_step', bizStep.value)
                setCbvDescription({
                    disposition: {...cbvDescription.disposition, value: _descrDisp},
                    bizStep: {...cbvDescription.bizStep, value: _descrBiz}
                })
           // })()
        } else if (bizStep && bizStep.value) {
            //(async () => {
            const _descrBiz = i18next.t(`cbvValues.bizStepTypes.${_.camelCase(splitCbvValues(bizStep.value))}`)
            //const _descrBiz = await fetchCbvInfoDescription('biz_step', bizStep.value)
                setCbvDescription({
                    ...cbvDescription,
                    bizStep: {...cbvDescription.bizStep, value: _descrBiz}
                })
            //})()
        }
    }, [])

    /**
     * OLD VERSION
     * const DetailCbvValue = ({value, description}) => {
        //console.log("DetailCbvValue ---> value:",value)
        //console.log("DetailCbvValue ---> description:",description)
        const {primaryColor, primaryColorAlpha, backgroundColor} = useContext(ThemeContext)

        return (
            <Box>
                {
                    value ?
                        <Box p={2} pt={0}>
                            <Accordion style={{backgroundColor: backgroundColor, color: primaryColor}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{color: primaryColor}}/>}
                                >
                                    <Typography className={classes.heading}>{i18next.t('entities.moreInfo')} - {splitCbvValues(value)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box> : null
                }
            </Box>
        )
    }*/

    return (
        <div>
            {
                eventContext.cbvValues ?
                    <Box pb={2}>
                        <Box p={2} style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-evenly',
                            alignItems: 'baseline'
                        }}>
                            {
                                _.orderBy(templateData.why, ['name'], ['asc']).map((field, index) => (
                                    <div key={index} style={{
                                        paddingRight: '4%',
                                        marginBottom: '4%',
                                        flex: field.input === inputTypes.EXTERNAL.value ? 2 : 1
                                    }}>
                                        <FieldInfo name={_.find(eventFieldNameTypes, ['name', field.name]).label}
                                                   optional={field.optional}
                                                   external={field.input === inputTypes.EXTERNAL.value}
                                                   description={field.name !== eventFieldNameTypes.ACTION.name ?
                                                       field.value ? `${splitCbvValues(field.value)}: `
                                                           + `${_.find(cbvDescription, ['label', field.name]) ?
                                                               _.find(cbvDescription, ['label', field.name]).value : null}` : `` : null}/>
                                        {
                                            field.name !== eventFieldNameTypes.DISPOSITION.name ?
                                                <Box pt={1} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                                    {
                                                        field.name === eventFieldNameTypes.BIZ_STEP.name ?
                                                            <BizStepIcon style={{marginRight: '5%'}}
                                                                         bizstep={field.value}
                                                                         color={primaryColor}/> : null
                                                    }
                                                    <Typography
                                                        style={field.name === eventFieldNameTypes.BIZ_STEP.name ? {marginLeft: '4%'} : {}}>{field.value ? splitCbvValues(field.value) : null}</Typography>
                                                </Box> :
                                                <Box pt={1} style={{display: 'flex', alignItems: 'center'}}>
                                                    {
                                                        field.name === eventFieldNameTypes.DISPOSITION.name && field.input === inputTypes.EXTERNAL.value ?
                                                            <Autocomplete
                                                                fullWidth
                                                                size={'small'}
                                                                value={dispValue}
                                                                options={eventContext.cbvValues.disposition}
                                                                getOptionLabel={(option) => option ? splitCbvValues(option) : ''}
                                                                renderInput={(params) =>
                                                                    <TextField {...params}
                                                                               size={'small'}
                                                                               error={!field.optional ? !dispValue : false}
                                                                               label="Disposition"
                                                                               helperText={i18next.t('createEvent.chooseDispositionInRelationToBusinessStep', 'Choose the disposition in relation to the Business Step')}
                                                                               variant="outlined"/>}
                                                                onChange={(event, newValue) => {
                                                                    //console.log("onChange --> newValue:", newValue)
                                                                    //fetchCbvInfoDescription(eventFieldNameTypes.DISPOSITION.name, newValue)
                                                                        //.then(r =>
                                                                    const descr = i18next.t(`cbvValues.dispositionTypes.${_.camelCase(splitCbvValues(newValue))}`)

                                                                    setCbvDescription({
                                                                            ...cbvDescription,
                                                                            disposition: {
                                                                                ...cbvDescription.disposition,
                                                                                value: descr
                                                                            }
                                                                        })
                                                                        //)
                                                                    eventContext.setFormField('why', field.name, newValue)
                                                                    setDispValue(newValue)
                                                                }}
                                                            />
                                                            : <Typography
                                                                style={field.name === eventFieldNameTypes.BIZ_STEP.name ? {marginLeft: '4%'} : {}}>{field.value ? splitCbvValues(field.value) : null}</Typography>
                                                    }
                                                </Box>
                                        }
                                    </div>
                                ))
                            }
                            {/*<DetailCbvValue value={'bizStep'}
                                             description={_.find(cbvDescription, ['label', 'bizStep']).value}/>
                                <DetailCbvValue value={'disposition'}
                                description={_.find(cbvDescription, ['label', 'disposition']).value}/>*/}
                        </Box>
                        <Divider/>
                    </Box> : null
            }
        </div>
    )
}

export default EventWhyComponent