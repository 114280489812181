import React, {useRef} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Box, Typography, Button} from "@material-ui/core";
import Summarylabel from "../../components/SummaryLabel";
import Divider from '@material-ui/core/Divider';
import Header from "../../components/Header";
import {makeStyles} from "@material-ui/core/styles";

const CertificateDetail = (props) =>  {

    const useStyles = makeStyles((theme) => ({
        button: {
            textTransform: "none",
            marginTop: "1%"
        }
    }));

    const classes = useStyles();

    const textAreaRef = useRef(null);
    const data = props.location.state;

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    }

    const RevokedText = (props) => {
        const revoked = props.isRevoked;
        if (revoked) {
            return <text style={{color: "red"}}>This certificate is revoked</text>;
        }
        return <noscript/>
    }

    return (
        <Box>
            <Paper>
                <Header title=''/>
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <Box p={4} pt={1}>
                            <Box pl={2} pr={2}>
                                <Summarylabel title='Serial Number' value={data._id}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title='Common Name' value={data.common_name}/>
                                <Divider light variant="middle"/>
                            </Box>
                            <Box p={2}>
                                <Box pb={2}>
                                    <Typography>Certificate</Typography>
                                </Box>
                                <Box>
                                    <RevokedText isRevoked={Boolean(data.revoked)}/>
                                </Box>
                                <Box>
                                    <textarea style={{resize: "none"}} ref={textAreaRef} readOnly={true} cols={64} rows={20} value={JSON.stringify(data.certificate)}/>
                                </Box>
                                <Box style={{marginTop: 2}}>
                                    <Button color="default" variant="contained" className={classes.button} onClick={copyToClipboard}>Copy to clipboard</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default CertificateDetail