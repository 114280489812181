import React from "react";
import {Box, Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import WhatIcon from "@material-ui/icons/LocalOfferOutlined";
import WhenIcon from "@material-ui/icons/DateRangeOutlined";
import WhereIcon from "@material-ui/icons/ExploreOutlined";
import WhyIcon from "@material-ui/icons/MyLocationOutlined";
import StorageIcon from '@material-ui/icons/Storage';
import WDetails from "./WDetails";
import shortid from "shortid";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderRadius: '10px',
        verticalAlign: 'middle',
        alignItems: "center",
        margin: 'auto',
        marginBottom: '3%',
    },
    wTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        padding: '2%',
        color: 'white',
        textAlign: 'center',
        fontSize: 'large',
        lineBreak: 'anywhere'
    },
    wDetails: {
        padding: '2% 4% 0 4%',
    },
    tag: {
        display: 'inline-block',
        backgroundColor: "lightgray",
        padding: '1.5%',
        borderRadius: '6px',
        marginRight: '2%',
    },
}));

/***
 * Alternativa di visualizzazione: (più vertical)
 *  <Paper item xs={3} style={{backgroundColor:bgColor}} className={classes.wTitle}>
 <Typography variant={'h5'}>{title} </Typography>
 </Paper>
 */

const Header = ({title}) => {
    return (
        <Box>
            <Typography variant={'h6'} align={"center"}>{title}</Typography>
        </Box>
    )
}

const WTable = ({title, bgColor, wData}) => {
    const classes = useStyles();
    //console.log("WTable ---> wData: ", wData)
    return (
        <Paper className={classes.root}>
            <Grid container alignContent={"center"} justify={'space-between'}>
                <Grid item xs={2} style={{backgroundColor: bgColor}} className={classes.wTitle}>
                    {title === "What" ?
                        <WhatIcon/>
                        : title === "When" ?
                            <WhenIcon/>
                            : title === "Why" ?
                                <WhyIcon/>
                                : title === "Where" ?
                                    <WhereIcon/>
                                    : <StorageIcon/> // ILMD icon
                    }
                    <Header title={title}/>
                </Grid>
                <Grid item xs={10} className={classes.wDetails}>
                    {title === "What" ?
                        <WDetails key={shortid.generate()} wData={wData.what} className={classes.wDetails}/>
                        : title === "When" ?
                            <WDetails key={shortid.generate()} wData={wData.when} className={classes.wDetails}/>
                            : title === "Why" ?
                                <WDetails key={shortid.generate()} wData={wData.why} className={classes.wDetails}/>
                                : title === "Where" ?
                                    <WDetails key={shortid.generate()} wData={wData.where}
                                              className={classes.wDetails}/>
                                    :
                                    <WDetails key={shortid.generate()} wData={wData.ilmd} className={classes.wDetails}/>

                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default WTable;
