import React, {useContext, useEffect, useState} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Box, Divider, Drawer, Hidden, IconButton, Toolbar, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Link, useHistory, useLocation} from "react-router-dom";
import {AuthContext, RoutingContext, ThemeContext} from "../contexts";
import {drawerSections, GetIconComponent, setLayoutTitle} from "../utils/LayoutUtils"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import Avatar from '@material-ui/core/Avatar';
import EventIcon from '@material-ui/icons/Event';
import {MonitoringIcon, SupplyChainIcon} from "../components/CustomIcons";
import chroma from 'chroma-js';
import Paper from "@material-ui/core/Paper";
import LoggedUserComponent from "./LoggedUserComponent";
import ActiveBrandSelection from "./ActiveBrandSelection";
import DevicesIcon from '@material-ui/icons/Devices';
import {useTranslation} from "react-i18next";
import {Timeline} from "@material-ui/icons";

function ResponsiveDrawer({children}) {
    const { t } = useTranslation();

    const { primaryColor, secondaryColor } = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        appBar: {
            backgroundColor: primaryColor,
            color: secondaryColor,
            display: 'inline'
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
            //backgroundColor: '#fafafa'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        footer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex:1,
            width: drawerWidth
        },
        pageTitle: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: theme.typography.fontWeightRegular,
            color: secondaryColor
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
            color: primaryColor,
        },
        headingSelected: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
            color: secondaryColor,
        },
        avatar: {
            backgroundColor: 'white'
        },
        iconButton: {
            marginLeft: '5%',
            backgroundColor: primaryColor,
            color: 'white',
            "&:hover": {
                backgroundColor: chroma('white').alpha(0.6).hex(),
            }
        },
        icon: {
            color: primaryColor,
        },
        subItem: {
            textAlign: 'end',
            color: primaryColor,
            '&:hover': {
                color: primaryColor,
            },
        },
        subItemSelected: {
            textAlign: 'end',
            color: secondaryColor,
        },
        selected: {
            backgroundColor: primaryColor,
            color: 'white',
            '&:hover': {
                backgroundColor: primaryColor,
            },
        },
        expand: {
            color: primaryColor,
        }
    }));

    const drawerWidth = 250;
    const [state, setState] = useState({
        mobileOpen: false,
        title: '',
        drawerSectionsExp: drawerSections,
        openLogoutDialog: false
    })
    const authContext = useContext(AuthContext);
    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        let welcomeMessage = null
        if(location.pathname === '/')
            welcomeMessage = `${t('appBar.welcomeTitle', { who: authContext.name ? authContext.name : authContext.userName })}!`
        const obj = setLayoutTitle(location, authContext, welcomeMessage)
        setState({...state, title: obj.title, drawerSectionsExp: obj.drawerExp})
    }, [location])

    const handleDrawerToggle = () => {
        setState({...state, mobileOpen: !state.mobileOpen})
    };

    const setRoute = (route, data) => {
        data ? history.push('/' + route, data) : history.push('/' + route)
    }

    const classes = useStyles();

    const onChange = (name) => (e, value) => {
        setState({...state, drawerSectionsExp: {...state.drawerSectionsExp, [name]: value}})
    };

    const api = {setRoute: setRoute}

    return (
        <RoutingContext.Provider value={api}>
            <div className={classes.root}>
                <CssBaseline/>
                {
                    authContext.auth ? <div>
                        <AppBar position="fixed" className={classes.appBar}>
                            <Toolbar style={{paddingLeft: 12}}>
                                {<img alt="logo_iChain" src="/assets/logo_iChain_round.png" style={{width: '40px', marginRight: 16}}/>}
                                <IconButton color="inherit" aria-label="open drawer" edge="start"
                                            onClick={handleDrawerToggle}
                                            className={classes.menuButton}>
                                    <MenuIcon/>
                                </IconButton>
                                <Typography className={classes.pageTitle} noWrap>
                                    {state.title}
                                </Typography>
                                <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
                                    <ActiveBrandSelection auth={authContext}/>
                                    <LoggedUserComponent auth={authContext}/>
                                </div>
                            </Toolbar>
                        </AppBar>
                        <nav aria-label="mailbox folders">
                            <Hidden smUp implementation="css">
                                <Drawer variant="temporary" open={state.mobileOpen} onClose={handleDrawerToggle}
                                        classes={{paper: classes.drawerPaper,}}
                                        ModalProps={{keepMounted: true,}}>
                                    <Paper className={classes.toolbar}
                                           style={{display: 'flex',
                                               justifyContent:'center',
                                               alignItems: 'center',
                                               position:'sticky',
                                               top:'0px',
                                               backgroundColor:'white',
                                               zIndex:'100',
                                               borderRadius: '0px'
                                           }}>
                                        <img alt="logo_iChain" src="/assets/logo_iChain.png" style={{width: '70%'}}/>
                                    </Paper>
                                    <List style={{paddingTop: '0px'}}>
                                        <Divider/>
                                        <AccordionSummary
                                            className={location.pathname === '/' ? classes.selected : null}>
                                            <ListItem style={{padding: '0px'}}

                                                      onClick={() => handleDrawerToggle()}
                                                      component={Link} to="/">
                                                <ListItemAvatar>
                                                    <Avatar className={classes.avatar}>
                                                        <DashboardIcon className={classes.icon}/>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText className={location.pathname === "/"
                                                    ? classes.subItemSelected : classes.subItem} style={{textAlign:'start'}}>{t('landingPage.title', 'Homepage')}</ListItemText>
                                            </ListItem>
                                        </AccordionSummary>
                                        <Divider/>
                                        <AccordionSummary
                                            className={location.pathname === '/CreateEvent' ? classes.selected : null}>
                                            <ListItem style={{padding: '0px'}}
                                                      onClick={() => handleDrawerToggle()}
                                                      component={Link} to="/CreateEvent">
                                                <ListItemAvatar>
                                                    <Avatar className={classes.avatar}>
                                                        <GetIconComponent path={'/CreateEvent'}/>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText className={location.pathname === "/CreateEvent"
                                                    ? classes.subItemSelected : classes.subItem} style={{textAlign:'start'}}>{t('createEvent.title')}</ListItemText>
                                            </ListItem>
                                        </AccordionSummary>
                                        <Divider/>
                                        <Accordion expanded={state.drawerSectionsExp.monitoring} onChange={onChange('monitoring')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand}/>}>
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar}>
                                                            <MonitoringIcon className={classes.icon}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText className={classes.heading}>{t('menuSections.monitoring')}</ListItemText>
                                                </ListItem>
                                            </AccordionSummary>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/IncomingEvents"
                                                          className={location.pathname === '/IncomingEvents' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/IncomingEvents' ? classes.subItemSelected : classes.subItem}>
                                                        {t('incomingEvents.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/ProductHistory"
                                                          className={location.pathname === '/ProductHistory' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/ProductHistory' ? classes.subItemSelected : classes.subItem}>
                                                        {t('productHistory.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            {/** KPI Analytics
                                             <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/KpiAnalytics"
                                                          className={location.pathname === '/KpiAnalytics' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/KpiAnalytics' ? classes.subItemSelected : classes.subItem}>
                                                        {t('kpiPage.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>*/}
                                            {
                                                /** FF - Map Diagram */
                                                //<AccordionDetails style={{padding: '0px'}}>
                                                //    <ListItem button component={Link} to="/MapDiagram"
                                                //              className={location.pathname === '/MapDiagram' ? classes.selected : null}
                                                //    >
                                                //        <ListItemText className={location.pathname === '/MapDiagram' ? classes.subItemSelected : classes.subItem}>Map Diagram</ListItemText>
                                                //    </ListItem>
                                                //</AccordionDetails>
                                            }
                                        </Accordion>
                                        <Accordion expanded={state.drawerSectionsExp.supplyChain} onChange={onChange('supplyChain')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand}/>}
                                            >
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar}>
                                                            <SupplyChainIcon className={classes.icon}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText className={classes.heading}>{t('menuSections.supplyChain')}</ListItemText>
                                                </ListItem>
                                            </AccordionSummary>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/ProductionFlow"
                                                          className={location.pathname === '/ProductionFlow' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/ProductionFlow' ? classes.subItemSelected : classes.subItem}>
                                                        {t('productionFlow.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/RulesConfiguratorProjects"
                                                          className={location.pathname === '/RulesConfiguratorProjects' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/RulesConfiguratorProjects' ? classes.subItemSelected : classes.subItem}>
                                                        {t('rulesConfigurator.title', 'Production Flow Configurator')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/RelationshipsGraph"
                                                          className={location.pathname === '/RelationshipsGraph' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/RelationshipsGraph' ? classes.subItemSelected : classes.subItem}>
                                                        {t('relationshipsGraph.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/CreateRelationship"
                                                          className={location.pathname === '/CreateRelationship' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/CreateRelationship' ? classes.subItemSelected : classes.subItem}>
                                                        {t('createRelationship.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.drawerSectionsExp.dataAnalysis} onChange={onChange('dataAnalysis')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand}/>}
                                            >
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar}>
                                                            <Timeline className={classes.icon}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText className={classes.heading}>{t('menuSections.dataAnalysis')}</ListItemText>
                                                </ListItem>
                                            </AccordionSummary>
                                            {/*
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/Reports"
                                                          className={location.pathname === '/Reports' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/Reports' ? classes.subItemSelected : classes.subItem}>
                                                        {t('reports.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            */}
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/ChemioDataset"
                                                          className={location.pathname === '/ChemioDataset' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/ChemioDataset' ? classes.subItemSelected : classes.subItem}>
                                                        {t('chemioDataset.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/FeedChart"
                                                          className={location.pathname === '/FeedChart' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/FeedChart' ? classes.subItemSelected : classes.subItem}>
                                                        {t('feedChart.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/PoultryChart"
                                                          className={location.pathname === '/PoultryChart' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/PoultryChart' ? classes.subItemSelected : classes.subItem}>
                                                        {t('poultryChart.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/SlaughtersChart"
                                                          className={location.pathname === '/SlaughtersChart' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/SlaughtersChart' ? classes.subItemSelected : classes.subItem}>
                                                        {t('slaughtersChart.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.drawerSectionsExp.eventModels} onChange={onChange('eventModels')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand}/>}
                                            >
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar}>
                                                            <EventIcon className={classes.icon}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText className={classes.heading}>{t('menuSections.eventModels')}</ListItemText>
                                                </ListItem>
                                            </AccordionSummary>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/EventModels"
                                                          className={location.pathname === '/EventModels' || location.pathname === '/EventModelDetail' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/EventModels' || location.pathname === '/EventModelDetail' ? classes.subItemSelected : classes.subItem}>
                                                        {t('eventModels.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/CreateEventTemplate"
                                                          className={location.pathname === '/CreateEventTemplate' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/CreateEventTemplate' ? classes.subItemSelected : classes.subItem}>
                                                        {t('createEventModel.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.drawerSectionsExp.registry} onChange={onChange('registry')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand}/>}
                                            >
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar}>
                                                            <AssignmentIcon className={classes.icon}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText className={classes.heading}>{t('menuSections.registry')}</ListItemText>
                                                </ListItem>
                                            </AccordionSummary>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/MyProfile"
                                                          className={location.pathname === '/MyProfile' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/MyProfile' ? classes.subItemSelected : classes.subItem}>
                                                        {t('profile.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/MyCompany"
                                                          className={location.pathname === '/MyCompany' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/MyCompany' ? classes.subItemSelected : classes.subItem}>
                                                        {t('myCompany.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/Items"
                                                          className={location.pathname === '/Items' || location.pathname === '/AddItem' || location.pathname === '/ProductDetail' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/Items' || location.pathname === '/AddItem' || location.pathname === '/ProductDetail' ? classes.subItemSelected : classes.subItem}>
                                                        {t('myItems.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/MyPlaces"
                                                          className={location.pathname === '/MyPlaces' || location.pathname === '/PlacesDetail' || location.pathname === '/AddPlace' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/MyPlaces' || location.pathname === '/PlacesDetail' || location.pathname === '/AddPlace' ? classes.subItemSelected : classes.subItem}>
                                                        {t('myPlaces.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/MyPartners"
                                                          className={location.pathname === '/MyPartners' || location.pathname === '/CompanyDetail' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/MyPartners' || location.pathname === '/CompanyDetail' ? classes.subItemSelected : classes.subItem}>
                                                        {t('myPartners.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.drawerSectionsExp.partners} onChange={onChange('partners')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand}/>}>
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar}>
                                                            <PeopleIcon className={classes.icon}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText className={classes.heading}>{t('menuSections.partners')}</ListItemText>
                                                </ListItem>
                                            </AccordionSummary>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/Companies"
                                                          className={location.pathname === '/Companies' || location.pathname === '/CompanyDetail' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/Companies' || location.pathname === '/CompanyDetail' ? classes.subItemSelected : classes.subItem}>
                                                        {t('otherCompanies.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/OtherItems"
                                                          className={location.pathname === '/OtherItems' || location.pathname === '/ProductDetailOther' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/OtherItems' || location.pathname === '/ProductDetailOther' ? classes.subItemSelected : classes.subItem}>
                                                        {t('othersItems.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                            <Divider variant="inset"/>
                                            <AccordionDetails style={{padding: '0px'}}>
                                                <ListItem button
                                                          onClick={() => handleDrawerToggle()}
                                                          component={Link} to="/OtherPlaces"
                                                          className={location.pathname === '/OtherPlaces' || location.pathname === '/PlacesDetail' || location.pathname === '/AddPlace' ? classes.selected : null}>
                                                    <ListItemText
                                                        className={location.pathname === '/OtherPlaces' || location.pathname === '/PlacesDetail' || location.pathname === '/AddPlace' ? classes.subItemSelected : classes.subItem}>
                                                        {t('othersPlaces.title')}
                                                    </ListItemText>
                                                </ListItem>
                                            </AccordionDetails>
                                        </Accordion>
                                        {
                                            authContext.isAdmin && process.env.REACT_APP_DEVICE_ADMIN === "1" && <Accordion expanded={state.drawerSectionsExp.deviceAdmin}
                                                                              onChange={onChange('deviceAdmin')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon className={classes.expand}/>}
                                                >
                                                    <ListItem style={{padding: '0px'}}>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.avatar}>
                                                                <DevicesIcon className={classes.icon}/>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText className={classes.heading}>Device
                                                            Admin</ListItemText>
                                                    </ListItem>
                                                </AccordionSummary>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/AuthRequests"
                                                              className={location.pathname === '/AuthRequests' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/AuthRequests' ? classes.subItemSelected : classes.subItem}>Auth
                                                            Requests</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                                <Divider variant="inset"/>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/Devices"
                                                              className={location.pathname === '/Devices' || location.pathname === '/DeviceControlPanel' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/Devices' || location.pathname === '/DeviceControlPanel' ? classes.subItemSelected : classes.subItem}>Devices</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                                <Divider variant="inset"/>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/Certificates"
                                                              className={location.pathname === '/Certificates' || location.pathname === '/CertificateDetail' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/Certificates' || location.pathname === '/CertificateDetail' ? classes.subItemSelected : classes.subItem}>Certificates</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                                <Divider variant="inset"/>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/Revocations"
                                                              className={location.pathname === '/Revocations' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/Revocations' ? classes.subItemSelected : classes.subItem}>Revocations</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                                <Divider variant="inset"/>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/Configurations"
                                                              className={location.pathname === '/Configurations' || location.pathname === '/ConfigurationDetail' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/Configurations' || location.pathname === '/ConfigurationDetail' ? classes.subItemSelected : classes.subItem}>Configurations</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                                <Divider variant="inset"/>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/Apks"
                                                              className={location.pathname === '/Apks' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/Apks' ? classes.subItemSelected : classes.subItem}>Apks</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                                <Divider variant="inset"/>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/ComposeFiles"
                                                              className={location.pathname === '/ComposeFiles' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/ComposeFiles' ? classes.subItemSelected : classes.subItem}>Compose Files</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                                <Divider variant="inset"/>
                                                <AccordionDetails style={{padding: '0px'}}>
                                                    <ListItem button
                                                              onClick={() => handleDrawerToggle()}
                                                              component={Link} to="/DevicesMonitoring"
                                                              className={location.pathname === '/DevicesMonitoring' ? classes.selected : null}>
                                                        <ListItemText
                                                            className={location.pathname === '/DevicesMonitoring' ? classes.subItemSelected : classes.subItem}>Devices
                                                            Monitoring</ListItemText>
                                                    </ListItem>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                    </List>
                                    <Box className={classes.footer} p={3}>
                                        <Typography style={{color:'darkgrey'}}>{t('poweredBy')}</Typography>
                                        <img alt="logo_wise_side" src="/assets/ws_logo_20_ago_2021.png" style={{width: '50%'}}/>
                                    </Box>
                                </Drawer>
                            </Hidden>
                        </nav>
                    </div> : null}
                <main className={classes.content}>
                    <div className={authContext.auth ? classes.toolbar : null}/>
                    {children}
                </main>
            </div>
        </RoutingContext.Provider>
    );
}

export default ResponsiveDrawer
