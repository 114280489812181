import IconButton from "@material-ui/core/IconButton";
import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import axios from "axios";
import UploadImage from "../../components/UploadImage";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";
import {severityTypes} from "../../utils/Utils";

const EditProfileImage = ({myProfile, theme, refetchData}) => {
    const { t } = useTranslation();

    const useStyles = makeStyles({
        dottedBox: {
            border: '2px solid lightGray',
            borderRadius: '15px',
            borderStyle: 'dotted',
        },
        button: {
            backgroundColor: theme.accentColor,
            color: "white",
            fontWeight: 'bold',
            marginTop: '1%',
            marginRight: '1%',
            borderRadius: '20px'
        }
    })
    const classes = useStyles()

    const initialState = {
        open: false,
        image: myProfile.image
    }

    const [state, setState] = useState(initialState)

    const handleClickOpen = () => {
        setState({...state, open: true});
    };
    const handleClose = () => {
        console.log("state:", state)
        setState({...state, open: false});
    };

    const initialErrorState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialErrorState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const updateMyProfile = () => {
        console.log("state:",state)
        axios.patch('/users/' + myProfile._id, {image: state.image || null})
            .then((res) => {
                if (res.status === 200) {
                    refetchData()
                    handleClose()
                }
            })
            .catch((err) => {
                setOpenAlertMessage({
                    open: true,
                    message: "Ops, something went wrong: " + err,
                    severity: severityTypes.ERROR
                })
            })
    }

    const onChangeImage = (id) => {
        console.log("id:", id)
        setState({...state, image: id})
    }

    const imageLabel = t('entities.image');
    const pictureLabel = t('entities.picture');
    return <Zoom in={true}>
        <div>
            <Tooltip title={t('actions.change', {what: pictureLabel})}>
                <IconButton style={{backgroundColor: theme.accentColor}}
                            onClick={handleClickOpen}>
                    <CameraAltIcon style={{color: 'white'}}/>
                </IconButton>
            </Tooltip>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                  autoHideDuration={3000} onClose={handleCloseErrorMessage}>
            <Alert severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
        </Snackbar>
        <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems:'center'}}>
                <Box p={8} className={classes.dottedBox}>
                    <UploadImage value={state.image} onChange={onChangeImage}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} style={{marginTop: '1%', marginRight: '1%'}}>
                    {t('actions.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={updateMyProfile}
                    className={classes.button}>
                    {t('actions.change', {what: imageLabel})}
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    </Zoom>
}

export default EditProfileImage