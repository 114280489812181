import Checkbox from "@material-ui/core/Checkbox";
import React, {useContext, useEffect, useState} from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Box, CircularProgress, Grid} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import {ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import {findCodeTypeAndValue, findLocationCodeTypeValue} from "../../../utils/CodesUtils";
import _ from "lodash";
import i18next from "../../../i18n/i18next";
import useAxios from "axios-hooks";

export const WhiteListComponent = ({options, disabled, multiple, formDataFieldName, formDataFieldValue, formData, handleChange, formLabel}) => {
    const {accentColor, primaryColorAlpha, iconHoverBg} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        icon: {
            color: 'darkgray',
            '&:hover': {
                background: iconHoverBg,
            },
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },

    }));
    const classes = useStyles()

    const [{data: subplaces}, postExecuteSubPlaces] = useAxios({
            url: "/api/subplaces/_by_codes/",
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    const [whiteListedValues, setWhiteListedValues] = useState(formDataFieldValue ? formDataFieldValue : [])

    const icon = <CheckBoxOutlineBlankIcon className={classes.icon} fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon className={classes.icon} fontSize="small"/>;

    useEffect(() => {
        setWhiteListedValues(formDataFieldValue)
    },[formDataFieldValue])

    useEffect(() => {
        if((formDataFieldName === 'whiteListedBizLocations' || formDataFieldName === 'whiteListedReadPoints')
            && formDataFieldValue && formDataFieldValue.length > 0)
            postExecuteSubPlaces({data: {codes: _.uniq(formDataFieldValue)}})
    }, [])

    useEffect(() => {
        if(subplaces) {
            let locs = _.map(formDataFieldValue, (loc) => (_.find(subplaces.data, ['sgln', loc]) || loc))
            locs && locs.length > 0 && setWhiteListedValues(locs)
        }
    }, [subplaces])

    return (
        <Box pl={2} pt={2} style={{flex: '3'}}>
            {
                whiteListedValues ?
                    <Box display={'flex'} justifyContent={'space-evenly'}>
                        <Box flex={4}>
                            <Autocomplete
                                multiple={multiple}
                                disabled={disabled}
                                disableCloseOnSelect
                                clearOnEscape={true}
                                onClose={() => {
                                    handleChange(formDataFieldName, whiteListedValues)
                                }}
                                options={options}
                                groupBy={(option) => option.data_origin||option.producer || null}
                                getOptionLabel={(option) => option.name ?
                                    (option.name+' - '+option.data_origin||option.producer) : (option.address+' - '+option.data_origin)}
                                value={whiteListedValues}
                                onChange={(event, newValue, reason) => {
                                    setWhiteListedValues(newValue)
                                    if (reason === 'remove-option' || reason === 'clear') {
                                        handleChange(formDataFieldName, newValue)
                                    }
                                }}
                                renderOption={(option, {selected}) => {
                                    const [codeType, codeValue, domain] = option ?
                                        option.sgln ? findLocationCodeTypeValue(option.sgln) :
                                            findCodeTypeAndValue(option.key)
                                        : [null, null, null]

                                    return (<React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                                className={classes.icon}
                                            />
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        className={classes.name}>{option.name ? option.name : findLocationCodeTypeValue(option.sgln)[1]}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        {
                                                            codeType ?
                                                                <Chip
                                                                    size={'small'}
                                                                    label={codeType.type ? codeType.type : codeType}
                                                                    className={classes.codeTypeChip}
                                                                /> : null
                                                        }
                                                        {
                                                            domain ?
                                                                <Chip
                                                                    size={'small'}
                                                                    label={domain}
                                                                    className={classes.codeTypeChip}
                                                                /> : null
                                                        }
                                                        <Typography
                                                            className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={(option?.name ? (option.name+' - '+(option.producer||option.data_origin))
                                                : option)}
                                            style={{backgroundColor: primaryColorAlpha, opacity: '0.8'}}
                                            {...getTagProps({index})}
                                        />
                                    ))
                                }
                                renderInput={params => {
                                    const {InputProps, ...restParams} = params;
                                    const {startAdornment, ...restInputProps} = InputProps;
                                    return (
                                        <TextField
                                            {...restParams}
                                            size={'small'}
                                            variant={"outlined"}
                                            placeholder={formLabel}
                                            InputProps={{
                                                ...restInputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {disabled ? <CircularProgress size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>),
                                                startAdornment: (
                                                    <div style={{
                                                        maxHeight: '150px',
                                                        overflowY: 'auto',
                                                    }}
                                                    >
                                                        {startAdornment}
                                                    </div>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                            />
                            {
                                options[0]?.gln ?
                                    <FormHelperText>
                                        {i18next.t('createEventModel.placesWhiteListDescription', 'This field is optional and allows you to specify multiple locations as a suggestion for the user who will generate the event')}
                                    </FormHelperText> : null
                            }
                        </Box>
                    </Box>
                    :
                    <Autocomplete
                        multiple={multiple}
                        disabled={disabled}
                        disableCloseOnSelect
                        clearOnEscape={true}
                        options={options}
                        getOptionLabel={option => option ? option.name : ''}
                        value={whiteListedValues}
                        renderInput={params => {
                            const {InputProps, ...restParams} = params;
                            const {startAdornment, ...restInputProps} = InputProps;
                            return (
                                <TextField
                                    {...restParams}
                                    size={'small'}
                                    variant={"outlined"}
                                    //label={formLabel}
                                    placeholder={formLabel}
                                    InputProps={{
                                        ...restInputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {disabled ? <CircularProgress size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>),
                                        startAdornment: (
                                            <div style={{
                                                maxHeight: '150px',
                                                overflowY: 'auto',
                                            }}
                                            >
                                                {startAdornment}
                                            </div>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />
            }
        </Box>
    )
}
