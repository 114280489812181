import React, {useContext, useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {convertToDomain, parseName, searchProductFromPrefixes} from "../../utils/Utils";
import {splitCbvValues} from "../../utils/CbvUtils";
import {name_types} from "../create_new_relationship/rules_components/WBooleanForm";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import Divider from "@material-ui/core/Divider";
import WhatIcon from '@material-ui/icons/LocalOffer';
import WhenIcon from '@material-ui/icons/DateRange';
import WhereIcon from '@material-ui/icons/Explore';
import WhyIcon from '@material-ui/icons/MyLocation';
import Chip from "@material-ui/core/Chip";
import _ from 'lodash'
import EditIcon from "@material-ui/icons/Edit";
import {AuthContext, RoutingContext, ThemeContext} from "../../contexts";
import {IconButton} from "@material-ui/core";
import chroma from 'chroma-js'
import TagController from "../../components/tag_management/TagController";
import Tooltip from "@material-ui/core/Tooltip";
import useAxios from "axios-hooks";
import {useTranslation} from "react-i18next";
import i18next from "../../i18n/i18next";
import {extractProductClassCode} from "../../utils/CodesUtils";

/*function parseProductPrefixes(prefixes) {
    let res = ''
    prefixes.map((pre) => {
        res += pre + "\n"
    })
    return res;
}*/

const FourWeventsDetailRow = ({relation, companies, products}) => {
    const {t} = useTranslation();

    const {accentColor} = useContext(ThemeContext)
    const useRowStyles = makeStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            }
        },
        expandableRuleTable: {
            '& .MuiTable-root': {
                'borderCollapse': 'none'
            }
        },
        activeIcon: {
            verticalAlign: 'bottom',
            color: chroma(accentColor).alpha(0.7).hex(),
        },
        inactiveIcon: {
            verticalAlign: 'bottom',
            color: 'lightgray',
        },
        icon: {
            color: 'darkgray',
            marginEnd: '15px'
        },
        ruleTableCell: {
            display: 'flex',
            marginBottom: '-1px'
        },
        labelChip: {
            margin: '0.3%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    });
    const classes = useRowStyles();

    const [{data: updatedRelation}, postExecute] = useAxios({
            url: "api/rules/" + relation._id
        },
        {useCache: false, manual: true}
    );

    const _relation = useMemo(() => {
        if (updatedRelation)
            return updatedRelation
        else return relation
    }, [updatedRelation])

    const myContext = useContext(RoutingContext)
    const authContext = useContext(AuthContext)
    const [open, setOpen] = React.useState(false);

    function handleButtonClickEdit(_relation) {
        myContext.setRoute('CreateRelationship',
            {
                ..._relation,
                edit: true,
            })
    }

    const source = convertToDomain(_relation.source)
    const destination = convertToDomain(_relation.destination)
    const editable = source === authContext.activeMainDomain

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell align={"right"} component="th" scope="row">{parseName(_relation.name)}</TableCell>
                <TableCell component="th" scope="row">{companies[source].name}</TableCell>
                <TableCell component="th" scope="row">{companies[destination].name}</TableCell>
                <TableCell align={"center"} component="th" scope="row">
                    {_relation.active ?
                        <Tooltip arrow title={t('relationshipsGraph.active_female')}><NotificationsActiveIcon
                            className={classes.activeIcon} fontSize="small"/></Tooltip>
                        : <Tooltip arrow title={t('relationshipsGraph.inactive_female')}><NotificationsOffIcon
                            className={classes.inactiveIcon} fontSize="small"/></Tooltip>}
                </TableCell>
                <TableCell component="th" scope="row">{<TagController selected={false} relation={_relation}
                                                                      refetch={postExecute}
                                                                      editable={editable}/>}</TableCell>
                {/*<TableCell component="th" scope="row">{<CustomTagField selected={false} tags={_relation.chain}/>}</TableCell>*/}
                <TableCell component="th" scope="row">{parseName(_relation.type)}</TableCell>
                <TableCell component="th" scope="row">
                    <Chip
                        label={_relation.rules && relation.rules.length ? _relation.rules.length : '0'}
                        onClick={() => setOpen(!open)}
                        onDelete={() => setOpen(!open)}
                        deleteIcon={open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    />
                </TableCell>
                <TableCell component="th" scope="row">
                    <IconButton disabled={!editable} onClick={() => {
                        handleButtonClickEdit(_relation)
                    }}>
                        <EditIcon fontSize={'small'} style={{color: !editable ? 'lightGray' : accentColor}}/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 10, paddingTop: 0}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography align={"center"} component="div"
                                        style={{
                                            margin: 10,
                                            fontWeight: 'bold'
                                        }}>{_.startCase(t('entities.rule_plural'))}</Typography>
                            <Divider variant={"fullWidth"}/>
                            <Table size="small">
                                <TableBody className={classes.expandableRuleTable}>
                                    {
                                        _relation.rules && _relation.rules.length ? _relation.rules.map((rule, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{fontWeight: 'bold', fontSize: 'larger'}}>
                                                        {/*<Chip label={`#${index + 1}`}/>*/}
                                                        {`#${index + 1}`}
                                                    </TableCell>
                                                    {rule && _.every(rule, _.isEmpty) &&
                                                    <TableCell component="th" scope="row" className={classes.ruleTableCell}>
                                                        <Typography>{i18next.t('createRelationship.rule.completeObservation', 'Complete Observation')}</Typography>
                                                    </TableCell>}
                                                    {rule.what && !_.isEmpty(rule.what) ?
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.ruleTableCell}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item xs={1}>
                                                                    <WhatIcon fontSize={"small"}
                                                                              className={classes.icon}/>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    {
                                                                        rule.what !== null ? rule.what.name_types ? (
                                                                            rule.what.name_types === name_types.prefix.value ? (
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        flexWrap: 'wrap',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        {_.startCase(t('entities.prefix_plural'))}:
                                                                                        {
                                                                                            rule.what.prefixes.map((prefix, index) =>
                                                                                                <Chip size={"small"}
                                                                                                      className={classes.labelChip}
                                                                                                      key={index}
                                                                                                      label={prefix}/>)
                                                                                        }
                                                                                    </div>)
                                                                                : _.find(name_types, ['value', rule.what.name_types])?.label + " - " + (
                                                                                rule.what.name_types === name_types.product_name.value ?
                                                                                    searchProductFromPrefixes(rule.what.prefixes, products.data) + ' - ' + extractProductClassCode(rule.what.prefixes[0])
                                                                                    :
                                                                                    //parseProductPrefixes(rule.what.prefixes) :
                                                                                    rule.what.name_types === name_types.serial_code_range.value ?
                                                                                        t('entities.interval.from') + ": " + rule.what.serial_range_begin + " \n" + t('entities.interval.to') + ": " + rule.what.serial_range_end : null
                                                                            )
                                                                        ) : null : i18next.t('createRelationship.rule.allProductsAreSelected', 'All products are selected')
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell> : null}
                                                    {rule.biz_step !== null ?
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.ruleTableCell}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item xs={1}>
                                                                    <WhyIcon fontSize={"small"}
                                                                             className={classes.icon}/>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    {"Business step - " + parseName(splitCbvValues(rule.biz_step))}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell> :
                                                        rule.event_name !== null ?
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.ruleTableCell}>
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item xs={1}>
                                                                        <WhyIcon fontSize={"small"}
                                                                                 className={classes.icon}/>
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        {"Event name - " + parseName(rule.event_name)}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell> : null}
                                                    {rule.biz_location !== null ?
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.ruleTableCell}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item xs={1}>
                                                                    <WhereIcon fontSize={"small"}
                                                                               className={classes.icon}/>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    {"Business Location - " + rule.biz_location}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell> :
                                                        rule.read_point !== null ?
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.ruleTableCell}>
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item xs={1}>
                                                                        <WhereIcon fontSize={"small"}
                                                                                   className={classes.icon}/>
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        {"Read point - " + rule.read_point}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            : null}
                                                    {rule.when !== null ?
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.ruleTableCell}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item xs={1}>
                                                                    <WhenIcon fontSize={"small"}
                                                                              className={classes.icon}/>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Grid item>
                                                                        {t('entities.interval.from') + ": " + t("formatters:dateLong", {date: Moment(rule.when.start)})}
                                                                        {/*From {Moment(rule.when.start).format('DD/MM/YYYY')*/}
                                                                        {
                                                                            rule.when.end !== null ?
                                                                                " " + t('entities.interval.to') + ": " + t("formatters:dateLong", {date: Moment(rule.when.end)})
                                                                                : ''
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        : null}
                                                </TableRow>
                                            ))
                                            : <TableRow><TableCell>
                                                <Grid container alignItems={"center"} justify={"center"}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{color: 'darkgrey'}}>
                                                            {t('errors.noDataToDisplay')}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell></TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default FourWeventsDetailRow
