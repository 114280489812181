import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './overridden-placeholder.css'
import {useContext, useState} from "react";
import Box from "@material-ui/core/Box";
import RGL, {WidthProvider} from "react-grid-layout";
import {makeStyles} from "@material-ui/core/styles";
import {AuthContext, ThemeContext} from "../../contexts";
import ButtonPageComponent from "./ButtonPageComponent";
import {useHistory} from "react-router-dom";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import SummaryDataComponent from "./SummaryDataComponent";
import WhyChart from "./WhyChart";
import Paper from "@material-ui/core/Paper";
import IncomingEventsComponent from "./IncomingEventsComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import {GetIconComponent, pathPageObj} from "../../utils/LayoutUtils";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MapCircleComponent from "./MapCircleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {useTranslation} from 'react-i18next';

const LandingPage = () => {

    const ReactGridLayout = WidthProvider(RGL);
    const authContext = useContext(AuthContext);
    const {primaryColor, secondaryColor, backgroundColor} = useContext(ThemeContext)
    const useStyles = makeStyles(() => ({
        box: {
            width: '100%',
            //height: '100%',
            padding: '8px',
        },
        box2: {
            width: '100%',
            height: '100%',
            padding: '8px',
            overflow: 'hidden'
        },
        boxBar: {
            width: '100%',
            height: '100%',
            padding: '8px',
        },
        boxLeft: {
            height: '100%',
            width: '18%',
            margin: '0px',
            float: 'left',
        },
        boxInsideLeft: {
            height: '100%',
            position: 'relative',
        },
        gridLayoutLeft: {
            backgroundColor: backgroundColor,
            top: 0, left: 0, right: 0,
            margin: 'auto',
            borderRadius: '8px',
            height: '100%'
        },
        boxRight: {
            height: '100%',
            width: '80%',
            margin: '0px',
            float: 'right',
        },
        editBox: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        titleBox: {
            position: 'absolute',
            top: '0',
            left: '0',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
        },
        titleBar: {
            fontWeight: "bolder",
            fontSize: '0.85rem'
        },
        iconEdit: {
            width: '0.75em',
            height: '0.75em',
        }
    }));
    const classes = useStyles()

    const originalLayout = getFromLS("layout") || [];
    const originalLayoutBar = getFromLS("layout_bar") || [];
    const [state,] = useState(originalLayout)
    const [stateBar,] = useState(originalLayoutBar)
    const [selectedValueBar, setSelectedValueBar] = useState(getFromLS("bar_paths") || []);

    const {t} = useTranslation();

    const defaultPropsBar = {
        className: "layoutBar",
        cols: 12,
        rowHeight: 50,
        onLayoutChange: function () {
        }
    };
    const defaultProps = {
        className: "layout",
        cols: 12,
        rowHeight: 100,
        onLayoutChange: function () {
        }
    };
    let history = useHistory();

    const [{data: summaryData, loading: loadingSummaryData}] = useAxios({
            url: "api/capture/epcis_events/landing_page_counters"
        },
        {useCache: false}
    )

    function onLayoutChange(layout, name) {
        if (name === "layout_bar") {
            saveToLS(name, layout);
            defaultPropsBar.onLayoutChange(layout) // updates status display
        } else {
            saveToLS(name, layout);
            defaultProps.onLayoutChange(layout) // updates status display
        }
    }

    function getFromLS(key) {
        if (localStorage) {
            return JSON.parse(localStorage.getItem(`${authContext.userName}_${authContext.companyPrefix}_${key}`)) || []
        }
    }

    function saveToLS(key, value) {
        if (localStorage) {
            localStorage.setItem(`${authContext.userName}_${authContext.companyPrefix}_${key}`, JSON.stringify(value));
        }
    }

    function goTo(route) {
        history.push(route)
    }

    function SimpleDialog({onClose, open, paths}) {
        const [checked, setChecked] = useState(paths);
        const handleToggle = (value) => () => {

            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            saveToLS("bar_paths", newChecked)
            setChecked(newChecked);
        };

        const handleClose = () => {
            onClose(checked);
        };

        return (
            <Dialog onClose={handleClose} scroll={'paper'} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle style={{
                    backgroundColor: primaryColor,
                    color: secondaryColor
                }}>{t('landingPage.chooseYourBookmarsTitle')}</DialogTitle>
                <DialogContent dividers={true}>
                    <List>
                        {pathPageObj().filter(function (obj) {
                            if (!authContext.isAdmin) {
                                if (obj.section !== "deviceAdmin")
                                    return obj
                            } else if (authContext.isAdmin) {
                                if (obj.section !== "deviceAdmin" || (obj.section === "deviceAdmin" && process.env.REACT_APP_DEVICE_ADMIN === "1"))
                                    return obj
                            }
                        }).map((obj) => (
                            <ListItem key={obj.path} button onClick={handleToggle(obj.path)}>
                                <ListItemIcon>
                                    <GetIconComponent path={obj.path}/>
                                </ListItemIcon>
                                <ListItemText id={obj.path} primary={obj.title}/>
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(obj.path)}
                                        checked={checked.indexOf(obj.path) !== -1}
                                        color="default"
                                        inputProps={{'aria-labelledby': obj.path}}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        );
    }

    const EditComponent = ({selectedValue, setSelectedValue}) => {
        const [open, setOpen] = useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = (paths) => {
            setOpen(false);
            setSelectedValue(paths);
        };

        return (
            <Box>
                <Box>
                    <Box pl={1} className={classes.titleBox}>
                        <Typography className={classes.titleBar}
                                    style={{color: primaryColor}}>{t("landingPage.bookmarks")}</Typography>
                    </Box>
                    <Box className={classes.editBox}>
                        <IconButton aria-label="edit" onClick={handleClickOpen}>
                            <EditIcon style={{color: primaryColor}} className={classes.iconEdit}/>
                        </IconButton>
                    </Box>
                </Box>
                <SimpleDialog paths={selectedValue} open={open} onClose={handleClose}/>
            </Box>
        )
    }

    return (
        <Box>
            {
                summaryData ?
                    <Box>
                        <Box className={classes.boxLeft}>
                            <Box className={classes.boxInsideLeft}>
                                <ReactGridLayout
                                    {...defaultPropsBar}
                                    layout={stateBar}
                                    onLayoutChange={(layout) => onLayoutChange(layout, "layout_bar")}
                                    className={classes.gridLayoutLeft}
                                    style={selectedValueBar.length > 0 ? {position: 'absolute'} : null}
                                    draggableHandle=".drag-handle">
                                    <div key="edit_btn"
                                         data-grid={{w: 12, h: 1, x: 0, y: 0, isResizable: false, static: true}}>
                                        <EditComponent selectedValue={selectedValueBar}
                                                       setSelectedValue={setSelectedValueBar}/>
                                    </div>
                                    {
                                        selectedValueBar.length > 0 ?
                                            selectedValueBar.map((path) => (
                                                <div key={path}
                                                     data-grid={{w: 12, h: 2.7, x: 0, y: 0, isResizable: false}}
                                                     className={classes.box}>
                                                    <ButtonPageComponent path={path} goTo={goTo}/>
                                                </div>
                                            )) :
                                            <div key='no_value'
                                                 data-grid={{w: 12, h: 2.5, x: 0, y: 0, isResizable: false}}>
                                                <Box pl={1} pr={1}>
                                                    {t('landingPage.bookmarksPlaceholder')}
                                                </Box>
                                            </div>
                                    }
                                </ReactGridLayout>
                            </Box>
                        </Box>
                        <Box className={classes.boxRight}>
                            <ReactGridLayout
                                {...defaultProps}
                                layout={state}
                                onLayoutChange={(layout) => onLayoutChange(layout, "layout")}
                                draggableHandle=".drag-handle"
                            >
                                <Box style={{backgroundColor: backgroundColor}} key="2"
                                     data-grid={{w: 4, h: 1, x: 0, y: 0, static: true}} className={classes.box}>
                                    <SummaryDataComponent data={Object.entries(summaryData)[0]}
                                                          title={t('landingPage.countersLabel.observedEvents')}/>
                                </Box>
                                <Box style={{backgroundColor: backgroundColor}} key="3"
                                     data-grid={{w: 4, h: 1, x: 4, y: 0, static: true}} className={classes.box}>
                                    <SummaryDataComponent data={Object.entries(summaryData)[1]}
                                                          title={`${t('landingPage.countersLabel.users', {whom: authContext.companyName})}`}/>
                                </Box>
                                <Box style={{backgroundColor: backgroundColor}} key="4"
                                     data-grid={{w: 4, h: 1, x: 8, y: 0, static: true}} className={classes.box}>
                                    <SummaryDataComponent data={Object.entries(summaryData)[2]}
                                                          title={t('landingPage.countersLabel.supplyChainPartners')}/>
                                </Box>
                                <Paper key="5" data-grid={{w: 4, h: 4, x: 0, y: 0, minW: 4, minH: 4}}
                                       className={classes.box}>
                                    <div className={classes.box2}>
                                        <IncomingEventsComponent/>
                                    </div>
                                </Paper>
                                <Paper key="6" data-grid={{w: 8, h: 4, x: 4, y: 0, minW: 5, minH: 3}}
                                       className={classes.box}>
                                    <div className={classes.box2}>
                                        {/** i grafici hanno bisogno di height definita,
                                         se no non vengono visualizzati
                                         --> <ContainerDimensions/> restituisce le dimens*/}
                                        <WhyChart/>
                                    </div>
                                </Paper>
                                <Paper key="7" data-grid={{w: 12, h: 4, x: 0, y: 5, minW: 4, minH: 3}}
                                       className={classes.box}>
                                    <div className={classes.box2}>
                                        <MapCircleComponent/>
                                    </div>
                                </Paper>
                            </ReactGridLayout>
                        </Box>
                    </Box> :
                    loadingSummaryData ?
                        <CustomLoader size={80} text={t('actions.loading')}/>
                        : null
            }
        </Box>

    )
}


export default LandingPage
