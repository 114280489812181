import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Collapse} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import {AuthContext, CreateEventContext, ThemeContext} from "../../../contexts";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import {makeStyles} from "@material-ui/core/styles";
import {WhiteListComponent} from "./WhiteListComponent";
import SelectInputTypeComponent from "./SelectInputTypeComponent";
import {FixedValueComponent} from "./FixedValueComponent";
import {useWindowSize} from "../../../utils/Utils";
import _ from "lodash";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {eventFieldNameTypes, eventTypes, inputTypes, splitCbvValues} from "../../../utils/CbvUtils";
import {convertLocationClassToInstance, extractLocationClassCode} from "../../../utils/CodesUtils";
import CustomLoader from "../../../components/CustomLoader";
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import i18next from "../../../i18n/i18next";
import FieldInfo from "../../generate_new_event/FieldInfo";
import TextField from "@material-ui/core/TextField";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useAxios from "axios-hooks";

const WhereComponent = ({handleChange, formData, setStepValidation}) => {
    const size = useWindowSize();
    const {primaryColor, backgroundColor, iconHoverBg} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        icon: {
            color: 'darkgray',
            '&:hover': {
                background: iconHoverBg,
            },
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        flexDottedDiv: {
            display: size.width > 800 ? 'flex' : 'grid',
            border: '2px solid lightGray',
            borderRadius: '15px',
            borderStyle: 'dotted',
            marginBottom: '3%',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        break: {
            flexBasis: '100%',
            height: '0',
        }
    }));
    const classes = useStyles()

    const GrayCheckbox = withStyles({
        root: {
            '&$checked': {
                color: primaryColor,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const {isAdmin, activeMainDomain} = useContext(AuthContext);
    const eventContext = useContext(CreateEventContext);

    //console.log("formData:",formData.whiteListedBizLocations)

    //const places = {}//eventContext.places

    const [sourceDestinationDescription, setSourceDestinationDescription] = useState({})
    const [checkedRequiredSourceDestLists, setCheckedRequiredSourceDestLists] = useState(formData.requiredSourceDestLists);

    /** Scelta del dominio di default in base a se ho edit mode o se sto creando da zero */
    const locationDomain = formData.fixedBizLocation?.data_origin || formData.whiteListedBizLocations[0]?.data_origin
    const locDomainObj = _.find(eventContext.activeDomains, ['domain', locationDomain || ''])
    const partnersDomainOption = {name: "Partners' Domains", other: true, domain: locationDomain};

    const initialDomain = eventContext.editMode ?
        locDomainObj ?
            locDomainObj :
            !isAdmin ? partnersDomainOption
                : eventContext.chosenDomain : eventContext.chosenDomain
    const [state, setState] = useState({
        chosenDomain: initialDomain,
    })

    const [{data: _places, loading: loadingPlaces, error: errorPlaces},] = useAxios({
            url: !state.chosenDomain?.other ?
                ("/api/places/?domain=" + state.chosenDomain?.domain + "&mine=true&not_mine=false")
                : "/api/places/?domain=" + activeMainDomain + "&mine=false&not_mine=true",
        },
        {useCache: false}
    )

    const [{data: _subplaces, loading: loadingSubplaces, error: errorSubplaces},] = useAxios({
            url: !state.chosenDomain?.other ?
                ("/api/subplaces/?domain=" + state.chosenDomain?.domain + "&mine=true&not_mine=false")
                : "/api/subplaces/?domain=" + activeMainDomain + "&mine=false&not_mine=true",
        },
        {useCache: false}
    )

    const [okayData, areEquals] = useMemo(() => {
        let okLocationsValue = false
        let areLocationsEquals = false;

        let isExternalBiz = formData.bizLocInputType === inputTypes.EXTERNAL.value
        let isExternalReadPoint = formData.readPointInputType === inputTypes.EXTERNAL.value

        let isPartiallyFixedSource = formData.sourceListInputType === inputTypes.PARTIALLY_FIXED.value
        let isPartiallyFixedDest = formData.destinationListInputType === inputTypes.PARTIALLY_FIXED.value
        const okSource = checkedRequiredSourceDestLists && isPartiallyFixedSource ? (formData.sourceListTypes && formData.sourceListTypes.length > 0) : true
        const okDest = checkedRequiredSourceDestLists && isPartiallyFixedDest ? (formData.destinationListTypes && formData.destinationListTypes.length > 0) : true

        if (!isExternalBiz && !isExternalReadPoint) {
            if (formData.fixedBizLocation && formData.fixedReadPoint) {
                if (formData.fixedBizLocation.sgln !== formData.fixedReadPoint.sgln) {
                    okLocationsValue = true
                } else {
                    areLocationsEquals = true
                }
            }
        }

        if ((!isExternalBiz && formData.fixedBizLocation)) {
            okLocationsValue = true
        }

        if ((!isExternalReadPoint && formData.fixedReadPoint)) {
            okLocationsValue = true
        }

        if (isExternalBiz && isExternalReadPoint)
            okLocationsValue = true

        return [okLocationsValue && okSource && okDest, areLocationsEquals]
    }, [formData, checkedRequiredSourceDestLists])

    const handleChangeOptional = (event) => {
        setCheckedRequiredSourceDestLists(event.target.checked);
        handleChange('requiredSourceDestLists', event.target.checked)

    };

    useEffect(() => {
        setStepValidation(okayData && !areEquals)
    }, [okayData, areEquals, setStepValidation])

    const places = useMemo(() => {
        if (!_places)
            return null

        return _places.data.map((bizLocation) => ({
            ...bizLocation,
            sgln: convertLocationClassToInstance(bizLocation.gln)
        }))
    }, [_places])

    const subplaces = useMemo(() => {
        if (!_subplaces)
            return null

        let filteredSubPlaces = _.filter(_subplaces.data, function (sub) {
            return !_.endsWith(sub.sgln, '.0')
        })

        if (!state.chosenDomain?.other) {
            filteredSubPlaces = _.filter(filteredSubPlaces, ['data_origin', state.chosenDomain?.domain])
        }

        if (formData.bizLocInputType === inputTypes.FIXED.value && formData.fixedBizLocation) {
            filteredSubPlaces = _.filter(filteredSubPlaces, ['place_id', formData.fixedBizLocation?.gln || ''])
        }

        return filteredSubPlaces
    }, [_subplaces, places, formData.bizLocInputType, formData.fixedBizLocation])

    useEffect(() => {
        //(async () => {
        let descriptions = {}
        let types = []
        types = _.union(formData.sourceListTypes, formData.destinationListTypes)

        for (let type of types) {
            descriptions[type] = i18next.t(`cbvValues.sourceDestListInputTypes.${_.camelCase(splitCbvValues(type))}.description`)
        }
        setSourceDestinationDescription(descriptions)
        //})();
    }, [formData.sourceListTypes, formData.destinationListTypes])


    const SelectOptionalComponent = ({checked, handleChange, formDataFieldName, label}) => {

        let otherLocationOptionalField = formDataFieldName === 'optionalBizLocation' ? 'optionalReadPoint' : 'optionalBizLocation'

        const handleSelectChange = (event) => {
            if (event.target.checked)
                handleChange([formDataFieldName, otherLocationOptionalField], [event.target.checked, !event.target.checked])
            else
                handleChange(formDataFieldName, event.target.checked)
        };

        return (
            <Box>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleSelectChange} color="default"/>}
                    label={label}
                />
            </Box>
        )
    }

    console.log("places:",places)
    console.log("subplaces:",subplaces)
    console.log("formData:",formData.fixedReadPoint)

    return (
        <div>
            {
                places && subplaces ?
                    <div>
                        <Autocomplete
                            style={{flex: 2, marginLeft: 'auto', width: '50%'}}
                            value={state.chosenDomain}
                            disableClearable
                            options={!isAdmin ? [...eventContext.activeDomains, partnersDomainOption] : eventContext.activeDomains}
                            getOptionLabel={(option) => !option.other ? option.name + ' - ' + option.domain : option.name}
                            onChange={(event, newValue) => {
                                //console.log("newValue:", newValue)
                                eventContext.chosenDomain = newValue
                                //handleChange(['fixedBizLocation', 'fixedReadPoint', 'whiteListedBizLocations', 'whiteListedReadPoints'], [null, null, [], []])
                                setState({...state, chosenDomain: newValue})
                            }}
                            renderInput={(params) => <TextField {...params}
                                                                size={'small'}
                                                                label={i18next.t('entities.company.companyDomain')}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: (
                                                                        !state.chosenDomain?.other && (state.chosenDomain?.image || state.chosenDomain?.img) &&
                                                                        <Box p={1}><Avatar
                                                                            src={(state.chosenDomain.image || state.chosenDomain?.img) ? ("api/uploads/uploads/" + (state.chosenDomain.image || state.chosenDomain?.img)) : null}>
                                                                        </Avatar></Box>
                                                                    ),
                                                                }}
                            />}
                            renderOption={(option, {selected}) => {
                                return (<React.Fragment>
                                        {(!option.other && (option.image || option.img)) && <ListItemAvatar>
                                            <Avatar
                                                src={(option.image || option.img) ? ("api/uploads/uploads/" + (option.image || option.img)) : null}>
                                            </Avatar>
                                        </ListItemAvatar>}
                                        <ListItemText
                                            primary={`${option.name}`}
                                            secondary={!option.other && option.domain}
                                            style={{color: primaryColor}}/>
                                    </React.Fragment>
                                )
                            }}
                        />
                        {formData.readPointInputType === inputTypes.FIXED.value && formData.fixedReadPoint ?
                            <Box p={2} pt={1} style={{flex: '1', marginRight: '2%', marginTop: '2%'}}>
                                <FormLabel component="legend">
                                    Business
                                    Location: {_.find(places, ['gln', extractLocationClassCode(formData.fixedReadPoint)])?.name||extractLocationClassCode(formData.fixedReadPoint)}
                                </FormLabel>
                            </Box> :
                            <div>
                                <FieldInfo name={eventFieldNameTypes.BIZ_LOCATION.label}
                                           description={eventFieldNameTypes.BIZ_LOCATION.description}/>
                                <Box p={2} pl={0} className={classes.flexDottedDiv}>
                                    {
                                        formData.bizLocInputType === inputTypes.EXTERNAL.value ?
                                            <Box p={2} pt={1}
                                                 style={{flex: '1 100%', marginRight: '2%', paddingBottom: '0px'}}>
                                                <SelectOptionalComponent
                                                    checked={formData.optionalBizLocation}
                                                    handleChange={handleChange}
                                                    formDataFieldName={'optionalBizLocation'}
                                                    label={i18next.t('entities.optional', 'Optional')}
                                                />
                                            </Box> : null
                                    }
                                    <SelectInputTypeComponent
                                        options={[inputTypes.FIXED.value, inputTypes.EXTERNAL.value]}
                                        formLabel={i18next.t('createEventModel.chooseTheBizLocInputType', 'Choose the Business Location Input Type')}
                                        formDataFieldName={'bizLocInputType'}
                                        formDataFieldValue={formData.bizLocInputType}
                                        formData={formData}
                                        handleChange={handleChange}/>
                                    {
                                        formData.bizLocInputType === inputTypes.FIXED.value ?
                                            <FixedValueComponent options={places}
                                                                 disabled={loadingPlaces}
                                                                 formDataFieldName={'fixedBizLocation'}
                                                                 formDataFieldValue={formData.fixedBizLocation}
                                                                 formLabel={i18next.t('createEventModel.enterTheBizLocToFix', 'Enter the Business Location you want to fix')}
                                                                 handleChange={handleChange}
                                                                 formData={formData}
                                            /> : formData.bizLocInputType === inputTypes.EXTERNAL.value ?
                                            <WhiteListComponent options={places}
                                                                disabled={loadingPlaces}
                                                                multiple={true}
                                                                formDataFieldName={'whiteListedBizLocations'}
                                                                formDataFieldValue={formData.whiteListedBizLocations}
                                                                formLabel={i18next.t('createEventModel.enterTheBizLocToSuggest', 'Enter the Business Locations you want to suggest')}
                                                                handleChange={handleChange}
                                                                formData={formData}
                                            /> : null
                                    }
                                </Box></div>}
                        <div>
                            <FieldInfo name={eventFieldNameTypes.READ_POINT.label}
                                       description={eventFieldNameTypes.READ_POINT.description}/>
                            <Box p={2} pl={0} className={classes.flexDottedDiv}>
                                {
                                    formData.readPointInputType === inputTypes.EXTERNAL.value ?
                                        <Box p={2} pt={1}
                                             style={{flex: '1 100%', marginRight: '2%', paddingBottom: '0px'}}>
                                            <SelectOptionalComponent
                                                checked={formData.optionalReadPoint}
                                                handleChange={handleChange}
                                                formDataFieldName={'optionalReadPoint'}
                                                label={i18next.t('entities.optional', 'Optional')}
                                            />
                                        </Box> : null
                                }
                                <SelectInputTypeComponent
                                    options={[inputTypes.EXTERNAL.value, inputTypes.FIXED.value]}
                                    formLabel={i18next.t('createEventModel.chooseTheReadPointInputType', "Choose the Read Point Input Type")}
                                    formDataFieldName={'readPointInputType'}
                                    formDataFieldValue={formData.readPointInputType}
                                    formData={formData}
                                    handleChange={handleChange}/>
                                {
                                    formData.readPointInputType === inputTypes.FIXED.value ?
                                        <FixedValueComponent options={subplaces}
                                                             disabled={loadingSubplaces}
                                                             formDataFieldName={'fixedReadPoint'}
                                                             formDataFieldValue={formData.fixedReadPoint}
                                                             formLabel={i18next.t('createEventModel.enterTheReadPointToFix', 'Enter the Read Point you want to fix')}
                                                             handleChange={handleChange}
                                                             formData={formData}
                                        /> : formData.readPointInputType === inputTypes.EXTERNAL.value ?
                                        <WhiteListComponent options={subplaces}
                                                            disabled={loadingSubplaces}
                                                            multiple={true}
                                                            formDataFieldName={'whiteListedReadPoints'}
                                                            formDataFieldValue={formData.whiteListedReadPoints}
                                                            formLabel={i18next.t('createEventModel.enterTheReadPointsToSuggest', 'Enter the Read Points you want to suggest')}
                                                            handleChange={handleChange}
                                                            formData={formData}
                                        /> : null
                                }
                            </Box>
                        </div>
                        {
                            formData.eventType === eventTypes.TRANSACTION.value ?
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <GrayCheckbox
                                                checked={checkedRequiredSourceDestLists}
                                                onChange={handleChangeOptional}
                                            />}
                                        label={i18next.t('createEventModel.chooseSourceDestOfTransaction', "Choose the source and the destination of the transaction")}
                                    />
                                    {<Collapse in={checkedRequiredSourceDestLists} mountOnEnter unmountOnExit>
                                        <div className={classes.flexDottedDiv}>
                                            <SelectInputTypeComponent
                                                options={[inputTypes.PARTIALLY_FIXED.value, inputTypes.EXTERNAL.value]}
                                                formLabel={i18next.t('createEventModel.chooseSourceListInputType', "Choose the Source List Input Type")}
                                                formDataFieldName={'sourceListInputType'}
                                                formDataFieldValue={formData.sourceListInputType}
                                                sourceListTypes={formData.sourceListTypes}
                                                handleChange={handleChange}/>

                                            <DoubleArrowIcon fontSize={"large"}
                                                             style={{margin: 'auto 8px', color: primaryColor}}/>
                                            <SelectInputTypeComponent
                                                options={[inputTypes.PARTIALLY_FIXED.value, inputTypes.EXTERNAL.value]}
                                                formLabel={i18next.t('createEventModel.chooseDestListInputType', "Choose the Destination List Input Type")}
                                                formDataFieldName={'destinationListInputType'}
                                                formDataFieldValue={formData.destinationListInputType}
                                                destinationListTypes={formData.destinationListTypes}
                                                handleChange={handleChange}/>
                                            <div style={{width: '100%'}}>
                                                {
                                                    formData.sourceListInputType === inputTypes.PARTIALLY_FIXED.value || formData.destinationListInputType === inputTypes.PARTIALLY_FIXED.value ?
                                                        !_.isEmpty(sourceDestinationDescription) ?
                                                            <Box p={2} pt={0}>
                                                                {_.map(sourceDestinationDescription, (val, key) => {
                                                                    if (val) {
                                                                        return (
                                                                            <Accordion style={{
                                                                                backgroundColor: backgroundColor,
                                                                                color: primaryColor
                                                                            }} key={key}>
                                                                                <AccordionSummary
                                                                                    expandIcon={<ExpandMoreIcon
                                                                                        style={{color: primaryColor}}/>}
                                                                                >
                                                                                    <Typography
                                                                                        className={classes.heading}>
                                                                                        {i18next.t('entities.moreInfo')} - {splitCbvValues(key)}</Typography>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <Typography>
                                                                                        {val}
                                                                                    </Typography>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        )
                                                                    }
                                                                })}

                                                            </Box> : null : null

                                                }
                                            </div>
                                        </div>
                                    </Collapse>}
                                </Box> : null
                        }
                        {
                            okayData ? null :
                                areEquals ?
                                    <FormHelperText
                                        style={{color: 'red'}}>{i18next.t('createEventModel.bizLocAndReadPointCannotBeTheSame', 'Business Location and Read Point cannot be the same')}</FormHelperText> :
                                    <FormHelperText
                                        style={{color: 'red'}}>{i18next.t('createEventModel.fulfillFixedValues', 'Fixed values need to be fulfilled')}</FormHelperText>
                        }
                    </div>
                    : (loadingPlaces || loadingSubplaces) ? <CustomLoader size={80}/> : null}
            {errorPlaces && <Typography align={'center'}
                                        style={{color: 'darkgrey'}}>{i18next.t('errors.errorFetchingPlaces', 'Error fetching business locations... ')}</Typography>}
            {errorSubplaces && <Typography align={'center'}
                                           style={{color: 'darkgrey'}}>{i18next.t('errors.errorFetchingSubPlaces', 'Error fetching read points... ')}</Typography>}
        </div>
    )
}

export default WhereComponent
