import React, {useState} from 'react';
import {Modal, Fade, Backdrop, Button, TextField, Box, LinearProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const ComposeFileUploader = (props) => {

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5]
        },
        uploader: {
            margin: 16
        },
        buttons: {
            marginRight: 16,
            marginBottom: 16,
            justifyContent: "flex-end",
            display: 'flex',
            alignItems: 'center'
        },
        button: {
            textTransform: "none",
        },
        buttonComplete: {
            textTransform: "none",
            color: "whitesmoke",
            backgroundColor: "rgba(0, 0, 0, 0.87)",
            marginRight: 8
        }
    }));

    const [successOpen, setSuccessOpen] = useState(false);

    const [errorOpen, setErrorOpen] = useState(false);

    const handleSuccessClick = () => {
        setSuccessOpen(true);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
    };

    const handleErrorClick = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    const [filename, setFilename] = useState("")

    const classes = useStyles();

    const [fileState, setFileState] = useState({

        selectedFile: null
    });

    const [loading, setLoading] = useState(0)

    const onFileChange = event => {

        setFileState({selectedFile: event.target.files[0]});
        setFilename(event.target.files[0].name)

    };

    const onFileUpload = () => {

        const formData = new FormData();

        formData.append(
            "file",
            fileState.selectedFile,
            fileState.selectedFile.name
        );
        setLoading(1)
        axios({
            method: 'post',
            url: 'api/compose_files/',
            data: formData,
            headers: {'Media-Type': 'application/x-yaml'}
        })
            .then((res) => {
                //console.log('res', res)
                setLoading(0)
                onHide()
                props.updateList()
                handleSuccessClick()
            })
            .catch((err) => {
                console.log('uploading err', err)
                setLoading(0)
                handleErrorClick()
            });
    };

    function onHide() {
        props.onHide()
        setFileState({selectedFile: null});
        setFilename("")
    }

    const body = (
        <Paper className={classes.paper}>
            <Grid container direction={"column"}>
                <Grid className={classes.uploader}>
                    <Box>
                        <Button variant="contained" component="label" className={classes.button}>
                            Choose File
                            <input type="file" onChange={onFileChange}
                                   accept={"application/x-yaml"} hidden/>
                        </Button>
                        <TextField InputProps={{
                            readOnly: true,
                        }} style={{marginLeft: 8}} variant={"outlined"} size={"small"} value={filename}>
                        </TextField>
                    </Box>
                </Grid>
                <Grid className={classes.buttons}>
                    {fileState.selectedFile ? <Button variant="contained" className={classes.buttonComplete}
                                                      onClick={onFileUpload}>Upload</Button> :
                        <Button variant="contained" className={classes.buttonComplete} disabled={true}>Upload</Button>}
                    <Button color="default" variant="contained" className={classes.button}
                            onClick={onHide}>Close</Button>
                </Grid>
            </Grid>
            <LinearProgress style={{opacity: loading}} color="primary"/>
        </Paper>
    );

    return (
        <div>
            <Modal
                open={props.show}
                onClose={onHide}
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.show}>
                    {body}
                </Fade>
            </Modal>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    Operation done successfully
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Error during operation
                </Alert>
            </Snackbar>
        </div>
    );

}

export default ComposeFileUploader;