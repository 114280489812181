import React, {useContext, useEffect, useState} from "react";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import _ from 'lodash'
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useForm} from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import {bizTransactionTypes, eventFieldNameTypes, inputTypes} from "../../../utils/CbvUtils";
import FieldInfo from "../FieldInfo";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import axios from "axios";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ClearIcon from "@material-ui/icons/Clear";
import BizTransactionBuilder from "./BizTransactionBuilder";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {findTransactionCodeTypeValue} from "../../../utils/CodesUtils";
import DocumentIconButton from "../../../components/DocumentIconButton";
import {Description, Link} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import BizTransactionPartiallyFixedBuilder from "./BizTransactionPartiallyFixedBuilder";
import {useTranslation} from "react-i18next";
import {severityTypes} from "../../../utils/Utils";

/** FORMATO BIZTRANS EXTERNAL ->  bizTransactionList: [(bizTransaction, type)]
 * - bizTransaction is an identifier or a pointer to a document of the transaction, usually is an urn which represent a GDTI or an URL which point to the document
 * - The type of the transaction must be one of the cbv values
 */

/** FORMATO BIZTRANS PARTIALLY-FIXED -> bizTransactionList: [str]
 * - bizTransactionList with no alias:
 --- It is a list of Business Transaction, the parameeter is the name of the attribute followed by a list of tuple of 2 elements: (bizTransaction, type)
 ---- bizTransaction is an identifier or a pointer to a document of the transaction, usually is an urn which represent a GDTI or an URL which point to the document
 ---- The size of the list and the types were fixed, so you must provide a list of string corresponding to the following ordered types:
 ----- ['urn:epcglobal:cbv:btt:pedigree', 'urn:epcglobal:cbv:btt:poc']
 --- bizTransactionList: [str]

 * */

export async function buildTransactionCode(generated, codeType, bizType, bizTransaction, setOpenAlertMessage) {
    if (generated) {
        const url = "/api/codes_gen/doc_id/new/?bt_type=" + bizType.value
        return await axios.get(url).then(code => {
            if (code) {
                //console.log("generate new gdti --> code:", code.data)
                return code.data
            }
        })
    } else {
        switch (codeType) {
            case 'outside':
                const [type] = findTransactionCodeTypeValue(bizTransaction)
                if (type !== undefined)
                    return bizTransaction
                else
                    setOpenAlertMessage({
                        open: true,
                        message: 'Invalid code format!',
                        severity: 'warning'
                    });
                break
            case 'inside':
                const url = "/api/codes_gen/bt_identifier/new/?legacy_id=" + bizTransaction
                return await axios.get(url).then(code => {
                    if (code) {
                        //console.log("generate new gdti --> code:", code.data)
                        return code.data
                    }
                })
            default:
                return null
        }


    }
}

const BizTransactionComponent = ({field}) => {
    const {t} = useTranslation()
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        bizTransArea: {
            borderRadius: '12px',
            backgroundColor: 'rgba(245,245,245,0.7)',
            border: '1px solid lightgray',
            borderStyle: 'dotted',
        },
        warningIcon: {
            color: accentColor,
            marginRight: '1%'
        },
        infoChip: {
            marginLeft: '1%',
            marginTop: '1%',
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }));
    const classes = useStyles()

    const initialAlertMessageState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialAlertMessageState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const eventContext = useContext(CreateEventContext)
    const {handleSubmit, register, control, watch} = useForm();

    const [state, setState] = useState({
        codeType: undefined,
        generateCodeCheck: false,
        bizTransactions: [],

        bizTransactionsDocuments: [],
        bizTransaction: null,
        bizTransactionType: null,
        bizTransactionDescription: null,

    });

    /*useEffect(() => {
        console.log("BizTransactionComponent ---> state:", state)
    }, [state])*/

    /** versione asincrona */
    /*useEffect(() => {
        if (field && field.children) {
            (async () => {
                let descriptions = {}
                for (let bizType of field.children) {
                    descriptions[bizType] = await fetchDescription('biz_transaction_type', bizType)
                }
                //console.log('DESCRIPTIONS ---> ', descriptions)
                setState({...state, bizTransactionDescription: descriptions})
            })();
        }
    }, [field])*/

    useEffect(() => {
        if (field.input === inputTypes.EXTERNAL.value)
            eventContext.setValidationField(
                'what',
                field.name,
                field.optional)
        else if (field.input === inputTypes.PARTIALLY_FIXED.value) {
            _.forEach(field.children, (type) => {
                eventContext.setValidationField('what', type, false)
            })
        }
    }, [field])

    async function handleAddBizTransaction(data) {
        const {codeType, bizType, bizTransaction} = data

        const bizTransValue = await buildTransactionCode(state.generateCodeCheck, codeType, bizType, bizTransaction, setOpenAlertMessage);
        if (bizTransValue) {
            if (_.findIndex(state.bizTransactions, function (o) {
                return JSON.stringify(o) === JSON.stringify([bizTransValue, bizType.value])
            }) === -1) {
                const newBizTransactions = [...state.bizTransactions, [bizTransValue, bizType.value]]
                eventContext.setValidationField('what', field.name, newBizTransactions.length !== 0)
                eventContext.setFormField('what', field.name, newBizTransactions)

                setState({
                    ...state,
                    bizTransactions: newBizTransactions
                })
            } else {
                setOpenAlertMessage({open: true, message: t('createEvent.codesMustbeUnique', 'Codes must be unique!'), severity: severityTypes.WARNING});
            }
        }
    }

    const onClearClick = async (code) => {
        const new_biz_trans = state.bizTransactions.filter(function (o) {
            return JSON.stringify(o) !== JSON.stringify([code[0], code[1]])
        })
        eventContext.setValidationField('what', field.name, new_biz_trans.length !== 0)
        eventContext.setFormField('what', field.name, new_biz_trans)

        const new_biz_trans_docs = state.bizTransactionsDocuments.filter(function (o) {
            return o.bizTransaction !== code[0]
        })
        eventContext.setFormField(null, 'docids', new_biz_trans_docs)

        setState({...state, bizTransactions: new_biz_trans, bizTransactionsDocuments: new_biz_trans_docs})
    }

    return (
        <div>
            {
                <Box mb={2} pb={2}>
                    <FieldInfo
                        name={eventFieldNameTypes.BIZTRANSACTIONLIST.label}
                        description={eventFieldNameTypes.BIZTRANSACTIONLIST.description}
                        external={true}
                        optional={field.optional}
                    />
                    {
                        field.input === inputTypes.EXTERNAL.value ?
                            state.bizTransactions && state.bizTransactions.length !== 0 ?
                                state.bizTransactions.map((code, index) => {
                                    const uploadedFile = _.find(state.bizTransactionsDocuments, ['bizTransaction', code[0]])
                                    return <Box key={index} pl={2} pr={2}>
                                        <Grid container direction="row" justify="flex-start">
                                            <Grid item xs={3} container
                                                  direction="row"
                                                  justify="flex-end"
                                                  alignItems="center">
                                                {
                                                    uploadedFile ?
                                                        <Chip
                                                            label={uploadedFile.docType === 'link' ? 'Link' : t('entities.document.uploadedFile')}
                                                            icon={uploadedFile.docType === 'link' ?
                                                                <Link style={{color: accentColor}}/>
                                                                : <Description style={{color: accentColor}}/>
                                                            }
                                                            className={classes.infoChip}
                                                            size={"small"}
                                                        /> : null
                                                }
                                                <DocumentIconButton editable={true} bizTransaction={code[0]}
                                                                    color={primaryColor}
                                                                    upperState={state} setUpperState={setState}/>
                                            </Grid>
                                            <Grid item xs={8} container
                                                  direction="row"
                                                  justify="flex-start"
                                                  alignItems="center">
                                                <Box width={1}>
                                                    <Typography>
                                                        <b>{_.find(bizTransactionTypes, ['value', code[1]]).name + ': '}</b>
                                                        {code[0]}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={1} container
                                                  direction="row"
                                                  justify="flex-end"
                                                  alignItems="center">
                                                <IconButton aria-label="clear" onClick={() => onClearClick(code)}>
                                                    <ClearIcon fontSize="small"/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Divider/>
                                    </Box>
                                }) : <FormHelperText error>{t('createEvent.fieldCannotBeEmpty')}</FormHelperText>
                            : null
                    }
                    <div>
                        {
                            field.input === inputTypes.EXTERNAL.value ?
                                <Box mt={1} p={2} className={classes.bizTransArea}>
                                    <BizTransactionBuilder
                                        control={control}
                                        state={state}
                                        setState={setState}
                                        inputRef={register}
                                        fieldValues={watch('bizTransaction')}
                                        classes={classes}
                                        disabled={false}/>
                                    <Box pt={1} style={{display: 'flex', justifyContent: 'center'}}>
                                        <Button aria-label="add"
                                                disabled={(state.generateCodeCheck && !watch('bizType')) ||
                                                (!state.generateCodeCheck && (!watch('bizType') || !watch('codeType') ||
                                                    !watch('bizTransaction')))}
                                                onClick={handleSubmit(handleAddBizTransaction)}
                                                endIcon={<AddCircleIcon style={{
                                                    color: ((state.generateCodeCheck && !watch('bizType')) ||
                                                        (!state.generateCodeCheck && (!watch('bizType') || !watch('codeType') ||
                                                            !watch('bizTransaction')))) ? 'lightgray' : accentColor
                                                }}/>}>
                                            Add
                                        </Button>
                                    </Box>
                                </Box>
                                : //VERSIONE ASINCRONA state.bizTransactionDescription &&
                                field.children && field.children.length !== 0 ?
                                    _.map(field.children, (bizType, index) => (
                                        <BizTransactionPartiallyFixedBuilder
                                            key={index}
                                            state={state}
                                            setState={setState}
                                            optional={field.optional}
                                            bizTransactions={field.children}
                                            bizType={bizType}
                                            classes={classes}
                                            disabled={true}
                                            setOpenAlertMessage={setOpenAlertMessage}/>))
                                    : null
                        }
                    </div>
                    <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                              onClose={handleCloseErrorMessage}>
                        <Alert onClose={handleCloseErrorMessage}
                               severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
                    </Snackbar>
                </Box> //: null
            }
        </div>
    )
}

export default BizTransactionComponent