import React, {useContext} from "react";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import chroma from "chroma-js";
import {ThemeContext} from "../contexts";

const Summarylabel = ({icon, title, value}) => {
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        tagChip : {
            backgroundColor: chroma(accentColor).alpha(0.7).hex(),
            color: 'white',
            fontWeight: "bold",
            fontSize: "inherit"
        },
        labelChip: {
            marginRight: '1%',
            marginBottom: '1%',
            border: '1px solid '+ accentColor,
            color: accentColor,
            fontWeight: 'bold',
        }
    }))
    const classes = useStyles();

    return (
        <Box pb={2} pt={2}>
            <Grid container direction={'row'} wrap={"nowrap"} justifyContent={'center'} alignItems={'center'}>
                <Grid item md={4} xs={12}>
                    <Chip className={classes.labelChip}
                          icon={icon ? icon : null}
                          label={title}
                          variant={'outlined'}
                    />
                </Grid>
                <Grid item md={8} xs={12}>
                    <Typography variant="subtitle2" gutterBottom style={{color: 'grey', marginLeft:'2%', lineBreak: 'anywhere'}}>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Summarylabel


/**
 * <Box pb={2} pt={2} style={{display:'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
 <div style={{display:'flex', marginRight: '3%'}}>
 {icon}
 <Typography  variant="subtitle1" gutterBottom>
 {title}:
 </Typography>
 </div>
 <Typography variant="subtitle2" gutterBottom style={{color: 'grey'}}>
 {value}
 </Typography>
 </Box>*/

/**
 * <Box pb={2} pt={2}>
 <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
<Grid item xs={icon ? 2 : 3}>
    <div>
        {icon}
        <Typography variant="subtitle1" gutterBottom>
            {title}
        </Typography>
    </div>
</Grid>
<Grid item xs={9}>
    <Typography variant="subtitle2" gutterBottom style={{color: 'grey'}}>
        {value}
    </Typography>
</Grid>
</Grid>
</Box>
*/