import {QrCodeIcon} from "./CustomIcons";
import {IconButton, Tooltip} from "@material-ui/core";
import React, {useContext, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {QRCode} from "react-qr-svg";
import {ThemeContext} from "../contexts";
import {useTranslation} from "react-i18next";

const QrCodeIconButton = ({sgtin, color}) => {
    const { t } = useTranslation();

    const {primaryColor} = useContext(ThemeContext)

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title={t('entities.qrCode.seeQrCode')} arrow>
                <IconButton onClick={handleClickOpen}>
                    <QrCodeIcon style={{color}}/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('entities.qrCode.qrCode')}</DialogTitle>
                <QRCode
                    bgColor="#FFFFFF"
                    fgColor={primaryColor}
                    level="Q"
                    style={{ width: 256, padding: '5%' }}
                    value={sgtin}
                />
            </Dialog>
        </div>
    )
}

export default QrCodeIconButton
