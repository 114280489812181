import React from "react";
import {Divider, Grid, List} from "@material-ui/core";
import WRow from "./WRow";
import shortid from 'shortid';
import {makeStyles} from "@material-ui/core/styles";
import FieldInfo from "../../pages/generate_new_event/FieldInfo";
import _ from 'lodash'
import {eventFieldNameTypes, inputTypes, splitCbvValues} from "../../utils/CbvUtils";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    fieldNameContainer: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
}));

const WDetails = ({wData}) => {
    const {t} = useTranslation()

    //console.log("WDetails ---> wData: ", wData)
    const classes = useStyles()
    return (
        <div>
            {
                wData.length > 0 ? wData.map((field, index) => {
                    if(field.name !== 'eventID') {
                        const fieldInfo = _.find(eventFieldNameTypes, ['name', field.name])
                        const fieldTypeInfo = field.type !== 'str' && field.type !== 'date' ? _.find(eventFieldNameTypes, ['type', field.type]) : null
                        const fieldInputInfo = _.find(inputTypes, ['value', field.input])
                        return <div key={index} style={{
                            marginBottom: '2%',
                            padding: '2%',
                            paddingBottom: '1%',
                            borderRadius: '10px',
                            border: '2px solid lightGray',
                            borderStyle: 'dotted'
                        }}>
                            <Grid container
                                  alignItems={"baseline"}>
                                <Grid item xs={12} md={4} className={classes.fieldNameContainer}>
                                    <FieldInfo name={_.startCase(fieldInfo?.name) || _.startCase(field.name)}
                                                optional={field.optional}
                                                external={/** per avere solo i campi required per chi compilerà il form
                                                 field.input === inputTypes.EXTERNAL.value */true}
                                                description={fieldInfo?.description}/>
                                </Grid>
                                <Grid item xs={12} md={8} className={classes.fieldDetails}>
                                    {
                                        field.type ? // campo "normale" altrimenti è un campo di ILMD
                                            <List>
                                                <WRow key={shortid.generate()} label={_.startCase(t('entities.type'))}
                                                      value={field.type} type={fieldTypeInfo}/>
                                                <Divider variant="middle"/>
                                                <WRow key={shortid.generate()}
                                                      label={_.startCase(t('entities.eventFields.input'))}
                                                      value={field.input} input={fieldInputInfo}/>
                                                <Divider variant="middle"/>
                                                {/**<WRow key={shortid.generate()}
                                                       label={_.startCase(t('entities.eventFields.alias'))}
                                                       value={field.alias ? field.alias : '-'}/>
                                                    <Divider variant="middle"/>*/}
                                                <WRow key={shortid.generate()} label={_.startCase(t('entities.value'))}
                                                      value={field.value ? splitCbvValues(field.value) : '-'}
                                                      bizStep={fieldInfo?.name === eventFieldNameTypes.BIZ_STEP.name ?
                                                          field.value : null}
                                                      disposition={fieldInfo?.name === eventFieldNameTypes.DISPOSITION.name ?
                                                          field.value : null}
                                                      action={fieldInfo?.name === eventFieldNameTypes.ACTION.name ?
                                                          field.value : null}/>
                                                {/**<WRow key={shortid.generate()}
                                                       label={_.startCase(t('entities.eventFields.function'))}
                                                       value={field.fun ? field.fun : '-'}/>
                                                    <Divider variant="middle"/>*/}
                                                {field.precedence && <WRow key={shortid.generate()}
                                                       label={_.startCase(t('entities.eventFields.precedence'))}
                                                       value={field.precedence ? _.startCase(field.precedence) : '-'}/>}
                                                {
                                                    field.children ? field.children.length > 0 ?
                                                        <div>
                                                            <Divider variant="middle"/>
                                                            <WRow key={shortid.generate()}
                                                                  label={_.startCase(t('entities.eventFields.children'))}
                                                                  value={field.children}/>
                                                        </div> : null
                                                        : null
                                                }
                                                {
                                                    field.whitelist && field.whitelist.length > 0 ?
                                                        <div>
                                                            <Divider variant="middle"/>
                                                            <WRow key={shortid.generate()}
                                                                  label={_.startCase(t('entities.eventFields.whitelist'))}
                                                                  value={field.whitelist}
                                                                  strictedWhitelist={field.strict}/>
                                                        </div> : null
                                                }
                                            </List> :
                                            <List>
                                                {/**<WRow key={shortid.generate()}
                                                   label={_.startCase(t('cbvValues.ilmd.namespace'))} value={field.ns}/>
                                                <Divider variant="middle"/>*/}
                                                <WRow key={shortid.generate()} label={_.startCase(t('entities.name'))}
                                                      value={field.name ? _.startCase(field.name) : '-'}/>
                                                <Divider variant="middle"/>
                                                <WRow key={shortid.generate()}
                                                      label={_.startCase(t('entities.eventFields.input'))}
                                                      value={field.input}/>
                                                <Divider variant="middle"/>
                                                <WRow key={shortid.generate()} label={_.startCase(t('entities.value'))}
                                                      value={field.value ? field.value : '-'}/>
                                                <Divider variant="middle"/>
                                                <WRow key={shortid.generate()}
                                                      label={_.startCase(t('cbvValues.ilmd.defaultValue'))}
                                                      value={field.default ? field.default : '-'}/>
                                            </List>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    } else return null
                }) : <Typography variant={'body2'}>{t('entities.model.sectionToComplete')}</Typography>
            }
        </div>
    )
}

export default WDetails;
