import React from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import CustomLoader from "../../components/CustomLoader";

const ErrorMessage = ({requestError, requestLoading}) => {
    const {t} = useTranslation()
    const msg = `${requestError?.response?.status} - ${requestError?.response?.data?.detail||requestError?.response?.data||t("errors.genericServerError")}`
    return (
            <Box p={1}>
                {!!requestError && <Alert severity="error"><Typography>{msg}</Typography></Alert>}
                {requestLoading && <CustomLoader/>}
            </Box>
    )
}

export default ErrorMessage
