import React, {useContext, useEffect, useMemo, useState} from 'react'
import Box from "@material-ui/core/Box";
import useAxios from "axios-hooks";
import {getCenter, getGenerilizedPlaces} from "../../components/CustomMap";
import axios from "axios";
import {Map, Popup, Marker, TileLayer} from "react-leaflet";
import ContainerDimensions from "react-container-dimensions";
import HeaderPanelComponent from "./HeaderPanelComponent";
import {ThemeContext} from "../../contexts";
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import _ from 'lodash'
import {findLocationCodeTypeValue} from "../../utils/CodesUtils";
import {useTranslation} from "react-i18next";

const MapCircleComponent = () => {
    const { t } = useTranslation();

    const [{data: events},] = useAxios({
            url: "api/capture/epcis_events/dashboard_4w_counters?",
        },
        {useCache: false}
    )
    const [placesToDisplay, setPlacesToDisplay] = useState([])
    const [center, setCenter] = useState({
        latitude: 45,
        longitude: 7,
        zoom: 11,
        bounds: null
    })
    const {accentColorAlpha} = useContext(ThemeContext)

    const mapWhere = useMemo(() => {
        const aggregateEvents = events
        if (aggregateEvents) {
            //console.log("aggregateEvents:",aggregateEvents)
            const map_where = {}
            if (aggregateEvents.where_panel) {
                Object.entries(aggregateEvents.where_panel).forEach(([key, whereObj]) => {
                    map_where[whereObj._id] = {
                        n_events: whereObj.events_count,
                        n_items: whereObj.items_count,
                    }
                })
            }
            //console.log("map_where:",map_where)
            return map_where
        }
    }, [events])

    useEffect(() => {
        (async () => {
            let placeLut = {}

            if (mapWhere) {
                if (Object.entries(mapWhere).length !== 0) {
                    let url = "api/places/_by_codes/?skip=0&limit=0"
                    await axios.post(url, {codes: Object.keys(mapWhere)})
                        .then(res => {
                            if (res) {
                                res.data.data.forEach((place) => {
                                    //console.log("place:", place)
                                    placeLut[place.gln] = place
                                })
                                if (Object.entries(mapWhere).length > 0) {
                                    const generilizedPlaces = getGenerilizedPlaces(mapWhere, placeLut, null, null)
                                    setCenter(getCenter(generilizedPlaces))
                                    setPlacesToDisplay(generilizedPlaces)
                                } else {
                                    setCenter({
                                        latitude: 45,
                                        longitude: 7,
                                        zoom: 11,
                                        bounds: null
                                    })
                                    setPlacesToDisplay([])
                                }
                            }
                        }).catch((error) => {
                            console.log("map error", error)
                        })
                } else {
                    setCenter({
                        latitude: 45,
                        longitude: 7,
                        zoom: 11,
                        bounds: null
                    })
                    setPlacesToDisplay([])
                }
            }
        })()
    }, [mapWhere])

    function handlePress(e) {
        e.stopPropagation();
    }

    const MyCircleMarker = ({r}) => {
        const _r = r*1.5
        return (
            <svg width={_r} height={_r}>
                {/*<defs>
                    <filter id="blur">
                        <feGaussianBlur stdDeviation="0"/>
                    </filter>
                </defs>*/}
                <circle cx={_r/2.5} cy={_r/2.5} r={_r/3} style={{opacity:'0.6'}} fill={accentColorAlpha} /*filter="url(#blur)"*//>
                <circle cx={_r/2.5} cy={_r/2.5} r={_r/4.1} /*style={{opacity:'0.5'}}*/ fill={accentColorAlpha} /*filter="url(#blur)"*//>
            </svg>
        )
    }

    function getIcon(r){
        const icon = L.divIcon({
            className: 'custom-icon',
            html: ReactDOMServer.renderToString(<MyCircleMarker r={r}/>)
        });
        return icon
    }

    return (
        <Box style={{height: '100%'}}>
            <HeaderPanelComponent title={t('landingPage.panelTitles.whereMap')}/>
            <Box style={{height: '100%', overflow: 'hidden'}} onMouseDown={e => handlePress(e)} >
                <ContainerDimensions>
                    {({width, height}) =>
                        <Map
                            center={[center.latitude, center.longitude]}
                            minZoom={2}
                            zoom={center.zoom}
                            style={{width: '100%', height: height * 0.85}}
                            bounds={center.bounds ? center.bounds : null}
                            animate={false}
                        >
                            <TileLayer
                                url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'/>
                            {
                                placesToDisplay.map((place) => {
                                    const [type, locationCode, domain] = findLocationCodeTypeValue(place.gln)

                                    return <Marker key={place.gln}
                                                   position={[place.latitude, place.longitude]}
                                                   icon={getIcon(Math.abs(15 * Math.log(place.nEvents / 10)))}>
                                        <Popup>
                                            <span>{_.startCase(t('entities.name'))}: {place.name},</span>
                                            <br/>
                                            <span>{_.upperCase(type)}: {domain ? (domain + ':') : '' + locationCode},</span>
                                            <br/>
                                            <span>{_.startCase(t('entities.location.address'))}: {place.address}</span>
                                            <br/>
                                            {
                                                place.nItems ?
                                                    <div>
                                                        <span>{t('entities.itemWithCount', {count: place.nItems})}</span>
                                                        <br/>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                place.nEvents ?
                                                    <div>
                                                        <span>{t('entities.eventWithCount', {count: place.nEvents})}</span>
                                                        <br/>
                                                    </div>
                                                    : null
                                            }
                                        </Popup>
                                    </Marker>
                                })
                            }
                        </Map>
                    }
                </ContainerDimensions>
            </Box>
        </Box>
    )
}

export default MapCircleComponent
