import _ from "lodash";

export const aggregationTypes = {
    why: 'why',
    where: 'where'
};


/** grafo completamente espanso (grafo totale dei nodi why) */
export function createExpandedGraphData(nodes) {
    const nodesMap = {}
    let linksMap = {}

    nodes.forEach(n => {
        // mappa di tutti i nodi
        nodesMap[n.id] = n

        // mappa di tutti i links
        if(n.arcs) {
            Object.entries(n.arcs).forEach(([key, value]) => {
                const linkId = `${n.id}-->${key}`
                linksMap[linkId] = {
                    id: linkId,
                    source: n.id,
                    target: key,
                    eventsCount: value
                }
            })
        }
    })

    return [nodesMap, linksMap];
}

/** fonde gli eventi clusterizzati dei singoli nodi (why) figli nel padre (where) */
export function fuseClusteredEvents(parent, children) {
    if(parent.clusteredEvents && children.clusteredEvents) {
        const parentTmp = Object.assign({}, parent)
        const childTmp = Object.assign({}, children.clusteredEvents)

        const cluster = Object.keys(childTmp)
        const timeSlotsParent = Object.keys(parentTmp.clusteredEvents[cluster])
        const timeSlotsChildren = Object.keys(childTmp[cluster])

        timeSlotsChildren && timeSlotsChildren.forEach((slot) => {

            const child = childTmp[cluster][slot]

            if (!_.includes(timeSlotsParent, slot)) {
                //console.log("childrenClusteredEvents[",cluster,"][",slot,"]:",childrenClusteredEvents[cluster][slot])
                parentTmp.clusteredEvents[cluster][slot] = {
                    event_count: parseInt(child.event_count),
                    items_count: parseInt(child.items_count),
                }
                //console.log("parent.clusteredEvents[",cluster,"][",slot,"]:",parent.clusteredEvents[cluster][slot])

            } else {
                //console.log("ELSEEE childrenClusteredEvents[",cluster,"][",slot,"]:",childrenClusteredEvents[cluster][slot])
                //console.log("ELSEEE parent.clusteredEvents[",cluster,"][",slot,"]:", parent.clusteredEvents[cluster][slot])
                parentTmp.clusteredEvents[cluster][slot].event_count += parseInt(child.event_count)
                parentTmp.clusteredEvents[cluster][slot].items_count += parseInt(child.items_count)
            }
        })
        return parentTmp.clusteredEvents
    }
}


/** fonde gli array dei prodotti dei singoli nodi (why) figli nel padre (where) */
export function fuseProducts(parent, childrenProducts) {
    return _.union(parent.products, childrenProducts)
}


/** filtra l'array dei nodi "nodes" con l'array di prodotti selezionati dall'utente "selectedProducts" */
export function filterProductGraph(nodes, selectedProducts) {
    const filter_nodes = []
    if(nodes && selectedProducts?.length !== 0) {
        nodes.forEach(n => {
            const found = n.products.some(r=> _.find(selectedProducts, ['key', r]))
            if(found){
                filter_nodes.push(n)
            }
        })
        //console.log("filterProductGraph:",filter_nodes)
        return filter_nodes
    } else return nodes
}


/** filtra l'array dei nodi "nodes" con l'array delle companies selezionate dall'utente "selectedCompanies" */
export function filterCompaniesGraph(nodes, selectedCompanies, aggregationType) {
    console.log("filterCompaniesGraph --> selectedCompanies:",selectedCompanies)
    const attribute = aggregationType === aggregationTypes.where ? 'label' : 'parent'
    const filter_nodes = []
    if(nodes && selectedCompanies) {
        nodes.forEach(n => {
            if(_.findIndex(selectedCompanies, function(o) { return o[0] === n[attribute]}) > -1) {
                filter_nodes.push(n)
            }
        })
    }
    console.log("filterCompaniesGraph:",filter_nodes)
    return filter_nodes
}

export function filterNodesByTags(nodes, productsLut, filterTags) {
    const filter_nodes_by_tags = []
    if(nodes && filterTags?.length !== 0) {
        //console.log("filterNodesByTags ---> filterTags:",filterTags)
        nodes.forEach(n => {
            const found = filterTags.some(r=> _.find(n.products, function(o) {
                return productsLut[o].tags.includes(r);
            }))
            if(found) {
                filter_nodes_by_tags.push(n)
            }
        })

        //console.log("filterNodesByTags:",filter_nodes_by_tags)
        return filter_nodes_by_tags
    } else return nodes
}

/** combina in OR i filtri su "products" e su "companies" */
// TODO: capire come gestire l'unione dei filtri
export function filterNodes(serverNodes, filterCompanies, filterProducts) {
    const filterCompaniesNodes = (filterCompanies && filterCompanies.length) ? filterCompaniesGraph(serverNodes, filterCompanies) : []
    const filterProductsNodes = (filterProducts && filterProducts.length) ? filterProductGraph(serverNodes, filterProducts) : []

    let filteredNodes = (filterCompaniesNodes.length !== 0 || filterProductsNodes.length !== 0) ? _.concat(filterCompaniesNodes, filterProductsNodes)
        : serverNodes
    //console.log("filterNodes:",filteredNodes)

    return filteredNodes
}

export function setProductLineHistoryNodes(serverNodes, filteredNodes, filterProducts) {
    const visited = {}
    const nodes = _.cloneDeep(filteredNodes)

    /***
     * const visited_2 = {}
     * function findPath(serverNodes, start, destination) {
        if (!visited_2[start.id]) {
            visited_2[start.id] = 1
            if (start.arcs) {
                for (const arcId in start.arcs) { // iterate over key, for of over values
                    const arcNode = _.find(serverNodes, ['id', arcId])
                    if (arcNode.id === destination.id) {
                        //console.log("trovato path!")
                        return true
                    } else {
                        //console.log("vado avanti con findPath!")
                        findPath(serverNodes, arcNode, destination)
                    }
                }
            }
        }

        return false;
    }*/

    function findNodes(node, selected_gtin, backward) {
        if (!visited[node.id + selected_gtin.key + backward]) {
            visited[node.id + selected_gtin.key + backward] = 1
            const newNodes = []
            //console.log("findNodes ---> node:", node, "selected_gtin:", selected_gtin)

            const gtins_out = node.in_out[selected_gtin.key]
            const gtins_in = node.out_in[selected_gtin.key]
            //console.log("findNodes ---> gtins_out:", gtins_out)
            //console.log("findNodes ---> gtins_in:", gtins_in)

            const gtins = backward ? gtins_in ? gtins_in : [] : gtins_out

            _.forEach(gtins, (gtin) => {
                //console.log("[gtin]:", [gtin])
                const candidateNodes = filterProductGraph(serverNodes, [{key: gtin}])
                //console.log("candidateNodes:", candidateNodes)
                _.forEach(candidateNodes, (n) => {
                    // forward-> inverti start e dest
                    //console.log("findPath --> start:", start, "destination:", destination)
                    //if(findPath(state.serverNodes, start, destination)) {
                    newNodes.push([n, {key: gtin}])
                    nodes.push(n)
                    //}
                })
            })

            _.forEach(newNodes, ([n, gtin]) => {
                //console.log("n:", n, "gtin:", gtin)
                findNodes(n, gtin, backward)
            })
        }
    }

    _.forEach(filterProducts, (selected_gtin) => {
        _.forEach(filteredNodes, (node) => {
            findNodes(node, selected_gtin, true)
        })
    })

    _.forEach(filterProducts, (selected_gtin) => {
        _.forEach(filteredNodes, (node) => {
            findNodes(node, selected_gtin, false)
        })
    })

    //console.log("setProductLineHistoryNodes:", _.uniqBy(nodes, 'id'))
    return _.uniqBy(nodes, 'id')
}

/** Crea un dizionario con i subGraphLink -> link che uniscono pezzi di grafo indirettamente connessi */
export function createSubGraphLinks(serverNodes, filteredNodes, expandedSublinks) {
    function createSubGraphMap(serverNodes, filteredNodes) { // nodes filtrati
        const subGraph = [] // lista di dizionari contenenti i nodi coinvolti

        filteredNodes.forEach(n => {
            // console.log("n:", n)
            if (n.arcs) {
                Object.keys(n.arcs).forEach((key) => {
                    const indexSource = _.findIndex(subGraph, function (o) {
                        return o[n.id];
                    })
                    const foundSource = subGraph[indexSource]

                    const indexTarget = _.findIndex(subGraph, function (o) {
                        return o[key];
                    })
                    const foundTarget = subGraph[indexTarget]
                    const newObj = {}

                    if (!foundSource) {
                        if (!foundTarget) {
                            newObj[n.id] = 1
                            newObj[key] = 1
                            subGraph.push(newObj)
                        } else {
                            foundTarget[n.id] = 1
                        }
                    } else {
                        if (!foundTarget) {
                            foundSource[key] = 1
                        } else {
                            if (indexTarget !== indexSource) {
                                _.pullAt(subGraph, [indexSource, indexTarget])
                                const mergedGroup = Object.assign({}, foundSource, foundTarget)
                                subGraph.push(mergedGroup)
                            }
                        }
                    }
                })
            }
        })
        //console.log("subGraph:", subGraph)

        const subGraphMap = {} // {nodeId: groupId}
        subGraph.forEach((group, index) => {
            Object.keys(group).forEach((nodeId) => {
                subGraphMap[nodeId] = index
            })
        })

        //console.log("subGraphMap:", subGraphMap)

        return [subGraph, subGraphMap]
    }
    const [subGraph, subGraphMap] = createSubGraphMap(serverNodes, filteredNodes)

    const subGraphLinks = {}
    const paths = {}
    let visited = {}

    function findExitNodes(group) {
        let exitNodes = []
        //console.log("findExitNodes --> group:",group)
        Object.keys(group).forEach((node) => {
            const curNode = _.find(serverNodes, ['id', node])
            if (curNode.arcs) {
                for (const arcId in curNode.arcs) { // iterate over key, for of over values
                    if (!group[arcId]) {
                        exitNodes.push(curNode)
                    }
                    //console.log("findExitNodes --> group[",arcId,"]:",group[arcId])
                }
            }
        })

        //console.log("exitNodes:",exitNodes)
        return exitNodes;
    }

    subGraph.forEach((group) => {

        const exitNodes = findExitNodes(group)
        exitNodes.forEach((node) => {
            // non è detto che tutti i nodi di group abbiano il prodotto, quindi nodes e non my modes
            //const node = _.find(nodes, ['id', nodeId])
            // resettare i visitati ogni volta
            visited = {};
            findPaths(node.id, node.id, group, []);
        })
    })

    function findPaths(startingNodeId, curNodeId, startingSubGraph, path) {
        //debugger
        if(!visited[curNodeId]) {
            visited[curNodeId] = 1

            const curNode = _.find(serverNodes, ['id', curNodeId])
            //console.log("findPaths --> path:",path)

            if (curNode && !startingSubGraph[curNode.id]
                && subGraphMap[curNode.id] && path.length > 1) {
                const id = `${subGraphMap[startingNodeId]}-->${subGraphMap[curNodeId]}`
                const newPath = _.uniqBy(path, 'id')

                if(!paths[id])
                    paths[id] = [newPath]
                else
                    paths[id].push(newPath)

                const linkId = `${path[0].id}-->${path[path.length-1].id}`
                subGraphLinks[linkId] = {
                    id:linkId,
                    source: path[0].id,
                    target: path[path.length-1].id,
                    subGraphLink: true,
                    children: newPath,
                    originalSource: path[0].id,
                    originalTarget: path[path.length-1].id
                }
            } else {
                if (curNode.arcs) {
                    for (const arcId in curNode.arcs) { // iterate over key, for of over values
                        //const arcNode = _.find(serverNodes, ['id', arcId])

                        if (_.findIndex(path, ['id', arcId]) === -1) {
                            //if(arcNode.parent !== curNode.parent)
                                path.push(curNode)
                            //console.log("CHILD PRIMA path:", path)

                            findPaths(startingNodeId, arcId, startingSubGraph, path)
                        }
                    }
                }
            }
        }
    }

    //console.log("paths:",paths)
    //console.log("subGraphLinks:",subGraphLinks)
    return subGraphLinks
}

/**
 * Crea la mappa dei nodi EFFETTIVAMENTE attivi che andranno passati dal grafico Dagre
 * se il nodo padre è stato espanso
 * ---> mette i nodi why (eventName || bizStep)
 * altrimenti
 * ---> lascia il nodo padre where (sgln) "compresso" (con all'interno i suoi children)
 *
 * parametro "nodesToAdd": serve nell'espansione dei subGraphLink
 * */
export function setDagreNodes(graphNodes, expandedNodes, aggregationType) {
    const activeNodes = {}

    graphNodes.forEach(n => {
        if(!expandedNodes[n.label]) {
            /** se già presente il nodo padre (non espanso) lo aggiorna */
            if (activeNodes[n.label]) {
                activeNodes[n.label].children[n.id] = n

                //fonde gli eventi clusterizzati dei singoli nodi (why) figli nel padre (where)
                activeNodes[n.label].clusteredEvents = fuseClusteredEvents(activeNodes[n.label], n)

                //aggiorna i prodotti del padre con quelli del figlio corrente
                activeNodes[n.label].products = fuseProducts(activeNodes[n.label], n.products)
            } else {
                /** altrimenti crea l'oggetto nodo padre a partire dai dati del primo figlio trovato */
                activeNodes[n.label] = {
                    //...n,
                    id: n.label,
                    label: n.label,
                    //parent: n.label,
                    loggedUser: n.loggedUser,
                    children: {[n.id]: n},
                    clusteredEvents: _.cloneDeep(n.clusteredEvents),

                    //aggiorna i prodotti del padre con quelli del figlio corrente
                    products: _.clone(n.products)
                }
                delete activeNodes[n.label].label
            }
        } else {
            activeNodes[n.id] = n
        }
    })

    //console.log("activeNodes:",activeNodes)

    return activeNodes
}


/**
 * Crea la mappa dei link EFFETTIVAMENTE attivi che andranno passati dal grafico Dagre
 * 1) sulla base dei NODI ATTIVI mi creo quella dei link a partire dal set completo dei link dati dal server
 * 2) creando i link attivi possono venire fuori nodi source o target non presenti nel set di nodi attivi
 * ---> se ciò capita li individuo e li aggiungo al set dei nodi attivi, come nodi aggiuntivi
 * */
export function setDagreLinks(serverNodes, serverLinks, subLinks, dagreNodes, filteredNodes, expandedNodes, childrenParentMap) {
    /** aggiorna i link attivi sulla base dei NODI ATTIVI */
    function setActiveLinks() {
        serverLinks.forEach(l => {
            if (((activeNodes[childrenParentMap[l.source]] || activeNodes[l.source]) /*&& _.some(filteredNodes, ['id', l.source])*/)
                && ((activeNodes[childrenParentMap[l.target]] || activeNodes[l.target]) /*&& _.some(filteredNodes, ['id', l.target])*/)) {
                //console.log("PRIMA l:", l)
                const source = `${!expandedNodes[childrenParentMap[l.source]] ? childrenParentMap[l.source] : l.source}`
                const target = `${!expandedNodes[childrenParentMap[l.target]] ? childrenParentMap[l.target] : l.target}`

                if (source !== target) { // evita i link a loop su se stessi
                    const linkId = `${source}-->${target}`
                    activeLinks[linkId] = {
                        ...l,
                        id: linkId,
                        source: source,
                        target: target
                    }
                    //console.log("DOPO l:", activeLinks[linkId])
                }
            }
        })
    }

    const activeNodes = dagreNodes
    const activeLinks = {}

    setActiveLinks()

    // aggiungo eventuali sublinks e li rimappo sui parent (se non espansi) di source e target
    Object.values(subLinks).forEach((sublink) => {
        const source = `${!expandedNodes[childrenParentMap[sublink.source]] ? childrenParentMap[sublink.source] : sublink.source}`
        const target = `${!expandedNodes[childrenParentMap[sublink.target]] ? childrenParentMap[sublink.target] : sublink.target}`

        const missingNodes = []

        if(!activeNodes[source] && !activeNodes[childrenParentMap[source]])
            missingNodes.push(_.find(serverNodes, ['id', sublink.source]))
        if(!activeNodes[target] && !activeNodes[childrenParentMap[target]])
            missingNodes.push(_.find(serverNodes, ['id', sublink.target]))

        missingNodes.forEach((missingNode) => {
            //console.log("missingNode:",missingNode)
            if (!expandedNodes[missingNode.label]) {
                /** se già presente il nodo padre (non espanso) lo aggiorna */
                if (activeNodes[missingNode.label]) {
                    activeNodes[missingNode.label] = {
                        ...activeNodes[missingNode.label],
                        children: {[missingNode.id]: missingNode},

                        //fonde gli eventi clusterizzati dei singoli nodi (why) figli nel padre (where)
                        clusteredEvents: Object.assign({},
                            activeNodes[missingNode.label].clusteredEvents,
                            missingNode.clusteredEvents),

                        //aggiorna i prodotti del padre con quelli del figlio corrente
                        products: _.union(activeNodes[missingNode.label].products, missingNode.products)
                    }
                } else {
                    /** altrimenti crea l'oggetto nodo padre a partire dai dati del primo figlio trovato */
                    activeNodes[missingNode.label] = {
                        ...missingNode,
                        id: missingNode.label,
                        label: missingNode.label,
                        children: {[missingNode.id]: missingNode},

                        //fonde gli eventi clusterizzati dei singoli nodi (why) figli nel padre (where)
                        clusteredEvents: Object.assign({}, missingNode.clusteredEvents),

                        //aggiorna i prodotti del padre con quelli del figlio corrente
                        products: missingNode.products
                    }
                    delete activeNodes[missingNode.label].label
                }
            } else {
                activeNodes[missingNode.id] = missingNode
            }
        })

        let linkId
        if((activeNodes[source] || activeNodes[childrenParentMap[source]])
            && (activeNodes[target] || activeNodes[childrenParentMap[target]])
            && (source !== target)) { // evita i link a loop su se stessi
            linkId = `${source}-->${target}`
            activeLinks[linkId] = {
                ...sublink,
                id: linkId,
                source: source,
                target: target,
            }
            //console.log("activeLinks[",linkId,"]:", activeLinks[linkId])
        }
    })

    // la richiamo per attivare eventuali link derivanti da missing nodes dei sublink
    setActiveLinks();

    //console.log("setDagreLinks ---> activeLinks:",activeLinks)
    //console.log("DOPO setDagreLinks ---> activeNodes:",activeNodes)

    return [activeLinks, activeNodes];
}
