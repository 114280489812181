import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Paper,
    Grid,
    Card,
    CardContent,
    Typography,
    CardActionArea
} from '@material-ui/core';
import Header from "../../components/Header";
import TuneIcon from '@material-ui/icons/Tune';
import WarningIcon from '@material-ui/icons/Warning';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import SecurityIcon from '@material-ui/icons/Security';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import axios from "axios";
import {RoutingContext, ThemeContext} from "../../contexts";
import ChangeCertificateDialog from "../../components/ChangeCertificateDialog";
import ChangeConfigurationDialog from "../../components/ChangeConfigurationDialog";
import DeviceDeleteDialog from "../../components/DeviceDeleteDialog";

const DeviceControlPanel = (props) => {

    const {primaryColor} = useContext(ThemeContext)

    const useStyles = makeStyles((theme) => ({
        row: {
            marginLeft: 20,
            marginBottom: 20,
            marginRight: "50%"
        },
        card: {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 120
        },
        icon: {
            width: 60,
            height: 60,
            marginRight: 8,
            color: primaryColor
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        error: {
            color: 'red',
            marginTop: 8
        }
    }));

    const data = props.location.state;

    const classes = useStyles();

    const myContext = useContext(RoutingContext);

    const [error, setError] = useState("")

    const [delDeviceOpen, setDeleteDeviceOpen] = useState(false);

    const [changeCertOpen, setChangeCertOpen] = useState(false);

    const [changeConfOpen, setChangeConfOpen] = useState(false);

    const [manAuthState, setManAuthState] = useState(data.manual_authorization)

    const onConfigurationClick = () => {
        axios.get('/api/configurations/_by_name/?name='+data.configuration)
            .then((res) => {
                console.log('res', res)
                myContext.setRoute('ConfigurationDetail', res.data);
            })
            .catch((err) => {
                console.log('err', err)
                setError(err.response.data)
            });
    }

    const onCertificateClick = () => {
        axios.get('/api/certificates/_by_common_name/?common_name='+data.name)
            .then((res) => {
                console.log('res', res)
                myContext.setRoute('CertificateDetail', res.data);
            })
            .catch((err) => {
                console.log('err', err)
                setError(err.response.data)
            });
    }

    const onManualAuthenticationClick = () => {
        axios({
            method: 'patch',
            url: '/api/devices/update_manual_authorization/'+data._id,
            data: {
                name: "device",
                object_owner: "none",
                configuration: "none",
                manual_authorization: !manAuthState
            }
        })
            .then((res) => {
                console.log('res', res)
                if (manAuthState)
                    setManAuthState(false)
                else
                    setManAuthState(true)
            })
            .catch((err) => {
                console.log('err', err)
                setError(err.response.data)
            });
    }

    return (
        <Box>
            <Paper>
                <Header/>
                <Grid container>
                    <Grid container className={classes.row} spacing={3} direction={"row"}>
                        <Grid item xs={6} sm={6} spacing={3} >
                            <Card >
                                <CardActionArea className={classes.card} onClick={() => {setChangeConfOpen(true)}}>
                                    <CardContent>
                                        <Typography component="h6" variant="h6">
                                            Change Configuration
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Select a configuration for the device from a list
                                        </Typography>
                                    </CardContent>
                                    <TuneIcon className={classes.icon}/>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={6} spacing={3} >
                            <Card >
                                <CardActionArea className={classes.card} onClick={() => {setChangeCertOpen(true)}}>
                                    <CardContent>
                                        <Typography component="h6" variant="h6">
                                            Change Certificate
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Revoke the current certificate and create a new certificate for the device
                                        </Typography>
                                    </CardContent>
                                    <WarningIcon className={classes.icon}/>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.row} spacing={3} direction={"row"}>
                        <Grid item xs={6} sm={6} spacing={3} >
                            <Card >
                                <CardActionArea className={classes.card} onClick={onConfigurationClick}>
                                    <CardContent>
                                        <Typography component="h6" variant="h6">
                                            Configuration
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            See device's configuration details
                                        </Typography>
                                    </CardContent>
                                    <PhonelinkSetupIcon className={classes.icon}/>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={6} spacing={3} >
                            <Card >
                                <CardActionArea className={classes.card} onClick={onCertificateClick}>
                                    <CardContent>
                                        <Typography component="h6" variant="h6">
                                            Certificate
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            See device's certificate details
                                        </Typography>
                                    </CardContent>
                                    <SecurityIcon className={classes.icon}/>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.row} spacing={3} direction={"row"}>
                        <Grid item xs={6} sm={6} spacing={3} >
                            <Card >
                                <CardActionArea className={classes.card} onClick={onManualAuthenticationClick}>
                                    <CardContent>
                                        <Typography component="h6" variant="h6">
                                            Manual Authentication
                                        </Typography>
                                        {
                                            manAuthState ?
                                                <Typography variant="subtitle1" color="textSecondary">
                                                    Enable/Disable manual authentication. Current state: Enabled
                                                </Typography> :
                                                <Typography variant="subtitle1" color="textSecondary">
                                                    Enable/Disable manual authentication. Current state: Disabled
                                                </Typography>
                                        }
                                    </CardContent>
                                    <LockIcon className={classes.icon}/>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={6} spacing={3} >
                            <Card >
                                <CardActionArea className={classes.card} onClick={() => {setDeleteDeviceOpen(true)}}>
                                    <CardContent>
                                        <Typography component="h6" variant="h6">
                                            Delete Device
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Delete the device, the associated user and revoke the certificate
                                        </Typography>
                                    </CardContent>
                                    <DeleteIcon className={classes.icon}/>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {error ? <p className={classes.error}>{error.detail}</p> : null}
            <ChangeCertificateDialog deviceId={data._id} open={changeCertOpen}
                                     onClose={() => {setChangeCertOpen(false)}}/>
            <ChangeConfigurationDialog deviceId={data._id} open={changeConfOpen}
                                       onClose={() => {setChangeConfOpen(false)}}
                                       configuration={data.configuration}/>
            <DeviceDeleteDialog deviceId={data._id} open={delDeviceOpen}
                                onClose={() => {delDeviceOpen(false)}}/>
        </Box>
    )
}

export default DeviceControlPanel

