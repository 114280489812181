import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {Box, Button} from "@material-ui/core";
import CustomChordSupplyChain from "./CustomChordSupplyChain";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import useAxios from "axios-hooks";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {convertToDomain, parseName,} from "../../utils/Utils";
import _ from "lodash";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import PersonPinIcon from '@material-ui/icons/PersonPin';
import {AuthContext, RoutingContext, ThemeContext} from "../../contexts";
import CustomLoader from "../../components/CustomLoader";
import IconButton from "@material-ui/core/IconButton";
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ListIcon from "@material-ui/icons/List";
import RelationshipsTable from "./RelationshipsTable";
import {v4 as uuidv4} from 'uuid';
import DoneIcon from "@material-ui/icons/Done";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import chroma from 'chroma-js'
import AddIcon from "@material-ui/icons/Add";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";

export const dataTypes = [
    '4W Events',
    'Master Data',
]

export const directionTypes = {
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
    ALL: 'all'
}

let uuid_all_relationships = uuidv4();

const initialState = {
    supplyChain: uuid_all_relationships, // null -> all chains
    ruleType: dataTypes[0],
    rules: null,
    selectedCompanies: [],
    directions: 'all',
    active: true
}

const SupplyChainGraph = () => {
    const { t } = useTranslation();

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            display: 'flex',
            alignItems: 'center',
        },
        error: {
            color: 'red',
            margin: '0px'
        },
        icon: {
            color: accentColor
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        relationDetail: {
            margin: '15px 15px 20px 15px'
        },
        denseTable: {
            minWidth: 600,
        },
        button: {
            color: theme.secondary,
            textTransform: "none"
        },
    }));
    const classes = useStyles()

    const authContext = useContext(AuthContext);
    const routingContext = useContext(RoutingContext);

    const [{data: chains, loading: loadingChains, error: errorChains},] = useAxios({
            url: `api/rules/_supply_chain_tags/?domain=${authContext.activeMainDomain}`
        },
        {useCache: false}
    )

    const [{data: companies, loading: loadingCompanies, error: errorCompanies},] = useAxios({
            url: "/api/companies/"
        },
        {useCache: false}
    )

    const descriptionElementRef = useRef(null);
    const [openDetailData, setOpenDetailData] = useState({
        open: false,
        detailData: null
    })

    const [state, setState] = useState(initialState)

    const [graphMode, setGraphMode] = useState(true)
    const handleGraphModeChange = () => {
        setGraphMode(!graphMode);
    };

    /** Filtri su API */
    const filters = useMemo(() => {
        let _filters = {
            chain: (state.supplyChain && state.supplyChain !== uuid_all_relationships) ? 'chain=' + state.supplyChain : null,
        }
        const _in = state.directions === directionTypes.INCOMING
        const _out = state.directions === directionTypes.OUTGOING

        if(state.directions !== '' && (_in || _out)) {
            _filters = {
                ..._filters,
                [directionTypes.INCOMING]: directionTypes.INCOMING + '=' + _in,
                [directionTypes.OUTGOING]: directionTypes.OUTGOING + '=' + _out,
            }
        }

        if(state.active !== null && state.active !== 'all') {
            _filters = {
                ..._filters,
                active: 'active=' + state.active,
            }
        }

        return _filters
    }, [state.supplyChain, state.directions, state.active])

    useEffect(() => {
        if (filters) {
            let url = "/api/rules/_filtered/?skip=0&limit=0&direction=1"

            filters && Object.entries(filters).forEach(([key, value], index) => {
                url += value ? ('&' + value) : ''
            })

            axios.get(url).then(res => {
                if (res) {
                    setState({...state, rules: res.data})
                }
            })
        }

        return () => {
            console.log("unmount")
        }//TODO: cancel axios
    }, [filters])

    const loggedCompany = useMemo(() => {
        if (!authContext)
            return null
        return {domain: authContext.activeMainDomain, name: authContext.companyName}
    }, [authContext])

    const brands = useMemo(() => {
        if (!companies)
            return null
        let _brands = []

        _.forEach(companies.data, (company) => {
            _brands = _.union(_brands, company.brands)
        })
        return _brands

    }, [companies])

    /** ASSEGNAMENTO INDICE NUMERICO A TUTTE LE AZIENDE COINVOLTE */
    const allCompanies = useMemo(() => {
        if (!state.rules || !state.ruleType || !brands || !loggedCompany)
            return null

        const allEntities = {}
        let index = 0

        // l'azienda loggata è sempre all'indice 0
        allEntities[loggedCompany.domain] = {
            relations: [],
            index: index++,
            name: loggedCompany.name,
            domain: loggedCompany.domain
        }

        state.rules.data.forEach((rule) => {
            const destination = convertToDomain(rule.destination)
            const source = convertToDomain(rule.source)

            if (!allEntities[source]) {
                allEntities[source] = {relations: [], index: index++}
            }
            allEntities[source].relations.push(rule)

            if (!allEntities[destination]) {
                allEntities[destination] = {relations: [], index: index++}
            }
            allEntities[destination].relations.push(rule)

            // Aggiunta dettagli company
            const compDetailsSource = _.find(brands, ['domain', source]);
            if (compDetailsSource) {
                allEntities[source] = {...allEntities[source], name: compDetailsSource.name, domain: source}
            }

            const compDetailsDest = _.find(brands, ['domain', destination]);
            if (compDetailsDest)
                allEntities[destination] = {
                    ...allEntities[destination],
                    name: compDetailsDest.name,
                    domain: destination
                }

        })

        return allEntities;
    }, [loggedCompany, state.rules, state.ruleType, brands])

    const companiesAutocompleteOptions = useMemo(() => {
        if (!allCompanies)
            return null

        const options = _.filter(allCompanies, function(o) { return authContext.activeMainDomain !== o.domain; })
        return _.map(options, (option) => ({domain: option.domain, name: option.name}))
    }, [allCompanies])

    /**
     * FILTRO SU AZIENDE SELEZIONATE NELL'AUTOCOMPLETE "Filter by company"
     * e CREAZIONE TUPLE "<source>|<destination>": { relations: [...rules], source: index, destination.index }
     * */
    const sourceDestPairs = useMemo(() => {
        if (!allCompanies || !state.selectedCompanies)
            return null

        const filterCompanies = [...state.selectedCompanies, loggedCompany]
        const _sourceDestPairs = {}

        state.rules.data.forEach((rule) => {
            const destination = convertToDomain(rule.destination)
            const source = convertToDomain(rule.source)

            if (!_sourceDestPairs[source + "|" + destination] && state.selectedCompanies.length === 0) {
                _sourceDestPairs[source + "|" + destination] = {
                    relations: [],
                    source: [source, allCompanies[source].index],
                    destination: [destination, allCompanies[destination].index]
                }
            }

            if (!_sourceDestPairs[source + "|" + destination] &&
                (_.findIndex(filterCompanies, ['domain', source]) > -1
                    && _.findIndex(filterCompanies, ['domain', destination]) > -1)) {
                _sourceDestPairs[source + "|" + destination] = {
                    relations: [],
                    source: [source, allCompanies[source].index],
                    destination: [destination, allCompanies[destination].index]
                }
            }

            if (_sourceDestPairs[source + "|" + destination])
                _sourceDestPairs[source + "|" + destination].relations.push(rule)

        })

        return _sourceDestPairs
    }, [allCompanies, state.selectedCompanies])

    /** PARSING DATA PER RENDER GRAFICO */
    const chordData = useMemo(() => {
        if(!sourceDestPairs)
            return null

        /** create chord data */
        const data = new Array(Object.keys(allCompanies).length).fill(null).map(() => new Array(Object.keys(allCompanies).length).fill(null)); // result
        let okData = false

        function setLength(value) {
            okData = true
            return value
        }

        Object.entries(sourceDestPairs).forEach(([key, value]) => {
            // creazione dati per il grafico chord
            data[value.source[1]][value.destination[1]] = value.relations ? setLength(value.relations.length) : null
        })

        return {data: data, show: okData};

    }, [sourceDestPairs])

    const handleChangeSupplyChainType = (event) => {
        setState({...state, supplyChain: event.target.value})
    };

    useEffect(() => {
        if (openDetailData) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDetailData])

    const handleClickOpen = (selectedData, allRelations) => {
        let res = {}
        if(graphMode) {
            function findRelations(source, target) {
                return _.filter(allCompanies[source].relations, function (o) {
                    return convertToDomain(o.source) === source && convertToDomain(o.destination) === target
                })
            }
            if (allRelations) {
                res = allCompanies[selectedData.id]
            } else {
                res = {name: selectedData.source.label + " - " + selectedData.target.label, relations: []}
                res.relations = _.concat(findRelations(selectedData.source.id, selectedData.target.id), findRelations(selectedData.target.id, selectedData.source.id))
            }
            setOpenDetailData({open: true, detailData: res})
        }
    };

    const handleClose = () => {
        setOpenDetailData({open: false});
    };

    const handleChangeDirections = (event, newDirections) => {
        if(newDirections !== null)
            setState({...state, directions: newDirections})
    }

    const handleChangeActive = (event, newState) => {
        if(newState !== null)
            setState({...state, active: newState})
    }

    const onAddRelationshipClick = () => {
        routingContext.setRoute('CreateRelationship');
    }

    let companiesLabel = t('entities.company.label_plural')
    let companyLabel = t('entities.company.label')
    return (
        <div>
            <Button
                className={classes.button}
                onClick={onAddRelationshipClick}
                startIcon={<AddIcon/>}>
                {t('relationshipsGraph.addNewRelation')}
            </Button>
            {chains && allCompanies && companiesAutocompleteOptions &&
                <Paper>
                    <div className={classes.root}>
                        <Box pt={2} pl={2}>
                            <Select
                                value={state.supplyChain}
                                displayEmpty
                                defaultValue={uuid_all_relationships}
                                onChange={handleChangeSupplyChainType}>
                                <MenuItem value={uuid_all_relationships} key={uuid_all_relationships}>{t('relationshipsGraph.allSupplyChains')}</MenuItem>))
                                {
                                    chains.map((chain) => (
                                        <MenuItem value={chain} key={chain}>{parseName(chain)}</MenuItem>))
                                }
                            </Select>
                            <FormHelperText>{t('relationshipsGraph.selectSupplyChain')}</FormHelperText>
                        </Box>
                        <Box pt={2} pl={2} style={{width: 'max-content'}}>
                            <ToggleButtonGroup value={state.directions}
                                               onChange={handleChangeDirections}
                                               exclusive
                                               size={"small"}
                                               aria-label="relationships-direction">
                                <ToggleButton value={directionTypes.INCOMING} aria-label={directionTypes.INCOMING}>
                                    <CallReceivedIcon fontSize={'small'} />
                                    {t('relationshipsGraph.incoming')}
                                </ToggleButton>
                                <ToggleButton value="all" aria-label="all">
                                    {t('entities.all')}
                                </ToggleButton>
                                <ToggleButton value={directionTypes.OUTGOING} aria-label={directionTypes.OUTGOING}>
                                    {t('relationshipsGraph.outgoing')}
                                    <CallMadeIcon fontSize={'small'} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box pt={2} pl={2} style={{width: 'max-content'}}>
                            <ToggleButtonGroup value={state.active}
                                               onChange={handleChangeActive}
                                               exclusive
                                               size={"small"}
                                               aria-label="relationships-active">
                                <ToggleButton value={true} aria-label="active">
                                    <NotificationsActiveIcon fontSize={'small'} />
                                    {t('relationshipsGraph.active_plural', {context: 'female'})}
                                </ToggleButton>
                                <ToggleButton value="all" aria-label="all">
                                    {t('entities.all')}
                                </ToggleButton>
                                <ToggleButton value={false} aria-label="inactive">
                                    {t('relationshipsGraph.inactive_plural', {context: 'female'})}
                                    <NotificationsOffIcon fontSize={'small'} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box pt={2} pl={2} style={{width: '20%'}}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                limitTags={2}
                                id="tags-standard"
                                options={companiesAutocompleteOptions}
                                getOptionLabel={(option) => option.name}
                                value={state.selectedCompanies}
                                onChange={(event, newSelection) => {
                                    setState({...state, selectedCompanies: newSelection});
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder={t('actions.add', {what: companiesLabel})}
                                    />
                                )}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.name}
                                            size='small'
                                            avatar={option.domain === authContext.activeMainDomain ?
                                                <PersonPinIcon fontSize={"small"}/> : null}
                                            style={option.domain === authContext.activeMainDomain ? {
                                                backgroundColor: chroma(accentColor).alpha(0.5).hex(),
                                                opacity: '0.5',
                                                marginBottom: '1%'
                                            } : {marginBottom: '1%'}}
                                            {...getTagProps({index})}
                                            disabled={option.domain === authContext.activeMainDomain}
                                            deleteIcon={option.domain === authContext.activeMainDomain ?
                                                <DoneIcon/> : null}
                                        />
                                    ))
                                }
                            />
                            <FormHelperText>{t('actions.filterBy', {by: companyLabel})}</FormHelperText>
                        </Box>
                        <Tooltip arrow title={t('relationshipsGraph.resetFilters')}>
                            <IconButton onClick={() => {setState({...state, active: true, supplyChain: uuid_all_relationships, directions: 'all', selectedCompanies: []})}}>
                                <SettingsBackupRestoreIcon/>
                            </IconButton>
                        </Tooltip>
                        <Box pr={2} style={{display: "flex", alignItems: "center",  marginLeft: 'auto'}}>
                            <IconButton
                                style={{color: primaryColor}}
                                onClick={handleGraphModeChange}>
                                {!graphMode ? <DataUsageIcon className={classes.icon}/> : <ListIcon className={classes.icon}/>}
                            </IconButton>
                            <Typography
                                style={{color: primaryColor}}>
                                {!graphMode ? t('entities.viewMode.graph') : t('entities.viewMode.table')}
                            </Typography>
                        </Box>
                    </div>
                    {
                        graphMode ?
                            <Box p={2} style={{height: 1000}}>
                                {
                                    chordData.show ?
                                        <CustomChordSupplyChain data={chordData.data}
                                                                companies={allCompanies}
                                                                handleClickOpen={handleClickOpen}/>
                                        :
                                        <Typography align={'center'} style={{color: 'darkgrey'}}>
                                            {t('errors.noDataToDisplay')}
                                        </Typography>
                                }
                            </Box>
                            : sourceDestPairs ?
                            <RelationshipsTable
                                ruleType={state.ruleType}
                                relations={(() => {
                                    let _relationships = []
                                    _.forEach(sourceDestPairs, (pair) => {
                                        _relationships = _.union(_relationships, pair.relations)
                                    })
                                    return _relationships
                                })()}
                                companies={allCompanies}/>
                                : <CustomLoader size={80} />
                    }
                    <Dialog maxWidth={"md"} open={openDetailData.open} onClose={handleClose} scroll='paper'>
                        <DialogContent ref={descriptionElementRef} tabIndex={-1} style={{padding: 0}}>
                            <Box p={2} style={{backgroundColor: '#fafafa'}}>
                                {
                                    openDetailData.detailData && openDetailData.detailData.relations ?
                                        <Box pl={3} pr={3} pb={3} pt={0}>
                                            <Typography className={classes.relationDetail} variant="h6" gutterBottom
                                                        component="div">
                                                {openDetailData.detailData.name ? openDetailData.detailData.name : 'Admin'}
                                            </Typography>
                                            {
                                                <RelationshipsTable
                                                    ruleType={state.ruleType}
                                                    relations={openDetailData.detailData.relations}
                                                    companies={allCompanies}/>
                                            }
                                        </Box> : null
                                }
                            </Box>
                        </DialogContent>
                    </Dialog>
                </Paper>
            }
            {(loadingChains || loadingCompanies) && <CustomLoader size={80}  text={t('actions.loading')}/>}
            {(errorChains || errorCompanies) && <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.genericServerError')}</Typography>}
        </div>
    )
}

export default SupplyChainGraph
