import * as d3 from "d3";
import {format} from "date-fns";
import {it, enGB, zhCN } from "date-fns/locale";

export const TimeScaleEnum = Object.freeze({
    one_hour:'one_hour',
    four_hours:'four_hours',
    one_day:'one_day',
    one_week:'one_week',
    one_month:'one_month',
    three_months:'three_months',
    one_year:'one_year'
})


export function computeTimeSlot(timeScale) {
    switch (timeScale) {
        case TimeScaleEnum.one_hour:
            return (date) => Math.floor(date.getTime()/3600000)
        case TimeScaleEnum.four_hours:
            return (date) => Math.floor(date.getTime()/(3600000*4))
        case TimeScaleEnum.one_day:
            return (date) => Math.floor(date.getTime()/(3600000*24))
        case TimeScaleEnum.one_week:
            return (date) => Math.floor(date.getTime()/(3600000*24*7))
        case TimeScaleEnum.one_month:
            return (date) => (date.getFullYear()-1970)*12+date.getMonth()
        case TimeScaleEnum.three_months:
            return (date) => (date.getFullYear()-1970)*4+Math.floor(date.getMonth()/3)
        case TimeScaleEnum.one_year:
            return (date) => (date.getFullYear()-1970);
        default:
            throw new Error(`Unknown TimeScaleEnumValue: ${timeScale}`)
    }
}

export function groupByTime(items, key, timeScale) {
    let f1 = computeTimeSlot(timeScale)
    let f2= (item) => f1(item[key]);
    return items.reduce(
        (result,item) => {
            const key = f2(item);
            if (!result[key]) result[key] = []
            result[key].push(item)
            return result
        },
        {}
    )
}

export function getTimeTick(timeScale) {
    switch (timeScale) {
        case TimeScaleEnum.one_hour:
            return d3.timeHour
        case TimeScaleEnum.four_hours:
            return d3.timeHour.every(4)
        case TimeScaleEnum.one_day:
            return d3.timeDay
        case TimeScaleEnum.one_week:
            return d3.timeWeek
        case TimeScaleEnum.one_month:
            return d3.timeMonth
        case TimeScaleEnum.three_months:
            return d3.timeMonth.every(3)
        case TimeScaleEnum.one_year:
            return d3.timeYear
        default:
            throw new Error(`Unexpected TimeScaleEnum value ${timeScale}`)
    }
}

export function getTimeCluster(timeSlot, timeScale, v=0) {
    const from = new Date()
    const to = new Date()
    switch (timeScale) {
        case TimeScaleEnum.one_hour:
            from.setTime((timeSlot+v)*3600000)
            to.setTime((timeSlot+v+1)*3600000)
            break;
        case TimeScaleEnum.four_hours:
            from.setTime((timeSlot+v)*3600000*4)
            to.setTime((timeSlot+v+1)*3600000*4)
            break;
        case TimeScaleEnum.one_day:
            from.setTime((timeSlot+v)*3600000*24)
            to.setTime((timeSlot+v+1)*3600000*24)
            break;
        case TimeScaleEnum.one_week:
            from.setTime((timeSlot+v)*3600000*24*7)
            to.setTime((timeSlot+v+1)*3600000*24*7)
            break;
        case TimeScaleEnum.one_month:
            from.setFullYear(1970+Math.floor((timeSlot+v)/12), ((timeSlot+v)%12),1)
            from.setHours(0,0,0,0)
            to.setFullYear(1970+Math.floor((timeSlot+v+1)/12), ((timeSlot+v+1)%12),1)
            to.setHours(0,0,0,0)
            break;
        case TimeScaleEnum.three_months:
            from.setFullYear(1970+Math.floor((timeSlot+v)/4), ((timeSlot+v)%4)*3,1)
            from.setHours(0,0,0,0)
            to.setFullYear(1970+Math.floor((timeSlot+v+1)/4), ((timeSlot+v+1)%4)*3,1)
            to.setHours(0,0,0,0)
            break;
        case TimeScaleEnum.one_year:
            from.setFullYear(1970+timeSlot+v, 0,1)
            from.setHours(0,0,0,0)
            to.setFullYear(1970+timeSlot+v+1, 0,1)
            to.setHours(0,0,0,0)
            break;
        default:
            throw new Error(`Unexpected TimeScaleEnum value ${timeScale}`)
    }
    return [from,to]
}

export function getTimeClusters(date,timeScale) {
    const timeSlot = computeTimeSlot(timeScale)(date)
    return [-3,-2,-1,0,1,2,3].map(v => getTimeCluster(timeSlot, timeScale, v))
}

export function getTimeInterval(date, timeScale) {
    const from = new Date(date.getTime())
    const to = new Date(date.getTime())
    switch (timeScale) {
        case TimeScaleEnum.one_hour:
            from.setTime(from.getTime()-3600000*3)
            to.setTime(to.getTime()+3600000*3)
            break;
        case TimeScaleEnum.four_hours:
            from.setTime(from.getTime()-3600000*12)
            to.setTime(to.getTime()+3600000*12)
            break;
        case TimeScaleEnum.one_day:
            from.setTime(from.getTime()-3600000*24*3)
            to.setTime(to.getTime()+3600000*24*3)
            break;
        case TimeScaleEnum.one_week:
            from.setTime(from.getTime()-3600000*24*7*3)
            to.setTime(to.getTime()+3600000*24*7*3)
            break;
        case TimeScaleEnum.one_month:
            from.setMonth(from.getMonth()-3)
            to.setMonth(to.getMonth()+3)
            break;
        case TimeScaleEnum.three_months:
            from.setMonth(from.getMonth()-3*3)
            to.setMonth(to.getMonth()+3*3)
            break;
        case TimeScaleEnum.one_year:
            from.setFullYear(from.getFullYear()-3)
            to.setFullYear(to.getFullYear()+3)
            break;
        default:
            throw new Error(`Unexpected TimeScaleEnum value ${timeScale}`)
    }
    return [from,to]
}

export function getSliderMarks(oldestDate, mostRecentDate, timeScale) {
    const minDate = new Date(oldestDate);
    const maxDate = new Date(mostRecentDate);
    const minTimeSlot = computeTimeSlot(timeScale)(minDate)
    const maxTimeSlot = computeTimeSlot(timeScale)(maxDate)
    const marks = [];
    for (let currentTimeslot = minTimeSlot; currentTimeslot <= maxTimeSlot; currentTimeslot++) {
        marks.push({value: getTimeCluster(currentTimeslot, timeScale)[0].getTime(), label: ""});
    }
    return marks;
}

export function getSliderLabel(sliderDate, timeScale) {
    const locale  = localStorage.getItem("iChain_lang") || "en";
    const formatLocale = locale === "zh" ? zhCN : locale === "it" ? it : enGB;
    switch (timeScale) {
        case TimeScaleEnum.one_hour:
            return format(sliderDate, "d/M/yyyy HH:mm:ss", {locale: formatLocale})
        case TimeScaleEnum.four_hours:
            return format(sliderDate, "d/M/yyyy HH:mm:ss", {locale: formatLocale})
        case TimeScaleEnum.one_day:
            return format(sliderDate, "d/M/yyyy", {locale: formatLocale})
        case TimeScaleEnum.one_week:
            return format(sliderDate, "d/M/yyyy", {locale: formatLocale})
        case TimeScaleEnum.one_month:
            return format(sliderDate, "MMMM yyyy", {locale: formatLocale})
        case TimeScaleEnum.three_months:
            return format(sliderDate, "MMMM yyyy", {locale: formatLocale})
        case TimeScaleEnum.one_year:
            return format(sliderDate, "yyyy", {locale: formatLocale})
        default:
            throw new Error(`Unknown TimeScaleEnumValue: ${timeScale}`)
    }
}
