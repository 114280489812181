import React from "react";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Controller} from "react-hook-form";
import UploadImage from "../UploadImage";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    prodBox: {
        marginBottom: '3%',
        [theme.breakpoints.up('md')]: {
            height: '200px',
        },
    },
    error: {
        color: 'red',
        margin: '0px'
    },
    preview: {
        width: "160px",
        height: "170px"
    },
    grid: {
        [theme.breakpoints.down('sm')]: {
            //minHeight: '300px'
        },
        borderRadius: '10px',
        border: '2px solid lightGray',
        borderStyle: 'dotted',
        //boxShadow: theme.shadows[1]
    }
}));

const BaseInfo = ({control, errors}) => {
    const {t} = useTranslation()
    const classes = useStyles();

    const ProductNameInput = ({value, onChange}) => {
        return (
            <TextField fullWidth label={t('entities.product.name')} value={value || ''} onChange={onChange}/>
        )
    };

    const ProductDescriptionInput = ({value, onChange}) => {
        return (
            <TextField fullWidth label={t('entities.description')} value={value || ''} onChange={onChange}/>
        )
    };

    return (
        <Box pl={2} pr={2} className={classes.prodBox}>
            <Grid container spacing={3}>
                <Grid container item xs={12} md={8}>
                    <Grid item xs={11}>
                        <Controller
                            as={ProductNameInput}
                            name="name"
                            control={control}
                        />
                        {errors.name &&
                        <p className={classes.error}>{errors.name.message}</p>}

                    </Grid>
                    <Grid item xs={11}>
                        <Box mt={1}>
                            <Controller
                                as={ProductDescriptionInput}
                                name="description"
                                control={control}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={2}>
                            <Controller
                                as={<FormControlLabel control={<Checkbox color="default"/>}
                                                      label={t('entities.product.activeProduct')} labelPlacement="end"/>}
                                name="active"
                                control={control}
                                type="checkbox"
                                defaultValue={true}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={4} className={classes.grid} justify={'center'} alignContent={'center'}>
                    <Controller
                        as={<UploadImage/>}
                        name="image"
                        control={control}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default BaseInfo