import React, {useContext, useEffect, useMemo, useState} from "react";
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import {PinnedIcon, UnpinnedIcon} from "../../components/CustomIcons";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import {parseName} from "../../utils/Utils";
import Typography from "@material-ui/core/Typography";
import useAxios from "axios-hooks";
import {ThemeContext} from "../../contexts";
import TagController from "../../components/tag_management/TagController";
import chroma from 'chroma-js'
import {findCodeTypeAndValue} from "../../utils/CodesUtils";
import Chip from "@material-ui/core/Chip";
import {useTranslation} from "react-i18next";
import _ from 'lodash'
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import i18next from "../../i18n/i18next";

const TableWhatEvents = ({what, filters, setFilters, setSkip}) => {
    const { t } = useTranslation();
    //const t0 = performance.now()

    console.log("FF what:", what)

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        row: {
            cursor: 'pointer',
            '&:hover': {
                background: "#fafafa",
            },
        },
        selectedRow: {
            cursor: 'pointer',
            '&:hover': {
                //background: "#fafafa",
            },
            //background: "darkgray",
            background: chroma(accentColor).alpha(0.9).hex(),
        },
        selectedCells: {
            color: 'white'
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        date: {
            fontSize: '0.725rem',
        },
        codeTypeChip: {
            marginRight: '3%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
        codeTypeChipSelected: {
            marginRight: '3%',
            color: 'white',
            border: '1px solid white',
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        }
    }));
    const classes = useStyles();
    const [selected, setSelected] = useState({isSelected: false, selection: ''})
    const [gtinNameLUT, setGtinNameLUT] = useState(null)

    const [{data: itemsByKeys}, postExecute] = useAxios(
        {
            url: "api/items/_by_keys/",
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    useEffect(() => {
        const keys = Object.keys(what)
        postExecute({data: {keys: keys}})
    }, [what, postExecute])

    useMemo(() => {
        if (!itemsByKeys || itemsByKeys.data.length < 1)
            return

        //console.log("itemsByKeys:",itemsByKeys)
        let nameLut = {}

        itemsByKeys.data.forEach((item) => {
            nameLut[item.key] = {_id: item._id, name: item.name, tags: item.tags}
        })

        //console.log("nameLut:",nameLut)
        setGtinNameLUT(nameLut)

    }, [itemsByKeys])

    useEffect(() => {
        if (!filters.gtin) {
            setSelected({
                isSelected: false,
                selection: ''
            })
        }
    }, [filters])

    const onPinIconClick = (event, gtin) => {
        console.log('ho cliccato su ->', gtin)
        let selection = ''
        if (selected.isSelected) {
            selection = ''
            setSkip(0)
            setFilters({
                ...filters,
                gtin: '',
                itemName: ''
            })
        } else {
            selection = gtin
            setSkip(0)
            setFilters({
                ...filters,
                gtin: "what=" + gtin,
                itemName: gtinNameLUT && gtinNameLUT[gtin] ? gtinNameLUT[gtin].name : ''
            })
        }

        setSelected({
            isSelected: !selected.isSelected,
            selection: selection
        })
    }

    const CustomDataField = ({data}) => {
        //let date = Moment(data).format('YYYY-MM-DD')
        //let time = Moment(data).format('HH:mm')
        const dateTime = t("formatters:dateTime", {date: Moment(data)})
        return <Typography className={classes.date}>{dateTime}</Typography>
    }

    const CustomEventField = ({bizStep, date}) => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid item>
                        {bizStep}
                    </Grid>
                    <Grid item>
                        <CustomDataField data={date}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const itemLabel = t('entities.item') // esplicito se no non la estrae

    //const t1 = performance.now()
    //t1-t0 > 0 && console.log("-------------------------------------------- TIME TableWhatEvents --->", t1-t0)

    return (
        <Box pb={2}>
            {gtinNameLUT && filters && setFilters && <Autocomplete
                style={{width: '80%', marginBottom: 8}}
                options={Object.keys(what)}
                getOptionLabel={(option) => gtinNameLUT[option] ? (`${gtinNameLUT[option]?.name} - ${option}`) : option}
                value={selected.selection}
                onChange={(event, newValue) => {
                    console.log("newValue:", newValue)
                    onPinIconClick(event, newValue)
                }}
                loading={!gtinNameLUT}
                renderInput={(params) => <TextField {...params} size={'small'}
                                                    label={_.startCase(i18next.t('entities.product.label'))}/>}
                renderOption={(option) => {
                    return (<React.Fragment>
                            <ListItemText
                                primary={gtinNameLUT[option] ? (`${gtinNameLUT[option]?.name}`) : option}
                                secondary={gtinNameLUT[option] ? option : ""}
                                style={{color: primaryColor}}/>
                        </React.Fragment>
                    )
                }}
            />}
            {//gtinNameLUT ?
                <TableContainer style={{maxHeight: '500px', overflow: 'scroll'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{_.startCase(t('entities.name'))} - {_.startCase(t('entities.code'))}</TableCell>
                                <TableCell>Tags</TableCell>
                                {/*<TableCell align="right">#{_.startCase(t('entities.item_plural'))}</TableCell>*/}
                                <TableCell align="right">#{_.startCase(t('entities.event_plural'))}</TableCell>
                                <TableCell align="right">{t('incomingEvents.what.lastEvent')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.entries(what).map(([key, value]) => {
                                    const classCode = findCodeTypeAndValue(key)
                                        return <TableRow key={key}
                                                         className={selected.selection === key ? classes.selectedRow : classes.row} /*onClick={(event) => onPinIconClick(event, key)}*/>
                                            <TableCell component="th" scope="row"
                                                       className={selected.selection === key ? classes.selectedCells : null}>
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        <Tooltip
                                                            title={!selected.isSelected ? t('actions.filterBy', {by: itemLabel}) : t('actions.clear', {what: t('entities.filter')})}
                                                            arrow>
                                                            <IconButton onClick={(e) => {
                                                                onPinIconClick(e, key, value)
                                                            }}>
                                                                {
                                                                    selected.selection === key ?
                                                                        <PinnedIcon style={{color: 'white'}}/>
                                                                        :
                                                                        <UnpinnedIcon style={{color: 'lightgray'}}/>
                                                                }
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemAvatar>
                                                    <ListItemText>
                                                        <Typography
                                                            className={classes.name}>{gtinNameLUT && gtinNameLUT[key] ? gtinNameLUT[key].name : ""}</Typography>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <Chip
                                                                size={'small'}
                                                                className={selected.selection === key ? classes.codeTypeChipSelected : classes.codeTypeChip}
                                                                label={classCode[0].type}
                                                            />
                                                            {
                                                                classCode[2] ?
                                                                    <Chip
                                                                        size={'small'}
                                                                        className={selected.selection === key ? classes.codeTypeChipSelected : classes.codeTypeChip}
                                                                        label={classCode[2]}
                                                                    /> : null}
                                                            <Typography
                                                                className={classes.code}>{/*(classCode[2] ? classCode[2] + ':' : '') +*/ classCode[1]}</Typography>
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    gtinNameLUT && gtinNameLUT[key] ?
                                                        <TagController selected={selected.selection === key}
                                                                       product={gtinNameLUT[key]} value={value} editable={true}/> : null
                                                }
                                            </TableCell>
                                            {/*<TableCell align="right"
                                                       className={selected.selection === key ? classes.selectedCells : null}>{value.n_items}</TableCell>*/}
                                            <TableCell align="right"
                                                       className={selected.selection === key ? classes.selectedCells : null}>{value.n_events}</TableCell>
                                            <TableCell align="right"
                                                       className={selected.selection === key ? classes.selectedCells : null}>
                                                <CustomEventField
                                                    bizStep={parseName(value.last_event.biz_step)}
                                                    date={value.last_event.date}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    }
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                //: <Typography style={{color:'darkgrey'}}>Error parsing items</Typography>
            }
        </Box>
    )
}

export default TableWhatEvents
