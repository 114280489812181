import React, {Fragment, useContext, useMemo, useState} from "react";
import {Box, TextField} from "@material-ui/core";
import {AuthContext, CreateEventContext, ThemeContext} from "../../contexts";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@material-ui/lab";
import useAxios from "axios-hooks";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

const AssociationParentCodeBuilder = ({upperState, setUpperState, field, classes}) => {
    const {t} = useTranslation()

    const {activeMainDomain} = useContext(AuthContext)

    const [state, setState] = useState({bizLoc: null, readPoint: null})

    const eventContext = useContext(CreateEventContext)
    const {accentColor} = useContext(ThemeContext)

    const [{data: _places, loading: loadingPlaces},] = useAxios({
            url: "/api/places/?domain=" + activeMainDomain + "&mine=true&not_mine=true",
        },
        {useCache: false}
    )

    const [{data: _subplaces, loading: loadingSubplaces},] = useAxios({
            url: "/api/subplaces/?domain=" + activeMainDomain + "&mine=true&not_mine=true",
        },
        {useCache: false}
    )

    const subplaces = useMemo(() => {
        if (!_subplaces)
            return null

        let filteredSubPlaces = _.filter(_subplaces.data, function (sub) {
            return !_.endsWith(sub.sgln, '.0')
        })

        if (state.bizLoc) {
            filteredSubPlaces = _.filter(filteredSubPlaces, ['place_id', state.bizLoc.gln || ''])
        }

        return filteredSubPlaces
    }, [_subplaces, _places, state.bizLoc])

    function handleSetParentID() {
        const parentID = {type: state.readPoint ? t('cbvValues.readPoint.name'): t('cbvValues.bizLocation.name'),
            value: state.readPoint?.sgln || state.bizLoc?.gln}

        eventContext.setValidationField('what', field.name, true)
        eventContext.setFormField('what', field.name, parentID)
        setUpperState({...upperState, parentID: parentID})
    }

    return (
        <Box pt={2} pr={2} pl={2} className={classes.builderArea}>
            <Box display={'flex'}>
                <Autocomplete
                    style={{marginRight: '2%'}}
                    fullWidth
                    size={'small'}
                    loading={loadingPlaces}
                    options={_places?.data || []}
                    value={state.bizLoc}
                    groupBy={(option) => option.data_origin}
                    onChange={(event, newValue) => {
                        setState({bizLoc: newValue, readPoint: null});
                    }}
                    getOptionLabel={(option) => option.name + ' - ' + option.data_origin}
                    renderOption={(option, {selected}) => {
                        return <Fragment>
                            <Chip
                                size={'small'}
                                label={option.data_origin}
                                className={classes.labelChip}
                            />
                            <Typography style={{marginLeft: '2%'}}>{option.name}</Typography>
                        </Fragment>
                    }}

                    renderInput={(params) =>
                        <TextField {...params} label={t('cbvValues.bizLocation.name')} variant="outlined"
                                   error={!upperState.parentID}/>}
                />
                <Autocomplete
                    fullWidth
                    size={'small'}
                    loading={loadingSubplaces}
                    options={subplaces || []}
                    value={state.readPoint}
                    groupBy={(option) => option.data_origin}
                    onChange={(event, newValue, reason) => {
                        if(reason !== "clear") {
                            const bizLoc = _.find(_places.data, ['gln', newValue?.place_id || ''])
                            setState({readPoint: newValue, bizLoc: bizLoc});
                        } else setState({...state, readPoint: newValue});
                    }}
                    getOptionLabel={(option) => option.name + ' - ' + option.data_origin}
                    renderInput={(params) => <TextField {...params} label={t('cbvValues.readPoint.name')}
                                                        variant="outlined"/>}
                    renderOption={(option, {selected}) => {
                        return <Fragment>
                            <Chip
                                size={'small'}
                                label={option.data_origin}
                                className={classes.labelChip}
                            />
                            <Typography style={{marginLeft: '2%'}}>{option.name}</Typography>
                        </Fragment>
                    }}
                />
            </Box>
            <Box p={2} style={{display: 'flex', margin: 'auto'}}>
                <Button aria-label="set"
                        disabled={!state.bizLoc}
                        onClick={handleSetParentID}
                        endIcon={<CheckCircleIcon
                            style={{
                                color: (!state.bizLoc) ? 'lightgray': accentColor}}/>
                        }>
                    {t('actions.confirm')}
                </Button>
            </Box>
        </Box>
    )
}

export default AssociationParentCodeBuilder
