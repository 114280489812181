import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {Box, Button, Fade, Slide, TextField, Typography} from '@material-ui/core';
import {AuthContext, ThemeContext} from "../../contexts"
import useAxios from 'axios-hooks';
import LockIcon from '@material-ui/icons/Lock';
import Paper from "@material-ui/core/Paper";
import {login} from "./auth";
import {Link, Redirect} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import chroma from "chroma-js";
import Avatar from "@material-ui/core/Avatar";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../my_profile_settings/LanguageSelect";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const Login = (props) => {
    const { t } = useTranslation();

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '70%',
            justifyContent: "center",
            display: "block",
            margin: 'auto',
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        grid: {
            justifyContent: "center",
            alignItems: "center",
            padding: '1%',
        },
        paper: {
            borderRadius: '15px'
        },
        container: {
            padding: '6%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        button: {
            //textTransform: 'none',
            marginTop: '8%',
            marginBottom: '2%',
            backgroundColor: chroma(accentColor).alpha(0.9).hex(),
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '25px'
        },
        sponsored: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexWrap: 'wrap'
        },
        lockAvatar: {
            color: 'white',
            backgroundColor: primaryColor,
            //marginBottom: '0.5em'
        },
        form: {
            width: '70%',
        },
    }));
    const classes = useStyles();

    const authContext = useContext(AuthContext);
    const [user, setUser] = useState({userName: "", password: ""})
    const [showPassword, setShowPassword] = useState(false)

    const [{data: postToken, loading: postLoading, error: postError}, postExecute] = useAxios(
        {
            url: "auth/token",
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: "post",
            data: "username=" + user.userName.trim() + "&password=" + user.password
        },
        {manual: true}
    );

    /*useEffect( () => {
        if(authContext.auth){
            history.push('/')
        }
    }, [])*/

    // Qui non serve la use effect: se hai postToken lo setti subito, altrimenti no
    // useEffect(() => {
    if (postToken) {
        login(postToken)
        authContext.setAuthentication(true)
    } else {
        authContext.setAuthentication(false)
    }
    // },[postToken])


    const updateForm = (data) => {
        setUser(user => ({...user, ...data}))
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            postExecute()
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Fade in={true} appear={true} timeout={700}>
                    <div>
                        <Slide in={true} appear={true} direction={'left'} timeout={500}>
                            <div className={classes.container}>
                                <LanguageSelect/>
                                <Avatar className={classes.lockAvatar}>
                                    <LockIcon/>
                                </Avatar>
                                <img alt={"logo_iChain"} src="/assets/logo_iChain.png" style={{width: '35%'}}/>
                                <Collapse in={!!postError}>
                                    <Grid item xs={12} style={{marginBottom: '4%', marginTop: '4%'}}>
                                        <Alert severity="error">{t('errors.wrongUsernameOrPassword')}</Alert>
                                    </Grid>
                                </Collapse>
                                <form className={classes.form}>
                                    <TextField fullWidth={true} value={user.userName}
                                               onChange={e => updateForm({userName: e.target.value.trim()})}
                                               label={t("profile.username")}
                                               onKeyPress={onEnter}
                                    /><br/>
                                    <TextField fullWidth={true}
                                               value={user.password}
                                               onChange={e => updateForm({password: e.target.value})}
                                               label={t("profile.password")}
                                               type={showPassword ? 'text' : 'password'}
                                               onKeyPress={onEnter}
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={() => {setShowPassword(!showPassword)}}
                                                               onMouseDown={handleMouseDownPassword}
                                                               edge="end">
                                                               {showPassword ? <Visibility /> : <VisibilityOff />}
                                                           </IconButton>
                                                       </InputAdornment>
                                                   //
                                               }}
                                    /><br/>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={() => {
                                            postExecute()
                                        }}
                                        disabled={user.userName === "" || user.password === ""}
                                    >Login</Button><br/>
                                    <Link to="/ForgotPassword">
                                        <Box display={'flex'} justifyContent={'flex-end'}>
                                            <Typography align={'right'} variant={'caption'}>
                                                {t('profile.changePasswordForm.iForgotPassword')}
                                            </Typography>
                                        </Box>
                                    </Link>
                                </form>
                            </div>
                        </Slide>

                    </div>
                </Fade>
            </Paper>
            <Box className={classes.sponsored} p={1}>
                <Typography style={{color: 'darkgrey'}}>{t('poweredBy')}</Typography>
                <img alt={"logo_wise_side"} src="/assets/ws_logo_20_ago_2021.png" style={{marginLeft:'1%', width: '17%'}}/>
            </Box>
            {authContext.auth ? <Redirect to={'/'}/> : null}
            {postLoading ? <p>{t('actions.loading', {what: ''})}</p> : null}
            {/*postError ? <p>ERROR: {JSON.stringify(postError)}</p> : null*/}
        </div>
    )
}

export default Login;
