import {useContext} from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {LinkIcon} from "../../components/CustomIcons";
import {ThemeContext} from "../../contexts";
import Box from "@material-ui/core/Box";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import IconButton from "@material-ui/core/IconButton";
import {GetIconComponent, pathPageObj} from "../../utils/LayoutUtils";
import _ from 'lodash'

const ButtonPageComponent = ({path, goTo}) => {
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles(() => ({
        boxBar: {
            width: '100%',
            height: '100%',
            padding: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        arrowBox: {
            position: 'absolute',
            bottom: '0',
            right: '0',
        },
        dragBox: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        title: {
            fontSize: '0.9rem',
            textAlign: 'center',
            margin: '0px',
            cursor: 'default',
        },
        description: {
            textAlign: 'center',
            margin: '0px',
            fontSize: '0.6rem',
            cursor: 'default',
        },
        iconShortcut: {
            width: '0.9em',
            height: '0.9em',
            cursor: 'pointer',
            color: accentColor
        },
        iconDrag: {
            color: 'lightgrey',
            width: '0.75em',
            height: '0.75em',
            cursor: 'grab'
        },
    }));
    const classes = useStyles()

    const objPage = _.find(pathPageObj(), ['path', path])
    console.log("path:",path)
    return (
        <Paper className={classes.boxBar}>
            <Box pr={2} pt={3} style={{position: 'absolute',
                top: '0',
                right: '0',}} className="drag-handle">
                <DragIndicatorIcon className={classes.iconDrag} />
            </Box>
            <Box p={1}>
                <Grid container
                      height="100%"
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                >
                    <Grid item xs={12}>
                        <GetIconComponent path={path}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={1} pb={1}>
                            {(objPage?.title || '').split('\n').map(
                                (str, index) =>
                                    <p className={classes.title}
                                       style={{color: primaryColor}}
                                       key={str}>{str}</p>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.description}>{objPage?.description || ''}</p>
                    </Grid>
                </Grid>
            </Box>
            <Box pr={1} pb={1} className={classes.arrowBox}>
                <IconButton  aria-label="link" onClick={() => goTo(path)}>
                    <LinkIcon className={classes.iconShortcut}/>
                </IconButton>
            </Box>
        </Paper>
    )
}

export default ButtonPageComponent
