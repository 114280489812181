import {extractLocationClassCode, findLocationCodeTypeValue} from "../../../utils/CodesUtils";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import React, {useContext, useEffect, useMemo} from "react";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import StarsIcon from "@material-ui/icons/Stars";
import {eventFieldNameTypes, inputTypes} from "../../../utils/CbvUtils";
import FieldInfo from "../FieldInfo";
import {SubdirectoryArrowRight} from "@material-ui/icons";

const LocationComponent = ({places, field, state, setState, inset, setOpenAlertMessage}) => {
    const _theme = useContext(ThemeContext)
    const eventContext = useContext(CreateEventContext)
    const useStyles = makeStyles((theme) => ({
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: _theme.accentColor,
            border: '1px solid ' + _theme.accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }));
    const classes = useStyles()

    const locationWhitelist = field.whitelist

    const options = useMemo(() => {
        if (!places)
            return null

        let _options = places.map((place) => ({
            ...place,
            whitelisted: locationWhitelist ? _.includes(locationWhitelist, place.sgln) : null
        }))

        if (state.brand) {
            _options = _.filter(_options, function (o) {
                return o.data_origin === (state.brand.domain)
                    || (!state.onlyMine ? o.data_origin === (state.brand.other_domain) : false)
            })
        }

        if (field.name === 'readPoint' && state.bizLocation)
            _options = _.filter(_options, function (o) {
                return o.gln === (state.bizLocation.gln || extractLocationClassCode(state.bizLocation))
                    && o.sgln !== (state.bizLocation.sgln || state.bizLocation)
            })

        if (field.name === 'bizLocation' && state.readPoint)
            _options = _.filter(_options, function (o) {
                return o.gln === (state.readPoint.gln || extractLocationClassCode(state.readPoint))
                    && o.sgln !== (state.readPoint.sgln || state.readPoint)
            })

        return _.orderBy(_options, ['whitelisted'], ['desc'])
    }, [places, state.brand, state.onlyMine, state.bizLocation])

    useEffect(() => {
        eventContext.setValidationField(
            'where',
            field.name,
            !field.optional ? eventContext.getFormField('where', field.name) ?
                eventContext.getFormField('where', field.name).value !== null : false : true)
    }, [state])

    return (
        <Box pl={4*inset} pr={1} mt={2} flex={1} display={'flex'} alignItems={'center'}>
            <SubdirectoryArrowRight style={{color: _theme.accentColor}}/>
            {
                options ?
                    <Autocomplete
                        style={{width:'70%'}}
                        size={'small'}
                        options={options}
                        clearOnEscape
                        disabled={field.input === inputTypes.FIXED.value}
                        value={field.input === inputTypes.FIXED.value ?
                            _.find(options, ['sgln', field.value]) : state[field.name]}
                        groupBy={(option) => locationWhitelist && locationWhitelist.length !== 0 ?
                            option.whitelisted ? "Suggested" : "Others" : ''}
                        getOptionLabel={(option) => option.name ? option.name : ''}
                        onChange={(event, newValue) => {
                            if (newValue && _.some(eventContext.getForm()['where'], ['value', newValue.sgln])) {
                                setOpenAlertMessage({
                                    open: true,
                                    message: "Business Location and Read Point cannot be equal",
                                    severity: 'warning'
                                });
                            } else {
                                eventContext.setFormField('where', field.name, newValue ? newValue.sgln : null)

                                if(field.name === 'bizLocation') {
                                    /** se c'è un cambiamento in bizLocation azzero anche il readPoint per coerenza */
                                    if(!!newValue) {
                                        eventContext.setFormField('where', 'readPoint', null)
                                        setState({...state, bizLocation: newValue, readPoint: null})
                                    } /** altrimenti se il newValue è null azzero solo bizLocation */
                                    else setState({...state, bizLocation: null})
                                } else if(field.name === 'readPoint') {
                                    setState({...state, [field.name]: newValue})
                                }
                            }
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       size={'small'}
                                       label={field.name === 'bizLocation' ? "Enter the Business Location" : "Enter the Read Point"}
                                       variant="outlined"
                                       error={state[field.name] === null && !field.optional}/>}
                        renderOption={(option, {selected}) => {
                            const [codeType, codeValue, domain] = findLocationCodeTypeValue(option.sgln)
                            return (<React.Fragment>
                                    {
                                        option.whitelisted ?
                                            <StarsIcon style={{
                                                color: _theme.accentColor,
                                                marginRight: '3%'
                                            }}/> : null
                                    }
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes.name}>{option.name}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {
                                                    codeType ?
                                                        <Chip
                                                            size={'small'}
                                                            label={codeType.type ? codeType.type : codeType}
                                                            className={classes.codeTypeChip}
                                                        /> : null
                                                }
                                                {
                                                    domain ?
                                                        <Chip
                                                            size={'small'}
                                                            label={domain}
                                                            className={classes.codeTypeChip}
                                                        /> : null
                                                }
                                                <Typography
                                                    className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )
                        }}
                    /> : null
            }
            <FieldInfo
                description={_.find(eventFieldNameTypes, ['name', field.name]).description}
                optional={field.optional}
                external={field.input === inputTypes.EXTERNAL.value}/>
        </Box>
    )
}

export default LocationComponent