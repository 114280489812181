import React, {useState} from "react";
import TableComposeFiles from "../components/tables/TableComposeFiles";
import useAxios from 'axios-hooks';
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import ComposeFileUploader from "../components/ComposeFileUploader";

const ComposeFiles = () => {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        button: {
            marginBottom: "6%",
            color: theme.secondary,
            textTransform: "none"
        },
    }));

    const classes = useStyles();

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const increment = skip * limit

    const [{data: composeFiles, loading, error}, getExecute] = useAxios({
            url: "/api/compose_files/?skip=" + increment + "&limit=" + limit
        },
        {useCache: false}
    )

    const [modalShow, setModalShow] = useState(false);

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Button
                            className={classes.button}
                            onClick={() => setModalShow(true)}
                            startIcon={<AddIcon/>}>
                            Upload Compose File
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <ComposeFileUploader show={modalShow} onHide={() => setModalShow(false)}
                         updateList={() => {getExecute()}}/>
            {composeFiles ? <TableComposeFiles composeFiles={composeFiles} limit={limit} setLimit={setLimit} skip={skip} setSkip={setSkip}
                               updateList={() => {getExecute()}}/> :
                loading ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error ? <p>ERROR</p> : null}
        </div>
    )

}

export default ComposeFiles;