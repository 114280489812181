import React, {useContext} from "react";
import {Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from '@material-ui/core/styles';
import {ThemeContext} from "../contexts";
import chroma from 'chroma-js'

const TagValue = ({tag, remove}) => {
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        button: {
            padding: '0px',
            paddingLeft: '2px',
            "&:hover": {
                backgroundColor: "transparent"
            }
        },
        typography: {
            display: 'inline-block',
            backgroundColor: chroma(accentColor).alpha(0.25).hex(),
            padding: '5px 10px 5px 10px',
            borderRadius: '20px',
            marginRight: '1%',
            marginTop: '1%'
        },
        paragraph: {
            marginBottom: 16,
        },
    }));
    const classes = useStyles();

    const handleClick = () => {
        remove(tag)
    }

    return (
        <Typography className={classes.typography}>{tag}
            <IconButton aria-label="clear" className={classes.button} onClick={handleClick}>
                <ClearIcon fontSize="small"/>
            </IconButton>
        </Typography>
    )
}

export default TagValue;
