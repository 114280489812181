import TableRow from "@material-ui/core/TableRow";
import {TableCell, Typography} from "@material-ui/core";
import {uomLut} from "../../utils/CbvUtils";
import TableHead from "@material-ui/core/TableHead";
import React from "react";

const SensorReportHead = ({headers, classes}) => {
    return <TableHead>
        <TableRow style={{background: 'whitesmoke'}}>
            <TableCell className={classes.stickyCell} style={{background: 'white'}}/>
            {headers.map((reportHeader, index) => (
                <TableCell key={index} className={classes.tableBorder} size={'small'} align={'center'}>
                    <Typography variant={'body1'}><strong>{reportHeader.type}</strong></Typography>
                    <Typography
                        variant={'caption'}>{reportHeader.uom ? `(${uomLut[reportHeader.uom] || reportHeader.uom})` : ''}</Typography>
                </TableCell>))}
        </TableRow>
    </TableHead>
}

export default SensorReportHead
