import React from "react";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import _ from 'lodash'
import {availableProductCodeTypes} from "../../utils/CodesUtils";

/** usato sia nel form di creazione eventi che nel form di creazione/edit prodotti */
const CodeValue = ({code, remove, editCodesChecked = true}) => {
    //console.log("CodeValue ---> code:",code)
    //console.log("CodeValue ---> type:",code.type)
    const handleClick = () => {
        remove(code)
    }

    return (
        <div>
            <Box pl={2} pr={2}>
                <Grid container direction="row" justify="flex-start" >
                    <Grid item xs={11} container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">
                        <Box width={1}>
                           <Typography>
                               <b>{
                               code.type ?
                                   code.type.label ? code.type.label+': '
                                       : ""+_.find(availableProductCodeTypes, ['name', code.type]).label+': '
                                   : ''}
                                </b>
                               {code.value} {code.quantity ? `(Q: ${code.quantity} ${code.uom})` : null}
                           </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                        <IconButton aria-label="clear" onClick={handleClick} disabled={!editCodesChecked}>
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={1} pr={1}>
                <Divider/>
            </Box>
        </div>
    )
}

export default CodeValue;
