import React, {useContext, useMemo, useState} from "react";
import {Backdrop, Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import useAxios from "axios-hooks";
import {AuthContext, ThemeContext} from "../../contexts";
import MaterialTable from 'material-table';
import TableReports from "./TableReports";
import {Autocomplete, Alert, AlertTitle} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import _ from 'lodash'
import FilterListIcon from '@material-ui/icons/FilterList';
import WhenRuleComponent from "../create_new_relationship/rules_components/WhenRuleComponent";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import CustomLoader from "../../components/CustomLoader";

const ChemioDataset = () => {
        const { t } = useTranslation()
    const { activeMainDomain } = useContext(AuthContext)
    const { accentColor } = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        },
        chip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent'
        },
        button: {
            backgroundColor: accentColor,
            color: "white",
            fontWeight: 'bold',
            marginLeft: '2%',
            borderRadius: '20px',
        },
    }));
    const classes = useStyles()

    const [{loading: loading}, downloadRequest] = useAxios(
        {method: "GET",},
        {useCache: false, manual: true}
    )

    const typeOfReports =
    [
        {
            "name": "Analisi Mangime",
            "id": "Analisi_mangime"
        },
        {
            "name": "Analisi Cicli",
            "id": "Analisi_cicli"
        },
        {
            "name": "Macellazioni",
            "id": "Macellazioni"
        }
    ]


    const [{data: pezzatura, loadingReports, errorReports},] = useAxios({
            url:  `/api/dataservices/records/_keys/?key_type=group_key&domain=${activeMainDomain}`,
            useCache: false
        },
    )

    const [state, setState] = useState({
        selectedTypeOfReports: null,
        selectedPezzatura: null,
        selectedDates: [{
            startDate: null,
            endDate: null,
            color: 'darkgrey',
            key: 'selection'
        }],
    });

    const [emptyReportFlag, setEmptyReportFlag] = useState(false);

    const onClickDownload = () => {
        setEmptyReportFlag(false)
        let pezz, tOfRep, start, end, fileName, responseType, mimeType, reports
        pezz= state.selectedPezzatura._id.replace("group_key:","")
        tOfRep= state.selectedTypeOfReports.id
        start = Moment(state.selectedDates[0].startDate).format('YYYY-MM-DD HH:mm:ss')
        end= Moment(state.selectedDates[0].endDate).format('YYYY-MM-DD HH:mm:ss')
        fileName = pezz+"-"+tOfRep+".xlsx"
        responseType = "blob"
        mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

        downloadRequest({
            url:  `/api/dataservices/chemio_reports/?pezzatura=${pezz}&type_of_report=${tOfRep}&start=${start}&end=${end}&domain=${activeMainDomain}`,
            responseType: responseType,
            headers: {'Content-Type': mimeType,}
        }).then(response => {
            let blob = new Blob([response.data], { type: 'application/zip' })
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        }).catch(error => {
             setEmptyReportFlag(true);
             console.log("onClickDownload --> error:", error);

        })
    }

    return (
        <div className={classes.root} id="chemioReport_page">
            {loading &&
            <Backdrop style={{zIndex: 1000}} open={loading}><CustomLoader size={80}/></Backdrop>}
            {true &&
            <Box mb={3} display={'flex'} alignItems={'end'}>
                <FilterListIcon style={{color: accentColor, marginRight: 16}}/>
                <Autocomplete
                    style={{width: '30%', marginRight: 16}}
                    limitTags={3}
                    id="typeOfReportsAC"
                    options={_.uniqBy(typeOfReports, 'name')}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id == value.id}
                    value={ state.selectedTypeOfReports }
                    onChange={(event, newSelection) => {
                        setState({...state, selectedTypeOfReports: newSelection});
                        setEmptyReportFlag(false);
                    }}
                    renderInput={params => {
                        const {InputProps, ...restParams} = params;
                        const {startAdornment, ...restInputProps} = InputProps;
                        return (
                            <TextField
                                {...restParams}
                                size={'small'}
                                variant={"standard"}
                                placeholder={t('actions.filterBy', {by: 'tipo di report'})}
                                InputProps={{
                                    ...restInputProps,
                                    startAdornment: (
                                        <div style={{
                                            maxHeight: '100px',
                                            overflowY: 'auto',
                                        }}
                                        >
                                            {startAdornment}
                                        </div>
                                    ),
                                }}
                            />
                        );
                    }}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip label={option.name} size='small'{...getTagProps({index})}/>
                        ))
                    }
                />
                <Autocomplete
                    style={{width: '30%', marginRight: 16}}
                    limitTags={3}
                    id="pezzaturaAC"
                    options={_.uniqBy(pezzatura, '_id')}
                    getOptionLabel={(option) => option._id.replace('group_key:','')}
                    getOptionSelected={(option, value) => option._id == value._id}
                    value={ state.selectedPezzatura }
                    //defaultValue={{"name": "Pollo Piccolo", "id":"pollo_piccolo"}}
                    onChange={(event, newSelection) => {
                        setState({...state, selectedPezzatura: newSelection});
                        setEmptyReportFlag(false);
                    }}
                    renderInput={params => {
                        const {InputProps, ...restParams} = params;
                        const {startAdornment, ...restInputProps} = InputProps;
                        return (
                            <TextField
                                {...restParams}
                                size={'small'}
                                variant={"standard"}
                                placeholder={t('actions.filterBy', {by: 'pezzatura'})}
                                //defaultValue="Pollo Piccolo"
                                InputProps={{
                                    ...restInputProps,
                                    startAdornment: (
                                        <div style={{
                                            maxHeight: '100px',
                                            overflowY: 'auto',
                                        }}
                                        >
                                            {startAdornment}
                                        </div>
                                    ),
                                }}
                            />
                        );
                    }}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip label={option.name} size='small'{...getTagProps({index})}/>
                        ))
                    }
                />
                <WhenRuleComponent state={state} setState={setState} width={'30%'} outlined={false}/>
                <Button className={classes.button} variant="contained"
                        onClick={() => {
                                onClickDownload()
                        }}
                        disabled={!state.selectedPezzatura || !state.selectedTypeOfReports ||
                         !state.selectedDates[0].startDate || !state.selectedDates[0].endDate}>
                    {t('actions.download')}
                </Button>
            </Box>}

            {errorReports && <p>{t('errors.genericServerError')}</p>}
            {emptyReportFlag &&
                <Alert severity="warning" onClose={() => {setEmptyReportFlag(false);}}>
                    <AlertTitle>{t('errors.emptyReport')}</AlertTitle>
                </Alert>
            }
        </div>
    )
}

export default ChemioDataset