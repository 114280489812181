import React, {useContext, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {PinnedIcon, UnpinnedIcon} from "../../components/CustomIcons";
import IconButton from "@material-ui/core/IconButton";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import {ThemeContext} from "../../contexts";
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import chroma from "chroma-js";
import {useTranslation} from "react-i18next";
import _ from 'lodash'
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import i18next from "../../i18n/i18next";

function reverseBizStep(why) {
    const prefix = 'urn:epcglobal:cbv:bizstep:'
    return prefix+getBizStep(why)
}

function getBizStep(why) {
    let newStr = ''
    if (why) {
        newStr = why.split('(') // formato stringa: eventName (bizStep)
        if (newStr.length > 1) {
            newStr = newStr[1].substring(0, newStr[1].length-1).replace(/ /g, '_');
        } else {
            return why
        }
    }

    return newStr.toLowerCase()
}

const TableWhyEvents = ({why, filters, setFilters, setSkip}) => {
   //const t0 = performance.now()
    const { t } = useTranslation();

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        row: {
            cursor: 'pointer',
            '&:hover': {
                background: "#fafafa",
            },
        },
        selectedRow: {
            cursor: 'pointer',
            '&:hover': {
                //background: "#fafafa",
            },
            background: chroma(accentColor).alpha(0.9).hex(),
        },
        selectedCells: {
            color: 'white'
        }
    }));
    const classes = useStyles()

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({isSelected: false, selection: ''})

    useEffect(() => {
        if (!filters.why) {
            setSelected({
                isSelected: false,
                selection: ''
            })
        }
    }, [filters])

    const handleClickLoading = () => {
        setLoading((prevLoading) => !prevLoading);
    };

    const onPinClick = (event, bizStep) => {
        handleClickLoading()
        var parts = bizStep?.split(' (', 2);
        let selection
        if (selected.isSelected) {
            selection = ''
            setSkip(0)
            setFilters({
                ...filters,
                why: ''
            })
        } else {
            selection = bizStep
            setSkip(0)
            setFilters({
                ...filters,
                why: "why=" + (parts ? parts[0] : '')
            })
        }

        setSelected({
            isSelected: !selected.isSelected,
            selection: selection
        })

        setTimeout(() => {
            handleClickLoading()
        }, 500);
    }

    const filterLabel = t('entities.filter') //esplicito se no non la estrae

    //const t1 = performance.now()
    //t1-t0 > 0 && console.log("-------------------------------------------- TIME TableWhyEvents --->", t1-t0)

    return (
        <Box pb={2}>
            {filters && setFilters && <Autocomplete
                style={{width: '80%', marginBottom: 8}}
                options={Object.keys(why)}
                getOptionLabel={(option) => _.upperFirst(option.replace(/_/g, ' '))}
                value={selected.selection}
                onChange={(event, newValue) => {
                    (newValue || selected.isSelected) && onPinClick(event, newValue)
                    //console.log("newValue:", newValue)
                }}
                loading={loading}
                renderInput={(params) => <TextField {...params} size={'small'}
                                                    label={_.startCase(i18next.t('cbvValues.eventName.label'))}/>}
                renderOption={(option) => {
                    return (<>
                            <ListItemAvatar children={<BizStepIcon bizstep={reverseBizStep(option)} color={primaryColor}/>}/>
                            <ListItemText
                                primary={_.upperFirst(option.replace(/_/g, ' ')).split('(')[0]}
                                secondary={`${i18next.t('cbvValues.bizStep.name')}: ${getBizStep(option)}`}
                                style={{color: primaryColor}}/>
                        </>
                    )
                }}
            />}
            {
                loading ?
                    <div className={classes.placeholder}>
                        <Fade
                            in={loading}
                            unmountOnExit
                        >
                            <CircularProgress style={{color: 'inherit'}}/>
                        </Fade>
                    </div>
                    :
                    <TableContainer style={{maxHeight: '500px', overflow: 'scroll'}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('cbvValues.bizStep.name')}</TableCell>
                                    <TableCell align="right"></TableCell>
                                    {/*<TableCell align="right">#{_.startCase(t('entities.item_plural'))}</TableCell>*/}
                                    <TableCell align="right">#{_.startCase(t('entities.event_plural'))}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.entries(why).map(([key, value]) =>
                                        <TableRow key={key}
                                                  className={selected.selection === key
                                                      ? classes.selectedRow
                                                      : classes.row}>
                                            <TableCell component="th" scope="row"
                                                       className={selected.selection === key ? classes.selectedCells : null}>
                                                <ListItem style={{padding: '0px'}}>
                                                    <ListItemAvatar>
                                                        {
                                                            selected.selection === key ?
                                                                <Tooltip
                                                                    title={!selected.isSelected ? t('actions.filterBy', {by: t('cbvValues.bizStep.name')}) : t('actions.clear', {what: filterLabel})}
                                                                    arrow>
                                                                    <IconButton onClick={(e) => {
                                                                        onPinClick(e, key)
                                                                    }}>
                                                                        <PinnedIcon style={{color: 'white'}}/>
                                                                    </IconButton>
                                                                </Tooltip> : selected.selection ?

                                                                    <IconButton disabled={true}>
                                                                        <PinnedIcon style={{color: 'lightgray'}}/>
                                                                    </IconButton>

                                                                :
                                                                <Tooltip
                                                                    title={!selected.isSelected ? t('actions.filterBy', {by: t('cbvValues.bizStep.name')}) : t('actions.clear', {what: filterLabel})}
                                                                    arrow>
                                                                    <IconButton onClick={(e) => {
                                                                        onPinClick(e, key)
                                                                    }}>
                                                                        <UnpinnedIcon style={{color: 'lightgray'}}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                        }
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={classes.heading}>{_.upperFirst(key.replace(/_/g, ' '))}</ListItemText>
                                                </ListItem>
                                            </TableCell>
                                            <TableCell className={selected.selection === key ? classes.selectedCells : null}>
                                                <BizStepIcon bizstep={reverseBizStep(key)} color={selected.selection === key ? 'white' : primaryColor}/>
                                            </TableCell>
                                            {/*<TableCell align="right" className={selected.selection === key ? classes.selectedCells : null}>{value.n_items}</TableCell>*/}
                                            <TableCell align="right"
                                                       className={selected.selection === key ? classes.selectedCells : null}>{value.n_events}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </Box>
    )
}

export default TableWhyEvents
