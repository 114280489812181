import React, {useContext, useMemo} from "react";
import {MarbleContext, ThemeContext} from "../../../contexts";
import {Box} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    setAggregationType,
    setFilterCompanies,
    setFilterProducts,
    setFilterTags,
    setShowGraph
} from "../marble_reducer";
import TextField from "@material-ui/core/TextField";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import chroma from "chroma-js";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from 'lodash'
import {parseName} from "../../../utils/Utils";
import LineProductCheckBox from "./LineProductCheckBox";
import {findCodeTypeAndValue, findLocationCodeTypeValue} from "../../../utils/CodesUtils";
import {useTranslation} from "react-i18next";
import {ToggleButtonGroup} from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {aggregationTypes} from "../MarbleUtility";
import WhereIcon from '@material-ui/icons/Explore';
import WhyIcon from '@material-ui/icons/MyLocation';

const TagFilter = ({classes, theme, t}) => {
    const [state, dispatch] = useContext(MarbleContext)

    const tags = useMemo(() => {
        if (!state.products)
            return null

        let _tags = []
        Object.values(state.products).forEach((p) => {
            _tags = _.union(_tags, p.tags)
        })

        return _tags
    }, [state.products])

    return (
        <Box mt={1} pl={1} pr={1}>
            {
                tags ?
                    <div>
                        <Autocomplete
                            disabled={state.filterCompanies && state.filterCompanies.length > 0}
                            multiple
                            filterSelectedOptions
                            limitTags={2}
                            options={tags}
                            getOptionLabel={(option) => option}
                            value={state.filterTags}
                            onChange={(event, newSelection, reason) => {
                                localStorage.setItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_filterTags`, JSON.stringify(newSelection))
                                if (reason !== 'remove-option' && reason !== 'clear') {
                                    dispatch(setShowGraph(false))
                                }
                                setTimeout(function () {
                                    dispatch(setShowGraph(true))
                                    dispatch(setFilterTags(newSelection));
                                }, 500);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={t('actions.add', {what: 'tag'})}
                                />
                            )}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <Typography className={classes.name}>{parseName(option)}</Typography>
                                </React.Fragment>
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={parseName(option)}
                                        size='small'
                                        style={{
                                            opacity: '0.5',
                                            marginBottom: '1%',
                                        }}
                                        {...getTagProps({index})}
                                    />
                                ))
                            }
                        />
                        <FormHelperText>{t('productionFlow.filters.filterByTags')}</FormHelperText>
                    </div> : null
            }
        </Box>
    )
}

const ProductsFilter = ({classes, theme, t}) => {
    const [state, dispatch] = useContext(MarbleContext)

    const products = useMemo(() => {
        if (!state.products || !state.filterTags)
            return null

        const filterProd = state.filterTags.length > 0 ? _.filter(Object.values(state.products), function(o) {
            return state.filterTags.some(r=> _.includes(o.tags, r));
        }) : Object.values(state.products)
        return filterProd.map((p) => ({key: p.key, name: p.name}))
    }, [state.products, state.filterTags])

    const productLabel = t('entities.product.label')
    return (
        <Box mt={1} pl={1} pr={1}>
            {
                products ?
                    <div>
                        <Autocomplete
                            disabled={state.filterCompanies && state.filterCompanies.length > 0}
                            multiple
                            filterSelectedOptions
                            limitTags={2}
                            options={products}
                            getOptionLabel={(option) => option.key + ',' + option.name}
                            value={state.filterProducts}
                            onChange={(event, newSelection, reason) => {
                                localStorage.setItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_filterProducts`, JSON.stringify(newSelection))
                                if (reason !== 'remove-option' && reason !== 'clear') {
                                    dispatch(setShowGraph(false))
                                }
                                setTimeout(function () {
                                    dispatch(setShowGraph(true))
                                    dispatch(setFilterProducts(newSelection));
                                }, 500);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={t('actions.add', {what: productLabel})}
                                />
                            )}
                            renderOption={(option, {selected}) => {
                                const [codeType, codeValue, domain] = option ? findCodeTypeAndValue(option.key) : [null, null, null]

                                return (
                                    <React.Fragment>
                                        <FiberManualRecordIcon fontSize={'large'} style={{
                                            color: state.gtinColorMap[option.key],
                                            marginRight: '3%'
                                        }}/>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography className={classes.name}>{option.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                    {/*codeType ?
                                                            <Chip
                                                                size={'small'}
                                                                label={codeType.type}
                                                                className={classes.codeTypeChip}
                                                            /> : null*/}
                                                    <Typography className={classes.code}>{option ? (domain ? domain : codeType.type) +': ' + codeValue : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            }
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.name || option.key}
                                        size='small'
                                        style={{
                                            backgroundColor: state.gtinColorMap[option.key],
                                            opacity: '0.5',
                                            marginBottom: '1%',
                                            color: chroma(state.gtinColorMap[option.key]).luminance() < 0.45 ? 'white' : 'black'
                                        }}
                                        {...getTagProps({index})}
                                    />
                                ))
                            }
                        />
                        <FormHelperText>{t('productionFlow.filters.filterByProducts')}</FormHelperText>
                        <LineProductCheckBox label={t('productionFlow.includeProductLine')}
                                        color={theme.primaryColorAlpha}
                                        checkedColor={theme.primaryColor}/>
                    </div> : null
            }
        </Box>
    )
}

const CompanyFilter = ({classes, t}) => {
    const [state, dispatch] = useContext(MarbleContext)
    return (
        <Box mt={1} pl={1} pr={1}>
            {
                state.products ?
                    <div>
                        <Autocomplete
                            disabled={(state.filterProducts && state.filterProducts.length > 0) ||
                                (state.filterTags && state.filterTags.length > 0)}
                            multiple
                            limitTags={2}
                            // FORMATO OPTIONS: [["sgln1", "companyName1"], ["sgln2", "companyName2"], ...]
                            options={Object.entries(state.companyNameLut.loc_name)}
                            getOptionLabel={(option) => option[0] + ',' + option[1]}
                            value={state.filterCompanies}
                            onChange={(event, newSelection, reason) => {
                                localStorage.setItem(`${state.authContext.userName}_${state.authContext.companyPrefix}_filterCompanies`, JSON.stringify(newSelection))
                                if (reason !== 'remove-option' && reason !== 'clear') {
                                    dispatch(setShowGraph(false))
                                }
                                setTimeout(function () {
                                    dispatch(setShowGraph(true))
                                    dispatch(setFilterCompanies(newSelection));
                                }, 500);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={t('actions.add', {what: t('entities.location.label')})}
                                />
                            )}
                            renderOption={(option, {selected}) => {
                                const [type, locationCode, domain] = findLocationCodeTypeValue(option[0])

                                return <React.Fragment>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography className={classes.name}>{option[1]}</Typography>
                                        </Grid>
                                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                            <Chip variant={'outlined'} label={state.companyNameLut.company_name[option[0]]} size={'small'} style={{marginRight: '2%'}}/>
                                            <Typography className={classes.code}>{(domain ? domain : type) + ': ' + locationCode}</Typography>
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            }}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option[1] + ' - ' + state.companyNameLut.company_name[option[0]]}
                                        size='small'
                                        style={{
                                            opacity: '0.5',
                                            marginBottom: '1%',
                                        }}
                                        {...getTagProps({index})}
                                    />
                                ))
                            }
                        />
                        <FormHelperText>{t('productionFlow.filters.filterByLocations')}</FormHelperText>
                    </div> : null
            }
        </Box>
    )
}

const AggregationType = ({t}) => {
    const [state, dispatch] = useContext(MarbleContext)

    const handleChangeAggregationType = (event, newType) => {
        if (newType !== null) {
            dispatch(setShowGraph(false))
            setTimeout(function () {
                dispatch(setShowGraph(true))
                dispatch(setAggregationType(newType))
            }, 500);
        }
    }

    return (
        <Box mt={1} pl={1} pr={1}>
            <ToggleButtonGroup value={state.aggregationType}
                               onChange={handleChangeAggregationType}
                               exclusive
                               size={"small"}
                               aria-label="doc-type">
                <ToggleButton value={aggregationTypes.why}>
                    <WhyIcon fontSize={'small'} style={{marginRight: '8px'}}/>
                    {t('productionFlow.aggregationType.why')}
                </ToggleButton>
                {/** $DEMOFILENI: enabled */}
                <ToggleButton value={aggregationTypes.where}>
                    {t('productionFlow.aggregationType.where')}
                    <WhereIcon fontSize={'small'} style={{marginLeft: '8px'}}/>
                </ToggleButton>
            </ToggleButtonGroup>
            <FormHelperText>{t('productionFlow.aggregationType.label')}</FormHelperText>
        </Box>
    );
}

const Filters = () => {
    const { t } = useTranslation()
    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: _theme.accentColor,
            border: '1px solid ' + _theme.accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }));
    const classes = useStyles()
    return (
        <Box mt={2}>
            <AggregationType t={t}/>
            <TagFilter classes={classes} theme={_theme} t={t}/>
            <ProductsFilter classes={classes} theme={_theme} t={t}/>
            <CompanyFilter classes={classes} theme={_theme} t={t}/>
        </Box>
    )
}

export default Filters
