import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import React, {useContext, useEffect, useState} from "react";
import {logout} from "../pages/login/auth";
import {ThemeContext} from "../contexts";
import {makeStyles} from "@material-ui/core/styles";
import chroma from "chroma-js";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {MyCompanyIcon} from "../components/CustomIcons";
import ListItemText from "@material-ui/core/ListItemText";
import {Divider, Tooltip} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {fetchUserInfo} from "../utils/Utils";
import {useTranslation} from "react-i18next";

const LoggedUserComponent = ({auth: authContext}) => {
    const { t } = useTranslation();
    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            marginLeft:'2%',
            '& .MuiChip-label':{
                paddingLeft:'6px',
                paddingRight:'6px'
            }
        },
        loggedUserChip: {
            marginLeft: '2%',
            backgroundColor: chroma(_theme.accentColor).alpha(0.9).hex(),
            color: 'white',
            fontWeight: "bold",
            fontSize: "inherit",
        },
        button: {
            backgroundColor: _theme.accentColor,
            color: 'white',
            borderRadius: '25px',
            width:'40%',
            fontWeight: 'bold',
            boxShadow: theme.shadows[2]
        },
        icon: {
            color: 'darkgray',
        },
    }));
    const classes = useStyles()

    useEffect(() => {
        (async () => {
            let proPic = await fetchUserInfo()
            setState({...state, proPic: proPic.info.image})
        })()
    }, [])

    const [state, setState] = useState({anchorEl: null, openLogoutDialog: false, proPic: null});

    const handleProfileMenuOpen = (event) => {
        setState({...state, anchorEl: event.currentTarget});
    };

    const handleProfileMenuClose = () => {
        setState({...state, anchorEl: null});
    };

    const handleClickOpenLogoutDialog = () => {
        setState({...state, openLogoutDialog: true});
    };
    const handleLogoutDialogClose = () => {
        setState({...state, openLogoutDialog: false});
    };
    const handleLogout = () => {
        logout()
        handleProfileMenuClose()
        handleLogoutDialogClose()
        authContext.setAuthentication(false)
    }

    return (<div className={classes.root}>
        <Tooltip title={t('appBar.accountDetails')}>
            <Chip className={classes.loggedUserChip}
                  avatar={<Avatar style={{color: _theme.secondaryColor, backgroundColor: _theme.primaryColor}}
                                  src={state.proPic ? "api/uploads/uploads/" + state.proPic : null}/>}
                  //label={`${authContext.name? authContext.name +' '+authContext.surname : authContext.userName}`}
                  onClick={handleProfileMenuOpen}
                  onDelete={handleProfileMenuOpen}
                  deleteIcon={<ExpandMoreIcon style={{color: _theme.secondaryColor}}/>}
            />
        </Tooltip>
        <Menu
            variant={"selectedMenu"}
            id="simple-menu"
            anchorEl={state.anchorEl}
            keepMounted
            open={Boolean(state.anchorEl)}
            //TransitionComponent={Slide}
            onClose={handleProfileMenuClose}>
            <MenuItem button component={Link} to="/MyProfile" onClick={handleProfileMenuClose}>
                <ListItemIcon>
                    <AccountCircleIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary={`${authContext.name ? `${authContext.name} ${authContext.surname || ''}` : authContext.userName}`}
                              secondary={t('appBar.profileAndSettings')}
                              style={{color: _theme.primaryColor}}/>
            </MenuItem>
            <Divider/>
            <MenuItem button component={Link} to="/MyCompany" onClick={handleProfileMenuClose}>
                <ListItemIcon>
                    <MyCompanyIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary={`${authContext.companyName}`}
                              secondary={t('appBar.companyDetails')}
                              style={{color: _theme.primaryColor}}/>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={handleClickOpenLogoutDialog}>
                <ListItemIcon>
                    <ExitToAppIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary={t('actions.logout')} style={{color: _theme.primaryColor}}/>
            </MenuItem>
        </Menu>
        <Dialog
            maxWidth={'xs'}
            fullWidth
            open={state.openLogoutDialog}
            onClose={handleLogoutDialogClose}>
            <DialogContent>
                <DialogContentText>
                    {t('appBar.areYouSureToLogout?')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLogoutDialogClose} color="default">
                    {t('actions.cancel')}
                </Button>
                <Button className={classes.button} onClick={handleLogout} color="default" autoFocus>
                    {t('actions.logout')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>)
}

export default LoggedUserComponent

