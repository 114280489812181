import React, {useContext, useEffect, useState} from "react";
import {Box, Divider} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getTimezonesNames} from "../../../utils/Utils";
import {eventFieldNameTypes, inputTypes} from "../../../utils/CbvUtils";
import TextField from "@material-ui/core/TextField";
import {CreateEventContext} from "../../../contexts";
import _ from "lodash";
import FieldInfo from "../FieldInfo";
import {useTranslation} from "react-i18next";

/** UTC FORMAT ->(example: '+01:00') */
const UtcOffsetComponent = ({field, state, setState}) => {
    const {t} = useTranslation()
    const eventContext = useContext(CreateEventContext)

    useEffect(() => {
        eventContext.setValidationField(
            'when',
            field.name,
            !field.optional ? eventContext.getFormField('when', field.name) ?
                eventContext.getFormField('when', field.name).value !== null : false : true)
    }, [state])

    const [value, setValue] = useState(eventContext.getFormField('when', field.name)? eventContext.getFormField('when', field.name).value : null)

    useEffect(() => {
        eventContext.setFormField('when', field.name, value)
        eventContext.setValidationField(
            'when',
            field.name,
            eventContext.getFormField('when', field.name) ?
                eventContext.getFormField('when', field.name).value !== null : false)
        setState({...state, [field.name]: value})
    }, [value])

    return (
        <div>
            <Box pb={2} style={{display: 'flex', alignItems: 'flex-end'}}>
                <FieldInfo name={_.find(eventFieldNameTypes, ['name', field.name]).label}
                           description={_.find(eventFieldNameTypes, ['name', field.name]).description}
                           optional={field.optional}
                           external={field.input === inputTypes.EXTERNAL.value}/>
                <Autocomplete
                    style={{width:'60%'}}
                    size={'small'}
                    options={getTimezonesNames()}
                    getOptionLabel={(option) => option.tzPresentationName}
                    renderInput={(params) =>
                        <TextField {...params} size={'small'} label={t('createEventModel.chooseUTC')}/>}
                    onChange={(event, newValue) => {
                        setValue(newValue ? newValue.tzValue : null)
                    }}
                />
            </Box>
            <Divider/>
        </div>
    )
}

export default UtcOffsetComponent