import React, {useContext, useMemo, useState} from "react";
import TableCompanies from "../../components/tables/TableCompanies";
import useAxios from 'axios-hooks';
import MaterialTable from 'material-table';
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../contexts";
import _ from "lodash";
import {isActivePartner, isPendingRequest, isRequestToAccept} from "../../utils/Utils";

const Companies = ({myPartners}) => {
    const { t } = useTranslation()
    const {activeDomains} = useContext(AuthContext)

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const increment = skip * limit

    const isMyCompany = (domain) => {
        return activeDomains.includes(domain)
    }

    const [{data: companies, loading, error}] = useAxios({
            //url: "/api/companies/?skip=" + increment + "&limit=" + limit
            url: "/api/companies/"
        },
        {useCache: false}
    )

    const [{data: pendingRequests, loadingPendingRequests, errorPendingRequests}, refetchPendingRequests] = useAxios({
            url: "/api/partnership_requests/pending"
        },
        {useCache: false}
    )

    const [{data: requestsToAccept, loadingRequestsToAccept, errorRequestsToAccept}, refetchRequestsToAccept] = useAxios({
            url: "/api/partnership_requests/to_accept"
        },
        {useCache: false}
    )

    const [{data: activePartners, loadingActiveRequests, errorActiveRequests}, refetchActivePartners] = useAxios({
            url: "/api/partnership_requests/active"
        },
        {useCache: false}
    )

    const refetch = () => {
        refetchPendingRequests()
        refetchRequestsToAccept()
        refetchActivePartners()
    }

    function getRequestStatus(domain) {
        return isPendingRequest(pendingRequests, domain) ? "pending"
            : isRequestToAccept(requestsToAccept, domain) ? "to_accept"
                : isActivePartner(activePartners, domain) ? "partner"
                    : null;
    }

    const _companies = useMemo(() => {
        if(!companies || !pendingRequests || !activePartners || !requestsToAccept)
            return null

        let res = {data: companies.data, count: companies.count}
        _.forEach(companies.data, (company, parentIndex) => {
            const reqStat = getRequestStatus(company.brands[0].domain)
            res.data[parentIndex] = {
                ...company,
                id: company.brands[0].domain,
                domain: company.brands[0].domain,
                requestStatus: reqStat
            }

            const subDomains = company.brands.slice(1, company.brands.length)
            if(subDomains && subDomains.length > 0) {
                const brands = _.map(subDomains, (brand, index) => {
                    return ({...brand, id: brand.domain, parentId: company.brands[0].domain, requestStatus: getRequestStatus(brand.domain)})
                })
                res.data = _.union(res.data, brands)
            }
        })

        if(myPartners) {
            res.data = _.filter(res.data, (brand) => {
                return (pendingRequests.includes(brand.domain))
                    || (activePartners.includes(brand.domain))
                    || (_.find(requestsToAccept, ["brand_domain", brand.domain]))
            })

            res.count = res.data.length
        }

        return res
    }, [companies, pendingRequests, activePartners, requestsToAccept])

    return (
        <div>
            {_companies ? <TableCompanies _myPartners={myPartners} companies={_companies} pendingRequests={pendingRequests} requestsToAccept={requestsToAccept} activePartners={activePartners} refetch={refetch} /* limit={limit} setLimit={setLimit} skip={skip} setSkip={setSkip}*//> :
                loading || loadingPendingRequests || loadingRequestsToAccept || loadingActiveRequests ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error || errorPendingRequests || errorRequestsToAccept || errorActiveRequests ? <p>{t('errors.genericServerError')}</p> : null}
        </div>
    )

}

export default Companies;
