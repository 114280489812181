import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import {ThemeContext} from "../../../contexts";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useWindowSize} from "../../../utils/Utils";
import {eventTypes, inputTypes, splitCbvValues} from "../../../utils/CbvUtils";
import {makeStyles} from "@material-ui/core/styles";
import SelectInputTypeComponent from "./SelectInputTypeComponent";
import {BizStepIcon} from "../../../components/CustomBizStepIcons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import i18next from "../../../i18n/i18next";
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    bcgGrey: {
        backgroundColor: 'rgba(245,245,245,0.7)',
        borderRadius: '8px'
    },
    break: {
        flexBasis: '100%',
        height: '0',
    }
}));

const SelectComponent = ({options, formLabel, formDataFieldName, formDataFieldValue, handleChange}) => {
    const [value,] = useState(formDataFieldValue ? formDataFieldValue : options[0]);
    const [helperText, setHelperText] = useState(null);

    useEffect(() => {
        handleChange(formDataFieldName, value)
    }, [])

    useEffect( () => {
        if(formDataFieldValue){
            const text = i18next.t(`cbvValues.action.${splitCbvValues(formDataFieldValue)}`)
            setHelperText(text)
        }else setHelperText(null)

    }, [formDataFieldValue])

    const handleSelectChange = (event) => {
        handleChange(formDataFieldName, event.target.value)
    };

    return (
        <Box p={2}>
            <FormLabel component="legend">{formLabel}</FormLabel>
            <Select onChange={handleSelectChange}
                    displayEmpty
                    defaultValue={value}>
                {
                    options.map(
                        (option) => (
                            <MenuItem value={option} key={option}>{option}</MenuItem>)
                    )
                }
            </Select>
            {
                helperText ? helperText.split('\n').map(str => <FormHelperText key={str}>{str}</FormHelperText>)
                    : null
            }
        </Box>
    )
}

const AutoCompleteComponent = ({options, formDataFieldName, formDataFieldValue, formLabel, formData, handleChange, optional, description}) => {
    const [value,] = useState(formDataFieldValue ? formDataFieldValue : null);
    const {primaryColor} = useContext(ThemeContext)

    const onChange = (event, newValue) => {
        handleChange(formDataFieldName, newValue)
    };

    return (
        <Box p={2} style={{flex:'2'}}>
            <Autocomplete
                id="combo-box"
                clearOnEscape
                onChange={onChange}
                fullWidth={true}
                options={options.sort()}
                defaultValue={value}
                getOptionLabel={(option) => splitCbvValues(option)}
                renderInput={(params) =>
                    <TextField {...params}
                               label={formLabel}
                               size={'small'}
                               variant="outlined"
                               error={formData.bizStep === null && !optional}
                               InputProps={{
                                   ...params.InputProps,
                                   startAdornment: (
                                       formDataFieldName === 'bizStep' && formData.bizStep && <Box p={1}>
                                           <BizStepIcon bizstep={formData.bizStep} color={primaryColor}/>
                                       </Box>
                                   ),
                               }}

                    />}
                renderOption={(option, {selected}) => (
                        <React.Fragment>
                            {formDataFieldName === 'bizStep' && <BizStepIcon bizstep={option} color={primaryColor}/>}
                            <Box style={{marginLeft:'3%'}}>
                                {splitCbvValues(option)}
                            </Box>
                        </React.Fragment>
                )}
            />
            <div>
                {
                    formData.bizStep === null && !optional ?
                            <FormHelperText style={{color:'red'}}>{i18next.t('entities.requiredField')}</FormHelperText>
                        :   (formData.dispositionInputType === inputTypes.FIXED.value && formData.disposition === null) && optional ?
                            <FormHelperText style={{color:'orange'}}>{i18next.t('createEventModel.fixDispositionHere', 'You should fix the Disposition value here')}</FormHelperText> : null
                }
                <FormHelperText>{description}</FormHelperText>
            </div>
        </Box>
    )
}

const WhyComponent = ({cbvValues, handleChange, formData, setStepValidation}) => {
    const classes = useStyles();
    const [bizDescription, setBizDescription] = useState(null)
    const [dispositionDescription, setDispostionDescription] = useState(null)
    const size = useWindowSize();

    const okayWhy = useMemo(() => {
        let okValueBizStep = formData.bizStep !== null

        let okValueDisposition = false;
        let isFixedDisposition = formData.dispositionInputType === inputTypes.FIXED.value

        if(!isFixedDisposition){
            okValueDisposition = true
        }else{
            if(formData.disposition !== null) okValueDisposition = true
        }
        return okValueBizStep && okValueDisposition

    }, [formData])

    useEffect(() => {
        setStepValidation(okayWhy)
    }, [okayWhy, setStepValidation])

    useEffect( () => {
        if(formData.bizStep) {
            setBizDescription(i18next.t(`cbvValues.bizStepTypes.${_.camelCase(splitCbvValues(formData.bizStep))}`))
        } else setBizDescription(null)

    }, [formData.bizStep])

    useEffect( () => {
        if(formData.disposition) {
            setDispostionDescription(i18next.t(`cbvValues.dispositionTypes.${_.camelCase(splitCbvValues(formData.disposition))}`))
        } else setDispostionDescription(null)

    }, [formData.disposition])

    const DetailCbvValue = ({value, description}) => {
        const {primaryColor, backgroundColor} = useContext(ThemeContext)

        return (
            <Box>
                {
                    value ?
                        <Box p={2} pt={0}>
                            <Accordion style={{backgroundColor: backgroundColor, color: primaryColor}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{color: primaryColor}}/>}
                                >
                                    <Typography className={classes.heading}>{i18next.t('entities.moreInfo')} - {splitCbvValues(value)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box> : null
                }
            </Box>
        )
    }

    return (
        <div>
            {
                cbvValues ?
                    <div>
                        <AutoCompleteComponent  options={cbvValues.biz_step}
                                                formLabel={i18next.t('createEventModel.chooseBizStep')}
                                                formDataFieldName={'bizStep'}
                                                formDataFieldValue={formData.bizStep}
                                                handleChange={handleChange}
                                                formData={formData}
                                                optional={false}
                                                description={i18next.t('cbvValues.bizStep.shortDescription')}
                                                setStepValidation={setStepValidation}
                        />
                        {
                            bizDescription ?
                                <DetailCbvValue value={formData.bizStep} description={bizDescription}/>
                                : null
                        }
                        <div style={{display: size.width > 700 ? 'flex' : 'grid', flexWrap: 'wrap',}} className={formData.dispositionInputType === inputTypes.FIXED.value ? classes.bcgGrey : null}>
                            <SelectInputTypeComponent
                                options={[inputTypes.FIXED.value, inputTypes.EXTERNAL.value]}
                                formDataFieldName={'dispositionInputType'}
                                formDataFieldValue={formData.dispositionInputType}
                                formLabel={i18next.t('createEventModel.chooseTheDispositionInputType')}
                                handleChange={handleChange}
                            />
                            {
                                formData.dispositionInputType === inputTypes.FIXED.value ?
                                <AutoCompleteComponent
                                    options={cbvValues.disposition}
                                    formLabel={i18next.t('createEventModel.chooseTheDispositionValueToFix')}
                                    formDataFieldName={'disposition'}
                                    formDataFieldValue={formData.disposition}
                                    handleChange={handleChange}
                                    formData={formData}
                                    optional={formData.dispositionInputType === inputTypes.FIXED.value}
                                    description={i18next.t('cbvValues.disposition.shortDescription')}
                                    setStepValidation={setStepValidation}
                                /> : null
                            }
                            <div className="break"/>
                            <div style={{width: '100%'}}>
                                {
                                    dispositionDescription ?
                                        <DetailCbvValue value={formData.disposition} description={dispositionDescription}/>
                                        : null
                                }
                            </div>
                        </div>
                        {
                            formData.eventType !== eventTypes.TRANSFORMATION.value ?
                                <SelectComponent options={cbvValues.action}
                                                 formLabel={i18next.t('createEventModel.chooseTheAction')}
                                                 formDataFieldName={'action'}
                                                 formDataFieldValue={formData.action}
                                                 handleChange={handleChange}
                                                 formData={formData}
                                    //description={actionDescription}
                                />
                            : null
                        }
                    </div> : null
            }
        </div>
    )
}

export default WhyComponent
