import React, {useState} from "react";
import TableCertificates from "../../components/tables/TableCertificates";
import useAxios from 'axios-hooks';
import MaterialTable from 'material-table';

const Certificates = () => {

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const increment = skip * limit

    const [{data: certificates, loading, error}, getExecute] = useAxios({
            url: "/api/certificates/?skip=" + increment + "&limit=" + limit
        },
        {useCache: false}
    )

    return (
        <div>
            {certificates ? <TableCertificates certificates={certificates} limit={limit} setLimit={setLimit}
                                               skip={skip} setSkip={setSkip} updateList={() => {getExecute()}}/> :
                loading ? <MaterialTable title={''} isLoading={true} options={{loadingType: 'overlay'}}/> : null}
            {error ? <p>ERROR</p> : null}
        </div>
    )

}

export default Certificates;
