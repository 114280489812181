import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import {MarbleContext, ThemeContext} from "../../contexts";
import {resetGraph} from "./marble_reducer";
import {useTranslation} from "react-i18next";

const SpeedDials = ({centerFitBoundaries, numberOfNodes}) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const [, dispatch] = useContext(MarbleContext)

    const useStyles = makeStyles((theme) => ({
        speedDial: {
            position: 'absolute',
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                bottom: theme.spacing(4.5),
                right: theme.spacing(2),
            },
            '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
                top: theme.spacing(2),
                left: theme.spacing(2),
            },
            '&.MuiFab-root, &.MuiSpeedDial-fab': {
                color: 'white',
                backgroundColor: 'red',
            },
            '&.MuiFab-primary' : {
                color: accentColor
            }
        },
    }));
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCenterFitBoundaries = () => {
        centerFitBoundaries(numberOfNodes <=3 ? 0.5 : 0.9)
    }

    const handleReset = () => {
        dispatch(resetGraph())
    }

    return (
        <SpeedDial
            ariaLabel="SpeedDial"
            className={classes.speedDial}
            icon={<SpeedDialIcon/>}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            //FabProps={{color: "primary"}}
        >
            <SpeedDialAction
                key={"Center Graph"}
                icon={<FilterCenterFocusIcon/>}
                tooltipTitle={t('productionFlow.centerGraph')}
                onClick={() => {handleCenterFitBoundaries()}}
            />

            <SpeedDialAction
                key={"Reset Filters"}
                icon={<SettingsBackupRestoreIcon/>}
                tooltipTitle={t('productionFlow.resetGraph')}
                onClick={() => {handleReset()}}
            />
        </SpeedDial>
    );
}

export default SpeedDials