import React, {useContext, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from 'material-table';
import axios from "axios";
import {RoutingContext, ThemeContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import useAxios from "axios-hooks";
import Moment from "moment";
import i18next, {langResources} from "../../i18n/i18next";
import CustomLoader from "../../components/CustomLoader";
import {CircularProgress, Tooltip, Typography} from "@material-ui/core";
import {severityTypes} from "../../utils/Utils";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {AccountTree} from "@material-ui/icons";
import _ from 'lodash'
import Box from "@material-ui/core/Box";
import * as PropTypes from "prop-types";

export function CircularPercentStatusProgress(props) {
    return <Box position="relative" display="inline-flex">
        <div style={{position: "relative"}}>
            <CircularProgress
                variant="determinate"
                style={{color: "#efefef"}}
                value={100}/>
            <CircularProgress
                variant="determinate" value={props.status || 0}
                style={{color: props.color, position: "absolute", left: 0,}}/>
        </div>
        <Tooltip arrow title={i18next.t('rulesConfigurator.messages.projectStatusDescription', 'It indicates the percentage of complete models in the project over the draft models')}>
            <Box top={0}
                 left={0}
                 bottom={0}
                 right={0}
                 position="absolute"
                 display="flex"
                 alignItems="center"
                 justifyContent="center">
                <Typography variant="caption" component="div"
                            color="textSecondary">{`${props.status || 0}%`}</Typography>
            </Box>
        </Tooltip>
    </Box>;
}

CircularPercentStatusProgress.propTypes = {
    status: PropTypes.any,
    color: PropTypes.any
};

const ProjectsTable = () => {
    const { accentColor } = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiPopover-paper': {
                width: '60%',
                borderRadius: '8px'
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
        }
    }));
    const classes = useStyles()

    const materialTableRef = React.createRef();

    const initialErrorState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialErrorState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const [{data: projectInfos, loading: loadingProjectInfos, error: errorProjectInfos}] = useAxios({
            url: "/api/graphic_conf/project/all/info/"
        },
        {useCache: false}
    )
    const myContext = useContext(RoutingContext);

    const onItemClick = () => {
        //myContext.setRoute('CreateEventTemplate');
        materialTableRef.current.dataManager.changeRowEditing();
        materialTableRef.current.setState({
            ...materialTableRef.current.dataManager.getRenderState(),
            showAddRow: true,
        });
    }

    const onRowClick = (event, rowData) => {
        //console.log("rowData:",rowData)
        myContext.setRoute('RulesConfigurator', {_id:rowData._id, project_name:rowData.project_name});
    }

    let columns = [
        { title:i18next.t('rulesConfigurator.projectName'), field: 'project_name', /*editable:,*/},
        { title:i18next.t('rulesConfigurator.creator', 'Creator'), field: 'creator', editable:'never'},
        { title:i18next.t('rulesConfigurator.lastEditor', 'Last Editor'), field: 'last_editor', editable:'never'},
        { title:i18next.t('rulesConfigurator.lastUpdateTime', 'Last Modified'), field: 'last_update_time', defaultSort: 'desc', editable:'never', type:'date', render: rowData => <p>{i18next.t("formatters:dateTime", {date: Moment(rowData.last_update_time)})}</p>},
        { title:i18next.t('entities.status', 'Status'), field: 'status', editable:'never',
            render: rowData => <CircularPercentStatusProgress status={rowData.status} color={accentColor}/>},
    ]

    const tab = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable

    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        if (projectInfos) {
            setTableData(_.map(projectInfos, (project) => {
                const l = project.counter_draft_templates + project.counter_not_draft_templates
                const status = ((l - project.counter_draft_templates) / l).toPrecision(1) * 100
                return ({...project, status: status})
            }))
        }
    }, [projectInfos])

    return (
        tableData ? <div className={classes.root}>
            <Button
                //style={{marginBottom: "1%", color: theme.secondary, textTransform: "none"}}
                onClick={onItemClick}
                startIcon={<AddIcon/>}>
                {i18next.t('rulesConfigurator.addNewProject')}
            </Button>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                      autoHideDuration={4000} onClose={handleCloseErrorMessage}>
                <Alert onClose={handleCloseErrorMessage}
                       severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
            </Snackbar>
            <MaterialTable
                columns={columns}
                localization={tab}
                tableRef={materialTableRef}
                actions={[
                    /*{
                        icon: 'library_add',
                        tooltip: 'Duplicate Project',
                        onClick: (event, rowData) => {
                            const materialTable = materialTableRef.current;

                            materialTable.dataManager.changeRowEditing();
                            materialTable.setState({
                                ...materialTable.dataManager.getRenderState(),
                                showAddRow: true,
                            });
                        }
                    },*/
                    {
                        icon: () => <AccountTree style={{color: accentColor}}/>,
                        tooltip: i18next.t('rulesConfigurator.messages.openGraphEditor', 'Open Graph Editor'),
                        onClick: (event, rowData) => {
                            onRowClick(event, rowData)
                        }
                    }]
                }
                data={tableData}
                onRowClick={onRowClick}
                options={{
                    search: true,
                    sorting: true,
                    showTitle: false,
                    tableLayout: "auto",
                    padding: 'dense',
                    pageSize: 10,
                    addRowPosition: 'first'
                    //actionsColumnIndex: -1,
                }}
                editable={{
                    onRowAdd: async newData => {
                        if(!newData.project_name.trim())
                            setOpenAlertMessage({
                                open: true,
                                message: i18next.t('rulesConfigurator.messages.cannotBeEmpty', 'The project name cannot be empty!'),
                                severity: severityTypes.WARNING
                            });
                        else {
                            const url = `api/graphic_conf/project/by_name/event_graph/plot/?project_name=${newData.project_name}`
                            await axios.post(url, {
                                project_name: newData.project_name,
                                position: [0, 0],
                                zoom: 0.5,
                                nodes: [],
                                edges: []
                            }).then(res => {
                                if (res) {
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            setTableData([newData, ...tableData]);
                                            resolve();
                                        }, 500)
                                    })
                                }
                            }).catch((error) => {
                                console.log("POST error", error)
                                if (error.response.status === 400)
                                    setOpenAlertMessage({
                                        open: true,
                                        message: i18next.t('rulesConfigurator.messages.cannotContainSpecialChar', 'The project name cannot contain \'/\'!'),
                                        severity: severityTypes.WARNING
                                    });
                                else if (error.response.status === 403)
                                    setOpenAlertMessage({
                                        open: true,
                                        message: i18next.t('rulesConfigurator.messages.nameAlreadyExists', 'The project name already exsits!'),
                                        severity: severityTypes.WARNING
                                    });
                                else
                                    setOpenAlertMessage({
                                        open: true,
                                        message: i18next.t("errors.genericServerError"),
                                        severity: severityTypes.ERROR
                                    });

                                console.log("ERROR PUT --> err:", error)
                                return Promise.reject(error)
                            })
                        }
                    },
                    onRowUpdate: async (newData, oldData) => {
                        //console.log("newData:",newData)
                        //console.log("oldData:",oldData)
                        const url = `api/graphic_conf/project/${oldData._id}/name/?new_name=${newData.project_name}`
                        await axios.patch(url, {
                            project_name:newData.project_name,
                        })
                            .then(res => {
                                if (res) {
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            const dataUpdate = [...tableData];
                                            const index = oldData.tableData.id;
                                            dataUpdate[index] = newData;
                                            setTableData([...dataUpdate]);

                                            resolve();
                                        }, 500)
                                    })
                                }
                            }).catch((error) => {
                                console.log("PATCH error", error)
                                if (error.response.status === 400)
                                    setOpenAlertMessage({
                                        open: true,
                                        message: i18next.t('rulesConfigurator.messages.cannotContainSpecialChar', 'The project name cannot contain \'/\'!'),
                                        severity: severityTypes.WARNING
                                    });
                                else if (error.response.status === 403)
                                    setOpenAlertMessage({
                                        open: true,
                                        message: i18next.t('rulesConfigurator.messages.nameAlreadyExists', 'The project name already exsits!'),
                                        severity: severityTypes.WARNING
                                    });
                                else
                                    setOpenAlertMessage({open: true, message: i18next.t("errors.genericServerError"), severity: severityTypes.ERROR});

                                console.log("ERROR PUT --> err:", error)
                                return Promise.reject(error)
                            })
                    },
                    /*onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...tableData];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setTableData([...dataDelete]);

                                resolve()
                            }, 500)
                        }),*/
                }}
            />
        </div>
            : loadingProjectInfos ? <CustomLoader size={80}/>
            : errorProjectInfos ? <Typography align={'center'} style={{color: 'darkgrey'}}>{i18next.t('errors.genericServerError')}</Typography>
                : null
    )
}

export default ProjectsTable
