import React, {useEffect, useState} from "react";
import Moment from "moment";
import * as moment from 'moment-timezone';
import {TileLayer} from "react-leaflet";
import _ from "lodash";
import axios from "axios";
import {
    extractProductClassCode,
} from "./CodesUtils";
import i18next from "../i18n/i18next";

/** CHIAMATE API -------------------------------------------------------------------------------- */
export const fetchUserInfo = async () => {
    const res = await axios.get("/users/me/", {
        headers: {'Media-Type': 'application/json'}
    })
    return {
        info: res.data,
    }
}

/**
 * e.g. byWhat => '_by_subdomain/?domain='
 * */
export const fetchCompanyInfo = async (byWhat, parameter) => {
    const res = await axios.get(`api/companies/${byWhat}${parameter}`, {
        headers: {
            'Media-Type': 'application/json'
        }
    })
    return {name: res.data.name}
}

export const fetchCompanies = async () => {
    const res = await axios.get(`api/companies/`, {
        headers: {
            'Media-Type': 'application/json'
        }
    })
    return res.data.data
}

export const fetchActiveBrandsInfo = async () => {
    const res = await axios.get("/users/me/brands_info/", {
        headers: {'Media-Type': 'application/json'}
    })
    return res.data
}

/**
 * fetch asincrona della descrizione dei valori del dizionario cbv
 *  - da usare in func/contesto async
 *  CATEGORY must be one of the following values:
 *  ['biz_step', 'disposition', 'action', 'biz_transaction_type',
 *  'source-destination_type', 'error_reason']
 * */
export const fetchCbvInfoDescription = async (category, value) => {
    const res = await axios.get("/api/cbv_values/info/?category=" + category + "&value=" + value, {
        headers: {
            'Media-Type': 'application/json'
        }
    })
    return {
        description: res.data,
    }
}

export const fetchDocumentId = async (source, bizTransaction) => {
    const res = await axios.get("api/uploads/uploads/doc_id_association/_by_public_id/?doc_public_id=" + bizTransaction, {
        headers: {
            'Media-Type': 'application/json'
        },
        cancelToken: source.token
    })
    //console.log("fetchDocID ---> res:", res)

    return res.data ? {
        doc_db_id: res.data.doc_db_id,
        _id: res.data._id
    } : null
}

export const fetchFileInfo = async (fileId) => {
    const res = await axios.get("api/uploads/uploads/_info/"+fileId, {
        headers: {
            'Media-Type': 'application/json'
        }
    })

    return res.data ? {
        file_name: res.data,
    } : ''
}

export const fetchProductHistoryInfo = async (sgtins, forward, backward, truncate, max_children, setOpenErrorMessage) => {
    const url = "api/capture/epcis_events/_product_history_v6/?enrich=true&group=false&forward="+forward
        +"&backward="+backward
        +"&truncate="+truncate
        + (truncate && max_children !== 'all' ? "&max_children="+max_children : '')

    const res = await axios.post(url, {whats: sgtins})
        .catch((error) => {
        console.log("catch error", error)
        setOpenErrorMessage && setOpenErrorMessage({
            open: true,
            message: i18next.t('productHistory.messages.productNotFound'),
            severity: 'error'
        })
    })

    return res && res.data ? {
        data: res.data
    } : null
}

export const fetchDomainModels = async (domain) => {
    const url = "/api/capture/event_templates/_by_domain/?synthesis=true&domain=" + (domain || '')
    return await axios.get(url, {
        headers: {
            'Media-Type': 'application/json'
        }
    })
}

/** UTILITY MISCELLANEA ----------------------------------------------------------------------- */
export const severityTypes = {
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
}

export const getTimezonesNames = () => {
    const arr = [];
    const names = moment.tz.names();
    for (const name of names) {
        if ((name.indexOf('/') < 0 && name !== 'UTC') || name.startsWith('Etc/')) {
            continue;
        }
        const data = {}
        data.tzName = name;
        data.tzPresentationName = moment.tz(name).format('Z');
        data.tzValue = data.tzPresentationName
        arr.push(data);
    }
    arr.sort((a, b) => {
        if (a.tzPresentationName === b.tzPresentationName) {
            if (a.tzName === 'UTC') {
                return -1;
            }
            return a.tzName === b.tzName ? 0 : (a.tzName > b.tzName ? 1 : -1);
        }
        const afc = a.tzPresentationName.charAt(0);
        const bfc = b.tzPresentationName.charAt(0);
        if (afc === '-') {
            if (bfc === '+') {
                return -1;
            }
            return a.tzPresentationName > b.tzPresentationName ? -1 : 1;
        }
        if (bfc === '-') {
            return 1;
        }
        return a.tzPresentationName > b.tzPresentationName ? 1 : -1;
    });
    arr.forEach(a => a.tzPresentationName = `${a.tzName} (UTC ${a.tzPresentationName})`);
    return arr;
}

export const find_code = (type, codes) => {
    const sel = codes.filter(x => x.type === type);
    return sel.length>0 ? sel[0].value : null;
}

/** TileLayer per le mappe Leaflet */
export const MyTileLayer = () => {
    return (
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
    )
}

/** Per responsività delle tabelle */
export const useWindowSize = () => {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

/*const brands = useMemo(() => {
    if (!companies)
        return null
    let _brands = []

    _.forEach(companies.data, (company) => {
        _brands = _.union(_brands, _.map(company.brands, (brand) => ({...brand, companyName:company.name})))
    })

    // colori per domain
    let palette// = chroma.scale('RdYlBu').padding(0.15).colors(Object.keys(_brands).length)
    palette = distinctColors({count: _brands.length || 0, lightMin: 40})
    _brands = _.map(_brands, (brand, index) => ({...brand, color: chroma(palette[index]).hex()}));
    return _brands

}, [companies])*/

export function convertToDomain(topicName) {
    const topicNameKeywordLUT = ['capture', '4w_events']

    /** TOPIC destination: client_domain_main_trinacria_com_capture */
    /** TOPIC source: client_domain_main_izzo_com_4w_events */

    let tmp = _.replace(topicName, 'client_domain', '');
    const keyword = _.includes(topicName, topicNameKeywordLUT[0]) ? topicNameKeywordLUT[0] : topicNameKeywordLUT[1]
    tmp = _.replace(tmp, keyword, '');

    const values = _.split(tmp, '_')
    return _.slice(values, 1, values.length - 1).join('.')
}

export const allCompaniesDomains = async () => {
    const companies = await fetchCompanies()
    let domains = []
    _.each(companies, (company) => {
        domains = _.union(domains, company.brands)
    })
    return domains
}

/** hook per leggere tutti i domini in caso di utente admin */
export const useDomains = (isAdmin, activeDomains) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const loadDomains = async (isAdmin) => {
            try {
                if(isAdmin) {
                    const companies = await fetchCompanies()

                    let domains = []
                    _.each(companies, (company) => {
                        domains = _.union(domains, _.map(company.brands, (brand) => ({
                            ...brand,
                            companyName: company.name
                        })))
                    })
                    setData(domains)
                } else {
                    const activeDomainsInfo = await fetchActiveBrandsInfo()
                    setData(activeDomainsInfo)
                }
            } catch (e) {
                setError(error)
            }
            setLoading(false)
        }
        loadDomains(isAdmin)
    }, [])


    return {data: data, loading: loading, error: error}
}

/** partnership requests utility -------------------------------------------------------------- */
export const isPendingRequest = (pendingRequests, domain) => {
    return pendingRequests.includes(domain)
}

export const isRequestToAccept = (requestsToAccept, domain) => {
    return _.find(requestsToAccept, ["brand_domain", domain])
}

export const isActivePartner = (activePartners, domain) => {
    return activePartners.includes(domain)
}

export const isEveryBrandPartner = (activePartners, brands) => {
    return _.every(brands, (brand) => isActivePartner(activePartners, brand.domain));
}

export const isEveryRequestPending = (pendingRequests, brands) => {
    return _.every(brands, (brand) => isPendingRequest(pendingRequests, brand.domain));
}

/** codes utility - vecchie, da rivedere -------------------------------------------------------------- */
export const splitSgln = (sgln) => {
    const value = sgln.split(':');
    return value[4]
}

export const splitGtin = (sgln) => {
    const value = sgln.split(':');
    return value[3]
}

/** deprecated -> piuttosto _.startCase(str) di Lodash */
export const parseName = (name) => {
    let newStr = ''
    if (name) {
        newStr = name.replace(/_/g, ' ');
    }
    if (newStr) {
        newStr = `${newStr[0].toUpperCase()}${newStr.slice(1)}`
    }
    return newStr
}

export const extractSerialCode = (val) => {
    /** FORMATO ID NODI HISTORY DETAIL: "codiceSeriale" oppure "codiceSeriale,eventID" */
    return _.first(_.split(val, ','))
}

export const reverseParseName = (name) => {
    let newStr = ''
    if (name) {
        newStr = name.replace(/ /g, '_');
    }
    return newStr.toLowerCase()
}

export const extractCompanyPrefixFromSgln = (sgln) => {
    const value = splitSgln(sgln).split('.');
    return value[0]
}

export const extractCompanyPrefix = (prefix) => {
    //console.log("extractCompanyPrefix ---> cp:",_.replace(prefix, 'urn:gs1:prefix:',''))
    return _.replace(prefix, 'urn:gs1:prefix:','')
}

export const extractGtinFromUrn = (gtin) => {
    const value = gtin.split(':');
    return value[3]
}

export const searchProductFromPrefixes = (prefixes, products) => {
    //console.log("prefixes: ", prefixes)
    const instanceCode = prefixes[0]
    let classCode = extractProductClassCode(instanceCode)
    const product = _.find(products, ['key', classCode])
    //console.log('cerco prodotto --->', gtin)
    //console.log('i prodotti sono --->', products)
    return product ? product.name : 'No Name'
}

export const searchAddressFromSgln = (sgln, places) => {
    const address = places.filter(place => place.sgln === sgln)
    //console.log("trovato: ", address)
    //console.log('cerco address --->', gln)
    //console.log('tra luoghi --->', places)
    return address.length > 0 ? address[0].name : 'No Name'
}

export const searchPlaceFromSgln = (sgln, places) => {
    //console.log('ricevo --->', sgln)
    const place = places.filter(place => place.sgln === sgln)
    //console.log('cerco address --->', gln)
    //console.log('ho trovato --->', place)
    return place[0]
}

export const extractValue = (val) => {
    let value = val.split('=')
    let gtin = value[1]
    return gtin
}

export const extractValueData = (val) => {
    let value = val.split('=')
    //let data = Moment(value[1]).format('MMM Do \'YY')
    let data = i18next.t("formatters:date", {date: Moment(value[1])})
    return data
}

export const extractDateTime = (val) => {
    let dateComplete = val.split(':')
    let dateTime = dateComplete[0]
    return dateTime
}
