import {Box, Collapse, Fade, Slide, TextField, Typography} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import React, {useContext, useState} from "react";
import * as yup from "yup";
import {ThemeContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import chroma from "chroma-js";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/Lock";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {KeyboardArrowLeft} from "@material-ui/icons";
import axios from "axios";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../my_profile_settings/LanguageSelect";
import {severityTypes} from "../../utils/Utils";

const ForgotPasswordForm = () => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        email_or_username: yup.string().required(t('emailOrUsernameRequired')),
    });
    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '70%',
            justifyContent: "center",
            display: "block",
            margin: 'auto',
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: _theme.accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: _theme.accentColor,
                },
            },
        },
        grid: {
            justifyContent: "center",
            alignItems: "center",
            padding: '1%',
        },
        paper: {
            borderRadius: '15px'
        },
        container: {
            padding: '6%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        button: {
            //textTransform: 'none',
            marginTop: '6%',
            marginLeft: '2%',
            backgroundColor: chroma(_theme.accentColor).alpha(0.9).hex(),
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '25px'
        },
        sponsored: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        lockAvatar: {
            color: 'white',
            backgroundColor: _theme.primaryColor,
            //marginBottom: '0.5em'
        },
        form: {
            width: '70%',
        },
    }));
    const classes = useStyles();

    let history = useHistory();

    const {control, handleSubmit, errors} = useForm({
        validationSchema: schema,
    })

    const [openAlertMessage, setOpenAlertMessage] = useState({
        open: false,
        message: '',
        severity: severityTypes.WARNING
    });

    const onSubmit = async (_data) => {
        let url = "users/me/forgot_password/?email_or_username=" + _data.email_or_username
        await axios.get(url)
            .then((res) => {
                setTimeout(function () {
                    setOpenAlertMessage({
                        open: true,
                        message: t('profile.changePasswordForm.sentResetEmailSuccess'),
                        severity: severityTypes.SUCCESS
                    })
                }, 300)
            })
            .catch((err) => {
                const errMsg = err.response.data.detail[0]?.msg || err.response.data.detail
                console.log('error sending reset email', errMsg)
                setOpenAlertMessage({
                    open: true,
                    message: errMsg || 'Server Error',
                    severity: severityTypes.ERROR
                })
            })
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Fade in={true} appear={true} timeout={700}>
                    <div>
                        <Slide in={true} appear={true} direction={'right'} timeout={500}>
                            <div className={classes.container}>
                                <LanguageSelect/>
                                <Avatar className={classes.lockAvatar}>
                                    <LockIcon/>
                                </Avatar>
                                <img alt={"logo_iChain"} src="/assets/logo_iChain.png" style={{width: '35%'}}/>
                                <Typography align={'center'} variant={'h6'}>
                                    {t('profile.changePasswordForm.forgotPassword')}
                                </Typography>
                                <Typography color={'textSecondary'} align={'center'} variant={'body2'}
                                            style={{width: '58%'}}>
                                    {t('profile.changePasswordForm.resetPasswordInstruction')}
                                </Typography>
                                <Collapse in={!!openAlertMessage.open}>
                                    <Grid item xs={12} style={{marginBottom: '4%', marginTop: '4%'}}>
                                        <Alert severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
                                    </Grid>
                                </Collapse>
                                {/*<Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                           autoHideDuration={3000} onClose={handleCloseErrorMessage}>
                    <Alert onClose={handleCloseErrorMessage}
                           severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
                    </Snackbar>*/}
                                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                                    <Controller
                                        as={<TextField
                                            //variant={'outlined'}
                                            fullWidth
                                            //type="email"
                                            error={!!errors.email_or_username || openAlertMessage.severity === severityTypes.ERROR}
                                            helperText={errors.email_or_username?.message}
                                            label={t('profile.changePasswordForm.emailOrUsername')}/>}
                                        name="email_or_username"
                                        control={control}
                                    />
                                    <Box display={'flex'} alignItems={'flex-end'}>
                                        <Button
                                            style={{textTransform: 'none', paddingLeft: '16px', paddingRight: '16px'}}
                                            onClick={() => history.goBack()}
                                            startIcon={<KeyboardArrowLeft/>}>
                                            {t('actions.login')}
                                        </Button>
                                        <Button type="submit"
                                                fullWidth
                                                variant="contained"
                                                className={classes.button}>{t('profile.changePasswordForm.sendResetLink')}</Button>
                                    </Box>
                                </form>
                            </div>
                        </Slide>
                    </div>
                </Fade>
            </Paper>
            <Box className={classes.sponsored} p={1}>
                <Typography style={{color: 'darkgrey'}}>Powered by</Typography>
                <img alt={"logo_wise_side"} src="/assets/ws_logo_20_ago_2021.png" style={{marginLeft:'1%', width: '17%'}}/>
            </Box>
        </div>
    )
}

export default ForgotPasswordForm