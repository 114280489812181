import React, {createRef, useContext, useEffect, useMemo, useState} from "react";
import 'leaflet-geosearch/assets/css/leaflet.css';
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
import {Controller, useForm} from "react-hook-form";
import {Box, Button, FormControl, MenuItem} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Header from "../../components/Header";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import SummaryPlacePage from "./SummaryPlacePage";
import TextField from "@material-ui/core/TextField";
import {Map} from "react-leaflet";
import Switch from "@material-ui/core/Switch";
import Typography from '@material-ui/core/Typography';
import {MyTileLayer, severityTypes} from "../../utils/Utils";
import {
    availableLocationCodeTypes,
    buildGS1gln,
    buildInternalCode,
    convertGlnToSgln,
    findLocationCodeTypeValue,
    getNewPrivateCode,
    getNewSgln
} from "../../utils/CodesUtils";
import * as yup from "yup";
import OtherLocation from "../../components/new_place/OtherLocation";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Control from 'react-leaflet-control';
import RestoreIcon from '@material-ui/icons/Restore';
import {AuthContext, RoutingContext, ThemeContext} from "../../contexts";
import axios from "axios";
import _ from "lodash";
import CustomLoader from "../../components/CustomLoader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import WarningIcon from "@material-ui/icons/Warning";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {useTranslation} from "react-i18next";
import L from "leaflet"
//let L = window.L

const TheFormPlace = ({initValues, onSubmit, forceSubmit, editMode, setRender, editableSubplaces}) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        country: yup.string()
            .required(t('addPlace.enterCountry')),
        //city: yup.string()
        //    .required('Please enter city'),
        //gln: yup.string().required('Please enter gln'),
        gln: yup.string().when('generateCodeCheck', {
            is: (_generateCodeCheck) => _generateCodeCheck === false,
            then: yup.string()
                .required(t('entities.requiredField'))
        }),
        name: yup.string().required(t('addPlace.enterName'))
        //.length(5)
    });

    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        error: {
            color: 'red',
            margin: '0px'
        },
        grid: {
            marginLeft: '2%',
            paddingTop: '2%',
            width: '96%',
        },
        button: {
            textTransform: "none",
            marginLeft: "1%"
        },
        otherLocation: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '20%'
            },
        },
        icon: {
            color: accentColor,
            marginRight: theme.spacing(2),
        },
        infoChip: {
            marginLeft: '1%',
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
    }));
    const classes = useStyles();

    const height = {height: '100%'};
    const center = {lat: 41.90, lng: 12.49};
    // ripristino i valori salvati nel contenitore
    const {handleSubmit, control, setValue, getValues, errors} = useForm({
        validationSchema: schema,
        defaultValues: initValues
    });
    const [checked, setChecked] = useState(getValues("checked"));
    const [generateCodeCheck, setGenerateCodeCheck] = useState(getValues("generateCodeCheck")); // se generateCodeCheck === true -> ho già un gln
    const [markerOnMap, setMarkerOnMap] = useState(null);
    const [markerOnMap_checked, setMarkerOnMapChecked] = useState(null);
    const [editSub, setEditSub] = useState({
        edit: false,
        objSub: {},
    })

    let mapRef = createRef()
    var marker;

    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
        provider: provider,
        style: 'bar',
        autoComplete: true,
        marker: {
            icon: new L.Icon.Default(),
            draggable: false,
        },
        maxMarkers: 1, // optional: number      - default 1
        retainZoomLevel: false, // optional: true|false  - default false
        animateZoom: true, // optional: true|false  - default true
        autoClose: true, // optional: true|false  - default false
        searchLabel: t('addPlace.enterAddress'), // optional: string      - default 'Enter address'
        keepResult: false, // optional: true|false  - default false
    });

    const [, setPlace] = useState(
        {
            address: initValues.address,
            latitude: initValues.latitude,
            longitude: initValues.longitude
        })

    useEffect(() => {
        const map = mapRef.leafletElement
        map.addControl(searchControl);
        if(!checked) {
            if (getValues("center_1") && getValues("address_1")) {
                marker = L.marker(getValues('center_1'))
                    .bindPopup(getValues('address_1'))
                    .addTo(map)
                setMarkerOnMap(marker)
            }
            if (getValues("center_2") && getValues("address_2")) {
                marker = L.marker(getValues('center_2'))
                    .bindPopup(getValues('address_2'))
                setMarkerOnMapChecked(marker)
            }
        }
        if(checked){
            if (getValues("center_2") && getValues("address_2")) {
                marker = L.marker(getValues('center_2'))
                    .bindPopup(getValues('address_2'))
                    .addTo(map)
                setMarkerOnMapChecked(marker)

            }
            if (getValues("center_1") && getValues("address_1")) {
                marker = L.marker(getValues('center_1'))
                    .bindPopup(getValues('address_1'))
                setMarkerOnMap(marker)
            }
        }
    }, [])

    useEffect(() => {
        const map = mapRef.leafletElement
        const controlSearch = document.getElementsByClassName("leaflet-control-geosearch")[1]
        console.log("controlSearch:",controlSearch)
        /*
        // L.Control.Geocoder.nominatim() -> rotto
        map.on("click", (e) => {
            onMapClick(e, map)
        })*/
        if (!checked) map.off("click")
        map.on('geosearch/showlocation', e => {
            onLocationSelected(e, map)
        });
        if (checked) {
            if (controlSearch) {
                controlSearch.style.display = 'none'
            }
            if (markerOnMap) {
                map.removeLayer(markerOnMap);
            }
            if(markerOnMap_checked){
                map.addLayer(markerOnMap_checked)
            }
        }
        if (!checked) {
            if (controlSearch) {
                controlSearch.style.display = 'block'
            }
            if (markerOnMap_checked) {
                map.removeLayer(markerOnMap_checked);
            }
            if(markerOnMap){
                map.addLayer(markerOnMap)
            }
        }
    }, [checked])

    useEffect( () => {
        if(editSub.edit){
            handleSubmit(forceSubmit)()
        }
    }, [editSub.edit])


    function onLocationSelected(e, map) {
        if (!checked) {
            if (marker) {
                map.removeLayer(marker);
            } else {
                if(markerOnMap){
                    map.removeLayer(markerOnMap);
                }
            }
            setValue("center_1", e.marker._latlng)
            setValue("latitude_1", e.location.raw.lat)
            setValue("longitude_1", e.location.raw.lon)
            setValue("address_1", e.location.label)
            var n = e.location.label.split(",");
            if(n){
                setValue("country", n[n.length - 1])
            }

            setPlace({
                address: e.location.label,
                latitude: e.location.raw.lat,
                longitude: e.location.raw.lon
            })

            if(Object.keys(map._layers).length < 4){
                marker = L.marker(getValues('center_1'))
                    .bindPopup(getValues('address_1'))
                    .addTo(map)
                setMarkerOnMap(marker)
            }
        }
    }

    function onMapClick(e, map) {
        if (checked) {
            const geocoder = L.Control.Geocoder.nominatim()
            geocoder.initialize()
            geocoder.reverse(
                e.latlng,
                map.options.crs.scale(map.getZoom()),
                results => {
                    if (results.length > 0) {
                        setValue("address_2", results[0].name)
                        setValue("latitude_2", results[0].center.lat)
                        setValue("longitude_2", results[0].center.lng)
                        setValue("center_2", results[0].center)
                        setPlace({
                            address: results[0].name,
                            latitude: results[0].center.lat,
                            longitude: results[0].center.lng
                        })
                        var r = results[0];
                        if (r) {
                            if (marker) {
                                map.removeLayer(marker);
                                setMarkerOnMapChecked(null)
                            }
                            if (markerOnMap_checked) {
                                map.removeLayer(markerOnMap_checked);
                                setMarkerOnMapChecked(null)
                            }
                            setValue("center_2", r.center)
                            setValue("latitude_2", r.center.lat)
                            setValue("longitude_2", r.center.lng)
                            setValue("address_2", r.name)
                            var n = r.name.split(",");
                            if(n){
                                setValue("country", n[n.length - 1])
                            }else setValue("country", r.name)
                            marker = L.marker(getValues('center_2'))
                                .bindPopup(r.name)
                                .addTo(map)
                            setMarkerOnMapChecked(marker)
                        }
                    }
                }
            );
        }
    }

    const handleChangeSwitch = (event, newValue) => {
        setChecked(event.target.checked);
        return newValue;
    };

    function clearAddress() {
        const map = mapRef.leafletElement
        if(checked){
            setValue("center_2", '')
            setValue("latitude_2", '')
            setValue("longitude_2", '')
            setValue("address_2", 'r.name')
            setPlace({
                address: '',
                latitude: '',
                longitude: ''
            })
            if (markerOnMap_checked) {
                map.removeLayer(markerOnMap_checked);
                setMarkerOnMapChecked(null)
            }
        }
        if(!checked){
            setValue("center_1", '')
            setValue("latitude_1", '')
            setValue("longitude_1", '')
            setValue("address_1", '')
            setPlace({
                address: '',
                latitude: '',
                longitude: ''
            })
            if (markerOnMap) {
                map.removeLayer(markerOnMap);
                setMarkerOnMap(null)
            }
        }
    }

    const handleCheckedChange = (event) => {
        setGenerateCodeCheck(event.target.checked)
        setValue("gln",'')
        setValue("generateCodeCheck", event.target.checked)
    };

    return (
        <Box>
            <Box>
                <Paper>
                    <Header title={editMode.editMode ? t('actions.edit', {what: t('entities.location.label')})+' - ' + initValues.name : t('addPlace.addNew')}/>
                    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
                        <Box pl={2} pr={2} className={classes.prodBox}>
                            <Grid container spacing={3}>
                                <Grid container item xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <Box pr={2} pl={2}>
                                            <Box style={{display:'none'}}>
                                                <Controller
                                                    as={<TextField/>}
                                                    name="subplace_place"
                                                    control={control}
                                                />
                                            </Box>
                                            <Box style={{display:'none'}}>
                                                <Controller
                                                    as={<TextField/>}
                                                    name="id"
                                                    control={control}
                                                />
                                            </Box>
                                            <Box style={{display:'none'}}>
                                                <Controller
                                                    as={<TextField/>}
                                                    name="locations_extended"
                                                    control={control}
                                                />
                                            </Box>
                                            <Box mt={2}>
                                                <Controller
                                                    as={<TextField fullWidth label={_.startCase(t('entities.name'))}/>}
                                                    name="name"
                                                    control={control}
                                                />
                                                {errors.name &&
                                                <p className={classes.error}>{errors.name.message}</p>}
                                            </Box>
                                            {
                                                !editMode.editMode ?
                                                    <Box mt={2} p={1} borderColor={'lightgray'} border={1} borderRadius={5}>
                                                        <Grid container item xs={12}>
                                                            <Grid item xs={12}>
                                                                <Box display={'flex'}>
                                                                    <Controller
                                                                        style={{flex:1, marginRight: '16px'}}
                                                                        as={<TextField select label={t("addPlace.locationCodeType")}>
                                                                            <MenuItem value="gs1">{availableLocationCodeTypes.GLN.label}</MenuItem>
                                                                            <MenuItem value="internal">{availableLocationCodeTypes.INTERNAL.label}</MenuItem>
                                                                        </TextField>}
                                                                        name="locationCodeType"
                                                                        control={control}
                                                                        disabled={generateCodeCheck}
                                                                    />
                                                                    <Controller
                                                                        style={{flex:3}}
                                                                        as={<TextField name={"gln"} fullWidth label={!generateCodeCheck ? t('addPlace.insertLocationCode') : t('addPlace.iChainWillGenerateAlocationCode')}/>}
                                                                        name="gln"
                                                                        control={control}
                                                                        disabled={generateCodeCheck}
                                                                        generateCodeCheck={generateCodeCheck}
                                                                    />
                                                                    {errors.gln &&
                                                                    <p className={classes.error}>{errors.gln.message}</p>}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box pl={2} p={1} style={{display: 'flex', alignItems: 'center'}}>
                                                                    <WarningIcon className={classes.icon}/>
                                                                    <Controller
                                                                        as={<FormControl>
                                                                            <FormControlLabel
                                                                                control={<Checkbox checked={generateCodeCheck} onChange={handleCheckedChange}
                                                                                                   color="default"/>}
                                                                                label={<Typography>{t('addPlace.iDontHaveAcode')}</Typography>}
                                                                            />
                                                                        </FormControl>}
                                                                        name="generateCodeCheck"
                                                                        control={control}>
                                                                    </Controller>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    :
                                                    <Grid container item xs={12}>
                                                        <Grid item xs={12}>
                                                            <Box mt={2}>
                                                                <Controller
                                                                    as={<TextField disabled={true} fullWidth label={t('entities.location.code')}/>}
                                                                    name="gln"
                                                                    control={control}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                            }
                                            <Box mt={2}>
                                                <Controller
                                                    as={<TextField fullWidth label={t('entities.location.country')}/>}
                                                    name="country"
                                                    control={control}
                                                />
                                                {errors.country &&
                                                <p className={classes.error}>{errors.country.message}</p>}
                                            </Box>
                                            <Box mt={2}>
                                                <Controller
                                                    as={<TextField fullWidth label={t('entities.location.city')}/>}
                                                    name="city"
                                                    control={control}
                                                />
                                                {errors.city &&
                                                <p className={classes.error}>{errors.city.message}</p>}
                                            </Box>
                                        </Box>
                                        <Box mt={3} display="flex" flexDirection="row-reverse">
                                            <Box>
                                                <Controller
                                                    as={
                                                        <Switch/>
                                                    }
                                                    onChange={([e, data]) => handleChangeSwitch(e, data)}
                                                    name="checked"
                                                    control={control}
                                                    color="default"
                                                />
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                pr={1}
                                            >
                                                {
                                                    checked ?
                                                        <Typography>{t('addPlace.choosePlaceOnMap')}</Typography> :
                                                        <Typography>{t('addPlace.choosePlaceByAddress')}</Typography>
                                                }
                                            </Box>
                                        </Box>
                                        <Box style={{display: 'none'}}>
                                            <Controller
                                                as={<TextField/>}
                                                name="center_1"
                                                control={control}
                                            />
                                            <Controller
                                                as={<TextField/>}
                                                name="center_2"
                                                control={control}
                                            />
                                        </Box>
                                        <Box pl={2} pr={2} mb={2}>
                                            {
                                                !checked ?
                                                    <Box>
                                                        <Box mt={2}>
                                                            <Controller
                                                                as={<TextField fullWidth label={_.startCase(t('entities.location.address'))}/>}
                                                                name="address_1"
                                                                control={control}
                                                            />
                                                        </Box>
                                                        <Box mt={2}>
                                                            <Controller
                                                                as={<TextField fullWidth label={_.startCase(t('entities.location.latitude'))}/>}
                                                                name="latitude_1"
                                                                control={control}
                                                            />
                                                        </Box>
                                                        <Box mt={2}>
                                                            <Controller
                                                                as={<TextField fullWidth label={_.startCase(t('entities.location.longitude'))}/>}
                                                                name="longitude_1"
                                                                control={control}
                                                            />
                                                        </Box>
                                                    </Box> :
                                                    <Box>
                                                        <Box mt={2}>
                                                            <Controller
                                                                as={<TextField fullWidth label={_.startCase(t('entities.location.address'))}/>}
                                                                name="address_2"
                                                                control={control}
                                                            />
                                                        </Box>
                                                        <Box mt={2}>
                                                            <Controller
                                                                as={<TextField fullWidth label={_.startCase(t('entities.location.latitude'))}/>}
                                                                name="latitude_2"
                                                                control={control}
                                                            />
                                                        </Box>
                                                        <Box mt={2}>
                                                            <Controller
                                                                as={<TextField fullWidth label={_.startCase(t('entities.location.address'))}/>}
                                                                name="longitude_2"
                                                                control={control}
                                                            />
                                                        </Box>
                                                    </Box>
                                            }
                                        </Box>
                                        <Box mt={4} mb={3}>
                                            <Controller
                                                as={<OtherLocation
                                                    editMode={editMode}
                                                    initValues={initValues}
                                                    editableSubplaces={editableSubplaces}
                                                    handleSubmit={handleSubmit}
                                                    editSub={editSub}
                                                    setEditSub={setEditSub}
                                                    setRender={setRender}/>}
                                                control={control}
                                                name="locations"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={6}>
                                    <Box p={2} style={{height: '500px', width: '100%'}}>
                                        <Map
                                            style={height}
                                            center={getValues("center") ? getValues("center") : center}
                                            zoom={getValues("center") ? 18 : 4}
                                            minZoom={2}
                                            ref={
                                                m => {
                                                    mapRef = m;
                                                }
                                            }
                                        >
                                            <MyTileLayer/>
                                            <Control position="bottomleft" >
                                                <Tooltip
                                                    title={t('actions.clear', {what: t('entities.location.address')})}
                                                >
                                                    <IconButton
                                                        color="default"
                                                        onClick={clearAddress}
                                                    >
                                                        <RestoreIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Control>
                                        </Map>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {
                            !editSub.edit ?
                                <Box mt={3} pb={2}>
                                    <Button type="submit" color="default" variant="contained"
                                            className={classes.button}>{t('actions.next')}</Button>
                                </Box> : null
                        }
                    </form>
                </Paper>
            </Box>
        </Box>
    )
}

const TheSummaryPlace = ({data, onGoBack, editMode, editableSubplaces}) => {
    const { t } = useTranslation();

    const myContext = useContext(RoutingContext);
    const authContext = useContext(AuthContext)

    function goBack() {
        myContext.setRoute(editMode.other? 'OtherPlaces' : 'MyPlaces')
    }

    const [error, setError] = useState(false)
    const [openAlertMessage, setOpenAlertMessage] = useState({open: false, message:'', severity: severityTypes.ERROR});
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const addSub = async (subplace, count, loc_number) => {
        await axios.post('api/subplaces/'+(editMode.other ? 'others/':''), subplace)
            .then( () => {
                if(count === loc_number){
                    goBack()
                    //console.log('TUTTE LE SUB SONO STATE AGGIUNTE')
                }
            })
            .catch( (err) => {
                console.log('error posting subplace:', err.response.data)
                setOpenAlertMessage({
                    open: true,
                    message: t('errors.errorAddingSubPlace'),
                    severity: severityTypes.ERROR
                })
                setError(t('errors.errorAddingSubPlace'))
            })
    }

    async function getNewLocationInstanceCode(other, locClassType, gln, setOpenAlertMessage) {
        switch (locClassType) {
            case 'gln':
                return await getNewSgln(gln, authContext.companyPrefix)
            case 'internal':
                return await getNewPrivateCode((other ? 'not_mine/' : '')+'loc_instance/?loc_class=' + gln, setOpenAlertMessage)
            default:
                console.log("case loc class type unknown")
        }
    }

    const saveAll = async (other, locations, gln) => {
        if(locations) {
            const loc_number = locations.length
            let count = 0
            for(let location of locations) {
                try {
                    const locClassType = findLocationCodeTypeValue(gln)[0]
                    const codeLocation = await getNewLocationInstanceCode(other, locClassType, gln, setOpenAlertMessage);
                    count++
                    let newSubplace = {
                        sgln: codeLocation.code,
                        name: location.name,
                        place_id: gln,
                        small_description: location.description,
                        data_origin: other ? 'x.'+authContext.activeMainDomain : authContext.activeMainDomain,
                    }
                    await addSub(newSubplace, count, loc_number)
                } catch(e) {
                    console.log('error getting new location code:', e)
                }
            }
        } else {
            goBack()
        }
    }

    const onCompleteClick = async () => {
        //console.log('DATA --->', data)
        const locationCode = !editMode.editMode ? data.generateCodeCheck ?
            (await getNewPrivateCode((editMode.other ? 'not_mine/' : '')+'loc_class/')).code
            : data.locationCodeType === 'internal' ? buildInternalCode(editMode.other, authContext.activeMainDomain, 'loc', data.gln)
            : data.locationCodeType === 'gs1' ? buildGS1gln(data.gln) : data.gln : data.gln

        let myData = {
            gln: locationCode,
            data_origin: editMode.other ? 'x.'+authContext.activeMainDomain : authContext.activeMainDomain,
            address: data.checked ? data.address_2 : data.address_1,
            country: data.country,
            name: data.name,
            city: data.city,
            latitude: data.checked ? data.latitude_2 ? data.latitude_2 : null : data.latitude_1 ? data.latitude_1 : null,
            longitude: data.checked ? data.longitude_2 ? data.longitude_2 : null : data.longitude_1 ? data.longitude_1 : null,
        }
        if(!editMode.editMode) {
            const url = '/api/places/'+(editMode.other ? 'others/':'')
            await axios.post(url, myData)
                .then((res) => {
                    let instanceCodeDefault = !data.generateCodeCheck ?
                        data.locationCodeType === 'gs1' ?
                            convertGlnToSgln(findLocationCodeTypeValue(myData.gln)[1], authContext.companyPrefix)+'0'
                        : myData.gln+'.0' : myData.gln+'.0'

                    let mySubplaceDefault = {
                        data_origin: editMode.other ? 'x.'+authContext.activeMainDomain : authContext.activeMainDomain,
                        sgln: instanceCodeDefault,
                        name: data.name,
                        place_id: myData.gln,
                        small_description: "",
                    }
                    const url = 'api/subplaces/'+(editMode.other ? 'others/':'')
                    axios.post(url, mySubplaceDefault).then( (res) => {
                        saveAll(editMode.other, data.locations, myData.gln)
                    }).catch((err) => {
                        console.log('error:', err?.response?.data)
                        setOpenAlertMessage({
                            open: true,
                            message: t('errors.errorAddingSubPlace')+(err?.response?.data?.detail ? err?.response?.data?.detail[0]?.msg : ''),
                            severity: severityTypes.ERROR
                        })
                        setError(t('errors.errorAddingPlace')+(err?.response?.data?.detail ? err?.response?.data?.detail[0]?.msg : ''))
                    })
                }).catch((err) => {
                    console.log('error:', err?.response?.data)
                    setOpenAlertMessage({
                        open: true,
                        message: t('errors.errorAddingPlace')+(err?.response?.data?.detail ? err?.response?.data?.detail[0]?.msg : ''),
                        severity: severityTypes.ERROR
                    })
                    setError(t('errors.errorAddingPlace')+(err?.response?.data?.detail ? err?.response?.data?.detail[0]?.msg : ''))
                })
        } else {
            const url = 'api/places/'+(editMode.other ? 'others/':'') + data.id
            let editSubplaceDefault = {
                sgln: data.subplace_place.sgln,
                name: data.name,
                place_id: data.gln,
                small_description: data.subplace_place.small_description,
                data_origin: data.subplace_place.data_origin
            }
            axios({
                method: 'put',
                url: url,
                data: {...myData, id: data.id},
                config: { headers: {'Media-Type': 'application/json'}}
            })
                .then(function (response) {
                    if (response.status === 200) {
                        const url = 'api/subplaces/'+(editMode.other ? 'others/':'') + data.subplace_place._id
                        axios({
                            method: 'put',
                            url: url,
                            data: {...editSubplaceDefault, id: data.subplace_place._id},
                            config: { headers: {'Media-Type': 'application/json'}}
                        })
                            .then(function (response) {
                                if (response.status === 200) {
                                    let subToAdd = []
                                    data.locations.forEach( (sub) => {
                                        if(!sub.sgln){ // Non è ancora stato assegnato un sgln
                                            subToAdd.push(sub)
                                        }
                                    })
                                    saveAll(editMode.other, subToAdd, data.gln).then( () => goBack())
                                }
                            })
                            .catch(function (err) {
                                console.log('error editing subplace', err.response.data);
                                setOpenAlertMessage({
                                    open: true,
                                    message: t('errors.errorSavingSubPlace'),
                                    severity: severityTypes.ERROR
                                })
                                setError(t('errors.errorSavingSubPlace'))
                            });
                    }
                })
                .catch(function (err) {
                    console.log('errorSavingPlace', err.response.data);
                    setOpenAlertMessage({
                        open: true,
                        message: t('errors.errorSavingPlace'),
                        severity: severityTypes.ERROR
                    })
                    setError(t('errors.errorSavingPlace'))
                });
        }
    }

    return (
        <Box>
            {
              <SummaryPlacePage data={data} onGoBack={onGoBack} onCompleteClick={onCompleteClick}
                                              error={error}/>
            }
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlertMessage.open} onClose={handleCloseErrorMessage}>
                <Alert onClose={handleCloseErrorMessage} severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
            </Snackbar>
        </Box>
    )
}

const AddPlace = (props) => {
    const { t } = useTranslation();
    const { activeMainDomain } = useContext(AuthContext)
    //console.log("AddPlace --> props:",props)
    const [step, setStep] = useState(0);
    const [data, setData] = useState({
        country: '',
        city: '',
        name: '',
        locationCodeType: 'gs1',
        gln: '',
        data_origin: '',
        generateCodeCheck: false,
        address_1: '',
        latitude_1: '',
        longitude_1: '',
        center_1: '',
        address_2: '',
        latitude_2: '',
        longitude_2: '',
        center_2: '',
        locations_extended: [],
        checked: false,
    });
    const [okData, setOkData] = useState(false)
    const [render, setRender] = useState(0)

    const editMode = useMemo( () => {
        if(props.location.state && props.location.state.edit) {
            let editPlace = props.location.state.place

            if(okData){
                setOkData(false)
            }

            const fetchSubplaces = async (locationCode) => {
                const res =  await axios.get("/api/subplaces/_by_classcode/?domain="+ activeMainDomain +"&classcode=" + locationCode, {
                    headers: {
                        'Media-Type': 'application/json'
                    }
                })
                return res.data
            }

            let subplaces;
            let subplace_place;

            (async () => {
                subplaces = await fetchSubplaces(editPlace.gln)
                let mySubplaces = []
                let mySubplacesExtended = []
                _.forEach(subplaces.data, (subplace, index) => {
                    if(index !== 0) {
                        let objSub = {
                            name: subplace.name,
                            description: subplace.small_description,
                            sgln: subplace.sgln,
                            data_origin: subplace.data_origin
                        }
                        mySubplaces.push(objSub)
                        mySubplacesExtended.push(subplace)
                    } else {
                        subplace_place = subplace
                    }
                })

                if(render === 0){ // sono al primo caricamento
                    setData(
                        {
                            id: editPlace._id,
                            country: editPlace.country,
                            city: editPlace.city,
                            name: editPlace.name,
                            gln: editPlace.gln,
                            data_origin: editPlace.data_origin,
                            address_1: editPlace.address,
                            latitude_1: editPlace.latitude,
                            longitude_1: editPlace.longitude,
                            center_1: {
                                lat: editPlace.latitude,
                                lng: editPlace.longitude
                            },
                            address_2: '',
                            latitude_2: '',
                            longitude_2: '',
                            center_2: '',
                            locations: mySubplaces,
                            locations_extended: mySubplacesExtended,
                            subplace_place: subplace_place,
                            checked: false,
                            generateCodeCheck: false,
                        }
                    )
                } else {
                    _.forEach(data.locations, function(location) {
                        if(!location.sgln) // non è ancora stato assegnato un sgln
                        {
                            mySubplaces.push(location)
                        }
                    });

                    setData({...data,
                        locations: mySubplaces,
                        locations_extended: mySubplacesExtended,
                        subplace_place: subplace_place,
                        gln: data.gln
                    })
                }

                setOkData(true)
            })();
            return {editMode: true, id: props.location.state.place._id, other: props.location.state.other}
        }else{
            setOkData(true)
            return {editMode: false, id: null, other: props.location.state ? props.location.state.other : false}
        }
    }, [render])

    function onSubmit(data) {
        setData(data)
        setStep(1);
    }

    function forceSubmit(data) {
        //console.log("forceSubmit---> data:",data)
        setData(data)
    }

    return (
        <Box>
            {
                okData ?
                    <div>
                        {step == 0 ?
                            <TheFormPlace onSubmit={onSubmit} forceSubmit={forceSubmit} initValues={data} editMode={editMode} editableSubplaces={data.locations_extended} setRender={setRender}/>
                            : <TheSummaryPlace data={data} onGoBack={() => setStep(0)} editableSubplaces={data.locations_extended} editMode={editMode}/>}
                    </div>
                    : <CustomLoader size={80}  text={t('actions.loading', {what: ''})}/>
            }
        </Box>
    );
}

export default AddPlace;
