import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import React, {useState} from "react";

const RevokeCertificateDialog = (props) => {

    const [reason, setReason] = useState("");

    const [successOpen, setSuccessOpen] = useState(false);

    const [errorOpen, setErrorOpen] = useState(false);

    const handleSuccessClick = () => {
        setSuccessOpen(true);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
    };

    const handleErrorClick = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    const onRevokeCertificateClick = () => {
        axios({
            method: 'post',
            url: '/api/revocations/',
            data: {
                certificate_id: props.certificateId,
                reason: reason
            }
        })
            .then((res) => {
                console.log('res', res)
                props.onClose()
                props.afterDelete()
                handleSuccessClick()
            })
            .catch((err) => {
                console.log('err', err)
                props.onClose()
                handleErrorClick()
            })
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm revocation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Specify a reason for revocation.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        fullWidth
                        onChange={(event) => {
                            setReason(event.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onRevokeCertificateClick} color="primary">
                        Revoke
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    Operation done successfully
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Error during operation
                </Alert>
            </Snackbar>
        </div>
    );
}

export default RevokeCertificateDialog