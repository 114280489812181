import React, {useContext, useState} from "react";
import {Box, Chip} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import {parseName} from "../../utils/Utils";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import * as PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {ThemeContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const FieldInfo = (props) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        optionalChip: {
            border: '1px solid '+ accentColor,
            color: accentColor,
            fontWeight: 'bold',
        },
        requiredChip: {
            border: '1px solid #f56c62',
            color: '#f56c62',
            fontWeight: 'bold',
        },
    }));
    const classes = useStyles();

    const [show, setShow] = useState(false)

    function toggleShow() {setShow(!show)}

    return <Box style={{marginRight: '8px', display: "flex", alignItems: 'center', flexWrap: 'wrap'}}>
        {props.name ? <FormLabel style={{marginRight:'8px'}} component="legend">{parseName(props.name)||props.name}</FormLabel> : null}
        {
            props.description ?
                <Tooltip
                arrow
                placement="top"
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={toggleShow}
                open={show && !!props.description}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    <React.Fragment>
                        <Typography style={{lineBreak: 'auto'}} variant={'caption'}>{props.description}</Typography>
                    </React.Fragment>
                }>

                    <IconButton onClick={toggleShow}>
                        {props.icon || <HelpIcon fontSize={'small'}/>}
                    </IconButton>

        </Tooltip> : null }
        {
            props.optional ?
                <Chip size={'small'}
                      variant={'outlined'}
                      label={t('entities.optional')}
                      className={classes.optionalChip}
                />
                : props.external ? <Chip size={'small'}
                           variant={'outlined'}
                           label={t('entities.required')}
                           className={classes.requiredChip}
                /> : null
        }
            {/*<Collapse in={show}><FormLabel component="legend" className={props.classes.subLabel}>
                {props.description}
            </FormLabel></Collapse>*/}
    </Box>;
}

FieldInfo.propTypes = {
    name: PropTypes.any,
    description: PropTypes.any,
    optional: PropTypes.bool,
    external: PropTypes.bool,
};

export default FieldInfo
