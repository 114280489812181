import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Typography} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Moment from 'moment';
import {fetchDocumentId, parseName} from "../../utils/Utils";
import {eventTypes, splitCbvValues} from "../../utils/CbvUtils";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import EventDetail from "../events/EventDetail";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import ArrowDownwardIcon from '@material-ui/icons/ArrowUpward';
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import {BizStepIcon} from "../CustomBizStepIcons";
import {ThemeContext} from "../../contexts";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {langResources} from "../../i18n/i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    grid: {
        marginLeft: '2%',
        paddingTop: '2%',
        width: '96%',
    },
    date: {
        fontSize: '0.825rem',
    },
    eventName: {
        fontSize: '0.825rem',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export const AttachedBizTrans = ({bizTransactionList, numberOfAttachment}) => {
    const {accentColor} = useContext(ThemeContext)
    /** per avere la bizTransLut per il numero di allegati in modo asincrono*/
    const [bizTransLut, setBizTranLut] = useState()
    useEffect(() => {
        if(numberOfAttachment === null || numberOfAttachment === undefined) {
            let source = axios.CancelToken.source();
            (async () => {
                let _bizTransLut = {}
                for (let transaction of bizTransactionList) {
                    if (transaction.bizTransaction) {
                        const r = await fetchDocumentId(source, transaction.bizTransaction)
                        if (r)
                            _bizTransLut[transaction.bizTransaction] = {documentId: r.doc_db_id, _id: r._id}
                    }
                }
                //if (!_.isEmpty(_bizTransLut)) {
                //console.log("_bizTransLut:",_bizTransLut)
                setBizTranLut(_bizTransLut)
                //}
            })();
            return function () {
                source.cancel("Cancelling in cleanup");
            };
        }
    }, )

    return (<div style={{margin: '5%'}}>
            <Chip label={numberOfAttachment !== null && numberOfAttachment !== undefined ? numberOfAttachment : !bizTransLut ? '...' : Object.keys(bizTransLut).length} size={"small"}
                  icon={<AttachFileIcon style={{color: "gray"}}
                  />}
                  style={{
                      //marginLeft: '20%',
                      color: accentColor,
                      border: '1px solid ' + accentColor,
                      fontWeight: 'bolder',
                      backgroundColor: 'transparent',
                      padding: '5%',
                  }}
            />
    </div>)
}

const TableIncomingEvents = ({events, limit, setLimit, skip, setSkip, direction, setDirection, maxBodyHeight}) => {
   // const t0 = performance.now()

    const { t } = useTranslation();
    const {primaryColor} = useContext(ThemeContext)

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])

    const handleClickOpen = (event, rowData) => {
        setData(rowData)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Per bug di react-grid-layout
     * -> drag dei pannelli anche quando il dialog era aperto
     * */
    const onMouseDown = (ev) => {
        if (open) {
            ev.stopPropagation();
        }
    };

    const CustomDataField = ({data}) => {
        return <Typography className={classes.date}>{t("formatters:dateTime", {date: Moment(data)})}</Typography>
    }

    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open])

    let columns = [
        {
            title: '',
            field: 'bizStepIcon',
            align: 'center',
            width: '20%',
            sorting: false,
            render: rowData => <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap:'wrap'}}>
                <BizStepIcon bizstep={rowData.bizStep} color={primaryColor}/>
                {rowData.type === eventTypes.TRANSACTION.value && rowData.attatchment_counter > 0 ?
                    <AttachedBizTrans numberOfAttachment={rowData.attatchment_counter} bizTransactionList={rowData.bizTransactionList}/> : null}
            </div>
        },
        {
            title: _.startCase(t('entities.name')),
            field: 'bizStep',
            width: '40%',
            sorting: false,
            render: rowData =>
                <Box className={classes.eventName}>
                    {
                        rowData.eventName ?
                            parseName(rowData.eventName) : splitCbvValues(rowData.bizStep)
                    }
                </Box>
        },
        {
            title: _.startCase(t('entities.date.label')),
            field: 'eventTime',
            width: '40%',
            sorting: true,
            type: 'datetime',
            render: rowData =>
                <CustomDataField data={rowData.eventTime}/>
        },
    ]

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const handleOrderChange = (orderedColumnId, orderDirection) => {
        setSkip(0)
        direction === -1 ? setDirection(1) : setDirection(-1)
    };

    const tab = (langResources[localStorage.getItem('iChain_lang') || 'en'])?.translation.entities.localizedTable

    //const t1 = performance.now()
    //t1-t0 > 0 && console.log("-------------------------------------------- TIME TableINCOMINGEvents --->", t1-t0)
    return (
        <Box pb={2}>
            {
                events ?
                    <div style={{maxWidth: '100%'}}>
                        <MaterialTable
                            columns={columns}
                            localization={tab}
                            onRowClick={handleClickOpen}
                            data={events.data}
                            options={{
                                search: false,
                                showTitle: false,
                                paging: true,
                                toolbar: false,
                                maxColumnSort: 1,
                                maxBodyHeight: maxBodyHeight,
                                headerStyle: {backgroundColor: '#fafafa'},
                                pageSize: limit,
                                pageSizeOptions: [10, 30, 50, 100],
                                tableLayout: "fixed",
                                idSynonym: "eventID"
                            }}

                            components={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            totalCount={events.count}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            onOrderChange={handleOrderChange}
                            icons={
                                direction === -1 ?
                                    {
                                        SortArrow: forwardRef((props, ref) => <ArrowDownwardIcon{...props} ref={ref}/>)
                                    } : null}
                            page={skip}
                        />
                    </div> : null
            }
            <Dialog onMouseDown={onMouseDown} fullWidth={true} maxWidth={"lg"} open={open} onClose={handleClose}
                    scroll='paper'>
                <Grid container className={classes.grid} justifyContent="space-between" alignItems="center">
                    <Grid item xs={8}>
                        <Typography variant="h6">{_.startCase(t('entities.event'))}</Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-end" xs={3}>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <DialogContent ref={descriptionElementRef} tabIndex={-1}>
                    <EventDetail data={data} classes={classes}/>
                </DialogContent>
            </Dialog>
        </Box>

    )
}

export default TableIncomingEvents
