import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {Controller} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, CircularProgress} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import RulesComponent from "./rules_components/RulesComponent";
import {parseName} from "../../utils/Utils";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from '@material-ui/icons/Add';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {RelationshipContext, ThemeContext} from "../../contexts";
import _ from 'lodash'
import Chip from "@material-ui/core/Chip";
import {createFilterOptions} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import Typography from "@material-ui/core/Typography";
import {availableProductCodeTypes, convertLocationClassToInstance, findCodeTypeAndValue} from "../../utils/CodesUtils";

const filter = createFilterOptions();

const RelationshipForm = ({onSubmit, control}) => {
    const {t} = useTranslation()
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        error: {
            color: 'red',
            margin: '0px'
        },
        icon: {
            marginRight: '1%',
            color: 'darkgray'
        },
        iconAdd: {
            marginRight: '1%',
            color: 'black'
        },
        button: {
            width: '25%',
            backgroundColor: accentColor,
            color: "white",
            borderRadius: "20px"
        }
    }));
    const classes = useStyles();

    const relationshipContext = useContext(RelationshipContext);

    const [state, setState] = useState({
        chosenDomain: relationshipContext.chosenDomain,
        places: relationshipContext.places,
        counter: 0,
    })

    const [{data: eventTemplates, loading: loadingTemplates, error: errorTemplates},] = useAxios({
            url: "/api/capture/event_templates/_by_domain/?synthesis=true&domain="+state.chosenDomain.domain
        },
        {useCache: false}
    )

    const [{data: _rules, loading: loadingRules, error: errorRules}, refetchRules] = useAxios({
            url: "/api/rules/?domain="+(state.chosenDomain.domain)
        },
        {useCache: false}
    )

    const [{data: chains, loading: loadingChains, error: errorChains}] = useAxios({
            url: "/api/rules/_supply_chain_tags/?domain=" + state.chosenDomain.domain
        },
        {useCache: false}
    )

    const [{data: _allProducts, loading: loadingProducts, error: errorProducts},] = useAxios({
            url: "/api/items/?domain=" + state.chosenDomain.domain + "&not_producer=true&producer=true"
        },
        {useCache: false}
    )

    const [{data: places, loading: loadingPlaces, error: errorPlaces},] = useAxios({
            url: "/api/places/?domain=" + state.chosenDomain.domain + "&mine=true&not_mine=true&limit=0"
        },
        {useCache: false}
    )

    const [{data: subplaces, loading: loadingSubPlaces, error: errorSubPlaces},] = useAxios({
            url: "/api/subplaces/?domain=" + state.chosenDomain.domain + "&mine=true&not_mine=true&limit=0"
        },
        {useCache: false}
    )

    const allProducts = useMemo(() => {
        if (!_allProducts || !relationshipContext.allCompanies)
            return null
        let products_all = _.map(_allProducts.data, product => {
            let brand = _.find(relationshipContext.allCompanies, ['domain', product.producer]);
            return {
                ...product,
                mine: product.producer === state.chosenDomain.domain,
                name: product.name + (brand ? ' - ' + brand.name : ''),
                companyPrefix: brand && findCodeTypeAndValue(product.key)[0] === availableProductCodeTypes.GTIN14 ?
                    brand.company_prefix : null
            }
        });

        return _.orderBy(products_all, 'mine', 'desc')
    }, [_allProducts, relationshipContext.allCompanies])

    const allPlaces = useMemo(() => {
        if (!places || !subplaces || !relationshipContext.allCompanies)
            return null
        //console.log('VALORI ----> my_company:', my_company)
        const _places = _.map(places.data, (place) => {
            const company = _.find(relationshipContext.allCompanies, ['domain', place.data_origin])
            let sgln = convertLocationClassToInstance(place.gln)
            return {
                ...place,
                sgln: sgln,
                companyName: company.name,
                mine: company.domain === state.chosenDomain.domain
            }
        })

        const _subplaces = _.map(subplaces.data, (subplace) => {
            const company = _.find(relationshipContext.allCompanies, ['domain', subplace.data_origin])
            return {...subplace, companyName: company.name, mine: company.domain === state.chosenDomain.domain}
        })
        return {bizLocation: _places, readPoint: _subplaces}
    }, [places, subplaces, relationshipContext.allCompanies])

    let emptyRuleObj = {
        name: t('createRelationship.newRelationship'),
        active: true,
        selectType: relationshipContext.types[0],
        source: state.chosenDomain,
        destination: null,
        chain: [],
        rules: ''
    }

    const rules = useMemo(() => {
        if(!_rules)
            return null
        _rules?.data.unshift(emptyRuleObj)
        return _rules.data||[]
    }, [_rules])

    useEffect(() => {
        if (!relationshipContext.editMode.state) {
            if (relationshipContext.getValues("name")) {
                let myRule = rules.filter(rule => parseName(rule.name).toLowerCase() === parseName(relationshipContext.getValues("name")).toLowerCase())
                if (myRule.length > 0) {
                    relationshipContext.setValue("editCreate", myRule[0])
                }
            }
        } else {
            setTimeout(function () {
                let myRule = _.find(rules, ['_id', relationshipContext.editMode.editObj._id])
                myRule && relationshipContext.setValue("editCreate", myRule)
                handleChangeRelationship(null, relationshipContext.editMode.editObj)
            }, 200);
        }
    }, [])

    const handleChangeRelationship = (e, newValue, reason) => {
        //console.log('handleChangeRelationship --->newValue:', newValue)
        if(reason === "clear") {
            relationshipContext.setEditMode({...relationshipContext.editMode, state: false})
            relationshipContext.reset({
                    name: '',
                    source: state.chosenDomain,
                    destination: null,
                    chain: [],
                    active: true,
                    rules: '',
                })
        }
        relationshipContext.setEditMode({...relationshipContext.editMode, state: true})
        relationshipContext.clearError()
        refetchRules()
        let objValue = newValue
        if (newValue) {
            if (newValue.name !== t('createRelationship.newRelationship') || newValue.rules !== "") {
                if (newValue._id) {
                    relationshipContext.setValue("id", newValue._id)
                }
                let boolFound = true
                if (typeof newValue === 'string') {
                    objValue = {
                        name: newValue
                    }
                    boolFound = false
                }
                if (boolFound) {
                    const partner = relationshipContext.allCompanies.filter(val => val.domain === newValue.partner)
                    //const source = relationshipContext.sources.find(val => val.domain === convertToDomain(newValue.source))
                    if (newValue.name) {
                        relationshipContext.setValue("name", parseName(newValue.name))
                    }
                    if (partner.length > 0) {
                        relationshipContext.setValue("destination", partner[0])
                    }

                    relationshipContext.setValue("chain", _.map(newValue.chain, (chain) => ({chain: chain})))
                    relationshipContext.setValue("rules", newValue.rules)

                    relationshipContext.setValue("active", newValue.active)
                    relationshipContext.setValue("selectType", parseName(newValue.type))
                } else if (newValue.name) {
                    relationshipContext.setEditMode(
                        {
                            state: true,
                            name: newValue.name
                        }
                    )
                }
            } else {
                relationshipContext.reset({
                        name: '',
                        source: state.chosenDomain,
                        destination: null,
                        chain: [],
                        active: true,
                        rules: '',
                    }
                )
                relationshipContext.setEditMode(
                    {
                        state: false,
                        name: ''
                    }
                )
            }
        }
        return objValue;
    };

    const handleInputChange = (e, data) => {
        return data;
    };

    const handleChangeSource = (e, newValue, reason) => {
        setState({...state, chosenDomain: newValue})
        relationshipContext.setEditMode({...relationshipContext.editMode, state: false})
        relationshipContext.reset({
            name: '',
            source: state.chosenDomain,
            destination: null,
            chain: [],
            active: true,
            rules: '',
        })
        return newValue
    };

    const handleChange = (e, newValue, reason) => {
        return newValue
    };

    const RuleTypeInput = ({value, onChange}) => {

        const handleClickItem = (e) => {
            setState({...state, counter: state.counter + 1})
        }

        return (
            <FormControl fullWidth>
                <InputLabel>{_.startCase(t('entities.prefix'))}</InputLabel>
                <Select disabled={true} value={value || relationshipContext.types[0]} onChange={onChange}>
                    {
                        relationshipContext.types.map(
                            (type) => (
                                <MenuItem value={type} key={type} onClick={handleClickItem}>{type}</MenuItem>)
                        )
                    }
                </Select>
            </FormControl>
        )
    }

    const RuleTypeInputDisabled = ({value, onChange}) => {
        return (
            <FormControl fullWidth disabled>
                <InputLabel>{_.startCase(t('entities.prefix'))}</InputLabel>
                <Select value={value || relationshipContext.types[0]} onChange={onChange}>
                    {
                        relationshipContext.types.map(
                            (type) => (
                                <MenuItem value={type} key={type}>{type}</MenuItem>)
                        )
                    }
                </Select>
            </FormControl>
        )
    }

    useEffect(() => {
        if(state.chosenDomain)
            relationshipContext.setValue("source", state.chosenDomain)
    }, [state.chosenDomain])

    return (
        <div style={{width: '100%'}}>
            <form className={classes.root} onSubmit={relationshipContext.handleSubmit(onSubmit)}>
                {
                    //!relationshipContext.editMode.state ?
                    <Box pb={2} display={'flex'}>
                        <Controller
                            style={{flex:2, marginRight:'16px'}}
                            name="source"
                            control={control}
                            onChange={([e, data]) => handleChangeSource(e, data)}
                            as={
                                <Autocomplete
                                    disableClearable
                                    options={relationshipContext.sources}
                                    renderOption={option => <Fragment>{(option.image || option.img) &&
                                    <ListItemAvatar>
                                        <Avatar
                                            src={(option.image || option.img) ? ("api/uploads/uploads/" + (option.image || option.img)) : null}>
                                        </Avatar>
                                    </ListItemAvatar>}
                                        <ListItemText
                                            primary={`${option.name}`}
                                            secondary={option.domain}
                                            style={{color: primaryColor}}/>
                                    </Fragment>}
                                    getOptionLabel={option => option.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={_.startCase(t('entities.source', 'Source'))}
                                            //variant="outlined"
                                        />
                                    )}
                                />
                            }
                            rules={{required: true}}
                        />
                        <Controller
                            name="editCreate"
                            style={{flex:4}}
                            control={control}
                            onChange={([e, data, reason]) => handleChangeRelationship(e, data, reason)}
                            onInputChange={(e, data) => handleInputChange(e, data)}
                            as={
                                <Autocomplete
                                    disabled={!rules}
                                    options={rules||[]}
                                    getOptionLabel={option => option.name ? parseName(option.name) : ''}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={t('createRelationship.createEditRelationship')}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endadornment: (
                                                    <React.Fragment>
                                                        {loadingRules ? <CircularProgress size={20}/> : null}
                                                        {params.InputProps.endadornment}
                                                    </React.Fragment>),
                                            }}
                                        />
                                    )}
                                    renderOption={(option, {selected}) => (
                                        (option.name && option.name !== t('createRelationship.newRelationship')) || option.rules !== "" ?
                                            <React.Fragment>
                                                <EditIcon className={classes.icon} fontSize="small"/>
                                                {option.name ? parseName(option.name) : ''}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <AddIcon className={classes.iconAdd} fontSize="small"/>
                                                <Box style={{fontWeight: 'bold', width: '100%'}}>
                                                    {option.name ? parseName(option.name) : ''}
                                                </Box>
                                            </React.Fragment>
                                    )}
                                />
                            }
                            rules={{required: true}}
                        />
                    </Box> //: null
                }
                {_rules && chains && eventTemplates && allProducts && allPlaces ? <Paper>
                    <Box pt={2} pl={3} pr={3}>
                        <input name="id" type="text" ref={relationshipContext.register} style={{display: 'none'}}/>
                        <Controller
                            as={<TextField size={'small'} variant="outlined" fullWidth
                                           label={_.startCase(t('entities.name'))}/>}
                            name="name"
                            control={control}
                            rules={{required: true}}
                        />
                        {relationshipContext.errors.name &&
                        <Box>
                            <p className={classes.error}>{relationshipContext.errors.name.message}</p>
                        </Box>}
                    </Box>
                    <Box pt={2} pl={3} pr={3}>
                        <Controller
                            name="destination"
                            control={control}
                            onChange={([e, data]) => handleChange(e, data)}
                            as={
                                <Autocomplete
                                    size={'small'}
                                    options={relationshipContext.destinations}
                                    renderOption={option => <Fragment>{(option.image || option.img) &&
                                    <ListItemAvatar>
                                        <Avatar
                                            src={(option.image || option.img) ? ("api/uploads/uploads/" + (option.image || option.img)) : null}>
                                        </Avatar>
                                    </ListItemAvatar>}
                                        <ListItemText
                                            primary={`${option.name}`}
                                            secondary={option.domain}
                                            style={{color: primaryColor}}/>
                                    </Fragment>}
                                    getOptionLabel={option => option.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={t('entities.destinationPartner')}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            }
                            rules={{required: true}}
                        />
                        {relationshipContext.errors.destination &&
                        <Box>
                            <p className={classes.error}>{relationshipContext.errors.destination.message}</p>
                        </Box>
                        }
                    </Box>
                    <Box pt={2} pr={3} pl={3}>
                        <Controller
                            name="chain"
                            control={control}
                            onChange={([e, data]) => handleChange(e, data)}
                            onInputChange={(e, data) => handleInputChange(e, data)}
                            as={
                                <Autocomplete
                                    freeSolo
                                    size={'small'}
                                    multiple
                                    options={_.map(chains, (chain) => ({chain: chain}))}
                                    getOptionLabel={option => option ? option.chain : ''}
                                    renderOption={option =>
                                        <Fragment>{option ? (option.inputValue ? t('actions.add') + ": " : '') + option.chain : ''}</Fragment>}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={t('createRelationship.supplyChainTags')}
                                            variant="outlined"
                                            //placeholder="You can add write a new tag then press enter to confirm"
                                        />
                                    )}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                label: t('actions.add', {what: params.inputValue}),
                                                chain: params.inputValue
                                            });
                                        }

                                        return filtered;
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option.chain} {...getTagProps({index})} />
                                        ))
                                    }
                                />}
                        />
                    </Box>
                    <Box pt={1} pl={3} pr={3}>
                        <Controller
                            as={<FormControlLabel control={<Checkbox color="default"/>}
                                                  label={t('relationshipsGraph.active_female')} labelPlacement="end"/>}
                            name="active"
                            control={control}
                            type="checkbox"
                        />
                    </Box>
                    <Box pt={2} pl={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                {
                                    !relationshipContext.editMode.state ?
                                        <Box>
                                            <Controller
                                                as={
                                                    <RuleTypeInput/>
                                                }
                                                name="selectType"
                                                control={control}

                                                rules={{
                                                    validate: () => {
                                                        let rules = relationshipContext.getValues("rules");
                                                        if (rules.length === 0)
                                                            return true
                                                        let ruleType = "locations" in rules ? t('createRelationship.masterData', 'Master data') : t('createRelationship.4wEvents', '4w events')
                                                        if (relationshipContext.getValues("selectType") === ruleType) {
                                                            return true
                                                        } else {
                                                            relationshipContext.setValue("rules", '')
                                                            return true
                                                        }
                                                    }
                                                }}

                                            />
                                            <FormHelperText>{t('createRelationship.selectType')}</FormHelperText>
                                        </Box>
                                        :
                                        <Box>
                                            <Controller
                                                as={
                                                    <RuleTypeInputDisabled/>
                                                }
                                                name="selectType"
                                                control={control}
                                            />
                                            <FormHelperText>{t('createRelationship.selectType')}</FormHelperText>
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <Box pl={3} pr={3}>
                        <Controller
                            as={<RulesComponent/>}
                            control={control}
                            name="rules"
                            places={allPlaces}
                            products={allProducts}
                            eventTemplates={_.map(eventTemplates, (template) => (template.name))}
                            rules={{
                                validate: () => {
                                    let validate = false
                                    if (relationshipContext.getValues("selectType") === "4w events") {
                                        if (relationshipContext.getValues("rules").length > 0) {
                                            validate = true
                                        }
                                    }
                                    if (relationshipContext.getValues("selectType") === "Master data") {
                                        if (relationshipContext.getValues("rules").locations.length > 0 || relationshipContext.getValues("rules").items.length > 0) {
                                            validate = true
                                        }
                                    }
                                    return validate;
                                }
                            }}
                        />
                        {relationshipContext.errors.rules &&
                        <Box pt={2}>
                            <p className={classes.error}>{relationshipContext.errors.rules.message}</p>
                        </Box>
                        }
                    </Box>
                    <Box mt={2} p={3} style={{display: 'flex', justifyContent: 'center'}}>
                        <Button className={classes.button} type="submit" variant="contained">
                            {
                                relationshipContext.editMode.state ?
                                    t('createRelationship.saveRelationship', 'Save Relationship')
                                    : t('createRelationship.createRelationship', 'Create Relationship')
                            }
                        </Button>
                    </Box>
                </Paper>
                    : loadingRules || loadingChains || loadingTemplates || loadingProducts || loadingPlaces || loadingSubPlaces ? <CustomLoader size={80}/>
                        : errorRules || errorChains || errorTemplates || errorProducts || errorPlaces || errorSubPlaces ? <Typography style={{color:'darkgrey'}}>{t('errors.genericServerError')}</Typography>
                            : null
                }
            </form>
        </div>
    )
}

export default RelationshipForm
