import React, {useContext, useEffect, useState} from "react";
import {Box, CircularProgress, Grid} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import {ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import {findLocationCodeTypeValue} from "../../../utils/CodesUtils";
import useAxios from "axios-hooks";

export const FixedValueComponent = ({options, disabled, formDataFieldName, formDataFieldValue, handleChange, formLabel}) => {
    const {accentColor, primaryColorAlpha, iconHoverBg} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        icon: {
            color: 'darkgray',
            '&:hover': {
                background: iconHoverBg,
            },
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: accentColor,
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },

    }));
    const classes = useStyles()

    const [{data: subplace}, postExecuteSubPlaces] = useAxios({
            method: "GET",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    const [fixedValue, setFixedValue] = useState(formDataFieldValue ? formDataFieldValue : null)

    useEffect(() => {
        setFixedValue(formDataFieldValue)
    },[formDataFieldValue])

    useEffect(() => {
        const url = "/api/subplaces/_by_code/?code=" +(formDataFieldValue?.sgln || formDataFieldValue)
        if((formDataFieldName === 'fixedBizLocation' || formDataFieldName === 'fixedReadPoint') && formDataFieldValue)
            postExecuteSubPlaces({url: url})
    }, [])

    useEffect(() => {
        if(subplace)
            setFixedValue(subplace)
    }, [subplace])

    return (
        <Box pl={2} style={{flex:'3'}}>
            <Autocomplete
                clearOnEscape={true}
                disabled={disabled}
                options={options}
                getOptionLabel={(option) => (option?.name ? (option.name+' - '+(option.producer||option.data_origin))
                    : option)}
                value={fixedValue}
                onChange={(event, newValue) => {
                    setFixedValue(newValue)
                    handleChange(formDataFieldName, newValue)
                }}
                renderOption={(option, {selected}) => {
                    const [codeType, codeValue, domain] = option ?
                        findLocationCodeTypeValue(option.sgln)
                        : [null, null, null]

                    return (<React.Fragment>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography className={classes.name}>{option.name ? option.name : option.address}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {
                                        codeType ?
                                            <Chip
                                                size={'small'}
                                                label={codeType.type ? codeType.type : codeType}
                                                className={classes.codeTypeChip}
                                            /> : null
                                    }
                                    {
                                        domain ?
                                            <Chip
                                                size={'small'}
                                                label={domain}
                                                className={classes.codeTypeChip}
                                            /> : null
                                    }
                                    <Typography className={classes.code}>{option ? /*(domain ? domain +':' : '') +*/ codeValue : ''}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option.name ? option.name : option.address}
                            style={{backgroundColor: primaryColorAlpha, opacity: '0.8'}}
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={params => {
                    const {InputProps, ...restParams} = params;
                    const {startAdornment, ...restInputProps} = InputProps;
                    return (
                        <TextField
                            {...restParams}
                            size={'small'}
                            variant={"outlined"}
                            //label={formLabel}
                            placeholder={formLabel}
                            error={fixedValue === null}
                            InputProps={{
                                ...restInputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {disabled ? <CircularProgress size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>),
                                startAdornment: (
                                    <div style={{
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                    }}
                                    >
                                        {startAdornment}
                                    </div>
                                ),
                            }}
                        />
                    );
                }}
            />
        </Box>
    )
}
