import React, {useState} from 'react';
import MaterialTable from 'material-table';
import ComposeFileDeleteDialog from "../ComposeFileDeleteDialog";

const MaterialTableDemo = ({composeFiles, limit, setLimit, skip, setSkip, updateList}) =>  {

    let columns = [
        { title: 'Filename', field: 'filename'},
        { title: 'Upload Date', field: 'uploadDate', render: rowData => <div>{rowData.uploadDate
                .replace("T", " ")
                .slice(0,19)}</div>},
    ]

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const [open, setOpen] = useState(false);

    const [id, setComposeFileId] = useState("")

    return (
        <div style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                data={composeFiles.data}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Delete Compose File',
                        onClick: (event, rowData) => {
                            setComposeFileId(rowData._id)
                            setOpen(true)
                        },
                    }
                ]}
                totalCount={composeFiles.count}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={skip}
            />
            <ComposeFileDeleteDialog composeFileId={id} open={open} onClose={() => {setOpen(false)}} updateList={updateList}/>
        </div>
    );
}

export default MaterialTableDemo
