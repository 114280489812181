import React, {useContext, useState} from 'react';
import MaterialTable from 'material-table';
import {RoutingContext} from "../../contexts";
import {useWindowSize} from "../../utils/Utils"
import ConfigurationDeleteDialog from "../ConfigurationDeleteDialog";

const MaterialTableDemo = ({configurations, limit, setLimit, skip, setSkip, updateList}) =>  {

    const size = useWindowSize();

    const myContext = useContext(RoutingContext);

    function onEditClick(event, rowData) {
        myContext.setRoute('EditConfiguration', rowData);
    }

    let columns = [
        { title: 'Name', field: 'name'},
        { title: 'Version', field: 'version'},
    ]

    if(size.width>300){
        columns = [
            { title: 'Name', field: 'name'},
            { title: 'Version', field: 'version'},
            { title: 'Last Update', field: 'last_update', render: rowData => <div>{rowData.last_update
                    .replace("T", " ")
                    .slice(0,19)}</div>},
        ]
    }

    const onRowClick = (event, rowData) => {
        myContext.setRoute('ConfigurationDetail', rowData);
    }

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const [open, setOpen] = useState(false);

    const [id, setConfigurationId] = useState("")

    return (
        <div style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                data={configurations.data}
                onRowClick={onRowClick}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit Configuration',
                        onClick: (event, rowData) => onEditClick(event, rowData)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Configuration',
                        onClick: (event, rowData) => {
                            setConfigurationId(rowData._id)
                            setOpen(true)
                        },
                    }
                ]}
                totalCount={configurations.count}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={skip}
            />
            <ConfigurationDeleteDialog configurationId={id} open={open} onClose={() => {setOpen(false)}} afterDelete={updateList}/>
        </div>
    );
}

export default MaterialTableDemo
