import React, {useContext} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {ThemeContext} from "../../contexts";
import Box from "@material-ui/core/Box";

const SummaryDataComponent = ({data, title}) => {

    //console.log('RICEVO DATA ---> ', data)
    const {primaryColor} = useContext(ThemeContext)
    const useStyles = makeStyles(() => ({
        paper: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        number: {
            fontSize: '1.9rem',
            textAlign: 'left',
            margin: '0px',
            cursor: 'default',
        },
        title: {
            marginTop: '0px',
        }
    }));
    const classes = useStyles()

    return(
        <Box className={classes.paper} pl={1}>
            <Grid container>
                <Grid item xs={12}>
                    <p className={classes.number}
                        style={{color:primaryColor}}>{data[1]}</p>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.title}>{title}</p>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SummaryDataComponent
