import React, {useContext, useEffect, useMemo, useState} from "react";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import _ from 'lodash'
import {Box, Collapse} from "@material-ui/core";
import FeatureValue from "../../../components/new_product/FeatureValue";
import Grid from "@material-ui/core/Grid";
import {Controller, useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FormHelperText from "@material-ui/core/FormHelperText";
import {inputTypes} from "../../../utils/CbvUtils";
import Alert from "@material-ui/lab/Alert";
import i18next from "../../../i18n/i18next";
import {severityTypes} from "../../../utils/Utils";
//import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import Select from "@material-ui/core/Select";
//import MenuItem from "@material-ui/core/MenuItem";

const IlmdComponent = ({formDataFieldName, formDataFieldValue, handleChange, setStepValidation}) => {
    const {accentColor, iconHoverBg} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        icon: {
            color: 'darkgray',
            '&:hover': {
                background: iconHoverBg,
            },
        },
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        dottedDiv: {
            border: '2px solid lightGray',
            borderRadius: '15px',
            borderStyle: 'dotted',
            marginBottom: '3%'
        }

    }));
    const classes = useStyles()
    const eventContext = useContext(CreateEventContext)

    const [openAlertMessage, setOpenAlertMessage] = useState({
        open: false,
        message: '',
        severity: severityTypes.WARNING
    });
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const {handleSubmit, register, reset, control, watch} = useForm({
        defaultValues: {
            ns: 'iChain',
            required: false
        }
    });
    const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

    const [ilmd, setIlmd] = useState(formDataFieldValue ? formDataFieldValue : []);

    useEffect(() => {
        setStepValidation(!error)
    }, [watchAllFields])

    const error = useMemo(() => {
        if (watchAllFields.name) {
            const duplicateName = _.some(ilmd, function (o) {
                return _.toLower(o.name) === _.toLower(watchAllFields.name)
            })

            if (duplicateName)
                return duplicateName
            else {
                const new_ilmd = [...ilmd, {
                    //ns: data.ns,
                    ns: 'iChain',
                    name: _.trim(watchAllFields.name),
                    value: null,
                    optional: !watchAllFields.required,
                    input: inputTypes.EXTERNAL.value,
                    default: watchAllFields.default
                }];
                eventContext.ilmd = new_ilmd
                return false
            }
        }
    }, [watchAllFields]);


    const onAddClick = (data) => {
        const newName = _.trim(data.name)
        if (!_.some(ilmd, function (o) {
            return _.toLower(o.name) === _.toLower(newName)
        })) {
            const new_ilmd = [...ilmd, {
                //ns: data.ns,
                ns: 'iChain',
                name: newName,
                value: null,
                optional: !data.required,
                input: inputTypes.EXTERNAL.value,
                default: data.default
            }];
            setIlmd(new_ilmd)
            handleChange(formDataFieldName, new_ilmd)
            reset()
            setOpenAlertMessage({
                open: false,
                message: '',
                severity: severityTypes.WARNING
            })
        } else {
            setOpenAlertMessage({
                open: true,
                message: i18next.t('cbvValues.ilmd.duplicateName', 'Duplicate Name!'),
                severity: severityTypes.WARNING
            })
        }
    }

    const onClearClick = (ilmd_remove) => {
        let new_ilmd = ilmd.filter(val => val.name !== ilmd_remove.name)
        setIlmd(new_ilmd)
        handleChange(formDataFieldName, new_ilmd)
    }

    const setIlmdData = (row) => {
        const newObj = {required: !row.optional, ...row}
        return newObj
    }

    return (
        <Box className={classes.dottedDiv} p={2} borderRadius={2}>
            <Collapse in={!!openAlertMessage.open}>
                <Alert onClose={handleCloseErrorMessage}
                       severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
            </Collapse>
            <div>
                {
                    ilmd.map((row, index) => <FeatureValue key={index} remove={onClearClick}
                                                           feature={setIlmdData(row)}/>)
                }
            </div>
            <Grid style={{marginTop: '16px'}} container direction="row" justify="center" alignItems="flex-start">
                <Grid item xs={12} md={4} style={{marginRight: '16px', marginBottom: '8px'}}>
                    <TextField fullWidth variant={'outlined'} size={'small'}
                               label={_.startCase(i18next.t('entities.name'))}
                               name="name"
                               inputRef={register}
                               error={error}
                    />
                    {error && <FormHelperText error={error}>{i18next.t('cbvValues.ilmd.duplicateName')}</FormHelperText>}
                </Grid>
                <Grid item xs={12} md={5} style={{marginRight: '16px'}}>
                    <TextField fullWidth variant={'outlined'} size={'small'}
                               label={i18next.t('cbvValues.ilmd.defaultValue')}
                               name="default"
                               disabled={!watch('name') || error}
                               inputRef={register}/>
                    <FormHelperText>{i18next.t('cbvValues.ilmd.defaultValueOptional', 'The default value is optional')}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={2} style={{justifyContent: 'flex-end', display: 'flex'}}>
                    <Controller
                        as={
                            <FormControlLabel control={<Checkbox color='default'/>}
                                              label={i18next.t('entities.required')}
                                              labelPlacement="start"/>
                        }
                        name="required"
                        control={control}
                        type="checkbox"
                    />
                </Grid>
                <Grid item xs={12} md={1}>
                    <IconButton style={{color: (!watch('name') || error) ? 'lightgray' : accentColor}}
                                aria-label="add"
                                onClick={handleSubmit(onAddClick)}
                                disabled={!watch('name') || error}>
                        <AddCircleIcon/>
                    </IconButton>
                    {/*<Box>
                            <Controller
                                as={
                                    <NameSpace/>
                                }
                                name="ns"
                                control={control}
                            />
                        </Box>*/}
                </Grid>
            </Grid>
        </Box>
    )
}

/**
 * const available_name_space = ['iChain'];
 * const NameSpace = ({value, onChange}) => {
    return (
        <FormControl fullWidth>
            <InputLabel>{i18next.t('cbvValues.ilmd.namespace')}</InputLabel>
            <Select value={value || ''} onChange={onChange}>
                {
                    available_name_space.map(
                        (ns) => (
                            <MenuItem value={ns} key={ns}>{ns}</MenuItem>)
                    )
                }
            </Select>
        </FormControl>
    )
};
 */

export default IlmdComponent
