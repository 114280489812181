import React, {useState} from 'react';
import MaterialTable from 'material-table';
import ApkDeleteDialog from "../ApkDeleteDialog";

const MaterialTableDemo = ({apks, limit, setLimit, skip, setSkip, updateList}) =>  {

    let columns = [
            { title: 'Filename', field: 'filename'},
            { title: 'Upload Date', field: 'uploadDate', render: rowData => <div>{rowData.uploadDate
                    .replace("T", " ")
                    .slice(0,19)}</div>},
        ]

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    const [open, setOpen] = useState(false);

    const [id, setApkId] = useState("")

    return (
        <div style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                data={apks.data}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Delete Apk',
                        onClick: (event, rowData) => {
                            setApkId(rowData._id)
                            setOpen(true)
                        },
                    }
                ]}
                totalCount={apks.count}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={skip}
            />
            <ApkDeleteDialog apkId={id} open={open} onClose={() => {setOpen(false)}} updateList={updateList}/>
        </div>
    );
}

export default MaterialTableDemo
