import React, {useContext} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Summarylabel from "../../components/SummaryLabel";
import Divider from '@material-ui/core/Divider';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Header from "../../components/Header";
import {makeStyles} from '@material-ui/core/styles';
import {findCodeTypeAndValue} from "../../utils/CodesUtils";
import {ThemeContext} from "../../contexts";
import TagController from "../../components/tag_management/TagController";
import {useTranslation} from "react-i18next";

const ProductDetail = (props) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiPaper-rounded': {
                borderRadius: '8px'
            },
        },
        preview: {
            //width: "170px",
            height: "170px",
            objectFit: "cover",
            borderRadius: '8px'
        },
        box: {
            backgroundColor: "#f6f6f6",
        },
        typography: {
            display: 'inline-block',
            color: 'white',
            backgroundColor: accentColor,
            padding: '0.5%',
            paddingRight: '1.5%',
            paddingLeft: '1.5%',
            borderRadius: '20px',
            marginRight: '2%',
            marginTop: '2%'
        },
        prefix: {
            backgroundColor: 'darkgrey',
            color: 'whitesmoke',
            padding: '2.5%',
            borderRadius: '18px',
            marginRight: '2%',
            marginTop: '2%'
        },
        button: {
            textTransform: "none",
        },
        buttonComplete: {
            textTransform: "none",
            color: "whitesmoke",
            backgroundColor: "rgba(0, 0, 0, 0.87)",
            marginLeft: "2%"
        }
    }));
    const classes = useStyles();

    const _src = "/assets/product.png";
    const data = props.location.state;

    return (
        <Box className={classes.root}>
            <Paper>
                <Header title='' edit={true} data={data} product={true} other={false}/>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Box p={4}>
                            <Grid item container xs={12} direction="row" justify="center"
                                  alignItems="center">

                                {
                                    <img alt="my_product_img"
                                         src={data.image ? "api/uploads/uploads/" + data.image : _src}
                                         className={classes.preview}/>
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box p={4} pt={1}>
                            <Box pl={2} pr={2}>
                                <Box pb={2}>
                                    <Typography variant="h6">{data.name.toUpperCase()}</Typography>
                                </Box>
                                <Summarylabel title={t('entities.product.name')} value={data.name}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.product.code')+': ' + findCodeTypeAndValue(data.key)[0].label}
                                              value={data.key}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.description')} value={data.description}/>
                                <Divider light variant="middle"/>
                            </Box>
                            <Box p={2}>
                                <FormControlLabel
                                    disabled
                                    control={<Checkbox defaultChecked={data.active}
                                                       color='default'/>}
                                    label={t('entities.product.activeProduct')}
                                    labelPlacement="end"/>
                            </Box>
                            <Box className={classes.box} p={2} borderRadius={6}>
                                <Typography>{t('entities.product.otherProductCodes')}</Typography>
                                {
                                    data.codes !== undefined ? (
                                        data.codes.length > 0 ?
                                            data.codes.map(
                                                (code, index) => {
                                                    const [type, value] = findCodeTypeAndValue(code.value)
                                                    return (
                                                        <Box key={index}>
                                                            <Summarylabel title={type.label} value={value}/>
                                                            <Divider variant="inset" light/>
                                                        </Box>
                                                    )
                                                }
                                            ) :
                                            <Box pt={1}>
                                                {t('entities.product.noOtherProductCodesAvailable')}
                                            </Box>
                                    ) : null
                                }
                            </Box>
                            <Box p={2}>
                                <Typography style={{marginBottom: '2%'}}>Tags</Typography>
                                {
                                    data ? <TagController selected={false} product={data} value={data.key}
                                                          editable={true}/> : null
                                }
                            </Box>
                            <Divider light variant="middle"/>
                            <Box p={2}>
                                <Typography>{t('entities.product.customFeatures')}</Typography>
                                {
                                    data.custom_features !== undefined ? (
                                        data.custom_features.map(
                                            (feature) => (
                                                <Box pt={3} key={feature.name}>
                                                    <Grid container direction="row" justify="flex-start">
                                                        <Grid item xs={9} container
                                                              direction="row"
                                                              justify="flex-start"
                                                              alignItems="center">
                                                            <Box width={1}>
                                                                <Typography>{/*<iclassName={classes.prefix}>{feature.ns}</i>*/}
                                                                    <strong>{feature.name}:</strong> {feature.value}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3} container
                                                              direction="row"
                                                              justify="flex-end"
                                                              alignItems="center">
                                                            {feature.required ? <Typography>{t('entities.required')}</Typography>
                                                                : <Typography>{t('entities.notRequired')}</Typography>}
                                                        </Grid>
                                                    </Grid>
                                                    <Box pt={2}>
                                                        <Divider variant="inset" light/>
                                                    </Box>
                                                </Box>
                                            )
                                        )
                                    ) : null
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default ProductDetail
