import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Header from "../../components/Header";
import Grid from "@material-ui/core/Grid";
import {Box, Button, Typography} from "@material-ui/core";
import Summarylabel from "../../components/SummaryLabel";
import Divider from "@material-ui/core/Divider";
import CustomMap from "../../components/CustomMap";
import _ from 'lodash'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    preview: {
        width: "160px",
        height: "160px",
        objectFit: "cover"
    },
    box: {
        backgroundColor: "#f6f6f6",
    },
    typography: {
        display: 'inline-block',
        backgroundColor: '#f6f6f6',
        padding: '1.5%',
        borderRadius: '18px',
        marginRight: '2%',
        marginTop: '2%'
    },
    prefix: {
        backgroundColor: 'darkgrey',
        color: 'whitesmoke',
        padding: '2.5%',
        borderRadius: '18px',
        marginRight: '2%',
        marginTop: '2%'
    },
    button: {
        textTransform: "none",
    },
    buttonComplete: {
        textTransform: "none",
        color: "whitesmoke",
        backgroundColor: "rgba(0, 0, 0, 0.87)",
        marginLeft: "2%"
    }
}));

const SummaryPlacePage = ({data, onGoBack, onCompleteClick, error}) => {
    const {t} = useTranslation()

    const classes = useStyles();

    const places = [
        {
            gln: data.gln,
            address: data.checked ? data.address_2 : data.address_1,
            latitude: !data.checked ? data.latitude_1 ? data.latitude_1 : null :
                data.latitude_2 ? data.latitude_2 : null ,
            longitude: !data.checked ? data.longitude_1 ? data.longitude_1 : null :
                data.longitude_2 ? data.longitude_2 : null ,
        }
    ]

    return (
        <Box>
            <Paper>
                <Header title={t('entities.summary')}/>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box p={4}>
                            <Box pl={2} pr={2}>
                                <Summarylabel title={_.startCase(t('entities.name'))} value={data.name}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.location.country')} value={data.country}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.location.city')} value={data.city}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={t('entities.location.code')} value={!data.generateCodeCheck ? data.gln : t('entities.autoGenCode')}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={_.startCase(t('entities.location.address'))} value={data.checked ? data.address_2 : data.address_1}/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={_.startCase(t('entities.location.latitude'))} value={
                                    !data.checked ? data.latitude_1 ? data.latitude_1 : null :
                                        data.latitude_2 ? data.latitude_2 : null
                                }/>
                                <Divider light variant="middle"/>
                                <Summarylabel title={_.startCase(t('entities.location.longitude'))} value={
                                    !data.checked ? data.longitude_1 ? data.longitude_1 : null :
                                        data.longitude_2 ? data.longitude_2 : null
                                }/>
                                {
                                    data.locations !== undefined ? (
                                        data.locations.length > 0 ? (
                                            <Box className={classes.box} p={2} borderRadius={2}>
                                                <Typography>{t('entities.subLocation.label_plural')}</Typography>
                                                {
                                                    data.locations.map(
                                                        (location) => (
                                                            <Box key={location.name}>
                                                                <Summarylabel title={location.name}
                                                                              value={location.description}/>
                                                                <Divider variant="inset" light/>
                                                            </Box>
                                                        )
                                                    )
                                                }
                                            </Box>
                                        ) : null
                                    ) : null
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box p={4} style={{height: '500px', width: '100%'}}>
                            {
                                !places[0].latitude && !places[0].longitude ?
                                    <CustomMap/> : <CustomMap places={places}/>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box ml={4} pb={3}>
                    <Button color="default" variant="contained" onClick={onGoBack}
                            className={classes.button}>{t('actions.back')}</Button>
                    <Button variant="contained" className={classes.buttonComplete}
                            onClick={onCompleteClick}>{t('actions.finish')}</Button>
                </Box>
            </Paper>
            {
                error ? <p style={{color: 'red'}}>{error ? error : t('errors.genericServerError')}</p> : null
            }
        </Box>
    )
}

export default SummaryPlacePage