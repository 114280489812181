import 'date-fns';
import React, {useContext, useEffect, useState} from "react";
import {CreateEventContext} from "../../../contexts";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import _ from "lodash";
import Box from "@material-ui/core/Box";
import Moment from "moment";
import {eventFieldNameTypes, inputTypes} from "../../../utils/CbvUtils";
import FieldInfo from "../FieldInfo";
import {Divider} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const TimeComponent = ({field, state, setState}) => {
    const {t} = useTranslation()
    const eventContext = useContext(CreateEventContext)

    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const validateDate = () => {
        if(field.input === inputTypes.CLIENT_COMPUTED.value)
            return true

        const getField = eventContext.getFormField('when', field.name) ?
            eventContext.getFormField('when', field.name).value !== null : false

        if(field.input === inputTypes.EXTERNAL.value)
            return !field.optional ?
                getField
                && (Moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss") <= Moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))
                        && selectedDate !== t('entities.date.invalidDate', 'Invalid Date')
                : true
    }

    useEffect(() => {
        eventContext.setFormField('when', field.name, Moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss"))
        eventContext.setValidationField(
            'when',
            field.name,
            !field.optional ? validateDate() : true)
        //setState({...state, [field.name]: selectedDate})
    }, [selectedDate])


    /** DATETIME FORMAT: YYYY-MM-DD HH:MM:SS.microseconds */
    return (<div>
            {
                field.input === inputTypes.EXTERNAL.value ?
                    <div>
                        <Box pb={2} style={{display: 'flex', alignItems: 'baseline'}}>
                            <FieldInfo name={_.find(eventFieldNameTypes, ['name', field.name]).label}
                                       description={_.find(eventFieldNameTypes, ['name', field.name]).description}
                                       optional={field.optional}
                                       external={field.input === inputTypes.EXTERNAL.value}/>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    autoOk
                                    ampm={false}
                                    disableFuture
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    label="yyyy/MM/dd HH:mm"
                                    format="yyyy/MM/dd HH:mm"
                                    showTodayButton
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Divider/>
                    </div>
                : null
            }
        </div>
    )
}

export default TimeComponent