import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Avatar from "@material-ui/core/Avatar";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {severityTypes} from "../../../utils/Utils";

const _src = "/assets/company.png";

const CompaniesSelectComponent = ({companies, disabled, state, setState}) => {
    const {t} = useTranslation()
    const _theme = useContext(ThemeContext)
    const eventContext = useContext(CreateEventContext)
    const useStyles = makeStyles((theme) => ({
        name: {
            fontSize: '1rem',
        },
        code: {
            fontSize: '0.725rem'
        },
        subLabel: {
            fontSize: '0.825rem'
        },
        codeTypeChip: {
            marginRight: '1%',
            color: _theme.accentColor,
            border: '1px solid ' + _theme.accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
        avatar: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            marginRight: '2%',
        }
    }));
    const classes = useStyles()

    const initialAlertMessageState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialAlertMessageState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const options = useMemo(() => {
        if(!companies)
            return null

        let _brands = []

        _.forEach(companies, (company) => {
            const brand = _.map(company.brands, (brand) => ({...brand, mainCompanyName: company.brands[0].name}))
            _brands = _.union(_brands, brand)
        })

        return _brands
    }, [companies])

    useEffect(() => {
        if(options && state.onlyMine) {
            setState({...state, brand: _.find(options, ['domain',eventContext.activeMainDomain])})
        }
    }, [state.onlyMine])

    return (
        <Box pr={1} mt={2} flex={1}>
            {
                options ?
                    <Autocomplete
                        //fullWidth
                        size={'small'}
                        clearOnEscape
                        disabled={disabled}
                        filterSelectedOptions
                        options={options}
                        value={state.brand}
                        groupBy={(option) => option.mainCompanyName}
                        getOptionLabel={(option) => option.name ? option.name : ''}
                        onChange={(event, newValue) => {
                            eventContext.setFormField('where', 'bizLocation', null)
                            eventContext.setFormField('where', 'readPoint', null)

                            setState({...state, brand:newValue, bizLocation: null, readPoint: null})
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       size={'small'}
                                       label={t('createEvent.filterByCompany')}/>}
                        renderOption={(option, {selected}) => {
                            return (<React.Fragment>
                                    <Avatar
                                        className={classes.avatar}
                                        //alt="company_logo"
                                        src={option.image ? "api/uploads/uploads/" + option.image : _src}>
                                    </Avatar>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes.name}>{option.name}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Chip
                                                size={'small'}
                                                label={option.domain}
                                                className={classes.codeTypeChip}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )
                        }}
                    /> : null
            }
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                      onClose={handleCloseErrorMessage}>
                <Alert onClose={handleCloseErrorMessage}
                       severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
            </Snackbar>
        </Box>
    )
}

export default CompaniesSelectComponent