import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import React, {useContext} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {setClassCodeType} from "./product_history_reducer";
import {ProductHistoryContext} from "../../contexts";
import {serialTypes} from "../../utils/CodesUtils";
import {useTranslation} from "react-i18next";
import _ from 'lodash'

const SerialType = () => {
    const { t } = useTranslation();

    const [state, dispatch] = useContext(ProductHistoryContext)
    const {serialType} = state

    const handleChangeType = (event) => {
        dispatch(setClassCodeType(event.target.value))
    }

    return (
        <FormControl
            variant="outlined"
            size={'small'}
            style={{flex: 1, marginRight: "3%"}}
            error={!serialType}>
            <InputLabel>{_.startCase(t('entities.type'))}</InputLabel>
            <Select
                value={serialType || ""}
                onChange={handleChangeType}
                label={_.startCase(t('entities.type'))}>
                {
                    Object.values(serialTypes).map((type, index) => (
                            <MenuItem value={type}
                                      key={index}>{type.label}</MenuItem>))
                }
            </Select>
            <FormHelperText>{t('entities.product.selectClassCodeType')}</FormHelperText>
        </FormControl>
    );
}

export default SerialType