import React, {useEffect, useState} from "react";
import {ResponsiveLine} from '@nivo/line'
import Moment from "moment";
import _ from "underscore";
import {useTranslation} from "react-i18next";
import i18next from "../../i18n/i18next";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

/**
 *  {
            "id": "#items",
            "color": "hsl(204,70%,50%)",
            "data": [
                {
                    "x": "plane",
                    "y": 65
                },
            ]
        },
 */

const CustomChartWhen = ({when /*data  see data tab */}) => {
    const t0 = performance.now()

    const {t} = useTranslation();

    const prepareData = (events) => {
        let whenArray = []
        let chartData = [
            {
                "id": "#" + t('entities.item_plural'),
                "color": "hsl(204,70%,50%)",
                "data": []
            },
            {
                "id": "#" + t('entities.event_plural'),
                "color": "hsl(339,70%,50%)",
                "data": []
            }
        ]

        for (let date in events) {
            events[date].date = date
            whenArray.push(events[date])
        }

        let whenArraySorted = _.sortBy(whenArray, 'date')

        //console.log('when array sorted -->', whenArraySorted)

        let diff_In_Days = 0
        let legendX

        if (whenArraySorted.length > 0) {
            let dateStart = new Date(Moment(whenArraySorted[0].date).format('M/D/YYYY'))
            let dateEnd = new Date(Moment(whenArraySorted[whenArraySorted.length - 1].date).format('M/D/YYYY'))
            let diff_In_Time = dateEnd.getTime() - dateStart.getTime();
            diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
        }

        //console.log('giorni di differenza ---->', diff_In_Days)

        function populateChartData(groups) {
            Object.keys(groups).forEach((e) => {
                let nItems = 0
                let nEvents = 0
                groups[e].forEach((date) => {
                    nItems += date.n_items
                    nEvents += date.n_events
                })
                chartData.forEach((serie) => {
                    switch (serie.id) {
                        case "#" + t('entities.item_plural'):
                            serie.data.push({x: e, y: nItems})
                            break
                        case "#" + t('entities.event_plural'):
                            serie.data.push({x: e, y: nEvents})
                            break
                        default:
                            break
                    }
                })
            })
        }

        if (diff_In_Days < 32) {
            whenArraySorted.forEach((e) => {
                //const date = Moment(e.date).format('MMM Do \'YY, hh')
                const date = i18next.t("formatters:date", {date: Moment(e.date)})
                const nItems = e.n_items
                const nEvents = e.n_events
                chartData.forEach((serie) => {
                    switch (serie.id) {
                        case "#" + t('entities.item_plural'):
                            serie.data.push({x: date, y: nItems})
                            break
                        case "#" + t('entities.event_plural'):
                            serie.data.push({x: date, y: nEvents})
                            break
                        default:
                            break
                    }
                })
                legendX = t('incomingEvents.when.dateTime', 'Date')
            })
        }

        if (diff_In_Days >= 32 && diff_In_Days <= 365) {
            const groups = whenArraySorted.reduce((acc, event) => {
                const yearWeek = `${Moment(event.date).year()}, ${Moment(event.date).week()}`;
                if (!acc[yearWeek]) {
                    acc[yearWeek] = [];
                }
                acc[yearWeek].push(event);
                return acc;
            }, {});
            legendX = t('incomingEvents.when.yearWeek', 'Year, Week')
            populateChartData(groups)
        }

        if (diff_In_Days > 365) {
            const groups = whenArraySorted.reduce((acc, event) => {
                const yearWeek = `${Moment(event.date).year()}, ${Moment(event.date).format('MMM')}`;
                if (!acc[yearWeek]) {
                    acc[yearWeek] = [];
                }
                acc[yearWeek].push(event);
                return acc;
            }, {});
            legendX = t('incomingEvents.when.yearMonth', 'Year, Month')
            populateChartData(groups)
        }

        //console.log("CUSTOM HIST --> chartData", chartData)
        let objData = {
            chartData: chartData,
            diff_In_Days: diff_In_Days,
            legendX: legendX
        }

        return objData
    }

    const [dataToDisplay, setDataToDisplay] = useState([])
    //const [legendX, setLegendX] = useState('')

    useEffect(() => {
        const data = prepareData(when)
        setDataToDisplay(data)
        //setLegendX(
        //   data.diff_In_Days < 32 ? 'Date, Time' :
        //       data.diff_In_Days > 365 ? 'Year, Month' : 'Year, Week'
        //)
    }, [when])

    console.log('data to display', dataToDisplay)

    const t1 = performance.now()
    t1-t0 > 0 && console.log("-------------------------------------------- TIME CustomChartWhen --->", t1-t0)

    return (
        <ResponsiveLine
            data={dataToDisplay.chartData}
            lineWidth={3}
            curve={'cardinal'}
            layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', /*'points',*/ 'slices', 'mesh', 'legends']}
            margin={{top: 50, right: 110, bottom: 90, left: 60}}
            xScale={{type: 'point'}}
            yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 15,
                tickPadding: 10,
                tickRotation: 25,
                legend: dataToDisplay.legendX,
                legendOffset: dataToDisplay.legendX === 'Date, Time' ? 80 : 60,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: t('incomingEvents.when.count'),
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            colors={{scheme: 'nivo'}}
            pointSize={14}
            pointColor={'white'}
            pointBorderWidth={3}
            pointBorderColor={{from: 'serieColor'}}
            pointLabel="y"
            pointLabelYOffset={-12}
            enableGridX={false}
            useMesh={true}
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 10,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 40,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default CustomChartWhen
