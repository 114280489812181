import React, {useContext, useEffect, useState} from "react";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import {Box, Grid} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import _ from 'lodash'
import {inputTypes, splitCbvValues} from "../../../utils/CbvUtils";
import FieldInfo from "../../generate_new_event/FieldInfo";

const SelectInputTypeComponent = ({options, formDataFieldName, formDataFieldValue, handleChange, formLabel, description, disabled, sourceListTypes, destinationListTypes}) => {
    const eventContext = useContext(CreateEventContext);

    const [value, setValue] = useState(formDataFieldValue ? formDataFieldValue : options[0]);
    const [helperText, setHelperText] = useState();

    useEffect(() => {
        handleChange(formDataFieldName, value)
        setHelperText(_.find(inputTypes, ['value', value]).description)
    }, [value])

    const handleSelectChange = (event) => {
        setValue(event.target.value)
        //handleChange(formDataFieldName, event.target.value)
        //setHelperText(inputTypesDescription[event.target.value])
    };

    const AutoCompleteSelectInputComponent = ({ options, multiple, formDataFieldName, sourceListTypes, handleChange, formLabel}) => {
        const [value, setValue] = useState(sourceListTypes ? sourceListTypes : []);
        //const [helperText, setHelperText] = useState();
        const {primaryColorAlpha} = useContext(ThemeContext)

        //console.log('LOG ---> ', formDataFieldValue)

        return (
            <Box p={2}>
                <Autocomplete
                    multiple={multiple}
                    clearOnEscape={true}
                    options={options}
                    getOptionLabel={(option) => option}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue)
                        handleChange(formDataFieldName, newValue)
                    }}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={splitCbvValues(option)}
                                style={{backgroundColor: primaryColorAlpha, opacity: '0.8'}}
                                {...getTagProps({index})}
                            />
                        ))
                    }
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>{splitCbvValues(option)}</Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                    renderInput={params => {
                        const {InputProps, ...restParams} = params;
                        const {startAdornment, ...restInputProps} = InputProps;
                        return (
                            <TextField
                                {...restParams}
                                variant={"outlined"}
                                //label={formLabel}
                                placeholder={formLabel}
                                InputProps={{
                                    ...restInputProps,
                                    startAdornment: (
                                        <div style={{
                                            maxHeight: '150px',
                                            overflowY: 'auto',
                                        }}
                                        >
                                            {startAdornment}
                                        </div>
                                    ),
                                }}
                            />
                        );
                    }}
                />
            </Box>
        )
    }

    return (
        <Box p={2} pt={1} style={{flex:'1', marginRight:'2%', marginTop:'2%'}}>
            {/*<FormLabel component="legend">{formLabel}</FormLabel>*/}
            <FieldInfo name={formLabel} description={description}/>
            <Select value={value} disabled={disabled}
                    onChange={handleSelectChange}
                    displayEmpty>
                {
                    options.map(
                        (option) => (
                            <MenuItem value={option} key={option}>{option}</MenuItem>)
                    )
                }
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
            {
                formDataFieldName === 'sourceListInputType' && formDataFieldValue === inputTypes.PARTIALLY_FIXED.value ?
                    <AutoCompleteSelectInputComponent
                        options={eventContext.cbvValues.source_destination_types}
                        multiple={true}
                        formLabel={formLabel}
                        formDataFieldName={'sourceListTypes'}
                        sourceListTypes={sourceListTypes}
                        handleChange={handleChange}
                    />
                : null
            }
            {
                formDataFieldName === 'destinationListInputType' && formDataFieldValue === inputTypes.PARTIALLY_FIXED.value ?
                    <AutoCompleteSelectInputComponent
                        options={eventContext.cbvValues.source_destination_types}
                        multiple={true}
                        formLabel={formLabel}
                        formDataFieldName={'destinationListTypes'}
                        sourceListTypes={destinationListTypes}
                        handleChange={handleChange}
                    />
                : null
            }
        </Box>
    )
}

export default SelectInputTypeComponent
