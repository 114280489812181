import React, {useContext, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {Box, Typography} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import chroma from 'chroma-js'
import Badge from "@material-ui/core/Badge";
import {AuthContext, ThemeContext} from "../../contexts";
import Summarylabel from "../../components/SummaryLabel";
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    Language,
    LockOpen,
} from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Button from "@material-ui/core/Button";
import ChangePasswordForm from "./ChangePasswordForm";
import Settings from "../Settings";
import LanguageSelect from "./LanguageSelect";
import {useTranslation} from "react-i18next";
import EditProfileImage from "./EditProfileImage";
import {useDomains, useWindowSize} from "../../utils/Utils";
import {CustomTagField} from "../../components/tag_management/TagController";
import CustomLoader from "../../components/CustomLoader";
import ActiveBrandSelection from "../../appbar_components/ActiveBrandSelection";

const ProfileDetail = ({myProfile, refetchData}) => {
    console.log("myProfile:",myProfile)
    const { t } = useTranslation();
    const size = useWindowSize();

    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiPaper-rounded': {
                borderRadius: '8px'
            },
            '& .MuiAccordion-root:before': {
                display: 'none'
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: _theme.accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: _theme.accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: _theme.accentColor
            },
        },
        largePreview: {
            flex: 1,
            width: theme.spacing(16),
            height: theme.spacing(16),
            '& .MuiAvatar-img': {objectFit: "contain",},
            backgroundColor: chroma(_theme.primaryColor).alpha(0.2).hex(),
            boxShadow: theme.shadows[3]
            //cursor: 'pointer'
        },
        expansion: {
            width: '100%',
            backgroundColor: _theme.backgroundColor,
            marginBottom: '2%'
        },
        summary: {
            display: size.width > 650 ? 'flex' : 'grid',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
        infoDiv: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelChip: {
            border: '1px solid ' + _theme.accentColor,
            color: _theme.accentColor,
            fontWeight: 'bold',
        },
        proPicBox: {
            flex:1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            //margin: 'auto',
        },
        brandTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }));
    const classes = useStyles();

    const authContext = useContext(AuthContext)
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const domains = useDomains(authContext.isAdmin, authContext.activeDomains)

    const onTagIconClick = () => {
        setNumberToShow(numberToShow === -1 ? 10 : -1)
    }

    let [numberToShow, setNumberToShow] = useState(10);
    return (
        <Box pb={2} className={classes.root}>
            <Box pt={3} pb={2} className={classes.summary}>
                <Box className={classes.proPicBox}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={<EditProfileImage myProfile={myProfile} theme={_theme} refetchData={refetchData}/>}>
                        <Avatar
                            alt={"my_profile_pic"}
                            src={myProfile.image ? "api/uploads/uploads/" + myProfile.image : null}
                            className={classes.largePreview}/>
                        {/*<img alt="my_company_img"
                              src={myProfile.image ? "api/uploads/uploads/" + myProfile.image : _src}
                              className={classes.preview}/>*/}
                    </Badge>
                    <Typography variant="h6"
                                align="center"
                                style={{marginTop:'8px'}}>
                        {(myProfile.name || myProfile.surname) ?
                            ((myProfile.name || '').toUpperCase() +' '+ (myProfile.surname || '').toUpperCase())
                        : myProfile.username}
                    </Typography>
                </Box>
                <Box ml={1} flex={4}>
                <Paper className={classes.infoDiv}>
                    <Box p={2} pl={4} pr={4}>
                        {(myProfile.name || myProfile.surname) && <div><Summarylabel title={t('profile.fullName')}
                                       value={((myProfile.name || '') +' '+ (myProfile.surname || ''))}/>
                        <Divider/></div>}
                        <Summarylabel icon={<AccountCircleIcon style={{color: _theme.accentColor}}/>} title={t('profile.username')}
                                      value={myProfile.username}/>
                        <Summarylabel icon={<AlternateEmailIcon style={{color: _theme.accentColor}}/>} title={t('profile.email')}
                                      value={myProfile.email}/>
                        <Summarylabel icon={<LockOpen style={{color: _theme.accentColor}}/>} title={t('profile.password')}
                                      value={<Button onClick={() => setOpenChangePassword(!openChangePassword)}
                                                     variant={'outlined'}
                                                     endIcon={openChangePassword ?
                                                         <KeyboardArrowUp/> : <KeyboardArrowDown/>}>
                                          {t('profile.changePasswordForm.changePassword')}
                                      </Button>}/>
                        <ChangePasswordForm myProfileId={myProfile._id} openChangePassword={openChangePassword} setOpenChangePassword={setOpenChangePassword}/>
                        <Divider/>
                        <Summarylabel icon={<Language style={{color: _theme.accentColor}}/>} title={t('profile.language')}
                                      value={<LanguageSelect/>}/>
                        <Divider/>
                        <Summarylabel icon={<LocalOfferIcon style={{color: _theme.accentColor}}/>} title={t('profile.activeDomain')}
                                      value={<ActiveBrandSelection toolbar={false} auth={authContext}/>}/>
                        <Summarylabel icon={<MoreHorizIcon style={{color: _theme.accentColor}}/>}
                                       title={t('profile.allDomains')}
                                        //value={myProfile.active_domains.join(', ')}/>
                                       value={(domains.data && <CustomTagField numberToShow={numberToShow}
                                                           tags={domains.data.flatMap(domain => domain.domain || domain)} // se non è admin ha lista di str, se è admin riceve lista di obj
                                                           onTagIconClick={onTagIconClick}/>)
                                       || (domains.loading && <CustomLoader/>)}/>
                    </Box>
                </Paper>
                <Settings theme={authContext.theme} setTheme={authContext.setTheme}/>
                </Box>
            </Box>
        </Box>
    )
}

export default ProfileDetail
