import React, {useEffect, useMemo, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import {AuthContext, StateProvider, ThemeContext} from "./contexts";
import ResponsiveDrawer from "./appbar_components/ResponsiveDrawer";
import {BrowserRouter, Redirect, Route} from "react-router-dom";
import data from './mock_server/user_themes.json'
import MyProducts from "./pages/products/MyProducts";
import OtherProducts from "./pages/products/OtherProducts";
import Places from "./pages/places/Places";
import AddPlace from "./pages/places/AddPlace"
import Settings from "./pages/Settings";
import AddProduct from "./pages/products/AddProduct";
import TestPage from './pages/TestPage'
import Dashboard from "./pages/incoming_events/Dashboard";
import MyCompany from "./pages/MyCompany";
import Login from "./pages/login/Login";
import ProductDetail from "./pages/products/ProductDetail";
import Companies from "./pages/companies/Companies";
import CompanyDetail from "./pages/companies/CompanyDetail";
import PlacesDetail from "./pages/places/PlacesDetail";
import EventModels from "./pages/event_models/EventModels";
import EventModelDetail from "./pages/event_models/EventModelDetail";
import {reducer} from "./reducer";
import axios from "axios";
import {login} from "./pages/login/auth";
import SupplyChainGraph from "./pages/relationships_graph/SupplyChainGraph";
import CreateRelationship from "./pages/create_new_relationship/CreateRelationship";
import jwt_decode from 'jwt-decode'
import ProductDetailOther from "./pages/products/ProductDetailOther";
import CreateEventTemplate from "./pages/event_models/create_event_model/CreateEventTemplate";
import MarbleDiagramPage from "./pages/production_flow_marble/MarbleDiagramPage";
import ProductHistoryV6 from "./pages/product_history_v6/ProductHistoryV6";
import LandingPage from "./pages/landing_page/LandingPage";
import Certificates from './pages/certificates/Certificates';
import CertificateDetail from './pages/certificates/CertificateDetail';
import Revocations from './pages/Revocations';
import Configurations from './pages/configurations/Configurations';
import ConfigurationDetail from './pages/configurations/ConfigurationDetail';
import AddConfiguration from './pages/configurations/AddConfiguration';
import EditConfiguration from './pages/configurations/EditConfiguration';
import Apks from './pages/Apks';
import ComposeFiles from './pages/ComposeFiles'
import AuthRequests from './pages/AuthRequests';
import Devices from './pages/devices/Devices'
import DeviceControlPanel from './pages/devices/DeviceControlPanel'
import DevicesMonitoring from './pages/DevicesMonitoring'
import CreateEvent from "./pages/generate_new_event/CreateEvent";
import MyProfile from "./pages/my_profile_settings/MyProfile";
import ForgotPasswordForm from "./pages/login/ForgotPasswordForm";
import ResetPassword from "./pages/login/ResetPassword";
import AppErrorBoundary from "./AppErrorBoundary";
import RulesConfiguratorWrapper from "./pages/rules_configurator/RulesConfigurator";
import ProjectsTable from "./pages/rules_configurator/ProjectsTable";
import AcceptPartnershipRequest from "./pages/companies/my_partners/AcceptPartnershipRequest";
import Reports from "./pages/data_analysis/Reports";
import ChemioDataset from "./pages/data_analysis/ChemioDataset";
//import HowChart from "./pages/data_analysis/HowChart.js"
import FeedChart from "./pages/data_analysis/FeedChart.js";
import PoultryChart from "./pages/data_analysis/PoultryChart.js";
import SlaughtersChart from "./pages/data_analysis/SlaughtersChart.js";
//import MapDiagram from './pages/MapDiagram';

function App() {
    console.log("localStorage.getItem('token')", localStorage.getItem('token') ? "esiste" : "no" )
    const token = localStorage.getItem('token')

    const [isAuthenticated, setAuth] = useState(!!token)
    const [theme, setTheme] = useState(data.themes[0])
    const [, setMainActiveBrand] = useState()
    const [, setActiveBrands] = useState()

    const [userName, companyName, brName, mainDomain, activeDomains, companyPrefix, name, surname, isAdmin] = useMemo(() => {
        if (token === null)
            return []
        const isAdmin = jwt_decode(token).rights && jwt_decode(token).rights.includes("admin")
        console.log("jwt_decode(token): ", jwt_decode(token))

        return [
            jwt_decode(token).sub,
            jwt_decode(token).cpname,
            jwt_decode(token).brname,
            jwt_decode(token).main_domain,
            jwt_decode(token).active_domains,
            jwt_decode(token).company_prefix,
            jwt_decode(token).name,
            jwt_decode(token).surname,
            isAdmin
        ]
    }, [token])

    useEffect(() => {
        if (userName && companyPrefix) {
            setTheme(JSON.parse(localStorage.getItem(`${userName}_${companyPrefix}_theme`)) || data.themes[0])
        }
    }, [userName, companyPrefix])

    axios.interceptors.request.use(
        config => {
            const token = localStorage.getItem('token');
            //debugger;
            if (token) {//config.headers.authorization = `Bearer ${token}`;
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            const originalRequest = error.config;

            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const refresh_token = localStorage.getItem('refresh_token');
                //console.log("refresh_token: ", refresh_token)
                if (refresh_token === null) {
                    _setAuthentication(false)
                } else {
                    let url = "/auth/refresh_token_from_header"
                    const instance = axios.create(
                        {
                            method: 'post',
                            headers: { 'Authorization': `Bearer ${refresh_token ? refresh_token : null}` },
                            //withCredentials: true,
                            //headers: {'Set-Cookie': `refresh_token="Bearer ${refresh_token ? refresh_token : null}"; HttpOnly; Path=/`},
                        }
                    );
                    return instance.post(url)
                        .then(
                            res => {
                                if (res.status === 200) {
                                    login(res.data)
                                    return axios(originalRequest);
                                }
                            })
                        .catch(
                            error => {
                                console.log("!!! error: ", error)
                                _setAuthentication(false)
                                return Promise.reject(error)
                            }
                        )
                }
            }
            return Promise.reject(error)
        }
    );

    const _setAuthentication = (auth) => {
        setAuth(auth)
    }

    const _setMainActiveBrand = (brand) => {
        setMainActiveBrand(brand)
    }

    const _setActiveBrands = (brands) => {
        setActiveBrands(brands)
    }

    const _setTheme = (theme) => {
        setTheme(theme)
    }

    const authentication = {
        auth: isAuthenticated,
        setAuthentication: _setAuthentication,
        userName: userName,
        companyName: companyName,
        activeBrandName: brName,
        activeMainDomain: mainDomain,
        setMainActiveBrand: _setMainActiveBrand,
        activeDomains: activeDomains,
        setActiveBrands: _setActiveBrands,
        companyPrefix: companyPrefix,
        name: name,
        surname: surname,
        isAdmin: isAdmin,
        theme: theme,
        setTheme: _setTheme
    }

    const PrivateRoute = ({ component: Component, isAuthenticated, ...props }) => {
        return (
            <Route
                {...props}
                render={props =>
                    isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/Login",
                                state: { referrer: props.location }
                            }}
                        />
                    )
                }
            />
        );
    }

    return (<AuthContext.Provider value={authentication}>
        <StateProvider reducer={reducer} initialState={{
            whatAggregate: {},
            whenAggregate: {},
            whereAggregate: {},
            whyAggregate: {},
        }}>
            <ThemeContext.Provider value={theme}>
                <BrowserRouter>
                    <CssBaseline />
                    <ResponsiveDrawer>
                        <PrivateRoute path="/" exact isAuthenticated={isAuthenticated} component={LandingPage} />
                        <PrivateRoute path="/IncomingEvents" exact isAuthenticated={isAuthenticated}
                            component={Dashboard} />
                        <PrivateRoute path="/MyProfile" isAuthenticated={isAuthenticated}
                            component={MyProfile} />
                        <PrivateRoute path="/MyCompany" isAuthenticated={isAuthenticated}
                            component={MyCompany} />
                        <PrivateRoute exact path="/Items" isAuthenticated={isAuthenticated}
                            component={MyProducts} />
                        <PrivateRoute path="/AddItem" isAuthenticated={isAuthenticated} component={AddProduct} />
                        <PrivateRoute path="/AddPlace" isAuthenticated={isAuthenticated}
                            component={(props) => <AddPlace {...props} other={false} />} />
                        <PrivateRoute path="/ProductDetail" isAuthenticated={isAuthenticated}
                            component={ProductDetail} />
                        <PrivateRoute path="/ProductDetailOther" isAuthenticated={isAuthenticated}
                            component={ProductDetailOther} />
                        <PrivateRoute path="/MyPlaces" isAuthenticated={isAuthenticated}
                            component={(props) => <Places {...props} areMyPlaces={true} />} />
                        <PrivateRoute path="/OtherPlaces" isAuthenticated={isAuthenticated}
                            component={(props) => <Places {...props} areMyPlaces={false} />} />
                        <PrivateRoute path="/PlacesDetail" isAuthenticated={isAuthenticated}
                            component={PlacesDetail} />
                        <PrivateRoute path="/Test" isAuthenticated={isAuthenticated} component={TestPage} />
                        <PrivateRoute path="/MyPartners" isAuthenticated={isAuthenticated}
                                      component={(props) => <Companies {...props} myPartners={true} />} />
                        <PrivateRoute path="/Companies" isAuthenticated={isAuthenticated}
                            component={Companies} />
                        <PrivateRoute path="/CompanyDetail" isAuthenticated={isAuthenticated}
                            component={CompanyDetail} />
                        <PrivateRoute path="/OtherItems" isAuthenticated={isAuthenticated}
                            component={OtherProducts} />
                        {/*<PrivateRoute path="/Places" isAuthenticated={isAuthenticated} component={(props) => <Places {...props} areMyPlaces={false}/>}/>*/}
                        <PrivateRoute path="/EventModels" isAuthenticated={isAuthenticated}
                            component={EventModels} />
                        <PrivateRoute path="/EventModelDetail" isAuthenticated={isAuthenticated}
                            component={EventModelDetail} />
                        <PrivateRoute path="/RelationshipsGraph" isAuthenticated={isAuthenticated}
                            component={SupplyChainGraph} />
                        <PrivateRoute path="/RulesConfiguratorProjects" isAuthenticated={isAuthenticated}
                            component={ProjectsTable} />

                        <PrivateRoute path="/RulesConfigurator" isAuthenticated={isAuthenticated}
                            component={RulesConfiguratorWrapper} />
                        <PrivateRoute path="/AuthRequests" isAuthenticated={isAuthenticated}
                            component={AuthRequests} />
                        <PrivateRoute path="/Devices" isAuthenticated={isAuthenticated}
                            component={Devices} />
                        <PrivateRoute path="/DeviceControlPanel" isAuthenticated={isAuthenticated}
                            component={DeviceControlPanel} />
                        <PrivateRoute path="/Certificates" isAuthenticated={isAuthenticated}
                            component={Certificates} />
                        <PrivateRoute path="/CertificateDetail" isAuthenticated={isAuthenticated}
                            component={CertificateDetail} />
                        <PrivateRoute path="/Revocations" isAuthenticated={isAuthenticated}
                            component={Revocations} />
                        <PrivateRoute path="/Configurations" isAuthenticated={isAuthenticated}
                            component={Configurations} />
                        <PrivateRoute path="/ConfigurationDetail" isAuthenticated={isAuthenticated}
                            component={ConfigurationDetail} />
                        <PrivateRoute path="/AddConfiguration" isAuthenticated={isAuthenticated}
                            component={AddConfiguration} />
                        <PrivateRoute path="/EditConfiguration" isAuthenticated={isAuthenticated}
                            component={EditConfiguration} />
                        <PrivateRoute path="/Apks" isAuthenticated={isAuthenticated}
                            component={Apks} />
                        <PrivateRoute path="/ComposeFiles" isAuthenticated={isAuthenticated}
                            component={ComposeFiles} />
                        <PrivateRoute path="/DevicesMonitoring" isAuthenticated={isAuthenticated}
                            component={DevicesMonitoring} />
                        <PrivateRoute path="/Settings" isAuthenticated={isAuthenticated}
                            component={() => <Settings theme={theme} setTheme={setTheme} />} />
                        <PrivateRoute path="/ProductHistory" isAuthenticated={isAuthenticated}
                            component={ProductHistoryV6} />
                        <PrivateRoute path="/Reports" isAuthenticated={isAuthenticated}
                                      component={Reports} />
                        <PrivateRoute path="/ChemioDataset" isAuthenticated={isAuthenticated}
                                      component={ChemioDataset} />
                        <PrivateRoute path="/FeedChart" isAuthenticated={isAuthenticated}
                                      component={FeedChart} />
                        <PrivateRoute path="/PoultryChart" isAuthenticated={isAuthenticated}
                                      component={PoultryChart} />
                        <PrivateRoute path="/SlaughtersChart" isAuthenticated={isAuthenticated}
                                      component={SlaughtersChart} />
                        <PrivateRoute path="/CreateRelationship" isAuthenticated={isAuthenticated}
                            component={CreateRelationship} />
                        <PrivateRoute path="/CreateEventTemplate" isAuthenticated={isAuthenticated}
                            component={(props) => <CreateEventTemplate {...props} is_draft={true} domain={mainDomain}/>} />
                        <PrivateRoute path="/CreateEvent" isAuthenticated={isAuthenticated}
                            component={CreateEvent} />
                        {/*<PrivateRoute path="/KpiAnalytics" isAuthenticated={isAuthenticated}
                                       component={KpiAnalytics}/>*/}
                        <PrivateRoute path="/ProductionFlow" isAuthenticated={isAuthenticated}
                            component={MarbleDiagramPage} />
                        {
                            /** FF - Map Diagram */
                            //<PrivateRoute path="/MapDiagram" isAuthenticated={isAuthenticated}
                            //           component={MapDiagram} />
                        }
                        <Route path="/Login" component={Login} />
                        <Route path="/ForgotPassword" component={ForgotPasswordForm} />
                        <Route path="/password_reset/:id" component={ResetPassword} />
                        <PrivateRoute path="/accept_partnership_request/:token" isAuthenticated={isAuthenticated} component={AcceptPartnershipRequest} />
                    </ResponsiveDrawer>
                </BrowserRouter>
            </ThemeContext.Provider>
        </StateProvider>
    </AuthContext.Provider>
    );
}

const BoundedApp = () => <AppErrorBoundary><App /></AppErrorBoundary>;

export default BoundedApp;
