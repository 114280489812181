import React, {Fragment, useContext, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import {RelationshipContext} from "../../../contexts";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '0px',
        paddingLeft: '2px',
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    typography: {
        display: 'inline-block',
        backgroundColor: '#f6f6f6',
        padding: '1%',
        borderRadius: '18px',
        marginRight: '1.5%',
        marginTop: '1.5%'
    },
    paragraph: {
        marginBottom: 16,
    },
}));

const MasterDataForm = ({value, onChange, places}) => {

    const classes = useStyles();
    const relationshipContext = useContext(RelationshipContext);

    let rule = value || {
        items: [],
        locations: [],
    };

    if(Array.isArray(value)){
        // se in value ho il campo locations e items è perchè ho una regola master data e qui svuoto le regole //
        console.log('SVUOTO')
        rule = {
            items: [],
            locations: [],
        };
    }

    console.log('HO RULE ---> ', rule)

    const [new_location, setNewLocation] = useState(null);
    const [new_product, setNewProduct] = useState(null);

    const used_locations = rule.locations.map((value) => value);
    const used_products = rule.items.map((value) => value);

    let  filtered_locations = places.bizLocation.filter((place) => used_locations.indexOf(place.gln) < 0 )
    let  filtered_products = relationshipContext.myProducts.data.filter((product) => used_products.indexOf(product.key) < 0 )

    //console.log('ho used --->', used_locations)
    //console.log('ho filtered --->', filtered_locations)


    const onClearClickLocation = (location) => {
        let locations = rule.locations
        let new_locations = locations.filter(val => val !== location.gln)
        const new_rule = {
            locations: new_locations,
            items: [...rule.items]
        };
        onChange && onChange(new_rule);
    }

    const RuleValueLocation = ({gln, remove}) => {
        let objLocation = places.bizLocation.filter(obj => {
            return obj.gln === gln
        })

        const handleClick = () => {
            remove(
                {
                    gln: objLocation[0].gln,
                    address: objLocation[0].address
                }
                )
        }

        return (
            objLocation[0] ?
            <Typography className={classes.typography}>{objLocation[0].address}
                <IconButton aria-label="clear" className={classes.button} onClick={handleClick}>
                    <ClearIcon fontSize="small"/>
                </IconButton>
            </Typography> :
                <Typography className={classes.typography}>No Name</Typography>
        )
    }

    const onClearClickProduct = (product) => {

        let products = rule.items
        let new_products = products.filter(val => val !== product.key)
        const new_rule = {
            locations: [...rule.locations],
            items: new_products
        };
        onChange && onChange(new_rule);
    }

    const RuleValueProduct = ({gtin, remove}) => {

        let objItem = relationshipContext.myProducts.data.filter(obj => {
            return obj.key === gtin
        })

        const handleClick = () => {
            remove(objItem[0])
        }

        return (
            objItem[0] ?
            <Typography className={classes.typography}>{objItem[0].name}
                <IconButton aria-label="clear" className={classes.button} onClick={handleClick}>
                    <ClearIcon fontSize="small"/>
                </IconButton>
            </Typography> :
                <Typography className={classes.typography}>No Name</Typography>
        )
    }

    return(
        <Box pt={1}>
            {rule.locations.length > 0 ? (
                <Box pb={2}>
                    {
                        rule.locations.map((location) => <RuleValueLocation key={location} gln={location} remove={onClearClickLocation}/>)
                    }
                </Box>
            ) : null}
            <Box>
                <Autocomplete
                    options={filtered_locations}
                    renderOption={option =>  <Fragment>{option.address}</Fragment>}
                    getOptionLabel={option => option ? option.address : ''}
                    value={new_location}
                    onChange={(event, newValue) => {
                        const new_rules = {
                            locations: [...rule.locations, newValue.gln],
                            items: [...rule.items]
                        };
                        onChange && onChange(new_rules);
                        filtered_locations.filter(val => val !== newValue)
                        setNewLocation(null)
                    }}
                    renderInput={(params) => <TextField {...params} label="Locations"/>}
                />
            </Box>
            <Divider/>
            <Box pt={2}>
                {rule.items.length > 0 ? (
                    <Box pb={2}>
                        {
                            rule.items.map((gtin) => <RuleValueProduct key={gtin} gtin={gtin} remove={onClearClickProduct}/>)
                        }
                    </Box>
                ) : null}
                <Box>
                    <Autocomplete
                        options={filtered_products}
                        renderOption={option =>  <Fragment>{option.name}</Fragment>}
                        getOptionLabel={option => option ? option.name : ''}
                        value={new_product}
                        onChange={(event, newValue) => {
                            const new_rules = {
                                locations: [...rule.locations],
                                items: [...rule.items, newValue.key]
                            };
                            onChange && onChange(new_rules);
                            filtered_products.filter(val => val !== newValue)
                            setNewProduct(null)
                        }}
                        renderInput={(params) => <TextField {...params} label="Products"/>}
                    />
                </Box>
            </Box>
        </Box>
    )

}

export default MasterDataForm
