import React, {useContext, useEffect, useState} from "react";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {Button, DialogContent, IconButton, Paper, TextField, Tooltip, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {CreateEventContext, ThemeContext} from "../contexts";
import {makeStyles} from "@material-ui/core/styles";
import chroma from 'chroma-js'
import DocViewer, {DocViewerRenderers} from "react-doc-viewer";
import Box from "@material-ui/core/Box";
import axios from "axios";
import UploadFile from "./UploadFile";
import {fetchDocumentId, severityTypes} from "../utils/Utils";
import {useForm} from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import _ from "lodash";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {CheckCircle, CloudUpload, Description, FlipToFront, Link as LinkIcon, LinkOff} from "@material-ui/icons";
import Link from '@material-ui/core/Link';
import LinkPreview from '@ashwamegh/react-link-preview'
import CustomLoader from "./CustomLoader";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Badge from "@material-ui/core/Badge";
import {useTranslation} from "react-i18next";

const DocumentIconButton = ({bizTransaction, bizTransLut, dryRun, editable, upperState, setUpperState, color}) => {
    const { t } = useTranslation();

    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
            '& .MuiIconButton-colorInherit': {
                color: accentColor
            },
            display: 'flex',
            //justifyContent: 'center',
            flexDirection: 'column',
            //alignItems: 'stretch',
            //minHeight: '300px'
        },
        icon: {color: color,},
        dividerContainer: {marginTop: '2%', display: 'flex', alignItems: 'center', justifyContent: 'center'},
        dividerLabel: {color: ''},
        divider: {
            marginLeft: '16px',
            marginRight: '16px',
            width: '25%',
            backgroundColor: chroma(accentColor).alpha(0.5).hex()
        },
        button: {
            width: '20%',
            borderRadius: '20px',
            backgroundColor: accentColor,
            color: 'white',
            fontWeight: 'bold',
        },
        linkPreview: {
            boxShadow: '0px 0px 8px #d8d8d8',
            borderRadius: '8px',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '8px'
        }
    }));
    const classes = useStyles()
    const eventContext = useContext(CreateEventContext)

    const [state, setState] = useState({
        bizTransaction: bizTransaction, // codice di transazione (e.g: gdti)
        type: 'doc', // tipo di associazione: link o documento
        documentId: null, // id del documento caricato in uploads
        urlId: null, // id del link al documento
        _id: null, // mongo id, se l'associazione già esiste

        editable: editable
    })

    const initialErrorState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialErrorState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    useEffect(() => {
        let source = axios.CancelToken.source();
        (async () => {
            function fetchDocId() {
                fetchDocumentId(source, bizTransaction)
                    .then(r => {
                        if (r) {
                            const type = (_.includes(r.doc_db_id, 'http://') || _.includes(r.doc_db_id, 'https://'))
                                ? 'link' : 'doc'
                            if (type === 'link')
                                setState({...state, type: type, urlId: r.doc_db_id, _id: r._id, editable: false})
                            else if (type === 'doc')
                                setState({...state, type: type, documentId: r.doc_db_id, _id: r._id, editable: false})
                        }
                    })
                    .catch(err => console.log("err doc id:", err))
            }

            if (!bizTransLut && !dryRun) {
                fetchDocId();
            } else if(dryRun && bizTransLut) {
                const bizTransLutElement = bizTransLut[bizTransaction]
                if(bizTransLutElement) {
                    const type = (_.includes(bizTransLutElement.documentId, 'http://') || _.includes(bizTransLutElement.documentId, 'https://'))
                        ? 'link' : 'doc'
                    if (type === 'link')
                        setState({...state, type: type, urlId: bizTransLutElement.documentId})
                    else if (type === 'doc')
                        setState({...state, type: type, documentId: bizTransLutElement.documentId})
                } else {
                    fetchDocId();
                }
            }
        })()
        return function () {
            source.cancel("Cancelling in cleanup");
        };
    }, [state.bizTransaction])

    const { register, control, reset, getValues } = useForm();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const onChangeDoc = (id) => {
        const type = state.type === 'link' ? 'urlId' : 'documentId'
        reset() //clear link text field
        let newBizTransactionsDocs = _.cloneDeep(upperState.bizTransactionsDocuments)
        const index = _.findIndex(newBizTransactionsDocs, ['bizTransaction', bizTransaction])

        if (index === -1) {
            if(id) {
                newBizTransactionsDocs.push({
                    bizTransaction: bizTransaction,
                    documentId: id,
                    docType: state.type
                })
            }
        } else {
            if(id)
                newBizTransactionsDocs[index] = {...newBizTransactionsDocs[index], documentId: id}
            else
                _.pullAt(newBizTransactionsDocs, index)
        }

        eventContext.setFormField(null, 'docids', newBizTransactionsDocs)
        setState({...state, [type]: id})
        setUpperState({...upperState, bizTransactionsDocuments: newBizTransactionsDocs})
    }

    function handleChangeDocType(event, newFormats) {
        if (newFormats !== null) {
            setState({...state, type: newFormats})
        }
    }

    const preventDefault = (event) => {
        event.preventDefault()
        window.open(state.urlId, "_blank")
    };

    /**
     * se la prop esterna "editable" è true, ma lo stato interno state.editable è diventato false
     * -> significa che l'associazione bizTrans - doc_db_id eiste già (da cui l'esistenza di state._id, id di mongo)
     * e dunque non può essere sovrascritta
     * */
    return (
        <div>
            <Tooltip
                title={((!state.documentId && !state.urlId) && !state.editable) ?
                    t('entities.document.noDocAttached') : (!state.editable && editable && state._id) ?
                        t('entities.document.busyBizTrans') : state.editable ?
                            t('entities.document.attachOrManageDoc') : t('entities.document.viewAttachements')}
                arrow>
                <span>
                    <IconButton disabled={(!state.editable && !state.documentId && !state.urlId)
                    || (!state.editable && editable && state._id)} onClick={handleClickOpen}
                                className={classes.icon}>
                        {!state.editable && (state.documentId || state.urlId) ?
                            <Badge badgeContent={state.type === 'link' ?
                                <LinkIcon fontSize={'small'} style={{color: accentColor}}/>
                                : <Description fontSize={'small'} style={{color: accentColor}}/>}>
                                <AttachFileIcon/>
                            </Badge>
                            : <AttachFileIcon/>
                        }
                    </IconButton>
                </span>
            </Tooltip>
            <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose}>
                <DialogTitle>{state.editable ? t('entities.document.attachOrManageDoc') : t('entities.document.viewAttachements')}</DialogTitle>
                <DialogContent className={classes.root}>
                    {state.editable ?
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Divider className={classes.divider}/>
                            <ToggleButtonGroup value={state.type}
                                               onChange={handleChangeDocType}
                                               exclusive
                                               size={"small"}
                                               aria-label="doc-type">
                                <ToggleButton value="doc" aria-label="doc" disabled={!!state.urlId}>
                                    <CloudUpload fontSize={'small'} style={{marginRight: '8px'}}/>
                                    {t('actions.upload', {what: t('entities.file')})}
                                </ToggleButton>
                                <ToggleButton value="link" aria-label="link" disabled={!!state.documentId}>
                                    Link
                                    <LinkIcon fontSize={'small'} style={{marginLeft: '8px'}}/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Divider className={classes.divider}/>
                        </div> : null}
                    {state.editable && state.type === 'link' ?
                        <Box pt={2} pb={2}>
                            {state.urlId ?
                                <Box mb={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Tooltip arrow title={t('entities.link.copyLink')}>
                                        <IconButton onClick={() => navigator.clipboard.writeText(state.urlId).then(() => setOpenAlertMessage({open: true, message: t('entities.link.copyLinkSuccess'), severity: severityTypes.SUCCESS}))}
                                                    className={classes.icon}>
                                            <FlipToFront/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow title={t('actions.clear', {what: 'link'})}>
                                        <IconButton onClick={() => {
                                            if (window.confirm(t('entities.link.confirmClearLink'))) {
                                                reset()
                                                onChangeDoc(null)
                                            }}
                                        }>
                                            <LinkOff/>
                                        </IconButton>
                                    </Tooltip>
                                    {state.urlId ? <LinkPreview render={CustomLinkPreviewComponent} url={state.urlId}
                                                                width={"100%"}/> : null}
                                </Box> : null}
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    fullWidth
                                    disabled={state.documentId}
                                    variant={'outlined'}
                                    name={'doc_link'}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) { //enter
                                            setTimeout(function () {
                                                onChangeDoc(getValues('doc_link'))
                                            }, 50);

                                        }
                                    }}
                                    defaultValue={state.urlId}
                                    control={control}
                                    inputRef={register}
                                    label={!state.documentId ? t('entities.link.linkToDoc') : t('entities.link.deleteFileToInsertLink')}
                                    placeholder={!state.documentId ?
                                        t('entities.link.insertLinkToDoc')
                                        : t('entities.link.deleteFileToInsertLink')}
                                />
                                <Button onClick={(event) => {
                                    setTimeout(function () {
                                        onChangeDoc(getValues('doc_link'))
                                    }, 50);
                                }}
                                        style={{marginLeft: '1%', color: accentColor}}
                                        startIcon={<CheckCircle fontSize="small"/>}>
                                    {t('actions.confirm')}
                                </Button>
                            </div>
                        </Box>
                        : !state.editable && state.type === 'link' ?
                                <Box p={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Tooltip arrow title={t('entities.link.copyLink')}>
                                        <IconButton onClick={() =>  navigator.clipboard.writeText(state.urlId).then(() => setOpenAlertMessage({open: true, message: t('entities.link.copyLinkSuccess'), severity: severityTypes.SUCCESS}))}
                                                    className={classes.icon}>
                                            <FlipToFront/>
                                        </IconButton>
                                    </Tooltip>
                                    <LinkIcon style={{color: 'darkgray', marginRight: '12px'}}/>
                                    {state.urlId ?
                                        <LinkPreview render={CustomLinkPreviewComponent} url={state.urlId} width={"100%"}/> : null}
                                </Box> : null}
                    {
                        state.type === 'doc' ?
                            <UploadFile
                                value={state.documentId}
                                editable={state.editable}
                                disabled={!!state.urlId || !!getValues('doc_link')}
                                onChange={onChangeDoc}
                                acceptedTypesLabel={t('entities.document.acceptedFormats')+": .pdf, .png, .jpg, .jpeg, .tiff, .bmp, .txt"}
                                acceptedMIMEtypes={"application/pdf,image/png,image/jpg,image/jpeg,image/tiff,image/bmp,text/plain"}/>
                            : null
                    }
                    {state.documentId ?
                        <Box>
                            <DocViewer
                                documents={[{uri: "api/uploads/uploads/" + state.documentId}]}
                                pluginRenderers={DocViewerRenderers}
                                config={{
                                    header: {
                                        disableHeader: true,
                                        disableFileName: false,
                                        retainURLParams: false
                                    }
                                }}/>
                        </Box>
                        : null
                    }
                </DialogContent>
                <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                          autoHideDuration={2000} onClose={handleCloseErrorMessage}>
                    <Alert onClose={handleCloseErrorMessage} severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
                </Snackbar>
            </Dialog>
        </div>
    )

    function CustomLinkPreviewComponent({loading, preview}) {
        return loading
            ? (<CustomLoader text={t('actions.loading')} size={40}/>)
            : (
                <Paper variant={"outlined"} className={classes.linkPreview} onClick={preventDefault}>
                    <Box m={1}>
                        <Typography><strong>{preview.domain}</strong></Typography>
                        <Tooltip arrow title={t('entities.link.openInNewTab')}>
                            <Link href={state.urlId} rel="noreferrer" onClick={preventDefault}>
                                <Typography>{preview.title}</Typography>
                            </Link>
                        </Tooltip>
                        <Typography>{preview.description}</Typography>
                    </Box>
                    <img height="90px" width="90px" src={preview.img} alt={preview.title}/>
                </Paper>
            )
    }
}
export default DocumentIconButton
