import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import useAxios from "axios-hooks";
import ClearIcon from '@material-ui/icons/Clear';
import {ThemeContext} from "../contexts";
import Chip from "@material-ui/core/Chip";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import {fetchFileInfo} from "../utils/Utils";
import {useTranslation} from "react-i18next";

const UploadFile = ({value: documentId, onChange, acceptedMIMEtypes, acceptedTypesLabel, editable, disabled}) => {
    const { t } = useTranslation();

    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        button: {
            borderRadius: '20px',
            marginBottom: '2%',
            backgroundColor: accentColor,
            color: 'white',
        },
        dottedBox: {
            border: '2px solid lightGray',
            borderRadius: '10px',
            borderStyle: 'dotted',
            padding: '5%',
            display: 'flex',
            justifyContent: 'center',
        },
        uploadLabel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }
    }));
    const classes = useStyles();

    const [state, setState] = useState({fileName: ''})

    useEffect(() => {
        (async () => {
            if(documentId) {
                fetchFileInfo(documentId)
                    .then(r => {
                        if (r)
                            setState({...state, fileName: r.file_name})
                    })
                    .catch(err => console.log("error fetching file info:", err))
            }
        })()
    }, [])

    const [, postExecute] = useAxios(
        {
            url: "api/uploads/uploads/",
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    const handleChange = async (e) => {
        let formData = new FormData();
        let file = e.target.files[0]
        setState({...state, fileName: file.name})
        let raw = Object.defineProperty(file, 'name', {
            writable: true,
            value: 'idcliente_' + file.name
        });
        formData.append('file', raw)

        postExecute({data: formData})
            .then(
                (res) => {
                    onChange(res.data.id)
                }
            )
            .catch(
                (err) => console.log('err uploading file:', err)
            )
    }

    const onClearClick = () => {
        if (window.confirm(t('entities.document.confirmClearFile')))
            onChange(undefined)
    }
    //const src = "/api/uploads/uploads/" + documentId;
    const fileLabel = t('entities.file')
    return (
        documentId ? (
            <Box pt={2} pb={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                {state.fileName ? <Chip label={t('entities.document.fileName')+': '+state.fileName.replace('sandbox/', '')}
                                        onDelete={editable ? onClearClick : null}/> : null}
                {
                    editable ?
                        <div><input color="default" accept={acceptedMIMEtypes} type="file"
                            onChange={handleChange} id="icon-button-file"
                            style={{display: 'none'}}/>
                        <label htmlFor="icon-button-file">
                        <Button color="default" component="span"
                            endIcon={<FolderOpenIcon />}>
                            {t('entities.document.chooseAnotherFile')}
                        </Button>
                        </label>
                        <Button color="default" onClick={onClearClick}
                            endIcon={<ClearIcon />}>
                            {t('actions.clear')}
                        </Button>
                        </div> : null
                }
            </Box>
        ) : (
            <Box mt={2} mb={2} className={classes.dottedBox}>
                <input
                    disabled={disabled}
                    color="default" accept={acceptedMIMEtypes} type="file"
                    onChange={handleChange} id="icon-button-file"
                    style={{display: 'none'}}/>
                <label htmlFor="icon-button-file" className={classes.uploadLabel}>
                    <Button
                        disabled={disabled}
                        className={classes.button}
                        variant="contained" component="span"
                        size="medium"
                        startIcon={<PublishOutlinedIcon/>}>
                        {t('actions.upload', {what: fileLabel})}
                    </Button>
                    <Typography style={{fontStyle: 'italic'}} align={'center'} variant={"body2"}>
                        {acceptedTypesLabel}
                    </Typography>
                </label>
            </Box>
        )
    )
}

export default UploadFile
