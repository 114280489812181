import React from "react";
import useAxios from "axios-hooks";
import CustomLoader from "../../../components/CustomLoader";
import Typography from "@material-ui/core/Typography";
import {Collapse, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

const AcceptPartnershipRequest = (props) => {
    const {t} = useTranslation()
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '70%',
            justifyContent: "center",
            display: "block",
            margin: 'auto',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        paper: {
            borderRadius: '10px'
        },
    }));
    const classes = useStyles();

    const token = props.match.params.token

    const [{loading: loading, error: error}] = useAxios(
        {
            url: "/api/partnership_requests/accept/token",
            method: "POST",
            data: `${JSON.stringify(token)}`,
        },
    )

    return (
        <div>
            {(error === null || error === undefined) ? <div className={classes.root}>
                <Box p={3} component={Paper} className={classes.paper} display={'flex'} flexDirection={'column'}>
                    <img alt={"logo_iChain"} src="/assets/logo_iChain.png" style={{width: '15%', margin:'auto'}}/>
                    <Typography align={'center'} variant={'h6'}>
                        {t("myPartners.thanksForAcceptingTheRequest")}
                    </Typography>
                </Box>
            </div> : null}
            {loading && <CustomLoader/>}
            {<Collapse in={!!error}>
                <Grid item xs={12} style={{marginBottom: '4%', marginTop: '4%'}}>
                    <Alert severity={"error"}>
                        {t("errors.genericServerError")} - {error?.response?.data?.detail || t("errors.loginWithTheRightDomain")}
                    </Alert>
                </Grid>
            </Collapse>}
            <Button component={Link} to={"/MyPartners"}>
                {t("myPartners.goToMyPartner")}
            </Button>
        </div>
    )
}

export default AcceptPartnershipRequest
