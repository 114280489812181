import React from "react";
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";

/** usato sia nel form di creazione eventi che nel form di creazione/edit prodotti */
const EventCodeValue = ({code, remove}) => {
    const handleClick = () => {
        remove(code)
    }

    return (
        <div>
            <Box pl={2} pr={2}>
                <Grid container direction="row" justify="flex-start" >
                    <Grid item xs={11} container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">
                        <Box width={1}>
                           <Typography>
                               <b>{code.type}: </b>
                               {code.value} {code.quantity ? `(Qty: ${code.quantity} ${code.uom})` : null}
                           </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                        <IconButton aria-label="clear" onClick={handleClick}>
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={1} pr={1}>
                <Divider/>
            </Box>
        </div>
    )
}

export default EventCodeValue;
