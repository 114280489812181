import IconButton from "@material-ui/core/IconButton";
import React, {useContext, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import axios from "axios";
import UploadImage from "../../components/UploadImage";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Zoom from "@material-ui/core/Zoom";
import _ from 'lodash'
import Tooltip from "@material-ui/core/Tooltip";
import {login} from "../login/auth";
import {AuthContext} from "../../contexts";
import {useTranslation} from "react-i18next";
import {severityTypes} from "../../utils/Utils";

const EditCompanyImage = (props) => {
    const { t } = useTranslation();

    const {company, isMainImg, data, theme} = props
    const authContext = useContext(AuthContext)
    //console.log("EditCompanyImage ---> data:", data)
    //console.log("EditCompanyImage ---> brands:", company.brands)
    const useStyles = makeStyles({
        dottedBox: {
            border: '2px solid lightGray',
            borderRadius: '15px',
            borderStyle: 'dotted',
        },
        button: {
            backgroundColor: theme.accentColor,
            color: "white",
            fontWeight: 'bold',
            marginTop: '1%',
            marginRight: '1%',
            borderRadius: '20px'
        }
    })
    const classes = useStyles()

    const initialState = {
        open: false,
        image: data.image
    }

    const [state, setState] = useState(initialState)

    const handleClickOpen = () => {
        setState({...state, open: true});
    };
    const handleClose = () => {
        console.log("state:", state)
        setState(initialState);
    };

    const initialErrorState = {open: false, message: '', severity: severityTypes.WARNING}
    const [openAlertMessage, setOpenAlertMessage] = useState(initialErrorState);
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
    };

    const updateMyCompany = () => {
        const brandToUpdateIndex = _.findIndex(company.brands, ['domain', data.domain])
        console.log("brandToUpdate:",company.brands[brandToUpdateIndex])

        const _brands = _.cloneDeep(company.brands)

        if(brandToUpdateIndex > -1) {
            _brands[brandToUpdateIndex] = {..._brands[brandToUpdateIndex], image: state.image}
        }

        if(isMainImg)
            _brands[0] = {..._brands[0], image: state.image}

        const dataToSend = isMainImg || brandToUpdateIndex === 0 ?
            {...company, image: state.image, brands: _brands} : {...company, brands: _brands}

        console.log("dataToSend:", dataToSend)
        axios
            .put('/api/companies/' + company._id, dataToSend)
            .then((res) => {
                if (res.status === 200) {
                    console.log("company updated okay")
                    let url = "/auth/refresh_token_from_header"
                    const instance = axios.create(
                        {
                            method: 'post',
                            headers: {'Authorization': `Bearer ${localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null}`},
                        }
                    );
                    return instance.post(url)
                        .then(
                            res => {
                                if (res.status === 200) {
                                    login(res.data)
                                    setOpenAlertMessage({open: true, message: "Added new domain!", severity: severityTypes.SUCCESS})
                                    setState({...state, open: false})
                                    authContext.setActiveBrands(dataToSend.brands)
                                }
                            })
                        .catch(
                            error => {
                                console.log("error: ", error)
                                return Promise.reject(error)
                            }
                        )

                }
            })
            .catch((err) => {
                setOpenAlertMessage({
                    open: true,
                    message: "Ops, something went wrong: " + err,
                    severity: severityTypes.ERROR
                })
            })
    }

    const onChangeImage = (id) => {
        console.log("id:", id)
        setState({...state, image: id})
    }

    const imageLabel = t('entities.image');
    return <Zoom in={true}>
        <div>
            <Tooltip title={t('actions.change', {what: t('entities.image')})}>
                <IconButton style={{backgroundColor: isMainImg ? theme.accentColor : 'transparent'}}
                            onClick={handleClickOpen}>
                    <CameraAltIcon fontSize={isMainImg ? 'small' : 'default'}
                                   style={{color: !isMainImg ? theme.accentColor : 'white'}}/>
                </IconButton>
            </Tooltip>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                  autoHideDuration={3000} onClose={handleCloseErrorMessage}>
            <Alert severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
        </Snackbar>
        <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems:'center'}}>
                <Box p={8} className={classes.dottedBox}>
                    <UploadImage value={state.image} onChange={onChangeImage}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} style={{marginTop: '1%', marginRight: '1%'}}>
                    {t('actions.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={updateMyCompany}
                    className={classes.button}>
                    {t('actions.change', {what: imageLabel})}
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    </Zoom>
}

export default EditCompanyImage
