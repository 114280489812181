import React, {useContext, useEffect, useMemo} from "react";
import {Box} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import {CreateEventContext} from "../../contexts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from 'lodash'
import {useTranslation} from "react-i18next";

const EventTypologyComponent = (props) => {
    const {t} = useTranslation()
    const {eventName, handleChange, setStepValidation} = props
    const eventContext = useContext(CreateEventContext);

    const validation = useMemo(() => {
        eventContext.setValidationField('eventName', 'eventName', !!eventName)
        return !!eventContext.fromEventModelsTableData ? true : !!eventName
    }, [eventName, eventContext])

    useEffect(() => {
        setStepValidation(validation)
    }, [validation, setStepValidation])

    return (
        <Box p={3}>
            <Autocomplete
                clearOnEscape
                fullWidth
                size={'small'}
                variant="outlined"
                options={eventContext.modelsName}
                getOptionLabel={(option) => _.startCase(option)}
                renderInput={(params) => <TextField size={'small'} {...params} label={t('createEventModel.chooseModel', "Choose the event model")} variant="outlined" />}
                disabled={!!eventContext.fromEventModelsTableData}
                value={eventName ? eventName : ''}
                onChange={(event, newValue) => {
                    //console.log("newValue:",newValue)
                    handleChange(newValue, null, null)
                    eventContext.setFormField(null, 'name', newValue)
                }}
            />
            {
                !validation ?
                    <FormHelperText error>{t('entities.requiredField')}</FormHelperText> : null
            }
        </Box>
    )
}

export default EventTypologyComponent