import _ from "lodash";
import i18next from "../i18n/i18next";
import Moment from "moment";

/** CBV DICTIONARY UTILS -----------------------------------------------------------------------------
 * vedere doc in <git>/docs/ennio "Event Template Guide - From frontend form to Data Format" */

/** Indica il tipo di eventi possbili in EPCIS */
export const eventTypes = {
    OBJECT: {value: 'ObjectEvent', label: i18next.t('cbvValues.eventTypes.object.label'), description: i18next.t('cbvValues.eventTypes.object.description')},
    TRANSACTION: {value: 'TransactionEvent', label: i18next.t('cbvValues.eventTypes.transaction.label'), description: i18next.t('cbvValues.eventTypes.transaction.description')},
    AGGREGATION: {value: 'AggregationEvent', label: i18next.t('cbvValues.eventTypes.aggregation.label'), description: i18next.t('cbvValues.eventTypes.aggregation.description')},
    ASSOCIATION: {value: 'AssociationEvent', label: i18next.t('cbvValues.eventTypes.association.label'), description: i18next.t('cbvValues.eventTypes.association.description')},
    TRANSFORMATION: {value: 'TransformationEvent', label: i18next.t('cbvValues.eventTypes.transformation.label'), description: i18next.t('cbvValues.eventTypes.transformation.description')},
}

/** Indica il tipo di input dalla quale viene preso il dato per riempire il campo value */
export const inputTypes = {
    COMPUTED: {value: i18next.t('inputTypes.computed.label', 'computed'), description: i18next.t('inputTypes.computed.description', 'This value is set to reception time by the server; timezone is automatically set from event location')},
    EXTERNAL: {value: i18next.t('inputTypes.external.label','external'), description: i18next.t('inputTypes.external.description', 'This value is going to be specified by the user during the creation the event')},
    FIXED: {value: i18next.t('inputTypes.fixed.label', 'fixed'), description: i18next.t('inputTypes.fixed.description', 'This value is fixed and it should be specified here')},
    PARTIALLY_FIXED: {value: i18next.t('inputTypes.partiallyFixed.label', 'partially-fixed'), description: i18next.t('inputTypes.partiallyFixed.description', 'This value is fixed with the document types (from the CBV) valid for this transaction, saved in an ordered list. The user who will generate the event has to insert the document id, in the same order')},
    WHITELIST: {value: i18next.t('inputTypes.suggestedPlacesList.label', 'suggested places list'), description: i18next.t('inputTypes.suggestedPlacesList.description', 'This value allows you to specify multiple locations as a suggestion for the user who will generate the event')},
    CLIENT_COMPUTED: {value: i18next.t('inputTypes.clientComputed.label', 'client-computed'), description: i18next.t('inputTypes.clientComputed.description', 'This value is automatically set by the input device during event creation; timezone is set accordingly to the input device configuration')}
}

/** Tipi di field possibili nei modelli/template degli eventi EPCIS e negli eventi EPCIS stessi */
export const eventFieldNameTypes = {
    /** FIELD WHAT */
    EPCLIST: {
        type: 'EPCList',
        name: 'epcList',
        label: i18next.t('cbvValues.epcList.label', 'Items subject of the event'),
        description: i18next.t('cbvValues.epcList.description', 'List of serial numbers related to single items')
    },
    INPUT_EPCLIST: {
        type: 'EPCList',
        name: 'inputEPCList',
        label: i18next.t('cbvValues.inputEPCList.label', 'List of input items'),
        description: i18next.t('cbvValues.inputEPCList.description', 'List of serial numbers related to input items')
    },
    OUTPUT_EPCLIST: {
        type: 'EPCList',
        name: 'outputEPCList',
        label: i18next.t('cbvValues.outputEPCList.label', 'List of output items, as the result of the Transformation'),
        description: i18next.t('cbvValues.outputEPCList.description', 'List of serial numbers related to output items')
    },
    QUANTITYLIST: {
        type: 'QuantityList',
        name: 'quantityList',
        label: i18next.t('cbvValues.quantityList.label', 'List of items with related quantities'),
        description: i18next.t('cbvValues.quantityList.description','List of serial numbers related to items belonging to a lot, characterized by the attributes quantity and UoM')
    },
    INPUT_QUANTITYLIST: {
        type: 'QuantityList',
        name: 'inputQuantityList',
        label: i18next.t('cbvValues.inputQuantityList.label','Input items with related quantities'),
        description: i18next.t('cbvValues.inputQuantityList.description','List of serial numbers related to input items belonging to a lot, characterized by the attributes quantity and UoM')
    },
    OUTPUT_QUANTITYLIST: {
        type: 'QuantityList',
        name: 'outputQuantityList',
        label: i18next.t('cbvValues.outputQuantityList.label','Output items with related quantities, as the result of the Transformation'),
        description: i18next.t('cbvValues.outputQuantityList.description', 'List of serial numbers related to output items belonging to a lot, characterized by the attributes quantity and UoM')
    },
    CHILD_QUANTITYLIST_LIST: {
        type: 'QuantityList',
        name: 'childQuantityList',
        label: i18next.t('cbvValues.childQuantityList.label', 'Children items being part of the aggregation, with related quantities'),
        description: i18next.t('cbvValues.childQuantityList.description', 'List of children items which are being aggregated in the parent')
    },
    CHILD_EPC_LIST: {
        type: 'EPCList',
        name: 'childEPCs',
        label: i18next.t('cbvValues.childEPCs.label','Children items being part of the aggregation'),
        description: i18next.t('cbvValues.childEPCs.description','List of children items which are being aggregated in the parent')
    },
    EPC_QUANTITY_LIST: {
        type: 'EpcQuantityList',
        name: 'epcQuantityList',
        label: i18next.t('cbvValues.bothListType.label','Multiple types of lists'),
        description: i18next.t('cbvValues.bothListType.description','The event will contain multiple types of lists')
    },
    PARENT_ID: {
        type: 'str',
        name: 'parentID',
        label: i18next.t('cbvValues.parentID.label'),
        description: i18next.t('cbvValues.parentID.description')
    },
    BIZTRANSACTIONLIST: {
        type: 'BizTransactionList',
        name: 'bizTransactionList',
        label: i18next.t('cbvValues.bizTransactionList.label','List of transactions information'),
        description: i18next.t('cbvValues.bizTransactionList.description','Usually it is a list of identifiers or link to the related documents of the transaction')
    },

    /** FIELD WHY */
    BIZ_STEP: {
        type: 'str',
        name: 'bizStep',
        label: i18next.t('cbvValues.bizStep.label', 'The specific type of action'),
        description: i18next.t('cbvValues.bizStep.description', 'Specifies what business process step was taking place that caused this event to be captured')
    },
    DISPOSITION: {
        type: 'str',
        name: 'disposition',
        label: i18next.t('cbvValues.disposition.label', 'Item state at the moment of the event generation'),
        description: i18next.t('cbvValues.disposition.description', 'Specifies the business condition of the subject of this event, subsequent to the event. The disposition is assumed to hold true until another event indicates a change of disposition')
    },
    ACTION: {type: 'str', name: 'action', label: 'Action'},

    /** FIELD WHERE */
    BIZ_LOCATION: {
        type: 'str',
        name: 'bizLocation',
        label: i18next.t('cbvValues.bizLocation.label', 'The Business Location in which the event has happened'),
        description: i18next.t('cbvValues.bizLocation.description', 'The location where the item subject of this event is assumed to be following an EPCIS event, until a new event takes place that indicates otherwise')
    },
    READ_POINT: {
        type: 'str',
        name: 'readPoint',
        label: i18next.t('cbvValues.readPoint.label', 'The Read Point location where the EPCIS event took place'),
        description: i18next.t('cbvValues.readPoint.description', 'In the case of an EPCIS event arising from reading a barcode or RFID tag, the Read Point is often the location where the barcode or RFID tag was read')
    },

    /** TODO: descrizioni source/dest list */
    SOURCELIST: {
        type: 'SourceList',
        name: 'sourceList',
        label: i18next.t('cbvValues.sourceList.label', 'List of Sources of the transaction'),
        description: i18next.t('cbvValues.sourceList.description', 'List of sgln identifiers of the origins of the business transfer')
    },
    DESTINATIONLIST: {
        type: 'DestinationList',
        name: 'destinationList',
        label: i18next.t('cbvValues.destinationList.label','List of Destinations of the transaction'),
        description: i18next.t('cbvValues.destinationList.description', 'List of sgln identifiers of the destinations of the business transfer')
    },

    /** FIELD WHEN */
    EVENT_TIME: {
        type: 'date',
        name: 'eventTime',
        label: i18next.t('cbvValues.eventTime.label','Event Time'),
        description: i18next.t('cbvValues.eventTime.description','The moment in time at which an EPCIS event occurred')
    },
    RECORD_TIME: {
        type: 'date',
        name: 'recordTime',
        label: i18next.t('cbvValues.recordTime.label','Record Time'),
        description: i18next.t('cbvValues.recordTime.description','Time of the recording of the event. It may differ from the Event Time in case of deferred recording of the event')
    },
    TZ_OFFSET: {
        type: 'date',
        name: 'eventTimeZoneOffset',
        label: i18next.t('cbvValues.eventTimeZoneOffset.label','UTC Timezone Offset'),
        description: i18next.t('cbvValues.eventTimeZoneOffset.description','The UTC timezone offset (example: \'+01:00\') of the place where the event occurred')
    },

    /** ILMD */
    ILMD: {type: 'array', name: 'ilmd',
        label: i18next.t('cbvValues.ilmd.label','Instance/Lot Master Data'),
        description: i18next.t('cbvValues.ilmd.description','Custom features related to the item subject of the EPCIS event')},
}

/** Tipi di business transaction possibili */
export const bizTransactionTypes = {
    INV: {
        value: 'urn:epcglobal:cbv:btt:inv',
        name: 'Invoice',
        description:  i18next.t('cbvValues.bizTransTypes.inv.description', 'Invoice: A document/message claiming payment for goods or services supplied under conditions agreed by the seller and buyer')
    },
    PO: {
        value: 'urn:epcglobal:cbv:btt:po',
        name: 'Purchase Order',
        description: i18next.t('cbvValues.bizTransTypes.po.description', "Purchase Order: A document/message that specifies details for goods and services ordered under conditions agreed by the seller and buyer")
    },
    DESADV: {
        value: 'urn:epcglobal:cbv:btt:desadv',
        name: 'Despatch Advice',
        description: i18next.t('cbvValues.bizTransTypes.desadv.description', "Despatch Advice: A document/message by means of which the seller or consignor informs the consignee about the despatch of goods. Also called an “Advanced Shipment Notice,” but the value desadv is always used regardless of local nomenclature")
    },
    BOL: {
        value: 'urn:epcglobal:cbv:btt:bol',
        name: 'Bill of Lading',
        description: i18next.t('cbvValues.bizTransTypes.bol.description', "Bill of Lading: A document issued by a carrier to a shipper, listing and acknowledging receipt of goods for transport and specifying terms of delivery")
    },
    PEDIGREE: {
        value: 'urn:epcglobal:cbv:btt:pedigree',
        name: 'Pedigree',
        description: i18next.t('cbvValues.bizTransTypes.pedigree.description', "Pedigree: A record that traces the ownership or custody and transactions of a product as it moves among various trading partners")
    },
    PRODORDER: {
        value: 'urn:epcglobal:cbv:btt:prodorder',
        name: 'Production Order',
        description: i18next.t('cbvValues.bizTransTypes.prodorder.description', "Production Order: An organisation-internal document or message issued by a producer that initiates a manufacturing process of goods")
    },
    POC: {
        value: 'urn:epcglobal:cbv:btt:poc',
        name: 'Purchase Order Confirmation',
        description: i18next.t('cbvValues.bizTransTypes.poc.description', "Purchase Order Confirmation: A document that provides confirmation from an external supplier to the request of a purchaser to deliver a specified quantity of material, or perform a specified service, at a specified price within a specified time")
    },
    RECADV: {
        value: 'urn:epcglobal:cbv:btt:recadv',
        name: 'Receiving Advice',
        description: i18next.t('cbvValues.bizTransTypes.recadv.description', "Receiving Advice: A document/message that provides the receiver of the shipment the capability to inform the shipper of actual goods received, compared to what was advised as being sent")
    },
    RMA: {
        value: 'urn:epcglobal:cbv:btt:rma',
        name: 'Return Merchandise Authorisation',
        description: i18next.t('cbvValues.bizTransTypes.rma.description', "Return Merchandise Authorisation: A document issued by the seller that authorises a buyer to return merchandise for credit determination")
    },
}

/** Tipi possibili di codici GENERABILI per biz transaction */
export const bizTransactionCodeTypes = {
    GDTI: {
        prefix: 'urn:epc:id:gdti:',
        name: 'gdti',
    },
    INTERNAL: {
        prefix: 'http://',
        name: 'internal',
    }
}

/** Tipi di source-destination possibili */
export const sourceDestinationListInputTypes = {
    LOCATION: {
        value: "urn:epcglobal:cbv:sdt:location",
        name: i18next.t('cbvValues.sourceDestListInputTypes.location.label','Location'),
        description: i18next.t('cbvValues.sourceDestListInputTypes.location.description', 'The source or destination identifier denotes the physical location of the originating endpoint or terminating endpoint (respectively) of the business transfer of which this EPCIS event is a part. When a source of this type is specified on an EPCIS event at the originating endpoint of a business transfer, the source identifier SHOULD be consistent with the Read Point specified in that event. When a destination of this type is specified on an EPCIS event at the terminating endpoint of a business transfer, the destination identifier SHOULD be consistent with the Read Point specified in that event')
    },
    OWNING_PARTY: {
        value: "urn:epcglobal:cbv:sdt:owning_party",
        name: i18next.t('cbvValues.sourceDestListInputTypes.owningParty.label', 'Owning Party'),
        description: i18next.t('cbvValues.sourceDestListInputTypes.owningParty.description', 'The source or destination identifier denotes the party who owns (or is intended to own) the objects at the originating endpoint or terminating endpoint (respectively) of the business transfer of which this EPCIS event is a part')
    },
    POSSESSING_PARTY: {
        value: "urn:epcglobal:cbv:sdt:possessing_party",
        name: i18next.t('cbvValues.sourceDestListInputTypes.possessingParty.label', 'Possessing Party'),
        description: i18next.t('cbvValues.sourceDestListInputTypes.possessingParty.description', 'The source or destination identifier denotes the party who has (or is intended to have) physical possession of the objects at the originating endpoint or terminating endpoint (respectively) of the business transfer of which this EPCIS event is a part')
    },
}

/** Tipi di valori letti dai sensori (HOW dimension) */
export const maskStdAttributes = [
    'type', 'uom', 'time', "value", "stringValue", "booleanValue", "uriValue", "hexBinaryValue", "minValue", "maxValue", "meanValue", "sDev", "percRank", "percValue"
]

export const simpleValues = [
    "value", "stringValue"
]

export const getSensorDataReportValueTypesFormat = (valueType, value) => {
    switch (valueType) {
        case sensorDataReportValueTypes.time:
        case sensorDataReportValueTypes.dateTimeValue:
            return i18next.t("formatters:dateTime", {date: Moment(value)})
        case sensorDataReportValueTypes.timeValue:
            return value?.split('.')[0] || value
            //return i18next.t("formatters:time", {date: Moment(value)})
        case sensorDataReportValueTypes.booleanValue:
            return value ? 'TRUE' : 'FALSE'
        case sensorDataReportValueTypes.percValue:
        case sensorDataReportValueTypes.percRank:
            return `${value}%`
        default:
            return value
    }
}

export const sensorDataReportValueTypes = {
    time: {
        name: "time",
        align: 'center',
        label: _.startCase(i18next.t('cbvValues.sensorReport.time')),
    },
    timeValue: {
        name: "timeValue",
        align: 'center',
        label: _.startCase(i18next.t('cbvValues.sensorReport.time')),
    },
    dateTimeValue: {
        name: "dateTimeValue",
        align: 'center',
        label: _.startCase(i18next.t('cbvValues.sensorReport.time')),
    },
    value: {
        name: "value",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.value')),
    },
    stringValue: {
        name: "stringValue",
        align: 'center',
        label: _.startCase(i18next.t('cbvValues.sensorReport.stringValue')),
    },
    booleanValue: {
        name: "booleanValue",
        align: 'center',
        label: _.startCase(i18next.t('cbvValues.sensorReport.booleanValue')),
    },
    uriValue: {
        name: "uriValue",
        align: 'center',
        label: _.startCase(i18next.t('cbvValues.sensorReport.uriValue')),
    },
    hexBinaryValue: {
        name: "hexBinaryValue",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.hexBinaryValue')),
    },
    minValue: {
        name: "minValue",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.minValue')),
    },
    maxValue: {
        name: "maxValue",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.maxValue')),
    },
    meanValue: {
        name: "meanValue",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.meanValue')),
    },
    sDev: {
        name: "sDev",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.sDev')),
    },
    percRank: {
        name: "percRank",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.percRank')),
    },
    percValue: {
        name: "percValue",
        align: 'right',
        label: _.startCase(i18next.t('cbvValues.sensorReport.percValue')),
    },
}

export const splitCbvValues = (cbvValue) => {
    let newStr = cbvValue
    if(_.includes(cbvValue, 'urn:epcglobal:cbv:')) {
        const value = cbvValue.split(':');
        if (value?.length > 1) {
            newStr = value[4].replace(/_/g, ' ');
        }
    }
    return newStr
}

/** UOM LUT ------------------------------------------------------------------------------------ */

export const uomLut = {
    "A1": "15 C Calorie",
    "AL": "Access Line",
    "ACR": "Acre",
    "26": "Actual Ton",
    "AH": "Additional Minute",
    "MD": "Air Dry Metric Ton",
    "ASM": "Alcoholic Strength By Mass",
    "ASU": "Alcoholic Strength By Volume",
    "AP": "Aluminium Pound Only",
    "AMP": "Ampere",
    "AMH": "Ampere Hour",
    "A2": "Ampere Per Centimetre",
    "AE": "Ampere Per Metre",
    "A3": "Ampere Per Millimetre",
    "A4": "Ampere Per Square Centimetre",
    "A41": "Ampere Per Square Metre",
    "A6": "Ampere Per Square Metre Kelvin Squared",
    "A7": "Ampere Per Square Millimetre",
    "A8": "Ampere Second",
    "A5": "Ampere Square Metre",
    "A10": "Ampere Square Metre Per Joule Second",
    "73": "Ampere Tum Per Centimetre",
    "AM": "Ampoule",
    "A11": "Angstrom",
    "AXU": "Anti XA Unit",
    "AQ": "Anti-Hemophilic Factor (Ahf) Unit",
    "ARE": "Are",
    "AY": "Assembly",
    "AS": "Assortment",
    "A12": "Astronomical Unit",
    "D43": "Atomic Mass Units (AMU)",
    "A13": "Attojoule",
    "AI": "Average Minute Per Call",
    "BG": "Bag",
    "BL": "Bale",
    "AA": "Ball",
    "D92": "Band",
    "BR": "Bar",
    "BAR": "Bar (unit of pressure)",
    "NB": "Barge",
    "A14": "Barn",
    "A15": "Barn Per Electron Volt",
    "A16": "Barn Per Steradian Electron Volt,",
    "A17": "Barn Per Sterdian",
    "B1": "Barrel (Us) Per Day",
    "5A": "Barrel Per Minute",
    "BLL": "Barrel US",
    "B4": "Barrel, Imperial",
    "BB": "Base box",
    "BW": "Base Weight",
    "BK": "Basket",
    "5B": "Batch",
    "B9": "Batt",
    "B3": "Batting Pound",
    "D79": "Beam",
    "BPM": "Beats Per Minute",
    "BQL": "Becquerel",
    "A18": "Becquerel Per Kilogram",
    "A19": "Becquerel Per Metre Cubed",
    "E2": "Belt",
    "B5": "Billet",
    "2W": "Bin",
    "B10": "Bit per second",
    "D64": "Block",
    "BD": "Board",
    "BFT": "Board Foot",
    "4A": "Bobbin",
    "BT": "Bolt",
    "D63": "Book",
    "BO": "Bottle",
    "BX": "Box",
    "BHP": "Brake Horse Power",
    "BTU": "British thermal unit",
    "2I": "British Thermal Unit Per Hour",
    "A23": "British Thermal Unit Per Hour Square Foot Degree Rankin",
    "AZ": "British Thermal Unit Per Pound",
    "A21": "British Thermal Unit Per Pound Degree Rankin",
    "A22": "British Thermal Unit Per Second Foot Degree Rankin",
    "A20": "British Thermal Unit Per Second Square Foot Degree Rankin",
    "BH": "Brush",
    "B0": "Btu Per Cubic Foot",
    "BJ": "Bucket",
    "VQ": "Bulk",
    "48": "Bulk Car Load",
    "AB": "Bulk Pack",
    "B6": "Bun",
    "BE": "Bundle",
    "B2": "Bunk",
    "BUI": "Bushel (UK)",
    "BUA": "Bushel (US)",
    "AD": "Byte",
    "1D": "Caboose Count",
    "1H": "Caboose Mile",
    "KA": "Cake",
    "C0": "Call",
    "D70": "Calorie",
    "92": "Calorie - Calorie Per Cubic Centimetre",
    "93": "Calorie Per Gram",
    "CA": "Can",
    "CDL": "Candela",
    "A24": "Candela per Square Metre",
    "4B": "Cap",
    "AV": "Capsule",
    "NC": "Car",
    "1B": "Car Count",
    "1A": "Car Mile",
    "CO": "Carboy",
    "CG": "Card",
    "C4": "Carload",
    "CCT": "Carrying Capacity In Metric Ton",
    "C2": "Carset",
    "CT": "Carton",
    "CQ": "Cartridge",
    "CS": "Case",
    "Z3": "Cask",
    "D66": "Cassette",
    "31": "Catch Weight",
    "C6": "Cell",
    "CNT": "Cental (Uk)",
    "CGM": "Centigram",
    "CLT": "Centilitre",
    "CMT": "Centimetre",
    "2M": "Centimetre Per Second",
    "C7": "Centipoise",
    "X_CHD": "Centisimal Hahnemannian Dilution (CH)",
    "4C": "Centistokes",
    "X1": "Chain",
    "Z2": "Chest",
    "A25": "Cheval Vapeur",
    "CL": "Coil",
    "C9": "Coil Group",
    "CFU": "Colony Forming Units",
    "II": "Column Inch",
    "CZ": "Combo",
    "C1": "Composite Product Pound (Total Weight)",
    "CJ": "Cone",
    "Z6": "Conference Point",
    "CK": "Connector",
    "CH": "Container",
    "HN": "Conventional Millimetre Of Mercury",
    "HP": "Conventional Millimetre Of Water",
    "AJ": "Cop",
    "WCD": "Cord",
    "C5": "Cost",
    "COU": "Coulomb",
    "A26": "Coulomb Metre",
    "A27": "Coulomb Metre Squared Per Volt",
    "A28": "Coulomb Per Cubic Centimetre",
    "A29": "Coulomb Per Cubic Metre",
    "A30": "Coulomb Per Cubic Millimetre",
    "CKG": "Coulomb Per Kilogram",
    "A31": "Coulomb Per Kilogram Second",
    "A32": "Coulomb Per Mole",
    "A33": "Coulomb Per Square Centimetre",
    "A34": "Coulomb Per Square Metre",
    "A35": "Coulomb Per Square Millimetre",
    "1N": "Count",
    "IT": "Count Per Centimetre",
    "IC": "Count Per Inch",
    "5K": "Count Per Minute",
    "CV": "Cover",
    "CR": "Crate",
    "CMQ": "Cubic centimetre",
    "A36": "Cubic Centimetre Per Mole",
    "2J": "Cubic Centimetre Per Second",
    "DMQ": "Cubic decimetre",
    "A37": "Cubic Decimetre Per Mole",
    "36": "Cubic Feet Per Minute Per Square Foot",
    "FTQ": "Cubic foot",
    "2K": "Cubic Foot Per Hour",
    "2L": "Cubic Foot Per Minute",
    "INQ": "Cubic inch",
    "MTQ": "Cubic metre",
    "D90": "Cubic Metre (Net)",
    "A38": "Cubic Metre Per Coulomb",
    "MQH": "Cubic Metre Per Hour",
    "A39": "Cubic Metre Per Kilogram",
    "A40": "Cubic Metre Per Mole",
    "MQS": "Cubic Metre Per Second",
    "MMQ": "Cubic millimetre",
    "YDQ": "Cubic Yard",
    "CU": "Cup",
    "G21": "Cup (US)",
    "CUR": "Curie",
    "A42": "Curie Per Kilogram",
    "94": "Curl Unit",
    "B7": "Cycle",
    "CY": "Cylinder",
    "DQ": "Data Record",
    "DAY": "Days",
    "A43": "Deadweight Tonnage",
    "DE": "Deal",
    "DEC": "Decade",
    "DJ": "Decagram",
    "A44": "Decalitre",
    "A45": "Decametre",
    "DAA": "Decare",
    "X_DBA": "Decibar",
    "2N": "Decibel",
    "DG": "Decigram",
    "DLT": "Decilitre",
    "22": "Decilitre Per Gram",
    "DMT": "Decimetre",
    "DN": "Decinewton Metre",
    "A47": "Decitex",
    "DTN": "Decitonne",
    "DD": "Degree (Unit of Angle)",
    "CEL": "Degree Celsius",
    "FAH": "Degree Fahrenheit",
    "A48": "Degree Rankin",
    "A49": "Denier",
    "DY": "Directory Book",
    "DC": "Disk (Disc)",
    "DI": "Dispenser",
    "DPT": "Displacement Tonnage",
    "DS": "Display",
    "D67": "Dollar Per Hour",
    "E27": "Dose",
    "E39": "Dots per inch",
    "DZN": "Dozen",
    "DZP": "Dozen Pack",
    "DPR": "Dozen Pair",
    "DPC": "Dozen Piece",
    "DRL": "Dozen Roll",
    "DRM": "Drachm (Uk)",
    "D8": "Draize Score",
    "DRI": "Dram (UK)",
    "DRA": "Dram (US)",
    "DR": "Drum",
    "BLD": "Dry Barrel (Us)",
    "GLD": "Dry Gallon (Us)",
    "PTD": "Dry Pint (US)",
    "DB": "Dry Pound",
    "DT": "Dry Ton",
    "A50": "Dyn Second Per Cubic Centimetre",
    "DU": "Dyne",
    "DX": "Dyne Per Centimetre",
    "D9": "Dyne Per Square Centimeter",
    "A51": "Dyne Second Per Centimetre",
    "A52": "Dyne Second Per Centimetre To The Fifth",
    "EA": "Each",
    "EC": "Each Per Month",
    "P8": "Eight Pack",
    "EB": "Electronic Mail Box",
    "A53": "Electronvolt",
    "A54": "Electronvolt Per Metre",
    "A55": "Electronvolt Square Metre",
    "A56": "Electronvolt Square Metre Per Kilogram",
    "EP": "Eleven Pack",
    "ELU": "ELISA Units",
    "1E": "Empty Car",
    "EV": "Envelope",
    "EQ": "Equivalent Gallon",
    "A57": "Erg",
    "A58": "Erg Per Centimetre",
    "A60": "Erg Per Cubic Centimetre",
    "A61": "Erg Per Gram",
    "A62": "Erg Per Gram Second",
    "A63": "Erg Per Second",
    "A64": "Erg Per Second Square Centimetre",
    "A65": "Erg Per Square Centimetre Second",
    "A66": "Erg Square Centimetre",
    "A67": "Erg Square Centimetre Per Gram",
    "A68": "Exajoule",
    "63": "Failure Rate In Time",
    "FAR": "Farad",
    "A69": "Farad Per Metre",
    "AK": "Fathom",
    "A70": "Femtojoule",
    "Q32": "Femtolitre",
    "A71": "Femtometre",
    "F9": "Fibre Per Cubic Centimetre Of Air",
    "FB": "Field",
    "98": "Fifteen Kg Drum",
    "47": "Fifty Lb Bag",
    "46": "Fifty Lb Bulk Bag",
    "18": "Fiftyfive Gallon (Us) Drum",
    "P5": "Five Pack",
    "44": "Fivehundred Kg Bulk Bag",
    "1I": "Fixed Rate",
    "FL": "Flake Ton",
    "OZI": "Fluid ounce (UK)",
    "OZA": "Fluid ounce (US)",
    "FOT": "Foot",
    "FR": "Foot Per Minute",
    "FS": "Foot Per Second",
    "A73": "Foot Per Second Squared",
    "85": "Foot Pound-Force",
    "A74": "Foot Pound-Force Per Second",
    "21": "Forty Foot Container",
    "P4": "Four Pack",
    "A75": "Freight Ton",
    "H79": "French gauge",
    "1G": "Fuel Usage Gallon (Us)",
    "GZ": "Gage System",
    "A76": "Gal",
    "GLI": "Gallon (UK)",
    "GLL": "Gallon (US)",
    "GB": "Gallon (Us) Per Day",
    "GW": "Gallon Per Thousand Cubic Feet",
    "5C": "Gallon(Us) Per Thousand",
    "AWG": "Gauge",
    "76": "Gauss",
    "A77": "Gaussian Cgs Unit Of Displacement",
    "A78": "Gaussian Cgs Unit Of Electic Current",
    "A79": "Gaussian Cgs Unit Of Electric Charge",
    "A80": "Gaussian Cgs Unit Of Electric Field Strength",
    "A81": "Gaussian Cgs Unit Of Electric Polarization",
    "A82": "Gaussian Cgs Unit Of Electric Potential",
    "A83": "Gaussian Cgs Unit Of Magnetization",
    "GBQ": "Gigabecquerel",
    "E34": "Gigabyte",
    "A84": "Gigacoulomb Per Cubic Metre",
    "A85": "Gigaelectronvolt",
    "A86": "Gigahertz",
    "GV": "Gigajoule",
    "A87": "Gigaohm",
    "A88": "Gigaohm Metre",
    "A89": "Gigapascal",
    "A90": "Gigawatt",
    "GWH": "Gigawatt hour",
    "GII": "Gill (Uk)",
    "GIA": "Gill (Us)",
    "A91": "Gon",
    "GRN": "Grain",
    "GRM": "Gram",
    "GFI": "Gram Of Fissile Isotope",
    "GC": "Gram Per 100 Gram",
    "A93": "Gram Per Cubic Metre",
    "F27": "Gram Per Hour",
    "GK": "Gram Per Kilogram",
    "GL": "Gram Per Litre",
    "GF": "Gram Per Metre (Gram Per 100 Centimetres)",
    "GJ": "Gram Per Millilitre",
    "A94": "Gram Per Mole",
    "25": "Gram Per Square Centimetre",
    "GM": "Gram per square metre",
    "23": "Grams Per Cubic Centimetre",
    "A95": "Gray",
    "A96": "Gray Per Second",
    "GGR": "Great Gross",
    "GRO": "Gross",
    "GD": "Gross Barrel",
    "GN": "Gross Gallon",
    "E4": "Gross kilogram",
    "GRT": "Gross Register Ton",
    "GT": "Gross Ton",
    "GY": "Gross Yard",
    "10": "Group",
    "HD": "Half dozen",
    "GH": "Half Gallon (Us)",
    "HT": "Half Hour",
    "H2": "Half Litre",
    "H1": "Half Page",
    "PV": "Half Pint (Us)",
    "SAN": "Half Year (6 Months)",
    "HA": "Hank",
    "8": "Heat Lot",
    "D55": "Heat Transfer Coefficient",
    "HAR": "Hectare",
    "HBA": "Hectobar",
    "HGM": "Hectogram",
    "HLT": "Hectolitre",
    "HPA": "Hectolitre Of Pure Alcohol",
    "HMT": "Hectometre",
    "A97": "Hectopascal",
    "81": "Henry",
    "A98": "Henry Per Metre",
    "HTZ": "Hertz",
    "HEP": "Histamine Equivalent Prick",
    "Z4": "Hogshead",
    "30": "Horse Power Day Per Air Dry Metric Ton",
    "HUR": "Hour",
    "CEN": "Hundred",
    "BP": "Hundred board foot",
    "HBX": "Hundred Boxe",
    "HC": "Hundred count",
    "HH": "Hundred Cubic Feet",
    "FF": "Hundred Cubic Metre",
    "HF": "Hundred Feet",
    "HL": "Hundred Feet (Linear)",
    "16": "Hundred Fifteen Kg Drum",
    "HIU": "Hundred International Unit",
    "HK": "Hundred Kilogram",
    "17": "Hundred Lb Drum",
    "CLF": "Hundred Leave",
    "YL": "Hundred Linear Yard",
    "CNP": "Hundred Pack",
    "CWA": "Hundred pound (cwt) / hundred weight (US)",
    "HI": "Hundred Sheet",
    "HS": "Hundred Square Feet",
    "HO": "Hundred Troy Ounce",
    "CWI": "Hundred weight (UK)",
    "HY": "Hundred Yard",
    "HE": "Hundredth Of A Carat",
    "5J": "Hydraulic Horse Power",
    "G3": "Imperial Gallon Per Minute",
    "IM": "Impression",
    "IL": "Inch Per Minute",
    "IU": "Inch Per Second (Linear Speed)",
    "IV": "Inch Per Second Squared (Acceleration)",
    "IA": "Inch Pound (Pound Inch)",
    "D69": "Inch To The Fourth Power",
    "INH": "Inches",
    "IF": "Inches Of Water",
    "IP": "Insurance Policy",
    "D75": "International Table (It) Calorie Per Gram",
    "D76": "International Table (It) Calorie Per Gram Kelvin",
    "D71": "International Table (It) Calorie Per Second Centimetre Kelvin",
    "D72": "International Table (It) Calorie Per Second Square Centimetre Kelvin",
    "JR": "Jar",
    "JO": "Joint",
    "JOU": "Joule",
    "B8": "Joule Per Cubic Metre",
    "D95": "Joule Per Gram",
    "JE": "Joule Per Kelvin",
    "J2": "Joule Per Kilogram",
    "B11": "Joule Per Kilogram Kelvin",
    "B12": "Joule Per Metre",
    "B14": "Joule Per Metre To The Fourth Power",
    "B15": "Joule Per Mole",
    "B16": "Joule Per Mole Kelvin",
    "B13": "Joule Per Square Metre",
    "B18": "Joule Second",
    "D73": "Joule Square Metre",
    "B20": "Joule Square Metre Per Kilogram",
    "JG": "Jug",
    "JB": "Jumbo",
    "KIU": "Kallikrein inactivator unit.",
    "KG": "Keg",
    "KEL": "Kelvin",
    "B21": "Kelvin Per Watt",
    "2Q": "Kilo Becquerel",
    "B22": "Kiloampere",
    "TAH": "Kiloampere Hour (Thousand Ampere Hour)",
    "B24": "Kiloampere Per Metre",
    "B23": "Kiloampere Per Square Metre",
    "KBA": "Kilobar",
    "B25": "Kilobecquerel Per Kilogram",
    "2P": "Kilobyte",
    "E14": "Kilocalorie (international table)",
    "KB": "Kilocharacter",
    "B26": "Kilocoulomb",
    "B27": "Kilocoulomb Per Cubic Metre",
    "B28": "Kilocoulomb Per Square Metre",
    "2R": "Kilocurie",
    "B29": "Kiloelectronvolt",
    "78": "Kilogauss",
    "KGM": "Kilogram",
    "KD": "Kilogram Decimal",
    "B31": "Kilogram Metre Per Second",
    "B32": "Kilogram Metre Squared",
    "B33": "Kilogram Metre Squared Per Second",
    "KNS": "Kilogram Named Substance",
    "KNI": "Kilogram Of Nitrogen",
    "KPP": "Kilogram Of Phosphorus Pentoxide (Phosphoric Anhydride)",
    "KPH": "Kilogram Of Potassium Hydroxide (Caustic Potash)",
    "KPO": "Kilogram Of Potassium Oxide",
    "KSH": "Kilogram Of Sodium Hydroxide (Caustic Soda)",
    "KSD": "Kilogram Of Substance 90 % Dry",
    "KUR": "Kilogram Of Uranium",
    "32": "Kilogram Per Air Dry Metric Ton",
    "B34": "Kilogram Per Cubic Decimetre",
    "KMQ": "Kilogram Per Cubic Metre",
    "3H": "Kilogram Per Kilogram Of Product",
    "B35": "Kilogram Per Litre",
    "KL": "Kilogram Per Metre",
    "KI": "Kilogram Per Millimetre Width",
    "D74": "Kilogram Per Mole",
    "3I": "Kilogram Per Piece Of Product",
    "KGS": "Kilogram Per Second",
    "D5": "Kilogram per square centimetre",
    "28": "Kilogram per square metre",
    "B37": "Kilogram-Force",
    "B38": "Kilogram-Force Metre",
    "B39": "Kilogram-Force Metre Per Second",
    "B40": "Kilogram-Force Per Square Metre",
    "KW": "Kilograms Per Millimeter",
    "KHZ": "Kilohertz",
    "KJO": "Kilojoule",
    "B41": "Kilojoule Per Kelvin",
    "B42": "Kilojoule Per Kilogram",
    "B43": "Kilojoule Per Kilogram Kelvin",
    "B44": "Kilojoule Per Mole",
    "K6": "Kilolitre",
    "4X": "Kilolitre Per Hour",
    "KMT": "Kilometre",
    "KTM": "Kilometre",
    "KMH": "Kilometre Per Hour",
    "B45": "Kilomole",
    "B46": "Kilomole Per Cubic Metre",
    "B47": "Kilonewton",
    "B48": "Kilonewton Metre",
    "KNM": "Kilonewton Per Square Metre",
    "B49": "Kiloohm",
    "B50": "Kiloohm Metre",
    "KF": "Kilopacket",
    "KPA": "Kilopascal",
    "33": "Kilopascal Square Metres Per Gram",
    "34": "Kilopascals Per Millimetre",
    "B51": "Kilopond",
    "84": "Kilopound Per Square Inch",
    "KR": "Kiloröntgen",
    "B52": "Kilosecond",
    "KJ": "Kilosegment",
    "B53": "Kilosiemens",
    "B54": "Kilosiemens Per Metre",
    "KTN": "Kilotonne",
    "KVR": "Kilovar",
    "KVA": "Kilovolt",
    "KVT": "Kilovolt",
    "K5": "Kilovolt Ampere (Reactive)",
    "K2": "Kilovolt Ampere Reactive Demand",
    "K3": "Kilovolt Ampere Reactive Hour",
    "B55": "Kilovolt Per Metre",
    "KWT": "Kilowatt",
    "K1": "Kilowatt Demand",
    "KWH": "Kilowatt hour",
    "B56": "Kiloweber Per Metre",
    "KT": "Kit",
    "KNT": "Knot",
    "X_KVN": "Korsakovian (K)",
    "LH": "Labour Hour",
    "LJ": "Large Spray",
    "LR": "Layer",
    "LEF": "Leaf",
    "LN": "Length",
    "5": "Lift",
    "Z1": "Lift Van",
    "B57": "Light Year",
    "LC": "Linear Centimetre",
    "LF": "Linear foot",
    "LI": "Linear Inch",
    "LM": "Linear metre",
    "LY": "Linear Yard",
    "LX": "Linear Yard Per Pound",
    "LK": "Link",
    "PTL": "Liquid pint (US)",
    "LP": "Liquid Pound",
    "QTL": "Liquid quart (US)",
    "LE": "Lite",
    "LTR": "Litre",
    "LPA": "Litre Of Pure Alcohol",
    "LD": "Litre Per Day",
    "E32": "Litre Per Hour",
    "L2": "Litre Per Minute",
    "B58": "Litre Per Mole",
    "NL": "Load",
    "1C": "Locomotive Count",
    "1K": "Locomotive Mile",
    "LO": "Lot",
    "Z5": "Lug",
    "LUM": "Lumen",
    "B59": "Lumen Hour",
    "B61": "Lumen Per Watt",
    "B62": "Lumen Second",
    "B60": "Lumens per Square Meter",
    "LS": "Lump Sum",
    "LUX": "Lux",
    "B63": "Lux Hour",
    "B64": "Lux Second",
    "MA": "Machine Per Unit",
    "M0": "Magnetic Tape",
    "3C": "Manmonth",
    "D98": "Mass Pound",
    "MT": "Mat",
    "B65": "Maxwell",
    "Q3": "Meal",
    "MAL": "Mega Litre",
    "B66": "Megaampere Per Square Metre",
    "4N": "Megabecquerel",
    "B67": "Megabecquerel Per Kilogram",
    "4L": "Megabyte",
    "D77": "Megacoulomb",
    "B69": "Megacoulomb Per Cubic Metre",
    "B70": "Megacoulomb Per Square Metre",
    "B71": "Megaelectronvolt",
    "2U": "Megagram",
    "B72": "Megagram Per Cubic Metre",
    "2V": "Megagram Per Hour",
    "MHZ": "Megahertz",
    "3B": "Megajoule",
    "JM": "Megajoule Per Cubic Metre",
    "JK": "Megajoule Per Kilogram",
    "MAM": "Megametre",
    "B73": "Meganewton",
    "B74": "Meganewton Metre",
    "B75": "Megaohm",
    "B76": "Megaohm Metre",
    "MPA": "Megapascal",
    "B77": "Megasiemens Per Metre",
    "B78": "Megavolt",
    "MVA": "Megavolt – Ampere",
    "B79": "Megavolt Per Metre",
    "MAW": "Megawatt",
    "MWH": "Megawatt hour (1000 kW.h)",
    "57": "Mesh",
    "NF": "Message",
    "NH": "Message Hour",
    "MTR": "Metre",
    "D18": "Metre Kelvin",
    "2X": "Metre Per Minute",
    "MTS": "Metre Per Second",
    "MSK": "Metre Per Second Squared",
    "B83": "Metre To The Fourth Power",
    "CTM": "Metric Carat",
    "HJ": "Metric Horse Power",
    "E5": "Metric Long Ton",
    "NQ": "Mho",
    "B84": "Microampere",
    "B85": "Microbar",
    "B86": "Microcoulomb",
    "B87": "Microcoulomb Per Cubic Metre",
    "B88": "Microcoulomb Per Square Metre",
    "M5": "Microcurie",
    "4O": "Microfarad",
    "B89": "Microfarad Per Metre",
    "G7": "Microfiche Sheet",
    "MC": "Microgram",
    "GQ": "Microgram Per Cubic Meter",
    "B90": "Microhenry",
    "B91": "Microhenry Per Metre",
    "M7": "Micro-Inch",
    "4G": "Microlitre",
    "4H": "Micrometre",
    "NR": "Micromho",
    "FH": "Micromole",
    "B92": "Micronewton",
    "B93": "Micronewton Metre",
    "B94": "Microohm",
    "B95": "Microohm Metre",
    "B96": "Micropascal",
    "B97": "Microradian",
    "B98": "Microsecond",
    "B99": "Microsiemens",
    "D81": "Microtesla",
    "D82": "Microvolt",
    "C3": "Microvolt Per Metre",
    "D80": "Microwatt",
    "D85": "Microwatt Per Square Metre",
    "SMI": "Mile (statute mile)",
    "HM": "Mile Per Hour",
    "X_MLM": "Millesimai (LM)",
    "4K": "Milliampere",
    "E09": "Milliampere hour",
    "MLD": "Milliard",
    "MBR": "Millibar",
    "D86": "Millicoulomb",
    "D88": "Millicoulomb Per Cubic Metre",
    "C8": "Millicoulomb Per Kilogram",
    "D89": "Millicoulomb Per Square Metre",
    "MCU": "Millicurie",
    "MEQ": "Milliequivalents",
    "C10": "Millifarad",
    "C11": "Milligal",
    "MGM": "Milligram",
    "GP": "Milligram Per Cubic Metre",
    "4M": "Milligram Per Hour",
    "NA": "Milligram Per Kilogram",
    "C12": "Milligram Per Metre",
    "MF": "Milligram Per Square Foot Per Side",
    "MK": "Milligram Per Square Inch",
    "M1": "Milligrams Per Litre",
    "GO": "Milligrams Per Square Metre",
    "C13": "Milligray",
    "C14": "Millihenry",
    "77": "Milli-Inch",
    "C15": "Millijoule",
    "MLT": "Millilitre",
    "WW": "Millilitre Of Water",
    "KX": "Millilitre Per Kilogram",
    "41": "Millilitre Per Minute",
    "40": "Millilitre Per Second",
    "35": "Millilitres Per Square Centimetre Second",
    "MMT": "Millimetre",
    "C16": "Millimetre Per Second",
    "C17": "Millimetre Squared Per Second",
    "C18": "Millimole",
    "D87": "Millimole Per Kilogram",
    "C20": "Millinewton",
    "D83": "Millinewton Metre",
    "C22": "Millinewton Per Metre",
    "C23": "Milliohm Metre",
    "MIO": "Million",
    "M9": "Million Btu Per 1000 Cubic Feet",
    "BZ": "Million Btus",
    "FM": "Million Cubic Feet",
    "HMQ": "Million Cubic Metre",
    "MIU": "Million International Unit (NIE)",
    "FD": "Million Particle Per Cubic Foot",
    "UM": "Million Unit",
    "74": "Millipascal",
    "C24": "Millipascal Second",
    "C25": "Milliradian",
    "2Y": "Milliröntgen",
    "C26": "Millisecond",
    "C27": "Millisiemens",
    "C28": "Millisievert",
    "C29": "Millitesla",
    "2Z": "Millivolt",
    "D49": "Millivolt Per Kelvin",
    "C30": "Millivolt Per Metre",
    "C31": "Milliwatt",
    "C32": "Milliwatt Per Square Metre",
    "C33": "Milliweber",
    "D61": "Minute",
    "MIN": "Minute (unit of time)",
    "5E": "Mmscf/Day",
    "C34": "Mole",
    "C35": "Mole Per Cubic Decimetre",
    "C36": "Mole Per Cubic Metre",
    "C19": "Mole Per Kilogram",
    "C38": "Mole Per Litre",
    "M4": "Monetary Value",
    "MON": "Month",
    "MPN": "Most Probable Number",
    "X_MTC": "Mother tincture (Dry material)",
    "ZZ": "Mutually Defined",
    "C39": "Nanoampere",
    "C40": "Nanocoulomb",
    "C41": "Nanofarad",
    "C42": "Nanofarad Per Metre",
    "X_NGM": "Nanogram",
    "C43": "Nanohenry",
    "C44": "Nanohenry Per Metre",
    "Q34": "Nanolitre",
    "C45": "Nanometre",
    "C46": "Nanoohm Metre",
    "C47": "Nanosecond",
    "C48": "Nanotesla",
    "C49": "Nanowatt",
    "NMI": "Nautical Mile",
    "C50": "Neper",
    "C51": "Neper Per Second",
    "ND": "Net Barrel",
    "NG": "Net Gallon (Us)",
    "NI": "Net Imperial Gallon",
    "58": "Net kilogram",
    "NE": "Net Litre",
    "NTT": "Net Register Ton",
    "NT": "Net Ton",
    "Z8": "Newspage Agate Line",
    "NEW": "Newton",
    "NU": "Newton Metre",
    "C53": "Newton Metre Second",
    "C54": "Newton Metre Squared Kilogram Squared",
    "4P": "Newton Per Metre",
    "C55": "Newton Per Square Metre",
    "C56": "Newton Per Square Millimetre",
    "C57": "Newton Second",
    "C58": "Newton Second Per Metre",
    "P9": "Nine Pack",
    "NAR": "Number Of Articles",
    "NBB": "Number Of Bobbins",
    "NCL": "Number Of Cells",
    "NIU": "Number of International Units",
    "N2": "Number Of Lines",
    "MV": "Number Of Mults",
    "NMP": "Number Of Packs",
    "NPR": "Number Of Pairs",
    "NPL": "Number Of Parcels",
    "NPT": "Number Of Parts",
    "NRL": "Number Of Rolls",
    "NJ": "Number Of Screens",
    "C59": "Octave",
    "66": "Oersted",
    "OHM": "Ohm",
    "C60": "Ohm Centimetre",
    "C61": "Ohm Metre",
    "C62": "One",
    "OPM": "Oscillations Per Minute",
    "ONZ": "Ounce",
    "OZ": "Ounce Av",
    "4R": "Ounce Foot",
    "4Q": "Ounce Inch",
    "37": "Ounce Per Square Foot",
    "38": "Ounces Per Square Foot Per 0,01 Inch",
    "ON": "Ounces per square yard",
    "11": "Outfit",
    "OT": "Overtime Hour",
    "PK": "Package",
    "PA": "Packet",
    "PD": "Pad",
    "ZP": "Page",
    "P0": "Page - Electronic",
    "QA": "Page - Facsimile",
    "QB": "Page - hardcopy",
    "PQ": "Page Per Inch",
    "PL": "Pail",
    "PR": "Pair",
    "PB": "Pair Inch",
    "PF": "Pallet (Lift)",
    "D97": "Pallet/Unit Load",
    "OA": "Panel",
    "C63": "Parsec",
    "61": "Part Per Billion (Us)",
    "59": "Part per million",
    "NX": "Part Per Thousand",
    "PAL": "Pascal",
    "C64": "Pascal Per Kelvin",
    "C65": "Pascal Second",
    "C66": "Pascal Second Per Cubic Metre",
    "C67": "Pascal Second Per Metre",
    "G23": "Peck",
    "PZ": "Peck Dry (Uk)",
    "PY": "Peck Dry (Us)",
    "N1": "Pen Calorie",
    "D23": "Pen Gram (Protein)",
    "DWT": "Pennyweight",
    "P1": "Percent",
    "62": "Percent Per 1000 Hour",
    "60": "Percent Weight",
    "IE": "Person",
    "C68": "Petajoule",
    "Q30": "pH (potential of Hydrogen)",
    "C69": "Phon",
    "R1": "Pica",
    "C70": "Picoampere",
    "C71": "Picocoulomb",
    "4T": "Picofarad",
    "C72": "Picofarad Per Metre",
    "C73": "Picohenry",
    "Q33": "Picolitre",
    "C52": "Picometre",
    "C75": "Picowatt",
    "C76": "Picowatt Per Square Metre",
    "H87": "Piece",
    "PCE": "Piece",
    "PTI": "Pint (UK)",
    "PT": "Pint (Us)",
    "PI": "Pitch",
    "E37": "Pixel",
    "X_PPC": "Pixel per centimetre",
    "X_PPI": "Pixel per inch",
    "PFU": "Plaque Forming unit(s)",
    "PG": "Plate",
    "PNT": "Point",
    "89": "Poise",
    "PTN": "Portion",
    "PRS": "Potential Renal Solute Load",
    "LBR": "Pound",
    "PE": "Pound Equivalent",
    "C77": "Pound Gage",
    "D96": "Pound Gross",
    "PN": "Pound Net",
    "NY": "Pound Per Air Dry Metric Ton",
    "87": "Pound Per Cubic Foot",
    "LA": "Pound Per Cubic Inch",
    "P2": "Pound Per Foot",
    "GE": "Pound Per Gallon (Us)",
    "4U": "Pound Per Hour",
    "PO": "Pound Per Inch Of Length",
    "PW": "Pound Per Inch Of Width",
    "3G": "Pound Per Piece Of Product",
    "3E": "Pound Per Pound Of Product",
    "RP": "Pound Per Ream",
    "FP": "Pound per square foot",
    "80": "Pound per square inch - Absolute",
    "64": "Pound per square inch - Gauge",
    "29": "Pound Per Thousand Square Feet",
    "PM": "Pound Percentage",
    "C78": "Pound-Force",
    "PS": "Pound-force per square inch",
    "5F": "Pounds Per Thousand",
    "AW": "Powder Filled Vial",
    "N3": "Print Point",
    "PGL": "Proof Gallon",
    "5G": "Pump",
    "QTI": "Quart (Uk)",
    "QTD": "Quart (US dry)",
    "QT": "Quart (Us)",
    "QAN": "Quarter (Of A Year)",
    "QTR": "Quarter (Uk)",
    "QD": "Quarter Dozen",
    "QH": "Quarter Hour",
    "QK": "Quarter Kilogram",
    "1X": "Quarter Mile",
    "QR": "Quire",
    "RA": "Rack",
    "C80": "Rad",
    "C81": "Radian",
    "C82": "Radian Meter Squared Per Mole",
    "C83": "Radian Metre Squared Per Kilogram",
    "C84": "Radian Per Metre",
    "2A": "Radian Per Second",
    "2B": "Radian Per Second Squared",
    "A9": "Rate",
    "13": "Ration",
    "RM": "Ream",
    "RN": "Ream Metric Measure",
    "C85": "Reciprocal",
    "C86": "Reciprocal Cubic Metre",
    "C87": "Reciprocal Cubic Metre Per Second",
    "C88": "Reciprocal Electron Volt Per Cubic Metre",
    "C89": "Reciprocal Henry",
    "C90": "Reciprocal Joule Per Cubic Metre",
    "C91": "Reciprocal Kelvin Or Kelvin To The Power Minus One",
    "C92": "Reciprocal Metre",
    "B81": "Reciprocal Metre Squared Reciprocal Second",
    "C94": "Reciprocal Minute",
    "C95": "Reciprocal Mole",
    "C96": "Reciprocal Pascal Or Pascal To The Power Minus One",
    "C97": "Reciprocal Second",
    "C98": "Reciprocal Second Per Cubic Metre",
    "C99": "Reciprocal Second Per Metre Squared",
    "D1": "Reciprocal Second Per Steradian",
    "D2": "Reciprocal Second Per Steradian Metre Squared",
    "C93": "Reciprocal Square Metre",
    "RL": "Reel",
    "D91": "Rem",
    "RS": "Reset",
    "XRE": "Retinol Equivalent (RE)",
    "RT": "Revenue Ton Mile",
    "RPM": "Revolutions Per Minute",
    "RPS": "Revolutions Per Second",
    "RG": "Ring",
    "RD": "Rod",
    "RO": "Roll",
    "RK": "Roll Metric Measure",
    "2C": "Röntgen",
    "D6": "Röntgen Per Second",
    "D65": "Round",
    "RU": "Run",
    "RH": "Running Or Operating Hour",
    "SA": "Sack",
    "X_SPS": "Sample per second",
    "D7": "Sandwich",
    "90": "Saybold Universal Second",
    "SCO": "Score",
    "SCR": "Scruple",
    "D62": "Second",
    "SEC": "Second (unit of time)",
    "D93": "Second Per Cubic Metre",
    "D94": "Second Per Radian Cubic Metre",
    "SE": "Section",
    "SG": "Segment",
    "5P": "Seismic Level",
    "5Q": "Seismic Line",
    "X_SER": "Serving",
    "S6": "Session",
    "SET": "Set",
    "P7": "Seven Pack",
    "ST": "Sheet",
    "SS": "Sheet Metric Measure",
    "SP": "Shelf Package",
    "SX": "Shipment",
    "SHT": "Shipping Ton",
    "SST": "Short Standard (7200 Matches)",
    "14": "Shot",
    "SIE": "Siemens",
    "D10": "Siemens Per Metre",
    "D12": "Siemens Square Metre Per Mole",
    "D13": "Sievert",
    "56": "Sitas",
    "P6": "Six Pack",
    "S5": "Sixty Fourths Of An Inch",
    "FJ": "Sizing Factor",
    "SW": "Skein",
    "SV": "Skid",
    "D99": "Sleeve",
    "SL": "Slipsheet",
    "4": "Small Spray",
    "SD": "Solid Pound",
    "D15": "Sone",
    "SK": "Split Tanktruck",
    "SO": "Spool",
    "SQE": "SQ-E",
    "SQ": "Square",
    "CMK": "Square centimetre",
    "D16": "Square Centimetre Per Erg",
    "D17": "Square Centimetre Per Steradian Erg",
    "DMK": "Square decimetre",
    "FTK": "Square foot",
    "S3": "Square Foot Per Second",
    "INK": "Square inch",
    "KMK": "Square Kilometre",
    "MTK": "Square metre",
    "D19": "Square metre kelvin per watt",
    "D20": "Square Metre Per Joule",
    "D21": "Square Metre Per Kilogram",
    "D22": "Square Metre Per Mole",
    "S4": "Square Metre Per Second",
    "D24": "Square Metre Per Steradian",
    "D25": "Square Metre Per Steradian Joule",
    "D26": "Square Metre Per Volt Second",
    "MIK": "Square mile",
    "MMK": "Square millimetre",
    "SN": "Square Rod",
    "YDK": "Square Yard",
    "5H": "Stage",
    "WSD": "Standard",
    "S8": "Standard Advertising Unit",
    "ATM": "Standard Atmosphere",
    "5I": "Standard Cubic Foot",
    "D27": "Steradian",
    "15": "Stick",
    "91": "Stokes",
    "STI": "Stone (Uk)",
    "S7": "Storage Unit",
    "SR": "Strip",
    "43": "Super Bulk Bag",
    "AR": "Suppository",
    "D28": "Syphon",
    "G24": "Tablespoon",
    "U2": "Tablet",
    "19": "Tank Truck",
    "TY": "Tank, Cylindrical",
    "TK": "Tank, Rectangular",
    "G25": "Teaspoon",
    "ATT": "Technical Atmosphere",
    "TPI": "Teeth Per Inch",
    "T0": "Telecommunication Line In Service",
    "UB": "Telecommunication Line In Service Average",
    "UC": "Telecommunication Port",
    "DAD": "Ten Day",
    "97": "Ten Kg Drum",
    "TP": "Ten Pack",
    "TPR": "Ten Pair",
    "TR": "Ten Square Feet",
    "TF": "Ten Square Yard",
    "96": "Ten Thousand Gallon (Us) Tankcar",
    "UH": "Ten Thousand Yard",
    "YT": "Ten Yard",
    "UE": "Tenth Hour",
    "UD": "Tenth Minute",
    "E35": "Terabyte",
    "D29": "Terahertz",
    "D30": "Terajoule",
    "D31": "Terawatt",
    "D32": "Terawatt hour",
    "D33": "Tesla",
    "69": "Test Specific Scale",
    "D34": "Tex",
    "KO": "The milliequivalence caustic potash per gram of product",
    "53": "Theoretical Kilograms",
    "24": "Theoretical Pound",
    "27": "Theoretical Ton",
    "54": "Theoretical Tonne",
    "TD": "Therm",
    "D35": "Thermochemical Calorie",
    "B36": "Thermochemical Calorie Per Gram",
    "D37": "Thermochemical Calorie Per Gram Kelvin",
    "D38": "Thermochemical Calorie Per Second Centimetre Kelvin",
    "D39": "Thermochemical Calorie Per Second Square Centimetre Kelvin",
    "MIL": "Thousand",
    "T4": "Thousand Bag",
    "MBF": "Thousand Board Feet",
    "T5": "Thousand Casing",
    "FC": "Thousand Cubic Feet",
    "F1": "Thousand Cubic Feet Per Day",
    "R9": "Thousand Cubic Metre",
    "TQD": "Thousand Cubic Metre Per Day",
    "TQ": "Thousand Feet",
    "TL": "Thousand Feet (Linear)",
    "T6": "Thousand Gallon (Us)",
    "T7": "Thousand Impression",
    "TV": "Thousand Kilogram",
    "T8": "Thousand Linear Inch",
    "TT": "Thousand Linear Metre",
    "D14": "Thousand Linear Yard",
    "D40": "Thousand Litre",
    "MQ": "Thousand Metre",
    "T3": "Thousand Piece",
    "T1": "Thousand Pound Gross",
    "KS": "Thousand Pound Per Square Inch",
    "TW": "Thousand Sheet",
    "TJ": "Thousand Square Centimeter",
    "TS": "Thousand Square Feet",
    "TI": "Thousand Square Inch",
    "MBE": "Thousand Standard Brick Equivalent",
    "P3": "Three Pack",
    "45": "Threehundred Kg Bulk Bag",
    "TN": "Tin",
    "LTN": "Ton (UK) or long ton (US)",
    "STN": "Ton (US) or short ton (UK)",
    "1J": "Ton Mile",
    "TSH": "Ton Of Steam Per Hour",
    "4W": "Ton(Us) Per Hour",
    "TNE": "Tonne",
    "TSD": "Tonne Of Substance 90 % Dry",
    "D41": "Tonne Per Cubic Metre",
    "UA": "Torr",
    "1L": "Total Car Count",
    "1M": "Total Car Mile",
    "TE": "Tote",
    "FE": "Track Foot",
    "E3": "Trailer",
    "NN": "Train",
    "1F": "Train Mile",
    "FG": "Transdermal Patch",
    "PU": "Tray / Tray Pack",
    "U1": "Treatment",
    "TRL": "Trillion (Eur)",
    "BIL": "Trillion (Us) (Note Bil = Billion In Uk)",
    "D42": "Tropical Year",
    "APZ": "Troy ounce or apothecary ounce",
    "LBT": "Troy Pound (Us)",
    "TC": "Truckload",
    "TU": "Tube",
    "20": "Twenty Foot Container",
    "4E": "Twenty Pack",
    "95": "Twenty Thousand Gallon (Us) Tankcar",
    "OP": "Two Pack",
    "W4": "Two Week",
    "G2": "Us Gallon Per Minute",
    "UF": "Usage Per Telecommunication Line Average",
    "E55": "Use",
    "D44": "Var",
    "NV": "Vehicle",
    "VI": "Vial",
    "VS": "Visit",
    "VLT": "Volt",
    "D46": "Volt - Ampere",
    "VA": "Volt Ampere Per Kilogram",
    "71": "Volt Ampere Per Pound",
    "D47": "Volt Per Centimetre",
    "D48": "Volt Per Kelvin",
    "D50": "Volt Per Metre",
    "D51": "Volt Per Millimetre",
    "D45": "Volt Squared Per Kelvin Squared",
    "WTT": "Watt",
    "WHR": "Watt hour",
    "D52": "Watt Per Kelvin",
    "WA": "Watt Per Kilogram",
    "D53": "Watt Per Metre Kelvin",
    "72": "Watt Per Pound",
    "D54": "Watt Per Square Metre",
    "D56": "Watt Per Square Metre Kelvin To The Fourth Power",
    "D57": "Watt Per Steradian",
    "D58": "Watt Per Steradian Square Metre",
    "WEB": "Weber",
    "D59": "Weber Per Metre",
    "D60": "Weber Per Millimetre",
    "WEE": "Week",
    "WI": "Weight Per Square Inch",
    "W2": "Wet Kilo",
    "WB": "Wet Pound",
    "WE": "Wet Ton",
    "WH": "Wheel",
    "WG": "Wine Gallon",
    "WM": "Working Month",
    "WR": "Wrap",
    "YRD": "Yard",
}
