import React, {useContext, useMemo, useState} from "react";
import {ResponsiveBar} from '@nivo/bar'
import {ResponsivePie} from '@nivo/pie'
import useAxios from "axios-hooks";
import CustomLoader from "../../components/CustomLoader";
import ContainerDimensions from "react-container-dimensions";
import {parseName, reverseParseName} from "../../utils/Utils";
import {splitCbvValues} from "../../utils/CbvUtils";
import {ThemeContext} from "../../contexts";
import _ from 'lodash'
import HeaderPanelComponent from "./HeaderPanelComponent";
import chroma from "chroma-js";
import Grid from "@material-ui/core/Grid";
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from 'react-i18next'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
const WhyChart = () => {
    const { t } = useTranslation();

    const {primaryColor, primaryColorAlpha, secondaryColor, accentColor, accentColorAlpha, secondAccentColor} = useContext(ThemeContext)
    const [topNumberToDisplay, setTopNumberToDisplay] = useState(10)
    const [pieGraphMode, setPieGraphMode] = useState(true)

    const [{data: _4wPanelCounter, loading, error}] = useAxios({
            url: "api/capture/epcis_events/dashboard_4w_counters"
        },
        {useCache: false}
    )

    const chartData = useMemo(() => {
        if (!_4wPanelCounter)
            return null
        const whyPanel = _4wPanelCounter.why_panel

        let _chartData = _.sortBy(whyPanel.map((why, index) => {
            return {
                /*eventName: parseName(why._id[0]),
                bizStep: splitCbvValues(why._id[1]).replace(/_/g, " "),
                eventsCount: why.events_count,
                itemsCount: why.items_count*/
                id: parseName(why._id[0]),
                bizStep: splitCbvValues(why._id[1]).replace(/_/g, " "),
                value: why.events_count,
                eventsCount: why.events_count,
                itemsCount: why.items_count
                //color: _colorScale[index]
            }
        }), 'value')

        return _chartData
    }, [_4wPanelCounter])

    const colorScale = useMemo(() => {
        if (!chartData)
            return null

        const length = topNumberToDisplay > chartData.length ? chartData.length : topNumberToDisplay
        return chroma.scale([primaryColorAlpha, chroma(secondAccentColor).alpha(0.35).hex(), accentColorAlpha]).padding(-0.15).colors(length)
    }, [chartData, topNumberToDisplay])

    const topChartData = useMemo(() => {
        if (!chartData)
            return null

        const length = topNumberToDisplay > chartData.length ? chartData.length : topNumberToDisplay
        return _.slice(chartData, chartData.length - length, chartData.length)
    }, [chartData, topNumberToDisplay])

    const CustomTooltip = ({value, data, color}) => {
        return (
            <Grid container alignItems={'center'} alignContent={'center'}>
                <Grid item xs={2}>
                    <BizStepIcon color={chroma(color ? color : primaryColor).alpha(0.8).hex()} bizstep={reverseParseName(data.bizStep || '')} />
                </Grid>
                <Grid item xs={10}>
                    <Grid container>
                        <Grid item xs={12} style={{color: chroma(color ? color : accentColor).alpha(1).hex()}}>
                            <strong>{data.id || ''}</strong>
                        </Grid>
                        <Grid item xs={12}>
                            {t('entities.eventWithCount', {count: value})}
                        </Grid>
                        <Grid item xs={12}>
                            {t('entities.itemWithCount', {count: data.itemsCount || 0})} {t('entities.involved', {count: data.itemsCount || 0})}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <ContainerDimensions>
            {({width, height}) =>
                <div style={{height: height * 0.9}}>
                    <HeaderPanelComponent title={t('landingPage.panelTitles.bizStepOverall')}
                                          pieGraphMode={pieGraphMode}
                                          setPieGraphMode={setPieGraphMode}
                                          topNumberToDisplay={topNumberToDisplay}
                                          setTopNumberToDisplay={setTopNumberToDisplay}/>
                    {
                        colorScale && topChartData ?
                            !pieGraphMode ?
                                <ResponsiveBar
                                    data={topChartData}
                                    tooltip={({value, data}) => (
                                        <CustomTooltip value={value} data={data}/>
                                    )}
                                    /*theme={{
                                        tooltip: {
                                            container: {
                                                background: primaryColor,
                                            },
                                        },
                                    }}*/
                                    groupMode={'grouped'}
                                    layout={'horizontal'}
                                    keys={['eventsCount']}
                                    //keys={['eventsCount', 'itemsCount']}
                                    indexBy={'id'}
                                    margin={{top: 50, right: 30, bottom: 75, left: 200}}
                                    padding={0.45}
                                    valueScale={{type: 'symlog'}}
                                    //indexScale={{type: 'band', round: true}}
                                    //colors={{scheme: 'nivo'}}
                                    colors={[chroma(accentColor).alpha(0.5).hex()]}
                                    borderColor={{from: 'color', modifiers: [['darker', 1.2]]}}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 0,
                                        tickPadding: 10,
                                        tickRotation: 90,
                                        /*legend: 'Count',
                                        legendPosition: 'middle',
                                        legendOffset: 32*/
                                    }}
                                    axisLeft={{
                                        tickSize: 10,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                        /*legend: 'Event Name',
                                        legendPosition: 'end',
                                        legendOffset: 20*/
                                    }}
                                    enableGridX={true}
                                    enableGridY={false}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    //labelTextColor={{from: 'color', modifiers: [['opacity', 0]]}}
                                    labelTextColor={secondaryColor}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'top',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 0,
                                            translateY: -40,
                                            itemsSpacing: 10,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolShape: 'circle',
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                />
                                : <ResponsivePie
                                    data={topChartData}
                                    margin={{top: 40, right: 150, bottom: 60, left: topNumberToDisplay >= 20 ? 150 : 100}}
                                    sortByValue={false}
                                    startAngle={topNumberToDisplay >= 20 ? undefined : 180}
                                    endAngle={topNumberToDisplay >= 20 ? undefined : -45}
                                    innerRadius={0.7}
                                    padAngle={2}
                                    cornerRadius={2}
                                    colors={colorScale}
                                    //colors={{ datum: 'data.color' }}
                                    borderWidth={1}
                                    borderColor={{from: 'color', modifiers: [['darker', 0.1]]}}
                                    radialLabelsLinkStrokeWidth={3}
                                    radialLabelsSkipAngle={11}
                                    radialLabelsLinkDiagonalLength={18}
                                    radialLabelsLinkHorizontalLength={18}
                                    radialLabelsTextColor="#333333"
                                    /*radialLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [['darker', 4]],
                                    }}*/
                                    radialLabel={d => `${d.id}`}
                                    radialLabelsLinkColor={{from: 'color'}}
                                    //enableSliceLabels={false}
                                    sliceLabelsSkipAngle={15}
                                    sliceLabelsTextColor="#333333"
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    tooltip={(props) => (
                                        <Paper style={{padding: '8px'}}>
                                            <CustomTooltip
                                                value={props.datum.value}
                                                color={props.datum.color}
                                                data={props.datum.data} />
                                        </Paper>
                                    )}
                                    /*legends={[
                                        {
                                            anchor: 'right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: -20,
                                            translateY: 0,
                                            itemsSpacing: 20,
                                            itemWidth: 100,
                                            itemHeight: 30,
                                            itemTextColor: '#999',
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 1,
                                            symbolSize: 18,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}*/
                                />
                            : loading ? <CustomLoader size={80} />
                            : error ? <p>Error</p> : <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>

                    }
                </div>
            }
        </ContainerDimensions>
    )
}

export default WhyChart

