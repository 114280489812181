import {Box, Collapse, TextField} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import React, {useContext, useState} from "react";
import useAxios from "axios-hooks";
import * as yup from "yup";
import {ThemeContext} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {severityTypes} from "../../utils/Utils";

const ChangePasswordForm = ({myProfileId, openChangePassword, setOpenChangePassword}) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        old_pswd: yup.string().required(t('errors.currentPasswordRequired')),
        new_pswd: yup.string().required(t('errors.newPasswordRequired')),
        repeat_new_pswd: yup.string().oneOf([yup.ref('new_pswd')], t('errors.passwordsMustMatch'))
    })

    const _theme = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        button: {
            backgroundColor: _theme.accentColor,
            width: '25%',
            color: "white",
            fontWeight: 'bold',
            borderRadius: '20px',
        }
    }));
    const classes = useStyles();

    const { control, handleSubmit, errors, reset } = useForm({
        validationSchema:schema,
        defaultValues: {
            old_pswd: '',
            new_pswd: '',
            repeat_new_pswd: '',
        }
    })

    const [openAlertMessage, setOpenAlertMessage] = useState({open: false, message:'', severity: severityTypes.WARNING});
    const handleCloseErrorMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage({...openAlertMessage, open: false});
        if(openAlertMessage.severity === severityTypes.SUCCESS)
            setOpenChangePassword(false);
    };

    const [, postExecute] = useAxios(
        {
            url: "users/me/_change_password/",
            method: "PATCH",
            headers: {'Media-Type': 'application/json'}
        },
        {manual: true}
    )

    const onSubmit = (_data) => {
        postExecute({data: {..._data, _id: myProfileId}})
            .then((res) => {
                setTimeout(function(){
                    setOpenAlertMessage({
                        open: true,
                        message: t('profile.changePasswordForm.passwordUpdated'),
                        severity: severityTypes.SUCCESS})
                    reset()
                    }, 300)
            })
            .catch((err) => {
                console.log('error changing password:', err)
                setOpenAlertMessage({
                    open: true,
                    message: err.response.data.detail ? t('errors.wrongPassword') : t('errors.genericServerError'),
                    severity: severityTypes.ERROR
                })
            })
    };

    const [showPassword, setShowPassword] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Collapse in={openChangePassword} unmountOnExit>
            <Box p={1} mb={2} width={'70%'} marginLeft={'auto'}>
                {/*<Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openAlertMessage.open}
                           autoHideDuration={3000} onClose={handleCloseErrorMessage}>
                    <Alert onClose={handleCloseErrorMessage}
                           severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
                </Snackbar>*/}
                <Collapse in={!!openAlertMessage.open}>
                    <Grid item xs={12} style={{marginBottom: '2%'}}>
                        <Alert onClose={handleCloseErrorMessage} severity={openAlertMessage.severity}>{openAlertMessage.message}</Alert>
                    </Grid>
                </Collapse>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        as={<TextField
                            variant={'outlined'}
                            fullWidth
                            size={'small'}
                            style={{marginBottom:'3%'}}
                            type="password"
                            error={!!errors?.old_pswd || openAlertMessage.severity === severityTypes.ERROR}
                            helperText={errors?.old_pswd?.message}
                            label={t('profile.changePasswordForm.currentPassword')}
                        />}
                        name="old_pswd"
                        control={control}
                    />
                    <Controller
                        as={<TextField
                            variant={'outlined'}
                            fullWidth
                            size={'small'}
                            style={{marginBottom:'3%'}}
                            type={showPassword ? 'text' : 'password'}
                            error={!!errors?.new_pswd}
                            helperText={errors?.new_pswd?.message}
                            label={t('profile.changePasswordForm.newPassword')}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {setShowPassword(!showPassword)}}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                //
                            }}
                        />}
                        name="new_pswd"
                        control={control}
                    />
                    <Controller
                        as={<TextField
                            variant={'outlined'}
                            fullWidth
                            size={'small'}
                            type={showPassword ? 'text' : 'password'}
                            error={!!errors?.repeat_new_pswd}
                            helperText={errors?.repeat_new_pswd?.message}
                            label={t('profile.changePasswordForm.confirmNewPassword')}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {setShowPassword(!showPassword)}}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                //
                            }}
                        />}
                        name="repeat_new_pswd"
                        control={control}
                    />
                    <Box pt={2} display={'flex'} justifyContent={'flex-end'}>
                        <Button onClick={() => setOpenChangePassword(false)}>{t('actions.cancel')}</Button>
                        <Button type="submit"
                                variant="contained"
                                className={classes.button}>{t('actions.save')}</Button>
                    </Box>
                </form>
            </Box>
        </Collapse>
    )
}

export default ChangePasswordForm