import React, {useContext, useEffect, useState} from 'react'
import {Map, Marker, Popup, TileLayer} from 'react-leaflet'
import Button from "@material-ui/core/Button";
import {PinnedIcon, UnpinnedIcon} from "./CustomIcons";
import {Box, ListItemAvatar, TextField, Tooltip} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import ReactDOMServer from "react-dom/server";
import {ThemeContext} from "../contexts";
import chroma from 'chroma-js'
import {findLocationCodeTypeValue} from "../utils/CodesUtils";
import _ from 'lodash'
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@material-ui/lab";
import i18next from "../i18n/i18next";
import ListItemText from "@material-ui/core/ListItemText";
import {LocationOff, LocationOn} from "@material-ui/icons";

let L = window.L // mappa

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: "none",
        color: 'darkgrey'
    },
    customMarker: {
        fill: 'red'
    }
}));

export const getCenter = (places) => {
    let latitudes = []
    let longitudes = []
    let bounds = []
    const latReducer = (accumulator, currentValue) => accumulator + currentValue;
    const lonReducer = (accumulator, currentValue) => accumulator + currentValue;
    places.forEach((p) => {
        latitudes.push(p.latitude)
        longitudes.push(p.longitude)
        //const latlng = L.latLng(p.latitude, p.longitude)
        //bounds.push(latlng)
        bounds.push([p.latitude, p.longitude])
    })

    const lat = latitudes ? latitudes.reduce(latReducer) : 0
    const lon = longitudes ? longitudes.reduce(lonReducer) : 0
    //console.log("Center: ", lat/latitudes.length, ";", lon/longitudes.length)

    return {latitude: lat / latitudes.length, longitude: lon / longitudes.length, bounds: L.latLngBounds(bounds)}
}

const filterByGLN = (gln, glnPlaceLUT, selected, setSelected, filters, setFilters) => {
    selected ? setFilters({
        ...filters,
        gln: '',
        //address: '',
        companyName: ''
    }) : setFilters({
        ...filters,
        gln: "where=" + gln,
        //address: glnPlaceLUT[gln].address
        companyName: glnPlaceLUT[gln]?.name || ""
    })

    setSelected(gln)
}

const CustomMarker = ({marker, glnPlaceLUT, selected, setSelected, filters, setFilters, setSkip}) => {
    const { t } = useTranslation();

    const { accentColor } = useContext(ThemeContext)
    const classes = useStyles();

    //https://leafletjs.com/examples/custom-icons/
    //https://github.com/pointhi/leaflet-color-markers

    /**
     * Altra modalità per avere le icone colorate da leaflet
     * var coloredIcon = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });*/

    /** per avere custom marker */
    const MyCustomMarker = () => {
        return (
            <svg
                width={30}
                height={50}
                viewBox={'-5 -5 30 50'}
                style={{transform: 'translate3d(-15px, -30px, 0px)'}}>
                <defs>
                    <filter id="blur">
                        <feGaussianBlur stdDeviation="3"/>
                    </filter>
                </defs>
                <path filter={"url(#blur)"} style={{opacity: 0.7}}
                      d="M23,20.7c0.9,3.4-1,5-6,8c-4.4,2.6-12,2-10-2c1.6-3.2,2-5,6-8C17.1,15.6,22.1,17.2,23,20.7z"/>
                <path stroke={chroma(accentColor).darken()} fill={accentColor} d="M10.2,30.2C11.5,28.6,20,17.6,20,10c0-5.5-4.5-10-10-10C4.5,0,0,4.5,0,10c0,7.7,8.5,18.6,9.8,20.3
                    C9.9,30.3,10,30.3,10.2,30.2C10.1,30.3,10.2,30.3,10.2,30.2z M5.6,9.4C5.9,7,8.2,5.3,10.6,5.6c2.4,0.3,4.1,2.5,3.8,4.9
                    c-0.3,2-1.8,3.5-3.8,3.8c-2.4,0.3-4.6-1.4-4.9-3.8C5.6,10.2,5.6,9.8,5.6,9.4z"/>
            </svg>
        )
    }

    function getIcon() {

        const icon = L.divIcon({
            className: 'customMarker',
            html: ReactDOMServer.renderToString(<MyCustomMarker/>),
            popupAnchor: [-5, -35]
        });
        return icon
    }

    const locationLabel = t('entities.location.label') // esplicito se no non la estrae
    const [type, locationCode, domain] = findLocationCodeTypeValue(marker.gln)
    return (
        <Marker
            position={[
                marker.latitude,
                marker.longitude
            ]}
            icon={getIcon()}
            //icon={coloredIcon}
        >
            <Popup  style={{zIndex: 1500}}>
                <span>{_.startCase(t('entities.name'))}: {marker.name},</span>
                <br/>
                <span>{_.upperCase(type)}: {(domain ? (domain + ':') : '') + locationCode},</span>
                <br/>
                <span>{_.startCase(t('entities.location.address'))}: {marker.address}</span>
                <br/>
                {
                    marker.nItems ?
                        <div>
                            <span>{t('entities.itemWithCount', {count: marker.nItems})}</span>
                            <br/>
                        </div>
                        : null
                }
                {
                    marker.nEvents ?
                        <div>
                            <span>{t('entities.eventWithCount', {count: marker.nEvents})}</span>
                            <br/>
                        </div>
                        : null
                }
                {
                    marker.filters ?
                        <Box pt={1}>
                            <Button
                                fullWidth={true}
                                className={classes.button}
                                startIcon={!selected ? <UnpinnedIcon/> : <PinnedIcon/>}
                                onClick={() => {
                                    setSkip(0)
                                    filterByGLN(marker.gln, glnPlaceLUT, selected, setSelected, filters, setFilters)
                                }}>
                                {
                                    !selected ?
                                        <span>{t('actions.filterBy', {by: locationLabel})}</span>
                                        :
                                        t('actions.clear', {what: t('entities.filter')})
                                }
                            </Button>
                        </Box>
                        : null
                }
            </Popup>
        </Marker>
    )
}

export const getGenerilizedPlaces = (places, glnPlaceLUT, filters, setFilters) => {
    const placesToDisplay = []
    places.length ?
        places.forEach((p) => {
            let place = null
            if (!!p.latitude && !!p.longitude) {
                place = p
            } else if (p.detail) {
                //console.log("p.detail")
                place = p.detail
            }
            place && placesToDisplay.push(place)
        })
        :
        Object.entries(places).forEach(([key, value]) => {
            if (glnPlaceLUT[key]){
                const place = {
                    gln: key,
                    nItems: value.n_items,
                    nEvents: value.n_events,
                    address: glnPlaceLUT[key].address,
                    latitude: glnPlaceLUT[key].latitude,
                    longitude: glnPlaceLUT[key].longitude,
                    name: glnPlaceLUT[key].name,
                    filters: filters,
                    setFilters: setFilters
                }
                placesToDisplay.push(place)
            }
        })


    return _.filter(placesToDisplay, (p) => p.latitude!==null && p.longitude!==null);
}

// marker -> quando ho una sola location, places quando ho più marker da mostrare
const CustomMap = ({marker, places, filters, setFilters, setSkip}) => {
    //const t0 = performance.now()
    const { primaryColor, accentColor } = useContext(ThemeContext)

    const [selected, setSelected] = useState(filters?.gln?.replace("where="))
    useEffect(() => {
        if (filters && !filters.gln) {
            setSelected(null)
        }
    }, [filters])

    const [glnPlaceLUT, setGlnPlaceLUT] = useState(null)

    const [placesToDisplay, setPlacesToDisplay] = useState([])
    const [center, setCenter] = useState(marker ? marker : {
        latitude: 45,
        longitude: 7,
        zoom: 11,
        bounds: null
    })

    useEffect(() => {
        (async () => {
            let placeLut = {}
            if (places) {
                if (Object.entries(places).length !== 0) {
                    let url = "api/places/_by_codes/?skip=0&limit=0"
                    await axios.post(url, {codes: Object.keys(places)},)
                        .then(res => {
                            res.data.data.forEach((place) => {
                                placeLut[place.gln] = place
                            })

                            //const _vplaces =
                            //    _.filter(places, placep => p.latitude !== null && p.longitude !== null);

                            if (Object.entries(places).length > 0) {
                                const generilizedPlaces = getGenerilizedPlaces(places, placeLut, filters, setFilters)

                                setCenter(getCenter(generilizedPlaces))
                                setPlacesToDisplay(generilizedPlaces)
                            } else {
                                setCenter({
                                    latitude: 45,
                                    longitude: 7,
                                    zoom: 11,
                                    bounds: null
                                })
                                setPlacesToDisplay([])
                            }
                            setGlnPlaceLUT(placeLut)
                        }).catch((error) => {
                            console.log("map error", error)
                        })
                } else {
                    setCenter({
                        latitude: 45,
                        longitude: 7,
                        zoom: 11,
                        bounds: null
                    })
                    setPlacesToDisplay([])
                }
            }
        })()
    }, [places, filters, setFilters])

    //console.log("CENTER: ", center)
    //const t1 = performance.now()
    //t1-t0 > 0 && console.log("-------------------------------------------- TIME CustomMap --->", t1-t0)

    return (
        <div>
            {glnPlaceLUT && filters && setFilters && <Autocomplete
                style={{width: '80%'}}
                options={Object.keys(places)}
                getOptionLabel={(option) => glnPlaceLUT[option] ? (`${glnPlaceLUT[option].name}, ${glnPlaceLUT[option].city} - ${option}`) : option}
                value={selected}
                onChange={(event, newValue) => {
                    filterByGLN(newValue, glnPlaceLUT, selected, setSelected, filters, setFilters)
                }}
                loading={!glnPlaceLUT}
                renderInput={(params) => <TextField {...params} size={'small'}
                                                    label={i18next.t('entities.location.label')}/>}
                renderOption={(option) => {
                    const geolocated = (glnPlaceLUT[option]?.latitude && glnPlaceLUT[option]?.longitude)
                    return (<React.Fragment>
                            {<Tooltip
                                title={geolocated ? i18next.t('entities.location.available') : i18next.t('entities.location.notAvailable')}><ListItemAvatar
                                style={{color: accentColor}}>
                                {geolocated ? <LocationOn/> : <LocationOff color={'disabled'}/>}
                            </ListItemAvatar></Tooltip>}
                            <ListItemText
                                primary={glnPlaceLUT[option] ? (`${glnPlaceLUT[option].name}, ${glnPlaceLUT[option].city}`) : option}
                                secondary={glnPlaceLUT[option] ? option : ""}
                                style={{color: primaryColor}}/>
                        </React.Fragment>
                    )
                }}
            />}
            <Map
                center={[center.latitude, center.longitude]}
                zoom={marker ? 11 : center.zoom}
                minZoom={2}
                style={{width: '100%', height: '400px', marginTop: '1%'}}
                bounds={center.bounds ? center.bounds : null}
                animate={false} // per evitare l'esplosione da "_leaflet_pos of undefined" causato dalla transitionEnd dello zoom
            >
                <TileLayer
                    attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {marker ?
                    <CustomMarker key={marker.gln} marker={marker} glnPlaceLUT={glnPlaceLUT}/>
                    :
                    placesToDisplay.map(place => (
                        <CustomMarker key={place.gln} marker={place} glnPlaceLUT={glnPlaceLUT} selected={selected}
                                      setSelected={setSelected} filters={filters} setFilters={setFilters}
                                      setSkip={setSkip}/>
                    ))
                }
            </Map>
        </div>
    )

}

export default CustomMap
