import React, {useContext, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Button, Box, FormControl, Typography} from '@material-ui/core';
import {useForm, Controller} from "react-hook-form";
import * as yup from "yup";
import CodeProduct from "../../components/new_product/CodeProduct";
import Header from "../../components/Header";
import CustomFeature from "../../components/new_product/CustomFeature";
import SummaryPage from "../../components/new_product/SummaryPage";
import Tags from "../../components/new_product/Tags";
import BaseInfo from "../../components/new_product/BaseInfo";
import useAxios from 'axios-hooks'
import {useHistory} from "react-router-dom";
import {AuthContext, RoutingContext, ThemeContext} from "../../contexts";
import _ from "lodash";
import axios from "axios";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import Fade from "@material-ui/core/Fade";
import WarningIcon from '@material-ui/icons/Warning';
import {
    availableProductCodeTypes,
    buildGS1gtin14, buildInternalCode,
    findCodeTypeAndValue, getNewPrivateCode,
    prepareFormattedProductClassCode
} from "../../utils/CodesUtils";
import CustomLoader from "../../components/CustomLoader";
import {useTranslation} from "react-i18next";

/**
 * ESEMPIO
 * const WrappedInput = ({value, onChange}) => {
    return (
        <Box>
            value: {value}
            <TextField fullWidth label="~~~" value={value || ''} onChange={onChange}/>
        </Box>
    )
};
 */


const TheForm = ({initValues, onSubmit, editMode}) => {
    const {t} = useTranslation()
    const {accentColor} = useContext(ThemeContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            '& > *': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: accentColor,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: accentColor,
                },
            },
        },
        grid: {
            marginLeft: '2%',
            paddingTop: '2%',
            width: '96%',
        },
        button: {
            marginTop: "1%",
            width: '20%',
            borderRadius: '20px',
            backgroundColor: accentColor,
            color: 'white',
            fontWeight: 'bold'
        },
        codeProduct: {
            marginTop: '3%',
            backgroundColor: "#f6f6f6",
            boxShadow: theme.shadows[1]
        },
        infoChip: {
            marginLeft: '1%',
            border: '1px solid ' + accentColor,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
        },
        icon: {
            color: accentColor,
            marginRight: '1%'
        }
    }));
    const classes = useStyles();

    const [generateCodeCheck, setGenerateCodeCheck] = useState(false);
    const [editCodesChecked, setEditCodesChecked] = useState(false);

    const schema = yup.object().shape({
        name: yup.string()
            .required(t('addItem.enterProductName')),
        codes: !generateCodeCheck ? yup.array().required(t('addItem.enterAtLeastOneCode'))
            : yup.array().notRequired()
    });

    const {handleSubmit, errors, setValue, control} = useForm({
        validationSchema: schema,
        defaultValues: initValues,
    });

    const handleGenerateCodeCheckedChange = (event) => {
        setGenerateCodeCheck(event.target.checked)
    };

    const handleEditCodesCheckedChange = (event) => {
        if (!editCodesChecked) {
            if(window.confirm(t('addItem.areYouSureToEditCodes')))
                setEditCodesChecked(event.target.checked)
        } else {
            setValue('codes', initValues.codes)
            setEditCodesChecked(event.target.checked)
        }
    }

    const checkKeyDown = (e) => {
        //console.log("e:",e)
        if (e.keyCode === 13) e.preventDefault(); //enter
    };

    return (
        <Paper>
            <Header title={editMode ? t('actions.edit', {what: t('entities.item')})+' - ' + initValues.name : t('addItem.addNew')}/>
            <Grid container spacing={1} className={classes.grid} justify="center">
                {/* salvo il submit nel contenitore */}
                <form className={classes.root} onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                    <BaseInfo control={control} errors={errors}/>

                    { /*
                            esempio di controllo wrappato = mette il valore di ciop0 in 'value' e legge cambiamenti con 'OnChange'
                            <Controller
                                as={WrappedInput}
                                control={control}
                                name="ciop0"
                            />

                            <Controller as={TextField} name="ciop1" control={control} defaultValue={getValues("ciop1") || ''} />

                            questo come in product name funziona per caso, per un baco prob ma poi si perdeva lo stesso i valori dopo altri rerender
                            <RHFInput as={TextField} name="ciop2" setValue={setValue} register={register} defaultValue={getValues("ciop2") || ''}/>

                            <input name="ciop3" ref={register} />
                            */}

                    {/* esempio di wrapping di controllo composto */}

                    <Box className={classes.codeProduct} borderRadius={6}>
                        <Box pl={2} pt={2} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                            <Typography>
                                {generateCodeCheck ? t('entities.autoGenCode') : t('addItem.enterAtLeastOneCode')}
                            </Typography>
                            <Chip
                                //size={'small'}
                                className={classes.infoChip}
                                icon={<InfoIcon style={{color: accentColor}}/>}
                                label={generateCodeCheck ?
                                    <i>{t('addItem.iChainWillGenerateAproductCode')}</i>
                                    : <i>{t('addItem.firstCodeIsGoingoToBeMain')}</i>}
                            />
                        </Box>
                        {
                            !generateCodeCheck ?
                                <Fade in={!generateCodeCheck}>
                                    <div>
                                        <Controller
                                            as={<CodeProduct/>}
                                            control={control}
                                            name="codes"
                                            availableProductCodeTypes={availableProductCodeTypes}
                                            errors={errors}
                                            generateCodeCheck={generateCodeCheck}
                                            editCodesChecked={editCodesChecked}
                                            editMode={editMode}
                                        />
                                        <Divider/>
                                    </div>
                                </Fade> : null
                        }
                        {
                            !editMode ?
                            <Box pl={2} p={1} style={{display: 'flex', alignItems: 'center'}}>
                                <WarningIcon className={classes.icon}/>
                                <FormControl>
                                    <FormControlLabel
                                        control={<Checkbox checked={generateCodeCheck} onChange={handleGenerateCodeCheckedChange}
                                                           color="default"/>}
                                        label={<Typography>{t('addItem.iDontHaveAcode')}</Typography>}/>
                                </FormControl>
                            </Box>
                                : <Box pl={2} p={1} style={{display: 'flex', alignItems: 'center'}}>
                                    <WarningIcon className={classes.icon}/>
                                    <FormControl>
                                        <FormControlLabel
                                            control={<Checkbox checked={editCodesChecked} onChange={handleEditCodesCheckedChange}
                                                               color="default"/>}
                                            label={<Typography>{t('addItem.iWantToEditCode')}</Typography>}/>
                                    </FormControl>
                                </Box>
                        }
                    </Box>
                    <Box pl={2} pr={2} pb={3}>
                        <Controller
                            as={<Tags/>}
                            name="tags"
                            control={control}
                        />
                    </Box>
                    <Box>
                        <Controller
                            as={
                                <CustomFeature/>
                            }
                            name="custom_features"
                            control={control}
                        />
                    </Box>
                    {/*<PalletizingInfo register={register}/>*/}
                    {/*<LogisticInfo register={register}/>*/}
                    <Box mt={3} pb={2} style={{display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" color="default" variant="contained"
                                className={classes.button}>{t('actions.next')}</Button>
                    </Box>

                </form>
            </Grid>
        </Paper>
    )
}

const TheSummary = ({data, onGoBack, editMode}) => {
    const myContext = useContext(RoutingContext);
    const authContext = useContext(AuthContext);
    const [putError, setPutError] = useState(null)

    const [{data: postData, loading: postSaving, error: postError}, postExecute] = useAxios(
        {
            url: `api/items/${!editMode.editMode && editMode.otherProduct ? "others/" : ''}`,
            method: "POST",
            headers: {
                'Media-Type': 'application/json',
            }
        },
        {manual: true}
    )

    let history = useHistory();

    function prepareDataToSend(_keyCode, otherCodes) {
        /** KEYCODE FORMAT: {type: {type, name, label, digitCount}, value: <code>} */

        let myData = {
            original_key_type: /*editMode.editMode ?
                data.initValues.original_key_type : */_keyCode.type.name ? _keyCode.type.name : _keyCode.type,
            codes: otherCodes,
            name: data.formValues.name,
            producer: editMode.editMode ?
                data.initValues.producer
                : editMode.otherProduct ?
                    "x."+authContext.activeMainDomain : authContext.activeMainDomain,
            key: _keyCode.value,
            description: data.formValues.description,
            tags: data.formValues.tags,
            image: data.formValues.image,
            active: data.formValues.active,
            custom_features: data.formValues.custom_features,
        }

        //console.log("DATA2SEND ---> myData:", myData)
        if (!editMode.editMode) {
            postExecute({data: myData})
                .then((res) => console.log('added product:', res))
                .catch((err) => {
                    console.log('error adding product:', err)
                })
        } else {
            const url = `api/items/${!editMode.editMode && editMode.otherProduct ? "others/" : ''}${editMode.id}`
            axios({
                method: 'put',
                url: url,
                data: {...myData, id: editMode.id},
                config: {headers: {'Media-Type': 'application/json'}}
            })
                .then(function (response) {
                    if (response.status === 200) {
                        myContext.setRoute(!editMode.otherProduct ? 'Items' : 'OtherItems');
                    }
                })
                .catch(function (response) {
                    console.log('error editing product ---> ', response);
                    setPutError(true)
                });
        }
    }

    const onCompleteClick = async () => {
        let keyCode = ''
        let otherCodes = []
        let finalKeyCode = null

        if (data.formValues.codes) {
            // TODO: rivedere questa funzione, con le ultime decisioni sull'uso sempre del gtin14 potrebbe essere superflua
            const formattedCodes = _.map(data.formValues.codes, (code) =>
                (prepareFormattedProductClassCode(editMode, authContext.activeMainDomain, code)))

            keyCode = data.formValues.codes[0]
            //otherCodes = gtin ? legacy ? [{type: legacy.type.name, value: legacy.value.trim()}] : [] : []
            otherCodes = formattedCodes.filter( x => x.type !== keyCode.type.name )

            finalKeyCode = keyCode.type.type === 'gtin' ?
                {type: keyCode.type, value: buildGS1gtin14(keyCode.value)}
                : {type: keyCode.type, value: buildInternalCode(editMode.otherProduct, authContext.activeMainDomain, 'class', keyCode.value)}

        } else {
            /** AUTOGENERATED CODE: non ho codes -> iChain deve generare un internal code */
                const value = await getNewPrivateCode(!editMode.otherProduct ? "prod_class/" : "not_mine/prod_class/")
                finalKeyCode = {type: 'internal', value: value.code}
        }
        prepareDataToSend(finalKeyCode, otherCodes)
    }

    function goBack() {
        history.goBack()
    }

    return (
        <Box>
            {
                postSaving ? <CustomLoader /> :
                    (
                        postData ? goBack :
                            <SummaryPage data={data.formValues} onGoBack={onGoBack} onCompleteClick={onCompleteClick}
                                         error={postError ? postError : putError ? putError : null}/>
                    )
            }
        </Box>
    )
}

const AddProduct = (props) => {
    //console.log('RICEVO PROPS.LOCATION ---> ', props.location.state)
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({initValues: {}, formValues: {}});

    const editMode = useMemo(() => {
        if (props.location.state && props.location.state.edit) {
            let editItem = props.location.state
            let codes = _.cloneDeep(editItem.codes)

            /** creo l'obj key e la inserirò nella lista di codici da modificare */
            const [keyCodeType, keyCodeValue] = findCodeTypeAndValue(editItem.key)
            let keyCode = {
                type: keyCodeType,
                value: keyCodeValue
            }
            codes = _.map(codes, (code) => {
                const [type, value] = findCodeTypeAndValue(code.value)
                return {
                    type: type,
                    value: value
                }
            })
            /** inserisco la key obj nella lista di codici da modificare */
            codes.splice(0, 0, keyCode)//.splice(0, 0, keyCode)
            let editObj = _.omit(editItem, ['other', 'tableData'])

            setFormData({...formData, initValues: {...editObj, codes: codes}})
            return {editMode: true, otherProduct: editItem.other, id: props.location.state._id}
        } else return {editMode: false, otherProduct: props.location.state ? props.location.state.other : null, id: null}
    }, [])

    function onSubmit(data) {
        setFormData({...formData, formValues: data})
        setStep(1);
    }

    function onGoBack() {
        setFormData({...formData, initValues: Object.assign({}, formData.initValues, formData.formValues)})
        setStep(0);
    }

    return (
        <div>
            {step === 0 ? (
                <TheForm onSubmit={onSubmit} initValues={formData.initValues} editMode={editMode.editMode}/>
            ) : (
                <TheSummary data={formData} onGoBack={onGoBack} editMode={editMode}/>
            )
            }
        </div>
    );
}

export default AddProduct

