import React from 'react';
import MaterialTable from 'material-table';
import {useWindowSize} from "../../utils/Utils"

const MaterialTableDemo = ({revocations, limit, setLimit, skip, setSkip}) =>  {

    const size = useWindowSize();

    let columns = [
        { title: 'Certificate Serial Number', field: 'certificate_id'},
        { title: 'Date', field: 'datetime'},
    ]

    if(size.width>300){
        columns = [
            { title: 'Certificate Serial Number', field: 'certificate_id'},
            { title: 'Date', field: 'datetime', render: rowData => <div>{rowData.datetime
                    .replace("T", " ")
                    .slice(0,19)}</div>},
            { title: 'Reason', field: 'reason'}
        ]
    }

    const handleChangePage = (newPage) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (n_rows) => {
        setLimit(n_rows)
        setSkip(0);
    };

    return (
        <div style={{ maxWidth: '100%'}}>
            <MaterialTable
                columns={columns}
                data={revocations.data}
                options={{
                    search: true,
                    sorting: false,
                    showTitle: false,
                    paging: true,
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20]
                }}
                totalCount={revocations.count}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={skip}
            />
        </div>
    );
}

export default MaterialTableDemo
