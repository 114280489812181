import useAxios from "axios-hooks";
import React, {useContext, useEffect, useMemo, useState} from "react";
import MarbleDiagram from "./MarbleDiagram";
import axios from "axios";
import CustomLoader from "../../components/CustomLoader";
import _ from "lodash";
import chroma from "chroma-js";
import {AuthContext} from "../../contexts";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

const MarbleDiagramPage = () => {
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);

    const [companyNameLut, setCompanyNameLut] = useState(null)
    const [products, setProducts] = useState(null)

    // nodi che arrivano inizialmente dal server, senza clusteredEvents
    const [{data: rawNodes, loading: loadingRawNodes, error: errorRawNodes},] = useAxios({
            url: 'api/capture/epcis_events/marble/v2/'
        },
        {useCache: false}
    );

    const [{data: clusteredEvents, loading: loadingClusteredEvents, error: errorClusteredEvents},postExecute] = useAxios({
            url: 'api/capture/epcis_events/marble/v2/clustered_events/?group=one_month'
        },
        {useCache: false, manual: true}
    );

    const [{data: itemsByKeys}, postExecuteItemsByKeys] = useAxios(
        {
            url: "api/items/_by_keys/",
            method: "POST",
            headers: {
                'Media-Type': 'multipart/form-data',
            }
        },
        {manual: true}
    )

    const [locationCodes, productClassCodes] = useMemo(() => {
        if(!rawNodes)
            return [null, null]

        postExecute()

        let locationsCodes = '' /** location instance codes */
        let _locationsCodes = [] /** location instance codes */

        let prod = []

        rawNodes.nodes.forEach(
            (node, index) => {
                if(node.parent && !locationsCodes.includes(node.parent)) {
                    _locationsCodes.push(node.parent)
                    if(index !== 0)
                        locationsCodes += `,${node.parent}`
                    else
                        locationsCodes = node.parent
                }

                if(node.products) {
                    prod.push(...node.products)
                }
            })

        /** Codici di classe prodotto */
        const productClassCodes = _.uniq(prod)//.join(',')

        if(productClassCodes) {
            postExecuteItemsByKeys({data: {keys: productClassCodes}})
        }

        return [_locationsCodes, productClassCodes]

    }, [rawNodes])

    useEffect(() => {
        if(itemsByKeys && productClassCodes) {
            const productsNamesMap = {}
            itemsByKeys.data.forEach((product) => {
                productsNamesMap[product.key] = {_id: product._id, key: product.key, name: product.name, tags: product.tags }
            })
            _.forEach(productClassCodes, (code) => {
                if(!productsNamesMap[code]) {
                    productsNamesMap[code] = {key: code, name: '', tags: []}
                }
            })

            setProducts(productsNamesMap)
        }
    }, [itemsByKeys])

    /** crea la storia dei prodotti visualizzati nei nodi */
    const [forwardHistory, backwardHistory] = useMemo(() => {
        if(!rawNodes)
            return [null, null]

        let forwardHistory = {}
        let backwardHistory = {}
        _.forEach(rawNodes.nodes, (node) => {
            Object.entries(node.in_out).forEach(([gtin_in, gtins_out]) => {
                if (!forwardHistory[gtin_in]) {
                    forwardHistory[gtin_in] = gtins_out
                } else {
                    forwardHistory[gtin_in] = _.union(forwardHistory[gtin_in], gtins_out)
                }
            })
        })

        Object.entries(forwardHistory).forEach(([gtin_in, gtins_out]) => {
            _.forEach(gtins_out, (gtin_out) => {
                if(!backwardHistory[gtin_out]){
                    backwardHistory[gtin_out] = [gtin_in]
                } else {
                    if(_.indexOf(backwardHistory[gtin_out], gtin_in) === -1) {
                        backwardHistory[gtin_out].push(gtin_in)
                    }
                }
            })
        })

        return [forwardHistory, backwardHistory]

    }, [rawNodes])

    /**
     * Arrichchimento dei rawNodes con i clusterEvents e il flag di utente loggato
     * */
    const clusteredData = useMemo( () => {
        if(!rawNodes || !clusteredEvents)
            return null
        //debugger

        const loggedDomain = authContext.activeMainDomain

        const data = _.map(rawNodes.nodes, (node) => {
            const out_in = {}
            Object.entries(node.in_out).forEach(([gtin_in, gtins_out]) => {
                _.forEach(gtins_out, (gtin_out) => {
                    if(!out_in[gtin_out]) {
                        out_in[gtin_out] = [gtin_in]
                    } else {
                        if(_.indexOf(out_in[gtin_out], gtin_in) === -1) {
                            out_in[gtin_out].push(gtin_in)
                        }
                    }
                })
            })

            return {
                ...node,
                out_in: out_in,
                clusteredEvents: clusteredEvents[node.id],
                loggedUser: loggedDomain === node.node_domain
            }
        })

        return data
    }, [rawNodes, clusteredEvents])

    useEffect(() => {
        (async () => {
            if (locationCodes) {
                const url = "/api/subplaces/brand_names/_by_loc_codes/?enrich=true"
                await axios.post(url, {loc_codes: locationCodes})
                    .then(res => {
                        setCompanyNameLut(res.data)
                    }).catch((error) => {
                        console.log("error fetching domain names", error)
                    })
            }
        })()
    }, [locationCodes])

    const completeData = useMemo(() => {
        if(!clusteredData || !companyNameLut || !products || !forwardHistory || !backwardHistory)
            return null

        // colori per gtin
        const colorScale = chroma.scale('RdYlBu').colors(Object.keys(products).length)
        const colorMap = {};
        Object.keys(products).forEach((gtin, index) => { colorMap[gtin] = colorScale[index] });

        return {...rawNodes,
            nodes: clusteredData,
            companyNameLut: companyNameLut,
            showGraph: true,
            products: products, // { key:gtin, name:product_name, _id: _id_db }
            gtinColorMap: colorMap,
            forwardHistory: forwardHistory,
            backwardHistory: backwardHistory,
            authContext: authContext
        }
    }, [clusteredData, companyNameLut, products, forwardHistory, backwardHistory])

    return (
        <div>
            {
                completeData ?
                    <MarbleDiagram clusteredData={completeData}/>
                    : loadingRawNodes || loadingClusteredEvents ? <CustomLoader size={80}  text={t('actions.loading')}/>
                    : errorRawNodes || errorClusteredEvents ? t('errors.genericServerError') :
                        rawNodes ? !rawNodes.nodes.length ?
                            <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.noDataToDisplay')}</Typography>
                            : null : null
            }
        </div>
    )
}

export default MarbleDiagramPage
