import React, {useContext, useEffect, useState} from "react";
import {Grid, ListItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {BizStepIcon} from "../CustomBizStepIcons";
import {ThemeContext} from "../../contexts";
import shortid from "shortid";
import QrCodeIconButton from "../QrCodeIconButton";
import FieldInfo from "../../pages/generate_new_event/FieldInfo";
import Box from "@material-ui/core/Box";
import _ from 'lodash'
import List from "@material-ui/core/List";
import i18next from "../../i18n/i18next";
import {splitCbvValues} from "../../utils/CbvUtils";

const RenderValue = ({classes, value, strictedWhitelist}) => {
    if (!Array.isArray(value)) {
        if(typeof value === 'string' || value instanceof String)
            return <Typography /*className={classes ? classes.tagColor : null}*/>{value}</Typography>
        else return value.sgln || value
    } else if(strictedWhitelist === null || strictedWhitelist === undefined) {
        /** children list per i TransactionEvent */
        return <Box>
            <List>
                {_.map(value, (val) => (
                    <Typography key={shortid.generate()}
                                className={classes ? classes.tag : null}>
                        {val.sgln || splitCbvValues(val)}
                    </Typography>))
                }
            </List>
        </Box>
    } else {
        /** whiteList (stricted e suggestion) per i prodotti e le location */
        return <Box>
            <FieldInfo
                name={strictedWhitelist ? i18next.t('cbvValues.whitelist.label', {context:'stricted'}) : i18next.t('cbvValues.whitelist.label', {context:'suggestion'})}
                optional={!strictedWhitelist}
                external={true}
                description={strictedWhitelist ?
                    i18next.t('cbvValues.whitelist.description', {context:'stricted'})
                    : i18next.t('cbvValues.whitelist.description',{context:'suggestion'})}
            />
            <List>
                {_.map(value, (val) => (
                    <Typography key={shortid.generate()} className={classes ? classes.tag : null}>{(val?.sgln||val)}</Typography>))}
            </List>
        </Box>
    }
}

const WRow = ({label, value, strictedWhitelist, classes, tooltipText, type, input, bizStep, disposition, action, qrCode,}) => {
    const {primaryColor} = useContext(ThemeContext)

    const [cbvDescription, setCbvDescription] = useState(null)

    useEffect(() => {
        if (bizStep) {
            /** async version (non tradotto) */
            //(async () => {
            //const _descr = await fetchCbvInfoDescription('biz_step', bizStep)
            //setCbvDescription(_descr.description)
            setCbvDescription(i18next.t(`cbvValues.bizStepTypes.${_.camelCase(splitCbvValues(bizStep))}`))
            //})()
        }

        if (disposition) {
            /** async version (non tradotto) */
            //(async () => {
            //const _descr = await fetchCbvInfoDescription('disposition', disposition)
            //setCbvDescription(_descr.description)
            setCbvDescription(i18next.t(`cbvValues.dispositionTypes.${_.camelCase(splitCbvValues(disposition))}`))
            //})()
        }

        if (action) {
            /** async version (non tradotto) */
            //(async () => {
            //const _descr = await fetchCbvInfoDescription('action', action)
            //setCbvDescription(_descr.description)
            setCbvDescription(i18next.t(`cbvValues.action.${splitCbvValues(action)}`))
            //})()
        }
    }, [])

    return (
        <ListItem key={shortid.generate()}>
            <Grid container style={{alignItems: 'center'}}>
                <Grid item xs={3}>
                    <Typography><b>{label}</b></Typography>
                </Grid>
                <Grid item xs={bizStep || qrCode ? 1 : false} style={{textAlign: "start"}}>
                    {
                        bizStep ?
                            <BizStepIcon bizstep={bizStep} color={primaryColor}/> :
                            qrCode ?
                                <QrCodeIconButton sgtin={qrCode} color={primaryColor}/> : null
                    }
                </Grid>
                <Grid item xs={bizStep || qrCode ? 8 : 9} style={{textAlign: "start", lineBreak: 'anywhere'}}>
                    {
                        tooltipText ?
                            <Tooltip title={tooltipText} arrow interactive placement={"left-start"}>
                                <Typography /*className={classes ? classes.tagColor : null}*/>{value}</Typography>
                            </Tooltip>
                            : bizStep || disposition || action ?
                            <FieldInfo name={value} description={cbvDescription}/>
                            : type || input ?
                                <FieldInfo name={value} description={(type || input)?.description}/>
                                : <RenderValue classes={classes} value={value} strictedWhitelist={strictedWhitelist}/>
                    }
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default WRow;
