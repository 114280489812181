import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import RelationshipForm from "./RelationshipForm";
import Box from "@material-ui/core/Box";
import useAxios from "axios-hooks";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {isActivePartner, parseName, reverseParseName, useDomains} from "../../utils/Utils";
import {useForm} from "react-hook-form";
import axios from "axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import _ from 'lodash';
import {AuthContext, RelationshipContext} from "../../contexts";
import CustomLoader from "../../components/CustomLoader";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        marginBottom: "6%",
        color: theme.secondary,
        textTransform: "none"
    },
    paper: {
        backgroundColor: '#F8F8F8',
    },
    edit: {
        fontWeight: "bold",
    },
}));

const types = [
    '4w events',
    'Master data',
]

const CreateRelationship = (props) => {
    const {t} = useTranslation();
    const schema = yup.object().shape({
        name: yup.string()
            .required(t('createRelationship.relationshipNameRequired')),
        destination: yup.object().nullable()
            .required(t('createRelationship.relationshipDestinationRequired')),
        rules: yup.array().nullable()
            .required(t('createRelationship.addAtLeastOneRule')) || yup.object().required(t('createRelationship.addAtLeastOneRule'))
    });

    const authContext = useContext(AuthContext)

    const [{loading: postSaving,}, postExecute] = useAxios(
        {
            method: "POST",
            headers: {
                'Media-Type': 'application/json',
            }
        },
        {manual: true}
    )

    const [{data: companies, loading: loadingCompanies, error: errorCompanies},] = useAxios({
            url: "/api/companies/"
        },
        {useCache: false}
    )

    const [{data: pendingRequests, loadingPendingRequests, errorPendingRequests}] = useAxios({
            url: "/api/partnership_requests/pending"
        },
        {useCache: false}
    )

    const [{data: activePartners, loadingActiveRequests, errorActiveRequests},] = useAxios({
            url: "/api/partnership_requests/active"
        },
        {useCache: false}
    )

    const filteredCompanies = useMemo(() => {
        if(!companies || !pendingRequests || !activePartners)
            return null
        return _.filter(companies.data, function (o) {
                return isActivePartner(activePartners, o.brands[0].domain)
            })
    }, [companies, pendingRequests, activePartners])

    const activeDomains = useDomains(authContext.isAdmin, authContext.activeDomains)

    const {handleSubmit, register, setValue, errors, control, reset, getValues, clearError, formState} = useForm(
        {
            validationSchema: schema,
            mode: 'onChange',
            defaultValues: {
                id: null,
                editCreate: null,
                selectType: types[0],
                name: '',
                source: null,
                destination: null,
                chain: [],
                active: true,
                rules: ''
            },
        }
    )

    const classes = useStyles();
    const [data, setData] = useState({});
    const descriptionElementRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState({open: false, errorDetail: ''});
    const [editMode, setEditMode] = useState({
        state: props.location.state && props.location.state.edit,
        name: props.location.state && props.location.state.name ? props.location.state.name : '',
        editObj: props.location.state && props.location.state.edit ? props.location.state : null
    })

    const handleClickOpen = (dataSent) => {
        setData(dataSent)
        setOpen(true);
    };

    const handleClickOpenError = (dataToSend, errorDetail) => {
        setData(dataToSend)
        setOpenError({open: true, errorDetail: errorDetail});
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseError = () => {
        setOpenError({open: false, errorDetail: ''});
    };

    useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open])

    function onSubmit(data) {
        var dataToSend = {
            id: data.id ? data.id : null,
            name: reverseParseName(data.name),
            type: reverseParseName(data.selectType),
            source: "xxxx",
            destination: "xxxx",
            partner: data.destination.domain,
            chain: _.map(data.chain, (chain) => (chain.chain)),
            active: data.active,
            rules: data.rules
        }

        if (!editMode.state) {
            const url = "api/rules/?domain="+data.source.domain
            postExecute({url: url, data: dataToSend})
                .then(() => {
                    handleClickOpen(dataToSend)
                })
                .catch((err) => {
                    handleClickOpenError(dataToSend, typeof err.response.data.detail === "string"
                        ? err.response.data.detail
                        : t("createRelationship.errorPostingNewRelation", "Server error posting new relationship"))
                })

        } else {
            axios
                .put('/api/rules/' + dataToSend.id, dataToSend)
                .then(() => {
                    handleClickOpen(dataToSend)
                })
                .catch((err) => {
                    handleClickOpenError(dataToSend, typeof err.response.data.detail === "string"
                        ? err.response.data.detail
                        : t("createRelationship.errorPostingNewRelation", "Server error posting new relationship"))
                })

        }

    }

    const [allCompanies, brandsDestinations] = useMemo(() => {
        if (!filteredCompanies)
            return [null, null]
        let _partnersBrands = []
        let _allCompaniesBrands = []

        _.forEach(filteredCompanies, (company) => {
            _partnersBrands = _.union(_partnersBrands, company.brands)
        })

        _.forEach(companies.data, (company) => {
            _allCompaniesBrands = _.union(_allCompaniesBrands, company.brands)
        })

        return [_allCompaniesBrands, _partnersBrands]

    }, [filteredCompanies])

    const context = useMemo(() => {
        if (!allCompanies || !brandsDestinations || !activeDomains.data)
            return null

        return {
            chosenDomain: _.find(activeDomains.data, ['domain', authContext.activeMainDomain]) || activeDomains.data[0],
            allCompanies: allCompanies,
            destinations: brandsDestinations,
            sources: activeDomains.data,
            handleSubmit: handleSubmit,
            register: register,
            setValue: setValue,
            errors: errors,
            reset: reset,
            setEditMode: setEditMode,
            getValues: getValues,
            editMode: editMode,
            types: types,
            clearError: clearError,
            formState: formState,
            /** Old fields */
            //products: allProducts,
            //myProducts: products,
            //products_count: _.filter(allProducts, ['mine', true]).length,
            //chains: chains,
            //relationships: rules,
            //places: allPlaces,
            //control: control,
            //refetchRules: () => console.log('refetchRules')
        }

    }, [brandsDestinations, allCompanies, activeDomains.data, errors])

    return (
        <div>
            {context && <RelationshipContext.Provider value={context}>
                {
                    allCompanies ?
                        <div className={classes.root}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <Box mb={2} className={classes.edit} p={1}>{
                                    editMode.state ? t('actions.edit', {what: ' - ' + parseName(editMode.name)}) : _.upperCase(t('createRelationship.newRelationship'))}
                                </Box>
                            </Grid>
                            <Box>
                                <Grid container spacing={3}
                                      direction="row"
                                      justify="center">
                                    <Grid container item xs={12}>
                                        <RelationshipForm onSubmit={onSubmit} control={control}/>
                                    </Grid>
                                </Grid>
                                {
                                    postSaving ? <Box pl={2} pb={2}>{t('actions.loading', {what: ''})}</Box> : null
                                }
                            </Box>
                            {
                                open ?
                                    <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose}
                                            scroll='paper'>
                                        <DialogTitle>{
                                            data ? parseName(data.name) : null
                                        }</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {
                                                    !editMode.state ? t('createRelationship.relationshipCreatedSuccess') :
                                                        t('createRelationship.relationshipModifiedSuccess')
                                                }
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose} color="default">
                                                {t('actions.ok')}
                                            </Button>
                                        </DialogActions>
                                    </Dialog> : null
                            }
                            {
                                openError.open ?
                                    <Dialog fullWidth={true} maxWidth={"md"} open={openError.open}
                                            onClose={handleCloseError}
                                            scroll='paper'>
                                        <DialogTitle>{
                                            data ? t('errors.label') + ' - ' + parseName(data.name) : null
                                        }</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {t('errors.label')}: {openError.errorDetail}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseError} color="default">
                                                {t('actions.ok')}
                                            </Button>
                                        </DialogActions>
                                    </Dialog> : null
                            }
                        </div> : null
                }
            </RelationshipContext.Provider>}
            {(loadingCompanies || loadingPendingRequests || loadingActiveRequests)
                ? <CustomLoader size={80} text={t('actions.loading', {what: ''})}/> : null}
            {(errorCompanies || errorPendingRequests || errorActiveRequests)
                ? <Typography align={'center'} style={{color:'darkgrey'}}>{t('errors.genericServerError')}</Typography> : null}
        </div>
    )
}

export default CreateRelationship;
