import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import './configurator.css';
import {useStoreActions, useStoreState} from "react-flow-renderer";
import {AuthContext, RulesConfiguratorContext, ThemeContext} from "../../contexts";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {setModels, setNodeDomain, setNodeModel} from "./configurator_reducer";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import chroma from "chroma-js"
import Grid from "@material-ui/core/Grid";
import {
    Accordion,
    AccordionDetails,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
    Typography
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CreateEventTemplate from "../event_models/create_event_model/CreateEventTemplate";
import DialogTitle from "@material-ui/core/DialogTitle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Button from "@material-ui/core/Button";
import {fetchDomainModels, severityTypes} from "../../utils/Utils";
import Chip from "@material-ui/core/Chip";
import {splitCbvValues} from "../../utils/CbvUtils";
import {BizStepIcon} from "../../components/CustomBizStepIcons";
import {useTranslation} from "react-i18next";
import i18next from "../../i18n/i18next";
import CreateTemplateDraft from "./CreateTemplateDraft";
import {Receipt} from "@material-ui/icons";
import {createFilterOptions} from "@material-ui/lab";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldInfo from "../generate_new_event/FieldInfo";

const filter = createFilterOptions();

const Sidebar = ({setOpenAlertMessage, addNode, classes}) => {

    const {t} = useTranslation()

    // rgba(155, 167, 179, 0.65);
    const {primaryColor, accentColor} = useContext(ThemeContext)
    const authContext = useContext(AuthContext)

    const [state, dispatch] = useContext(RulesConfiguratorContext)

    const nodes = useStoreState((store) => {
        return store.nodes
    });
    //const transform = useStoreState((store) => store.transform);
    const setSelectedElements = useStoreActions((actions) => {
        //console.log("actions:",actions)
        return actions.setSelectedElements
    });

    const selectAll = () => {
        setSelectedElements(nodes.map((node) => ({id: node.id, type: node.type, data: node.data})));
    };

    const handleAddNode = (event) => {
        if (inputValue.name || (state.nodeDomain && state.nodeModel)) {
            if (state.nodeDomain) {
                const model = _.find(state.models, ['name', inputValue.name]) || inputValue
                const customProps = {companyDomain: state.nodeDomain, model: model}

                addNode(customProps)
                dispatch(setNodeModel(model))
            }
        } else {
            setOpenAlertMessage({
                open: true,
                message: t('rulesConfigurator.messages.fullfilNode', 'You need to fullfil the node!'),
                severity: severityTypes.WARNING
            })
        }
    }

    const onDragStart = (event, nodeType) => {
        //console.log("onDragStart --> inputValue:", inputValue)
        if (state.nodeDomain) {
            const model = _.find(state.models, ['name', inputValue.name]) || inputValue
            //console.log("model:",model)
            event.dataTransfer.setData('application/configurator/okayData', state.nodeDomain && model);
            event.dataTransfer.setData('application/configurator/type', nodeType);
            event.dataTransfer.setData('application/configurator/domain', JSON.stringify(state.nodeDomain));
            event.dataTransfer.setData('application/configurator/model', JSON.stringify(model));
            event.dataTransfer.effectAllowed = 'move';
            dispatch(setNodeModel(model))
        }
    };

    const onDragEnd = (event, nodeType) => {
        //console.log("onDragEnd ---> event:",event)
        //console.log("onDragEnd ---> nodeType:",nodeType)
        //event.dataTransfer.setData('application/reactflow', nodeType);

        //window.alert("oh metti un nodo nuovo bibbi")
        //event.dataTransfer.setData('application/reactflow', nodeType);
    };

    /** menu per scegliere se aggiungere nuovo modello o nuovo modello "Bozza" */
    const [openAddModelMenu, setOpenAddModelMenu] = useState(false)
    const anchorRef = useRef(null);
    const handleToggle = () => {
        setOpenAddModelMenu((prevOpen) => !prevOpen);
    };

    const handleClose = (event, isDraft) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenAddModelMenu(false);
        if (isDraft !== undefined) {
            setOpenDialog({open: true, isDraft: isDraft})
        }
    };

    const [openDialog, setOpenDialog] = useState({open: false, isDraft: null})
    const handleCloseDialog = (newModel) => {
        if (!!newModel) {
            handleChangeModelValue(newModel)
        }
        setOpenDialog({open: false});
    };

    useEffect(() => {
        if (state.nodeDomain) {
            state.nodeDomain?.domain && fetchDomainModels(state.nodeDomain?.domain).then((r) => {
                if (r.data?.length === 0 || 0)
                    setOpenAlertMessage({
                        open: true,
                        message: t('rulesConfigurator.messages.noModelsForThisDomain', 'There are not templates for the given domain'),
                        severity: severityTypes.WARNING
                    })
                else dispatch(setModels(r.data))
                //dispatch(setModels(r.data))
            })
        }
    }, [state.nodeDomain])

    function handleChangeModelValue(newValue) {
        dispatch(setNodeModel(newValue))
        state.nodeDomain?.domain && fetchDomainModels(state.nodeDomain?.domain).then((r) => {
            dispatch(setModels(r.data))
        })
    }

    const [inputValue, setInputValue] = useState({name: '', is_draft: true, is_new: true})

    return (
        <aside>
            <Accordion defaultExpanded style={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>{t('rulesConfigurator.addNodes', 'Add nodes')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box paddingX={3} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                        {/*<div className="react-flow__node-default"
                              draggable
                              onDragStart={(event) => onDragStart(event, 'default')}
                              onDragEnd={(event) => onDragEnd()}>
                            Default Node
                        </div>*/}
                        <Box className={"customNode placeholder"} minWidth={'100%'} style={{
                            background: chroma(state.nodeDomain?.color || primaryColor).alpha(0.4).hex()
                        }}
                             draggable
                             onDragStart={(event) => {
                                 if (inputValue.name || (state.nodeDomain && state.nodeModel)) {
                                     onDragStart(event, 'customNode'/*, {name: 'Macello X', sgln: 'ashgdf2617'}, 'Accoppamento'*/)
                                 } else {
                                     setOpenAlertMessage({
                                         open: true,
                                         message: t('rulesConfigurator.messages.fullfilNode', 'You need to fullfil the node!'),
                                         severity: severityTypes.WARNING
                                     })
                                 }
                             }}
                             onDragEnd={(event) => onDragEnd()}>
                            <Autocomplete
                                size={'small'}
                                style={{backgroundColor: 'white', borderRadius: '4px', marginBottom: '4%'}}
                                fullWidth
                                onChange={(event, newValue) => {
                                    dispatch(setNodeDomain(newValue));
                                }}
                                options={state.domains}
                                groupBy={(option) => authContext.isAdmin ? option.companyName : null}
                                renderOption={option => <Fragment>
                                    <FiberManualRecordIcon fontSize={'large'} style={{
                                        color: option.color || primaryColor,
                                        marginRight: '3%'
                                    }}/>
                                    {option.name}
                                </Fragment>}
                                getOptionLabel={option => option.name + ((authContext.isAdmin && ' - ' + option.companyName) || '')}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t('entities.company.companyDomain', 'Company Domain')}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <Box display={'flex'} alignItems={'center'}>
                                <Autocomplete
                                    size={'small'}
                                    fullWidth
                                    freeSolo
                                    value={state.nodeModel || null}
                                    //onChange={handleChange}
                                    inputValue={inputValue.name}
                                    onInputChange={(ev, newInputValue) => setInputValue({
                                        ...inputValue,
                                        name: newInputValue
                                    })}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            dispatch(setNodeModel({
                                                name: newValue,
                                                is_draft: true,
                                                is_new: true
                                            }))
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            dispatch(setNodeModel({
                                                name: newValue.inputValue,
                                                is_draft: true,
                                                is_new: true
                                            }))
                                        } else {
                                            /** selezione di un modello o bozza pre-esistente */
                                            dispatch(setNodeModel(newValue))
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                name: `${t('actions.add', {what: t('entities.draft')})}: "${params.inputValue}"`,
                                                is_draft: true
                                            });
                                        }

                                        return filtered;
                                    }}
                                    disabled={!state.nodeDomain || !state.models}
                                    options={_.orderBy(state.models || [], ['is_draft'], 'asc')}
                                    groupBy={(option) => option.is_draft ? i18next.t('entities.draft_plural', 'Drafts') : i18next.t('entities.model.activeModel_plural', )}
                                    renderOption={option => <Fragment>
                                        <Grid container alignItems={'center'}>
                                            <Grid item xs={12}>
                                                <Typography>{/*option.is_draft ? option.draft_name : */option.name} <strong>{option.is_draft ? ' - '+_.upperCase(t('entities.draft')):''}</strong></Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {option.why && option.why[0]?.value ?
                                                    <BizStepIcon bizstep={option.why[0]?.value} color={accentColor}/>
                                                    : option.is_draft ? <Receipt style={{color: accentColor}}/> : null}
                                            </Grid>
                                            <Grid item xs={10}>
                                                <div style={{
                                                    marginLeft: '4%',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center'
                                                }}>
                                                    {option.type && <Chip
                                                        size={'small'}
                                                        label={_.startCase(option.type)}
                                                        style={{
                                                            marginRight: '3%',
                                                            color: accentColor,
                                                            border: '1px solid ' + accentColor,
                                                            fontWeight: 'bold',
                                                            backgroundColor: 'transparent',
                                                        }}
                                                    />}
                                                    {option.why && option.why[0]?.value && <Chip
                                                        size={'small'}
                                                        label={_.startCase(splitCbvValues(option.why[0]?.value))}
                                                        style={{
                                                            marginRight: '3%',
                                                            color: accentColor,
                                                            border: '1px solid ' + accentColor,
                                                            fontWeight: 'bold',
                                                            backgroundColor: 'transparent',
                                                        }}
                                                    />}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Fragment>}
                                    //getOptionLabel={option => option?.name}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            style={{backgroundColor: 'white', borderRadius: '4px'}}
                                            {...params}
                                            error={/\s/g.test(inputValue.name) || /\//g.test(inputValue.name)}
                                            helperText={(/\s/g.test(inputValue.name) || /\//g.test(inputValue.name))
                                            && t('cannotContainSpecialCharAndWhiteSpaces', 'Cannot contain \'/\' or white spaces')}
                                            label={_.startCase(t('entities.model.label'))}
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Tooltip
                                    title={t('rulesConfigurator.messages.addNewModel')}>
                        <span>
                            <IconButton size={'small'}
                                        disabled={!state.nodeDomain}
                                        ref={anchorRef}
                                        aria-controls={openAddModelMenu ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                /*onClick={() => {setOpenDialog(true)}}*/>
                                <AddCircleIcon/>
                            </IconButton>
                        </span>
                                </Tooltip>
                                <Popper open={openAddModelMenu} anchorEl={anchorRef.current} role={undefined}
                                        transition>
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={openAddModelMenu} id="menu-list-grow">
                                                        <MenuItem
                                                            onClick={(event) => handleClose(event, true)}>{t('rulesConfigurator.messages.addModelDraft')}</MenuItem>
                                                        <MenuItem
                                                            onClick={(event) => handleClose(event, false)}>{t('rulesConfigurator.messages.addCompleteModel')}</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>
                        </Box>
                        <Box m={1} display={'flex'} alignItems={'center'}>
                            <Button className={classes.button}
                                    onClick={handleAddNode}>{t('rulesConfigurator.messages.addNode',)}</Button>
                            <FieldInfo description={t('rulesConfigurator.messages.dragNode')}/>
                        </Box>

                        <Box style={{marginRight: 'auto'}} display={'flex'} flexDirection={'column'}>
                            <FieldInfo name={t('rulesConfigurator.messages.deleteNodes')}
                                       description={t('rulesConfigurator.messages.deleteNodesDescription')}/>
                            <FieldInfo name={t('rulesConfigurator.messages.multipleSelection')}
                                       description={t('rulesConfigurator.messages.multipleSelectInstructions.shiftDrag')+' '+t('rulesConfigurator.messages.multipleSelectInstructions.ctrlClick')}/>
                            <Button
                                onClick={selectAll}>{t('rulesConfigurator.messages.selectAllNodes', 'Select all nodes')}</Button>
                        </Box>

                        <Dialog disableBackdropClick fullWidth={true} maxWidth={"md"} open={openDialog.open}
                                scroll='paper'>
                            <DialogTitle id="customized-dialog-title">
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    {state.nodeDomain && openDialog.isDraft === true && t('rulesConfigurator.newDraftModel', 'New Draft Model')}
                                    {state.nodeDomain && openDialog.isDraft === false && _.startCase(t('eventModels.addNewTemplate'))}
                                    <IconButton onClick={() => {
                                        setOpenDialog({...openDialog, open: false})
                                    }}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                {state.nodeDomain && openDialog.isDraft === true &&
                                <CreateTemplateDraft handleCloseDialog={handleCloseDialog}
                                                     domain={state.nodeDomain.domain || ''}
                                                     draftName={inputValue.name}
                                />}
                                {state.nodeDomain && openDialog.isDraft === false &&
                                <CreateEventTemplate fromRulesConf={handleCloseDialog}
                                                     editMode={false}
                                                     is_draft={true}
                                                     domain={state.nodeDomain.domain || ''}/>}
                            </DialogContent>
                        </Dialog>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </aside>
    );
};

export default Sidebar
