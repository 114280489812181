import React, {useContext, useState} from "react";
import _ from "lodash";
import {bizTransactionTypes} from "../../../utils/CbvUtils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Box, Collapse, Fade} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Controller, useForm} from "react-hook-form";
import FieldInfo from "../FieldInfo";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import {CreateEventContext, ThemeContext} from "../../../contexts";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import {buildTransactionCode} from "./BizTransactionComponent";
import {Description, Link} from "@material-ui/icons";
import DocumentIconButton from "../../../components/DocumentIconButton";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {useTranslation} from "react-i18next";

const BizTransactionPartiallyFixedBuilder = (props) => {
    const {t} = useTranslation()
    const _bizType = _.find(bizTransactionTypes, ['value', props.bizType])

    const {primaryColor, accentColor} = useContext(ThemeContext)
    const eventContext = useContext(CreateEventContext)
    const {handleSubmit, register, control, watch, getValues} = useForm();

    const [bizValue, setBizValue] = useState({isSet: false, value: null, generated: false})

    function CodeType(props) {
        function handleChange(event) {
            props.setState({...props.state, codeType: event.target.value})
            props.onChange(event.target.value)
        }

        return <div style={{flex: 1, display: 'flex', alignItems: 'baseline', marginRight: "2%"}}>
            <FormControl
                style={{flex: 1}}>
                <InputLabel>{t('createEvent.whereDidYouGetTheCode', 'Where did you get the code?')}</InputLabel>
                <Select
                    //displayEmpty
                    disabled={props.disabled}
                    value={props.value}
                    onChange={handleChange}
                    label="Code Type">
                    <MenuItem value={'inside'}>{t('createEvent.codeIsMine', 'The code is mine')}</MenuItem>
                    <MenuItem value={'outside'}>{t('createEvent.codeIsFromPartner', 'I received it from a partner')}</MenuItem>
                </Select>
                {/*<FormHelperText>Select the source type of the transaction code</FormHelperText>*/}
            </FormControl>
            {
                props.value ?
                    <FieldInfo description={props.value === 'inside' ?
                        t('createEvent.codeIsMineDescription', 'e.g. If the code is from your ERP')
                        : t('createEvent.codeIsFromPartnerDescription', 'e.g. If you received the code from an event generated from a partner')}/> : null
            }
        </div>
    }
    /*<Typography gutterBottom><strong>Business Transaction Code:</strong> {bizValue.value}
    </Typography>*/

    const uploadedFile = bizValue && bizValue.value ? _.find(props.state.bizTransactionsDocuments, ['bizTransaction', bizValue.value]) : null

    const handleGenerateCodeCheckedChange = (event) => {
        onClearClick(bizValue.value)
        setBizValue({value: null, isSet: false, generated: event.target.checked})
    };

    const onSetClick = async (data) => {
        if(!bizValue.isSet) {
            const {codeType} = data
            let newBizTransactionList = _.cloneDeep(eventContext.getFormField('what','bizTransactionList').value) || []

            const bizTransValue = await buildTransactionCode(bizValue.generated, codeType, _bizType.value, data[_bizType.value], props.setOpenAlertMessage);
            if (bizTransValue) {
                eventContext.setValidationField('what', _bizType.value, true)

                _.forEach(props.bizTransactions, (type, index) => {
                    if (type === _bizType.value) {
                        newBizTransactionList[index]=bizTransValue
                    }
                })

                eventContext.setFormField('what', "bizTransactionList", newBizTransactionList)

                setBizValue({...bizValue, isSet: true, value: bizTransValue})
            }
        } else {
            onClearClick(bizValue.value)
        }
    }

    const onClearClick = (_bizValue) => {
        let newBizTransactionList = _.cloneDeep(eventContext.getFormField('what','bizTransactionList').value) || []

        _.forEach(props.bizTransactions, (type, index) => {
            if (type === _bizType.value) {
                newBizTransactionList[index] = undefined
            }
        })

        eventContext.setValidationField('what', _bizType.value, false)

        eventContext.setFormField('what', "bizTransactionList", newBizTransactionList)

        const new_biz_trans_docs = props.state.bizTransactionsDocuments.filter(function (o) {
            return o.bizTransaction !== _bizValue
        })

        props.setState({...props.state, bizTransactionsDocuments: new_biz_trans_docs})

        eventContext.setFormField(null, 'docids', new_biz_trans_docs)

        setBizValue({...bizValue, value: null, isSet: false})
    }

    return <Box p={1} mb={1}>
        {bizValue && bizValue.value ?
            <Box pl={2} pr={2}>
                <Grid container direction="row" justify="flex-start">
                    <Grid item xs={3} container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                        {
                            uploadedFile ?
                                <Chip
                                    label={uploadedFile.docType === 'link' ? 'Link' : t('entities.document.uploadedFile', 'Uploaded File')}
                                    icon={uploadedFile.docType === 'link' ?
                                        <Link style={{color: accentColor}}/>
                                        : <Description style={{color: accentColor}}/>
                                    }
                                    className={props.classes.infoChip}
                                    size={"small"}
                                /> : null
                        }
                        <DocumentIconButton editable={true} bizTransaction={bizValue.value}
                                            color={primaryColor}
                                            upperState={props.state} setUpperState={props.setState}/>
                    </Grid>
                    <Grid item xs={8} container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">
                        <Box width={1}>
                            <Typography>
                                <b>{_.find(bizTransactionTypes, ['value', _bizType.value]).name + ': '}</b>
                                {bizValue.value}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                        <IconButton aria-label="clear" onClick={() => onClearClick(bizValue.value)}>
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            : !props.optional ? <FormHelperText error>{_bizType.name} cannot be empty</FormHelperText> : null}
        <Box p={2} className={props.classes.bizTransArea}>
            <Box mb={1} style={{display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", alignItems: "center", flex: 1}}>
                {/* VERSIONE ASINCRONA<FieldInfo description={props.state.bizTransactionDescription ?
                props.state.bizTransactionDescription[props.bizType] : null}/>*/}
                <FieldInfo name={t('createEvent.transactionType', 'Transaction Type')+': ' + _bizType.name}
                           description={_bizType ? _bizType.description : ''}/>
                {<Fade in={bizValue.generated} unmountOnExit mountOnEnter>
                    <Chip
                        size={'small'}
                        className={props.classes.infoChip}
                        icon={<InfoIcon style={{color: accentColor}}/>}
                        label={<i>{t('createEvent.iChainWillGenerateAbizTransCodeByConfirm', "iChain will generate a code for this transaction, by pressing 'Confirm'")}</i>}
                    />
                </Fade>}
            </Box>
            <Box>
                <Divider/>
                <Box pt={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Tooltip arrow
                             title={t('createEvent.iChainWillGenerateAbizTransCode', "If you don't have a transaction code, by clicking 'Add' iChain will generate a code for the selected type of transaction")}>
                        <WarningIcon className={props.classes.warningIcon}/>
                    </Tooltip>
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox value={bizValue.generated}
                                               checked={bizValue.generated}
                                               disabled={bizValue.isSet}
                                               onChange={handleGenerateCodeCheckedChange}
                                               color="default"/>}
                            label={<Typography variant={"caption"}>{t('createEvent.iDontHaveAdocId', "I don't have a document identifier")}</Typography>}
                            labelPlacement="end"/>
                    </FormControl>
                </Box>
                <Collapse in={!bizValue.generated} unmountOnExit={true}>
                    <div style={{display: 'flex', justifyContent: "flex-start", alignItems: "baseline"}}>
                        <Controller
                            as={
                                <CodeType/>
                            }
                            name="codeType"
                            control={control}
                            state={props.state}
                            setState={props.setState}
                            disabled={bizValue.isSet || bizValue.generated}
                        />
                        <TextField
                            style={{flex: 2}}
                            size={'small'}
                            variant={"outlined"}
                            label={"Document Identifier"}
                            name={_bizType.value}
                            inputRef={register}
                            error={bizValue.generated ? false : !watch(_bizType.value)}
                            disabled={bizValue.isSet || bizValue.generated}
                            helperText={t('createEvent.insertDocId', "Insert the document identifier related to this transaction")
                            + (getValues('codeType') && getValues('codeType') === 'outside' ?
                                t('createEvent.insertDocIdDescription', "(e.g. a GDTI code with prefix 'urn:epc:id:gdti:', or a iChain internal code with the prefix 'http://' format)") : '')}
                        />
                    </div>
                </Collapse>
            </Box>
            <Box pt={1} style={{display: 'flex', justifyContent: 'center'}}>
                <Button aria-label="set"
                        disabled={(!bizValue.generated &&
                            (!watch('codeType') || !watch(_bizType.value)))}
                        onClick={handleSubmit(onSetClick)}
                        endIcon={!bizValue.isSet ?
                            <CheckCircleIcon style={{color: accentColor}}/>
                            : <EditIcon style={{color: accentColor}}/>}>
                    {!bizValue.isSet ? t('actions.confirm') : t('actions.edit')}
                </Button>
            </Box>
        </Box>
    </Box>
}

export default BizTransactionPartiallyFixedBuilder